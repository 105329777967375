import React, { PureComponent } from 'react';
import { Segment, Grid, Image, Button } from 'semantic-ui-react';

export interface IPictureStatementsProps {
    callToAction?: any;
    image: string;
    statements?: any;
    children: any;
}

export default class PictureStatements extends PureComponent<IPictureStatementsProps> {
    render() {
        const { callToAction, image, statements, children } = this.props;
        return (
            <Segment className='picture-statement' vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {statements || children}
                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                            <Image bordered rounded size='large' src={image} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Button size='huge'>{callToAction}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}