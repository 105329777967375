import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import SelectInput from "../../../controls/SelectInput";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, { IStateAwareFormProps } from "../../StateAwareForm/StateAwareForm";

export interface IContactFormProps extends IStateAwareFormProps {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    preferredLanguage: string;
    telephoneNumber: string;
    state: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
}

export default class ContactForm extends StateAwareForm<IContactFormProps> {
    constructor(props) {
        super(props);
    }

    public static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            email: this.props.email,
            title: this.props.title,
            telephoneNumber: this.props.telephoneNumber,
            preferredLanguage: this.props.preferredLanguage,
            state: this.props.state,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                firstName: this.props.firstName,
                lastName: this.props.lastName,
                email: this.props.email,
                title: this.props.title,
                telephoneNumber: this.props.telephoneNumber,
                preferredLanguage: this.props.preferredLanguage,
                state: this.props.state,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Contact"
                />

                <Message
                    error
                    header="Could not save contact"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"First Name"}
                    defaultValue={this.props.firstName}
                    name="firstName"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input
                    fluid
                    label={"Last Name"}
                    defaultValue={this.props.lastName}
                    name="lastName"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input
                    fluid
                    label={"Email"}
                    placeholder="example@email.com"
                    defaultValue={this.props.email}
                    name="email"
                    type="email"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                />

                <Form.Input
                    fluid
                    label={"Title"}
                    defaultValue={this.props.title}
                    placeholder="CEO"
                    name="title"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <Form.Input
                    fluid
                    label={"Telephone Number"}
                    defaultValue={this.props.telephoneNumber}
                    name="telephoneNumber"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    placeholder="123-456-7890"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                />

                <SelectInput
                    options={[
                        { key: "en", text: "English", value: "en" },
                        { key: "fr", text: "French", value: "fr" },
                    ]}
                    label={"Preferred Language"}
                    defaultValue={this.props.preferredLanguage}
                    name="preferredLanguage"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <SelectInput
                    options={[
                        { key: "primary", text: "Primary Contact", value: "primary" },
                        { key: "regular", text: "Regular Contact", value: "regular" },
                        { key: "former", text: "Former Contact", value: "former" },
                    ]}
                    label={"State"}
                    defaultValue={this.props.state}
                    name="state"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
