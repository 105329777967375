import React from 'react';
import PageEditPage from '../../components/pages/PageEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class PageEditController extends LocalizedController {
    private repository: Repository;
    private websitesRepository: Repository;
    private layoutsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        layoutMap: [],
        currentLayout: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, websitesRepository, layoutsRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.websitesRepository = websitesRepository;
        this.layoutsRepository = layoutsRepository;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            let layout = await this.repository.getByPath(`pages/${this.params?.id}/layouts`);
            item.layout = layout.length > 0 ? layout[0].id : undefined;
        } catch (e) {
            return;
        }
        console.log(item)
        return item;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let layoutId = data.layout;
        delete data.layout;
        try {
            if (this.state.currentLayout) {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                await this.repository.unlinkByPath(`pages/${this.params?.id}`, { layoutId: this.state.currentLayout })
            }
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
                ...data,
            });
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.linkByPath(`pages/${this.params?.id}`, { layoutId });

            this.setState({
                item: {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    id: this.params?.id,
                    ...data,
                    layout: layoutId,
                },
                currentLayout: layoutId,
            })
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlinkByPath(`pages/${this.params?.id}`, { layoutId: this.state.currentLayout })
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/website-management/pages');
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        let item = await this.get();
        let layoutMap: any[] = await this.getPageLayoutsMap();
        layoutMap = layoutMap.map((layout, index) => {
            return { key: index, text: layout['name'], value: layout['id'] }
        })
        this.setState({
            item,
            layoutMap,
            currentLayout: item && item.layout ? item.layout : undefined,
        });
    }

    private async getPageLayoutsMap() {
        let layout;
        let websiteInfo;
        let pageLayouts = [];

        try {
            websiteInfo = await this.websitesRepository.get();
            layout = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates`);
            pageLayouts = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates/${layout[0].id}/layouts`);
            console.log(pageLayouts);
        } catch (e) {
            throw new Error(e);
        }
        return pageLayouts;
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Pages'), value: '/website-management/pages' },
            {
                key: 3,
                text: this.state.item ? this.state.item!['title'] : '',
                value: this.state.item ? `/website-management/pages/${this.state.item!['id']}` : undefined
            },
            { key: 4, text: this.localizationService.translate('Edit'), undefined },
        ]
    }

    render() {
        const { item, layoutMap } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();
        return this.prepare(
            <PageEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                layoutMap={layoutMap}
            />
        )
    }
}