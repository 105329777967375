import React, { PureComponent } from "react";
import {
    Button,
    Table,
    Container,
    Header,
} from "semantic-ui-react";
import { IStoreConfigurationListPageProps } from "./StoreConfigurationListPage";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import ThemingUtils from "../../../../utilities/Theming/ThemingUtils";
import StoreSettingsTemplate from "../StoreSettingsTemplate";
import ScrollableTable from "../../../layouts/tables/ScrollableTable";
import "./styles.css";

const commonUtils = new CommonUtils();
const themingUtils = new ThemingUtils();

export interface IStoreConfigurationListDesktopPageProps extends IStoreConfigurationListPageProps {
    onPathChange: (path: string) => any,
    localizationService: any,
    storefronts?: any[],
}

export interface IStoreConfigurationListDesktopPageState {
}

export default class DesktopPage extends PureComponent<
    IStoreConfigurationListDesktopPageProps,
    IStoreConfigurationListDesktopPageState
> {
    state = {};

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No storefronts!')}</Header>
            </Container>
        )
    }

    private renderStorefrontHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Store Name')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    private renderStorefrontBody() {
        const { storefronts } = this.props;
        if (!storefronts) {
            return <Table.Body>
                {!this.renderNoContentMessage()}
            </Table.Body>
        }
        const storefrontsTable = storefronts.map((storefront: any) => (
            <Table.Row /*onClick={(e) => this.handleDoubleClick(e, storefront)}*/>
                <Table.Cell>{storefront.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Button
                        icon="eye"
                        color="blue"
                        as="a"
                        href={`/store-management/${storefront.id}/overview`}
                    />
                </Table.Cell>
            </Table.Row>
        ));
        return (
            <Table.Body>
                {!storefrontsTable.length ? this.renderNoContentMessage() : storefrontsTable}
            </Table.Body>
        );
    }

    render() {
        return (
            <StoreSettingsTemplate onChange={this.props.onPathChange} activeItem="store-management" localizationService={this.props.localizationService}>
                <div style={{ padding: '0px 30px' }}>
                    <h2>{this.translate('Storefronts')}</h2>
                    <ScrollableTable striped selectable className="fifteen">
                        {this.renderStorefrontHeaders()}
                        {this.renderStorefrontBody()}
                    </ScrollableTable>
                </div>
            </StoreSettingsTemplate>
        );
    }
}
