import React, { PureComponent } from "react";
import { LicenseForm } from "../../../../../forms/licenses";
import "./styles.css";

export interface ILicenseDesktopPageProps {
    name: string;
    type: string;
    level: string;
    features: any;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ILicenseDesktopPageProps> {
    static defaultProps = {
        name: "",
        type: "",
        level: "",
        features: undefined,
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="license-management">
                <LicenseForm
                    name={this.props.name}
                    type={this.props.type}
                    level={this.props.level}
                    onSubmit={(data) => this.props.onSave(data)}
                    onDelete={this.props.onDelete}
                />
            </Gatekeeper>
        );
    }
}
