import React from 'react';
import InvoicePage from '../../components/pages/invoices/InvoicePage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class InvoicesController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        invoices: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.getByPath('invoices?sort=date:desc');
        } catch (e) {
            console.error(e)
            return [];
        }
        return items;
    }

    private async updateStatus(data: any) {
        try {
            await this.repository.update(data);
            await this.getAll();
        } catch (e) {
            console.error(e);
        }

    }

    async componentDidMount() {
        await this.getAll();
    }

    private async getAll() {
        let invoices = await this.get();
        this.setState({
            invoices,
        });
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/store-management/invoices/${id}`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Invoices'), value: undefined },
        ]
    }

    render() {
        const { invoices } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <InvoicePage
                items={invoices}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onPathChange={(path) => this.changeSettings(path)}
                quickSave={(data) => this.updateStatus(data)}
            />
        )
    }
}