import React from 'react';
import { ActivationPage } from '../components/pages';
import LocalizedController from './LocalizedController';
import queryString from 'query-string';
import { ActivationService } from '../services';

export interface IActivationControllerProps {

}

export interface IActivationControllerState {
    activationSuccessful?: boolean;
    message?: string;
}

export default class ActivationController extends LocalizedController {
    state: IActivationControllerState = {};
    protected activationService: ActivationService;

    constructor({ args: [router, routeMenuItemFactory, localizationService, activationService], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.activationService = activationService;
    }

    async componentDidMount() {
        let activationSuccessful;
        let message;
        let res;

        try {
            res = await this.activate();
            activationSuccessful = true;
            message = "Thank you for registering with Dreamship Solutions Build Platform! Further instructions will follow in an upcoming email.";
        } catch (e) {
            activationSuccessful = false;

            switch ((e as any).statusCode) {
                case 400: message = "Looks like you're already registered!"; break;
                case 401: message = "We were not able to validate your activation. Please try re-activating or contact support."; break;
                default: message = (e as Error).message; break;
            }
        }

        this.setState({ activationSuccessful, message })
    }

    async activate() {
        const { token, email } = this.getSearchParameters() || {};

        if (!token || !email) {
            throw new Error("Invalid uri");
        }

        await this.activationService.activate({ token, email });
    }

    async handleAcknowledge(user) {
        setTimeout(() => this.router.redirect('/login'), 1000);

        return true;
    }

    getSearchParameters() {
        return queryString.parse(this.router.getQueryParameters());
    }

    render() {
        const { activationSuccessful, message } = this.state;

        return this.prepare(
            <ActivationPage
                onAcknowledge={user => this.handleAcknowledge(user)}
                activationSuccessful={activationSuccessful}
                message={message}
            />
        )
    }
}