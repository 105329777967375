import React, { PureComponent } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import ContactForm from "../../../../../forms/accounts/ContactForm";

export interface IContactEditorModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onSave: (data: any) => Promise<any>;
    item?: any;
}

export interface IContactEditorModalState {
    item?: any;
    state: string;
}

export default class ContactEditorModal extends PureComponent<IContactEditorModalProps, IContactEditorModalState> {
    static defaultProps = {
        item: {}
    }

    state: IContactEditorModalState = {
        state: 'create',
    }

    toggleModal() {
        this.props.toggleModal();
    }

    renderState(state: string) {
        switch (state) {
            case 'create': return this.renderCreateState();
            case 'successful': return this.renderSuccessful();
        }

        return ""
    }

    renderCreateState() {
        const { item } = this.props;
        return [
            <Modal.Content>
                <p>Saving the form will create the user and associate it to your account.</p>
                <ContactForm
                    onSubmit={async (data) => this.handleSave({ ...item, ...data })}
                    firstName={item?.firstName}
                    lastName={item?.lastName}
                    email={item?.email}
                    title={item?.title}
                    state={item?.state}
                    preferredLanguage={item?.preferredLanguage}
                    telephoneNumber={item?.telephoneNumber}
                    controlsTop={false}
                    controlsBottom={true}
                />
            </Modal.Content>,
        ];
    }

    renderSuccessful() {
        return [
            <Modal.Content>
                <Header>Successfully saved {this.state.item?.firstName} {this.state.item?.lastName} as a contact on this account</Header>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ]
    }

    async handleSave(data: any) {
        let item;

        try {
            item = await this.props.onSave(data);
        } catch (e) {
            throw e;
        }

        this.setState({ item, state: 'successful' })
    }

    render() {
        const { modalOpen } = this.props;

        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Modify Contact</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}