import React from 'react';
import ArchetypePage from '../../components/pages/applications/ArchetypePage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class ArchetypeController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: []
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ArchetypePage
                items={items}
                
                Gatekeeper={Gatekeeper}
            />
        )
    }
}