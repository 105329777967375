import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IProductCatalogPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any;
    breadcrumbLevels?: any;
    onPathChange: (path: string) => any;
}

export default class ProductCatalogPage extends PureComponent<IProductCatalogPageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, localizationService, breadcrumbLevels, onPathChange } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage items={items} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} onPathChange={onPathChange} />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}
