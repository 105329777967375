export interface IActivationServiceParams {
    token: string;
    email: string;
}

export default class ActivationService {
    constructor(protected provider) {

    }

    async activate(params: IActivationServiceParams) {
        const { token, email } = params;
        return await this.provider.post({ token, email });
    }
}