import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";
import LocalizedPage from '../LocalizedPage';
import Component from "../../layouts/component";

export interface IFeaturesPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    toggleFeatureActive?: Function;
    toastConfig?: any;
    mobileToastConfig?: any;
    delayCount?: number;
    featureUpdated?: boolean;
}

export default class FeaturesPage extends PureComponent<IFeaturesPageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, toggleFeatureActive, toastConfig, delayCount, featureUpdated } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage items={items} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} toggleFeatureActive={toggleFeatureActive} toastConfig={toastConfig} delayCount={delayCount} featureUpdated={featureUpdated} />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} toggleFeatureActive={toggleFeatureActive} toastConfig={toastConfig} delayCount={delayCount} featureUpdated={featureUpdated} />
                </Component>
            </Page>
        );
    }
}
