import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { SignupForm } from '../../forms';
import { BrandedEntryContent } from '../../layouts/sections';
import { ISignupFormState } from '../../forms/SignupForm/SignupForm';
import { ISignupPageProps } from './SignupPage';
import { Divider } from 'semantic-ui-react';
import './styles.css';

export interface ISignupDesktopPageProps extends ISignupPageProps {
    onSignup: (values: ISignupFormState) => any;
}

export default class DesktopPage extends PureComponent<ISignupDesktopPageProps> {
    render() {
        // const Picker = LocalizedComponent()(DesktopPageRender);
        const Picker = DesktopPageRender;
        return <Picker {...this.props} />;
    }
}

class DesktopPageRender extends PureComponent<ISignupDesktopPageProps> {
    render() {
        const { onSignup } = this.props;

        return (
            <DesktopSkeleton>
                <BrandedEntryContent className="signup-page" useFullLogo stacked={false}>
                    <SignupForm onSubmit={onSignup} />
                    <Divider />
                    <p>Already have an account? <a href="/login">Log in</a>.</p>
                </BrandedEntryContent>
            </DesktopSkeleton>
        )
    }
}