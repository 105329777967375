import React, { PureComponent } from 'react';
import { Button, Container, Header, Table, Grid, Label } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import StoreSettingsTemplate from '../../storeconfiguration/StoreSettingsTemplate';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private renderInvoiceHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Invoice ID')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Date Created')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Status')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderInvoiceBody() {
        const { items } = this.props;
        const invoicesTable = items.map((invoice: any) => (
            <Table.Row onClick={(e) => this.handleDoubleClick(e, invoice)}>
                <Table.Cell>{invoice.id}</Table.Cell>
                <Table.Cell>
                    {commonUtils.renderDate(invoice.date)}
                </Table.Cell>
                <Table.Cell>
                    <Label content={invoice.status} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <Button
                        icon="eye"
                        color="blue"
                        as="a"
                        href={`/store-management/invoices/${invoice.id}`}
                    />
                </Table.Cell>
            </Table.Row>
        ));
        return (
            <Table.Body>
                {!invoicesTable.length ? this.renderNoContentMessage() : invoicesTable}
            </Table.Body>
        );
    }


    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No invoices!')}</Header>
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <StoreSettingsTemplate localizationService={this.props.localizationService} onChange={this.props.onPathChange} activeItem='invoices'>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <Grid columns={1} stackable style={{ marginBottom: '40px' }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3' content={this.translate('All Invoices')}></Header>
                                    <ScrollableTable striped selectable className="fifteen">
                                        {this.renderInvoiceHeaders()}
                                        {this.renderInvoiceBody()}
                                    </ScrollableTable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </StoreSettingsTemplate>
        );
    }
}
