import { CookieProvider } from "../providers";
import FeatureService from "./FeatureService";

export class SecurityError extends Error {
    statusCode?: number;
    statusMessage?: string;
}

export default class SecurityService {
    static KEY = 'token';
    static APPLICATION_ID_KEY = 'appId'

    constructor(protected provider: CookieProvider, protected router, protected featureService: FeatureService) {

    }

    getHeader() {
        const token = this.provider.getOne(SecurityService.KEY);
        const applicationId = localStorage.getItem('applicationId') ? localStorage.getItem('applicationId')! : this.provider.getOne(SecurityService.APPLICATION_ID_KEY);


        if (!token) {
            const statusMessage = 'Unauthorized';
            let e = new SecurityError(statusMessage);
            e.statusCode = 401;
            e.statusMessage = statusMessage;

            throw e;
        }

        let header = {
            'Authorization': `Bearer ${token}`,
        };

        if (applicationId) {
            header['Application-Id'] = applicationId;
        }

        return header;
    }

    /**
     * Julian please check if this is okay.
     * It's aiming to resolve the issue where the appId is undefined and stays as such.
     * You are forced to log out and log back in.
     * 
     * Instead I delete the expired cookie and reload the page,
     * which fetches a new appId.
     */
    getApplicationId() {
        let value = localStorage.getItem('applicationId') ? localStorage.getItem('applicationId')! : this.provider.getOne(SecurityService.APPLICATION_ID_KEY);

        if ("undefined" === value || !value) {
            // return undefined
            this.provider.delete(SecurityService.APPLICATION_ID_KEY);
            window.location.reload();
        }

        return value;
    }

    saveApplication(appId) {
        this.provider.update(SecurityService.APPLICATION_ID_KEY, appId, {
            sameSite: 'strict',
            // secure: true,
            // domain,
        });

        localStorage.setItem('applicationId', appId)

        if (window.location.pathname !== '/login') {
            this.featureService.setStale();
            this.router.redirect('/');
        }
    }

    saveToken(token, expiryDate) {
        this.featureService.setStale();

        this.provider.update(SecurityService.KEY, token, {
            expires: new Date(expiryDate),
            sameSite: 'strict',
            // secure: true,
            // domain,
        });
    }

    handleAuthenticationError() {
        const pagesWithoutModal = [
            '/login',
            '/signup',
            '/reset',
            '/activate',
        ]

        const verbsWithoutState = [
            'associate',
            'create',
            'edit',
        ]

        //TODO: REMOVE THIS ONCE SECURITY IS DONE
        if (process.env.REACT_APP_DISABLE_SECURITY && JSON.parse(process.env.REACT_APP_DISABLE_SECURITY)) {
            return;
        }

        if (!pagesWithoutModal.includes(window.location.pathname) && !verbsWithoutState.some((v) => window.location.href.indexOf(v) >= 0)) {
            localStorage.setItem('last_visited_page', window.location.pathname);
        }

        localStorage.removeItem('applicationId');

        return this.router.redirect('/login');
    }

    logout() {
        localStorage.removeItem('applicationId');
        this.provider.delete(SecurityService.KEY);
        this.provider.delete(SecurityService.APPLICATION_ID_KEY);
        this.router.redirect('/login');
    }
}