const settingsValues = [
    {
        "name": "candidates",
    },
    {
        "name": "user settings"
    }
];

export default settingsValues;
