import React from 'react';
import ProductEditPage from '../../components/pages/products/ProductEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class ProductCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;

        try {
            item = await this.repository.create(data);
            this.router.redirect(`/products/${item.id}/edit`);
        } catch (e) {
            throw e;
        }
    }

    private generateProductSKUs(...args) {
        let r: any[] = [], max = args.length - 1;
        function helper(arr, i) {
            for (let j = 0, l = args[i].length; j < l; j++) {
                let a = arr.slice(0); // clone arr
                a.push(args[i][j]);
                if (i == max)
                    r.push(a);
                else
                    helper(a, i + 1);
            }
        }
        helper([], 0);
        return r;
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 2, text: this.localizationService.translate('Create product'), value: undefined },
        ]
    }

    render() {
        const { item } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}