import React, { PureComponent } from "react";
import { Form, Dropdown } from "semantic-ui-react";

export interface IMultiSelectInputProps {
    options: any[];
    defaultValue?: any[];
    onChange?: (e, value) => any;
    label: string;
    name: string;
}

export interface IMultiSelectInputState {
    options: any[],
    value: any
}

export default class MultiSelectInput extends PureComponent<IMultiSelectInputProps, IMultiSelectInputState> {
    state = {
        options: [],
        value: undefined,
    };

    static defaultProps = {
        options: [],
    }

    componentDidMount() {
        let options = (this.props.defaultValue || []).map(value => ({text: value, value}));
        this.setState({
            options: [
                ...this.props.options,
                ...options,
            ],
            value: this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            let options = (this.props.defaultValue || []).map(value => ({text: value, value}));
            
            this.setState({
                options: [
                    ...this.props.options,
                    ...options,
                ],
                value: this.props.defaultValue,
            });
        }
    }

    handleAddition ({ value }) {
        this.setState((prevState) => ({
            options: [{ text: value, value }, ...prevState.options],
        }));
    }

    handleChange(e, { name, value }) {
        this.setState({value}, () => this.props.onChange && this.props.onChange(e, { name, value }));
    }

    render() {
        return (
            <Form.Input label={this.props.label} fluid>
                <Dropdown
                    value={this.state.value}
                    search
                    selection
                    allowAdditions
                    multiple
                    fluid
                    name={this.props.name}
                    options={this.state.options}
                    onChange={(e, { name, value }) => this.handleChange(e, { name, value })}
                    onAddItem={(e, { value }) => this.handleAddition({ value })}
                />
            </Form.Input>
        );
    }
}