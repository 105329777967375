import React, { PureComponent } from "react";
import "./styles.css";
import {
    Button,
    Table,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import { ScrollableTable } from "../../layouts/tables";
import { Message, UnauthorizedMessage } from "../../layouts/messages";
import { LoadingSpinner } from "../../controls";

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
}
export interface IDesktopPageState {
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    static defaultProps = {
        onDoubleClick: () => { },
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Customer ID</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell icon>
                        Status
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    private renderTableBody() {
        const { items } = this.props;
        const cartsTable = items.map((cart: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, cart)}>
                    <Table.Cell>{cart.customerId}</Table.Cell>
                    <Table.Cell>
                        {cart.date}
                    </Table.Cell>
                    <Table.Cell>
                        {cart.status}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            icon="eye"
                            color="teal"
                            as="a"
                            href={`/carts/${cart.id}`}
                            disabled
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
        return <Table.Body>{!cartsTable.length ? <Message content="No carts." compact /> : cartsTable}</Table.Body>;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="cart-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <ScrollableTable padded striped selectable>
                            {this.renderTableHeaders()}
                            {this.renderTableBody()}
                        </ScrollableTable>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
