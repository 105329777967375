import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface IGroupFormProps extends IStateAwareFormProps {
    name: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
}

export default class GroupForm extends StateAwareForm<IGroupFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Group"
                />

                <Message
                    error
                    header="Could not save group"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"Name"}
                    defaultValue={this.props.name}
                    name="name"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
