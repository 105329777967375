import Papa from 'papaparse';

export default class CsvParser {
    async readFile(file, limit, header) {
        if (!(file instanceof File)) {
            throw new Error('Requires a valid File class')
        }

        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: ({ data, meta, errors }) => {
                    const { fields: headers } = meta;

                    return resolve({ data, headers, errors })
                },
                error: error => reject(error),
                preview: limit,
                header,
            })
        });
    }
}