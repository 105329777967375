import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';

export interface IBlogModalContentProps {
    isMobile: boolean;
    localizationService?: any;
}

export default class BlogModalContent extends PureComponent<IBlogModalContentProps> {
    static defaultProps = {
        isMobile: false,
    };
    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }
    render() {
        return (
            <Table>
                {!this.props.isMobile && (
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={'two'}>
                                {this.translate('Status')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="six">
                                {this.translate('Definition')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )}
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{this.translate('Draft')}</Table.Cell>
                        <Table.Cell>
                            {this.translate('The post is being worked on and saved but it isn\'t ready for review, or for public eyes.')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{this.translate('Submitted')}</Table.Cell>
                        <Table.Cell>
                            {this.translate('The post is completed and is ready for review.')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{this.translate('Approved')}</Table.Cell>
                        <Table.Cell>
                            {this.translate('The post has been reviewed and approved by decision makers to ensure the contents are up to par.')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{this.translate('Published')}</Table.Cell>
                        <Table.Cell>
                            {this.translate('The post is now visible to the public on the website.')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{this.translate('Recalled')}</Table.Cell>
                        <Table.Cell>
                            {this.translate('An issue was found with the post after it was published, and therefore the post needed to be taken down. At this stage, edits can be made and a new round of approval sought. The Recalled status is also available during the approval phase of the post so that a decision maker can indicate that the post needs revisions.')}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
}
