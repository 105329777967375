import React, { PureComponent } from "react";
import {
    Segment,
    Header,
    Form,
    Input,
    TextArea,
    Button,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";

export interface IContactFormTemplateProps {
    section: any;
}

export default class ContactFormTemplate extends PureComponent<
    IContactFormTemplateProps
> {
    render() {
        const { section } = this.props;
        return (
            <Form onSubmit={(e) => e.preventDefault()}>
                <Header>{section.title}</Header>
                <Form.Group widths='equal'>
                    {section.additional.name &&
                        <Form.Field
                            control={Input}
                            label={section.additional.name.label}
                            placeholder={section.additional.name.placeholder}
                        />
                    }
                    {section.additional.email &&
                        <Form.Field
                            control={Input}
                            label={section.additional.email.label}
                            placeholder={section.additional.email.placeholder}
                        />
                    }
                    {section.additional.phone &&
                        <Form.Field
                            control={Input}
                            label={section.additional.phone.label}
                            placeholder={section.additional.phone.placeholder}
                        />
                    }
                </Form.Group>
                {section.additional.message &&
                    <Form.Field
                        id='form-textarea-control-opinion'
                        control={TextArea}
                        label={section.additional.message.label}
                        placeholder={section.additional.message.placeholder}
                    />
                }
                {section.additional.submit &&
                    <Form.Field
                        id='form-button-control-public'
                        control={Button}
                        content={section.additional.submit.label}
                    />
                }
            </Form>
        )
    }
}