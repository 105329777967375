import { DecoratedCollection } from "../collections";

export default class DecoratedCollectionFactory {
    constructor(protected decoratedEntityFactory) {

    }

    create(type, response) {
        const { _links, _embedded } = response;

        for (let key in _embedded) {
            _embedded[key] = _embedded[key].map(entity => this.decoratedEntityFactory.create(entity));
        }

        return new DecoratedCollection(type)
            .setLinks(_links)
            .setEmbedded(_embedded);
    }
}