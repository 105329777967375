export default {
    "host": process.env.REACT_APP_API_AUTHENTICATION_HOST,
    "port": process.env.REACT_APP_API_AUTHENTICATION_PORT,
    "basePath": process.env.REACT_APP_API_AUTHENTICATION_BASE_PATH,
    "path": "login",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'login';