import React, { PureComponent } from "react";
import { Form, Button, InputOnChangeData } from "semantic-ui-react";
import { LoadingMessage, Message } from "../../layouts/messages";

export interface ISignupFormProps {
    onSubmit: (values: ISignupFormState) => any;
}

export interface ISignupFormState {
    error?: any;
    loading?: boolean;
}

export default class SignupForm extends PureComponent<ISignupFormProps> {
    state: ISignupFormState = {};

    static defaultProps = {
        onSubmit: (values) => values,
    };

    handleChange({ name, value }: InputOnChangeData) {
        this.setState({ [name]: value });
    }

    async handleSubmit() {
        try {
            this.setState({ error: undefined, loading: true });
            await this.props.onSubmit(this.state);
        } catch (e) {
            this.setState({ error: e, loading: false });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        name="firstName"
                        icon="user"
                        iconPosition="left"
                        placeholder="First name"
                        onChange={(e, value) => this.handleChange(value)}
                    />
                    <Form.Input
                        fluid
                        name="lastName"
                        icon="user"
                        iconPosition="left"
                        placeholder="Last name"
                        onChange={(e, value) => this.handleChange(value)}
                    />
                </Form.Group>

                <Form.Input
                    fluid
                    name="email"
                    icon="at"
                    iconPosition="left"
                    placeholder="E-mail address"
                    onChange={(e, value) => this.handleChange(value)}
                />

                <Form.Input
                    fluid
                    name="password"
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    onChange={(e, value) => this.handleChange(value)}
                >
                    <input autoComplete="true" />
                </Form.Input>

                <Form.Input
                    fluid
                    name="password_confirmed"
                    icon="lock"
                    iconPosition="left"
                    placeholder="Confirm password"
                    type="password"
                    onChange={(e, value) => this.handleChange(value)}
                >
                    <input autoComplete="true" />
                </Form.Input>

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="We are creating your account"
                />

                <Message
                    error
                    header="Account creation failed"
                    content={error && error.toString()}
                />

                <Button color="teal" fluid size="large" disabled={loading}>
                    Sign Up
                </Button>

                <p className="signup-page-tos-message">
                    By signing up, you agree to Dreamship Solutions' Terms of Service,
                    Code of Conduct, and Privacy Policy.
                </p>
            </Form>
        );
    }
}
