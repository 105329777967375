import React, { PureComponent } from 'react';

export interface ILogoProps {
    full?: boolean;
}

export default class Logo extends PureComponent<ILogoProps> {
    render() {
        const { full } = this.props;
        let logo = '/images/logo-black.png';

        if (full) {
            logo = '/images/logo-black.png'
        }

        return <img src={logo} />
    }
}