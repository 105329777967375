import React from "react";
import { Menu } from "semantic-ui-react";
import { SecurityService } from "../services";

export default class LogoutButtonFactory {
    constructor(protected service: SecurityService) { }

    create(showText?) {
        if (showText) {
            // return <div style={{ position: 'absolute', bottom: '0', width: '100%' }}><Menu.Item link content="Logout" onClick={() => this.service.logout()} position='right' /></div>
            return <div><Menu.Item link content="Logout" onClick={() => this.service.logout()} position='right' /></div>
        }
        return (
            <Menu.Item link icon="sign out" onClick={() => this.service.logout()} />
        )
    }
}