import { ApiProvider } from "../providers";
import SecurityService from "./SecurityService";

export default class AuthenticationService {
    constructor(protected provider: ApiProvider, protected securityService: SecurityService) {

    }

    async authenticate(email, password) {
        if (!email || !password) {
            throw new Error('Missing credentials');
        }

        let authentication;

        try {
            authentication = await this.provider.post({ email, password });
        } catch (e) {
            throw e;
        }

        const { token, expiryDate } = authentication;

        this.securityService.saveToken(token, expiryDate);
    }
    
}