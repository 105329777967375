import React, { PureComponent } from 'react';
import Cards from '../../cards/Cards';

export interface IDockProps {
    children: any,
    showNav: boolean,
    openFromRight: boolean,
    onHideNav?: any,
}

export default class Dock extends PureComponent<IDockProps> {
    static defaultProps = {
        onHideNav: () => { }
    }

    constructor(props) {
        super(props);
    }

    onHideNav() {
        this.props.onHideNav();
    }

    render() {
        const { children, openFromRight, showNav } = this.props;
        const root: React.CSSProperties = {
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            overflow: 'hidden',
            zIndex: 102,
            pointerEvents: showNav ? 'auto' : 'none',
        }

        const nav: React.CSSProperties = {
            position: 'relative',
            width: '90%',
            maxWidth: 400,
            height: '100%',
            background: '#fff',
            boxShadow: '2px 0 12px rgba(0,0,0,0.4)',
            transform: showNav ? 'none' : `translateX(${openFromRight ? 102 : -102}%)`,
            transition: `transform ${showNav ? '0.33s' : '0.13s'} cubic-bezier(0,0,0.3,1)`,
            display: 'flex',
            willChange: 'transform',
            flexDirection: 'column',
            float: openFromRight ? 'right' : 'left',
            padding: '50px 25px',
        }

        const overlay: React.CSSProperties = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: showNav ? 1 : 0,
            background: 'rgba(0,0,0,0.4)',
            transition: 'opacity 0.3s cubic-bezier(0, 0, 0.3, 1)',
            willChange: 'opacity',
        }

        return (
            <aside style={root}>
                <div style={overlay} onClick={() => this.onHideNav()}></div>
                <nav style={nav}>
                    {children}
                </nav>
            </aside>
        )
    }


}