import React, { PureComponent } from "react";
import { Button, Card, CardGroup, Feed, Grid, Image, Message } from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import TimeAgo from "timeago-react";
import UnauthorizedMessage from "../../layouts/messages/UnauthorizedMessage";
import LoadingSpinner from "../../controls/loaders/LoadingSpinner";
import "./styles.css";

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {
    state = {
        activeMessage: 0,
    };

    renderPosts() {
        const { items } = this.props;
        const postsTable = items.map((post, index) => {
            return (
                <Card
                    onClick={() => this.setState({ activeMessage: index })}
                    className={this.state.activeMessage === index ? "selected-message" : ""}
                >
                    <Card.Content>
                        <Feed>
                            <Feed.Event>
                                <Feed.Content>
                                    <Feed.Summary>
                                        <Feed.User>{post.name}</Feed.User>
                                    </Feed.Summary>
                                    <Feed.Date style={{ padding: "5px 0px 0px" }}>
                                        {post.date &&
                                            new Date(post.date).toLocaleString("en-CA", {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            })}
                                    </Feed.Date>
                                    {post?.message && <Feed.Extra content={post.message.substring(0, 50) + "..."} />}
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    </Card.Content>
                </Card>
            );
        });
        return postsTable;
    }

    renderMessage(activeMessage) {
        return (
            <Card fluid basic className="message-container">
                <Card.Content className="message-container-header">
                    <Card.Header>
                        {this.props.items[activeMessage]?.name}{" "}
                        {`<${this.props.items[activeMessage]?.email}>`}
                        <Button.Group floated="right">
                            <Button
                                as="a"
                                href={`mailto:${this.props.items[activeMessage]?.email}`}
                                icon="mail"
                            />
                            <Button
                                as="a"
                                href={`tel:${this.props.items[activeMessage]?.phoneNumber}`}
                                icon="phone"
                            />
                        </Button.Group>
                    </Card.Header>
                    <Card.Meta>
                        {this.props.items[activeMessage]?.date &&
                            `${new Date(this.props.items[activeMessage]?.date).toLocaleString(
                                "en-US",
                                {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                }
                            )} (`}
                        {this.props.items[activeMessage]?.date && (
                            <TimeAgo
                                datetime={new Date(this.props.items[activeMessage]?.date)}
                                locale="en_US"
                            />
                        )}
                        {this.props.items[activeMessage]?.date && `)`}
                    </Card.Meta>
                </Card.Content>
                <Card.Content>
                    <Card.Description>
                        {this.props.items[activeMessage]?.message}
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    }

    renderInbox() {
        const { items } = this.props;
        if (items && items.length > 0) {
            return (<Grid padded divided>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div className="inbox-sidebar">
                            <CardGroup stackable itemsPerRow={1} centered>
                                {this.renderPosts()}
                            </CardGroup>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {this.props.items[this.state.activeMessage] ? this.renderMessage(this.state.activeMessage) : <></>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            )
        }
        return (
            <Message size='large' compact className="inbox-no-messages">
                <Message.Header>No messages!</Message.Header>
                <br />
                <Message.Content>
                    <Image src="/images/icons/Empty mailbox with grass.png" size="small" />
                </Message.Content>
            </Message>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="messages-management"  unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div style={{ marginTop: "10px" }}>
                        {this.renderInbox()}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
