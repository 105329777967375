import React, { PureComponent } from "react";
import "./styles.css";
import {
    Icon,
    Grid,
    Card,
    Divider,
    CardGroup,
    Button,
    ButtonGroup,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../../layouts/skeleton";
import { ReturnButton } from "../../../controls";
import SwipeableViews from 'react-swipeable-views';

export interface IDesktopPageProps {
    createRoutes?: Function;
    item?: any;
    Gatekeeper: any;
    panes: any[];
    primaryContact?: any;
}
export interface IDesktopPageState {
    index: number
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state: IDesktopPageState = {
        index: 0,
    };

    handleChangeIndex(index) {
        this.setState({
            index: index,
        });
    }

    renderPanes() {
        const { panes } = this.props;
        let widgets = panes.map((pane, index) => {
            return (
                <Grid.Column width={8}>
                    <div style={{ marginBottom: '15px' }}>
                        {pane.render()}
                    </div>
                </Grid.Column>
            )
        })
        return (
            <Grid.Row>
                {widgets}
            </Grid.Row>
        );
    }

    render() {
        const { Gatekeeper, panes, primaryContact, item, children } = this.props;

        return (
            <DesktopSkeleton>
                <Grid padded>
                    <Grid.Row columns={"equal"}>
                        <Grid.Column>
                            <CardGroup itemsPerRow={2}>
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>
                                            <Icon name="building" /> {item?.name}
                                        </Card.Header>
                                        <Card.Meta>
                                            <span className="capitalize">Stage: {item?.stage || 'N/A'}</span>
                                        </Card.Meta>
                                    </Card.Content>
                                    <Card.Content extra textAlign="right">
                                        <Button color='green' icon='edit' content='Edit' as='a' href={`/accounts/${item?.id}/edit`} />
                                    </Card.Content>
                                </Card>
                                <Gatekeeper name="account-contact-view">
                                    <>
                                        {primaryContact && (
                                            <Card fluid>
                                                <Card.Content>
                                                    <Card.Header>
                                                        <Icon name="user circle" /> {primaryContact.firstName} {primaryContact.lastName}
                                                    </Card.Header>
                                                    <Card.Meta>{primaryContact.title}</Card.Meta>
                                                    <Divider />
                                                    <Card.Description>
                                                        <Icon name="phone" /> {primaryContact.telephoneNumber}
                                                        <br />
                                                        <Icon name="envelope" />
                                                        {primaryContact.email}
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        )}
                                    </>
                                </Gatekeeper>
                            </CardGroup>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <h4>Overview</h4>
                <Divider />
                <Grid columns={2} stackable padded>
                    {this.renderPanes()}
                </Grid>
            </DesktopSkeleton>
        );
    }
}
