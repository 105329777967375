import React, { PureComponent } from 'react';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IGroup {
    name: string;
}

export interface IGroupEditPageProps extends IPageProps {
    item?: IGroup;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
}

export default class GroupEditPage extends PureComponent<IGroupEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <DesktopPage
                    name={item && item.name}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    Gatekeeper={Gatekeeper}
                />
            </Page>
        );
    }
}