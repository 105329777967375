import React, { PureComponent } from 'react';
import BookmarkPage from '../../controls/BookmarkPage';
import Breadcrumbs from '../../controls/Breadcrumbs/Breadcrumbs';
import { DesktopSkeleton, MobileSkeleton } from '../skeleton';
import './styles.css';

export interface IComponentProps {
    name?: string,
    children: any,
    breadcrumbLinks?: any,
    localizationService?: any,
    notInPane?: boolean,
    feature?: string;
    additional?: any,
}

export default class Component extends PureComponent<IComponentProps> {

    static defaultProps = {
        notInPane: true,
        feature: undefined,
    }

    translate(key: string): string {
        return this.props.localizationService && this.props.localizationService.translate(key);
    }

    render() {
        const { children, name, breadcrumbLinks, notInPane, feature, additional } = this.props;

        return (
            <>
                <DesktopSkeleton>
                    <div className={notInPane ? 'component-container' : 'component-container-compact'}>
                        <div className={notInPane ? 'component-information' : ''}>
                            {breadcrumbLinks && <Breadcrumbs links={breadcrumbLinks} />}
                            {name && <h3>{this.translate(name)}</h3>}
                            {additional}
                            {feature && <BookmarkPage feature={feature} />}
                        </div>
                        {children}
                    </div>
                </DesktopSkeleton>
                <MobileSkeleton>
                    <div style={{ margin: '5% 10%' }}>
                        {/* TO-DO revisit ASAP */}
                        {breadcrumbLinks && <Breadcrumbs links={breadcrumbLinks} />}
                    </div>
                    {/* {name && <h3>{this.translate(name)}</h3>} */}
                    {children}
                </MobileSkeleton>
            </>
        );
    }
}