import React, { PureComponent } from 'react';
import { Button, Icon, Table, Form, Checkbox } from 'semantic-ui-react';
import InlinePriceUpdate from './InlinePriceUpdate';
import { toast } from 'react-toastify';
import './styles.css';

export interface IProductRowProps {
    product: any;
    localizationService: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    quickSave: (data: any) => any;
    getDefinitionProducts: (data: any) => any;
    handleStockUpdate: (data: any) => any;
    updateSingleProductPrice: (data: any) => any;
}
export interface IProductRowState {
    rowOpen: boolean;
    caretState: string;
    products: any[];
}

export default class ProductRow extends PureComponent<
    IProductRowProps,
    IProductRowState
> {

    static defaultProps = {
        products: [],
    }

    state = {
        rowOpen: false,
        caretState: 'right',
        products: [],
    };

    quickSave(data: any) {
        try {
            if (this.props.quickSave) {
                this.props.quickSave(data);
                toast.success(this.translate('Product successfully updated.'));
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    singleUpdate(data: any[]) {
        if (this.props.updateSingleProductPrice) {
            try {
                this.props.updateSingleProductPrice(data);
                toast.success(this.translate('Product(s) successfully updated.'));
            } catch (e) {
                toast.error(e.getMessage());
            }
        }
    }

    async handleStockUpdate(productId: string, value: boolean) {
        try {
            if (this.props.handleStockUpdate) {
                await this.props.handleStockUpdate({
                    productId,
                    "type": "basic",
                    "data": {
                        "available": value,
                    }
                })
                toast.success(this.translate('Stock successfully updated.'));
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    async toggleRow() {
        let products = [];
        if (!this.state.rowOpen) {
            products = await this.fetchProducts();
            this.setState({
                products
            })
        }

        this.setState({
            rowOpen: !this.state.rowOpen,
            products,
        })
    }


    async fetchProducts() {
        if (this.props.getDefinitionProducts) {
            const { product } = this.props;
            let items = await this.props.getDefinitionProducts(product);
            return items;
        }
    }

    renderProducts() {
        const { products } = this.state;

        let items = products.map((product: any) => {
            return <Table.Row collapsing key={product.id}>
                <Table.Cell collapsing width={1}>
                    {product.sku}
                </Table.Cell>
                <Table.Cell collapsing width={2}>
                    <Form widths='equal'>
                        <InlinePriceUpdate key={product.id} defaultValue={product.price} name={product.id} item={product} onChange={(data) => { this.singleUpdate(data) }} />
                    </Form>
                </Table.Cell>
                <Table.Cell collapsing width={1}>
                    <Checkbox toggle defaultChecked={product.inventory} onChange={async (event, data) => await this.handleStockUpdate(product.id, data.checked!)} />
                </Table.Cell>
            </Table.Row>
        })

        return <Table columns={3} padded collapsing>
            <Table.Header>
                <Table.HeaderCell collapsing width={1}>
                    {this.translate('SKU')}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing width={2}>
                    {this.translate('Price')}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing width={1}>
                    {this.translate('In Stock?')}
                </Table.HeaderCell>
            </Table.Header>
            {items}
        </Table>
    }

    render() {
        const { product, Gatekeeper } = this.props;

        return (
            <>
                <Table.Row onClick={(e) => this.handleDoubleClick(e, product)} key={product.id}>
                    <Gatekeeper name="inventory-management">
                        <Table.Cell collapsing>
                            <Icon
                                name={this.state.rowOpen ? `caret down` : `caret right`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.toggleRow()}
                            />
                        </Table.Cell>
                    </Gatekeeper>
                    <Table.Cell>
                        {product.seriesCode ? product.seriesCode + '. ' : ''}{product.title}
                    </Table.Cell>
                    <Table.Cell>
                        {product.status}
                    </Table.Cell>
                    <Table.Cell>{product.category}</Table.Cell>
                    <Table.Cell width={3}>
                        <Form>
                            <InlinePriceUpdate key={product.id} defaultValue={product.price} name={product.id} item={product} onChange={(data) => { this.quickSave(data) }} />
                        </Form>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            icon="eye"
                            color="blue"
                            as="a"
                            href={`/productdefinitions/${product.id}`}
                        />
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/productdefinitions/${product.id}/edit`}
                        />
                    </Table.Cell>
                </Table.Row>
                {this.state.rowOpen && this.renderProducts()}
            </>
        )
    }
}