import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../../LocalizedPage';
import Component from "../../../layouts/component";

export interface ICustomerOverviewPageProps {
    createRoutes?: Function;
    item: any;
    primaryContact: any;
    Gatekeeper: any;
    panes: any[];
    breadcrumbLevels: any;
}

export default class CustomerOverviewPage extends PureComponent<ICustomerOverviewPageProps> {
    render() {
        const { createRoutes, item, Gatekeeper, panes, primaryContact, breadcrumbLevels } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage item={item} Gatekeeper={Gatekeeper} panes={panes} primaryContact={primaryContact} />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
