import React, { PureComponent } from 'react';
import { LoadingSpinner } from '../../../controls';
import { ProductDefinitionForm } from '../../../forms/products';
import ProductForm from '../../../forms/products/ProductForm';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import './styles.css';

export interface IProductDesktopPageProps {
    title: string;
    language: string;
    content: any;
    sku: string;
    price: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps> {
    static defaultProps = {
        title: '',
        language: '',
        content: '',
        sku: '',
        price: '',
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="editor-container product-editor">
                        <div>
                            {/* <ReturnButton
                                href={'/products'}
                                itemType={'products'}
                            /> */}
                        </div>
                        <ProductForm
                            productId=''
                            title={this.props.title}
                            language={this.props.language}
                            content={this.props.content}
                            sku={this.props.sku}
                            price={this.props.price}
                            onSubmit={async (data) => this.props.onSave(data)}
                            onDelete={this.props.onDelete}
                            localizationService={this.props.localizationService}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
