import React, { PureComponent } from "react";
import Component from "../../layouts/component";
import LocalizedPage from "../LocalizedPage";
import { IPageProps } from "../Page/Page";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";

export interface IFeatures {
    name: string;
    active: boolean;
    key: string;
    permissions?: string[];
    resources?: string[];
}

export interface IFeaturesEditPageProps extends IPageProps {
    item?: IFeatures;
    resources?: any[];
    permissions?: any[];
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    toastConfig: any;
}

export default class FeaturesEditPage extends PureComponent<IFeaturesEditPageProps> {
    render() {
        const { createRoutes, item, resources, onChange, onSave, onDelete, Gatekeeper, toastConfig } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        name={item && item.name}
                        active={item && item.active}
                        featureKey={item && item.key}
                        permissions={item && item.permissions}
                        resources={resources}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        toastConfig={toastConfig}
                    />
                    <MobilePage
                        name={item && item.name}
                        active={item && item.active}
                        featureKey={item && item.key}
                        permissions={item && item.permissions}
                        resources={resources}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        toastConfig={toastConfig}
                    />
                </Component>
            </Page>
        );
    }
}
