import React, { PureComponent } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { LocalizationService } from '../../../services';
import CommonUtils from '../../../utilities/Common/CommonUtils';

const commonUtils = new CommonUtils();

export interface ITemporaryLanguagePickerProps {
    localizationService: any,
}

export default class TemporaryLanguagePicker extends PureComponent<ITemporaryLanguagePickerProps> {

    updateLanguage(value) {
        localStorage.setItem('lang', value);
        window.location.reload();
    }

    renderi18nDropdownItems() {
        const { localizationService } = this.props;

        let options = commonUtils.generateLanguagesMap();

        return <Dropdown
            button
            className='icon'
            floating
            labeled
            icon='world'
            options={options}
            text={localizationService.translate('Select Language')}
            onChange={(event, { value }) => this.updateLanguage(value)}
        />;
    }

    render() {
        return (
            this.renderi18nDropdownItems()
        )
    }
}