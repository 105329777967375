import React, { PureComponent } from 'react';
import { Button, Container, Header, Icon, Modal, Table, Grid, Form, FormGroup, Card, Message } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import ProductModalContent from './ProductModalContent';
import { DimmedModal } from '../../../layouts/modals';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import StoreSettingsTemplate from '../../storeconfiguration/StoreSettingsTemplate';
import InlinePriceUpdate from './InlinePriceUpdate';
import ProductDefinitionModalForm from '../../../forms/products/ProductDefinitionModalForm';
import { toast } from 'react-toastify';
import Media from "react-media";
import ProductRow from './ProductRow';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    products?: any;
    catalogs?: any;
    attributes?: any;
    taxes?: any;
    brand?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    categories: any;
    isFetching: boolean;
    productsHaveSlugs: boolean;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
    handleChange: (data: any) => any;
    handleSave: ({ key, value }: any) => any;
    handlePublishAll: () => any;
    getDefinitionProducts: (data: any) => any;
    handleStockUpdate: (data: any) => any;
    updateSingleProductPrice: (data: any) => any;
    handleGenerateSlugs: () => any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
    productFormModal: boolean;
    products: any[];
    filteredNumberResults: number;
    searchInputHasValue: boolean;
    searchValue: string;
    isPublishing: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
        productFormModal: false,
        products: [],
        filteredNumberResults: 0,
        searchInputHasValue: false,
        searchValue: '',
        isPublishing: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    componentDidMount() {
        this.setState({
            products: this.props.items ? this.props.items : [],
            filteredNumberResults: this.props.items ? this.props.items.length : 0,
        })
    }

    componentDidUpdate(prevProps: Readonly<IDesktopPageProps>, prevState: Readonly<IDesktopPageState>, snapshot?: any): void {
        const searchParams = new URLSearchParams(document.location.search).get('action');
        if (prevProps !== this.props) {
            this.setState({
                products: this.props.items ? this.props.items : [],
                filteredNumberResults: this.props.items ? this.props.items.length : 0,
                productFormModal: !this.props.isFetching && searchParams == 'create',
            })
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    async duplicate(data: any) {
        delete data.id;
        try {
            await this.props.handleSave(data);
            toast.success(this.translate('Product successfully duplicated.'));
        } catch (e) {
            toast.error(e.message);
        }
    }

    async publishAll() {
        try {
            if (this.props.handlePublishAll) {
                this.setState({ isPublishing: true })
                await this.props.handlePublishAll();
            }
        } catch (e) {
            this.setState({ isPublishing: false })
            toast.error(e.message);
        }
    }

    async allSlugsPublish() {
        try {
            if (this.props.handleGenerateSlugs) {
                this.setState({ isPublishing: true })
                await this.props.handleGenerateSlugs();
            }
        } catch (e) {
            this.setState({ isPublishing: false })
            toast.error(e.message);
        }
    }

    quickSave(data: any) {
        try {
            if (this.props.quickSave) {
                this.props.quickSave(data);
                toast.success(this.translate('Product successfully updated.'));
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    openProductFormModal() {
        this.setState(({ productFormModal }) => ({ productFormModal: !productFormModal }));
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header={this.translate('Nothing to see here!')}
                content={this.translate('Add your first product!')}
                actions={
                    [this.translate('Not now'),
                    { key: 'done', content: this.translate('Create product'), positive: true, href: '/products/create' }
                    ]}
            />;
        }
        return;
    }

    private renderProductDefinitionHeaders() {
        const { Gatekeeper } = this.props;

        return (
            <Table.Header>
                <Table.Row>
                    <Gatekeeper name="inventory-management">
                        <Table.HeaderCell collapsing />
                    </Gatekeeper>
                    <Table.HeaderCell>{this.translate('Name')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Status')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Category')}</Table.HeaderCell>
                    <Table.HeaderCell width={3}>{this.translate('Price')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderProductDefinitionBody() {
        const { products } = this.state;
        const {
            localizationService,
            onDoubleClick,
            quickSave,
            getDefinitionProducts,
            handleStockUpdate,
            updateSingleProductPrice,
            Gatekeeper,
        } = this.props;

        const productsTable = products.map((product: any) => (
            <ProductRow
                product={product}
                localizationService={localizationService}
                onDoubleClick={onDoubleClick}
                quickSave={quickSave}
                getDefinitionProducts={getDefinitionProducts}
                handleStockUpdate={handleStockUpdate}
                Gatekeeper={Gatekeeper}
                updateSingleProductPrice={updateSingleProductPrice}
            />
        ));
        return (
            <Table.Body>
                {!productsTable.length ? this.renderNoContentMessage() : productsTable}
            </Table.Body>
        );
    }

    renderProductDefinitionCards() {
        const { products } = this.state;
        const productsTable = products.map((product: any) => (
            <Card fluid>
                <Card.Content>
                    <Card.Header>{product.title}</Card.Header>
                    <Card.Meta>{product.category}</Card.Meta>
                    <Card.Description>
                        <Form>
                            <InlinePriceUpdate
                                key={product.id}
                                defaultValue={product.price}
                                name={product.id}
                                item={product}
                                onChange={(data) => { this.quickSave(data) }}
                            />
                        </Form>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className='ui two buttons'>
                        <Button
                            icon="eye"
                            color="blue"
                            as="a"
                            href={`/productdefinitions/${product.id}`}
                        />
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/productdefinitions/${product.id}/edit`}
                        />
                    </div>
                </Card.Content>
            </Card>
        ));
        return (
            <Media queries={{
                xsmall: "(max-width: 639px)",
                small: "(min-width: 640px)",
            }}>
                {matches => (
                    <>
                        {matches.xsmall &&
                            <Card.Group itemsPerRow={1}>
                                {!productsTable.length ? this.renderNoContentMessage() : productsTable}
                            </Card.Group>
                        }
                        {matches.small &&
                            <Card.Group itemsPerRow={2}>
                                {!productsTable.length ? this.renderNoContentMessage() : productsTable}
                            </Card.Group>
                        }
                    </>
                )}
            </Media>
        );
    }

    private renderInformationalModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>{this.translate('About Product Statuses')}</Modal.Header>
                <Modal.Content>
                    <ProductModalContent localizationService={this.props.localizationService} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        {this.translate('Got it!')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private renderProductFormModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.toggleProductModalForm()}
                dimmer
                open={modalOpen}
                size="large"
            >
                <Modal.Header>{this.translate('Create Product')}</Modal.Header>
                <ProductDefinitionModalForm
                    onSubmit={this.props.handleSave}
                    localizationService={this.props.localizationService}
                    onCancel={() => this.toggleProductModalForm()}
                    brand={this.props.brand && this.props.brand}
                />
            </Modal>
        );
    }

    private renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No products!')}</Header>
            </Container>
        )
    }

    filterProducts(name: string) {
        const { items } = this.props;
        if (!items) { return; }
        const products = items.filter(function (product) { return product.title.toLowerCase().indexOf(name.toLowerCase()) >= 0; });
        this.setState({ products, filteredNumberResults: products.length, searchInputHasValue: name.length > 0, searchValue: name });
    }

    resetTextInput() {
        this.filterProducts('');
    }

    private renderSearch() {
        return <>
            <Media queries={{
                medium: "(max-width: 1023px)",
                large: "(min-width: 1024px)",
            }}>
                {matches => (
                    <>
                        {matches.medium &&
                            <Form.Input fluid placeholder={this.translate("Search product by name")} value={this.state.searchValue} onChange={(event) => this.filterProducts(event.target.value)} type='text' icon={(e) => {
                                if (this.state.searchInputHasValue) {
                                    return <Icon name='delete' link onClick={() => { this.resetTextInput(); }} />;
                                }
                                return <Icon name='search' />;
                            }} />}
                        {matches.large &&
                            <Form.Input placeholder={this.translate("Search product by name")} value={this.state.searchValue} onChange={(event) => this.filterProducts(event.target.value)} type='text' icon={(e) => {
                                if (this.state.searchInputHasValue) {
                                    return <Icon name='delete' link onClick={() => { this.resetTextInput(); }} />;
                                }
                                return <Icon name='search' />;
                            }} />}
                    </>
                )}
            </Media>
            <small className="text-muted">
                {this.translate('Now showing')} {this.state.filteredNumberResults} {this.translate(commonUtils.plur('result', 'results', this.state.filteredNumberResults))}{this.state.searchInputHasValue && ` ${this.translate('for')} ${this.state.searchValue}`}.
            </small>
        </>
    }

    private toggleProductModalForm(): void {
        return this.setState({ productFormModal: !this.state.productFormModal });
    }

    private renderProducts() {
        const Gatekeeper = this.props.Gatekeeper;
        return <Media queries={{
            medium: "(max-width: 1023px)",
            large: "(min-width: 1024px)",
        }}>
            {matches => (
                <>
                    {matches.medium &&
                        <div style={{ padding: '0px 15px', height: '60vh' }} className="mobile-item-list-container">
                            {this.renderProductDefinitionCards()}
                        </div>}
                    {matches.large &&
                        <ScrollableTable striped selectable className="ten">
                            {this.renderProductDefinitionHeaders()}
                            {this.renderProductDefinitionBody()}
                            <Gatekeeper name="productdefinition-view">
                                <Table.Footer fullWidth>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell colSpan='4'>
                                        {/* <Button color="purple" size="small" floated="right" icon onClick={async () => await this.publishAll()} disabled={this.state.isPublishing}><Icon loading={this.state.isPublishing} name={this.state.isPublishing ? 'spinner' : 'save'} />{this.state.isPublishing ? '' : 'Publish All Products'}</Button> */}
                                        <Button color="green" size="small" floated="right" icon onClick={() => this.toggleProductModalForm()}><Icon name='plus' />{this.translate('Create Product')}</Button>
                                    </Table.HeaderCell>
                                </Table.Footer>
                            </Gatekeeper>
                        </ScrollableTable>}
                </>
            )}
        </Media>;
    }

    render() {
        const { isFetching } = this.props;
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <StoreSettingsTemplate localizationService={this.props.localizationService} onChange={this.props.onPathChange} activeItem='products'>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    {!isFetching && <>
                        {this.renderInformationalModal(this.state.modalOpen)}
                        {this.renderProductFormModal(this.state.productFormModal)}
                        <Header as='h3' content={this.translate('All Products')}></Header>
                        <Grid columns={'1'}>
                            <Grid.Column>
                                <FormGroup>
                                    {this.renderSearch()}
                                </FormGroup>
                            </Grid.Column>
                        </Grid>
                        {!this.props.productsHaveSlugs &&
                            <Message warning className='regular-message'>
                                <Message.Header><Icon name='warning' /> Warning</Message.Header>
                                <p>
                                    Your products are missing some metadata. Without it, on the next rebuild, you may not get expected results.
                                </p>
                                <Button content='Update my products' onClick={async () => await this.allSlugsPublish()} disabled={this.state.isPublishing}><Icon loading={this.state.isPublishing} name={this.state.isPublishing ? 'spinner' : 'save'} />{this.state.isPublishing ? '' : 'Update my products'}</Button>
                            </Message>
                        }
                        <Grid columns={1} stackable style={{ marginBottom: '40px' }}>
                            <Grid.Row>
                                <Grid.Column>
                                    {this.renderProducts()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>}
                </Gatekeeper>
            </StoreSettingsTemplate>
        );
    }
}
