import React from "react";
import { toast } from "react-toastify";
import { Form, Button, Divider } from "semantic-ui-react";
import { DeleteButton, ReturnButton } from "../../../controls";
import ToggleInput from "../../../controls/ToggleInput";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import FeaturePermissionsInput from "./FeaturePermissionsInput";
import "./style.css";

export interface IFeaturesFormProps extends IStateAwareFormProps {
    name: string;
    active: boolean;
    featureKey: string;
    resources?: any[];
    permissions?: any[];
    toastConfig?: any;
}

export default class FeaturesForm extends StateAwareForm<IFeaturesFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any,
        onDelete: () => {},
        status: "draft",
        readOnly: false,
        permissions: [],
        toastConfig: {},
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
            active: this.props.active,
            featureKey: this.props.featureKey,
            resources: this.props.resources,
            permissions: this.props.permissions,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
                active: this.props.active,
                key: this.props.featureKey,
                resources: this.props.resources,
                permissions: this.props.permissions,
            });
        }
    }

    private validateForm() {
        const permissionsArray = this.state['permissions'] || [];
        permissionsArray.reduceRight((accuator, permission, index, object) => {
            if (permission.verbs.length === 0) {
                object.splice(index, 1);
            }
        }, []);

        if (permissionsArray.length === 0) {
            throw new Error('Could not create or update the feature. Please assign permissions to it.');
        }
    }

    async submit() {
        try {
            this.validateForm();
            await this.handleSubmit();
            toast.success('Feature successfully updated.', this.props.toastConfig);
        } catch (e) {
            toast.error(e.message, this.props.toastConfig);
        }
    }

    render() {
        const { error, loading } = this.state;
        const { children } = this.props;
        return (
            <Form onSubmit={() => this.submit()} error={!!error}>
                <Button.Group floated="left">
                    <ReturnButton
                        href={`/features`}
                        itemType={'features'}
                    />
                </Button.Group>
                <Button.Group floated="right" className='form-right-toolbar-container'>
                    <Button disabled={loading}>Save</Button>
                    {this.props.name && (
                        <DeleteButton
                            itemType="feature"
                            itemName={this.props.name}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </Button.Group>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Features"
                />

                <Form.Group fluid>
                    <Form.Input
                        className="form-fields"
                        width={"8"}
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Input
                        className="form-fields"
                        fluid
                        width={'7'}
                        label={"Key"}
                        placeholder="key"
                        defaultValue={this.props.featureKey}
                        name="key"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <ToggleInput
                        label={"Active"}
                        defaultValue={this.props.active}
                        name="active"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>
                <br />
                <Divider />
                <FeaturePermissionsInput
                    name='permissions'
                    value={this.props.permissions}
                    handleChange={({ name, value }) => this.handleChange({ name, value })}
                    resources={this.props.resources} />
            </Form>
        );
    }
}
