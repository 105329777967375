import { Buffer } from 'buffer';

export default class ThemingUtils {

    toHex(n) { // 4byte
        const buff = Buffer.alloc(4);
        buff.writeInt32BE(n);
        return buff.toString('hex').slice(-2);
    }

    hexToRGB(H: string) {
        if (H.length === 6 && !H.startsWith(`#`)) {
            H = `#${H}`
        }

        let r = `0`
        let g = `0`
        let b = `0`
        if (H.length === 4) {
            r = `0x${H[1]}${H[1]}`
            g = `0x${H[2]}${H[2]}`
            b = `0x${H[3]}${H[3]}`
        } else if (H.length === 7) {
            r = `0x${H[1]}${H[2]}`
            g = `0x${H[3]}${H[4]}`
            b = `0x${H[5]}${H[6]}`
        }

        return { r, g, b }
    }

    hexToHSL(H: string) {
        if (H.length === 6 && !H.startsWith(`#`)) {
            H = `#${H}`
        }

        // Convert hex to RGB first
        let { r, g, b } = this.hexToRGB(H)
        // Then to HSL
        let red = Number(r) / 255;
        let green = Number(g) / 255;
        let blue = Number(b) / 255;
        const cmin = Math.min(red, green, blue)
        const cmax = Math.max(red, green, blue)
        const delta = cmax - cmin
        let h = 0
        let s = 0
        let l = 0

        if (delta === 0) h = 0
        else if (cmax === red) h = ((green - blue) / delta) % 6
        else if (cmax === green) h = (blue - red) / delta + 2
        else h = (red - green) / delta + 4

        h = Math.round(h * 60)

        if (h < 0) h += 360

        l = (cmax + cmin) / 2
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
        s = +(s * 100).toFixed(1)
        l = +(l * 100).toFixed(1)

        //   return `hsl(${h},${s}%,${l}%)`;
        return { h, s, l }
    }

    createSaturationScale(tweak: number = 0) {
        return [
            { key: 0, tweak: Math.round(tweak * 1.15) },
            { key: 50, tweak: Math.round(tweak * 1.125) },
            { key: 100, tweak: Math.round(tweak) },
            { key: 200, tweak: Math.round(tweak * 0.75) },
            { key: 300, tweak: Math.round(tweak * 0.5) },
            { key: 400, tweak: Math.round(tweak * 0.25) },
            { key: 500, tweak: 0 },
            { key: 600, tweak: Math.round(tweak * 0.25) },
            { key: 700, tweak: Math.round(tweak * 0.5) },
            { key: 800, tweak: Math.round(tweak * 0.75) },
            { key: 900, tweak: Math.round(tweak) },
            { key: 1000, tweak: Math.round(tweak) * 1.25 },
        ]
    }

    createHueScale(tweak: number = 0) {
        return [
            { key: 0, tweak: tweak ? tweak * 4 + tweak : 0 },
            { key: 50, tweak: tweak ? tweak * 3.5 + tweak : 0 },
            { key: 100, tweak: tweak ? tweak * 3 + tweak : 0 },
            { key: 200, tweak: tweak ? tweak * 2 + tweak : 0 },
            { key: 300, tweak: tweak ? tweak * 1 + tweak : 0 },
            { key: 400, tweak: tweak ? tweak + 0 : 0 },
            { key: 500, tweak: 0 },
            { key: 600, tweak: tweak || 0 },
            { key: 700, tweak: tweak ? tweak * 1 + tweak : 0 },
            { key: 800, tweak: tweak ? tweak * 2 + tweak : 0 },
            { key: 900, tweak: tweak ? tweak * 3 + tweak : 0 },
            { key: 1000, tweak: tweak ? tweak * 4 + tweak : 0 },
        ]
    }

    luminanceFromRGB(r: number, g: number, b: number) {
        // Formula from WCAG 2.0
        const [R, G, B] = [r, g, b].map(function (c) {
            c /= 255 // to 0-1 range
            return c < 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
        })
        return 21.26 * R + 71.52 * G + 7.22 * B
    }

    luminanceFromHex(H: string) {
        let rgb = this.hexToRGB(H);
        return this.round(this.luminanceFromRGB(Number(rgb.r), Number(rgb.g), Number(rgb.b)), 2);
    }

    round(value: number, precision: number = 0) {
        const multiplier = Math.pow(10, precision)
        return Math.round(value * multiplier) / multiplier
    }

    createDistributionValues(min: number, max: number, lightness: number) {
        // A `0` swatch (lightest color) would have this lightness
        const maxLightness = max ?? 100
        const maxStep = this.round((maxLightness - lightness) / 5, 2)

        // A `1000` swatch (darkest color) would have this lightness
        const minLightness = min ?? 0
        const minStep = this.round((lightness - minLightness) / 5, 2)

        const values = [
            { key: 0, tweak: Math.round(lightness + maxStep * 5) }, // Closest to 100, lightest colour
            { key: 50, tweak: Math.round(lightness + maxStep * 4.5) },
            { key: 100, tweak: Math.round(lightness + maxStep * 4) },
            { key: 200, tweak: Math.round(lightness + maxStep * 3) },
            { key: 300, tweak: Math.round(lightness + maxStep * 2) },
            { key: 400, tweak: Math.round(lightness + maxStep * 1) },
            { key: 500, tweak: Math.round(lightness) }, // Lightness of original colour
            { key: 600, tweak: Math.round(lightness - minStep * 1) },
            { key: 700, tweak: Math.round(lightness - minStep * 2) },
            { key: 800, tweak: Math.round(lightness - minStep * 3) },
            { key: 900, tweak: Math.round(lightness - minStep * 4) },
            { key: 1000, tweak: Math.round(lightness - minStep * 5) }, // Closest to 0, darkest colour
        ]

        // Each 'tweak' value must be between 0 and 100
        const safeValues = values.map((value) => ({
            ...values,
            tweak: Math.min(Math.max(value.tweak, 0), 100),
        }))

        return safeValues
    }

    HSLtoRGB(h: number, s: number, l: number) {
        s /= 100
        l /= 100

        const c = (1 - Math.abs(2 * l - 1)) * s
        const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
        const m = l - c / 2
        let r = 0
        let g = 0
        let b = 0

        if (h >= 0 && h < 60) {
            r = c
            g = x
            b = 0
        } else if (h >= 60 && h < 120) {
            r = x
            g = c
            b = 0
        } else if (h >= 120 && h < 180) {
            r = 0
            g = c
            b = x
        } else if (h >= 180 && h < 240) {
            r = 0
            g = x
            b = c
        } else if (h >= 240 && h < 300) {
            r = x
            g = 0
            b = c
        } else if (h >= 300 && h < 360) {
            r = c
            g = 0
            b = x
        }

        return {
            r: Math.round((r + m) * 255),
            g: Math.round((g + m) * 255),
            b: Math.round((b + m) * 255),
        }
    }

    lightnessFromHSLum(H: number, S: number, Lum: number) {
        const vals = {}
        for (let L = 99; L >= 0; L--) {
            let hsl = this.HSLtoRGB(H, S, L);
            vals[L] = Math.abs(Lum - this.luminanceFromRGB(hsl.r, hsl.g, hsl.b))
        }

        // Run through all these and find the closest to 0
        let lowestDiff = 100
        let newL = 100
        for (let i = Object.keys(vals).length - 1; i >= 0; i--) {
            if (vals[i] < lowestDiff) {
                newL = i
                lowestDiff = vals[i]
            }
        }

        return newL
    }

    createSwatches(value) {

        // Tweaks may be passed in, otherwise use defaults
        const useLightness = true;
        const h = 0;
        const s = 0;
        const lMin = 0;
        const lMax = 100;

        // Create hue and saturation scales based on tweaks
        const hueScale = this.createHueScale(h)
        const saturationScale = this.createSaturationScale(s)

        // Get the base hex's H/S/L values
        const { h: valueH, s: valueS, l: valueL } = this.hexToHSL(value)

        // Create lightness scales based on tweak + lightness/luminance of current value
        const lightnessValue = useLightness ? valueL : this.luminanceFromHex(value)
        const distributionScale = this.createDistributionValues(lMin, lMax, lightnessValue)

        let swatches = hueScale.map(({ key }, i) => {
            // Hue value must be between 0-360
            // todo: fix this inside the function
            let newH = valueH + hueScale[i].tweak
            newH = newH < 0 ? 360 + newH - 1 : newH
            newH = newH > 720 ? newH - 360 : newH
            newH = newH > 360 ? newH - 360 : newH

            // Saturation must be between 0-100
            let newS = valueS + saturationScale[i].tweak
            newS = newS > 100 ? 100 : newS

            const newL = useLightness
                ? distributionScale[i].tweak
                : this.lightnessFromHSLum(newH, newS, distributionScale[i].tweak)

            const newHex = this.HSLToHex(newH, newS, newL)
            const paletteI = key

            return {
                // stop: paletteI,
                // // Sometimes the initial value is changed slightly during conversion,
                // // overriding that with the original value
                // hex: paletteI === 500 ? `${value.toUpperCase()}` : newHex.toUpperCase(),
                // // Used in graphs
                // h: newH,
                // hScale: hueScale[i].tweak,
                // s: newS,
                // sScale: saturationScale[i].tweak,
                // l: newL,
                [paletteI]: paletteI === 500 ? `${value.toUpperCase()}` : newHex.toUpperCase(),
            }
        })

        swatches = Object.assign({}, ...swatches);

        return swatches
    }

    HSLToHex(h, s, l) {
        let { r, g, b } = this.HSLtoRGB(h, s, l)

        // Having obtained RGB, convert channels to hex
        let red = this.toHex(r)
        let green = this.toHex(g)
        let blue = this.toHex(b)

        return `#${red}${green}${blue}`
    }
}