import React, { PureComponent } from "react";
import { Menu, Dropdown, SemanticICONS } from "semantic-ui-react";

export interface INavigationHeader {
    key: string;
    title: string;
    icon: SemanticICONS;
    path?: string;
    disabled?: boolean;
}

export default class NavigationExtra extends PureComponent {
    renderDropdownItems(options) {
        let items;
        items =
            options &&
            options.map(
                ({ title, icon = "", path = "/", disabled = false }) =>
                    title && (
                        <Dropdown.Item
                            as="a"
                            href={path}
                            icon={icon}
                            text={title}
                            disabled={disabled}
                        />
                    )
            );
        return items;
    }

    render() {
        const options = [
            {
                path: "/inbox",
                icon: "inbox",
                title: "Inbox",
                key: "inbox",
                disabled: false,
            },
            // {
            //     path: "/settings",
            //     icon: "cog",
            //     title: "Settings",
            //     key: "settings",
            //     disabled: false,
            // },
            {
                path: "/logout",
                icon: "logout",
                title: "Logout",
                key: "logout",
                disabled: false,
            },
        ];

        return (
            <Menu.Menu position="right" horizontal="true">
                <Dropdown
                    item
                    icon={"caret down"}
                    style={{ margin: "auto 0" }}
                >
                    <Dropdown.Menu>{this.renderDropdownItems(options)}</Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        );
    }
}
