import React, { PureComponent } from 'react';
import { Button, ButtonProps, Header, Icon, Message, Modal } from 'semantic-ui-react';

export interface IDeleteButtonProps extends ButtonProps {
    handleOnClick?: () => void;
    itemName: string;
    itemType: string;
    serviceDisruption?: boolean,
}

export default class DeleteButton extends PureComponent<IDeleteButtonProps> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        itemName: 'Resource',
        itemType: 'Resource Type',
        handleOnClick: () => { },
        serviceDisruption: false,
    };

    setOpen(e, modalOpen) {
        e.preventDefault();
        this.setState({ modalOpen });
    }

    handleOnClick(e) {
        e.preventDefault();
        this.props.handleOnClick && this.props.handleOnClick();
    }

    triggerModal() {
        const capitalizedItemType =
            this.props.itemType.charAt(0).toUpperCase() +
            this.props.itemType.slice(1);
        return (
            <Modal
                onClose={(e) => this.setOpen(e, false)}
                onOpen={(e) => this.setOpen(e, true)}
                open={this.state.modalOpen}
                size="small"
            >
                <Header>
                    Delete {capitalizedItemType} "{this.props.itemName}"
                </Header>
                <Modal.Content>
                    <p>
                        Once you delete this {this.props.itemType}, you will not
                        be able to retrieve it.
                    </p>
                    {
                        this.props.serviceDisruption &&
                        <Message error>
                            Deleting this {this.props.itemType} may disrupt application service. Make sure this {this.props.itemType} is no longer in use.
                        </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        color="red"
                        onClick={(e) => this.setOpen(e, false)}
                    >
                        <Icon name="remove" /> No
                    </Button>
                    <Button
                        color="green"
                        onClick={() =>
                            this.props?.handleOnClick &&
                            this.props.handleOnClick()
                        }
                    >
                        <Icon name="checkmark" /> Yes, I understand
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        return (
            <>
                {this.triggerModal()}
                <Button
                    icon={this.props.icon}
                    content={this.props.content}
                    color={this.props.color || 'red'}
                    floated="right"
                    onClick={(e) => this.setOpen(e, true)}
                />
            </>
        );
    }
}
