import { Router } from 'tramway-core-router';
import { DependencyResolver } from 'tramway-core-dependency-injector';
import ReactRouterStrategy from 'tramway-router-react-strategy';

export default {
    "router": {
        "class": Router,
        "constructor": [
            { "type": "parameter", "key": "routes" },
            { "type": "service", "key": "react-router-strategy" },
            DependencyResolver,
        ],
    },
    "react-router-strategy": {
        "class": ReactRouterStrategy,
        "constructor": [],
        "functions": [
            {
                "function": "setBrowserRouterSettings",
                "args": [{ "type": "parameter", "key": "routerOptions" }]
            }
        ]
    }
}
