export interface IApiProviderRequestOptions {
    protocol?: string;
    headers?: any;
    port?: number;
    host?: string;
    query?: any;
    path?: string;
    pathIsAbsolute?: boolean;
}

export interface IApiProviderParams {
    host?: string;
    port?: number;
    scheme?: string;
    headers?: any;
    path?: string;
    basePath?: string;
}

export class ApiError extends Error {
    public statusCode?: number;
    public statusMessage?: string;
    public item?: any;
}

export default class ApiProvider {
    /**
     * 
     * @param {Object} params 
     * @property {string} host
     * @property {number} port
     * @property {string} [scheme = "http"]
     */
    constructor(protected params: IApiProviderParams = {}) {

    }

    /**
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async get(path: string, options: IApiProviderRequestOptions = {}) {
        const { query, ...params } = options;

        if (query) {
            path = `${path}${this.buildQueryString(query)}`;
        }

        return await this.sendRequest('get', { path, ...params });
    }

    /**
     * @param {Object} body
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async post(body: any, path?: string, options: IApiProviderRequestOptions = {}) {
        const { query, ...params } = options;
        
        if (query) {
            path = `${path}${this.buildQueryString(query)}`;
        }

        return await this.sendRequest('post', <any>{ path, body, ...options });
    }

    /**
     * @param {Object} body
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async put(body: any, path?: string, options: IApiProviderRequestOptions = {}) {
        return await this.sendRequest('put', <any>{ path, body, ...options });
    }

    /**
     * @param {Object} body
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async patch(body: any, path?: string, options: IApiProviderRequestOptions = {}) {
        return await this.sendRequest('patch', <any>{ path, body, ...options });
    }

    /**
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async delete(path: string, options: IApiProviderRequestOptions = {}) {
        return await this.sendRequest('delete', { path, ...options });
    }

    // Some HTTP methods are not supported by browsers. We tunnel them with X-HTTP-Method-Override header in this case: https://www.hanselman.com/blog/http-put-or-delete-not-allowed-use-xhttpmethodoverride-for-your-rest-service-with-aspnet-web-api
    /**
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async link(path: string, options: IApiProviderRequestOptions = {}) {
        const { query, ...params } = options;

        if (query) {
            path = `${path}${this.buildQueryString(query)}`;
        }

        if (!params?.headers) {
            params.headers = {};
        }

        params.headers['X-HTTP-Method-Override'] = 'LINK';

        return await this.sendRequest('post', { path, ...params });
    }

    /**
     * @param {string} path - The resource of the given API
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Object}
     * @throws {Error}
     * 
     * @async
     */
    async unlink(path: string, options: IApiProviderRequestOptions = {}) {
        const { query, ...params } = options;

        if (query) {
            path = `${path}${this.buildQueryString(query)}`;
        }

        if (!params?.headers) {
            params.headers = {};
        }

        params.headers['X-HTTP-Method-Override'] = 'UNLINK';

        return await this.sendRequest('post', { path, ...params });
    }

    /**
     * @param {string} method - The HTTP method to use
     * @param {Object} options - Options object that complies to `http` module requirements
     * @property {string} options.protocol - The schema of the request ('http' or 'https')
     * @property {Object} options.headers - Hashmap of expected headers and values (optional)
     * @property {string} options.path - The resource of the given API
     * @property {number} options.port - The port the API serves on
     * @property {string} options.host - The host name or IP of the API
     * @returns {Promise}
     * @throws {Error}
     * 
     * @async
     */
    async sendRequest(method, options: any = {}) {
        const { headers: apiHeaders = {}, path: apiPath } = this.params;
        const { path = apiPath, headers: optionHeaders = {}, pathIsAbsolute, ...params } = options;

        let headers = Object.assign({}, apiHeaders, optionHeaders);

        let fetchOptions: any = {
            method,
            headers,
            withCredentials: true,
        }

        if (options.body && !(options.body instanceof FormData)) {
            fetchOptions.body = JSON.stringify(options.body);
        } else {
            fetchOptions.body = options.body;
        }

        let response = await fetch(pathIsAbsolute ? path : this.buildResourcePath(path), fetchOptions);

        let statusCode = response.status;
        let statusMessage = response.statusText;

        // @TODO: Re-enable once HATEOAS is back
        // if ('post' === method && 201 === statusCode) {
        //     return response.headers.get('Location');
        // }

        if (204 === statusCode) {
            return response;
        }

        if ([401, 404].includes(statusCode)) {
            let e = new ApiError(statusMessage);
            e.statusCode = statusCode;
            e.statusMessage = statusMessage;

            throw e;
        }

        let item;

        try {
            try {
                item = await response.json();
            } catch (e) {
                item = await response.text();
            }
        } catch (e) {
            item = null;
        }

        if (statusCode >= 400) {
            let message = (item && item.message) || statusMessage;
            let e = new ApiError(message);
            e.statusCode = statusCode;
            e.statusMessage = statusMessage;
            e.item = item;

            throw e;
        }

        return item;
    }

    buildResourcePath(resource = '') {
        const { host, port, scheme = 'https', basePath } = this.params;
        let portString = port ? `:${port}` : '';
        let domain = host ? `${scheme}://${host}${portString}` : '';
        domain = basePath ? `${domain}/${basePath}` : domain;

        return `${domain}/${resource}`;
    }

    buildQueryString(params = {}) {
        let queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
        return `?${queryString}`;
    }

    /**
     * 
     * @param {{}} params 
     */
    setParams(params = {}, consolidate = false) {
        this.params = consolidate ? Object.assign(this.params, params) : params;
        return this;
    }
}