import React, { PureComponent } from "react";
import {
    Segment,
    Header,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";

export interface ITextTemplateProps {
    title: string;
    content: string;
}

export default class TextTemplate extends PureComponent<
    ITextTemplateProps
> {
    render() {
        return (
            <Segment>
                <Header>{this.props.title}</Header>
                <ReactMarkdown children={this.props.content} />
            </Segment>
        )
    }
}