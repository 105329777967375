import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
// import MobilePage from './MobilePage';

export interface IProduct {
    title: string;
    language: string;
    content: any;
    category: string;
    price: string;
    brand: string;
}

export interface IProductAttributesEditPageProps extends IPageProps {
    item?: IProduct;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    createProperty?: (type: string) => void;
    deleteProductAttribute?: (id: any) => void;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    properties: any[];
    selections: any[];
    productDefinitionId: string;
}

export default class ProductAttributesEditPage extends PureComponent<IProductAttributesEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper, localizationService, breadcrumbLevels, createProperty, deleteProductAttribute, properties, selections, productDefinitionId } =
            this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        title={item && item.title}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        createProperty={createProperty}
                        deleteProductAttribute={deleteProductAttribute}
                        properties={properties}
                        selections={selections}
                        productDefinitionId={productDefinitionId}
                    />
                    {/* <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        brand={item && item.brand}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                    /> */}
                </Component>
            </Page>
        );
    }
}
