import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../LocalizedPage';
import { IPageProps } from '../Page/Page';

export interface IActivationPageProps extends IPageProps {
    onAcknowledge: Function;
    message?: string;
    activationSuccessful?: boolean;
}

export default class ActivationPage extends PureComponent<IActivationPageProps> {
    render() {
        let { children, createRoutes, onAcknowledge, message, activationSuccessful } = this.props;

        const Page = LocalizedPage();

        return (
            <Page createRoutes={createRoutes} headerHidden>
                <DesktopPage
                    onAcknowledge={onAcknowledge}
                    activationSuccessful={activationSuccessful}
                    message={message}
                >
                    {children}
                </DesktopPage>
                <MobilePage
                    onAcknowledge={onAcknowledge}
                    activationSuccessful={activationSuccessful}
                    message={message}
                >
                    {children}
                </MobilePage>
            </Page>
        );
    }
}