import React, { PureComponent } from 'react';
import Component from '../../layouts/component';
import LocalizedPage from '../LocalizedPage';
import { IPageProps } from '../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IApplicationTemplates {
    name: string;
    templateRepository: string;
    templateRepositoryOwner: string;
}

export interface IApplicationTemplatesEditPageProps extends IPageProps {
    item?: IApplicationTemplates;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    localizationService?: any;
    breadcrumbLevels?: any;
}

export default class ApplicationTemplatesEditPage extends PureComponent<IApplicationTemplatesEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper, breadcrumbLevels, localizationService } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        templateRepository={item && item.templateRepository}
                        name={item && item.name}
                        templateRepositoryOwner={item && item.templateRepositoryOwner}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                    {/* <MobilePage
                        title={item && item.title}
                        name={item && item.name}
                        language={item && item.language}
                        type={item && item.type}
                        content={item && item.content}
                        additional={item && item.additional}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    /> */}
                </Component>
            </Page>
        );
    }
}