import React, { PureComponent } from "react";
import { Button, Header, Message, Modal } from "semantic-ui-react";
import FeaturesLinkForm from "../../../../../forms/features/FeaturesLinkForm";

export interface IEditApiKeyFeaturesModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onToggleFeature: (data: any) => Promise<any>;

    features: any[];
    toastConfig: any;
    apiKeyFeatures?: any[];
    item: any;
}

export interface IEditApiKeyFeaturesModalState {
    state: string;
    item: any;
    query: any;
}

export default class EditApiKeyFeaturesModal extends PureComponent<IEditApiKeyFeaturesModalProps, IEditApiKeyFeaturesModalState> {
    state: IEditApiKeyFeaturesModalState = {
        state: 'features',
        item: undefined,
        query: {},
    };

    toggleModal() {
        this.props.toggleModal();
        this.setState({
            state: 'features',
            item: undefined,
            query: {},
        })
    }

    goToFeatureSelection() {
        return this.setState({
            state: 'features',
        })
    }

    renderState(state: string) {
        switch (state) {
            case 'features': return this.renderFeatureSelectionState();
        }

        return ""
    }

    renderFeatureSelectionState() {
        const { Gatekeeper, features, apiKeyFeatures, toastConfig } = this.props;
        return [
            <Modal.Content scrolling>
                <Gatekeeper name="license-management">
                    <Header>Associtate features to the api key</Header>
                    Toggling the features will associate them to the key in real time.
                    <Message warning>
                        The following features can be assigned to an API key. It is strongly recommended to keep the selections to a minimum to avoid any issues if the API key gets into the wrong hands.
                    </Message>
                    <div className='api-key-features-modal-table'>
                        <FeaturesLinkForm
                            features={features}
                            licenseFeatures={apiKeyFeatures}
                            onSubmit={(data) => this.props.onToggleFeature(data)}
                            toastConfig={toastConfig}
                        />
                    </div>
                </Gatekeeper>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ];
    }

    render() {
        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={this.props.modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Manage API key features</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}