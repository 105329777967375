import React from "react";
import {
    Form,
    Message,
    Button,
} from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface IApplicationFormProps extends IStateAwareFormProps {
    name?: string;
}

export default class ApplicationSearchForm extends StateAwareForm<IApplicationFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => {};
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
            });
        }
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Looking for the Application"
                />

                <Message
                    error
                    header="Could not find application"
                    content={error && error.toString()}
                />

                <Form.Group>
                    <Form.Input
                        width={"16"}
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>

                <Button.Group floated="right">
                    <Button disabled={loading}>Find</Button>
                </Button.Group>
            </Form>
        );
    }
}
