import React, { PureComponent } from 'react';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IResource {
    name: string;
    path: string;
    host: string;
}

export interface IResourceEditPageProps extends IPageProps {
    item?: IResource;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
}

export default class ResourceEditPage extends PureComponent<IResourceEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <DesktopPage
                    name={item && item.name}
                    path={item && item.path}
                    host={item && item.host}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    Gatekeeper={Gatekeeper}
                />
            </Page>
        );
    }
}