import React, { PureComponent } from "react";
import ReactMarkdown from "react-markdown";
import { Tab, Table, Button, Icon, Popup, Header } from "semantic-ui-react";
import CommonUtils from "../../../../../../utilities/Common/CommonUtils";
import { ScrollableTable } from "../../../../../layouts/tables";
import CreateSubscriptionModal from "./CreateSubscriptionModal";

const commonUtils = new CommonUtils();

export interface ISubscriptionsPaneProps {
    items: any[];
    id: any;
    Gatekeeper: any;
    onCreateAccountSubscription: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
    subscriptions: any[];
    onChooseSubscription: (data: any) => Promise<any>;
}

export interface ISubscriptionPaneState {
    modalOpen: boolean;
}

export default class SubscriptionsPane extends PureComponent<ISubscriptionsPaneProps, ISubscriptionPaneState> {
    state: ISubscriptionPaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell><ReactMarkdown children={item.description} /></Table.Cell>
                <Table.Cell>{commonUtils.renderDate(item.startDate)}</Table.Cell>
                <Table.Cell>{commonUtils.renderDate(item.endDate)}</Table.Cell>
                <Table.Cell>{item.state}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="subscription-view">
                        <Popup
                            trigger={
                                <Button
                                    icon="eye"
                                    color="blue"
                                    as="a"
                                    href={`/subscriptions/${item.subscriptionId}`}
                                />
                            }
                            content="View the details of the subscription."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="account-subscription-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="ban"
                                    color="red"
                                />
                            }
                            content="The Subscription can be canceled but will remain on the Account's records"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={2} textAlign={'center'}>
                    <Header>No subscriptions!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return !items.length ? this.renderNoContentMessage() : items;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
                <Gatekeeper name="account-subscription-management">
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='6'>
                                <Button color="green" size="small" floated="right" as='a' href={`/accounts/${this.props.id}/subscriptions/associate`} icon><Icon name='plus' />Add Subscription</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>

        );
    }
}