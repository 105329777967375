import React from 'react';
import SiteConfigurationPage from '../../components/pages/siteconfiguration';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { EntityId } from '../../repositories/Repository';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class WebsiteConfigurationController extends LocalizedController {
    private localizationService: LocalizationService;
    private applicationRepository: Repository;
    private securityService: SecurityService;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        component: undefined, //todo: pass through
        settings: undefined,
        provisions: [],
        domain: undefined,
        isFetching: true,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, applicationRepository, gatekeeperFactory] = props.args;

        this.localizationService = localizationService;
        this.securityService = securityService;
        this.applicationRepository = applicationRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        const applicationId = this.securityService.getApplicationId();
        let component, settings, provisions, domain;
        component = await this.getComponent(applicationId);
        if (component) {
            settings = await this.getSettings(applicationId, component.id);
            provisions = await this.getProvisions(applicationId);
            domain = await this.extractProvisionURL(applicationId);
        }
        return { component, settings, provisions, domain };
    }

    private async getComponent(applicationId: EntityId) {
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/components`)
        } catch (e) {
            console.error(e);
            return [];
        }

        //TODO: Temporary we hard code the component we want, but the component id should be passed as part of selecting the component from overview
        return items.find(item => 'Web' === item.name);
    }

    private async getSettings(applicationId: EntityId, componentId: EntityId) {
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/settings`)
        } catch (e) {
            console.error(e);
            return [];
        }

        return items.find(item => componentId === item.componentId);
    }

    private async getProvisions(applicationId: EntityId) {
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e);
            return [];
        }

        return items;
    }

    private async extractProvisionURL(applicationId: EntityId) {
        let items: any[] = [];
        let uri: any;

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/provisions`, {
                query: {
                    state: 'success',
                }
            })
            const [item] = items;

            if (!item) {
                return;
            }

            uri = item.actionResults.find((result) => "web.rollup" === result.name);
        } catch (e) {
            console.error(e);
            return;
        }

        return uri?.result?.aliases[0];
    }

    private async provision() {
        const applicationId = this.securityService.getApplicationId();

        try {
            await this.applicationRepository.createByPath({}, `applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e);
        }
    }

    async componentDidMount() {
        let { component, settings, provisions, domain } = await this.get();

        this.setState({
            component,
            settings,
            provisions,
            domain,
            isFetching: false,
        })
    }

    private async save(data: any) {
        const { settings } = this.state;

        if (!settings) {
            return;
        }

        let settingsValues = (settings as any)?.settings;

        if (data?.primaryPalette) {
            settingsValues.primaryPalette = data?.primaryPalette;
        }

        if (data?.textPalette) {
            settingsValues.textPalette = data?.textPalette;
        }

        (settings as any).settings = settingsValues;
        const applicationId = this.securityService.getApplicationId();

        try {
            await this.applicationRepository.updateByPath(settings, `applications/${applicationId}/settings/${(this.state.settings as any)?.id}`)
        } catch (e) {
            console.error(e);
        }
    }

    protected changeSettings(path) {
        this.router.redirect(`/website-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Website Settings'), value: undefined },
        ]
    }

    render() {

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <SiteConfigurationPage
                localizationService={this.localizationService}
                options={(this.state.component as any)?.options}
                settings={(this.state.settings as any)?.settings}
                onProvision={!this.state.provisions || !this.state.provisions.length ? () => this.provision() : undefined}
                onSave={(data) => this.save(data)}
                domain={this.state.domain}
                onPathChange={(path) => this.changeSettings(path)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                isFetching={this.state.isFetching}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}