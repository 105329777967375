import React, { PureComponent } from 'react';
import Message from '../Message';
import { IMessageProps } from '../Message/Message';

export interface IWarningMessageProps extends IMessageProps {

}

export default class WarningMessage extends PureComponent<IWarningMessageProps> {
    static defaultProps = {
        icon: 'warning sign',
        header: '',
        content: '',
        /**
         * Color can be either red, orange, yellow, olivegreen,
         *                  teal, blue, violet, purple, pink,
         *                  brown, grey, black.
         *  */
        color: 'yellow',
        size: 'small',
    }

    render() {
        const { children, icon, header, content, color, size, hidden, attached } = this.props;
        return (
            <Message
                icon={icon}
                header={header}
                content={content}
                color={color}
                size={size}
                className='message'
                hidden={hidden}
                attached={attached}
                children={children}
            />
        )
    }
}