import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup } from "semantic-ui-react";
import AssociateComponentModal from "./AssociateComponentModal";

export interface IComponentsPaneProps {
    items: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onLinkComponent: (data: any) => Promise<any>;
    onUnlinkComponent: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface IComponentPaneState {
    modalOpen: boolean;
}

export default class ComponentsPane extends PureComponent<IComponentsPaneProps, IComponentPaneState> {
    state: IComponentPaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="component-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/components/${item.id}/edit`}
                                />
                            }
                            content="Edit the component's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="archetype-component-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkComponent(item)}
                                />
                            }
                            content="Components can be associated to multiple archetypes. Unassociating the component will remove the component's access from this archetype but will not delete or alter the component itself"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateComponentModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onLinkComponent={async (data: any) => this.props.onLinkComponent(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
            />
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Tab.Pane attached={false}>
                <Gatekeeper name="archetype-component-management">
                    {this.renderModal(this.state.modalOpen, Gatekeeper)}
                    <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Associate Component</Button>
                </Gatekeeper>

                <Table>
                    <Table.Header>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.props.items?.map(item => this.renderItem(item, Gatekeeper))}
                    </Table.Body>
                </Table>
            </Tab.Pane>
        );
    }
}