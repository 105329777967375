import React, { PureComponent } from 'react';
import { Button, ButtonProps, Header, Icon, Message, Modal } from 'semantic-ui-react';

export interface ICancelButtonProps extends ButtonProps {
    handleOnClick?: () => void;
    itemName?: string;
    itemType: string;
    buttonName?: string;
}

export default class CancelButton extends PureComponent<ICancelButtonProps> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        itemName: 'Resource',
        itemType: 'Resource Type',
        handleOnClick: () => { console.log('cancelled') },
        buttonName: 'Cancel',
    };

    setOpen(e, modalOpen) {
        e.preventDefault();
        this.setState({ modalOpen });
    }

    handleOnClick(e) {
        e.preventDefault();
        this.props.handleOnClick && this.props.handleOnClick();
    }

    triggerModal() {
        const capitalizedItemType =
            this.props.itemType.charAt(0).toUpperCase() +
            this.props.itemType.slice(1);
        return (
            <Modal
                onClose={(e) => this.setOpen(e, false)}
                onOpen={(e) => this.setOpen(e, true)}
                open={this.state.modalOpen}
                size="small"
            >
                <Header>
                    Are you sure?
                </Header>
                <Modal.Content>
                    <p>
                        If you cancel, you will lose your data not be able to retrieve it.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={(e) => this.setOpen(e, false)}
                    >
                        No
                    </Button>
                    <Button
                        color="green"
                        onClick={() =>
                            this.props?.handleOnClick &&
                            this.props.handleOnClick()
                        }
                    >
                        <Icon name="checkmark" /> Yes, I understand
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        return (
            <>
                {this.triggerModal()}
                <Button
                    icon={this.props.icon}
                    content={this.props.content}
                    floated="right"
                    onClick={(e) => this.setOpen(e, true)}
                >
                    {this.props.buttonName}
                </Button>
            </>
        );
    }
}
