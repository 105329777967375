import React from 'react';
import { FeaturesPage } from '../../components/pages';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class FeaturesController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private delayCount = parseInt(process.env.REACT_APP_CLIENT_REFRESH_MS || '5000');

    state = {
        items: [],
        featureUpdated: false,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private configureToast() {
        return (
            {
                autoClose: this.delayCount,
            }
        );
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            return [];
        }

        return items;
    }

    private async toggleFeatureActive(data: any) {
        data['active'] = !data['active'];
        try {
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                ...data,
            });
        } catch (e) {
            throw e;
        }
        // refresh the list
        await this.fetchAndSortAllFeatures();
        this.setState({ featureUpdated: true });
        setTimeout(() => this.router.reload(), this.delayCount);
    }

    async componentDidMount() {
        await this.fetchAndSortAllFeatures();
    }

    private async fetchAndSortAllFeatures() {
        let items = await this.get();

        this.setState({
            items,
        });
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/features/${id}/edit`);
    }

    render() {
        const { items, featureUpdated } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <FeaturesPage
                items={items}
                
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                toggleFeatureActive={async (data: any) => this.toggleFeatureActive(data)}
                toastConfig={this.configureToast()}
                delayCount={this.delayCount}
                featureUpdated={featureUpdated}
            />
        )
    }
}