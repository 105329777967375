import React from "react";
import {
    Form,
    Table,
    TableRow,
    TableBody,
    TableCell,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import "rc-slider/assets/index.css";
import ToggleInput from "../../../controls/ToggleInput";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import "./style.css";
import { ScrollableTable } from "../../../layouts/tables";

export interface IFeaturesLinkFormProps extends IStateAwareFormProps {
    features?: any[];
    licenseFeatures?: string[];
    toastConfig?: any;
    isMobile?: boolean;
}

export default class FeaturesLinkForm extends StateAwareForm<IFeaturesLinkFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => {},
        toastConfig: {},
        isMobile: false,
    };

    componentDidMount() {
        super.setState({
            features: this.props.features,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                features: this.props.features,
            });
        }
    }

    async submit(name) {
        const successMessageAction = this.state[name] ? "linked" : "unlinked";
        const preposition = this.state[name] ? "to" : "from";
        const errorMessageAction = this.state[name] ? "linking" : "unlinking";
        try {
            await this.handleSubmit();
            await delete this.state[name];
            toast.success(`Feature successfully ${successMessageAction} ${preposition} the license`, this.props.toastConfig);
        } catch (e) {
            toast.error(`Error ${errorMessageAction} the feature ${preposition} the license`, this.props.toastConfig);
        }
    }

    displayFeatures() {
        const { features, licenseFeatures, isMobile } = this.props;
        let rows;

        rows = features?.map((feature, index) => (
            <TableRow>
                <TableCell>{feature.name}</TableCell>
                {!isMobile && <TableCell>
                    <ToggleInput
                        name={feature.id}
                        defaultValue={licenseFeatures?.includes(feature.id)}
                        label={""}
                        onChange={async (e, { name, value }) => {
                            await this.handleChange({ name, value });
                            await this.submit(name);
                        }}
                    />
                </TableCell>}
                {isMobile && <TableCell width={2}>
                    <ToggleInput
                        name={feature.id}
                        defaultValue={licenseFeatures?.includes(feature.id)}
                        label={""}
                        onChange={async (e, { name, value }) => {
                            await this.handleChange({ name, value });
                            await this.submit(name);
                        }}
                    />
                </TableCell>}
            </TableRow>
        ));
        return rows;
    }

    render() {
        const { error } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <div className={this.props.isMobile ? 'mobile-item-table' : 'item-table-container'}>
                    <ScrollableTable compact size="small" celled padded unstackable columns={2} className={'ten'}>
                        {!this.props.isMobile &&
                            <Table.Header>
                                <Table.HeaderCell>Feature</Table.HeaderCell>
                                <Table.HeaderCell>Associate</Table.HeaderCell>
                            </Table.Header>
                        }
                        <TableBody>{this.displayFeatures()}</TableBody>
                    </ScrollableTable>
                </div>
            </Form>
        );
    }
}
