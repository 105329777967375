import React, { PureComponent } from "react";
import Component from "../../../layouts/component";
import LocalizedPage from "../../LocalizedPage";
import { IPageProps } from "../../Page/Page";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";

export interface ILicense {
    name: string;
    type: string;
    level: string;
}

export interface IFeatures {
    name: string;
    key: string;
}
export interface ILicenseOverviewPageProps extends IPageProps {
    item?: ILicense;
    features?: IFeatures;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    panes: any;
}

export default class LicenseOverviewPage extends PureComponent<ILicenseOverviewPageProps> {
    render() {
        const { createRoutes, item, features, onChange, onSave, onDelete, Gatekeeper, panes } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        name={item && item.name}
                        type={item && item.type}
                        level={item && item.level}
                        features={features}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        panes={panes}
                    />
                    <MobilePage
                        name={item && item.name}
                        type={item && item.type}
                        level={item && item.level}
                        features={features}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        panes={panes}
                    />
                </Component>
            </Page>
        );
    }
}
