import React, { PureComponent } from "react";
import {
    Segment,
    Header,
    Button,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import './styles.css';

export interface IHeroTemplateProps {
    section: any;
}

export default class HeroTemplate extends PureComponent<
    IHeroTemplateProps
> {
    render() {
        const { section } = this.props;
        return (
            <div className="jumbotron text-center">
                <div className="inner">
                    <h1 style={{ fontWeight: 300 }}>{section.section.title}</h1>
                    <ReactMarkdown children={section.section.content} />
                    {section.options.layout.options.cta &&
                        <Button primary content={section.options.layout.options.cta} />
                    }
                </div>
            </div>
        )
    }
}