import React, { PureComponent } from "react";
import { Dropdown, Label } from 'semantic-ui-react'
import CommonUtils from "../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface IInlineOrderUpdateProps {
    defaultValue?: string;
    onChange?: (data: any) => any;
    item: any;
    name: string;
    value?: string;
}

export interface IInlineOrderUpdateState {
    value?: string
}

export default class InlineOrderUpdate extends PureComponent<IInlineOrderUpdateProps, IInlineOrderUpdateState> {
    state = {
        value: '',
    };

    static defaultProps = {
        item: {},
    }

    handleChange(e) {
        this.onSave(e.value);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.value || this.props.defaultValue,
            });
        }
    }

    onSave(value: string) {
        if (this.props.onChange) {
            let { item } = this.props;
            const data = {
                ...item,
                status: value,
            }
            this.props.onChange([data]);
        }
    }

    render() {

        const options = [
            {
                key: 0,
                text: <Label content='Received' />,
                value: 'received',
            },
            {
                key: 1,
                text: <Label content='Processing' color='yellow' />,
                value: 'processing',
            },
            {
                key: 2,
                text: <Label content='Shipped' color='blue' />,
                value: 'shipped',
            },
            {
                key: 3,
                text: <Label content='Delivered' color='green' />,
                value: 'delivered',
            },
            {
                key: 4,
                text: <Label content='Cancelled' color='red' />,
                value: 'cancelled',
            },
            {
                key: 5,
                text: <Label content='External' color='brown' />,
                value: 'external',
            },
        ]
        return (
            <Dropdown
                inline
                options={options}
                defaultValue={this.props.value}
                onChange={(event, data) => { this.handleChange(data) }}
            />
        );
    }
}