import React from "react";
import ApplicationSwitcher from "../components/controls/ApplicationSwitcher";
import LanguagePicker from "../components/controls/LanguagePicker";
import { Repository } from "../repositories";
import { SecurityService } from "../services";

export default class RightNavbarLanguageControlsFactory {

    create() {
        return (
            <LanguagePicker />
        )
    }
}