import React, { PureComponent } from "react";
import { MobileSkeleton } from "../../layouts/skeleton";
import { BrandedEntryContent } from "../../layouts/sections";
import { LoginForm } from "../../forms";
import { ILoginFormState } from "../../forms/LoginForm/LoginForm";
import { ButtonGroup, Button, Divider } from "semantic-ui-react";
import "./styles.css";

export interface ILoginPageProps {
    onLogin?: (values: ILoginFormState) => any;
}

export default class MobilePage extends PureComponent<ILoginPageProps> {
    render() {
        const { onLogin } = this.props;

        return (
            <MobileSkeleton>
                <BrandedEntryContent className="login-page" useFullLogo>
                    {/* <p>Choose an authentication provider.</p>
                    <ButtonGroup size="huge" className="login-page-oauth">
                        <Button
                            icon
                            href="https://localhost:8089/auth/google/url?redirect=true"
                            className="login-with-google-btn"
                        >
                            Sign in with Google
                        </Button>
                    </ButtonGroup>
                    <p>Or login with your email directly.</p> */}
                    <LoginForm onSubmit={onLogin} />
                    <Divider />
                    {/* <p>No account? <a href="/signup">Sign up</a>.</p> */}
                    <p>No account? <a href="https://dreamshipsolutions.com/#contact-us">Contact us</a>.</p>
                </BrandedEntryContent>
            </MobileSkeleton>
        );
    }
}
