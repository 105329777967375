import React, { Fragment, PureComponent } from "react";
import { Button, Form, SemanticWIDTHS } from "semantic-ui-react";
import CommonUtils from "../../../utilities/Common/CommonUtils";

const commonUtils = new CommonUtils();

export interface IMetadataEditorProps {
    name: string;
    value?: Record<string, any>;
    defaultValue?: Record<string, any>;
    fluid?: boolean;
    onChange?: (value) => any;
    widths?: SemanticWIDTHS;
    canUpdateKeys: boolean;
    canRemoveKeyValues: boolean;
    canAddNewValues: boolean;
    eligibleKeys?: { key: string, text: string, value: string }[];
}

export default class MetadataEditor extends PureComponent<IMetadataEditorProps> {
    state = {
        value: {},
        items: [],
        keys: {}
    };

    static defaultProps = {
        widths: "equal",
        canUpdateKeys: true,
        canAddNewValues: true,
        canRemoveKeyValues: true,
        eligibleKeys: undefined,
    }

    renderKeyEditor(key) {
        if (this.props.eligibleKeys) {
            return (
                <Form.Select
                    fluid
                    label={"Key"}
                    defaultValue={key}
                    onChange={(e, { value }) => {
                        this.handleKeyChange({ oldKey: key, newKey: value }, () => this.handleKeyUpdate(key));
                    }}
                    options={this.props.eligibleKeys.filter(({value}) => value === key || !Object.keys(this.state.value).includes(value))}
                />
            );
        }

        return (
            <Form.Input
                fluid
                label={"Key"}
                defaultValue={key}
                onChange={(e, { value }) => this.handleKeyChange({ oldKey: key, newKey: value })}
            >
                <input />
                <Button
                    type="button"
                    content="Update"
                    color="teal"
                    onClick={() => this.handleKeyUpdate(key)}
                />
            </Form.Input>
        )
    }

    prepareFields(item: Record<string, any>) {
        return Object.entries(item).map(([key, value]) => (
            <Form.Group widths="equal">
                {this.props.canUpdateKeys && this.renderKeyEditor(key)}
                <Form.Input
                    fluid
                    label={this.props.canUpdateKeys ? "Value" : commonUtils.capitalizeFirstLetter(commonUtils.splitCamelCaseWithAbbreviations(key))}
                    name={key}
                    defaultValue={value}
                    onChange={(e, { name, value }) => this.handleChange({ name, value })}
                />
                {(this.props.canUpdateKeys && this.props.canRemoveKeyValues) && (
                    <Form.Button width={1} icon="trash" label="Delete" color="red" onClick={() => this.handleDelete(key)}></Form.Button>
                )}
            </Form.Group>
        ));
    }

    componentDidMount() {
        this.setState({
            value: this.props?.defaultValue,
            items: this.prepareFields(this.props?.defaultValue || {})
        })
    }

    handleKeyChange({ oldKey, newKey }, cb?) {
        let keys = this.state.keys;
        keys[oldKey] = newKey;

        this.setState({
            keys: keys,
        }, cb);
    }

    handleKeyUpdate(oldKey) {
        let newKey = this.state.keys[oldKey];

        if (!newKey || oldKey === newKey) {
            return;
        }

        this.setState({
            items: []
        })

        this.handleChange({
            name: newKey,
            value: this.state.value[oldKey],
        }, () => {
            delete this.state.value[oldKey];

            this.setState({
                items: this.prepareFields(this.state.value)
            });
        });
    }

    handleDelete(key) {
        let keys = this.state.keys;
        let value = this.state.value;

        delete keys[key];
        delete value[key];

        this.setState({
            items: this.prepareFields(this.state.value),
            keys,
            value,
        });
    }

    handleChange({ name, value }, cb?) {
        let item = this.state.value;
        item[name] = value;

        this.setState({ value: item }, () => {
            if (this.props.onChange) {
                this.props.onChange({
                    name: this.props.name,
                    value: this.state.value,
                })
            }

            if (cb) {
                cb();
            }
        });
    }

    async handleAdd() {
        let item = this.state.value;
        item[""] = "";

        this.setState({
            value: item,
            items: this.prepareFields(item)
        })
    }

    render() {
        const { items } = this.state;

        return (
            <Fragment>
                {this.props.canAddNewValues && (
                    <Button
                        type="button"
                        content="Add"
                        icon="add"
                        color="teal"
                        onClick={() => this.handleAdd()}
                    />
                )}
                {items}
            </Fragment>
        );
    }
}