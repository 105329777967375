export default {
    "host": process.env.REACT_APP_API_ACTIVATIONS_HOST,
    "port": process.env.REACT_APP_API_ACTIVATIONS_PORT,
    "basePath": process.env.REACT_APP_API_ACTIVATIONS_BASE_PATH,
    "path": "activations",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'activations';