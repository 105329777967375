import React, { PureComponent } from "react";
import "./styles.css";
import {
    Grid,
    Card,
    Divider,
    CardGroup,
    Segment,
    Container,
    Tab,
    Button,
    Icon,
    Popup,
} from "semantic-ui-react";
import copy from "clipboard-copy";
import { ToastPosition, toast } from "react-toastify";
import { DesktopSkeleton } from "../../../layouts/skeleton";

export interface IDesktopPageProps {
    createRoutes?: Function;
    item?: any;
    Gatekeeper: any;
    panes: any[];
}
export interface IDesktopPageState { }

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {};

    private toastConfig = {
        autoClose: parseInt(process.env.REACT_APP_CLIENT_TOAST_AUTOCLOSE || '5000'),
        closeButton: ('true' === process.env.REACT_APP_CLIENT_TOAST_CLOSEBUTTON),
        draggable: ('true' === process.env.REACT_APP_CLIENT_TOAST_DRAGGABLE),
        limit: parseInt(process.env.REACT_APP_CLIENT_TOAST_LIMIT || '0'),
        newestOnTop: ('true' === process.env.REACT_APP_CLIENT_TOAST_NEWESTONTOP),
        position: (process.env.REACT_APP_CLIENT_TOAST_POSITION || 'top-right') as ToastPosition,
        pauseOnHover: ('true' === process.env.REACT_APP_CLIENT_TOAST_PAUSEONHOVER),
    }

    async copyIdToClipboard(value) {
        try {
            await copy(value);
            toast.success("Application ID copied to clipboard.", this.toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", this.toastConfig);
        }
    }

    render() {
        const { Gatekeeper, panes, item } = this.props;

        return (
            <DesktopSkeleton>
                <Container style={{ 'marginBottom': '1rem' }}>
                    <Grid>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <CardGroup>
                                    <Gatekeeper name="application-view">
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Header>
                                                    {item?.name}
                                                </Card.Header>
                                                <Card.Meta>
                                                    <span>Application ID: <span style={{ textDecoration: 'underline' }}>{item ? item.id : ''}</span>
                                                        <Popup
                                                            size="tiny"
                                                            trigger={
                                                                <Button style={{ 'marginLeft': '0.25rem' }} compact basic color='blue' icon onClick={async () => this.copyIdToClipboard(item.id)}>
                                                                    <Icon name='copy' />
                                                                </Button>
                                                            }
                                                            content='Copy application ID to the clipboard'
                                                            position='bottom center'
                                                        />
                                                    </span>
                                                </Card.Meta>
                                                <Divider />
                                                <Card.Description>

                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Gatekeeper>
                                </CardGroup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <Container>
                    <Segment padded>
                        <h4>Overview</h4>
                        <Divider />
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                    </Segment>
                </Container>
            </DesktopSkeleton>
        );
    }
}
