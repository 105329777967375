import React, { PureComponent } from "react";
import {
    Grid,
    Card,
    Divider,
    CardGroup,
    Container,
    Tab,
    Label,
    Button,
    CardContent,
    Header,
    CardMeta,
    Modal,
    CardDescription,
    ButtonGroup,
    Message,
    Popup,
    Dropdown,
    Form,
    Select,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import TextTemplate from "./templates/TextTemplate/TextTemplate";
import HeroTemplate from "./templates/HeroTemplate/HeroTemplate";
import ContactFormTemplate from "./templates/ContactFormTemplate/ContactFormTemplate";
import CSSEditor from "../../editors/CSSEditor/CSSEditor";
import ProductTemplate from "./templates/ProductTemplate/ProductTemplate";
import HeroTheming from "./theming/HeroTheming/HeroTheming";
import MapWithOpenHoursTemplate from "./templates/MapWithOpenHoursTemplate/MapWithOpenHoursTemplate";
import IndividualServiceTemplate from "./templates/IndividualServiceTemplate/IndividualServiceTemplate";
import UndefinedSectionTemplate from "./templates/UndefinedSectionTemplate/UndefinedSectionTemplate";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import GenericPageSectionSettings from "./forms/GenericPageSectionSettings";
import { toast } from 'react-toastify';
import "./styles.css";

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    item?: any;
    sections?: any[];
    locations?: any[];
    Gatekeeper: any;
    pageSections?: any[];
    isFetching: boolean;
    handleLinkSection: (data: any) => Promise<void>;
    handleUnlinkSection: (data: any) => Promise<void>;
    swapPositions: (data: any) => Promise<void>;
    handlePublishUnpublish: (data: any) => Promise<void>;
    handleUpdatePageSection: (data: any) => Promise<void>;
}
export interface IDesktopPageState {
    openModal: boolean;
    openQuickView: boolean;
    openCodeEditor: boolean;
    openThemingEditor: boolean;
    previewedSection: any;
    background: string;
    currentLocation: string;
    currentPageSection: any;
    displayPageSectionEditor: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        openModal: false,
        openQuickView: false,
        openCodeEditor: false,
        openThemingEditor: false,
        previewedSection: {},
        background: '#ffffff',
        currentLocation: 'main',
        currentPageSection: {},
        displayPageSectionEditor: false,
    };

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
      }

    toggleOpenModal() {
        this.setState({ openModal: !this.state.openModal });
    }

    toggleQuickView(previewedSection) {
        this.setState({ openQuickView: !this.state.openQuickView, previewedSection });
    }

    toggleCodeEditor() {
        this.setState({ openCodeEditor: !this.state.openCodeEditor });
    }

    toggleThemingEditor(previewedSection) {
        this.setState({ openThemingEditor: !this.state.openThemingEditor, previewedSection });
    }

    private togglePageSectionEditor(pageSection) {
        this.setState({ currentPageSection: pageSection, displayPageSectionEditor: !this.state.displayPageSectionEditor })
    }

    private handleLinkSection(data: string) {
        const sectionData = {
            sectionId: data,
            location: this.state.currentLocation,
        }
        this.props.handleLinkSection(sectionData);
        this.toggleOpenModal();
    }

    private handleUnlinkSection(data: string) {
        this.props.handleUnlinkSection(data);
    }

    private handleSavePageSection(data: any) {
        const { currentPageSection } = this.state;
        if (currentPageSection && this.props.handleUpdatePageSection) {
            this.props.handleUpdatePageSection({
                ...currentPageSection,
                ...data
            })
            toast.success('Page section successfully updated.');
            this.togglePageSectionEditor({});
        }
    }

    private renderSectionCards(type?: string) {
        const { sections } = this.props;
        if (!sections || sections.length == 0) { return; }
        const filteredSections = type ? sections.filter((section) => { return section.type === type }) : sections;
        let sectionCards = filteredSections.map((section) => {
            return (
                <Card fluid>
                    <CardContent>
                        <Header>{section.title}</Header>
                        <CardMeta>{section.type}</CardMeta>
                    </CardContent>
                    {/* {section.type == 'text' &&
                        <CardContent>
                            <CardDescription>{section.content}</CardDescription>
                        </CardContent>
                    } */}
                    <CardContent extra>
                        <Popup
                            content={'Preview Section'}
                            trigger={<Button icon={'eye'} onClick={() => { this.toggleQuickView(section) }} />}
                        />
                        <Button positive content={'Add section'} floated={'right'} onClick={() => this.handleLinkSection(section.id)} />
                    </CardContent>
                </Card>
            )
        })
        if (sectionCards.length == 0) {
            return (
                <Card fluid>
                    <CardContent>
                        <Header>No sections of this type.</Header>
                    </CardContent>
                    <CardContent>
                        <CardDescription><p>There are no sections of this type available. Why not create one?</p></CardDescription>
                    </CardContent>
                </Card>
            )
        }
        return sectionCards;
    }

    renderCodeEditorModal(code: any) {
        return (
            <Modal
                onClose={() => this.toggleCodeEditor()}
                open={this.state.openCodeEditor}
                size='large'
            >
                <Modal.Header>CSS Editor</Modal.Header>
                <Modal.Content scrolling>
                    <CSSEditor code={`.masthead {display: flex;justify-content: center;align-items: center;flex-direction: column;text-align: center;width: 100%;height: 100vh;overflow: hidden;background-size: cover !important;background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%), url(qijin-xu.png) no-repeat center center scroll;}h1 {font-style: normal;font-weight: bold;color: #eee;font-size: 11vmin;letter-spacing: 0.03em;line-height: 1;text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);margin-bottom: 40px;}button {background: #098191;transition: background ease .25s;border-radius: 5px;display: inline-block;border: none;padding: 0.75rem 1.5rem;margin: 0;text-decoration: none;color: #ffffff;font-size: 1.2rem;cursor: pointer;text-align: center;-webkit-appearance: none;-moz-appearance: none;}button:hover {background: #63b6b8 }button:focus {outline: 1px solid #fff;outline-offset: -4px;}`} />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon='check'
                        content='All Done'
                        onClick={() => this.toggleCodeEditor()}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    renderPageSectionEditor(pageSection: any) {
        if (!pageSection) return;
        return (
            <Modal
                onClose={() => this.togglePageSectionEditor({})}
                open={this.state.displayPageSectionEditor}
                size='small'

            >
                <Modal.Header>Edit page section</Modal.Header>
                <GenericPageSectionSettings item={pageSection} locationMap={this.props.locations} onSave={(data) => this.handleSavePageSection(data)} onCancel={() => this.togglePageSectionEditor({})} />
            </Modal>
        )
    }

    private renderLinkingSectionsModal() {
        const { sections } = this.props;
        const { previewedSection = {} } = this.state;
        const panes = [
            { menuItem: 'All Section Types', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards()}</CardGroup></Tab.Pane> },
            { menuItem: 'Text', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('text')}</CardGroup></Tab.Pane> },
            { menuItem: 'Hero', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('hero')}</CardGroup></Tab.Pane> },
            { menuItem: 'Contact Forms', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('contact-form')}</CardGroup></Tab.Pane> },
            { menuItem: 'Product', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('product')}</CardGroup></Tab.Pane> },
            { menuItem: 'Individual Service', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('individual-service')}</CardGroup></Tab.Pane> },
            { menuItem: 'Map With Open Hours', render: () => <Tab.Pane><CardGroup>{this.renderSectionCards('map-with-open-hours')}</CardGroup></Tab.Pane> },
        ]
        return (
            <Modal
                onClose={() => this.toggleOpenModal()}
                onOpen={() => this.toggleOpenModal()}
                open={this.state.openModal}
                trigger={<Button color="green" onClick={() => { this.toggleOpenModal() }} content={'Add Section'} icon={'plus'} />}
            >
                <Modal.Header>Add a Section</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {sections && sections?.length > 0 && <Tab panes={panes}></Tab>}
                        {sections && sections?.length == 0 && <Message content={'No sections!'} />}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <p style={{ float: 'left', verticalAlign: 'middle' }}>Can't find what you're looking for? <a href={`/website-management/pages/${this.props.item?.id}/sections/create?location=${this.state.currentLocation}`}>Create a new section</a>.</p>
                    <Button color='grey' onClick={() => this.toggleOpenModal()}>
                        Cancel
                    </Button>
                </Modal.Actions>
                <Modal
                    onClose={() => this.toggleQuickView({})}
                    open={this.state.openQuickView}
                    size='large'
                >
                    <Modal.Header>Section Preview</Modal.Header>
                    <Modal.Content scrolling>
                        {this.renderPageSectionPreview(previewedSection)}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            icon='check'
                            content='All Done'
                            onClick={() => this.toggleQuickView({})}
                        />
                    </Modal.Actions>
                </Modal>
            </Modal>
        )
    }

    private handleChangeComplete(color) {
        this.setState({ background: color.hex });
    }


    private renderThemingSection() {
        const { previewedSection } = this.state;

        return (
            <Modal
                onClose={() => this.toggleThemingEditor({})}
                open={this.state.openThemingEditor}
                centered
                size='large'>
                <Modal.Content>
                    {previewedSection['type'] == 'hero' && <HeroTheming title={previewedSection['title'] || ""} content={previewedSection['content'] || ""} cta={previewedSection['additional']['callToAction'] || ""} />}
                </Modal.Content>
            </Modal>
        )
    }

    private renderPageSectionPreview(pageSection: any) {
        let preview = <></>;
        if (!pageSection.section) { return preview; }
        switch (pageSection.section.type) {
            case 'text':
                preview = <TextTemplate title={pageSection.section.title} content={pageSection.section.content} />
                break;
            case 'hero':
                preview = <HeroTemplate section={pageSection} />
                break;
            case 'contact-form':
                preview = <ContactFormTemplate section={pageSection.section} />
                break;
            case 'product':
                preview = <ProductTemplate section={pageSection} />
                break;
            case 'map-with-open-hours':
                preview = <MapWithOpenHoursTemplate section={pageSection.section} />
                break;
            case 'individual-service':
                preview = <IndividualServiceTemplate section={pageSection.section} />
                break;
            default:
                preview = <UndefinedSectionTemplate section={pageSection.section} />
        }
        return preview;
    }

    private renderPageSections() {
        const { pageSections, item } = this.props;
        let sectionCards
        if (!item || !item.layout) {
            return (
                <Card.Group> <Card fluid>
                    <Card.Content>
                        <Card.Header>Ooops!</Card.Header>
                        <Card.Description>
                            You need to attach a layout to this page. Please do so before continuing any further.
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button icon='edit' color='teal' as={'a'} href={`/website-management/pages/${this.props.item?.id}/edit?error=missingLayout`} floated={'right'}>
                            Edit Page
                        </Button>
                    </Card.Content>
                </Card>
                </Card.Group>
            );
        }
        if (!this.state.currentLocation || !pageSections || Object.keys(pageSections).length === 0 || pageSections[this.state.currentLocation].length == 0) {
            return (
                <Message>
                    No sections here. <a onClick={() => this.toggleOpenModal()} style={{ cursor: 'pointer' }}>Add a new section</a>.
                </Message>
            );
        }
        try {
            sectionCards = pageSections[this.state.currentLocation].map((pageSection, index, array) => {
                const section = pageSection['section'];
                const previousItem = array[index - 1];
                const nextItem = array[index + 1];

                return (
                    <Card fluid>
                        <Label color='grey' attached="top" content={`${commonUtils.capitalizeFirstLetterLocale(commonUtils.splitKebabCase(section.type))}`} />
                        <CardContent>
                            {this.renderPageSectionPreview(pageSection)}
                        </CardContent>
                        <CardContent extra>
                            {array.length > 1 &&
                                <ButtonGroup>
                                    <Button icon='arrow up' disabled={index == 0} onClick={() => this.props.swapPositions(
                                        {
                                            firstId: previousItem.id,
                                            secondId: pageSection.id,
                                        }
                                    )} />
                                    <Button icon='arrow down' disabled={index == pageSections.length - 1} onClick={() => this.props.swapPositions(
                                        {
                                            firstId: pageSection.id,
                                            secondId: nextItem.id,
                                        }
                                    )} />
                                </ButtonGroup>
                            }
                            <ButtonGroup floated="right">
                                {/* <Button color="teal" size="tiny" icon='paint brush' onClick={() => this.toggleThemingEditor(pageSection.section)} /> */}
                                <Button icon='edit' onClick={() => this.togglePageSectionEditor(pageSection)} />
                                <Button color="red" size="tiny" icon='trash' onClick={() => this.handleUnlinkSection(pageSection.id)} />
                            </ButtonGroup>

                        </CardContent>
                    </Card>
                )
            })
        } catch (e) {
            sectionCards = <Card fluid>
                <Card.Content>
                    <Card.Header>Ooops!</Card.Header>
                    <Card.Description>
                        You need to attach a layout to this page. Please do so before continuing any further.
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button icon='edit' color='teal' as={'a'} href={`/website-management/pages/${this.props.item?.id}/edit`} floated={'right'}>
                        Edit Page
                    </Button>
                </Card.Content>
            </Card>;
        }

        return (<CardGroup>{sectionCards}</CardGroup>)
    }

    private updateEditorLocation(value: any) {
        this.setState({ currentLocation: value })
    }

    render() {
        const { Gatekeeper, pageSections, item, sections } = this.props;

        return (
            <DesktopSkeleton>
                {this.renderCodeEditorModal(`.bg-red-500 {}`)}
                {this.renderThemingSection()}
                {this.renderPageSectionEditor(this.state.currentPageSection)}
                <Container>
                    <Grid padded>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <CardGroup>
                                    <Gatekeeper name="application-view">
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Header>
                                                    {item?.title}
                                                </Card.Header>
                                                <Card.Meta>
                                                    https://example.com{item?.slug}
                                                </Card.Meta>
                                                <Divider />
                                                <Card.Description>
                                                    Language: {commonUtils.renderLanguageRealName(item?.language)}<br />
                                                    {item?.content}
                                                </Card.Description>
                                            </Card.Content>
                                            <Card.Content extra>
                                                {(item?.layout && 'string' === typeof item?.layout) && (<Label content={item?.layout?.toUpperCase()} color={'black'} basic circular />)}
                                                {item?.status && 'published' === item?.status && <Button icon='send' color='red' onClick={() => { this.handlePublishUnpublish('draft') }} floated={'right'}>
                                                    Unpublish
                                                </Button>}
                                                {item?.status && 'published' !== item?.status && <Button icon='send' color='green' onClick={() => { this.handlePublishUnpublish('published') }} floated={'right'}>
                                                    Publish
                                                </Button>}
                                                <Button icon='edit' color='teal' as={'a'} href={`/website-management/pages/${item?.id}/edit`} floated={'right'}>
                                                    Edit
                                                </Button>
                                            </Card.Content>
                                        </Card>
                                    </Gatekeeper>
                                </CardGroup>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                {sections && this.renderLinkingSectionsModal()}
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <label>Layout Location: </label>
                                <Dropdown
                                    placeholder='Select Location'
                                    selection
                                    defaultValue='main'
                                    options={this.props.locations}
                                    onChange={(e, { value }) => this.updateEditorLocation(value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {!this.props.isFetching && this.renderPageSections()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>

            </DesktopSkeleton>
        );
    }
    handlePublishUnpublish(status: string) {
        this.props.handlePublishUnpublish && this.props.handlePublishUnpublish(status);
    }
}
