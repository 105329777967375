import React, { PureComponent } from "react";
import { ButtonGroup } from "semantic-ui-react";
import { ReturnButton } from "../../controls";
import { LicenseForm } from "../../forms/licenses";
import { DesktopSkeleton } from "../../layouts/skeleton";
import "./styles.css";

export interface ILicenseDesktopPageProps {
    name: string;
    type: string;
    level: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ILicenseDesktopPageProps> {
    static defaultProps = {
        name: "",
        type: "",
        level: "",
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="license-management">
                    <div className={"license-form-container"}>
                        <ButtonGroup floated="left">
                            <ReturnButton
                                href={`/licenses`}
                                itemType={'licenses'}
                            />
                        </ButtonGroup>
                        <LicenseForm
                            name={this.props.name}
                            type={this.props.type}
                            level={this.props.level}
                            onSubmit={(data) => this.props.onSave(data)}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
