export default {
    "host": process.env.REACT_APP_API_MESSAGE_HOST,
    "port": process.env.REACT_APP_API_MESSAGE_PORT,
    "basePath": process.env.REACT_APP_API_MESSAGE_BASE_PATH,
    "path": "messages",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'messages';

export const MESSAGE_OPTIONS = {
    secure: {
        getOne: true,
        get: true,
        create: true,
        update: true,
        delete: true,
        count: true,
    }
}