import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IProduct {
    id: string;
    title: string;
    language: string;
    content: any;
    category: string;
    price: string;
    brand: string;
}

export interface IProductDefinitionsEditPageProps extends IPageProps {
    item?: IProduct;
    webmeta?: any;
    content?: any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    properties: any[];
    selections: any[];
    taxes: any[];
    productTaxes: any[];
    contents: any[];
    isFetching: boolean;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onContentSave: (data: any) => any;
    onContentDelete: (data: any) => any;
    onContentUpdate: (data: any) => any;
    onDelete?: () => void;
    onCancel?: () => void;
    createProperty?: (type: string) => void;
    deleteProductAttribute?: (id: any) => void;
    onAttributesChange: (key: string, value: any) => void;
    onAttributesSave: (data: any) => any;
    handleLinkTax: (data: any) => any;
    handleUnlinkTax: (data: any) => any;
    handleCreateTax: (data: any) => any;
}

export default class ProductDefinitionsEditPage extends PureComponent<IProductDefinitionsEditPageProps> {
    render() {
        const {
            createRoutes,
            breadcrumbLevels,
            properties,
            selections,
            item,
            webmeta,
            taxes,
            productTaxes,
            contents,
            content,
            isFetching,
            onChange,
            onSave,
            onDelete,
            onCancel,
            createProperty,
            deleteProductAttribute,
            onAttributesChange,
            onAttributesSave,
            handleLinkTax,
            handleUnlinkTax,
            handleCreateTax,
            onContentSave,
            onContentUpdate,
            onContentDelete,
            Gatekeeper,
            localizationService,
        } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        productDefinitionId={item && item.id}
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        brand={item && item.brand}
                        webmeta={webmeta}
                        properties={properties}
                        selections={selections}
                        taxes={taxes}
                        productTaxes={productTaxes}
                        contents={contents}
                        mainContent={content}
                        isFetching={isFetching}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        onCancel={onCancel}
                        createProperty={createProperty}
                        deleteProductAttribute={deleteProductAttribute}
                        onAttributesChange={onAttributesChange}
                        onAttributesSave={onAttributesSave}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        handleLinkTax={handleLinkTax}
                        handleUnlinkTax={handleUnlinkTax}
                        handleCreateTax={handleCreateTax}
                        onContentSave={onContentSave}
                        onContentUpdate={onContentUpdate}
                        onContentDelete={onContentDelete}
                    />
                    {/* <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        taxes={taxes}
                    /> */}
                </Component>
            </Page>
        );
    }
}
