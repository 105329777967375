import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import CustomerOverviewPage from '../../components/pages/accounts/CustomerOverviewPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountOverviewController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private userControllerAdapter;
    private subscriptionControllerAdapter;
    private contactControllerAdapter;
    private applicationControllerAdapter;
    private localizationService;

    state = {
        item: undefined,
        primaryContact: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory, userControllerAdapter, subscriptionControllerAdapter, contactControllerAdapter, applicationControllerAdapter] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.userControllerAdapter = userControllerAdapter;
        this.subscriptionControllerAdapter = subscriptionControllerAdapter;
        this.contactControllerAdapter = contactControllerAdapter;
        this.applicationControllerAdapter = applicationControllerAdapter;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    private async getPrimaryContact() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/contacts?state=primary`)
        } catch (e) {
            return;
        }

        return items[0];
    }

    async componentDidMount() {
        let item = await this.get();
        let primaryContact = await this.getPrimaryContact();

        this.setState({
            item,
            primaryContact,
        })
    }

    preparePanes(Gatekeeper) {
        const UserController = this.userControllerAdapter.getController();
        const SubscriptionController = this.subscriptionControllerAdapter.getController();
        const ContactController = this.contactControllerAdapter.getController();
        const ApplicationController = this.applicationControllerAdapter.getController();

        return [
            {
                menuItem: "Contacts",
                render: () => (
                    <>
                        <Gatekeeper
                            name="account-contact-management"
                            or={["account-contact-view"]}
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ContactController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Subscriptions",
                render: () => (
                    <>
                        <Gatekeeper
                            name="account-subscription-management"
                            or={["account-subscription-view"]}
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <SubscriptionController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Users",
                render: () => (
                    <Gatekeeper
                        name="account-user-management"
                        or={["account-user-view"]}
                        unauthorizedComponent={<UnauthorizedMessage />}
                        loadingComponent={<LoadingSpinner />}
                    >
                        {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                        {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                        <UserController params={this.params} location={this.location} history={this.history} />
                    </Gatekeeper>
                ),
            },
            {
                menuItem: "Applications",
                render: () => (
                    <Gatekeeper
                        name="application-management"
                        or={["application-view"]}
                        unauthorizedComponent={<UnauthorizedMessage />}
                        loadingComponent={<LoadingSpinner />}
                    >
                        {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                        {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                        <ApplicationController params={this.params} location={this.location} history={this.history} />
                    </Gatekeeper>
                ),
            },
        ]
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Accounts'), value: '/accounts' },
            { key: 2, text: this.state.item ? this.state.item!['name'] : '', value: undefined },
        ]
    }

    render() {
        const { item, primaryContact } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        const panes = this.preparePanes(Gatekeeper);

        return this.prepare(
            <CustomerOverviewPage
                item={item}
                primaryContact={primaryContact}
                breadcrumbLevels={this.generateBreadcrumbs()}
                Gatekeeper={Gatekeeper}
                panes={panes}
            />
        )
    }
}