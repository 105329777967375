import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import Page from '../../Page';
import DesktopPage from './DesktopPage';

export interface ITaxesPageProps {
    createRoutes?: Function,
    items: any,
    Gatekeeper: any;
    onPathChange: (path: string) => any;
    breadcrumbLevels: any;
    localizationService?: any;
}

export default class TaxesPage extends PureComponent<ITaxesPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
            onPathChange,
            breadcrumbLevels,
            localizationService,
        } = this.props;

        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        onPathChange={onPathChange}
                        items={items}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                    />
                </Component>
            </Page>
        );
    }
}