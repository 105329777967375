import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import './styles.css';

const { Item, Icon, Content, Header, Description } = List;

export interface IScrollableListProps {
    textAlign: string;
    children: any;
    className?: string;
}

export default class ScrollableList extends Component<IScrollableListProps> {
    static defaultProps = {
        textAlign: 'left',
        className: '',
    }

    render() {
        const { textAlign, children, className } = this.props;

        return (
            <List divided relaxed style={{ textAlign, overflow: 'auto' }} className={`scrollable-list ${className}`}>
                {children}
            </List>
        )
    }

    static Item = Item;
    static Icon = Icon;
    static Content = Content;
    static Header = Header;
    static Description = Description;
}