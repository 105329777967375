import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header } from "semantic-ui-react";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";
import AssociateUserModal from "./AssociateUserModal";

export interface IUsersPaneProps {
    items: any[];
    applications: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onCreateUser: (data: any) => Promise<any>;
    onLinkUser: (data: any) => Promise<any>;
    onLinkApplication: (user: any, data: any) => Promise<any>;
    onUnlinkUser: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface IUserPaneState {
    modalOpen: boolean;
}

export default class UsersPane extends PureComponent<IUsersPaneProps, IUserPaneState> {
    state: IUserPaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.firstName}</Table.Cell>
                <Table.Cell>{item.lastName}</Table.Cell>
                <Table.Cell><a href={`mailto:${item.email}`}>{item.email}</a></Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="user-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/users/${item.id}/edit`}
                                />
                            }
                            content="Edit the user's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="account-user-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkUser(item)}
                                />
                            }
                            content="Users can be associated to multiple accounts. Unassociating the user will remove the user's access from this account but will not delete or alter the user itself"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateUserModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onCreateUser={async (data: any) => this.props.onCreateUser(data)}
                onLinkUser={async (data: any) => this.props.onLinkUser(data)}
                onLinkApplication={async (user: any, data: any) => this.props.onLinkApplication(user, data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
                applications={this.props.applications}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={4} textAlign={'center'}>
                    <Header>No associated users!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return (<Table.Body>
            {!items.length ? this.renderNoContentMessage() : items}
        </Table.Body>)
    }

    private renderTableHeaders() {
        return <Table.Header>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
        </Table.Header>;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                {this.renderTableHeaders()}
                {this.renderTableBody()}
                <Gatekeeper name="account-user-management">
                    <Table.Footer>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan='4'>
                            {this.renderModal(this.state.modalOpen, Gatekeeper)}
                            <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()} style={{marginBottom:'1rem'}}><Icon name='plus' />Associate User</Button>
                        </Table.HeaderCell>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}