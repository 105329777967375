/**
 * @abstract
 * @export
 * @class LocalStorageProvider
 */
export default class LocalStorageProvider {

    /**
     * @param {number|string} id
     * @returns {Promise<Object>}
     * 
     * @memberOf LocalStorageProvider
     */
    getOne(id) {
        let item = localStorage.getItem(id);

        if (null === item) {
            return null;
        }

        try {
            item = JSON.parse(item);
        } catch (e) {

        }

        return item;
    }

    /**
     * @param {number|string} id
     * @returns {Promise<boolean>}
     * 
     * @memberOf LocalStorageProvider
     */
    has(id) {
        return !! this.getOne(id);
    }

    /**
     * @param {number|string} id
     * @param {Object} item
     * 
     * @memberOf LocalStorageProvider
     */
    update(id, item) {
        try {
            item = JSON.stringify(item);
        } catch (e) {

        }

        localStorage.setItem(id, item);
    }

    /**
     * @param {number|string} id
     * 
     * @memberOf LocalStorageProvider
     */
    delete(id) {
        localStorage.removeItem(id);
    }
}