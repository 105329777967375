import React, { PureComponent } from "react";
import { Button } from "semantic-ui-react";
import { DeleteButton, LoadingSpinner, ReturnButton } from "../../../controls";
import AccountForm from "../../../forms/accounts/AccountForm";
import { UnauthorizedMessage } from "../../../layouts/messages";
import { MobileSkeleton } from "../../../layouts/skeleton";
import "./styles.css";

export interface ISectionMobilePageProps {
  name: string;
  onChange?: (key: string, value: any) => void;
  onDelete?: () => void;
  onSave: (data: any) => any;
  Gatekeeper: any;
}

export default class MobilePage extends PureComponent<ISectionMobilePageProps> {
  state: any = {};

  static defaultProps = {
    name: "",
  };

  onChange(key, value) {
    if (this.props.onChange) {
      this.props.onChange(key, value);
    }
  }

  render() {
    const Gatekeeper = this.props.Gatekeeper;

    return (
      <MobileSkeleton>
        <Gatekeeper name="account-management"
          unauthorizedComponent={<UnauthorizedMessage />}
          loadingComponent={<LoadingSpinner />}>
          <div className="user-editor-container">
            <Button.Group floated="left">
              <ReturnButton
                href={`/accounts`}
              />
            </Button.Group>
            <AccountForm
              name={this.props.name}
              onSubmit={(data) => this.props.onSave(data)}
              onDelete={this.props.onDelete}
            />
          </div>
        </Gatekeeper>
      </MobileSkeleton>
    );
  }
}
