import React, { PureComponent } from 'react';
import { Table, TableProps } from 'semantic-ui-react';
import './styles.css';

export interface IScrollableTableProps extends TableProps {
    className?: string;
    definition?: boolean;
}

export default class ScrollableTable extends PureComponent<IScrollableTableProps> {
    static Row = Table.Row;
    static Header = Table.Header;
    static Cell = Table.Cell;
    static HeaderCell = Table.HeaderCell;
    static Body = Table.Body;
    static Footer = Table.Footer;

    render() {
        const { children, className, ...props } = this.props;

        return (
            <Table className={`scrollable ${className}`} definition={this.props.definition} role="grid" aria-labelledby="header" {...props}>
                {children}
            </Table>
        )
    }
}