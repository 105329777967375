import React, { PureComponent } from 'react';
import { Container, Header, Table, Grid, Label, Modal, Tab, Menu, Icon, Card, Segment, Button, Select, Form, Radio, Dropdown, Input } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import { UnauthorizedMessage } from '../../../layouts/messages';
import Countdown, {
    CountdownApi,
} from "react-countdown";
import TimeAgo from "timeago-react";
import OrderQuickView from './modals/OrderQuickView';
import * as timeago from 'timeago.js';
import { toast } from "react-toastify";
import './styles.css';

// import it first.
import fr from 'timeago.js/lib/lang/fr';
import Media from 'react-media';
import SimpleInlineOrderUpdate from '../SimpleInlineOrderUpdate';
import { add, endOfDay } from 'date-fns';
import PageVisibility from 'react-page-visibility';

// register it.
timeago.register('fr', fr);

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    exceptions?: any;
    pauseButtonMap?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    countdownDate: number;
    refreshOrders: Function;
    hasNewOrders?: boolean;
    lastUpdatedDate: string;
    displaySku?: boolean;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
    quickSaveStatus: (data: any) => any;
    getInvoice: (data: any) => any;
    getInvoiceInformation: (data: any) => any;
    setExceptions: (data: any) => any;
    resumeOrders: Function;
}
export interface IDesktopPageState {
    modalOpen: boolean;
    currentOrder: any;
    orderItems: any[];
    invoiceInfo: any[];
    activeIndex: number;
    shouldAutoRefresh: boolean;
    isLoading: boolean;
    //
    showPauseModal: boolean;
    reasonForPause: string;
    amountOfTimeForPause: any;
    startDateForPause: string;
    endDateForPause: string;
    startTimeForPause: string;
    endTimeForPause: string;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    countdownApi: CountdownApi | null = null;

    state = {
        activeIndex: 0,
        modalOpen: false,
        currentOrder: false,
        orderItems: [],
        invoiceInfo: [],
        shouldAutoRefresh: true,
        isLoading: false,
        showPauseModal: false,
        reasonForPause: '',
        amountOfTimeForPause: {},
        startDateForPause: '',
        endDateForPause: '',
        startTimeForPause: '',
        endTimeForPause: '',
    };

    handleVisibilityChange = isVisible => {
        document.location.reload();
    }

    componentWillReceiveProps(nextProps: Readonly<IDesktopPageProps>, nextContext: any): void {
        if (nextProps.hasNewOrders) {
            toast.info('Commandes mises à jour.', {
                hideProgressBar: true,
                position: 'top-center',
                autoClose: false,
                theme: 'colored',
                toastId: 'id',
            })
            if ('Notification' in window && "granted" === Notification.permission) {
                try {
                    new Notification('Commandes mises à jour.');
                } catch (e) {
                    navigator.serviceWorker.ready.then(function (registration) {
                        registration.showNotification('Commandes mises à jour.');
                    });
                }
            }
        }
        // this.setState({ shouldAutoRefresh: !this.props.exceptions.length });
    }

    static defaultProps = {
        onDoubleClick: () => { },
    }

    generateStatusLabel(status: string) {
        switch (status) {
            case 'cancelled':
                return 'red';
            case 'processing':
                return 'yellow';
            case 'external':
                return 'brown';
            case 'delivered':
                return 'green';
            case 'shipped':
                return 'blue';
            case 'received':
            default:
                return undefined;
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    updateActiveIndex(activeIndex: number) {
        this.setState({ activeIndex });
    }

    handleClick(e, item) {
        this.countdownApi && this.countdownApi.pause();
        this.setState({ isLoading: true }, () => this.openQuickOverViewModal(item))
    }

    private renderOrderHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Order Number')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Date Placed')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Due Date')}</Table.HeaderCell>
                    {/* <Table.HeaderCell>{this.translate('Status')}</Table.HeaderCell> */}
                </Table.Row>
            </Table.Header>
        );
    }

    renderOrdersTable(key: string) {
        const { items } = this.props;
        if (!items || !items[key]) { return; }
        const ordersTable = items[key].map((order: any) => (
            <Table.Row key={order.id} onClick={(e) => this.handleClick(e, order)} className="link">
                <Table.Cell>{order.orderNumber}<br /><Label content={`${this.translate(order.status.charAt(0).toUpperCase() + order.status.slice(1))}`} color={this.generateStatusLabel(order.status)} /></Table.Cell>
                <Table.Cell>
                    {commonUtils.renderDate(order.date, false)}
                </Table.Cell>
                <Table.Cell>
                    {commonUtils.renderDate(order.dueDate, false)}<br />
                    (<TimeAgo
                        datetime={new Date(order.dueDate)}
                        locale="fr"
                    />)
                </Table.Cell>
            </Table.Row>
        ));
        return (
            <ScrollableTable striped selectable className="eight">
                {this.renderOrderHeaders()}
                <Table.Body>
                    {!ordersTable.length ? this.renderNoContentMessage() : ordersTable}
                </Table.Body>
            </ScrollableTable>
        );
    }

    renderOrderCards(key: string) {
        const { items } = this.props;
        if (!items || !items[key]) { return; }
        const ordersTable = items[key].map((order: any) => (
            <Card link fluid>
                <Card.Content onClick={(e) => this.handleClick(e, order)}>
                    <Card.Header>{order.orderNumber}<span style={{ float: 'right', fontWeight: 100, fontSize: 'small' }}>{this.translate('Due Date')}: {commonUtils.renderDate(order.dueDate, false)}<br /><TimeAgo
                        datetime={new Date(order.dueDate)}
                        locale={JSON.parse(localStorage.getItem('lang')!) || 'en'}
                        style={{ float: 'right' }}
                    /></span></Card.Header>
                    <Card.Meta><Label content={`${this.translate(order.status.charAt(0).toUpperCase() + order.status.slice(1))}`} color={this.generateStatusLabel(order.status)} /></Card.Meta>
                </Card.Content>
                {key !== 'past' && <Card.Content extra>
                    <SimpleInlineOrderUpdate localizationService={this.props.localizationService} name='' value={order.status} item={order} onChange={(data) => { this.updateOrderStatus(data) }} />
                </Card.Content>}
            </Card>
        ));
        return (
            <Segment style={{ padding: '15px', height: '45vh' }} className="mobile-item-list-container">
                <Card.Group>
                    {!ordersTable.length ? this.renderNoContentMessage() : ordersTable}
                </Card.Group>
            </Segment>
        );
    }


    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No orders!')}</Header>
            </Container>
        )
    }

    renderCountdownMessage(hours, minutes, seconds) {
        return `${commonUtils.zeroPad(hours, 2)}:${commonUtils.zeroPad(minutes, 2)}:${commonUtils.zeroPad(seconds, 2)}`;
    }

    renderer({ hours, minutes, seconds, completed }) {
        if (completed) {
            {
                sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
                this.props.refreshOrders()
            }
            return <span style={{ fontSize: '0.95rem', float: 'right' }}>{this.translate('Refreshing...')}</span>
        }
        return (
            <div>
                <span style={{ fontSize: '0.95rem', float: 'right', textAlign: 'right' }}>
                    {this.countdownApi && this.countdownApi.isStarted() ? `${this.translate('Refreshing in')} ${this.renderCountdownMessage(hours, minutes, seconds)}.` : 'Order auto-refresh paused.'} <span style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (this.countdownApi && this.countdownApi.isStarted()) {
                                this.countdownApi && this.countdownApi.stop();
                                this.setState({ shouldAutoRefresh: false })
                            } else {
                                this.countdownApi && this.countdownApi.start();
                                this.setState({ shouldAutoRefresh: true })
                            }
                        }}><Icon name={this.countdownApi && this.countdownApi.isStarted() ? 'pause circle' : 'play circle'} />
                    </span><br />
                    {this.translate('Last updated on')} {this.props.lastUpdatedDate}.
                </span>
            </div>
        );
    };

    setRef = (countdown: Countdown | null): void => {
        if (countdown) {
            this.countdownApi = countdown.getApi();
        }
    };

    renderLoadingModal() {
        return (
            <Modal open={this.state.isLoading} size="tiny">
                <Modal.Content>
                    <p>Un instant. Nous cherchons les informations de la commande...</p>
                    <div style={{ margin: 'auto', textAlign: 'center' }}><Icon name="spinner" loading size='large' /></div>
                </Modal.Content>
            </Modal>
        )
    }

    private async resumeOrders() {
        if (this.props.resumeOrders) {
            await this.props.resumeOrders();
        }
    }

    openPauseModal() {
        this.setState({ showPauseModal: !this.state.showPauseModal, reasonForPause: '', amountOfTimeForPause: this.props.pauseButtonMap[0]?.value });
        this.resumeAutoRefresh();

    }

    async openQuickOverViewModal(order: any) {
        let orderItems;
        let invoiceInfo;

        if (this.props.getInvoice) {
            orderItems = await this.props.getInvoice(order.id);
            console.log('ord', orderItems)
        }

        if (this.props.getInvoiceInformation) {
            invoiceInfo = await this.props.getInvoiceInformation(order.invoiceId);
            console.log('in', invoiceInfo)
        }

        if (this.state.shouldAutoRefresh) {
            this.countdownApi && this.countdownApi.pause();
        }
        this.setState({ currentOrder: order, modalOpen: true, orderItems, invoiceInfo, isLoading: false })
    }
    private toggleOrderQuickViewModal() {
        this.setState({ currentOrder: undefined, modalOpen: false, orderItems: [] })
        this.resumeAutoRefresh();
        // this.props.refreshOrders && this.props.refreshOrders();
    }

    private resumeAutoRefresh() {
        // if (this.state.shouldAutoRefresh) {
        if (this.countdownApi && this.countdownApi.isStarted()) {
            this.countdownApi && this.countdownApi.stop();
            this.setState({ shouldAutoRefresh: false })
        } else {
            this.countdownApi && this.countdownApi.start();
            this.setState({ shouldAutoRefresh: true })
        }
        // }
    }

    private updateOrderStatus(data: any) {
        this.props.quickSaveStatus && this.props.quickSaveStatus(data);
        this.toggleOrderQuickViewModal();
    }

    private submitExceptions() {
        if (this.props.setExceptions) {
            let endDate;
            switch (this.state.amountOfTimeForPause) {
                case 'eod':
                    endDate = endOfDay(Date.now());
                    break;
                default:
                    endDate = add(Date.now(), this.state.amountOfTimeForPause);
            }
            let data = { type: this.state.reasonForPause, startDate: Date.now(), endDate };
            this.props.setExceptions(data);
        }
        this.openPauseModal();
    }

    private renderPauseButtons() {
        const { pauseButtonMap } = this.props;
        if (!pauseButtonMap) { return; }
        let buttons = pauseButtonMap.map(button => {
            return (<Grid.Column>
                <Form.Field>
                    <Button fluid toggle active={this.state.amountOfTimeForPause === button.value} onClick={(event) => {
                        this.setState({ amountOfTimeForPause: button.value })
                    }}>

                        {button.text}
                    </Button>
                </Form.Field>
            </Grid.Column>)
        })

        return <Grid>
            <Grid.Row columns={2}>
                {buttons}
            </Grid.Row>
        </Grid>;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        const panes = [
            {
                ksy: 0,
                menuItem: (
                    <Menu.Item key='today' onClick={() => { this.updateActiveIndex(0) }} active={0 == this.state.activeIndex}>
                        {this.translate('Current')} {this.props.items?.today?.length > 0 ? <Label color='red' circular size='tiny'>{this.props.items?.today.length}</Label> : <Label basic circular size='tiny'>0</Label>}
                    </Menu.Item>
                ),
                render: () => <PageVisibility onChange={this.handleVisibilityChange}>
                    <Tab.Pane attached={false} as={'div'} style={{ paddingTop: '20px' }}>
                        <Media queries={{
                            medium: "(max-width: 1023px)",
                            large: "(min-width: 1024px)",
                        }}>
                            {matches => (
                                <>
                                    {matches.medium && this.renderOrderCards('today')}
                                    {matches.large && this.renderOrdersTable('today')}
                                </>
                            )}
                        </Media>
                    </Tab.Pane>
                </PageVisibility>,
            },
            {
                key: 1,
                menuItem: (
                    <Menu.Item key='future' onClick={() => { this.updateActiveIndex(1) }} active={1 == this.state.activeIndex}>
                        {this.translate('Future Orders')} {this.props.items?.future?.length > 0 ? <Label color='red' circular size='tiny'>{this.props.items?.future.length}</Label> : <Label basic circular size='tiny'>0</Label>}
                    </Menu.Item>
                ),
                render: () => <Tab.Pane attached={false} as={'div'} style={{ paddingTop: '20px' }}>
                    <Media queries={{
                        medium: "(max-width: 1023px)",
                        large: "(min-width: 1024px)",
                    }}>
                        {matches => (
                            <>
                                {matches.medium && this.renderOrderCards('future')}
                                {matches.large && this.renderOrdersTable('future')}
                            </>
                        )}
                    </Media>
                </Tab.Pane>,
            },
            {
                key: 2,
                menuItem: (
                    <Menu.Item key='past' onClick={() => { this.updateActiveIndex(2) }} active={2 == this.state.activeIndex}>
                        {this.translate('Past Orders')}
                    </Menu.Item>
                ),
                render: () => <Tab.Pane attached={false} as={'div'} style={{ paddingTop: '20px' }}>
                    <Media queries={{
                        medium: "(max-width: 1023px)",
                        large: "(min-width: 1024px)",
                    }}>
                        {matches => (
                            <>
                                {matches.medium && this.renderOrderCards('past')}
                                {matches.large && this.renderOrdersTable('past')}
                            </>
                        )}
                    </Media>
                </Tab.Pane>,
            },
        ]

        return (
            <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />}>
                {this.renderLoadingModal()}
                <Modal open={this.state.modalOpen} size='large' onClose={() => this.toggleOrderQuickViewModal()}>
                    <OrderQuickView
                        localizationService={this.props.localizationService}
                        order={this.state.currentOrder}
                        orderItems={this.state.orderItems}
                        invoiceInfo={this.state.invoiceInfo}
                        toggleModal={() => this.toggleOrderQuickViewModal()}
                        toggleOrderStatus={(data) => { this.updateOrderStatus(data) }}
                        displaySku={this.props.displaySku}
                    />
                </Modal>
                <Grid columns={1} onTouchStart={() => { toast.dismiss() }}>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as='h3' content={this.translate('All Orders')}></Header>
                            {this.props.exceptions.length ? <Header.Content>
                                <Header.Subheader style={{ color: 'red', fontWeight: 'bolder' }}>{this.translate("Orders suspended until")} {new Date(this.props.exceptions[0].endDate).toLocaleString(`${JSON.parse(localStorage.getItem('lang')!)}-CA`, { dateStyle: 'long', timeStyle: 'short', timeZone: 'America/Montreal' })}.</Header.Subheader>
                            </Header.Content> : <></>}
                        </Grid.Column>
                        <Grid.Column floated='right' textAlign='right'>
                            <Gatekeeper name={'manage-storefront-availabilities'}>
                                {!this.props.exceptions.length ? <Modal
                                    size='tiny'
                                    trigger={<Button color='instagram' content={this.translate('Pause Orders')} icon='pause' onClick={() => this.openPauseModal()} />}
                                    open={this.state.showPauseModal}
                                    onClose={(event, data) => {
                                        this.openPauseModal();
                                        // this.resetPauseState();
                                    }}
                                >
                                    <Modal.Header>{this.translate('Pause Orders')}</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <label>{this.translate('Pause reason')}</label>
                                            <Form.Group inline>
                                                <Button toggle active={this.state.reasonForPause === 'capacity'} onClick={(event) => {
                                                    this.setState({ reasonForPause: 'capacity' })
                                                }}>
                                                    {this.translate('Capacity')}
                                                </Button>

                                                <Button toggle active={this.state.reasonForPause === 'incident'} onClick={(event) => {
                                                    this.setState({ reasonForPause: 'incident' })
                                                }}>
                                                    {this.translate('Incident')}
                                                </Button>

                                                {/* <Button toggle active={this.state.reasonForPause === 'holiday'} onClick={(event) => {
                                                        this.setState({ reasonForPause: 'holiday' })
                                                    }}>
                                                        Holiday
                                                    </Button> */}
                                            </Form.Group>
                                            {this.state.reasonForPause && <>
                                                <hr />
                                                <label>{this.translate('I want to pause for')} </label>
                                                <Form.Group inline grouped widths={'equal'}>
                                                    {this.renderPauseButtons()}
                                                    <Form.Field>
                                                        <Button fluid toggle active={this.state.amountOfTimeForPause === 'eod'} onClick={(event) => {
                                                            this.setState({ amountOfTimeForPause: 'eod' })
                                                        }}>
                                                            {this.translate('Remainder of the day')}
                                                        </Button>
                                                    </Form.Field>
                                                </Form.Group>
                                                {/* <Form.Group>
                                                        <Form.Field>
                                                            <Button fluid toggle active={this.state.amountOfTimeForPause === 'other'} onClick={(event) => {
                                                                this.setState({ amountOfTimeForPause: 'other' })
                                                            }}>
                                                                Custom...
                                                            </Button>
                                                        </Form.Field>
                                                    </Form.Group> */}
                                            </>}
                                            {/* {this.state.amountOfTimeForPause === 'other' && <>
                                                    <hr />
                                                    <label>Select date and time of pause:</label>
                                                    <Form.Group inline>
                                                        <Form.Field>
                                                            <SemanticDatepicker datePickerOnly minDate={new Date()} value={this.state.startDateForPause ? new Date(this.state.startDateForPause) : undefined} clearable={false} onChange={(event, data) => { data.value && this.setState({ startDateForPause: data.value!.toString() }) }} />
                                                            <Dropdown placeholder='00:00' className='datepicker-time' options={commonUtils.generateTimes()} onChange={(event, data) => { data.value && this.setState({ startTimeForPause: data.value!.toString() }) }} scrolling search={false} trigger={<Input type='text' icon={'clock'} value={this.state.startTimeForPause} onChange={(event) => event.preventDefault()} />} />
                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Form.Group inline>
                                                        <Form.Field>
                                                            <SemanticDatepicker pointing='top right' datePickerOnly minDate={new Date()} value={this.state.endDateForPause ? new Date(this.state.endDateForPause) : undefined} clearable={false} onChange={(event, data) => { data.value && this.setState({ endDateForPause: data.value!.toString() }) }} />
                                                            <Dropdown placeholder='00:00' className='datepicker-time' options={commonUtils.generateTimes()} onChange={(event, data) => { data.value && this.setState({ endTimeForPause: data.value!.toString() }) }} scrolling search={false} trigger={<Input type='text' icon={'clock'} value={this.state.endTimeForPause} onChange={(event) => event.preventDefault()} />} />
                                                        </Form.Field>
                                                    </Form.Group>
                                                </>
                                                } */}
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button content={this.translate('Cancel')} onClick={() => { this.openPauseModal() }} />
                                        <Button positive content={this.translate('Pause')} onClick={() => { this.submitExceptions() }} disabled={this.state.reasonForPause.length == 0 || Object.keys(this.state.amountOfTimeForPause).length == 0} />
                                    </Modal.Actions>
                                </Modal> : <Button color='vk' content={this.translate('Resume Orders')} icon='play' onClick={() => this.resumeOrders()} />}
                            </Gatekeeper>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Tab key='0' activeIndex={this.state.activeIndex} menu={{ secondary: true, pointing: true, compact: true }} panes={panes} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Countdown
                                date={this.props.countdownDate}
                                intervalDelay={0}
                                precision={0}
                                autoStart={this.state.shouldAutoRefresh}
                                renderer={(e) => this.renderer(e)}
                                ref={this.setRef}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Gatekeeper>
        );
    }
}
