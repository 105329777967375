import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { Button, Container, Header, Icon, Modal, Table, Image, Grid, Statistic } from 'semantic-ui-react';
import { ScrollableTable } from '../../layouts/tables';
import CommonUtils from '../../../utilities/Common/CommonUtils';
import BlogModalContent from './BlogModalContent';
import { DimmedModal } from '../../layouts/modals';
import { UnauthorizedMessage } from '../../layouts/messages';
import { LoadingSpinner } from '../../controls';
import LocalizedCreateButton from '../../controls/buttons/CreateButton/LocalizedCreateButton';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    submitted?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header={this.translate('Nothing to see here!')}
                content={this.translate('Add your first blog post!')}
                actions={
                    [this.translate('Not now'),
                    { key: 'done', content: this.translate('Create blog post'), positive: true, href: '/posts/create' }
                    ]}
            />;
        }
        return;
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Date Modified')}</Table.HeaderCell>
                    <Table.HeaderCell icon>
                        {this.translate('Status')}{' '}
                        <Icon
                            name="question circle"
                            className="icon-link"
                            onClick={() => this.openModal()}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderTableBody() {
        const { items } = this.props;
        const refinedItems = items.filter((post: any) => 'submitted' !== post.status && 'recalled' !== post.status);
        const postsTable = refinedItems.map((post: any) => {
            if ('submitted' !== post.status && 'recalled' !== post.status) {
                return (
                    <Table.Row onClick={(e) => this.handleDoubleClick(e, post)}>
                        <Table.Cell>{post.title}</Table.Cell>
                        <Table.Cell>
                            {commonUtils.renderLanguage(post.language)}
                        </Table.Cell>
                        <Table.Cell>{commonUtils.renderDate(post.date)}</Table.Cell>
                        <Table.Cell className="blog-list-post-status">
                            {post.status}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Button
                                icon="edit"
                                color="teal"
                                as="a"
                                href={`/posts/${post.id}/edit`}
                            />
                        </Table.Cell>
                    </Table.Row>
                );
            }
        });
        return (
            <Table.Body>
                {!postsTable.length ? this.renderNoContentMessage() : postsTable}
            </Table.Body>
        );
    }

    private renderSubmittedTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Date Modified')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderSubmittedTableBody() {
        const { submitted } = this.props;
        const postsTable = submitted.map((post: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, post)}>
                    <Table.Cell>{post.title}</Table.Cell>
                    <Table.Cell>
                        {commonUtils.renderLanguage(post.language)}
                    </Table.Cell>
                    <Table.Cell>{commonUtils.renderDate(post.date)}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/posts/${post.id}/edit`}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
        return (
            <Table.Body>
                {!postsTable.length ? this.renderNoSubmittedContentMessage() : postsTable}
            </Table.Body>
        );
    }

    private renderRecalledTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Date Modified')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderRecalledTableBody() {
        const { items } = this.props;
        const postsTable = items.filter((post: any) => 'recalled' == post.status).map((post: any) => {
            if ('recalled' == post.status) {
                return (
                    <Table.Row onClick={(e) => this.handleDoubleClick(e, post)}>
                        <Table.Cell>{post.title}</Table.Cell>
                        <Table.Cell>
                            {commonUtils.renderLanguage(post.language)}
                        </Table.Cell>
                        <Table.Cell>{commonUtils.renderDate(post.date)}</Table.Cell>
                        <Table.Cell textAlign="right">
                            <Button
                                icon="edit"
                                color="teal"
                                as="a"
                                href={`/posts/${post.id}/edit`}
                            />
                        </Table.Cell>
                    </Table.Row>
                );
            }
        });
        return (
            <Table.Body>
                {!postsTable.length ? this.renderNoRedacalledContentMessage() : postsTable}
            </Table.Body>
        );
    }

    private renderEntitlementsTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Published')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('In Review')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Accrued')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Entitlement')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderEntitlementsStatistics() {
        return (
            <Container style={{ marginTop: '75px', height: '10%', width: '100%' }} textAlign='center' fluid>
                <div style={{ margin: '0 auto' }}>
                    <Statistic.Group centered>
                        <Statistic>
                            <Statistic.Value>4</Statistic.Value>
                            <Statistic.Label>Published</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>2</Statistic.Value>
                            <Statistic.Label>In Review</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>4</Statistic.Value>
                            <Statistic.Label>Accrued</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>36</Statistic.Value>
                            <Statistic.Label>Entitlement</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                </div>
            </Container>
        );
    }

    private renderInformationalModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>{this.translate('About Post Statuses')}</Modal.Header>
                <Modal.Content>
                    <BlogModalContent localizationService={this.props.localizationService} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        {this.translate('Got it!')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No blog posts!')}</Header>
                <Image centered src="/images/icons/Text_blog.png" size="small" />
            </Container>
        )
    }

    renderNoSubmittedContentMessage() {
        return (
            <Container style={{ marginTop: '75px', height: '10%' }} textAlign='center' fluid>
                <Header>{this.translate('No blog posts to review!')}</Header>
            </Container>
        )
    }

    renderNoRedacalledContentMessage() {
        return (
            <Container style={{ marginTop: '75px', height: '10%' }} textAlign='center' fluid>
                <Header>{this.translate('No recalled blog posts!')}</Header>
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="blog-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    {this.renderInformationalModal(this.state.modalOpen)}
                    <div className="item-list-container">
                        <Grid columns={2} stackable style={{ marginBottom: '25px' }}>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <Header as='h3' content={this.translate('All Posts')}></Header>
                                    <ScrollableTable striped selectable>
                                        {this.renderTableHeaders()}
                                        {this.renderTableBody()}
                                    </ScrollableTable>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Grid.Row>
                                        <div className='widget'>
                                            <Header as='h3' content={this.translate('Pending Approval')}></Header>
                                            <ScrollableTable striped selectable>
                                                {this.renderSubmittedTableHeaders()}
                                                {this.renderSubmittedTableBody()}
                                            </ScrollableTable>
                                        </div>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <div className='widget'>
                                            <Header as='h3' content={this.translate('Recalled')}></Header>
                                            <ScrollableTable striped selectable>
                                                {this.renderRecalledTableHeaders()}
                                                {this.renderRecalledTableBody()}
                                            </ScrollableTable>
                                        </div>
                                    </Grid.Row>
                                    {/* <Grid.Row centered>
                                        <Grid.Column>
                                            <div className='widget'>
                                                <Header as='h3' content={this.translate('Entitlements')}></Header>
                                                {this.renderEntitlementsStatistics()}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row> */}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.renderNoContentModal()}
                        <LocalizedCreateButton
                            localizationService={this.props.localizationService}
                            itemType='blog post'
                            href='/posts/create'
                            attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
