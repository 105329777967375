import React, { PureComponent } from 'react';
import { MobileSkeleton } from '../../layouts/skeleton';
import { BrandedEntryContent } from '../../layouts/sections';
import { SignupForm } from '../../forms';
import { ISignupPageProps } from './SignupPage';
import './styles.css';

export interface ISignupMobilePageProps extends ISignupPageProps {

}

export default class MobilePage extends PureComponent<ISignupMobilePageProps> {
    render() {
        const { onSignup } = this.props;

        return (
            <MobileSkeleton>
                <BrandedEntryContent className="signup-page" useFullLogo>
                    <SignupForm onSubmit={onSignup} />
                </BrandedEntryContent>
            </MobileSkeleton>
        )
    }
}