import React from 'react';
import ProductAttributesEditPage from '../../../components/pages/productdefinitions/ProductAttributesEditPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { EntityId } from '../../../repositories/Repository';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductAttributesCreateController extends LocalizedController {
    private repository: Repository;
    private attributesRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        properties: [],
        selections: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, productDefinitionsRepository, attributesRepository, gatekeeperFactory] = props.args;

        this.repository = productDefinitionsRepository;
        this.attributesRepository = attributesRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    async componentWillMount() {
        await this.fetchAttributes();
    }

    private async fetchAttributes() {
        let items;
        let properties;
        let selections;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.attributesRepository.find({ definitionId: this.params?.id });
            properties = items.filter((item) => { return item.type == 'property' });
            selections = items.filter((item) => { return item.type == 'selection' });
            this.setState({ properties, selections });
        } catch (e) {
            console.error(e);
        }
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        if (data.id) {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            data.definitionId = this.params?.id
        }
        try {
            data.id ? await this.attributesRepository.updateByPath(data, `attributes/${data.id}`) : await this.attributesRepository.create(data, {
                query: {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    definitionId: this.params?.id
                }
            });
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async createProductAttribute(type: string) {
        let entity = {
            name: "",
            type: type,
            options: [],
        }

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.attributesRepository.create(entity, { query: { definitionId: this.params?.id } });
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async deleteProductAttribute(id: any) {
        try {
            await this.attributesRepository.delete(id);
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 2, text: this.localizationService.translate('Create product attributes'), value: undefined },
        ]
    }

    render() {
        const { item, properties, selections } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductAttributesEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                createProperty={async (type: string) => this.createProductAttribute(type)}
                deleteProductAttribute={async (id: any) => this.deleteProductAttribute(id)}
                properties={properties}
                selections={selections}
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                productDefinitionId={this.params?.id}
            />
        )
    }
}