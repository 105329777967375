import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import DeleteButton from "../../../controls/buttons/DeleteButton";
import MarkdownEditor from "../../../controls/MarkdownEditor";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface ISubscriptionFormProps extends IStateAwareFormProps {
    name: string;
    description: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
}

export default class SubscriptionForm extends StateAwareForm<ISubscriptionFormProps> {
    constructor(props) {
        super(props);
    }

    public static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
            description: this.props.description,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
                description: this.props.description,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                        {this.props.name && (
                            <DeleteButton
                                itemType="subscription"
                                itemName={this.props.name}
                                icon="trash"
                                color="red"
                                handleOnClick={this.props.onDelete}
                            />
                        )}
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Subscription"
                />

                <Message
                    error
                    header="Could not save user"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"Name"}
                    defaultValue={this.props.name}
                    name="name"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <label className={"mobile-post-content-header"}>{"Description"}</label>
                <MarkdownEditor
                    value={this.props.description}
                    name="description"
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    hideStats
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
