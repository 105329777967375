import React, { PureComponent } from "react";
import Media from "react-media";
import {
    Button,
    Grid,
    Menu,
    Segment,
    Sidebar,
} from "semantic-ui-react";
// import "./styles.css";

export interface IStoreSettingsTemplate {
    onChange: (path: string) => any;
    activeItem: string;
    localizationService?: any;
}

export default class StoreSettingsTemplate extends PureComponent<IStoreSettingsTemplate> {
    state = {
        displaySidebar: false,
    }
    translate(key: string): string {
        return this.props.localizationService ? this.props.localizationService.translate(key) : key;
    }

    protected handleItemClick(name) {
        this.props.onChange(name)
    };

    render() {
        return (
            <div className="editor-container">
                <Grid centered>
                    <Media queries={{
                        small: "(max-width: 1023px)",
                        medium: "(min-width: 1024px)",
                    }}>
                        {matches => (
                            <>
                                {matches.small &&
                                    <Grid.Column>
                                        <Sidebar
                                            as={Segment}
                                            animation={'push'}
                                            direction={'left'}
                                            visible={this.state.displaySidebar}
                                            onHide={() => this.setState({ displaySidebar: !this.state.displaySidebar })}
                                        >
                                            <Menu fluid pointing vertical secondary>
                                                <Menu.Item
                                                    name={this.translate('Store Settings')}
                                                    content={this.translate('Store Settings')}
                                                    active={'store-management' === this.props.activeItem}
                                                    icon='shopping basket'
                                                    onClick={(e) => this.handleItemClick("")}
                                                />
                                                <Menu.Item
                                                    name={this.translate('Products')}
                                                    content={this.translate('Products')}
                                                    active={'products' === this.props.activeItem}
                                                    icon={'boxes'}
                                                    onClick={(e) => this.handleItemClick("products")}
                                                />
                                                <Menu.Item
                                                    name={this.translate('Catalogs')}
                                                    content={this.translate('Catalogs')}
                                                    active={'catalogs' === this.props.activeItem}
                                                    icon={'book'}
                                                    onClick={(e) => this.handleItemClick("catalogs")}
                                                />
                                                <Menu.Item
                                                    name={this.translate('Orders')}
                                                    content={this.translate('Orders')}
                                                    active={'orders' === this.props.activeItem}
                                                    icon={'ordered list'}
                                                    onClick={(e) => this.handleItemClick("orders")}
                                                />
                                                <Menu.Item
                                                    name={this.translate('Invoices')}
                                                    content={this.translate('Invoices')}
                                                    active={'invoices' === this.props.activeItem}
                                                    icon={'sticky note outline'}
                                                    onClick={(e) => this.handleItemClick("invoices")}
                                                />
                                                <Menu.Item
                                                    name={this.translate('Taxes')}
                                                    content={this.translate('Taxes')}
                                                    active={'taxes' === this.props.activeItem}
                                                    icon={'percent'}
                                                    onClick={(e) => this.handleItemClick("taxes")}
                                                />
                                            </Menu>
                                        </Sidebar>
                                    </Grid.Column>
                                }
                                {matches.medium &&
                                    <Grid.Column width={3}>
                                        <Menu fluid pointing vertical secondary>
                                            <Menu.Item
                                                name={this.translate('Store Settings')}
                                                content={this.translate('Store Settings')}
                                                active={'store-management' === this.props.activeItem}
                                                icon='shopping basket'
                                                onClick={(e) => this.handleItemClick("")}
                                            />
                                            <Menu.Item
                                                name={this.translate('Products')}
                                                content={this.translate('Products')}
                                                active={'products' === this.props.activeItem}
                                                icon={'boxes'}
                                                onClick={(e) => this.handleItemClick("products")}
                                            />
                                            <Menu.Item
                                                name={this.translate('Catalogs')}
                                                content={this.translate('Catalogs')}
                                                active={'catalogs' === this.props.activeItem}
                                                icon={'book'}
                                                onClick={(e) => this.handleItemClick("catalogs")}
                                            />
                                            <Menu.Item
                                                name={this.translate('Orders')}
                                                content={this.translate('Orders')}
                                                active={'orders' === this.props.activeItem}
                                                icon={'ordered list'}
                                                onClick={(e) => this.handleItemClick("orders")}
                                            />
                                            <Menu.Item
                                                name={this.translate('Invoices')}
                                                content={this.translate('Invoices')}
                                                active={'invoices' === this.props.activeItem}
                                                icon={'sticky note outline'}
                                                onClick={(e) => this.handleItemClick("invoices")}
                                            />
                                            <Menu.Item
                                                name={this.translate('Taxes')}
                                                content={this.translate('Taxes')}
                                                active={'taxes' === this.props.activeItem}
                                                icon={'percent'}
                                                onClick={(e) => this.handleItemClick("taxes")}
                                            />
                                        </Menu>
                                    </Grid.Column>
                                }
                            </>
                        )}
                    </Media>
                    <Media query="(max-width: 1023px)" render={() =>
                    (
                        <>
                            <Grid.Column stretched width={'16'}>
                                {this.props.children}
                            </Grid.Column>
                            <Button size="mini" icon='bars' onClick={() => {
                                this.setState({
                                    displaySidebar: !this.state.displaySidebar
                                })
                            }} style={{ position: 'absolute', top: '4.75rem', left: '1rem' }} />
                        </>
                    )}
                    />
                    <Media query="(min-width: 1024px)" render={() =>
                    (
                        <Grid.Column stretched width={'13'}>
                            {this.props.children}
                        </Grid.Column>
                    )}
                    />
                </Grid>
            </div>
        );
    }
}
