import React, { PureComponent } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import './styles.css';

export interface IEntryContentProps {
    children?: any;
    className?: string;
}

export default class EntryContent extends PureComponent<IEntryContentProps> {
    render() {
        const { children, className } = this.props;

        return (
            <Grid className={`entry-content ${className}`} textAlign="center" verticalAlign='middle' padded>
                <Grid.Column>
                    <Segment stacked>
                        {children}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}