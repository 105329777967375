import React, { PureComponent } from "react";
import { MobileSkeleton } from "../../layouts/skeleton";
import { UnauthorizedMessage } from "../../layouts/messages";
import { Card, CardGroup, Icon } from "semantic-ui-react";
import { LoadingSpinner } from "../../controls";
import Breadcrumbs from "../../controls/Breadcrumbs/Breadcrumbs";
import "./styles.css";

export interface IOverviewMobilePageProps {
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
}

export default class MobilePage extends PureComponent<IOverviewMobilePageProps> {
    render() {
        const Gatekeeper = this.props.Gatekeeper;
        const Localization = this.props.localizationService;

        return (
            <MobileSkeleton>
                <Gatekeeper
                    name="blog-management"
                    or={["website-management", "messages-management"]}
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="main-container-mobile">
                        <h2>{Localization.translate('What would you like to accomplish today?')}</h2>
                        <div className="menu-container-mobile">
                            <CardGroup centered>
                                <Gatekeeper name="blog-management">
                                    <Card link href={"/posts"}>
                                        <Card.Content>
                                            <Card.Header as="h2" icon>
                                                <Icon name="list" />
                                                {Localization.translate('View all blog posts')}
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content>
                                            <Card.Description>
                                                {Localization.translate('View and manage posts that are present on your blog.')}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Gatekeeper>
                                <Gatekeeper name="website-management">
                                    <Card link href={"/sections"}>
                                        <Card.Content>
                                            <Card.Header as="h2" icon>
                                                <Icon name="sitemap" />
                                                {Localization.translate('Manage sections on the website')}
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content>
                                            <Card.Description>
                                                {Localization.translate('Tweak aspects of your website layout and ensure its contents are relevant.')}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Gatekeeper>
                                <Gatekeeper name="messages-management">
                                    <Card link href={"/inbox"}>
                                        <Card.Content>
                                            <Card.Header as="h2" icon>
                                                <Icon name="envelope" />
                                                {Localization.translate('See messages from customers')}
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content>
                                            <Card.Description>
                                                {Localization.translate('Keep up to date with your new customers by viewing and responding to their messages from your website.')}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Gatekeeper>
                            </CardGroup>
                        </div>
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
