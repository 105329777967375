import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IProductOverviewPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any,
    breadcrumbLevels?: any,
    panes: any[];
}

export default class ProductOverviewPage extends PureComponent<IProductOverviewPageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, localizationService, breadcrumbLevels, panes } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage panes={panes} items={items} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                    {/* <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} /> */}
                </Component>
            </Page>
        );
    }
}
