import React from 'react';
import AccountSettingsPage from '../../components/pages/SettingsPage/AccountSettingsPage';
import LocalizationService from '../../services/LocalizationService';
import LocalizedController from '../LocalizedController';

export default class AccountController extends LocalizedController {
    private localizationService: LocalizationService;
    state = {};

    constructor({ args: [router, routeMenuItemFactory, localizationService], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.localizationService = localizationService;
    }

    protected changeSettings(path) {
        this.router.redirect(`/settings/${path}`);
    }

    render() {
        return this.prepare(
            <AccountSettingsPage
                localizationService={this.localizationService}
                onChange={(path) => this.changeSettings(path)}
            />
        )
    }
}