import React, { PureComponent } from 'react';
import { Header, Button } from 'semantic-ui-react';

export interface IStatementProps {
    title: string;
    content?: any;
    children: any;
    callToAction?: any;
    callToActionLink?: string;
    newTab?: boolean;
}

export default class Statement extends PureComponent<IStatementProps> {
    render() {
        const { title, content, children, callToAction, callToActionLink, newTab } = this.props;

        let link: any = {
            href: callToActionLink,
        }

        if (newTab) {
            link.target = "_blank";
        }

        return (
            <React.Fragment>
                <Header as='h3' className='statement'>
                    {title}
                </Header>
                <p className='statement'>
                    {children || content}
                </p>
                {callToAction && (
                    <Button as='a' size='large' {...link}>
                        {callToAction}
                    </Button>
                )}
            </React.Fragment>
        );
    }
}