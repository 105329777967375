import React from 'react';
import CustomersPage from '../../components/pages/accounts/CustomersPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class AccountController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        items: []
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/accounts/${id}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Accounts'), value: undefined },
        ]
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;

        try {
            item = await this.repository.create(data);
        } catch (e) {
            throw e;
        }

        setTimeout(() => this.router.redirect(`/accounts/${item.id}`), 500)
    }

    render() {
        const { items, item } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <CustomersPage
                items={items}
                Gatekeeper={Gatekeeper}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
            />
        )
    }
}