import React, { PureComponent } from "react";
import RichTextEditor from "react-rte";
import Countable from 'countable';
import removeMarkdown from "markdown-to-text";
import { TimeToRead } from "@dreamshipsolutions/facade-components-time-to-read"
import { Form, Label, LabelGroup } from "semantic-ui-react";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import './styles.css';

const commonUtils = new CommonUtils();
export interface IMarkdownEditorProps {
    onChange: any;
    value: any;
    name: string;
    disabled?: boolean;
    hideStats?: boolean;
    hideToolbar?: boolean;
    localizationService?: any;
}

export default class MarkdownEditor extends PureComponent<IMarkdownEditorProps> {
    state = {
        value: RichTextEditor.createEmptyValue(),
        markdown: "",
        counter: {},
    };

    static defaultProps = {
        onChange: null,
        value: '',
        disabled: false,
        hideStats: false,
        hideToolbar: false,
    };

    componentDidMount() {
        this.setState({
            value: RichTextEditor.createValueFromString(this.props.value, "markdown"),
            markdown: this.props.value,
        });
        let countingString = this.sanitizeMarkdown(this.props.value);
        Countable.count(countingString, counter => this.setState({ counter }));
    }

    translate(key: string): string {
        if (!this.props.localizationService) { return key; }
        return this.props.localizationService.translate(key);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: RichTextEditor.createValueFromString(
                    this.props.value,
                    "markdown"
                ),
                markdown: this.props.value,
            });
        }
    }

    onChange(value) {
        this.setState(
            {
                value: value,
                markdown: value.toString("markdown"),
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange({
                        name: this.props.name,
                        value: value.toString("markdown"),
                    });
                    let countingString = this.sanitizeMarkdown(value);
                    Countable.count(countingString, counter => this.setState({ counter }));
                }
            }
        );
    }

    private sanitizeMarkdown(value: any) {
        let countingString = removeMarkdown(value.toString("markdown"));
        countingString = countingString
            .replace(/\n/g, ' ')
            .trim()
            .replace(/\r/g, ' ')
            .trim()
            .replace(/[\u200B-\u200D\uFEFF]/g, '');
        return countingString;
    }

    getWordStats() {
        return (
            <Form.Group fluid className="word-stats-container">
                {Object.entries(this.state.counter).length > 0 &&
                    <LabelGroup color="grey" size="small">
                        <Label
                            icon="align left"
                            content={`${parseInt(this.state.counter['words'] || '0')} ${commonUtils.plur(this.translate("word"), this.state.counter['words'])}`}
                        />
                        <Label
                            icon="file alternate outline"
                            content={`${parseInt(this.state.counter['sentences'] || '0')}  ${commonUtils.plur(this.translate("sentence"), this.state.counter['sentences'])}`}
                        />
                        <Label
                            icon="clock"
                            content={<TimeToRead language={localStorage.getItem('lang') || 'en'} content={this.sanitizeMarkdown(this.state.value)} />}
                        />
                    </LabelGroup>
                }
            </Form.Group>
        )
    }

    render() {
        const toolbarConfig = this.props.hideToolbar ? {
            display: [],
        } : {
            // Optionally specify the groups to display (displayed in the order listed).
            display: [
                "INLINE_STYLE_BUTTONS",
                "BLOCK_TYPE_BUTTONS",
                "LINK_BUTTONS",
                // "IMAGE_BUTTON",
                "BLOCK_TYPE_DROPDOWN",
                "HISTORY_BUTTONS",
            ],
            INLINE_STYLE_BUTTONS: [
                { label: this.translate("Bold"), style: "BOLD", className: "custom-css-class" },
                { label: this.translate("Italic"), style: "ITALIC" },
                { label: this.translate("Underline"), style: "UNDERLINE" },
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: this.translate("Paragraph"), style: "unstyled" },
                { label: "H1", style: "header-one" },
                { label: "H2", style: "header-two" },
                { label: "H3", style: "header-three" },
                { label: "H4", style: "header-four" },
                { label: "H5", style: "header-five" },
                { label: "H6", style: "header-six" },
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: "UL", style: "unordered-list-item" },
                { label: "OL", style: "ordered-list-item" },
                { label: this.translate("Blockquote"), style: "blockquote" },
            ],
        };
        return (
            <div>
                <RichTextEditor
                    disabled={this.props.disabled}
                    editorClassName="markdown-editor-textbox"
                    toolbarClassName="markdown-editor-toolbar"
                    value={this.state.value}
                    onChange={(value) => this.onChange(value)}
                    toolbarConfig={toolbarConfig}
                />
                {!this.props.hideStats && this.getWordStats()}
            </div>
        );
    }
}
