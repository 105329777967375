import RouteMenuItemFactory from './RouteMenuItemFactory';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export default class LocalizedRouteMenuItemFactory extends RouteMenuItemFactory {
    constructor(protected localizationService, routes = []) {
        super(routes);
    }

    create(activePath) {
        return this.routes.filter(({ key }) => { return key !== undefined })
            .map(({ key, path = '/' }) => {
                let title = this.localizationService.translate(key);
                return <Dropdown.Item as='a' active={path === activePath} href={path}>{title}</Dropdown.Item>
            });
    }
}