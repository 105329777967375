import React, { PureComponent } from 'react';
import './styles.css'
export interface IBuildingAnimationProps {
    text?: string,
}

export default class BuildingAnimation extends PureComponent<IBuildingAnimationProps> {
    static defaultProps: {
        text: null,
    }

    render() {
        const { text } = this.props;
        return (
            <div id="building">
                <div id="blocks">
                    <div className="b" id="b1"></div>
                    <div className="b" id="b2"></div>
                    <div className="b" id="b3"></div>
                    <div className="b" id="b4"></div>
                </div>
                {text &&
                    <div id="caption">
                        {text}
                    </div>
                }
            </div>
        )
    }
}