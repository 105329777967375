import React, { PureComponent } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import EntryContent from '../EntryContent';
import { Logo } from '../../../elements';

export interface IBrandedEntryContentProps {
    className?: string;
    useFullLogo?: boolean;
    header?: any;
    children?: any;
    footer?: any;
    stacked: boolean;
}

export default class BrandedEntryContent extends PureComponent<IBrandedEntryContentProps> {
    static defaultProps = {
        stacked: true,
    }

    render() {
        const { className, useFullLogo, header, children, footer } = this.props;
        return (
            <EntryContent className={className}>
                <Header as='h2' textAlign='center'>
                    <Logo full={useFullLogo} />
                </Header>
                {header && (
                    <Header.Subheader textAlign='center'>
                        {header}
                    </Header.Subheader>
                )}
                <Segment stacked={this.props.stacked}>
                    {children}
                </Segment>
                {footer}
            </EntryContent>
        );
    }
}