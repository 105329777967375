import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface ITax {
    name: string;
    rate: number;
    rateType: string;
    registrationNumber: string;
}

export interface ITaxesEditPageProps extends IPageProps {
    item?: ITax;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    breadcrumbLevels?: any,
    localizationService: any,
}

export default class TaxesEditPage extends PureComponent<ITaxesEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, localizationService, Gatekeeper, breadcrumbLevels } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        name={item && item.name}
                        rate={item && item.rate}
                        rateType={item && item.rateType}
                        registrationNumber={item && item.registrationNumber}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                    />
                </Component>
            </Page>
        );
    }
}