import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../../LocalizedPage';
import Component from "../../../layouts/component";

export interface ITemplatesEditPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    item: any;
    layouts: any[];
    sections: any[];
    breadcrumbLevels: any[];
    handleCreateTemplateLayout: (data) => any;
    handleCreateTemplateSectionLayout: (data) => any;
}

export default class TemplatesEditPage extends PureComponent<ITemplatesEditPageProps> {
    render() {
        const { createRoutes, Gatekeeper, item, layouts, sections, breadcrumbLevels, handleCreateTemplateLayout,handleCreateTemplateSectionLayout } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage Gatekeeper={Gatekeeper} item={item} layouts={layouts} sections={sections} handleCreateTemplateLayout={handleCreateTemplateLayout} handleCreateTemplateSectionLayout={handleCreateTemplateSectionLayout} />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
