export default {
    "host": process.env.REACT_APP_API_ARCHETYPE_HOST,
    "port": process.env.REACT_APP_API_ARCHETYPE_PORT,
    "basePath": process.env.REACT_APP_API_ARCHETYPE_BASE_PATH,
    "path": "archetypes",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'archetypes';

export const ARCHETYPE_OPTIONS = {
    secure: {
        getOne: true,
        get: true,
        create: true,
        update: true,
        delete: true,
        count: true,
        link: true,
        unlink: true,
    }
}