import React, { PureComponent } from "react";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import MarkdownEditor from "../../../controls/MarkdownEditor";
import ReactMarkdown from "react-markdown";

const commonUtils = new CommonUtils();

export interface IContentEditionFormProps {
    content?: any;
    isMobile?: boolean;
    edit?: boolean;
    handleChange?: ({ name, value }) => any;
    toggleModal: (toggleModal: boolean) => void;
    onSave: (data: any) => void;
    localizationService: any;
}

export interface IContentEditionFormState {
    content: any;
}

export default class ContentEditionForm extends PureComponent<IContentEditionFormProps, IContentEditionFormState> {

    static defaultProps = {
        onSave: (data) => { console.log(data) },
        edit: false,
    };

    state = {
        content: {},
    };

    componentDidMount() {
        if (this.props.content) {
            let content = { fr: {}, en: {} };
            content.fr = this.props.content.filter((content) => content.language == 'fr');
            content.en = this.props.content.filter((content) => content.language == 'en');
            this.setState({ content });
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps !== this.props) {
    //         this.setState({
    //             id: this.props.content ? this.props.content['id'] : '',
    //             title: this.props.content ? this.props.content['title'] : '',
    //             content: this.props.content ? this.props.content['content'] : '',
    //             language: this.props.content ? this.props.content['language'] : '',
    //         })
    //     }
    // }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private handleTitleChange(language, value) {
        let { content } = this.state;
        if (content[language].length > 0) {
            content[language][0]['title'] = value;
        } else {
            content[language].push({ title: value, language, status: 'draft' });
        }
        this.setState({ content });
    }

    handleContentChange(language, value) {
        let { content } = this.state;
        if (content[language].length > 0) {
            content[language][0]['content'] = value.value;
        } else {
            content[language].push({ content: value.value, language, status: 'draft' });
        }
        this.setState({ content });
    }

    async submit() {
        try {
            console.log(this.state.content)
            // await this.props.onSave(this.state);
        } catch (e) {

        }
    }

    render() {
        const languageOptions = commonUtils.generateLanguagesMap();

        return (
            <>
                <Modal.Header>{this.translate(`${this.props.edit ? 'Edit' : 'Add'} a translation`)}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Form onSubmit={() => this.submit()} widths={'equal'} className='product-definition-edit-content'>
                                        <Form.Input label={this.translate('Title')}>
                                            <input placeholder={this.translate('Title')} autoFocus defaultValue={this.state.content['en'] && this.state.content['en'].length ? this.state.content['en'][0]['title'] : ''} onChange={(e) => this.handleTitleChange('en', e.target.value)} />
                                        </Form.Input>
                                        <label>{this.translate('Content')}</label>
                                        <MarkdownEditor localizationService={this.props.localizationService} name='content' value={this.state.content['en'] && this.state.content['en'].length ? this.state.content['en'][0]['content'] : ''} onChange={(value) => this.handleContentChange('en', value)} />
                                    </Form>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form onSubmit={() => this.submit()} widths={'equal'} className='product-definition-edit-content'>
                                        <Form.Input label={this.translate('Title')}>
                                            <input placeholder={this.translate('Title')} autoFocus defaultValue={this.state.content['fr'] && this.state.content['fr'].length ? this.state.content['fr'][0]['title'] : ''} onChange={(e) => this.handleTitleChange('fr', e.target.value)} />
                                        </Form.Input>
                                        <label>{this.translate('Content')}</label>
                                        <MarkdownEditor localizationService={this.props.localizationService} name='content' value={this.state.content['fr'] && this.state.content['fr'].length ? this.state.content['fr'][0]['content'] : ''} onChange={(value) => this.handleContentChange('fr', value)} />
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        {this.translate('Discard Changes')}
                    </Button> <Button
                        content={"Save Translation"}
                        labelPosition='right'
                        icon='save'
                        onClick={() => { this.submit(); }}
                        positive
                        disabled // disabling until submit is properly implemented
                    />
                </Modal.Actions>
            </>
        )
    }
}