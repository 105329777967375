import React from 'react';
import { toast } from 'react-toastify';
import { Form, Message, Accordion, Icon, Button } from 'semantic-ui-react';
import MarkdownEditor from '../../../controls/MarkdownEditor';
import SelectInput from '../../../controls/SelectInput';
import { LoadingMessage } from '../../../layouts/messages';
import StateAwareForm, {
    IStateAwareFormProps,
} from '../../StateAwareForm/StateAwareForm';
import DeleteButton from '../../../controls/buttons/DeleteButton/DeleteButton';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import './style.css';

const commonUtils = new CommonUtils();

export interface IProductCatalogFormProps extends IStateAwareFormProps {
    title: string;
    language: string;
    content: string;
    localizationService: any;
}
export default class ProductCatalogForm extends StateAwareForm<IProductCatalogFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => void;
        status: 'draft';
        readOnly: false;
    };

    componentDidMount() {
        super.setState({
            title: this.props.title,
            language: this.props.language,
            content: this.props.content,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                title: this.props.title,
                language: this.props.language,
                content: this.props.content,
            });
        }
    }

    async submit() {
        try {
            await this.handleSubmit();
            toast.success('ProductCatalog successfully updated.');
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    generateLanguagesMap() {
        return commonUtils.generateLanguagesMap().map(language => {
            return { key: language.key, text: language.realName, value: language.value };
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeAccordeonIndex } = this.state
        const newIndex = activeAccordeonIndex === index ? -1 : index

        this.setState({ activeAccordeonIndex: newIndex })
    }

    encapsulateAccordeon() {
        const { activeAccordeonIndex } = this.state

        return (
            <Form.Group fluid>
                <Accordion fluid style={{ margin: '10px auto' }} styled>
                    <Accordion.Title
                        active={activeAccordeonIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        {this.translate('General Information')}
                    </Accordion.Title>
                    <Accordion.Content active={activeAccordeonIndex === 0}>
                        {this.renderGeneralInformation()}
                    </Accordion.Content>
                </Accordion>
            </Form.Group>
        )
    }

    renderGeneralInformation() {
        return (
            <>
                <Form.Group fluid>
                    <Form.Input
                        width={'15'}
                        fluid
                        label={this.translate("Title")}
                        placeholder={this.translate("Title")}
                        defaultValue={this.props.title}
                        name="title"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <SelectInput
                        options={this.generateLanguagesMap()}
                        search
                        label={this.translate('Language')}
                        placeholder={this.translate('Language')}
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>
            </>
        )
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form
                onSubmit={() => this.submit()}
                error={!!error}
                style={{ zIndex: '0' }}
            >
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Product Catalog"
                />
                <Message
                    error
                    header="Could not save catalog"
                    content={error && error.toString()}
                />
                <Button.Group floated="right" className='form-right-toolbar-container'>
                    <Button disabled={loading}>{this.translate('Save')}</Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="catalog"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </Button.Group>
                <div style={{ display: 'block', clear: 'both' }}>
                    {this.props.title ? this.encapsulateAccordeon() : this.renderGeneralInformation()}
                </div>
                <label className={'mobile-product-content-header'}>
                    {this.translate('Product Catalog Description')}
                </label>
                <MarkdownEditor
                    value={this.props.content}
                    name="content"
                    onChange={({ name, value }) =>
                        this.handleChange({ name, value })
                    }
                    localizationService={this.props.localizationService}
                />
            </Form>
        );
    }
}
