import React, { PureComponent } from 'react';
import Component from '../../layouts/component';
import LocalizedPage from '../LocalizedPage';
import { IPageProps } from '../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IPost {
    title: string;
    language: string;
    content: any;
    featured: boolean;
    slug: string;
    category?: string[];
    status: string;
    date: string;
}

export interface IBlogEditPageProps extends IPageProps {
    item?: IPost;
    webmeta?: any;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
}

export default class BlogEditPage extends PureComponent<IBlogEditPageProps> {
    render() {
        const { createRoutes, item, webmeta, onChange, onSave, onDelete, Gatekeeper, localizationService, breadcrumbLevels } =
            this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        featured={item && item.featured}
                        slug={item && item.slug}
                        category={item && item.category}
                        date={item && item.date}
                        status={item && item.status}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        webmeta={webmeta}
                    />
                    <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        featured={item && item.featured}
                        slug={item && item.slug}
                        category={item && item.category}
                        date={item && item.date}
                        status={item && item.status}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                    />
                </Component>
            </Page>
        );
    }
}
