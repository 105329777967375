import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../LocalizedPage';
import Component from "../../layouts/component";

export interface IPagesOverviewPageProps {
    createRoutes?: Function;
    item: any;
    Gatekeeper: any;
    sections?: any[];
    locations?: any[];
    pageSections: any;
    breadcrumbLevels?: any;
    isFetching: boolean;
    handleLinkSection: (data: string) => void;
    handleUnlinkSection: (data: string) => void;
    handleUpdatePageSection: (data: any) => void;
    swapPositions: (data: string) => void;
    handlePublishUnpublish: (data: string) => void;
}

export default class PagesOverviewPage extends PureComponent<IPagesOverviewPageProps> {
    render() {
        const { createRoutes, item, Gatekeeper, breadcrumbLevels, pageSections, sections,locations, handleLinkSection, handleUnlinkSection, swapPositions,handlePublishUnpublish,handleUpdatePageSection,isFetching } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        item={item}
                        sections={sections}
                        Gatekeeper={Gatekeeper}
                        pageSections={pageSections}
                        locations={locations}
                        handleLinkSection={async (data) => handleLinkSection(data)}
                        handleUnlinkSection={async (data) => handleUnlinkSection(data)}
                        swapPositions={async (data) => swapPositions(data)}
                        handlePublishUnpublish={async (data) => handlePublishUnpublish(data)}
                        handleUpdatePageSection={async (data) => handleUpdatePageSection(data)}
                        isFetching={isFetching}
                    />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
