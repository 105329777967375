import React from 'react';
import ProductsAttributesPane from '../../../components/pages/products/ProductOverviewPage/panes/ProductsAttributesPane';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import Repository from '../../../repositories/Repository';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductOverviewProductAttributesController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);
        // add productRepo + attributeRepo
        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getOne(this.params?.id);
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductsAttributesPane
                items={items}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}