import React from 'react';
import PagesPage from '../../components/pages/PagesPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class PageController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private applicationRepository: Repository;
    private securityService: SecurityService;

    state = {
        items: [],
        submitted: [],
        hasProvisionned: false,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, repository, applicationRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.applicationRepository = applicationRepository;
        this.securityService = securityService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    private async hasProvisionned() {
        const applicationId = this.securityService.getApplicationId();
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e);
            return false;
        }

        return items.length > 0;
    }

    async componentDidMount() {
        let items = await this.get();
        let hasProvisionned = await this.hasProvisionned();
        let submitted = items.filter(value => value.status == 'submitted')

        this.setState({
            items,
            submitted,
            hasProvisionned,
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/website-management/pages/${id}`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/website-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Pages'), value: '/website-management/pages' },
        ]
    }

    render() {
        const { items, submitted, hasProvisionned } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <PagesPage
                items={items}
                submitted={submitted}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onChange={(path) => this.changeSettings(path)}
                hasProvisionned={hasProvisionned}
            />
        )
    }
}