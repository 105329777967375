import React, { PureComponent } from 'react';
import { SketchPicker } from 'react-color';
import './styles.css';

export interface IColorPickerProps {
    handleColor: Function;
    color?: string;
}

export default class ColorPicker extends PureComponent<IColorPickerProps> {
    static defaultProps = {
        handleColor: () => { },
        color: '#F17013',
    }

    state = {
        displayColorPicker: false,
        color: undefined,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { displayColorPicker } = prevState;
        const { color } = nextProps;

        if (!displayColorPicker && color !== prevState.color) {
            return { color };
        }

        return null;
    }

    handleColorClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleColorClose = () => {
        this.setState({ displayColorPicker: false })
        this.props.handleColor(this.state.color);
    };

    handleColorChange = (color) => {
        this.setState({
            color: color.hex,
        })
    };

    render() {
        const { color } = this.state;

        return (
            <div className='colorPicker'>
                <div className={'swatch'} onClick={this.handleColorClick}>
                    <div className={'color'} style={{ background: color }} />
                </div>
                {this.state.displayColorPicker ?
                    <div className={'popover'}>
                        <div className={'cover'} onClick={this.handleColorClose} />
                        <SketchPicker color={color} onChange={this.handleColorChange} disableAlpha />
                    </div> : null}
            </div>
        )
    }
}