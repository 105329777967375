import React from 'react';
import ResourceSettingsPage from '../../components/pages/SettingsPage/ResourceSettingsPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class ResourceController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private permissionsRepository: Repository;

    state = {
        items: []
    };

    constructor(props) {
        super(props);
        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory, permissionsRepository] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.permissionsRepository = permissionsRepository;
    }

    protected changeSettings(path) {
        this.router.redirect(`/settings/${path}`);
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
            items.sort(function (a, b) {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                return 0;
            });
        } catch (e) {
            console.error(e)
            return [];
        }

        items = await Promise.all(items.map(async (item) => {
            try {
                item.permissions = await this.permissionsRepository.find({ resourceId: item.id });
            } catch (e) {
                console.error(e)
                item.permissions = [];
            }

            return item;
        }));

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();
        const { items } = this.state;

        return this.prepare(
            <ResourceSettingsPage
                
                onChange={(path) => this.changeSettings(path)}
                Gatekeeper={Gatekeeper}
                items={items}
            />
        )
    }
}