export default {
    "host": process.env.REACT_APP_API_TAXES_HOST,
    "port": process.env.REACT_APP_API_TAXES_PORT,
    "basePath": process.env.REACT_APP_API_TAXES_BASE_PATH,
    "path": "taxes",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'taxes';

export const TAXES_OPTIONS = {
    secure: {
        getOne: true,
        get: true,
        create: true,
        update: true,
        delete: true,
        count: true,
        link: true,
        unlink: true,
    }
}