import React, { PureComponent } from "react";
import { Button, Segment, Tab } from "semantic-ui-react";
import { DeleteButton, ReturnButton } from "../../../controls";
import { MobileSkeleton } from "../../../layouts/skeleton";
import "./styles.css";

export interface ILicenseMobilePageProps {
    name: string;
    type: string;
    level: string;
    features: any;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    panes: any;
}

export default class MobilePage extends PureComponent<ILicenseMobilePageProps> {
    static defaultProps = {
        name: "",
        type: "",
        level: "",
        features: undefined,
        panes: [],
        onDelete: () => { },
    };

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        const panes = this.props.panes || [];
        const onDelete = this.props.onDelete;

        return (
            <MobileSkeleton>
                <Gatekeeper name="license-management">
                    <div className={"license-form-container"}>
                        {this.props.name && (
                            <div style={{marginBottom: '50px'}}>
                                <Button.Group>
                                    <ReturnButton
                                        href={`/licenses`}
                                        itemType={'licenses'}
                                    />
                                </Button.Group>
                                <Button.Group floated="right" className='form-right-toolbar-container'>
                                    <DeleteButton
                                        handleOnClick={onDelete}
                                        icon={'trash'}
                                        itemName={this.props.name}
                                        itemType={'license'}
                                    />
                                </Button.Group>
                            </div>
                        )}
                            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
