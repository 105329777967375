import React, { PureComponent } from "react";
import { Grid, FormGroup, FormInput, Icon, Header, Placeholder, PlaceholderParagraph, PlaceholderLine, Container, Label, LabelGroup, Button, ButtonContent } from "semantic-ui-react";
import FeaturesLinkForm from "../../../../../forms/features/FeaturesLinkForm";
import { DesktopSkeleton } from "../../../../../layouts/skeleton";
import CommonUtils from "../../../../../../utilities/Common/CommonUtils";
import "./styles.css";

const commonUtils = new CommonUtils();
export interface ILicenseDesktopPageProps {
    name: string;
    type: string;
    level: string;
    features: any[];
    licenseFeatures: any[];
    selectedLicenses: any[];
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    fetchSelectedLicenses?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    toastConfig: any;
}

export interface ILicenseDesktopPageState {
    features: any[];
    filteredNumberResults: number;
    searchInputHasValue: boolean;
    searchvalue: string;
}

export default class DesktopPage extends PureComponent<ILicenseDesktopPageProps, ILicenseDesktopPageState> {
    static defaultProps = {
        name: "",
        type: "",
        level: "",
        features: undefined,
        selectedLicenses: undefined,
    };

    state = {
        features: [],
        filteredNumberResults: 0,
        searchInputHasValue: false,
        searchvalue: '',
    };

    componentDidMount() {
        this.setState({
            features: this.props.features ? this.props.features : [],
            filteredNumberResults: this.props.features ? this.props.features.length : 0,
        })
    }

    componentDidUpdate(prevProps: Readonly<ILicenseDesktopPageProps>, prevState: Readonly<ILicenseDesktopPageState>, snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({
                features: this.props.features ? this.props.features : [],
                filteredNumberResults: this.props.features ? this.props.features.length : 0,
            })
        }
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    filterFeatures(name: string) {
        const { features } = this.props;
        if (!features) { return; }
        let filteredFeatures = features.filter(function (feature) { return feature.name.toLowerCase().indexOf(name.toLowerCase()) >= 0; });
        this.setState({ features: filteredFeatures, filteredNumberResults: filteredFeatures.length, searchInputHasValue: name.length > 0, searchvalue: name });
    }

    resetTextInput() {
        this.filterFeatures('');
    }

    async fetchSelectedLicenses() {
        if (this.props.fetchSelectedLicenses) {
            await this.props.fetchSelectedLicenses();
        }
    }

    renderPills() {
        const pills = this.props.selectedLicenses.map(feats => {
            return <Label icon circular color='grey' as='a' href='#'><span style={{ padding: '0px 0.25rem' }}>{feats.name}{/*<Icon name='broken chain' style={{marginLeft: '1rem'}} />*/}</span></Label>
        })
        return <LabelGroup children={pills} />
    }

    render() {
        const { Gatekeeper, features, licenseFeatures, toastConfig } = this.props;
        return (
            <DesktopSkeleton>
                <Gatekeeper name="license-management">
                    <Grid columns={'1'}>
                        <Grid.Column width={'5'}>
                            <FormGroup>
                                <FormInput placeholder="Search feature by name" value={this.state.searchvalue} onChange={(event) => this.filterFeatures(event.target.value)} type='text' icon={(e) => {
                                    if (this.state.searchInputHasValue) {
                                        return <Icon name='delete' link onClick={() => { this.resetTextInput() }} />
                                    }
                                    return <Icon name='search' />
                                }} />
                                <small className="text-muted">Now showing {this.state.filteredNumberResults} {commonUtils.plur('result', 'results', this.state.filteredNumberResults)}{this.state.searchInputHasValue && ` for ${this.state.searchvalue}`}.</small>
                            </FormGroup>
                        </Grid.Column>
                        <Grid.Column width={'11'}>
                            <Grid columns={2} padded>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={1}>
                                        <Button size="tiny" icon='refresh' onClick={async () => { await this.fetchSelectedLicenses() }}>
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <h3>License Features Summary</h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {/* </div> */}
                            {this.props.selectedLicenses.length == 0 && <div style={{ "overflowY": "auto", "height": "75px", "marginBottom": "15px" }}>This license currently has no features associated to it.</div>}
                            {this.props.selectedLicenses.length > 0 && <Container fluid style={{ "overflowY": "auto", "height": "75px", "marginBottom": "15px" }}>{this.renderPills()}.</Container>}

                        </Grid.Column>
                    </Grid>
                    <FeaturesLinkForm
                        features={this.state.features}
                        licenseFeatures={licenseFeatures}
                        onSubmit={(data) => this.props.onSave(data)}
                        toastConfig={toastConfig}
                    />
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
