import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import ContactsPane from '../../components/pages/accounts/CustomerOverviewPage/panes/ContactsPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountContactsController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/contacts`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    async handleSave(data: any) {
        let item;

        if (data?.id) {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                item = await this.repository.updateByPath(data, `accounts/${this.params?.id}/contacts/${data?.id}`);
            } catch (e) {
                console.error(e)
                throw Error("Failed to update the contact. Please try again.");
            }
        } else {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                item = await this.repository.createByPath(data, `accounts/${this.params?.id}/contacts`);
            } catch (e) {
                console.error(e)
                throw Error("Failed to create the contact. Please try again.");
            }
        }

        return item;
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    private async handleDelete(item) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.deleteByPath(`accounts/${this.params?.id}/contacts/${item.id}`);
        } catch (e) {
            throw e;
        }

        await this.refreshItems();
    }

    render() {
        const { items } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="account-contact-management"
                or={["account-contact-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <ContactsPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSave={async (data) => this.handleSave(data)}
                    onModalClose={async () => this.refreshItems()}
                    onDelete={async (data) => this.handleDelete(data)}
                />
            </Gatekeeper>
        )
    }
}