import React, { PureComponent } from 'react';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IUserSettingsPageProps extends IPageProps {
    onChange: (path: string) => any
}

export default class UserSettingsPage extends PureComponent<IUserSettingsPageProps> {
    render() {
        const { createRoutes, header, onChange } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <DesktopPage
                    header={header}
                    onChange={onChange}
                />
            </Page>
        );
    }
}