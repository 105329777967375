import React from 'react';
import FeaturesEditPage from '../../components/pages/FeaturesEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class FeaturesCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private resourcesRepository: Repository;

    state = {
        item: undefined,
        resources: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, resourcesRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.resourcesRepository = resourcesRepository;
    }

    private configureToast() {
        return (
            {
                autoClose: 1500,
            }
        );
    }

    private async getResources() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.resourcesRepository.get();
            items.sort(function (a, b) {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                return 0;
            });
        } catch (e) {
            return;
        }

        return items;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;
        try {
            item = await this.repository.create(data);
            this.router.redirect(`/features/${item.id}/edit`);
        } catch (e) {
            throw e;
        }
    }


    async componentDidMount() {
        let resources = await this.getResources();

        this.setState({
            item: undefined,
            resources,
        });
    }

    render() {
        const { item, resources } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <FeaturesEditPage
                
                item={item}
                resources={resources}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                toastConfig={this.configureToast()}
            />
        )
    }
}