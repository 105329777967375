import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IApplicationInstancesGeneralInformationPageProps extends IPageProps {
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    createProperty?: () => void;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    productDefinition: any,
    productPropertyAttributes: any[],
    productSelectionAttributes: any[],
}

export default class ApplicationInstancesGeneralInformationPage extends PureComponent<IApplicationInstancesGeneralInformationPageProps> {
    render() {

        const {
            createRoutes,
            onChange,
            onSave,
            onDelete,
            Gatekeeper,
            localizationService,
            breadcrumbLevels,
            createProperty,
            productDefinition,
            productPropertyAttributes,
            productSelectionAttributes
        } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        productDefinition={productDefinition}
                        productPropertyAttributes={productPropertyAttributes}
                        productSelectionAttributes={productSelectionAttributes}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        createProperty={createProperty}
                    />
                    {/* <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                    /> */}
                </Component>
            </Page>
        );
    }
}
