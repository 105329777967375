import React, { PureComponent } from 'react';
import { LoadingSpinner } from '../../controls';
import { ContactFormSectionForm, HeroSectionForm, MapWithOpeningHoursSectionForm, TextSectionForm } from '../../forms/sections';
import CheckoutSectionForm from '../../forms/sections/CheckoutSectionForm';
import FlowSectionForm from '../../forms/sections/FlowSectionForm';
import IndividualServiceSectionForm from '../../forms/sections/IndividualServiceSectionForm';
import CartSectionForm from '../../forms/sections/CartSectionForm';
import ProductSectionForm from '../../forms/sections/ProductSectionForm';
import SectionTypeSelectorForm from '../../forms/sections/SectionTypeSelectorForm';
import TableSectionForm from '../../forms/sections/TableSectionForm';
import UnauthorizedMessage from '../../layouts/messages/UnauthorizedMessage';
import { DesktopSkeleton } from '../../layouts/skeleton';
import "./styles.css";

export interface ISectionDesktopPageProps {
    title: string;
    name: string;
    language: string;
    type: string;
    content: string;
    additional?: any;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    additionalData: any;
}

export default class DesktopPage extends PureComponent<ISectionDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        title: "",
        name: "",
        language: "",
        type: "",
        content: "",
        additionalData: {},
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type !== this.props.type) {
            this.setState({
                type: this.props.type
            })
        }
    }

    resolveAdditional(type: string) {
        return {
            "text": undefined,
            "hero": {
                "tagline": "",
                "backgroundImageUrl": "",
                "callToAction": "",
                "callToActionLink": "",
            },
            "contact-form": {
                "name": {
                    "label": "",
                    "placeholder": "",
                },
                "phone": {
                    "label": "",
                    "placeholder": "",
                },
                "email": {
                    "label": "",
                    "placeholder": "",
                },
                "message": {
                    "label": "",
                    "placeholder": "",
                },
                "submit": {
                    "label": "",
                },
            },
            "product": {
                "rows": "",
                "columns": "",
                "callToAction": "",
                "callToActionLink": "",
                "query": {},
            },
            "map-with-open-hours": {
                "address": "",
                "storefrontId": "",
                "openingHoursTitle": ""
            },
            "individual-service": {
                "imageLink": "",
                "imagePosition": "",
                "featured": "",
                "callToAction": "",
                "callToActionLink": "",
            },
            "cart": {},
            "checkout": {
                "contact": {
                    "name": {
                        "label": "",
                        "placeholder": "",
                    },
                    "phone": {
                        "label": "",
                        "placeholder": "",
                    },
                    "email": {
                        "label": "",
                        "placeholder": "",
                    },
                },
                "pickup": {
                    "title": "Pick up time",
                    "standard": {
                        "label": "Standard",
                    },
                    "scheduled": {
                        "label": "Schedule for later",
                        "description": "Choose a time"
                    }
                },
                "payments": {
                    "title": "Select a payment option",
                    "cash": {
                        "label": "Cash",
                        "description": "Handled at the store"
                    },
                    "stripe": {
                        "label": "Card",
                        "description": "Pay now"
                    }
                },
                "storedetails": {
                    "title": "Store Details",
                }
            },
            "flow": {
                "location": "",
            }
        }[type];
    }

    handleSectionTypeSelection({ type }) {
        this.setState({
            type,
            additional: this.resolveAdditional(type)
        });
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="website-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="editor-container">
                        {!this.state.type && (
                            <SectionTypeSelectorForm
                                onSubmit={data => this.handleSectionTypeSelection(data)}
                            />
                        )}

                        {"text" === this.state.type && (
                            <TextSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                content={this.props.content}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"hero" === this.state.type && (
                            <HeroSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"contact-form" === this.state.type && (
                            <ContactFormSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"table" === this.state.type && (
                            <TableSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"product" === this.state.type && (
                            <ProductSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                content={this.props.content}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"map-with-open-hours" === this.state.type && (
                            <MapWithOpeningHoursSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                                storefronts={this.props.additionalData?.storefronts}
                            />
                        )}

                        {"individual-service" === this.state.type && (
                            <IndividualServiceSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                content={this.props.content}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"checkout" === this.state.type && (
                            <CheckoutSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"cart" === this.state.type && (
                            <CartSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"flow" === this.state.type && (
                            <FlowSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}