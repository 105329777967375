import React, { PureComponent } from 'react';
import { Button, Card, Divider, Form, Grid, Header, Icon, Label, Modal } from 'semantic-ui-react';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import PropertyAttributeForm from './modalforms/PropertyAttributeForm';
import SelectionAttributeForm from './modalforms/SelectionAttributeForm';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import QuickTaxCreationForm from './modalforms/QuickTaxCreationForm';
import QuickContentCreationForm from './modalforms/QuickContentCreationForm';
import { toast } from 'react-toastify';
import { LoadingNavPlaceholder } from '../../../controls';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IProductDesktopPageProps {
    productDefinitionId: string;
    title: string;
    language: string;
    content: any;
    category: string;
    price: string;
    brand: string;
    webmeta: any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    taxes: any[];
    productTaxes: any[];
    properties: any[];
    selections: any[];
    contents: any[];
    mainContent?: any;
    isFetching: boolean;
    onChange?: (key: string, value: any) => void;
    onAttributesChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onCancel?: () => void;
    onAttributesSave: (data: any) => any;
    onSave: (data: any) => any;
    onContentSave: (data: any) => any;
    onContentUpdate: (data: any) => any;
    createProperty?: (type: string) => any;
    deleteProductAttribute?: (id: any) => any;
    handleLinkTax: (data: any) => any;
    handleUnlinkTax: (data: any) => any;
    handleCreateTax: (data: any) => any;
    onContentDelete: (data: any) => any;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps> {
    static defaultProps = {
        productDefinitionId: '0',
        title: '',
        language: '',
        content: '',
        category: '',
        price: 0,
        brand: '',
        webmeta: {},
    };

    state = {
        openModal: false,
        attribute: undefined,
        openSelectionModal: false,
        openTaxModal: false,
        openContentModal: false,
        item: {},
        webmeta: {},
        title: '',
        content: '',
        language: '',
        editContent: false,
        contentItem: {},
    }

    componentDidUpdate(prevProps: Readonly<IProductDesktopPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps !== this.props) {
            let item = {};
            let webmeta = {};
            item['category'] = this.props.category;
            item['price'] = this.props.price;
            item['brand'] = this.props.brand;
            if (this.props.webmeta) {
                item['slug'] = this.props.webmeta.slug;
            }
            this.setState({ item, webmeta });
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    // attributes
    private displayModal(openModal: boolean = true, attribute: any = undefined) {
        this.setState({ openModal, attribute });
    }

    private displaySelectionModal(openSelectionModal: boolean = true, attribute: any = undefined) {
        this.setState({ openSelectionModal, attribute });
    }

    private displayTaxModal(openTaxModal: boolean = true) {
        this.setState({ openTaxModal });
    }

    private displayContentModal(openContentModal: boolean = true, editContent: boolean = false) {
        const { mainContent } = this.props;

        if (!openContentModal) {
            this.setState({ openContentModal, title: undefined, content: undefined, language: undefined, editContent, contentItem: {} });
        } else {
            this.setState({ openContentModal, title: mainContent.title, content: mainContent.content, language: mainContent.language, editContent, contentItem: mainContent });
        }
    }

    private displayEditContentModal(openContentModal: boolean = true, content: any, editContent: boolean = true) {
        this.setState({
            openContentModal,
            title: content.title,
            content: content.content,
            language: content.language,
            editContent,
            contentItem: content,
        });
    }

    private preparePropertyAttributes() {
        const { properties } = this.props;
        let results = properties.map((property, index) => {
            const { name, options, id } = property;
            return (<Card fluid>
                <Card.Content>
                    <Card.Header>{name}</Card.Header>
                    <Card.Description>{options instanceof Array ? options.join() : options}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button basic icon='edit' color='teal' onClick={() => this.displayModal(true, property)}>
                        {this.translate('Edit')}
                    </Button>
                    <Button icon='trash' color='red' onClick={() => { this.props.deleteProductAttribute && this.props.deleteProductAttribute(id) }}>
                        {this.translate('Delete')}
                    </Button>
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    private prepareOptionAttributes() {
        const { selections } = this.props;
        let results = selections.map((property) => {
            const { name, options, id } = property;
            let optionTags = options.map((option) => {
                return (<Label basic color='black' content={option} />)
            });

            return (<Card fluid>
                <Card.Content>
                    <Card.Header>{property.name}</Card.Header>
                    <Card.Description>{optionTags}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button basic icon='edit' color='teal' onClick={() => this.displaySelectionModal(true, property)}>
                        {this.translate('Edit')}
                    </Button>
                    <Button icon='trash' color='red' onClick={() => { this.props.deleteProductAttribute && this.props.deleteProductAttribute(id) }}>
                        {this.translate('Delete')}
                    </Button>
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    saveAttribute(attribute) {
        this.props.onAttributesSave(attribute);
        this.displayModal(false);
        this.displaySelectionModal(false);
    }

    // end attributes

    // taxes

    private handleLink(data: string) {
        if (this.props.handleLinkTax) {
            this.props.handleLinkTax(data);
        }
    }

    private handleUnlink(data: string) {
        if (this.props.handleUnlinkTax) {
            this.props.handleUnlinkTax(data);
        }
    }

    private handleCreateTax(data: any) {
        if (this.props.handleCreateTax) {
            this.props.handleCreateTax(data);
            this.setState({ openTaxModal: false })
        }
    }

    private prepareTaxes() {
        const { taxes, productTaxes } = this.props;
        if (!taxes || !productTaxes) { return; }
        let results = taxes.map((property, index) => {
            const { name, rateType, rate, id } = property;
            const isChecked = productTaxes.findIndex((definitionTax) => { return definitionTax.id === id; }) >= 0;
            const icon = isChecked ? <Icon name='check circle' color='blue' /> : <Icon name='check circle' />;
            return (<Card link onClick={() => isChecked ? this.handleUnlink(id) : this.handleLink(id)}>
                <Card.Content>
                    <Card.Header>{name}</Card.Header>
                    <Card.Description>{rateType === 'percentage' ? `${rate}%` : commonUtils.renderCurrency(rate)}</Card.Description>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    {icon}
                </Card.Content>
            </Card>
            )
        })
        return results;
    }


    // end taxes

    private handleSaveContent(data: any) {
        if (this.state.editContent && this.props.onContentUpdate) {
            this.props.onContentUpdate(data);
            this.setState({ openContentModal: false });
        }
        else if (!this.state.editContent && this.props.onContentSave) {
            this.props.onContentSave(data);
            this.setState({ openContentModal: false });
        }
    }

    private saveAndContinue() {
        try {
            this.props.onSave(this.state.item);
        } catch (e) {
            toast.error(`Error: ${e.message}`);
        }
    }

    private onContentDelete(data: any) {
        try {
            if (this.props.onContentDelete) {
                this.props.onContentDelete(data);
                toast.success("Translation successfully deleted.")
            }
        } catch (e) {
            toast.error(`Error: ${e.message}`);
        }
    }

    onChange(key: any, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private prepareContent() {
        const { contents } = this.props;
        if (!contents) { return; }
        let results = contents.map((content, index) => {

            return (<Card>
                <Label attached='bottom' basic>
                    <span style={{ float: 'left', textAlign: 'left' }}>{commonUtils.renderLanguageFlag(content.language)} {commonUtils.renderLanguageRealName(content.language)}</span>
                    <span style={{ float: 'right' }}>{this.translate(content.status)}</span>
                </Label>
                <Card.Content>
                    <Card.Header>
                        {content.title}
                    </Card.Header>
                    <Card.Description>{content.content}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button content={this.translate('Edit')} color='teal' basic onClick={() => { this.displayEditContentModal(true, content, true) }} />
                    {index > 0 && <Button content={this.translate('Delete')} color='red' onClick={() => { this.onContentDelete(content.id) }} />}
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    renderModal() {

    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                >
                    <div style={{ marginBottom: '5rem' }}>
                        <Modal open={this.state.openModal}>
                            <PropertyAttributeForm localizationService={this.props.localizationService} type='property' attribute={this.state.attribute} toggleModal={(display) => this.displayModal(display)} createAttribute={(attribute) => this.saveAttribute(attribute)} />
                        </Modal>
                        <Modal open={this.state.openSelectionModal}>
                            <SelectionAttributeForm localizationService={this.props.localizationService} type='selection' attribute={this.state.attribute} toggleModal={(display) => this.displaySelectionModal(display)} createAttribute={(attribute) => this.saveAttribute(attribute)} />
                        </Modal>
                        <Modal open={this.state.openTaxModal}>
                            <QuickTaxCreationForm localizationService={this.props.localizationService} type='tax' onSave={(data) => this.handleCreateTax(data)} attribute={this.state.attribute} toggleModal={() => this.displayTaxModal(false)} />
                        </Modal>
                        <Modal size='fullscreen' open={this.state.openContentModal}>
                            <QuickContentCreationForm localizationService={this.props.localizationService} edit={this.state.editContent} content={this.state.contentItem} onSave={(data) => this.handleSaveContent(data)} toggleModal={() => this.displayContentModal(false)} />
                        </Modal>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h3'>{this.translate('General Information')}</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {this.props.isFetching &&
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <LoadingNavPlaceholder />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {!this.props.isFetching && <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <Form.Group widths={'equal'}>
                                            <Form.Input
                                                fluid
                                                label={this.translate("Brand")}
                                                placeholder={this.translate("Brand")}
                                                defaultValue={this.state.item['brand']}
                                                name="brand"
                                                onChange={(e, { name, value }) =>
                                                    this.onChange(name, value)
                                                }
                                                required
                                            />
                                            <Form.Input
                                                fluid
                                                label={this.translate("Category")}
                                                placeholder={this.translate("Category")}
                                                defaultValue={this.state.item['category']}
                                                name="category"
                                                onChange={(e, { name, value }) =>
                                                    this.onChange(name, value)
                                                }
                                                required
                                            />
                                            <Form.Field>
                                                <Form.Input
                                                    fluid
                                                    label={this.translate("Price")}
                                                    placeholder={this.translate("Price")}
                                                    defaultValue={this.state.item['price']}
                                                    name="price"
                                                    onChange={(e, { name, value }) =>
                                                        this.onChange(name, value)
                                                    }
                                                    required
                                                    type='number'
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Form.Input
                                                    fluid
                                                    label={this.translate("Slug")}
                                                    placeholder={this.translate("Slug")}
                                                    defaultValue={this.state.item['slug']}
                                                    name="slug"
                                                    onChange={(e, { name, value }) =>
                                                        this.onChange(name, value)
                                                    }
                                                    required
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>}
                        </Grid>
                        <Divider />
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h3'>{this.translate('Content')}</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {this.props.isFetching &&
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <LoadingNavPlaceholder />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {!this.props.isFetching && <Grid.Row>
                                <Grid.Column>
                                    <Card.Group itemsPerRow={4}>
                                        {this.prepareContent()}
                                        <Card link style={{ border: 'dashed 1px grey', backgroundColor: 'rgba(255,255,255,8)', height: 'fit-content' }} onClick={() => this.displayContentModal()}>
                                            <Card.Content>
                                                <Card.Header><Icon name='plus' />{this.translate('Add new translation.')}</Card.Header>
                                            </Card.Content>
                                            <Card.Content>
                                                <Card.Description>
                                                    {this.translate('Quickly create and add a new translation to this product.')}
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>}
                        </Grid>
                        <Divider />
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h3'>{this.translate('Product Attributes and Options')}</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {this.props.isFetching &&
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <LoadingNavPlaceholder />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {!this.props.isFetching && <><Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h4'>{this.translate('Product Attributes')}</Header>
                                    <Card.Group itemsPerRow={4}>
                                        {this.preparePropertyAttributes()}
                                        <Card link style={{ border: 'dashed 1px grey', backgroundColor: 'rgba(255,255,255,8)' }} onClick={(event) => { event.preventDefault(); this.displayModal(); }}>
                                            <Card.Content>
                                                <Card.Header><Icon name='plus' />{this.translate('Add an attribute.')}</Card.Header>
                                            </Card.Content>
                                            <Card.Content>
                                                <Card.Description>
                                                    {this.translate('Quickly create and add a new attribute to this product.')}
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Header as='h4'>{this.translate('Product Options')}</Header>
                                        <Card.Group itemsPerRow={4}>
                                            {this.prepareOptionAttributes()}
                                            <Card link style={{ border: 'dashed 1px grey', backgroundColor: 'rgba(255,255,255,8)' }} onClick={(event) => { event.preventDefault(); this.displaySelectionModal(); }}>
                                                <Card.Content>
                                                    <Card.Header><Icon name='plus' />{this.translate('Add a set of options.')}</Card.Header>
                                                </Card.Content>
                                                <Card.Content>
                                                    <Card.Description>
                                                        {this.translate('Quickly create and add a new set of options to this product.')}
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        </Card.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </>}
                        </Grid>
                        <Divider />
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h3'>{this.translate('Product Tax Information')}</Header>
                                </Grid.Column>
                            </Grid.Row>
                            {this.props.isFetching &&
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <LoadingNavPlaceholder />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {!this.props.isFetching && <Grid.Row>
                                <Grid.Column>
                                    <label style={{
                                        display: 'block',
                                        margin: '0 0 .28571429rem 0',
                                        color: 'rgba(0,0,0,.87)',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}>{'Taxes'}</label>
                                    <Card.Group itemsPerRow={4}>
                                        {this.prepareTaxes()}
                                        <Card link style={{ border: 'dashed 1px grey', backgroundColor: 'rgba(255,255,255,8)' }} onClick={() => this.displayTaxModal()}>
                                            <Card.Content>
                                                <Card.Header><Icon name='plus' />{this.translate('Add new tax rate.')}</Card.Header>
                                            </Card.Content>
                                            <Card.Content>
                                                <Card.Description>
                                                    {this.translate('Quickly create and add a new tax rate to this product definition.')}
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>}
                        </Grid>
                        <Divider />
                        <Grid>
                            {this.props.isFetching &&
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <LoadingNavPlaceholder />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {!this.props.isFetching &&
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button.Group floated="right" className='form-right-toolbar-container'>
                                            <Button as='a' href={`/store-management/products`}>{this.translate('Back')}</Button>
                                            <Button as='a' onClick={() => this.saveAndContinue()} positive>{this.translate('Save and Continue')}</Button>
                                        </Button.Group>
                                    </Grid.Column>
                                </Grid.Row>}
                        </Grid>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
