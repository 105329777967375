import React from "react";
import Gatekeeper from "../components/controls/Gatekeeper";
import { Repository } from "../repositories";
import FeatureService from "../services/FeatureService";
import { SecurityService } from "../services";

export default class GatekeeperFactory {
    constructor(protected repository: Repository, protected service: FeatureService, protected securityService: SecurityService) { }

    create() {
        return (props) => (
            <Gatekeeper
                repository={this.repository}
                service={this.service}
                securityService={this.securityService}
                {...props}
            />
        )
    }
}