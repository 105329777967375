import React from 'react';
// import LocaleProvider from '../contexts/LocaleContext';
import { Controller } from '../core';
import { DeviceAwareRouteItemFactoryResolver } from '../factories';

export default class LocalizedController extends Controller {
    state = {};
    protected deviceAwareRouteItemFactory: DeviceAwareRouteItemFactoryResolver;
    // protected localizationService;

    constructor({ args: [router, deviceAwareRouteItemFactory, localizationService], ...props }) {
        super({ args: [router], ...props });
        this.deviceAwareRouteItemFactory = deviceAwareRouteItemFactory;
        // this.localizationService = localizationService;
    }

    createRoutes(device: string) {
        return this.deviceAwareRouteItemFactory.getDeviceRouteFactory(device).create(this.router.getPathName());
    }

    prepare(children) {
        return React.Children.map(children, item => React.cloneElement(item, { createRoutes: (device) => this.createRoutes(device) }));

        // return (
        //     <LocaleProvider localizationService={this.localizationService}>
        //         {React.Children.map(children, item => React.cloneElement(item, {createRoutes: () => this.createRoutes()}))}
        //     </LocaleProvider>
        // )
    }

    render() {
        return this.prepare(this.props?.children);
    }
}