import { DecoratedEntity } from "../entities";

export default class DecoratedEntityFactory {
    create(item) {
        const { _links, _embedded, ...entity } = item;
        return new DecoratedEntity()
            .setId(entity.id)
            .setLinks(_links)
            .setEmbedded(_embedded)
            .setEntity(entity);
    }
}