import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import WebsiteSettings from '../../components/pages/WebsiteSettings';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository, { EntityId } from '../../repositories/Repository';
import { SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class WebsitesController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private pageControllerAdapter;
    private sectionControllerAdapter;
    private websiteConfigurationControllerAdapter;
    private websiteDeploymentControllerAdapter;
    private securityService: SecurityService;

    state = {
        provisions: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory, securityService, pageControllerAdapter, sectionControllerAdapter, websiteConfigurationControllerAdapter, websiteDeploymentControllerAdapter] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.pageControllerAdapter = pageControllerAdapter;
        this.sectionControllerAdapter = sectionControllerAdapter;
        this.websiteConfigurationControllerAdapter = websiteConfigurationControllerAdapter;
        this.websiteDeploymentControllerAdapter = websiteDeploymentControllerAdapter;
        this.securityService = securityService;
    }

    private async getProvisions(applicationId: EntityId) {
        let items: any[] = [];

        try {
            items = await this.repository.getByPath(`applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        const applicationId = this.securityService.getApplicationId();
        let provisions = await this.getProvisions(applicationId);

        this.setState({
            provisions,
        })
    }

    preparePanes(Gatekeeper) {
        const PageController = this.pageControllerAdapter.getController();
        const SectionController = this.sectionControllerAdapter.getController();
        const WebsiteConfigurationController = this.websiteConfigurationControllerAdapter.getController();
        const WebsiteDeploymentController = this.websiteDeploymentControllerAdapter.getController();

        let panes = [
            {
                menuItem: "Website Settings",
                render: () => (
                    <>
                        <Gatekeeper
                            name="website-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <WebsiteConfigurationController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Pages",
                render: () => (
                    <>
                        <Gatekeeper 
                            name="website-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <PageController params={this.params} location={this.location} history={this.history}/>
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Sections",
                render: () => (
                    <>
                        <Gatekeeper
                            name="website-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <SectionController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
        ];

        if (this.state.provisions.length) {
            panes.push(
                {
                    menuItem: "Deployments",
                    render: () => (
                        <>
                            <Gatekeeper
                                name="website-management"
                                unauthorizedComponent={<UnauthorizedMessage />}
                                loadingComponent={<LoadingSpinner />}
                            >
                                {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                                {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                                <WebsiteDeploymentController params={this.params} location={this.location} history={this.history} />
                            </Gatekeeper>
                        </>
                    ),
                },
            );
        }

        return panes;
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: 'Overview', value: '/' },
            { key: 1, text: 'Website Management', value: null },
        ]
    }

    protected changeSettings(path) {
        this.router.redirect(`/settings/${path}`);
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();

        const panes = this.preparePanes(Gatekeeper);

        return this.prepare(
            <WebsiteSettings
                breadcrumbLevels={this.generateBreadcrumbs()}
                Gatekeeper={Gatekeeper}
                panes={panes}
            />
        )
    }
}