import React from "react";
import { Button, Card, Form, Label } from "semantic-ui-react";
import StateAwareForm, { IStateAwareFormProps, IStateAwareFormPropsState } from "../../StateAwareForm/StateAwareForm";
import './styles.css';

export interface ISectionTypeSelectorFormProps extends IStateAwareFormProps {

}

export interface ISectionTypeSelectorFormState extends IStateAwareFormPropsState {
    type: string;
}

export default class SectionTypeSelectorForm extends StateAwareForm<ISectionTypeSelectorFormProps, ISectionTypeSelectorFormState> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Form onSubmit={() => this.handleSubmit()} className={'section-create-form-controller'}>
                <h2>Select the type of section you wish to create</h2>
                <Form.Group widths={"equal"} className="section-type-selectors">
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "text" })} className={"text" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Text</Card.Header>
                                <Card.Description>
                                    The text section type is used to display text on a page with or without a title. It can include additional metadata, however the schema for it would need to be consistent with the Layout in development.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "hero" })} className={"hero" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Hero</Card.Header>
                                <Card.Description>
                                    The Hero section manages the contents for the Hero section on the website with its optional call to action.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "contact-form" })} className={"contact-form" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Contact Form</Card.Header>
                                <Card.Description>
                                    The Contact Form handles the Input labels and placeholders for the Contact Form section on the website.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                </Form.Group>
                <Form.Group widths={"equal"} className="section-type-selectors">
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "table" })} className={"table" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Table</Card.Header>
                                <Card.Description>
                                    A table displaying static or dynamic data.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "product" })} className={"product" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Products</Card.Header>
                                <Card.Description>
                                    Display products.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "map-with-open-hours" })} className={"map-with-open-hours" === this.state.type ? "active" : ''}>
                            <Card.Content>
                                <Card.Header>Map with Opening Hours</Card.Header>
                                <Card.Description>
                                    Display a map with opening hours.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                </Form.Group>
                <Form.Group widths={'three'} className="section-type-selectors">
                    <Form.Input>
                        <Card fluid onClick={() => this.handleChange({ name: "type", value: "individual-service" })} className={"individual-service" === this.state.type ? "active" : ''}>
                            <Label content="Beta" attached="bottom right" style={{ filter: 'opacity(1)' }} color='blue' />
                            <Card.Content>
                                <Card.Header>Individual Service</Card.Header>
                                <Card.Description>
                                    Displays a single service section.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid style={{ backgroundColor: 'silver' }} /*onClick={() => this.handleChange({ name: "type", value: "reviews" })}*/ className={"reviews" === this.state.type ? "active" : ''}>
                            <Label content="Coming Soon!" attached="bottom right" style={{ filter: 'opacity(1)' }} color='red' />
                            <Card.Content>
                                <Card.Header>Reviews</Card.Header>
                                <Card.Description>
                                    Displays a section with customer reviews.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid style={{ backgroundColor: 'silver' }} /*onClick={() => this.handleChange({ name: "type", value: "reviews" })}*/ className={"flow" === this.state.type ? "active" : ''}>
                            <Label content="Coming Soon!" attached="bottom right" style={{ filter: 'opacity(1)' }} color='red' />
                            <Card.Content>
                                <Card.Header>Flow</Card.Header>
                                <Card.Description>
                                    A section geared towards combining other sections to create a guided flow for users.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                </Form.Group>
                <Form.Group widths={'three'} className="section-type-selectors">
                    <Form.Input>
                        <Card fluid style={{ backgroundColor: 'silver' }} /*onClick={() => this.handleChange({ name: "type", value: "reviews" })}*/ className={"cart" === this.state.type ? "active" : ''}>
                            <Label content="Coming Soon!" attached="bottom right" style={{ filter: 'opacity(1)' }} color='red' />
                            <Card.Content>
                                <Card.Header>Cart</Card.Header>
                                <Card.Description>
                                    Displays an interactive cart that allows users to add and remove items and change quantities.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                    <Form.Input>
                        <Card fluid style={{ backgroundColor: 'silver' }} /*onClick={() => this.handleChange({ name: "type", value: "reviews" })}*/ className={"checkout" === this.state.type ? "active" : ''}>
                            <Label content="Coming Soon!" attached="bottom right" style={{ filter: 'opacity(1)' }} color='red' />
                            <Card.Content>
                                <Card.Header>Checkout</Card.Header>
                                <Card.Description>
                                    The checkout process to collect the necessary information to get paid and make an order.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Form.Input>
                </Form.Group>
                <Button color='teal' size='large'>
                    Create
                </Button>
            </Form>
        )
    }
}