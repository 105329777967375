import React, { PureComponent } from 'react';
import Component from '../../layouts/component';
import Page from '../Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface InboxPageProps {
    createRoutes?: Function,
    items: any,
    Gatekeeper: any;
    localizationService?: any,
    breadcrumbLevels?: any,
}

export default class InboxPage extends PureComponent<InboxPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
            localizationService,
            breadcrumbLevels
        } = this.props;

        return (
            <Page createRoutes={createRoutes}>
                <Component localizationService={localizationService} breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        items={items}
                        Gatekeeper={Gatekeeper}
                    />
                    <MobilePage
                        items={items}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}