import React, { PureComponent } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../controls';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import { ScrollableTable } from '../../../layouts/tables';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
    onDoubleClick: Function;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    static defaultProps = {
        onDoubleClick: () => { }
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    renderItems() {
        const { items, Gatekeeper } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, item)}>
                    <Table.Cell>{item.firstName}</Table.Cell>
                    <Table.Cell>{item.lastName}</Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Gatekeeper name="user-management">
                            <Button icon='edit' color='teal' as="a" href={`/users/${item.id}/edit`} />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="user-view" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <ScrollableTable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>First Name</Table.HeaderCell>
                                    <Table.HeaderCell>Last Name</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderItems()}
                            </Table.Body>
                        </ScrollableTable>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        )
    }
}