import React, { PureComponent } from 'react';
import { messages } from './messages';
import './styles.css';

export interface ILoadingNavPlaceholderProps {
}

export default class LoadingNavPlaceholder extends PureComponent<ILoadingNavPlaceholderProps> {

    static defaultProps = {
    }

    render() {
        return (
            <div className="loading-placeholder-container">
                <div className="placeholder">
                    <div className="animated-background"></div>
                </div>
            </div>
        );
    }
}