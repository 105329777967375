import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IAccount {
    name: string;
}

export interface IAccountEditPageProps extends IPageProps {
    item?: IAccount;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
    breadcrumbLevels: any;
}

export default class AccountEditPage extends PureComponent<IAccountEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, breadcrumbLevels, Gatekeeper } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        name={item && item.name}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                    <MobilePage
                        name={item && item.name}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}