import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header } from "semantic-ui-react";
import TermEditorModal from "./TermEditorModal";
import CommonUtils from "../../../../../../utilities/Common/CommonUtils";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";

const commonUtils = new CommonUtils();

export interface ITermsPaneProps {
    items: any[];
    Gatekeeper: any;
    onModalClose: () => Promise<any>;
    onSave: (data: any) => Promise<any>;
    onDelete: (data: any) => Promise<any>;
}

export interface ITermPaneState {
    modalOpen: boolean;
    selectedItem: any;
}

export default class TermsPane extends PureComponent<ITermsPaneProps, ITermPaneState> {
    state: ITermPaneState = {
        modalOpen: false,
        selectedItem: undefined,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.setState({ selectedItem: undefined });
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.period} {commonUtils.plur('month', item.period)}</Table.Cell>
                <Table.Cell>${item.price}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="subscription-term-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    onClick={() => this.setState({ selectedItem: item }, () => this.toggleModal())}
                                />
                            }
                            content="Edit the term's details."
                            basic
                        />
                        <Popup
                            trigger={
                                <Button
                                    icon="trash"
                                    color="red"
                                    onClick={() => this.props.onDelete(item)}
                                />
                            }
                            content="The subscription term will be removed permanently"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(item: any, modalOpen: boolean, Gatekeeper) {
        return (
            <TermEditorModal
                onSave={async (data: any) => this.props.onSave(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
                item={item}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={4} textAlign={'center'}>
                    <Header>No associated terms!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return (<Table.Body>
            {!items.length ? this.renderNoContentMessage() : items}
        </Table.Body>)
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                <Table.Header>
                    <Table.HeaderCell>Period (in months)</Table.HeaderCell>
                    <Table.HeaderCell>Price (in CAD)</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Header>
                {this.renderTableBody()}
                <Gatekeeper name="subscription-term-management">
                    <Table.Footer>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan={3}>
                            <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Add Term</Button>
                            {this.renderModal(this.state.selectedItem, this.state.modalOpen, Gatekeeper)}
                        </Table.HeaderCell>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}