import React, { PureComponent } from "react";
import {
    Button,
    Card,
    CardGroup,
    Divider,
    Header,
    Icon,
} from "semantic-ui-react";
import { MobileSkeleton } from "../../layouts/skeleton";
import { Message, UnauthorizedMessage } from "../../layouts/messages";
import { LoadingSpinner } from "../../controls";
import DimmedModal from "../../layouts/modals/DimmedModal";
import "./styles.css";
import CreateButton from "../../controls/buttons/CreateButton";

export interface IMobilePageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
}
export interface IMobilePageState {
}

export default class MobilePage extends PureComponent<
    IMobilePageProps,
    IMobilePageState
> {
    state = {};

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header">
                <Header as="h2" content="Licenses" className="mobile-items" />
            </div>
        );
    }

    renderCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.map((license: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{license.name}</Card.Header>
                        <Card.Meta>
                            <span>{`${license.type} - ${license.level}`}</span>
                        </Card.Meta>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`/licenses/${license.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    Edit
                                </Button>
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? <Message content="No content." size='large' /> : cardList}
            </CardGroup>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="license-management" unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="mobile-item-list">
                        {this.renderPageHeader()}
                        {this.renderCards()}
                        {!this.props.items.length &&
                            <DimmedModal header="Nothing to see here!" content="Add your first blog post!" actions={['Not now', { key: 'done', content: 'Create blog post', positive: true, href: '/posts/create' }]} />
                        }
                        <CreateButton href='/licenses/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
