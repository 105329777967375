import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import UsersPane from '../../components/pages/accounts/CustomerOverviewPage/panes/UsersPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountUsersController extends LocalizedController {
    private repository: Repository;
    private userRepository: Repository;
    private applicationRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
        applications: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, userRepository, applicationRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.userRepository = userRepository;
        this.applicationRepository = applicationRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/users`);
        } catch (e) {
            return [];
        }

        return items;
    }

    private async getApplications() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/applications`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();
        let applications;

        try {
            applications = await this.getApplications();
        } catch(e) {
            console.warn(e);
            applications = [];
        }

        this.setState({
            items,
            applications,
        })
    }

    async handleSearch(data: any) {
        let items;

        try {
            items = await this.userRepository.find(data);
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleCreateUser(data: any) {
        let item;

        try {
            item = await this.userRepository.create(data);
        } catch (e) {
            console.error(e)
            throw Error("Failed to create the user. Please try again.");
        }

        try {
            await this.handleLinkUser(item);
        } catch (e) {
            console.error(e)
            throw Error("Created the user but failed to associate it to this account. Try associating it by email.");
        }

        return item;
    }

    async handleLinkUser(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'userId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the user to this account. Please try again.");
        }
    }

    async handleLinkApplication(user: any, data: any) {
        try {
            await this.applicationRepository.link(data.id, 'userId', user.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the user to this application. Please try again.");
        }
    }

    async handleUnlinkUser(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'userId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to disassociate the user to this account. Please try again.");
        }

        await this.refreshItems();
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { items, applications } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="account-user-management"
                or={["account-user-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <UsersPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSearch={async (data: any) => this.handleSearch(data)}
                    onCreateUser={async (data: any) => this.handleCreateUser(data)}
                    onLinkUser={async (data: any) => this.handleLinkUser(data)}
                    onLinkApplication={async (user: any, data: any) => this.handleLinkApplication(user, data)}
                    onUnlinkUser={async (data: any) => this.handleUnlinkUser(data)}
                    onModalClose={async () => this.refreshItems()}
                    applications={applications}
                />
            </Gatekeeper>
        )
    }
}