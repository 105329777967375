import React, { PureComponent } from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../controls';
import CreateButton from '../../../controls/buttons/CreateButton';
import { UnauthorizedMessage, Message } from '../../../layouts/messages';
import DimmedModal from '../../../layouts/modals/DimmedModal';
import DesktopSkeleton from '../../../layouts/skeleton/DesktopSkeleton';
import { ScrollableTable } from '../../../layouts/tables';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
    onDoubleClick: Function;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    static defaultProps = {
        onDoubleClick: () => { }
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderItems() {
        const { items, Gatekeeper } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, item)}>
                    <Table.Cell><p>{item.name}</p></Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Gatekeeper name="account-view">
                            <Popup
                                trigger={
                                    <Button
                                        icon="eye"
                                        color="blue"
                                        as="a"
                                        href={`/subscriptions/${item.id}`}
                                    />
                                }
                                content="View the details of the account."
                                basic
                            />
                        </Gatekeeper>
                        <Gatekeeper name="subscription-management">
                            <Button icon='edit' color='teal' as="a" href={`/subscriptions/${item.id}/edit`} />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            )
        });
        return (
            <Table.Body>
                {!itemsTable.length ? <Message content="No content." compact /> : itemsTable}
            </Table.Body>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="subscription-view" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <ScrollableTable>
                            {this.renderTableHeaders()}
                            {this.renderItems()}
                        </ScrollableTable>
                        {!this.props.items.length &&
                            <DimmedModal header="Nothing to see here!" content="Add your first subscription!" actions={['Not now', { key: 'done', content: 'Create subscription', positive: true, href: '/subscriptions/create' }]} />
                        }
                        <CreateButton
                            itemType='subscription'
                            href='/subscriptions/create'
                            attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>

        )
    }
}