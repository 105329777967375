import React, { PureComponent } from 'react';
import { Icon, Table } from 'semantic-ui-react';

export interface IFeaturesModalContentProps {
    isMobile: boolean;
}

export default class FeaturesModalContent extends PureComponent<IFeaturesModalContentProps> {
    static defaultProps = {
        isMobile: false,
    };
    render() {
        return (
            <Table>
                {!this.props.isMobile && (
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={"two"}>Status</Table.HeaderCell>
                            <Table.HeaderCell width="six">Definition</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )}
                <Table.Body>
                    <Table.Row>
                        <Table.Cell><Icon color='green' name="circle" /> Active</Table.Cell>
                        <Table.Cell>
                            The feature is active and available to the public.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Icon color='black' name="circle" /> Inactive</Table.Cell>
                        <Table.Cell>
                            The feature is inactive and unavailable to the public.
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
}
