import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../LocalizedPage';
import Component from "../../layouts/component";

export interface IWebsiteSettingsProps {
    createRoutes?: Function;
    Gatekeeper: any;
    panes: any[];
    breadcrumbLevels: any[];
}

export default class WebsiteSettings extends PureComponent<IWebsiteSettingsProps> {
    render() {
        const { createRoutes, Gatekeeper, panes, breadcrumbLevels } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage Gatekeeper={Gatekeeper} panes={panes} />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
