import React, { PureComponent } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import "react-toggle/style.css"
import { appendFileSync } from "fs";

export interface ICheckboxInputProps {
    defaultValue?: boolean;
    onChange?: (e, value) => any;
    label?: string;
    name: string;
    value?: boolean;
    toggle?: boolean;
}

export interface ICheckboxInputState {
    value?: boolean
}

export default class CheckboxInput extends PureComponent<ICheckboxInputProps, ICheckboxInputState> {
    state = {
        value: false,
    };

    static defaultProps = {
        options: [],
        label: '',
        toggle: false,
    }

    handleChange(e) {
        const value = !this.state.value;
        this.setState({ value }, () => {
            this.props.onChange && this.props.onChange(e, { name: this.props.name, value });
        });
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.value || this.props.defaultValue,
            });
        }
    }

    render() {
        return (
            <Form.Input label={this.props.label} fluid>
                <Checkbox
                    defaultChecked={this.props.defaultValue}
                    name={this.props.name}
                    checked={this.state.value}
                    onChange={(e) => { this.handleChange(e) }}
                    toggle={this.props.toggle}
                />
            </Form.Input>
        );
    }
}