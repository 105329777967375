import React, { PureComponent } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

export interface IQuickTaxCreationFormProps {
    name?: string;
    type?: string;
    value?: any[];
    isMobile?: boolean;
    handleChange?: ({ name, value }) => any;
    uniqueKey?: number;
    attribute?: any;
    toggleModal: (toggleModal: boolean) => void;
    createAttribute?: (attribute: any) => void;
    onSave: (data: any) => void;
    localizationService: any;
}

export interface IQuickTaxCreationFormState {
    name: string;
    rate: number;
    rateType: string;
}

export default class QuickTaxCreationForm extends PureComponent<IQuickTaxCreationFormProps, IQuickTaxCreationFormState> {
    static defaultProps = {
        onSave: (data) => { console.log(data) }
    };

    state = {
        name: '',
        rate: 0,
        rateType: 'percentage',
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private handleNameChange(value) {
        this.setState({ 'name': value });
    }

    handleValueChange(value) {
        this.setState({ 'rate': value });
    }

    handleTaxRateTypeChange(value) {
        this.setState({ 'rateType': value.value })
    }

    async submit() {
        try {
            const { name, rate, rateType } = this.state;
            let data = { name, rate: Number(rate), rateType };
            await this.props.onSave(data);
        } catch (e) {

        }
    }

    render() {
        const { name, isMobile } = this.props;
        const rateTypeOptions = [
            {
                key: 'percentage',
                text: this.translate('Percentage'),
                value: 'percentage',
            },
            {
                key: 'dollar',
                text: this.translate('Dollar'),
                value: 'dollar',
            },
        ];
        return (
            <>
                <Modal.Header>{this.translate('Add a new tax rate')}</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Form onSubmit={() => this.submit()}>
                            <Form.Input label={this.translate('Tax Name')}>
                                <input placeholder={this.translate("Name")} autoFocus defaultValue={this.props.name || this.state.name} onChange={(e) => this.handleNameChange(e.target.value)} />
                            </Form.Input>
                            <Form.Group>
                                <Form.Input label={this.translate('Rate')}>
                                    <input placeholder="0" type={'number'} defaultValue={this.state.rate} onChange={(e) => this.handleValueChange(e.target.value)} />
                                </Form.Input>
                                <Form.Dropdown
                                    label={this.translate('Rate Type')}
                                    placeholder={this.translate('Select Rate Type')}
                                    fluid
                                    selection
                                    options={rateTypeOptions}
                                    labeled
                                    defaultValue={'percentage'}
                                    onChange={(e, data) => this.handleTaxRateTypeChange({ value: data.value })}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        {this.translate('Cancel')}
                    </Button>
                    <Button
                        content={this.translate("Create Tax")}
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { this.submit(); }}
                        positive
                    />
                </Modal.Actions>
            </>
        )
    }
}