import React from 'react';
import LicenseFeaturePane from '../../../components/pages/licenses/LicenseOverviewPage/panes/LicenseFeaturePane';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import LocalizedController from '../../LocalizedController';

export default class LicenseFeaturesController extends LocalizedController {
    private repository: Repository;
    private featureRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        item: undefined,
        features: [],
        licenseFeatures: [],
        selectedLicenses: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, featuresRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.featureRepository = featuresRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private configureToast() {
        return (
            {
                autoClose: 1500,
            }
        );
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    private async getLicenseFeatureIds() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`licenses/${this.params?.id}/features`);
        } catch (e) {
            return;
        }

        return items;
    }

    private async getFeatures() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.featureRepository.get();
        } catch (e) {
            return;
        }

        return items;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        delete data['features'];
        Object.keys(data).forEach((key) => {
            if (data[key]) {
                this.handleLink(key);
            } else {
                this.handleUnlink(key);
            }
        });
    }

    private async handleLink(data: any) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'featureId', data);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the feature to this license. Please try again.");
        }
    }

    private async handleUnlink(data: any) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'featureId', data);
        } catch (e) {
            console.error(e)
            throw Error("Failed to disassociate the feature to this license. Please try again.");
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/licenses');
        } catch (e) {
            throw e;
        }
    }

    async fetchAll() {
        let item = await this.get();
        let features = await this.getFeatures();
        let licenseFeatures = await this.getLicenseFeatureIds();
        let selectedLicenses = licenseFeatures;
        licenseFeatures = licenseFeatures.map(item => item.id);

        this.setState({
            item,
            features,
            licenseFeatures,
            selectedLicenses,
        });
    }

    async fetchSelectedLicenses() {
        await this.fetchAll();
    }

    async componentDidMount() {
        await this.fetchAll();
    }

    render() {
        const { item, features, licenseFeatures, selectedLicenses } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <LicenseFeaturePane

                item={item}
                features={features}
                licenseFeatures={licenseFeatures}
                selectedLicenses={selectedLicenses}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                fetchSelectedLicenses={async () => this.fetchSelectedLicenses()}
                Gatekeeper={Gatekeeper}
                toastConfig={this.configureToast()}
            />
        )
    }
}