import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import ApplicationsPane from '../../components/pages/accounts/CustomerOverviewPage/panes/ApplicationsPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountApplicationsController extends LocalizedController {
    private repository: Repository;
    private applicationRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, applicationRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.applicationRepository = applicationRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/applications`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    async handleSearch(data: any) {
        let items;

        try {
            items = await this.applicationRepository.find(data);
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleCreateApplication(data: any) {
        let item;

        try {
            item = await this.applicationRepository.create(data);
        } catch (e) {
            console.error(e)
            throw Error("Failed to create the application. Please try again.");
        }

        setTimeout(async () => {
            try {
                await this.handleLinkApplication(item);
            } catch (e) {
                console.error(e)
                throw Error("Created the application but failed to associate it to this account. Try associating it by name.");
            }
        }, 500)

        return item;
    }

    async handleLinkApplication(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'applicationId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the application to this account. Please try again.");
        }
    }

    async handleUnlinkApplication(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'applicationId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to disassociate the application to this account. Please try again.");
        }

        await this.refreshItems();
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { items } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="account-application-management"
                or={["account-application-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <ApplicationsPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSearch={async (data: any) => this.handleSearch(data)}
                    onCreateApplication={async (data: any) => this.handleCreateApplication(data)}
                    onLinkApplication={async (data: any) => this.handleLinkApplication(data)}
                    onUnlinkApplication={async (data: any) => this.handleUnlinkApplication(data)}
                    onModalClose={async () => this.refreshItems()}
                />
            </Gatekeeper>
        )
    }
}