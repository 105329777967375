import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header } from "semantic-ui-react";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";
import AssociateLicenseModal from "./AssociateLicenseModal";

export interface ILicensesPaneProps {
    items: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onLinkLicense: (data: any) => Promise<any>;
    onUnlinkLicense: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface ILicensePaneState {
    modalOpen: boolean;
}

export default class LicensesPane extends PureComponent<ILicensesPaneProps, ILicensePaneState> {
    state: ILicensePaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.type}</Table.Cell>
                <Table.Cell>{item.level}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="license-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/licenses/${item.id}/edit`}
                                />
                            }
                            content="Edit the license's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="subscription-license-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkLicense(item)}
                                />
                            }
                            content="Licenses can be associated to multiple subscriptions. Unassociating the license will remove the license's access from this subscription but will not delete or alter the license itself"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateLicenseModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onLinkLicense={async (data: any) => this.props.onLinkLicense(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={4} textAlign={'center'}>
                    <Header>No associated licenses!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return (<Table.Body>
            {!items.length ? this.renderNoContentMessage() : items}
        </Table.Body>)
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Header>
                {this.renderTableBody()}
                <Gatekeeper name="subscription-license-management">
                    <Table.Footer>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan={4}>
                            {this.renderModal(this.state.modalOpen, Gatekeeper)}
                            <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Associate License</Button>
                        </Table.HeaderCell>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}