import React from 'react';
import StoreConfigurationListPage from '../../components/pages/storeconfiguration/StoreConfigurationListPage';
import { Repository } from '../../repositories';
import { EntityId } from '../../repositories/Repository';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class StorefrontsController extends LocalizedController {
    private repository: Repository;
    private localizationService: LocalizationService;
    private applicationRepository: Repository;
    private securityService: SecurityService;

    state = {
        storefronts: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, repository, applicationRepository] = props.args;

        this.repository = repository;
        this.localizationService = localizationService;
        this.securityService = securityService;
        this.applicationRepository = applicationRepository;
    }

    private async getStorefront() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`storefronts`)
        } catch (e) {
            console.error(e);
            return [];
        }
        return items;
    }

    private async get() {
        let storefronts = await this.getStorefront();

        return { storefronts };
    }

    private async provision() {
        const applicationId = this.securityService.getApplicationId();

        try {
            await this.applicationRepository.createByPath({}, `applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e);
        }
    }

    async componentDidMount() {
        let { storefronts } = await this.get();

        this.setState({
            storefronts,
        })
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Storefront Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Storefront Settings'), value: undefined },
        ]
    }

    render() {
        return this.prepare(
            <StoreConfigurationListPage
                localizationService={this.localizationService}
                onPathChange={(path) => this.changeSettings(path)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                storefronts={this.state.storefronts}
            />
        )
    }
}