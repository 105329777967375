import React from 'react';
import LicenseCreatePage from '../../components/pages/LicenseCreatePage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class LicenseCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        item: undefined,
        features: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;
        try {
            item = await this.repository.create(data);
            this.router.redirect(`/licenses/${item.id}/edit`);
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        this.setState({
            item: undefined,
        });
    }

    render() {
        const { item } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <LicenseCreatePage
                
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}