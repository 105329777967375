import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IStoreConfigurationListPageProps extends IPageProps {
    onPathChange: (path: string) => any,
    localizationService?: any,
    breadcrumbLevels?: any,
    storefronts?: any[],
}

export default class StoreConfigurationListPage extends PureComponent<IStoreConfigurationListPageProps> {
    render() {
        const { createRoutes, header, localizationService,  onPathChange, breadcrumbLevels, storefronts } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        header={header}
                        localizationService={localizationService}
                        onPathChange={onPathChange}
                        storefronts={storefronts}
                    />
                </Component>
            </Page>
        );
    }
}