import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../LocalizedPage';
import { ILoginFormState } from '../../forms/LoginForm/LoginForm';
import { IPageProps } from '../Page/Page';

export interface ILoginPageProps extends IPageProps {
    onLogin?: (values: ILoginFormState) => any;
    localizationService: any;
}

export default class LoginPage extends PureComponent<ILoginPageProps> {
    render() {
        let { children, createRoutes, onLogin,localizationService } = this.props;

        const Page = LocalizedPage();

        return (
            <Page createRoutes={createRoutes} headerHidden>
                <DesktopPage
                    onLogin={onLogin}
                    localizationService={localizationService}
                >
                    {children}
                </DesktopPage>
                <MobilePage
                    onLogin={onLogin}
                >
                    {children}
                </MobilePage>
            </Page>
        );
    }
}