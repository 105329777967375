import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import ResourceForm from '../../../forms/permissions/ResourceForm';
import "./styles.css";

export interface ISectionDesktopPageProps {
    name: string;
    path: string;
    host: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ISectionDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        name: "",
        path: "",
        host: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="resource-management">
                <div className="resource-editor-container">
                    {this.props.name && (
                        <Button.Group floated="right">
                            <Button
                                type="button"
                                icon="trash"
                                color="red"
                                onClick={() => this.props?.onDelete && this.props.onDelete()}
                            />
                        </Button.Group>
                    )}
                    <ResourceForm
                        name={this.props.name}
                        path={this.props.path}
                        host={this.props.host}
                        onSubmit={(data) => this.props.onSave(data)}
                    />
                </div>
            </Gatekeeper>
        );
    }
}