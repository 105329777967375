import React, { PureComponent } from 'react';
import Component from '../../layouts/component';
import LocalizedPage from '../LocalizedPage';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface ISectionPageProps {
    createRoutes?: Function,
    items: any,
    Gatekeeper: any;
    onDoubleClick?: Function,
    localizationService?: any,
    breadcrumbLevels?: any,
    onPathChange: (path: string) => any,
    hasProvisionned: boolean,
}

export default class SectionPage extends PureComponent<ISectionPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
            onDoubleClick,
            localizationService,
            breadcrumbLevels,
            onPathChange,
            hasProvisionned,
        } = this.props;

        const Page = LocalizedPage();

        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        items={items}
                        Gatekeeper={Gatekeeper}
                        onDoubleClick={onDoubleClick}
                        onPathChange={onPathChange}
                        hasProvisionned={hasProvisionned}
                    />
                    <MobilePage
                        items={items}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}