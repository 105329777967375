import React, { PureComponent } from "react";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";

export interface ISelectionAttributeFormProps {
    name?: string;
    type?: string;
    value?: any[];
    isMobile?: boolean;
    handleChange: ({ name, value }) => any;
    uniqueKey: number;
    attribute: any;
    toggleModal: (toggleModal: boolean) => void;
    createAttribute: (attribute: any) => void;
    localizationService:any;
}

export interface ISelectionAttributeFormState {
    name: string;
    type: string;
    value: any[];
    currentValues: string[];
    options: any[];
}

export default class SelectionAttributeForm extends PureComponent<ISelectionAttributeFormProps, ISelectionAttributeFormState> {
    static defaultProps = {
        name: "",
        value: [],
        type: "selection",
        isMobile: false,
        handleChange: ({ name, value }) => { },
        uniqueKey: 0,
        toggleModal: (toggleModal) => { },
        createAttribute: (attribute) => { console.log('saved', attribute) },
    };

    state = {
        name: '',
        type: 'selection',
        value: [],
        currentValues: [],
        options: [],
    };

    componentDidMount() {
        if (this.props.attribute) {
            const { name, type, options } = this.props.attribute;
            const dropdownOptions = options.map(option => {
                return { text: option, value: option }
            })
            this.setState({
                options: dropdownOptions,
                currentValues: options,
                name: name || '',
                type: type || 'property',

            });
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private handleNameChange(name) {
        this.setState({ name: name.target.value });
    }

    handleChange(e, { value }) {
        this.setState({ currentValues: value })
    }

    async submit() {
        try {
            const { name, currentValues } = this.state;
            const data = { name, options: currentValues, type: 'selection' };
            if (this.props.attribute) {
                data['id'] = this.props.attribute.id;
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                data['definitionId'] = this.params?.id;
            }
            await this.props.createAttribute(data);
        } catch (e) {

        }
    }

    handleAddition(e, { value }) {
        this.setState(prevState => ({
            options: [{ text: value, value: value.toString() }, ...prevState.options],
        }))
    }

    render() {
        const { name, isMobile } = this.props;

        return (
            <>
                <Modal.Header>{this.translate('Create a selection attribute')}</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Form onSubmit={() => this.submit()}>
                            <Form.Input label={this.translate('Attribute Name')}>
                                <input placeholder={this.translate('Name')} autoFocus defaultValue={this.props.name || this.state.name} onChange={(e) => this.handleNameChange(e)} />
                            </Form.Input>
                            <Form.Input label={this.translate('Attribute Value')}>
                                <Dropdown
                                    options={this.state.options}
                                    placeholder={this.translate('Create Attribute Values')}
                                    search
                                    selection
                                    fluid
                                    multiple
                                    allowAdditions
                                    defaultValue={this.state.currentValues}
                                    value={this.state.currentValues}
                                    onAddItem={(e, data) => this.handleAddition(e, { value: data.value })}
                                    onChange={(e, data) => this.handleChange(e, { value: data.value })}
                                />
                            </Form.Input>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        {this.translate('Cancel')}
                    </Button>
                    <Button
                        content={this.translate("Create Property")}
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { this.submit(); }}
                        positive
                    />
                </Modal.Actions>
            </>
        )
    }
}