import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Form, Icon, Button, Grid, Modal, Popup, Dropdown } from 'semantic-ui-react';
import MarkdownEditor from '../../../controls/MarkdownEditor';
import languages from '../../../../utilities/Languages/Languages';
import './style.css';

export interface IProductDefinitionModalFormProps {
    onSubmit: (data: any) => any;
    onCancel: () => any;
    localizationService: any;
    brand?: any;
}
export interface IProductDefinitionModalFormState {
    productDefinition: any;
}
export default class ProductDefinitionModalForm extends PureComponent<IProductDefinitionModalFormProps, IProductDefinitionModalFormState> {

    state = {
        productDefinition: {},
        options: [],
    }

    static defaultProps: {
        onSubmit: (value: any) => any;
        onCancel: () => void;
    };


    private handleChange(data: any) {
        let productDefinition = this.state.productDefinition || {};
        productDefinition[data.name] = data.value;
        this.setState({ productDefinition });
    }

    async submit() {
        try {
            await this.props.onSubmit(this.state.productDefinition);
            toast.success(this.translate('Product successfully updated.'));
        } catch (e) {
            toast.error(e.message);
        }
    }

    async cancel() {
        try {
            this.props.onCancel();
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    generateLanguagesMap() {
        return languages.map(language => {
            return { key: language.code, text: language.name, value: language.code };
        })
    }

    private renderGeneralInformation() {
        return (
            <Grid.Column>
                <Form.Group fluid>
                    <Form.Input
                        width={'16'}
                        fluid
                        label={this.translate("Title")}
                        placeholder={this.translate("Title")}
                        name="title"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    {!this.props.brand && <Form.Input
                        fluid
                        label={this.translate("Brand")}
                        placeholder={this.translate("Brand")}
                        name="brand"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        defaultValue={this.props.brand}
                        disabled={this.props.brand}
                        required
                    />}
                    <Form.Input
                        fluid
                        label={this.translate("Category")}
                        placeholder={this.translate("Category")}
                        name="category"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    {/* <Dropdown
                        options={this.state.options}
                        label={this.translate("Category")}
                        placeholder={this.translate("Category")}
                        search
                        selection
                        fluid
                        allowAdditions
                        defaultValue={this.state.productDefinition && this.state.productDefinition['category'] ? this.state.productDefinition['category'] : ''}
                        // onAddItem={this.handleAddition}
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    /> */}
                    <Form.Field>
                        <Form.Input
                            fluid
                            label={this.translate("Price")}
                            placeholder={this.translate("Price")}
                            name="price"
                            onChange={(e, { name, value }) =>
                                this.handleChange({ name, value })
                            }
                            required
                            type='number'
                        />
                        <small>{this.firstWordBold(this.translate(`Note: You will be able to add taxes later.`))}</small>
                    </Form.Field>
                </Form.Group>
            </Grid.Column>
        )
    }

    firstWordBold(sentence: String) {
        const firstSpaceIndex = sentence.indexOf(" ");
        return (
            <>
                <span style={{ fontWeight: "bold" }}>
                    {sentence.substring(0, firstSpaceIndex)}
                </span>
                <span>{sentence.substring(firstSpaceIndex)}</span>
            </>
        );
    }

    render() {
        // const { error, loading } = this.state;
        return (
            <>
                <Modal.Content scrolling className='product-creation-form-modal'>
                    <Form>
                        <div style={{ display: 'block', clear: 'both' }}>
                            {this.renderGeneralInformation()}
                        </div>
                        <label className={'mobile-product-content-header'}>
                            {this.translate('Product Description')}
                        </label>
                        <MarkdownEditor
                            name="content"
                            value=''
                            onChange={({ name, value }) =>
                                this.handleChange({ name, value })
                            }
                            localizationService={this.props.localizationService}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button negative onClick={() => this.cancel()} icon>
                            <Icon name='cancel' />
                            {this.translate('Cancel')}
                        </Button>
                        <Button positive onClick={() => this.submit()} icon>
                            <Icon name='save' />
                            {this.translate('Save and continue')}
                        </Button>
                    </Button.Group>
                </Modal.Actions>
            </>
        );
    }
}
