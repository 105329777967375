import React from 'react';
import { toast } from 'react-toastify';
import { Form, Message, Button, Accordion, Icon } from 'semantic-ui-react';
import { DatePicker, DeleteButton } from '../../../controls';
import MarkdownEditor from '../../../controls/MarkdownEditor';
import MultiSelectInput from '../../../controls/MultiSelectInput';
import SelectInput from '../../../controls/SelectInput';
import ToggleInput from '../../../controls/ToggleInput';
import { LoadingMessage } from '../../../layouts/messages';
import StateAwareForm, {
    IStateAwareFormProps,
} from '../../StateAwareForm/StateAwareForm';
import languages from '../../../../utilities/Languages/Languages';
import { slug } from 'slug-gen';
import './style.css';

export interface IPagesFormProps extends IStateAwareFormProps {
    title: string;
    language: string;
    content: string;
    slug: any;
    layout?: string;
    status: string;
    readOnly?: boolean;
    localizationService: any;
    layoutMap: any[];
}
export default class PagesForm extends StateAwareForm<IPagesFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => void;
        status: 'draft';
        readOnly: false;
    };

    componentDidMount() {
        super.setState({
            title: this.props.title,
            slug: this.props.slug,
            language: this.props.language,
            content: this.props.content,
            layout: this.props.layout,
            status: this.props.status,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                title: this.props.title,
                slug: this.props.slug,
                language: this.props.language,
                content: this.props.content,
                layout: this.props.layout,
                status: this.props.status,
            });
        }
    }

    async submit() {
        try {
            await this.handleSubmit();
            toast.success('Post successfully updated.');
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    generateLanguagesMap() {
        return languages.map(language => {
            return { key: language.code, text: language.name, value: language.code };
        })
    }

    generateLayoutMap() {
        return this.props.layoutMap;
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeAccordeonIndex } = this.state
        const newIndex = activeAccordeonIndex === index ? -1 : index

        this.setState({ activeAccordeonIndex: newIndex })
    }

    encapsulateAccordeon() {
        const { activeAccordeonIndex } = this.state

        return (
            <Form.Group fluid>
                <Accordion fluid style={{ margin: '10px auto' }} styled>
                    <Accordion.Title
                        active={activeAccordeonIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        {this.translate('General Information')}
                    </Accordion.Title>
                    <Accordion.Content active={activeAccordeonIndex === 0}>
                        {this.renderGeneralInformation()}
                    </Accordion.Content>
                </Accordion>
            </Form.Group>
        )
    }

    renderGeneralInformation() {
        return (
            <>
                <Form.Group fluid>
                    <Form.Input
                        width={'16'}
                        fluid
                        label={this.translate("Title")}
                        placeholder={this.translate("Title")}
                        defaultValue={this.props.title}
                        name="title"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    {this.props.slug && <Form.Input
                        fluid
                        name="slug"
                        label={this.translate('Slug')}
                        defaultValue={this.props.slug}
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />}
                    <SelectInput
                        options={this.generateLayoutMap()}
                        search
                        label={this.translate('Layout')}
                        placeholder={this.translate('Select Layout')}
                        defaultValue={this.props.layout}
                        name="layout"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <SelectInput
                        options={this.generateLanguagesMap()}
                        search
                        label={this.translate('Language')}
                        placeholder={this.translate('Language')}
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>
            </>
        )
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form
                onSubmit={() => this.submit()}
                error={!!error}
                style={{ zIndex: '0' }}
            >
                <Button.Group floated="right" className='form-right-toolbar-container'>
                    {['draft', 'recalled'].includes(this.props.status) && (
                        <Button
                            color="teal"
                            onClick={() =>
                                this.handleChange({
                                    name: 'status',
                                    value: 'submitted',
                                })
                            }
                            disabled={loading}
                        >
                            {this.translate('Submit')}
                        </Button>
                    )}
                    {['submitted'].includes(this.props.status) && (
                        <Button
                            color="green"
                            onClick={() =>
                                this.handleChange({
                                    name: 'status',
                                    value: 'approved',
                                })
                            }
                            disabled={loading}
                        >
                            {this.translate('Approve')}
                        </Button>
                    )}
                    {['approved'].includes(this.props.status) && (
                        <Button
                            color="teal"
                            onClick={() =>
                                this.handleChange({
                                    name: 'status',
                                    value: 'published',
                                })
                            }
                            disabled={loading}
                        >
                            {this.translate('Publish')}
                        </Button>
                    )}
                    {['published', 'submitted'].includes(this.props.status) && (
                        <Button
                            color="orange"
                            onClick={() =>
                                this.handleChange({
                                    name: 'status',
                                    value: 'recalled',
                                })
                            }
                            disabled={loading}
                        >
                            {this.translate('Recall')}
                        </Button>
                    )}
                    <Button disabled={loading}>{this.translate('Save')}</Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="blog post"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </Button.Group>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Page"
                />
                <Message
                    error
                    header="Could not save page"
                    content={error && error.toString()}
                />
                <div style={{ display: 'block', clear: 'both' }}>
                    {this.props.title ? this.encapsulateAccordeon() : this.renderGeneralInformation()}
                </div>
                <label className={'mobile-post-content-header'}>
                    {this.translate('Page Meta Content')}
                </label>
                <MarkdownEditor
                    value={this.props.content}
                    name="content"
                    onChange={({ name, value }) =>
                        this.handleChange({ name, value })
                    }
                    hideToolbar
                />
            </Form>
        );
    }
}
