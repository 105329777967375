import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import { Button, Container, Header, Modal, Table, Grid, Card, GridColumn, List, ListItem, Segment, GridRow, ButtonGroup, Label, CardMeta, CardDescription, Menu, Dropdown } from 'semantic-ui-react';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import InlineOrderUpdate from '../InlineOrderUpdate';
import './styles.css';

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    products?: any;
    panes?: any[];
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    order?: any;
    invoice?: any[];
    quickSave: (data: any) => any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
        products: [],
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private generateOrderLineItems() {
        const { invoice } = this.props;
        if (!invoice) { return; }
        let lineItems = invoice.map(lineItem => {
            const { item } = lineItem;
            return (
                <Table.Row>
                    <Table.Cell verticalAlign='middle'>
                        {lineItem.quantity}
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle'>
                        {item.sku}
                    </Table.Cell>
                    <Table.Cell>
                        <span style={{ fontWeight: 'bolder' }}>{item.title}</span>
                    </Table.Cell>
                </Table.Row>
            )
        })
        return lineItems;
    }

    private renderCustomerAddressCard() {
        return <Card fluid>
            <Card.Content>
                <Card.Header>Delivery Address</Card.Header>
                <List divided relaxed className='customer-order-information-card'>
                    <ListItem>
                        <List.Content floated='right'>
                            14 Angelo Rd.
                        </List.Content>
                        <List.Header>Address Line 1</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            P0S 7A1
                        </List.Content>
                        <List.Header>Postal Code</List.Header>
                    </ListItem>
                </List>
            </Card.Content>
        </Card>;
    }

    private renderCustomerDetailsCard() {
        return <Card fluid>
            <Card.Content>
                <Card.Header>Customer Details</Card.Header>
                <List divided relaxed className='customer-order-information-card'>
                    <ListItem>
                        <List.Content floated='right'>
                            Harun Laal
                        </List.Content>
                        <List.Header>Customer Name</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            514-555-5555
                        </List.Content>
                        <List.Header>Phone Number</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            Delivery
                        </List.Content>
                        <List.Header>Type</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            N/A
                        </List.Content>
                        <List.Header>Note</List.Header>
                    </ListItem>
                </List>
            </Card.Content>
        </Card>;
    }


    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No products!')}</Header>
            </Container>
        )
    }

    render() {
        const { order, invoice } = this.props;
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <Grid padded stackable>
                        <GridRow textAlign='justified'>
                            <GridColumn>
                                <Header content={`${this.translate('Order')} #${order.id}`} floated='left' />
                            </GridColumn>
                        </GridRow>
                        <GridColumn width={11}>
                            <Segment>
                                <Table fluid={'true'} celled>
                                    <Table.Header>
                                        <Table.HeaderCell width={'4'}>Quantity</Table.HeaderCell>
                                        <Table.HeaderCell width={'4'}>SKU</Table.HeaderCell>
                                        <Table.HeaderCell  width={'4'}>Item Name</Table.HeaderCell>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.generateOrderLineItems()}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </GridColumn>
                        <GridColumn width={5}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>Order Details</Card.Header>
                                    <List divided relaxed className='customer-order-information-card'>
                                        <ListItem>
                                            <List.Content floated='right'>
                                                {commonUtils.renderDate(order.date)}
                                            </List.Content>
                                            <List.Header>Order Created Date</List.Header>
                                        </ListItem>
                                        <ListItem>
                                            <List.Content floated='right'>
                                                {commonUtils.renderDate(order.dueDate)}
                                            </List.Content>
                                            <List.Header>Order Due Date</List.Header>
                                        </ListItem>
                                        <ListItem>
                                            <List.Content floated='right'>
                                                {/* <Label color='grey' content={`${order.status}`} /> */}
                                                <InlineOrderUpdate name='' value={order.status} item={order} onChange={this.props.quickSave} />
                                            </List.Content>
                                            <List.Header>Order Status</List.Header>
                                        </ListItem>
                                    </List>
                                </Card.Content>
                                <Card.Content extra>
                                    <div className='ui two buttons'>
                                        <Button content='View Invoice' as='a' href={`/store-management/invoices/${order.invoiceId}`} />
                                    </div>
                                </Card.Content>
                            </Card>
                            {/* {this.renderCustomerDetailsCard()}
                            {this.renderCustomerAddressCard()} */}
                        </GridColumn>
                    </Grid>
                </Gatekeeper>
            </DesktopSkeleton >
        );
    }
}
