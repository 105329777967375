import React from 'react';
import { Menu } from 'semantic-ui-react';
import LoadingNavPlaceholder from '../components/controls/loaders/LoadingNavPlaceholder';
import GatekeeperFactory from './GatekeeperFactory';
import { IRouteMenuItemFactory } from './RouteMenuItemFactory';
import ApplicationSwitcherFactory from "./ApplicationSwitcherFactory";
import DeployButtonFactory from './DeployButtonFactory';
import LogoutButtonFactory from './LogoutButtonFactory';

export default class FeatureAwareRouteMenuItemFactory implements IRouteMenuItemFactory {
    protected routes;

    constructor(routes = [], protected gatekeeperFactory: GatekeeperFactory, protected logoutButtonFactory: LogoutButtonFactory, protected applicationSwitcherFactory: ApplicationSwitcherFactory, protected deployButtonFactory: DeployButtonFactory) {
        this.routes = Array.isArray(routes) ? routes : Object.values(routes);
    }

    create(activePath) {
        const Gatekeeper = this.gatekeeperFactory.create()
        const routes = this.routes
            .filter(({ title, icon }) => title || icon)
            .map(({ title, path = '/', position, icon, header, featureKey, disabled }, i) => {
                return (
                    featureKey ? (
                        <Gatekeeper name={featureKey} loadingComponent={<LoadingNavPlaceholder />}>
                            <Menu.Item as={disabled ? 'div' : 'a'} active={path === activePath} href={path} position={position} header={header} disabled={disabled}>{title}</Menu.Item>
                        </Gatekeeper>
                    ) : (
                        <Menu.Item as={disabled ? 'div' : 'a'} active={path === activePath} href={path} position={position} header={header} disabled={disabled}>{title}</Menu.Item>
                    )
                );
            });
        routes.push(this.applicationSwitcherFactory.create(true));
        routes.push(this.deployButtonFactory.create(true));
        routes.push(this.logoutButtonFactory.create(true));
        return routes;
    }
}