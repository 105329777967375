import languages from '../Languages/Languages';
import irregularPlurals from '../Languages/IrregularPlurals';
import timezones from '../Timezones/Timezones';
import { getEmoji } from 'language-flag-colors';

export default class CommonUtils {
    generateLanguagesMap() {
        return languages.map(language => {
            return { key: language.code, text: language.name, value: language.code, realName: language.realName };
        })
    }

    generateTimezonesMap() {
        return timezones.map(timezone => {
            return { key: timezone.tzCode, text: timezone.label, value: timezone.tzCode, realName: timezone.name };
        })
    }

    renderLanguage(languageCode: string) {
        const fullLanguage = languages.find(({ code }) => code === languageCode);
        return fullLanguage ? `${fullLanguage?.name}` : '-';
    }

    renderLanguageRealName(languageCode: string) {
        const fullLanguage = languages.find(({ code }) => code === languageCode);
        return fullLanguage ? `${fullLanguage?.realName}` : '-';
    }

    renderLanguageFlag(languageCode: string) {
        let flag = getEmoji(languageCode);
        return flag ? flag : languages.find(({ code }) => code === languageCode)?.flag;
    }

    renderDate(date: string, seconds: boolean = true) {
        let options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };
        if (!seconds) { delete options.second; }
        return (
            date &&
            new Date(date).toLocaleString('en-CA', options)
        );
    }

    truncate(str: string, n: number) {
        return str && str.length > n ? str.substring(0, n - 1) + '...' : str;
    }

    plur(word, plural?, count?) {
        if (typeof plural === 'number') {
            count = plural;
        }

        if (irregularPlurals.has(word.toLowerCase())) {
            plural = irregularPlurals.get(word.toLowerCase());

            const firstLetter = word.charAt(0);
            const isFirstLetterUpperCase = firstLetter === firstLetter.toUpperCase();
            if (isFirstLetterUpperCase) {
                plural = firstLetter + plural.slice(1);
            }

            const isWholeWordUpperCase = word === word.toUpperCase();
            if (isWholeWordUpperCase) {
                plural = plural.toUpperCase();
            }
        } else if (typeof plural !== 'string') {
            plural = (word.replace(/(?:s|x|z|ch|sh)$/i, '$&e').replace(/([^aeiou])y$/i, '$1ie') + 's')
                .replace(/i?e?s$/i, match => {
                    const isTailLowerCase = word.slice(-1) === word.slice(-1).toLowerCase();
                    return isTailLowerCase ? match.toLowerCase() : match.toUpperCase();
                });
        }

        return Math.abs(count) === 1 ? word : plural;
    }

    renderCurrency(price: number = 0, intl: string = 'en-CA', currency: string = 'CAD'): string {
        return new Intl.NumberFormat(intl, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' }).format(price);
    }

    generateTimes(startHour = 0, stopHour = 24) {
        const arr: any = [];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                const hour = `${i}:${j === 0 ? `00` : 15 * j}`;
                arr.push({
                    text: hour,
                    value: hour,
                });
            }
        }
        arr.push({
            text: `24:00`,
            value: `24:00`,
        })
        return arr;
    }

    capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.substring(1);
    }

    splitCamelCaseWithAbbreviations(s) {
        return s.match(/^[A-Z]?[^A-Z]*|[A-Z][^A-Z]*/g).join(' ');
    }

    splitKebabCase(s) {
        return s.replace(/-/g, ' ');
    }

    capitalizeFirstLetterLocale(sentence, locale = navigator.language) {
        let words = sentence.split(' ');
        words.forEach(([first, ...rest], index) => {
            words[index] = first.toLocaleUpperCase(locale) + rest.join('');
        });
        return words.join(' ');
    }

    isToday(someDate) {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    isPast(someDate) {
        const givenDate1 = new Date(someDate)
        return new Date().getTime() - givenDate1.getTime() > 0;
    }

    isFuture(someDate) {
        const givenDate1 = new Date(someDate)
        return new Date().getDate() - givenDate1.getDate() < 0;
    }

    zeroPad(num, places) {
        return String(num).padStart(places, '0');
    }
}