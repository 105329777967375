import React, { PureComponent } from "react";
import { Form, Button } from "semantic-ui-react";
import "react-toggle/style.css"

export interface IInlineSkuUpdateProps {
    defaultValue?: number;
    onChange?: (data: any) => any;
    item: any;
    name: string;
    value?: any;
    toggle?: boolean;
}

export interface IInlineSkuUpdateState {
    value?: any;
}

export default class InlineSkuUpdate extends PureComponent<IInlineSkuUpdateProps, IInlineSkuUpdateState> {
    state = {
        value: 'sku',
    };

    static defaultProps = {
        options: [],
        item: {},
        toggle: false,
    }

    handleChange(e) {
        this.setState({ value: e.target.value });

    }

    componentDidMount() {
        this.setState({
            value: this.props.value || this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.value || this.props.defaultValue,
            });
        }
    }

    onSave() {
        if (this.props.onChange) {
            let { item } = this.props;
            const { value } = this.state;
            const data = {
                ...item,
                sku: value,
            }
            this.props.onChange(data);
        }
    }

    render() {
        return (
            <Form.Input fluid action>
                <input defaultValue={this.props.defaultValue} onChange={(e) => { this.handleChange(e) }} />
                <Button color='twitter' icon='save' onClick={() => { this.onSave() }} />
            </Form.Input>
        );
    }
}