import React, { PureComponent } from 'react';
import Component from '../../layouts/component';
import LocalizedPage from '../LocalizedPage';
import { IPageProps } from '../Page/Page';
import DesktopPage from './DesktopPage';

export interface IDeploymentsPageProps extends IPageProps {
    onChange?: (path: string) => any,
    localizationService?: any,
    items: any[],
    onDeploy?: () => any,
    onRefresh?: () => any,
    hasProvisionned: boolean,
    breadcrumbLevels?: any[],
}

export default class DeploymentsPage extends PureComponent<IDeploymentsPageProps> {
    render() {
        const { createRoutes, header, localizationService, onChange = () => { }, items, onDeploy = () => { }, onRefresh = () => { }, hasProvisionned,breadcrumbLevels } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        hasProvisionned={hasProvisionned}
                        items={items}
                        header={header}
                        onChange={onChange}
                        localizationService={localizationService}
                        onDeploy={onDeploy}
                        onRefresh={onRefresh}
                    />
                </Component>
            </Page>
        );
    }
}