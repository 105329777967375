import React, { PureComponent } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
// import { animateScroll as scroll } from 'react-scroll'
// import './styles.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export interface IBookmarkPageProps extends ButtonProps {
    feature: string;
}

export default class BookmarkPage extends PureComponent<IBookmarkPageProps> {
    state = {
        isFavorite: false,
    }

    async componentDidMount() {
        await this.checkIfFavorite()
    }

    private async checkIfFavorite() {
        const { feature } = this.props;
        if (!feature) return false;
        const favoriteFeatures = cookies.get('favorite-features');
        const favoriteFeaturesArray = favoriteFeatures ? favoriteFeatures.split(',') : [];
        this.setState({ isFavorite: favoriteFeaturesArray.includes(feature) });
    }

    private updateFavorites() {
        const { feature } = this.props;
        if (!feature) return false;
        const favoriteFeatures = cookies.get('favorite-features');
        const favoriteFeaturesArray = favoriteFeatures ? favoriteFeatures.split(',') : [];
        if (!favoriteFeaturesArray.includes(feature)) {
            favoriteFeaturesArray.push(feature);
        } else {
            favoriteFeaturesArray.splice(favoriteFeaturesArray.indexOf(feature), 1);
        }
        this.setState({ isFavorite: favoriteFeaturesArray.includes(feature) });
        cookies.set('favorite-features', favoriteFeaturesArray.join(','), { path: '/' });
    }

    render() {
        return (
            <Button floated='right' icon={this.state.isFavorite ? 'star' : 'star outline'} onClick={() => this.updateFavorites()} />
        );
    }
}
