export interface ILocalizationServiceSettings {
    language?: string;
    languages?: string[] | any;
}

export default class LocalizationService {
    static STATE_NOTINITIALIZED = 'NOTINITIALIZED';
    static STATE_INITIALIZING = 'INITIALIZING';
    static STATE_READY = 'READY';

    protected vocabulary = new Map();
    protected state = LocalizationService.STATE_NOTINITIALIZED;
    protected language?: string;

    constructor(protected localizationRepository, protected customizationRepository, protected settings: ILocalizationServiceSettings = {}) {

    }

    initialize() {
        this.state = LocalizationService.STATE_INITIALIZING;
        let language = this.customizationRepository.get('lang') || this.settings.language;
        this.changeLanguage(language);
    }

    getLanguage() {
        return this.language;
    }

    getLanguages() {
        return Object.values(this.settings.languages) || [];
    }

    changeLanguage(language) {
        this.language = language;
        this.customizationRepository.save('lang', language);

        let vocabulary = this.localizationRepository.getByLanguage(this.settings.language);
        let languageVocabulary;

        if (language !== this.settings.language) {
            languageVocabulary = this.localizationRepository.getByLanguage(language);
            vocabulary = {
                ...vocabulary,
                ...languageVocabulary,
            };
        }

        this.vocabulary = new Map(Object.entries(vocabulary));
        this.state = LocalizationService.STATE_READY;
    }

    translate(key) {
        return this.vocabulary.get(key) || key;
    }

}