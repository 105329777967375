import React, { PureComponent } from 'react';
import { Segment, Grid } from 'semantic-ui-react';

export interface IFullLengthSplitStatementsProps {
    children: [any, any];
}

export default class FullLengthSplitStatements extends PureComponent<IFullLengthSplitStatementsProps> {
    render() {
        const { children = [] } = this.props;
        const [left, right] = children;

        return (
            <Segment className='full-length-split-statements' vertical>
                <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                        <Grid.Column className='split-statement'>
                            {left}
                        </Grid.Column>
                        <Grid.Column className='split-statement'>
                            {right}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}