import React, { PureComponent } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import SubscriptionTermForm from "../../../../../forms/subscriptions/SubscriptionTermForm";

export interface ITermEditorModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onSave: (data: any) => Promise<any>;
    item?: any;
}

export interface ITermEditorModalState {
    item?: any;
    state: string;
}

export default class TermEditorModal extends PureComponent<ITermEditorModalProps, ITermEditorModalState> {
    static defaultProps = {
        item: {}
    }

    state: ITermEditorModalState = {
        state: 'create',
    }

    toggleModal() {
        this.props.toggleModal();
    }

    renderState(state: string) {
        switch (state) {
            case 'create': return this.renderCreateState();
            case 'successful': return this.renderSuccessful();
        }

        return ""
    }

    renderCreateState() {
        const { item } = this.props;
        return [
            <Modal.Content>
                <p>Saving the form will create the user and associate it to your account.</p>
                <SubscriptionTermForm
                    onSubmit={async (data) => this.handleSave({ ...item, ...data })}
                    period={item?.period}
                    price={item?.price}
                    controlsTop={false}
                    controlsBottom={true}
                />
            </Modal.Content>,
        ];
    }

    renderSuccessful() {
        return [
            <Modal.Content>
                <Header>Successfully saved term for this subscription</Header>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ]
    }

    async handleSave(data: any) {
        let item;

        try {
            item = await this.props.onSave(data);
        } catch (e) {
            throw e;
        }

        this.setState({ item, state: 'successful' })
    }

    render() {
        const { modalOpen } = this.props;

        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Modify Term</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}