import React from "react";
import { toast } from "react-toastify";
import { Button, Form, Message } from "semantic-ui-react";
import DeleteButton from "../../../controls/buttons/DeleteButton";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
// import "./style.css";

export interface IAccountFormProps extends IStateAwareFormProps {
    name: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
    toastConfig?: any;
}

export default class AccountForm extends StateAwareForm<IAccountFormProps> {
    constructor(props) {
        super(props);
    }

    public static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
        toasConfig: {},
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
            });
        }
    }

    async submit() {
        try {
            await this.handleSubmit();
            toast.success('Account successfully updated.', this.props.toastConfig);
        } catch (e) {
            toast.error(e.message, this.props.toastConfig);
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.submit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right" className='form-right-toolbar-container'>
                        <Button disabled={loading}>Save</Button>
                        {this.props.name && (
                            <DeleteButton
                                handleOnClick={this.props.onDelete}
                                icon={"trash"}
                                itemName={this.props.name}
                                itemType={"account"}
                            />
                        )}
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Account"
                />

                <Message
                    error
                    header="Could not save user"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"Name"}
                    defaultValue={this.props.name}
                    name="name"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
