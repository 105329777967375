import React from "react";
import {
    Form,
    Message,
    Button,
} from "semantic-ui-react";
import SelectInput from "../../../controls/SelectInput";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface ILicenseFormProps extends IStateAwareFormProps {
    name?: string;
    type?: string;
    level?: string;
}

export default class LicenseForm extends StateAwareForm<ILicenseFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => {};
    };

    componentDidMount() {
        super.setState({
            title: this.props.name,
            type: this.props.type,
            level: this.props.level,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                title: this.props.name,
            });
        }
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Looking for the License"
                />

                <Message
                    error
                    header="Could not find license"
                    content={error && error.toString()}
                />

                <Form.Group>
                    <Form.Input
                        width={"16"}
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SelectInput
                        options={[
                            { key: "account", text: "Account", value: "account" },
                            { key: "subscription", text: "Subscription", value: "subscription" },
                        ]}
                        label={"Type"}
                        placeholder="Type"
                        defaultValue={this.props.type}
                        name="type"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                    <SelectInput
                        options={[
                            { key: "base", text: "Base", value: "base" },
                            { key: "user", text: "User", value: "user" },
                            { key: "admin", text: "Admin", value: "admin" },
                        ]}
                        label={"Level"}
                        placeholder="Level"
                        defaultValue={this.props.level}
                        name="level"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>

                <Button.Group floated="right" style={{ paddingBottom: '1rem' }}>
                    <Button disabled={loading}>Find</Button>
                </Button.Group>
            </Form>
        );
    }
}
