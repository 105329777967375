import React from "react";
import { Form, Message, Button, Grid, ButtonGroup } from "semantic-ui-react";
import DeleteButton from "../../../controls/buttons/DeleteButton";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import MarkdownEditor from "../../../controls/MarkdownEditor";
import "./styles.css";

const commonUtils = new CommonUtils();

export interface IIndividualServiceSectionFormProps extends IStateAwareFormProps {
    title?: string;
    name: string;
    language: string;
    content: string;
    additional: {
        featured: string,
        imagePosition: string;
        imageLink: string;
        callToAction: string;
        callToActionLink: string;
    }
}

export default class IndividualServiceSectionForm extends StateAwareForm<IIndividualServiceSectionFormProps> {
    componentDidMount() {
        super.setState({
            title: this.props.title,
            name: this.props.name,
            language: this.props.language,
            content: this.props.content,
            additional: this.props.additional,
            type: "individual-service",
        });
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error} className={'section-form-container'}>
                <ButtonGroup floated="right">
                    <Button
                        color="teal"
                        disabled={loading}
                        floated="right"
                    >
                        Save
                    </Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="section"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </ButtonGroup>
                <div style={{ display: 'block', clear: 'both' }}>
                    <Grid>
                        <Grid.Column floated="left" width={11}>
                            <h2>General Information</h2>
                            <p>
                                This section manages the core information necessary for a section
                                to work.
                            </p>
                        </Grid.Column>
                    </Grid>
                </div>
                <br />
                <br />
                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.title}
                    name="title"
                    onChange={(e, { name, value }) => this.handleChange({ name, value })}
                    required
                />

                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        search
                        options={commonUtils.generateLanguagesMap()}
                        label={"Language"}
                        placeholder="Language"
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        options={[
                            { key: "type", text: "Individual Service", value: "individual-service" },
                        ]}
                        label={"Type"}
                        placeholder="Type"
                        value="individual-service"
                        name="type"
                        disabled
                    />
                    <Form.Checkbox
                        width={'3'}
                        fluid
                        label={"Featured"}
                        defaultChecked={this.props.additional['featured'] ? true : false}
                        name="additional.featured"
                        onChange={(e, { name, checked }) => {
                            let value = checked;
                            this.handleChange({ name, value })
                        }
                        }
                        required
                    >
                    </Form.Checkbox>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Input
                        fluid
                        label={"Call to action"}
                        placeholder="https://picsum.photos/800/600"
                        defaultValue={this.props.additional.imageLink}
                        name="additional.imageLink"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                    <Form.Select
                        fluid
                        options={[
                            { key: "type", text: "Right", value: "right" },
                            { key: "type", text: "Left", value: "left" },
                        ]}
                        label={"Image position"}
                        placeholder="Image position"
                        defaultValue={this.props.additional && this.props.additional.imagePosition ? this.props.additional.imagePosition : "left"}
                        name="additional.imagePosition"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>
                <Form.Group widths={'equal'}>

                    <Form.Input
                        fluid
                        label={"Call to action"}
                        placeholder="Let's get started"
                        defaultValue={this.props.additional.callToAction}
                        name="additional.callToAction"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />

                    <Form.Input
                        fluid
                        label={"Call to action link"}
                        placeholder="https://example.com/contact-us"
                        defaultValue={this.props.additional.callToActionLink}
                        name="additional.callToActionLink"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>

                <label className={'mobile-post-content-header'}>
                    Section blurb
                </label>

                <div className='product-section-form-editor'>
                    <MarkdownEditor
                        value={this.props.content}
                        name="content"
                        onChange={({ name, value }) => this.handleChange({ name, value })}
                    />
                </div>

                <br />

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Section"
                />

                <Message
                    error
                    header="Could not save section"
                    content={error && error.toString()}
                />
            </Form>
        );
    }
}
