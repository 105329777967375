import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import ComponentsPane from '../../components/pages/applications/ArchetypeOverviewPage/panes/ComponentsPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class ArchetypeComponentsController extends LocalizedController {
    private repository: Repository;
    private componentRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, componentRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.componentRepository = componentRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`archetypes/${this.params?.id}/components`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    async handleSearch(data: any) {
        let items;

        try {
            items = await this.componentRepository.find(data);
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleLinkComponent(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'componentId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the component to this archetype. Please try again.");
        }
    }

    async handleUnlinkComponent(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'componentId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to disassociate the component to this archetype. Please try again.");
        }

        await this.refreshItems();
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { items } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="archetype-component-management"
                or={["archetype-component-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <ComponentsPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSearch={async (data: any) => this.handleSearch(data)}
                    onLinkComponent={async (data: any) => this.handleLinkComponent(data)}
                    onUnlinkComponent={async (data: any) => this.handleUnlinkComponent(data)}
                    onModalClose={async () => this.refreshItems()}
                />
            </Gatekeeper>
        )
    }
}