import React, { PureComponent } from "react";
import { Form } from "semantic-ui-react";

export interface ISelectInputProps {
    defaultValue?: string;
    onChange?: (e, value) => any;
    label: string;
    name: string;
    options: any[];
    placeholder?: string;
    required?: boolean;
    search?: boolean;
}

export interface ISelectInputState {
    value?: string;
}

export default class SelectInput extends PureComponent<ISelectInputProps, ISelectInputState> {
    state: ISelectInputState = {};

    static defaultProps = {
        options: [],
        value: '',
    }

    handleChange(e, { name, value }) {
        this.setState({value}, () => {
            this.props.onChange && this.props.onChange(e, { name, value });
        });
    }

    componentDidMount() {
        this.setState({
            value: this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.defaultValue,
            });
        }
    }

    render() {
        return (
            <Form.Select
                fluid
                options={this.props.options}
                label={this.props.label}
                placeholder={this.props.placeholder}
                value={this.state.value}
                name={this.props.name}
                onChange={(e, { name, value }) => this.handleChange(e, { name, value })}
                required={this.props.required}
                search={this.props.search}
            />
        );
    }
}