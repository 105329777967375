import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IOrderPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    localizationService?: any;
    breadcrumbLevels?: any;
    onPathChange: (path: string) => any;
    panes: any;
}

export default class OrderPage extends PureComponent<IOrderPageProps> {
    render() {
        const { createRoutes,
            Gatekeeper,
            localizationService,
            breadcrumbLevels,
            panes,
            onPathChange,
        } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        panes={panes}
                        onPathChange={onPathChange}
                    />
                    {/* <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} /> */}
                </Component>
            </Page>
        );
    }
}
