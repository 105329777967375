import React from "react";
import { PureComponent } from "react";
import { Card, Container, Header, Icon } from "semantic-ui-react";
import PlatformDetection from "../../../utilities/PlatformDetection/PlatformDetection";
const platformDetection = new PlatformDetection();
export interface IActiveSessionProps { }
export interface IActiveSessionState { }

export default class ActiveSession extends PureComponent<
    IActiveSessionProps,
    IActiveSessionState
> {
    render() {
        return (
            <Container>
                <Header>Active Sessions</Header>
                <Card.Group>
                    <Card>
                        <Card.Content>
                            <Card.Header icon>
                                <Icon name={platformDetection.getBrowserIcon()} />
                                {platformDetection.getBrowser()}
                            </Card.Header>
                            <Card.Meta>74.59.214.24</Card.Meta>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </Container>
        );
    }
}
