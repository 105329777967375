import React, { PureComponent } from "react";
import { Button, Icon, Table, Modal, Popup, FormGroup, FormInput, Grid } from "semantic-ui-react";
import { DesktopSkeleton } from "../../../layouts/skeleton";
import { Message, UnauthorizedMessage } from "../../../layouts/messages";
import { LoadingSpinner } from "../../../controls";
import { ScrollableTable } from "../../../layouts/tables";
import CustomersModalContent from "./CustomersModalContent";
import DimmedModal from "../../../layouts/modals/DimmedModal/DimmedModal";
import AccountForm from "../../../forms/accounts/AccountForm";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import "./styles.css";

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    name?: any;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
    newAccountModalOpen: boolean;
    accounts: any[];
    filteredNumberResults: number;
    searchInputHasValue: boolean;
    searchvalue: string;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
        newAccountModalOpen: false,
        accounts: [],
        filteredNumberResults: 0,
        searchInputHasValue: false,
        searchvalue: '',
    };

    static defaultProps = {
        onDoubleClick: () => { }
    }

    componentDidMount() {
        this.setState({
            accounts: this.props.items ? this.props.items : [],
            filteredNumberResults: this.props.items ? this.props.items.length : 0,
        })
    }

    componentDidUpdate(prevProps: Readonly<IDesktopPageProps>, prevState: Readonly<IDesktopPageState>, snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({
                accounts: this.props.items ? this.props.items : [],
                filteredNumberResults: this.props.items ? this.props.items.length : 0,
            })
        }
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    filterFeatures(name: string) {
        const { items } = this.props;
        if (!items) { return; }
        const accounts = items.filter(function (account) { return (account.name || '').toLowerCase().indexOf(name.toLowerCase()) >= 0; });
        this.setState({ accounts, filteredNumberResults: accounts.length, searchInputHasValue: name.length > 0, searchvalue: name });
    }

    resetTextInput() {
        this.filterFeatures('');
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    openNewAcctModal() {
        this.setState(({ newAccountModalOpen }) => ({ newAccountModalOpen: !newAccountModalOpen }));
    }

    private renderInformationalModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>About Customer Stages</Modal.Header>
                <Modal.Content>
                    <CustomersModalContent />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        Got it!
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal header="Nothing to see here!" content="Add your account!" actions={['Not now', { key: 'done', content: 'Create account', positive: true, href: '/accounts/create' }]} />;
        }
        return;
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell icon>
                        {"Stage "}
                        <Icon
                            name="question circle"
                            className="icon-link"
                            onClick={() => this.openModal()}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderTableBody() {
        const { Gatekeeper } = this.props;
        const { accounts } = this.state;
        if (!accounts) { return; }
        const accountsTable = accounts.map((customer: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, customer)}>
                    <Table.Cell>{customer.name}</Table.Cell>
                    <Table.Cell className="blog-list-customer-status capitalize">
                        {customer.stage}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <Gatekeeper name="account-view">
                            <Popup
                                trigger={
                                    <Button
                                        icon="eye"
                                        color="blue"
                                        as="a"
                                        href={`/accounts/${customer.id}`}
                                    />
                                }
                                content="View the details of the account."
                                basic
                            />
                        </Gatekeeper>
                        <Gatekeeper name="account-management">
                            <Popup
                                trigger={
                                    <Button
                                        icon="edit"
                                        color="teal"
                                        as="a"
                                        href={`/accounts/${customer.id}/edit`}
                                    />
                                }
                                content="Edit core account details."
                                basic
                            />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            );
        });
        return (
            <Table.Body>
                {!accountsTable.length ? <Message content="No content." compact /> : accountsTable}
            </Table.Body>
        );
    }

    renderNewAccountModal() {
        const { newAccountModalOpen } = this.state;
        return (
            <Modal
                onClose={() => this.openNewAcctModal()}
                dimmer
                open={newAccountModalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Create a new account</Modal.Header>
                <Modal.Content>
                    <AccountForm
                        name={this.props.name}
                        onSubmit={(data) => this.props.onSave(data)}
                        controlsBottom
                        controlsTop={false}
                    />
                </Modal.Content>
            </Modal>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="account-view" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    {this.renderInformationalModal(this.state.modalOpen)}
                    <div className="item-list-container">
                        <Grid columns={'1'}>
                            <Grid.Column width={'5'}>
                                <FormGroup>
                                    <FormInput fluid placeholder="Search account by name" value={this.state.searchvalue} onChange={(event) => this.filterFeatures(event.target.value)} type='text' icon={(e) => {
                                        if (this.state.searchInputHasValue) {
                                            return <Icon name='delete' link onClick={() => { this.resetTextInput() }} />
                                        }
                                        return <Icon name='search' />
                                    }} />
                                    <small className="text-muted">Now showing {this.state.filteredNumberResults} {commonUtils.plur('result', 'results', this.state.filteredNumberResults)}{this.state.searchInputHasValue && ` for ${this.state.searchvalue}`}.</small>
                                </FormGroup>
                            </Grid.Column>
                        </Grid>
                        <ScrollableTable striped selectable className="ten">
                            {this.renderTableHeaders()}
                            {this.renderTableBody()}
                            <Gatekeeper name="account-management">
                                <Table.Footer fullWidth>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell colSpan='4'>
                                        <Button color="green" size="small" floated="right" icon onClick={() => this.openNewAcctModal()}><Icon name='plus' />Create new Account</Button>
                                    </Table.HeaderCell>
                                </Table.Footer>
                            </Gatekeeper>
                        </ScrollableTable>
                        {this.renderNewAccountModal()}
                        {this.renderNoContentModal()}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
