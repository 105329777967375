import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import { Button, Container, Header, Icon, Modal, Table, Image, Grid, Statistic, Card } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import ProductModalContent from './ProductModalContent';
import { DimmedModal } from '../../../layouts/modals';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import LocalizedCreateButton from '../../../controls/buttons/CreateButton/LocalizedCreateButton';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    products?: any;
    panes?: any[];
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
        products: [],
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header={this.translate('Nothing to see here!')}
                content={this.translate('Add your first product!')}
                actions={
                    [this.translate('Not now'),
                    { key: 'done', content: this.translate('Create product'), positive: true, href: '/products/create' }
                    ]}
            />;
        }
        return;
    }

    private renderProductDefinitionHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Price')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderProductDefinitionBody() {
        const { products } = this.props;
        const productsTable = products.map((product: any) => (
            <Table.Row onClick={(e) => this.handleDoubleClick(e, product)}>
                <Table.Cell>{product.title}</Table.Cell>
                <Table.Cell>
                    {commonUtils.renderLanguage(product.language)}
                </Table.Cell>
                <Table.Cell>{commonUtils.renderCurrency(product.price)}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Button
                        icon="edit"
                        color="teal"
                        as="a"
                        href={`/products/${product.id}/edit`}
                    />
                </Table.Cell>
            </Table.Row>
        ));
        return (
            <Table.Body>
                {!productsTable.length ? this.renderNoContentMessage() : productsTable}
            </Table.Body>
        );
    }

    private renderInformationalModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>{this.translate('About Product Statuses')}</Modal.Header>
                <Modal.Content>
                    <ProductModalContent localizationService={this.props.localizationService} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        {this.translate('Got it!')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No products!')}</Header>
            </Container>
        )
    }

    private renderDefinitionCard() {
        const { items } = this.props;
        if (!items) return <></>;
        return (<Card fluid>
            <Card.Content>
                <Card.Header>{items.title}</Card.Header>
                <Card.Meta>{commonUtils.renderCurrency(items.price)}</Card.Meta>
                <Card.Description>{items.content}</Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="right">
                <Button color='green' icon='edit' content='Edit' as='a' href={`/products/${items.id}/edit`} />
            </Card.Content>
        </Card>)
    }

    render() {
        const { panes } = this.props;
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    {this.renderInformationalModal(this.state.modalOpen)}
                    <div className="item-list-container">
                        <Grid columns={2} stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    {panes ? panes[0].render() : <></>}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Header as='h3' content={this.translate('All Products for')}></Header>
                                    {panes ? panes[1].render() : <></>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
