export interface ILink {
    self?: string | undefined;
    [key: string]: any;
}

export default class DecoratedCollection {
    protected entities = {};
    protected links: ILink = {};

    constructor(protected type) {

    }

    getEntities() {
        return this.getEmbeddedEntities(this.type) || [];
    }

    getEmbeddedEntities(type) {
        return this.entities[type];
    }

    setEmbeddedEntities(type, entities) {
        this.entities[type] = entities;
        return this;
    }

    setEntities(entities) {
        return this.setEmbeddedEntities(this.type, entities);
    }

    setEmbedded(entities) {
        this.entities = entities;
        return this;
    }

    getLinks() {
        return this.links;
    }

    getSelfLink(): string | undefined {
        return this.links.self;
    }

    setLinks(links) {
        this.links = links;
        return this;
    }
}