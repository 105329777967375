import React, { PureComponent, RefObject } from 'react';
import { Form, Button, SemanticSIZES } from 'semantic-ui-react';

export interface IFileUploadProps {
    onChange: ({ name, value }) => {},
    content: string;
    labelPosition: "left" | "right";
    icon: string;
    name?: string;
    disabled?: boolean;
    label?: string;
    size?: SemanticSIZES;
    accept?: string;
    required?: boolean;
    value?: string;
}

export default class FileUpload extends PureComponent<IFileUploadProps> {
    static defaultProps = {
        onChange: ({ name, value }) => { },
        content: "Choose File",
        labelPosition: 'left',
        icon: 'file',
    };

    state = {};

    fileInputRef: RefObject<HTMLInputElement> = React.createRef();

    handleFileChange(e) {
        const { onChange, name } = this.props;

        return onChange({ name, value: e.target.files[0] })
    }

    render() {
        const { labelPosition, content, icon, name, disabled, label, size, accept, required, value } = this.props;

        return (
            <Form.Field name={name} disabled={disabled} required={required} value={value}>
                {label && <label>{label}</label>}
                <Button
                    content={content}
                    labelPosition={labelPosition}
                    icon={icon}
                    onClick={() => this.fileInputRef.current && this.fileInputRef.current.click()}
                    size={size}
                    type="button"
                />
                <input
                    ref={this.fileInputRef}
                    type="file"
                    hidden
                    accept={accept}
                    onChange={e => this.handleFileChange(e)}
                />
            </Form.Field>
        );
    }
}