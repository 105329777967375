export default {
    "host": process.env.REACT_APP_API_SUBSCRIPTION_HOST,
    "port": process.env.REACT_APP_API_SUBSCRIPTION_PORT,
    "basePath": process.env.REACT_APP_API_SUBSCRIPTION_BASE_PATH,
    "path": "subscriptions",
    "respondAsText": false,
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Location",
    }
};

export const RESOURCE = 'subscriptions';

export const SUBSCRIPTION_OPTIONS = {
    secure: {
        getOne: true,
        get: true,
        create: true,
        update: true,
        delete: true,
        link: true,
        unlink: true,
        count: true,
    }
}