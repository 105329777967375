import React from 'react';
import GroupSettingsPage from '../../components/pages/SettingsPage/GroupSettingsPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class GroupController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: []
    };

    constructor(props) {
        super(props);
        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    protected changeSettings(path) {
        this.router.redirect(`/settings/${path}`);
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
            // items.sort(function(a, b) {
            //     if (a.name < b.name) return -1;
            //     else if (a.name > b.name) return 1;
            //     return 0;
            // });
        } catch (e) {
            console.error(e)
            return [];
        }

        items = await Promise.all(items.map(async (item) => {
            try {
                item.resources = await this.repository.getByPath(`groups/${item.id}/resources`);
            } catch (e) {
                console.error(e);
                item.resources = [];
            }

            try {
                item.permissions = await this.repository.getByPath(`groups/${item.id}/permissions`);
                item.permissions.map(permission => {
                    permission.resource = item.resources.find(resource => permission.resourceId === resource.id);
                    return permission;
                })
            } catch (e) {
                console.error(e);
                item.permissions = [];
            }

            return item;
        }))

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();
        const { items } = this.state;

        return this.prepare(
            <GroupSettingsPage
                
                onChange={(path) => this.changeSettings(path)}
                Gatekeeper={Gatekeeper}
                items={items}
            />
        )
    }
}