import React, { PureComponent } from "react";
import {
    Header,
    Divider,
    Button,
    Icon,
    Form,
    Card,
    Label,
    Modal,
    Checkbox,
    Table,
} from "semantic-ui-react";
import SettingsTemplate from "../SettingsTemplate";
import { IGroupSettingsPageProps } from "./GroupSettingsPage";
import "./styles.css";

export interface IGroupSettingsDesktopPageProps extends IGroupSettingsPageProps {
    Gatekeeper: any;
    items: any[];
}

export interface IGroupSettingsDesktopPageState {
    open: boolean;
    name: string;
    groupModalOpen: boolean;
    selectedGroupName: string;
    selectedItem: any;
}

export default class DesktopPage extends PureComponent<
    IGroupSettingsDesktopPageProps,
    IGroupSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        groupModalOpen: false,
        selectedGroupName: "",
        selectedItem: undefined,
    };

    protected handleItemClick(name) {
        this.props.onChange(name)
    }

    protected setOpen(open, name) {
        this.setState({ open, name });
    }

    protected setGroupModalOpen(groupModalOpen, selectedGroupName, selectedItem) {
        this.setState({ groupModalOpen, selectedGroupName, selectedItem });
    }

    protected renderGroupModal() {
        const { groupModalOpen, selectedGroupName, selectedItem } = this.state;
        return (
            <Modal
                open={groupModalOpen}
                onClose={() => this.setGroupModalOpen(false, "", undefined)}
                onOpen={() => this.setGroupModalOpen(true, selectedGroupName, selectedItem)}
            >
                <Modal.Header>{selectedGroupName}</Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        <Modal.Description>
                            <Form.Field>
                                <label>Name</label>
                                <input defaultValue={selectedGroupName} />
                            </Form.Field>
                            {/* <Form.Field>
                                <label>Users in the group</label>
                                <Dropdown
                                    fluid
                                    selection
                                    multiple
                                    search
                                    options={[
                                        {
                                            key: "Julian Wolfe",
                                            text: "Julian Wolfe",
                                            value: "julian_wolfe",
                                        },
                                        {
                                            key: "Christopher Richa",
                                            text: "Christopher Richa",
                                            value: "christopher_richa",
                                        },
                                        {
                                            key: "Erika Kreimes",
                                            text: "Erika Kreimes",
                                            value: "erika_kreimes",
                                        },
                                    ]}
                                    placeholder={`Add users to the ${selectedGroupName} group`}
                                    value={["julian_wolfe", "christopher_richa"]}
                                />
                            </Form.Field> */}
                            <Form.Field>
                                <label>Permissions</label>
                                <Table>
                                    <Table.Header>
                                        <Table.HeaderCell>Resource Name</Table.HeaderCell>
                                        <Table.HeaderCell>Resource Path</Table.HeaderCell>
                                        <Table.HeaderCell colSpan="2">Verb</Table.HeaderCell>
                                    </Table.Header>
                                    <Table.Body>
                                        {selectedItem && ((selectedItem as any)?.permissions || []).map(item => (
                                            <Table.Row>
                                                <Table.Cell>{item?.resource?.name}</Table.Cell>
                                                <Table.Cell>{item?.resource?.path}</Table.Cell>
                                                <Table.Cell>{item?.verb}</Table.Cell>
                                                <Table.Cell>
                                                    <Checkbox checked />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Form.Field>
                        </Modal.Description>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setOpen(false, "")} primary>
                        Proceed <Icon name="chevron right" />
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    protected renderGroup(item) {
        return (
            <Card
                fluid
                onClick={() => {
                    this.setGroupModalOpen(true, item.name, item);
                }}
            >
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Description>
                    </Card.Description>
                    <Divider />
                    <Card.Content>
                        <Header sub>Available resources</Header>
                        {item.resources.map(resource => <Label color="red" content={resource.path} />)}
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="groups">
                <Gatekeeper name="group-view">
                    <>
                        {this.renderGroupModal()}
                        <h2>Groups</h2>
                        <Card.Group>
                            {this.props.items.map(item => this.renderGroup(item))}
                        </Card.Group>
                    </>
                </Gatekeeper>
            </SettingsTemplate>
        );
    }
}
