import React, { PureComponent } from 'react';
import { Button, Card, CardContent, CardDescription, CardGroup, CardHeader, CardMeta, Checkbox, Dropdown, Form, FormField, Grid, GridColumn, GridRow, Header, Icon, Input, Label, Menu, Modal, Table } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../controls';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import { ScrollableTable } from '../../../layouts/tables';
import InlinePriceUpdate from '../../products/ProductPage/InlinePriceUpdate';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import BulkPriceUpdateModal from './BulkPriceUpdateModal';
import './styles.css';
import InlineSkuUpdate from '../../products/ProductPage/InlineSkuUpdate';
import { toast } from 'react-toastify';
import ContentEditionForm from './ContentEditionForm';

const commonUtils = new CommonUtils();

export interface IProductDesktopPageProps {
    productDefinition: any,
    productPropertyAttributes: any[],
    productSelectionAttributes: any[],
    hasCustomSku: boolean,
    Gatekeeper: any;
    localizationService: any;
    onDelete?: () => void;
    createProperty?: () => void;
    updatePrices?: (data: any[]) => void;
    handlePublishUnpublish?: (data: string) => void;
    handleAttributePriceUpdate?: (data: any) => void;
    products: any,
}

export interface IDesktopPageState {
    openModal: boolean;
    selectAllProducts: boolean;
    selectedItems: any[];
    productContent: any[];
    openContentModal: boolean;
    productAttributePriceModalOpen: boolean;
    selectedAttributes: any;
    selectedAttributesProcessing: boolean;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps, IDesktopPageState> {
    static defaultProps = {
    };

    state = {
        openModal: false,
        selectAllProducts: false,
        selectedItems: [],
        productContent: [],
        openContentModal: false,
        productAttributePriceModalOpen: false,
        selectedAttributes: {},
        selectedAttributesProcessing: false,
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private displayContentModal(openContentModal: boolean = true, productContent: any[] = []) {
        this.setState({ openContentModal, productContent });
    }

    private preparePropertyAttributes() {
        const { productPropertyAttributes } = this.props;
        let results = productPropertyAttributes.map((property) => {
            const { name, options, id } = property;
            return (<Card fluid>
                <CardContent>
                    <CardHeader>{name}</CardHeader>
                    <CardDescription>{options instanceof Array ? options.join() : options}</CardDescription>
                </CardContent>
            </Card>
            )
        })
        if (!results.length) {
            return (
                <GridColumn>
                    <Header as="h3" content="Attributes"></Header>
                    <CardGroup centered>
                        <Card>
                            <CardContent>
                                <CardDescription>No attributes for this product</CardDescription>
                            </CardContent>
                        </Card>
                    </CardGroup>
                </GridColumn>
            )
        }
        return (
            <GridColumn>
                <Header as="h3" content="Attributes"></Header>
                <CardGroup itemsPerRow={3}>
                    {results}
                </CardGroup>
            </GridColumn>
        );
    }

    private prepareOptionAttributes() {
        const { productSelectionAttributes } = this.props;
        let results = productSelectionAttributes.map((property) => {
            const { name, options, id } = property;
            let optionTags = options.map((option) => {
                return (<Label basic color='black' content={option} />)
            });

            return (<Card fluid>
                <CardContent>
                    <CardHeader>{property.name}</CardHeader>
                    <CardDescription>{optionTags}</CardDescription>
                </CardContent>
            </Card>
            )
        })
        if (!results.length) {
            return (
                <GridColumn>
                    <Header as="h3" content="Options"></Header>
                    <CardGroup centered>
                        <Card fluid>
                            <CardContent>
                                <CardDescription>No options for this product</CardDescription>
                            </CardContent>
                        </Card>
                    </CardGroup>
                </GridColumn>
            )
        }
        return (
            <GridColumn>
                <Header as="h3" content="Options"></Header>
                <CardGroup itemsPerRow={3}>
                    {results}
                </CardGroup>
            </GridColumn>
        );
    }

    handlePublishUnpublish(status: string) {
        this.props.handlePublishUnpublish && this.props.handlePublishUnpublish(status);
    }


    renderGeneralInformation() {
        const { productDefinition } = this.props;
        if (!productDefinition) { return; }
        return (
            <Card fluid>
                <CardContent style={{ alignItems: 'center' }}>
                    <Header>{productDefinition.seriesCode ? productDefinition.seriesCode + '. ' : ''}{productDefinition.title}</Header>
                    <Header floated='right'>{commonUtils.renderCurrency(productDefinition.price, JSON.parse(localStorage.getItem('lang')!))}</Header>
                    <CardMeta>
                        <Label color='blue'>
                            {productDefinition.category}
                        </Label>
                    </CardMeta>
                </CardContent>
                {productDefinition.content && <CardContent>
                    <CardDescription>
                        {productDefinition.content}
                    </CardDescription>
                </CardContent>}
                <Card.Content extra>
                    <Label content={this.translate(productDefinition?.status).toUpperCase()} color={'black'} basic circular />
                    {productDefinition?.status && 'published' === productDefinition?.status && <Button icon='send' color='red' onClick={() => { this.handlePublishUnpublish('draft') }} floated={'right'}>
                        {this.translate('Unpublish')}
                    </Button>}
                    {productDefinition?.status && 'published' !== productDefinition?.status && <Button icon='send' color='green' onClick={() => { this.handlePublishUnpublish('published') }} floated={'right'}>
                        {this.translate('Publish')}
                    </Button>}
                    <Button icon='edit' color='teal' as={'a'} href={`/productdefinitions/${productDefinition?.id}/edit`} floated={'right'}>
                        {this.translate('Edit')}
                    </Button>
                </Card.Content>
            </Card>
        )
    }

    renderAttributes() {
        const { productPropertyAttributes, productSelectionAttributes } = this.props;
        if (!productPropertyAttributes || !productSelectionAttributes) { return; }
        return (
            <Grid columns={2} divided>
                <GridRow>
                    {this.preparePropertyAttributes()}
                    {this.prepareOptionAttributes()}
                </GridRow>
            </Grid>
        )
    }

    private selectAllProducts(checked: boolean = false, value: any) {
        this.setState({
            selectAllProducts: checked,
            selectedItems: checked ? this.props.products : [],
        });
    }

    private updateSelection(checked: boolean = false, value: any) {
        const { products } = this.props;
        let { selectedItems } = this.state;
        if (!products.length) { return; }
        let selectedProduct = products[value];
        let clonedSelectedItems: any[] = selectedItems;
        if (selectedProduct) {
            if (checked) {
                clonedSelectedItems.push(selectedProduct)
            } else {
                clonedSelectedItems.filter((value, index, arr) => {
                    // If the value at the current array index matches the specified value (2)
                    if (value.id === selectedProduct.id) {
                        // Removes the value from the original array
                        arr.splice(index, 1);
                        return true;
                    }
                    return false;
                });
            }
        }
        this.setState({ selectedItems: clonedSelectedItems, selectAllProducts: clonedSelectedItems.length === products.length });
        this.forceUpdate();
    }

    getProductContent(array) {
        let arr = array.filter(function (object) {
            return object['language'] === JSON.parse(localStorage.getItem('lang')!);
        })[0];
        console.log('array', arr);
        return arr;
    };

    renderContentEditionForm() {
        const { openContentModal, productContent } = this.state;
        const { localizationService } = this.props;
        return <Modal size='fullscreen' open={openContentModal}>
            <ContentEditionForm
                localizationService={localizationService}
                content={productContent}
                onSave={(data) => console.log('hi')}
                toggleModal={() => this.displayContentModal(false)}
            />
        </Modal>
    }

    renderProducts() {
        const { products } = this.props;
        const { selectedItems } = this.state;

        let renderedProducts = products.map((product, index) => {
            if (!product) { return; }
            // console.log(product, product.contents, product.title);
            // let title = product.contents ? this.getProductContent(product.contents).title : product.title;
            let attributes = product.attributes && Object.entries(product.attributes).map(([key, value], index) => {
                return <span style={{ marginRight: "0.25rem" }}><strong>{key}:</strong> {value}</span>;
            });
            let title = product.title;
            return (<Table.Row>
                <Table.Cell style={{ width: '5%' }}>
                    <Checkbox
                        checked={(
                            selectedItems.findIndex((selectedProduct: any) => {
                                return selectedProduct.id == product.id
                            }) >= 0)}
                        value={index}
                        onChange={(e, { checked, value }) => this.updateSelection(checked, value)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form.Field inline>

                        {this.props.hasCustomSku ? <InlineSkuUpdate name='sku' item={product} defaultValue={product.sku} onChange={(data) => this.singleUpdate(data)} /> : product.sku}
                    </Form.Field>
                </Table.Cell>
                <Table.Cell >
                    <div className={'bulk-checkbox'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: '100%', flexWrap: 'wrap' }}>
                            {attributes}
                        </span>
                    </div>
                </Table.Cell>
                <Table.Cell><InlinePriceUpdate key={product.id} name='' item={product} defaultValue={product.price} onChange={(data) => { this.singleUpdate(data) }} /></Table.Cell>
                {/* <Table.Cell>{commonUtils.renderLanguageRealName(product.language)}</Table.Cell> */}
                {/* <Table.Cell>{product.content}</Table.Cell> */}
            </Table.Row>)
        })
        return (
            <ScrollableTable className='five'>
                <Table.Header>
                    <Table.Row>
                        {/* <Table.HeaderCell>
                            <div className={'bulk-checkbox'}>
                                {/* <Checkbox checked={this.state.selectAllProducts} onChange={(e, { checked, value }) => this.selectAllProducts(checked, value)} /> *
                            </div>
                        </Table.HeaderCell> */}
                        <Table.HeaderCell style={{ width: '5%' }} />
                        <Table.HeaderCell>{this.translate('SKU')}</Table.HeaderCell>
                        <Table.HeaderCell >{this.translate('Variant')}</Table.HeaderCell>
                        <Table.HeaderCell>{this.translate('Price')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderedProducts}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan={6}>
                            <Button
                                floated='right'
                                icon='dollar'
                                content={`${this.translate('Bulk Update Price')} ${this.state.selectedItems.length > 0 ? this.translate('for') + ' ' + this.state.selectedItems.length + ' ' + commonUtils.plur(this.translate('product'), this.translate('products'), this.state.selectedItems.length) : ''}`}
                                labelPosition='left'
                                primary
                                disabled={this.state.selectedItems.length === 0}
                                size='small'
                                onClick={() => this.openModal()}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </ScrollableTable>
        )
    }

    openModal() {
        const { openModal } = this.state;
        this.setState({ openModal: !openModal });
    }

    bulkUpdate(data: any[]) {
        if (this.props.updatePrices) {
            try {
                this.props.updatePrices(data);
                this.openModal();
                toast.success(this.translate('Product(s) successfully updated. Refreshing page...'), {
                    onClose: () => { window.location.reload() }
                });
            } catch (e) {
                toast.error(e.getMessage());
            }
        }
    }

    singleUpdate(data: any[]) {
        if (this.props.updatePrices) {
            try {
                this.props.updatePrices([data]);
                toast.success(this.translate('Product(s) successfully updated.'));
            } catch (e) {
                toast.error(e.getMessage());
            }
        }
    }

    renderModal() {
        return (
            <Modal
                onClose={() => this.openModal()}
                open={this.state.openModal}
                size="small"
            >
                <Modal.Header>{`${this.translate('Change the price of')} ${this.state.selectedItems.length} ${this.translate(commonUtils.plur('product', 'products', this.state.selectedItems.length))}`}</Modal.Header>
                <Modal.Content>
                    <BulkPriceUpdateModal items={this.state.selectedItems} localizationService={this.props.localizationService} updatePrices={(data) => this.bulkUpdate(data)} />
                </Modal.Content>
            </Modal>
        )
    }

    handleProductAttributePriceChange(data) {
        let selectedAttributes = this.state.selectedAttributes;
        selectedAttributes[data.name] = data.value;
        this.setState({ selectedAttributes });
        console.log(selectedAttributes);
    }

    renderProductAttributePriceModal() {
        let options = this.props.productSelectionAttributes.map((attr) => {
            let rdoName = attr.name;
            let rdos = attr.options.map((opt) => {
                return <Form.Radio
                    label={opt}
                    name={rdoName}
                    value={opt}
                    // checked={this.state.selectedAttributes[attr.name] && this.state.selectedAttributes[attr.name] === opt}
                    onChange={(event, data) => this.handleProductAttributePriceChange(data)}
                />
            })
            return rdos;

        })
        let renderedAttr = this.props.productSelectionAttributes.map((attr, index) => {
            return (
                <Form.Group inline>
                    <label>{attr.name}</label>
                    {options[index]}
                </Form.Group>
            )
        })
        return (
            <Modal
                onClose={() => { this.openRenderProductAttributePriceModal(); }}
                open={this.state.productAttributePriceModalOpen}
                size="small"
            >
                <Modal.Header>{`${this.translate('Change the price of products based on attributes')}`}</Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        {renderedAttr}
                        <Form.Input name={'newPrice'} type="number" onChange={(event, data) => this.handleProductAttributePriceChange(data)} />
                    </Form>
                </Modal.Content>
                <Modal.Actions><Button.Group>
                    <Button content="Update" onClick={() => this.updateByAttributes()} loading={this.state.selectedAttributesProcessing} disabled={this.state.selectedAttributesProcessing} />
                </Button.Group></Modal.Actions>
            </Modal>
        )
    }

    async updateByAttributes() {
        this.setState({ selectedAttributesProcessing: true });
        if (this.props.handleAttributePriceUpdate) {
            await this.props.handleAttributePriceUpdate(this.state.selectedAttributes);
            toast.success(this.translate('Product(s) successfully updated.'));
        }
        this.setState({ selectedAttributesProcessing: false, selectedAttributes: {} });
        this.openRenderProductAttributePriceModal();
    }

    openRenderProductAttributePriceModal() {
        this.setState({ productAttributePriceModalOpen: !this.state.productAttributePriceModalOpen })
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    {this.renderContentEditionForm()}
                    {this.renderModal()}
                    {this.renderProductAttributePriceModal()}
                    <div className="editor-container product-editor">
                        <Header>{this.translate('General Information')}</Header>
                        {this.renderGeneralInformation()}
                        {this.renderAttributes()}
                        <Header>{this.translate('Added Products')}</Header>
                        <Button content="Update price by attributes" onClick={() => this.openRenderProductAttributePriceModal()} />{this.props.products.length}
                        {this.renderProducts()}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
