import React, { PureComponent } from 'react';
import { Segment, Container } from 'semantic-ui-react';

export default class Statements extends PureComponent {
    render() {
        const { children } = this.props;

        return (
            <Segment className='statements' vertical>
                <Container text>
                    {children}
                </Container>
            </Segment>
        );
    }
}