import React from "react";
import { PureComponent } from "react";
import { Button, Dropdown, Menu, Modal, Icon, Card, Input, Divider, Popup } from "semantic-ui-react";
import { Repository } from "../../../repositories";
import { SecurityService } from "../../../services";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import copy from "clipboard-copy";
import { ToastPosition, toast } from "react-toastify";
import './styles.css'

const commonUtils = new CommonUtils();

export interface IApplicationSwitcherProps {
    repository: Repository;
    service: SecurityService;
    isMobile?: boolean;
    Gatekeeper: any;
}

export interface IApplicationSwitcherState {
    items: any[],
    filteredItems: any[],
    inputValue: string,
    value: any,
    modalOpen: boolean,
    numberOfItems: number,
}

export default class ApplicationSwitcher extends PureComponent<IApplicationSwitcherProps, IApplicationSwitcherState> {
    private repository: Repository;
    private service: SecurityService;

    private toastConfig = {
        autoClose: parseInt(process.env.REACT_APP_CLIENT_TOAST_AUTOCLOSE || '5000'),
        closeButton: ('true' === process.env.REACT_APP_CLIENT_TOAST_CLOSEBUTTON),
        draggable: ('true' === process.env.REACT_APP_CLIENT_TOAST_DRAGGABLE),
        limit: parseInt(process.env.REACT_APP_CLIENT_TOAST_LIMIT || '0'),
        newestOnTop: ('true' === process.env.REACT_APP_CLIENT_TOAST_NEWESTONTOP),
        position: (process.env.REACT_APP_CLIENT_TOAST_POSITION || 'top-right') as ToastPosition,
        pauseOnHover: ('true' === process.env.REACT_APP_CLIENT_TOAST_PAUSEONHOVER),
    }

    state = {
        items: [],
        filteredItems: [],
        inputValue: '',
        value: undefined,
        modalOpen: false,
        numberOfItems: 0,
    };

    constructor({ repository, service, Gatekeeper, ...props }) {
        super({ repository, service, Gatekeeper, ...props });
        this.repository = repository;
        this.service = service;
    }

    setOpen(modalOpen: boolean) {
        this.setState({ modalOpen });
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            throw e;
        }

        return items;
    }

    async componentDidMount() {
        let items;

        try {
            items = await this.get();
        } catch (e) {
            if (401 === (e as any)?.statusCode) {
                this.service.handleAuthenticationError();
            }

            return [];
        }

        let item = this.service.getApplicationId();

        if (!item) {
            item = items[0]?.id;
            this.service.saveApplication(item);
        }

        items.forEach((item, i) => {
            if (item.id === this.service.getApplicationId()) {
                items.splice(i, 1);
                items.unshift(item);
            }
        });

        this.setState({
            items,
            filteredItems: items,
            value: item,
            numberOfItems: items.length,
        })
    }

    filterApplications(name: string) {
        const { items, filteredItems } = this.state;
        if (!items || !filteredItems) { return; }
        const apps: any[] = filteredItems.filter(function (feature: any) {
            return feature.name.toLowerCase().indexOf(name.toLowerCase()) >= 0 || feature.id.toLowerCase().indexOf(name.toLowerCase()) >= 0;
        });
        this.setState({ items: apps, inputValue: name, numberOfItems: apps.length });
    }

    resetTextInput() {
        this.filterApplications('');
    }

    resetPositioning() {
        const { filteredItems, value } = this.state;
        let item = this.service.getApplicationId();

        if (!item) {
            item = filteredItems[0] ? filteredItems[0]['id'] : undefined;
            this.service.saveApplication(item);
        }

        let newFilteredItems = filteredItems;

        newFilteredItems.forEach((item, i) => {
            if (item['id'] === this.service.getApplicationId()) {
                filteredItems.splice(i, 1);
                filteredItems.unshift(item);
            }
        });

        this.setState({
            items: newFilteredItems,
            value: item,
        })
    }

    handleChange(value) {
        try {
            if (value !== this.state.value) {
                this.setState({ value });
                this.service.saveApplication(value);
            }
            this.setOpen(false);
            this.resetTextInput();
            this.resetPositioning();
        } catch (e) {
            console.error(e);
        }
    }

    async copyIdToClipboard(value) {
        try {
            await copy(value);
            toast.success("Application ID copied to clipboard.", this.toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", this.toastConfig);
        }
    }

    prepareItems(items: any[]) {
        return items.map(({ id, name }) => ({
            value: id,
            key: id,
            text: name,
        }));
    }

    renderMobileSwitcher(items: any[], isMobile: boolean) {
        const { Gatekeeper } = this.props;
        const appCards = items.map(({ id, name }) => {
            return (
                <Card key={id} fluid>
                    <Card.Content>
                        <Card.Header>
                            {name}
                            {id === this.state.value &&
                                <Popup
                                    size="tiny"
                                    trigger={<Button compact icon style={{ float: 'right' }}><Icon name='check' color='green' /></Button>}
                                    content='Current application'
                                    position='top center'
                                />
                            }
                            {id !== this.state.value &&
                                <Popup
                                    size="tiny"
                                    trigger={<Button compact icon style={{ float: 'right' }} onClick={() => this.handleChange(id)}><Icon name='sign in' color='black' inverted /></Button>}
                                    content='Switch to this application'
                                    position='top center'
                                />
                            }
                            <Gatekeeper name="account-management">
                                <Popup
                                    size="tiny"
                                    trigger={<Button compact icon style={{ float: 'right' }} onClick={async () => this.copyIdToClipboard(id)}><Icon name='copy' /></Button>}
                                    content='Copy application ID to the clipboard'
                                    position='top center'
                                />
                            </Gatekeeper>
                        </Card.Header>
                    </Card.Content>
                </Card>
            )
        })
        return (
            <Modal
                size={isMobile ? "fullscreen" : "tiny"}
                onClose={() => { this.setOpen(false); this.resetTextInput() }}
                onOpen={() => this.setOpen(true)}
                open={this.state.modalOpen}
                trigger={isMobile ? <Menu.Item icon='grid layout' name='Switch Application' /> : <Menu.Item icon='grid layout' />}
            >
                <Modal.Header>Switch Application</Modal.Header>
                <Modal.Content style={{ paddingBottom: 0 }}>
                    <Input fluid value={this.state.inputValue} onChange={(e) => { this.filterApplications(e.target.value) }} icon={(e) => {
                        if (this.state.inputValue.length > 0) {
                            return <Icon name='delete' link onClick={() => { this.resetTextInput() }} />
                        }
                        return <Icon name='search' />
                    }} />
                    <small className="text-muted">Now showing {this.state.numberOfItems} {commonUtils.plur('result', 'results', this.state.numberOfItems)}{this.state.inputValue && ` for ${this.state.inputValue}`}.</small>
                </Modal.Content>
                <Divider />
                <Modal.Content scrolling>
                    <Modal.Description style={{ height: 'calc(5 * 41px)' }}>
                        <Card.Group>
                            {appCards}
                        </Card.Group>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' onClick={() => this.setOpen(false)}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const { items, value } = this.state;

        // if (!items.length || 1 === items.length) {
        //     return (
        //         <></>
        //     )
        // }

        // if (this.props.isMobile) {
        //     return (
        //         this.renderMobileSwitcher(items)
        //     )
        // }

        return this.renderMobileSwitcher(items, this.props.isMobile || false)

        // REVISIT
        // return (
        //     <Dropdown
        //         className="application-switcher"
        //         item
        //         onChange={(e, { value }) => this.handleChange(value)}
        //         options={this.prepareItems(items)}
        //         value={value}
        //     />
        // )
    }
}