import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import UserForm from '../../../forms/users/UserForm';
import "./styles.css";

export interface ISectionDesktopPageProps {
    firstName: string;
    lastName: string;
    email: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ISectionDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        firstName: "",
        lastName: "",
        email: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="user-management">
                <div className="user-editor-container">
                    {this.props.email && (
                        <Button.Group floated="right">
                            <Button
                                type="button"
                                icon="trash"
                                color="red"
                                onClick={() => this.props?.onDelete && this.props.onDelete()}
                            />
                        </Button.Group>
                    )}
                    <UserForm
                        firstName={this.props.firstName}
                        lastName={this.props.lastName}
                        email={this.props.email}
                        onSubmit={(data) => this.props.onSave(data)}
                    />
                </div>
            </Gatekeeper>
        );
    }
}