import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface ILayoutThemeConfigurationSettingsPageProps extends IPageProps {
    onChange: (path: string) => any,
    localizationService?: any,
}

export default class LayoutThemeConfigurationSettingsPage extends PureComponent<ILayoutThemeConfigurationSettingsPageProps> {
    render() {
        const { createRoutes, header, localizationService, onChange } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        header={header}
                        onChange={onChange}
                        localizationService={localizationService}
                    />
                </Component>
            </Page>
        );
    }
}