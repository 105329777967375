import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from "../LocalizedPage";
import { IPageProps } from "../Page/Page";

export interface IPasswordResetPageProps extends IPageProps {
    onSubmit: (values) => any;
    token: string;
}

export default class PasswordResetPage extends PureComponent<IPasswordResetPageProps> {
    render() {
        let { children, createRoutes, token, onSubmit } = this.props;

        const Page = LocalizedPage();

        return (
            <Page createRoutes={createRoutes} headerHidden>
                <DesktopPage token={token} onSubmit={onSubmit}>{children}</DesktopPage>
            </Page>
        );
    }
}
