import React, { PureComponent } from 'react';
import { UnauthorizedMessage } from '../../../layouts/messages';
import StoreSettingsTemplate from '../../storeconfiguration/StoreSettingsTemplate';

export interface IDesktopPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    panes: any;
    onPathChange: (path: string) => any;
}
export interface IDesktopPageState {

}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    state = {
    };

    private renderPanes() {
        const { panes } = this.props;
        if (!panes) { return; }
        let widgets = panes.map((pane, index) => {
            return (
                <div style={{ marginBottom: '15px' }}>
                    {pane.render()}
                </div>
            )
        })
        return (
            widgets
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <StoreSettingsTemplate localizationService={this.props.localizationService} onChange={this.props.onPathChange} activeItem='orders'>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />}>
                    {this.renderPanes()}
                </Gatekeeper>
            </StoreSettingsTemplate>
        );
    }
}
