import React from 'react';
import SectionEditPage from '../../components/pages/SectionEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import queryString from 'query-string';

export default class SectionCreateController extends LocalizedController {
    private repository: Repository;
    private pageRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private websitesRepository: Repository;
    private storefrontRepository: Repository;

    state = {
        item: undefined,
        additionalData: {},
    };

    constructor({ args: [router, routeMenuItemFactory, localizationService, repository, pageRepository, storefrontRepository, websitesRepository, layoutsRepository, sectionLayoutRepository, gatekeeperFactory], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.repository = repository;
        this.pageRepository = pageRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.websitesRepository = websitesRepository;
        this.storefrontRepository = storefrontRepository;
    }

    async componentDidMount() {
        let storefronts = [];

        try {
            storefronts = await this.storefrontRepository.getByPath(`storefronts`)
        } catch (e) {
            console.warn(e)
        }

        this.setState({
            additionalData: {
                storefronts,
            },
        })
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;
        let items;
        let pageSectionData;
        const parsed = queryString.parse(this.router.getQueryParameters());
        if (parsed.location && 'main' === parsed.location) {
            parsed.location = '';
        }

        try {
            item = await this.repository.create(data);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            if (this.params?.id) {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                items = await this.pageRepository.getByPath(`pages/${this.params?.id}/sections?sort=position:asc`);
                let additional = data.additional || {};

                if (additional.query) {
                    delete additional.query;
                }

                pageSectionData = {
                    "position": items && items.length > 0 ? items[items.length - 1].position + 1 : 0,
                    "style": {
                        "cssClassNames": []
                    },
                    "location": parsed.location || '', // Temporary until we can link `main` to the `main` layout.
                    "options": {
                        layout: {
                            type: 'card',
                            options: additional,
                        }
                    }
                }

                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                await this.pageRepository.createByPath(pageSectionData, `pages/${this.params?.id}/sections?sectionId=${item.id}`);
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                this.router.redirect(`/website-management/pages/${this.params?.id}`);
            } else {
                this.router.redirect(`/website-management/sections/${item.id}/edit`)
            }
        } catch (e) {
            throw e;
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 1, text: this.localizationService.translate('Sections'), value: '/website-management/sections' },
            { key: 2, text: this.localizationService.translate('Create section'), value: undefined },
        ]
    }

    render() {
        const { item, additionalData } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <SectionEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                additionalData={additionalData}
            />
        )
    }
}