import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import SubscriptionDesignPage from '../../components/pages/subscriptions/SubscriptionDesignPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class SubscriptionDesignController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private termControllerAdapter;
    private licenseControllerAdapter;
    private archetypeControllerAdapter;
    private localizationService;

    state = {
        item: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory, termControllerAdapter, licenseControllerAdapter, archetypeControllerAdapter] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.termControllerAdapter = termControllerAdapter;
        this.licenseControllerAdapter = licenseControllerAdapter;
        this.archetypeControllerAdapter = archetypeControllerAdapter;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    async componentDidMount() {
        let item = await this.get();

        this.setState({
            item,
        })
    }

    preparePanes(Gatekeeper) {
        const TermController = this.termControllerAdapter.getController();
        const LicenseController = this.licenseControllerAdapter.getController();
        const ArchetypeController = this.archetypeControllerAdapter.getController();

        return [
            {
                menuItem: "Terms",
                render: () => (
                    <>
                        <Gatekeeper
                            name="subscription-term-management"
                            or={["subscription-term-view"]}
                            // unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <TermController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Licenses",
                render: () => (
                    <>
                        <Gatekeeper
                            name="subscription-license-management"
                            or={["subscription-license-view"]}
                            // unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <LicenseController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Archetypes",
                render: () => (
                    <>
                        <Gatekeeper
                            name="subscription-archetype-management"
                            or={["subscription-archetype-view"]}
                            // unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ArchetypeController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
        ]
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Subscriptions'), value: '/subscriptions' },
            { key: 2, text: this.state.item ? this.state.item!['name'] : '', value: undefined },
        ]
    }

    render() {
        const { item } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        const panes = this.preparePanes(Gatekeeper);

        return this.prepare(
            <SubscriptionDesignPage
                item={item}
                breadcrumbLevels={this.generateBreadcrumbs()}
                Gatekeeper={Gatekeeper}
                panes={panes}
            />
        )
    }
}