import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header, Card } from "semantic-ui-react";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";
import CommonUtils from '../../../../../../utilities/Common/CommonUtils';

const commonUtils = new CommonUtils();

export interface IProductsAttributesPaneProps {
    items: any;
    Gatekeeper: any;
}

export interface IProductsAttributesPaneState {
}

export default class ProductsAttributesPane extends PureComponent<IProductsAttributesPaneProps, IProductsAttributesPaneState> {
    state: IProductsAttributesPaneState = {
    };

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.title}</Table.Cell>
                <Table.Cell>{commonUtils.renderCurrency(item.price)}</Table.Cell>
                <Table.Cell>{item.sku}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="products-managment">
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/products/${item.id}/edit`}
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={4} textAlign={'center'}>
                    <Header>No associated products!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return (<Table.Body>
            {!items.length ? this.renderNoContentMessage() : items}
        </Table.Body>)
    }

    private renderTableHeaders() {
        return <Table.Header>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>SKU</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
        </Table.Header>;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className='three'>
                {this.renderTableHeaders()}
                {this.renderTableBody()}
                <Gatekeeper name="products-management">
                    <Table.Footer>
                        <Table.HeaderCell />
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}