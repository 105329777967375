import React, { PureComponent } from "react";
import Component from "../../layouts/component";
import LocalizedPage from "../LocalizedPage";
import { IPageProps } from "../Page/Page";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";

export interface ILicense {
    name: string;
    type: string;
    level: string;
}

export interface IFeatures {
    name: string;
    key: string;
}
export interface ILicenseCreatePageProps extends IPageProps {
    item?: ILicense;
    features?: IFeatures;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
}

export default class LicenseCreatePage extends PureComponent<ILicenseCreatePageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        name={item && item.name}
                        type={item && item.type}
                        level={item && item.level}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                    <MobilePage
                        name={item && item.name}
                        type={item && item.type}
                        level={item && item.level}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}
