import React, { PureComponent } from "react";
import {
    Grid,
    Menu,
    Segment,
} from "semantic-ui-react";
import "./styles.css";

export interface ISettingsTemplate {
    onChange: (path: string) => any;
    activeItem: string;
    localizationService?: any;
}

export default class SettingsTemplate extends PureComponent<ISettingsTemplate> {
    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    protected handleItemClick(name) {
        this.props.onChange(name)
    };

    render() {
        return (
            <div className="editor-container">
                <Grid centered>
                    <Grid.Column width={3}>
                        <Menu fluid pointing vertical secondary>
                            <Menu.Item
                                name={this.translate('Account')}
                                content={this.translate('Account')}
                                active={'account' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("account")}
                                icon="clipboard list"
                            />
                            <Menu.Item
                                name={this.translate('Site Configuration')}
                                content={this.translate('Site Configuration')}
                                active={'site' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("site")}
                                icon="sitemap"
                            />
                            <Menu.Item
                                name={this.translate('Layout and theme')}
                                content={this.translate('Layout and theme')}
                                active={'theme' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("theme")}
                                icon="paint brush"
                            />
                            {/* <Menu.Item
                                name="security"
                                active={'security' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("security")}
                                icon="lock"
                            />
                            <Menu.Item
                                name="users"
                                active={'users' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("users")}
                                icon="user"
                            />
                            <Menu.Item
                                name="groups"
                                active={'groups' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("groups")}
                                icon="group"
                            />
                            <Menu.Item
                                name="resources"
                                active={'resources' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("resources")}
                                icon="key"
                            /> */}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column stretched width={13}>
                        <Segment>{this.props.children}</Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}
