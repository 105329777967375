import React from "react"
import { Menu, Dropdown } from "semantic-ui-react"
import { LoadingNavPlaceholder } from "../components/controls/"
import { LocalizationService } from "../services"
import ApplicationSwitcherFactory from "./ApplicationSwitcherFactory"
import { ControlsResolver } from "./ControlsEnabledRouteMenuItemFactory"
import DeployButtonFactory from "./DeployButtonFactory"
import GatekeeperFactory from "./GatekeeperFactory"
import LogoutButtonFactory from "./LogoutButtonFactory"
import RightNavbarLanguageControlsFactory from "./RightNavbarLanguageControlsFactory";

export default class RightNavbarControlsFactory {
    constructor(
        protected resolver: ControlsResolver,
        protected appSwitcherFactory: ApplicationSwitcherFactory,
        protected logoutButtonFactory: LogoutButtonFactory,
        protected gatekeeperFactory: GatekeeperFactory,
        protected deployButtonFactory: DeployButtonFactory,
        protected languageControlFactory: RightNavbarLanguageControlsFactory,
        protected localizationService: LocalizationService,
    ) { }

    renderDropdown(Gatekeeper) {
        return (
            <Dropdown icon="lightning" button item>
                <Dropdown.Menu>
                    <Gatekeeper name="blog-management">
                    <Dropdown.Header content={this.translate("Blog")} />
                        </Gatekeeper>
                    <Gatekeeper name="blog-management">
                        <Dropdown.Item text={this.translate("Blog Post")} href={"/posts/create"} />
                    </Gatekeeper>
                    <Gatekeeper
                        name="productdefinition-management"
                        or={["tax-management"]}
                    >
                        <Dropdown.Header content={this.translate("Store")} />
                    </Gatekeeper>
                    <Gatekeeper name="productdefinition-management">
                        <Dropdown.Item text={this.translate("Product")} href={"/store-management/products?action=create"} />
                    </Gatekeeper>
                    <Gatekeeper name="tax-management">
                        <Dropdown.Item text={this.translate("Taxes")} href={"/store-management/taxes/create"} />
                    </Gatekeeper>
                    <Gatekeeper
                        name="website-management"
                        or={["page-management"]}
                    >
                        <Dropdown.Header content={this.translate("Website")} />
                    </Gatekeeper>
                    {/* <Gatekeeper name="website-management">
                        <Dropdown.Item text="Section" href={"/sections/create"} />
                    </Gatekeeper> */}
                    <Gatekeeper name="page-management">
                        <Dropdown.Item text={this.translate("Page")} href={"/website-management/pages/create"} />
                    </Gatekeeper>
                    <Gatekeeper
                        name="user-management"
                        or={["license-management", "account-management", "subscription-management", "feature-management", "resource-management", "group-management"]}
                    >
                        <Dropdown.Header content="Administration" />
                    </Gatekeeper>
                    <Gatekeeper name="account-management">
                        <Dropdown.Item text="Account" href={"/accounts/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="subscription-management">
                        <Dropdown.Item text="Subscription" href={"/subscriptions/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="user-management">
                        <Dropdown.Item text="User" href={"/users/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="feature-management">
                        <Dropdown.Item text="Feature" href={"/features/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="license-management">
                        <Dropdown.Item text="License" href={"/licenses/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="resource-management">
                        <Dropdown.Item text="Resource" href={"/settings/resources/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="group-management">
                        <Dropdown.Item text="Group" href={"/settings/groups/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="archetype-management">
                        <Dropdown.Item text="Archetype" href={"/archetypes/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="component-management">
                        <Dropdown.Item text="Component" href={"/components/create"} />
                    </Gatekeeper>
                    <Gatekeeper name="component-management">
                        <Dropdown.Item text="Application Templates" href={"/applicationtemplates/create"} />
                    </Gatekeeper>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    translate(key: string): string {
        return this.localizationService.translate(key);
    }

    create() {
        const Gatekeeper = this.gatekeeperFactory.create();

        this.resolver
            .addControl({
                placementId: 'inbox',
                control: ({ active, path, position, header, content, disabled, featureKey }) => {
                    return [
                        <Gatekeeper name="blog-management" or={["website-management"]} unauthorizedComponent={<Menu.Menu position={position} />} loadingComponent={<LoadingNavPlaceholder />}>
                            <Menu.Menu position={position} className="create-button">
                                {this.renderDropdown(Gatekeeper)}
                            </Menu.Menu>
                        </Gatekeeper>,
                        <Menu.Menu>
                            {this.appSwitcherFactory.create()}
                        </Menu.Menu>,
                        <Menu.Menu>
                            {this.languageControlFactory.create()}
                        </Menu.Menu>,
                        featureKey ? (
                            <Gatekeeper name={featureKey}>
                                <Menu.Item as={disabled ? 'div' : 'a'} active={active} href={path} header={header} disabled={disabled}>{content}</Menu.Item>
                            </Gatekeeper>
                        ) : (
                            <Menu.Item as={disabled ? 'div' : 'a'} active={active} href={path} header={header} disabled={disabled}>{content}</Menu.Item>
                        ),
                        this.logoutButtonFactory.create(), // temporarily moved from settings placement id
                    ]
                },
            })
        // .addControl({
        //     placementId: 'settings',
        //     control: ({ active, path, position, header, content, disabled, featureKey }) => {
        //         return [
        //             featureKey ? (
        //                 <Gatekeeper name={featureKey} loadingComponent={<LoadingNavPlaceholder />}>
        //                     <Menu.Item as={disabled ? 'div' : 'a'} active={active} href={path} position={position} header={header} disabled={disabled}>{content}</Menu.Item>
        //                 </Gatekeeper>
        //             ) : (
        //                 <Menu.Item as={disabled ? 'div' : 'a'} active={active} href={path} position={position} header={header} disabled={disabled}>{content}</Menu.Item>
        //             ),
        //             this.logoutButtonFactory.create(),
        //         ]
        //     },
        // })
    }
}