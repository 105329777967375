import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IStoreConfigurationPageProps extends IPageProps {
    onChange?: (path: string) => any,
    onPathChange: (path: string) => any,
    onProvision?: () => any,
    onSave?: (data: any) => any,
    saveException?: (data: any) => any,
    updateException?: (data: any) => any,
    deleteException?: (data: any) => any,
    unauthorize?: () => any,
    localizationService?: any,
    options: any,
    settings: any,
    domain: any,
    breadcrumbLevels?: any,
    storefront?: any,
    exceptions?: any,
    availabilities?: any,
    storefrontIntegrations?: any,
}

export default class StoreConfigurationPage extends PureComponent<IStoreConfigurationPageProps> {
    render() {
        const { createRoutes, header, localizationService, onChange = () => { }, onPathChange, deleteException, updateException, options, settings, onProvision, onSave, domain, breadcrumbLevels, storefront, exceptions, availabilities, saveException, storefrontIntegrations, unauthorize } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        header={header}
                        onChange={onChange}
                        localizationService={localizationService}
                        options={options}
                        settings={settings}
                        onProvision={onProvision}
                        onSave={onSave}
                        domain={domain}
                        onPathChange={onPathChange}
                        storefront={storefront}
                        exceptions={exceptions}
                        availabilities={availabilities}
                        saveException={saveException}
                        updateException={updateException}
                        deleteException={deleteException}
                        storefrontIntegrations={storefrontIntegrations}
                        unauthorize={unauthorize}
                    />
                </Component>
            </Page>
        );
    }
}