import React, { PureComponent } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import CommonUtils from '../../../utilities/Common/CommonUtils';

const commonUtils = new CommonUtils();

export interface ILanguagePickerProps {
    trigger?: React.ReactNode;
}

// export default class LanguagePicker extends PureComponent {
//     render() {
//         const Picker = LocalizedComponent()(LanguagePickerLogic);
//         return <Picker {...this.props}/>;
//     }
// }

export default class LanguagePicker extends PureComponent<ILanguagePickerProps> {

    state = {
        lang: undefined,
    }

    componentDidMount() {
        let lang = JSON.parse(localStorage.getItem('lang')!);

        if (!lang) {
            localStorage.setItem('lang', JSON.stringify('en'));
            lang = JSON.parse(localStorage.getItem('lang')!);
        }
        this.setState({ lang })
    }

    componentDidUpdate(prevProps: Readonly<ILanguagePickerProps>, prevState: Readonly<{}>, snapshot?: any): void {
        let lang = JSON.parse(localStorage.getItem('lang')!);


        if (!lang) {
            localStorage.setItem('lang', 'en');
            lang = JSON.parse(localStorage.getItem('lang')!);
        }
        this.setState({ lang })
    }

    private updateLanguage(newLang: string) {
        localStorage.setItem('lang', JSON.stringify(newLang));
        window.location.reload();
    }

    render() {
        let lang = this.state.lang;
        const { trigger } = this.props;
        const options = [];

        return (
            <Dropdown item trigger={<Icon name='globe' />} icon={null} style={{ margin: 'auto 0' }} pointing='top right'>
                {lang && <Dropdown.Menu>
                    <Dropdown.Item active={lang === 'en'} onClick={() => this.updateLanguage('en')}>English</Dropdown.Item>
                    <Dropdown.Item active={lang === 'fr'} onClick={() => this.updateLanguage('fr')}>French</Dropdown.Item>
                </Dropdown.Menu>}
            </Dropdown>
        )
    }
}