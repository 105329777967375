import React, { PureComponent } from "react";
import {
    Segment,
    Button,
    List,
    Divider,
    Label,
    Container,
    Header,
    LabelGroup,
    Table,
    Modal,
    Form,
    Dropdown,
    Input,
    ButtonGroup,
    Grid,
    Icon,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { IStoreConfigurationPageProps } from "./StoreConfigurationPage";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import OrderSettings from "./Forms/OrderSettings";
import DeliverySettings from "./Forms/DeliverySettings";
import ProductSettings from "./Forms/ProductSettings";
import InvoiceSettings from "./Forms/InvoiceSettings";
import "./styles.css";
import TimezoneSettings from "./Forms/TimezoneSettings";
import AvailabilitiesSettings from "./Forms/AvailabilitiesSettings";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import SchedulerCalendar from 'scheduler-calendar'
import FullCalendar from '@fullcalendar/react'
import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { addHours, getHours, getMinutes, getTime, setHours, setMinutes } from "date-fns";
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr-ca';
// import 'scheduler-calendar/dist/index.css'

const commonUtils = new CommonUtils();

export interface IAccountSettingsDesktopPageProps extends IStoreConfigurationPageProps {
    onChange: (path: string) => any,
    onPathChange: (path: string) => any,
    onProvision?: () => any,
    unauthorize?: () => any,
    onSave?: (data: any) => any,
    saveException?: (data: any) => any,
    deleteException?: (data: any) => any,
    localizationService: any,
    options: any,
    settings: any,
    domain: any,
    storefront: any,
    exceptions?: any[],
    availabilities?: any[],
    storefrontIntegrations?: any[],
}

export default class DesktopPage extends PureComponent<
    IAccountSettingsDesktopPageProps
> {
    state = {
        openDeliveryOptionsSettings: false,
        openOrderOptionsSettings: false,
        openProductOptionsSettings: false,
        openInvoiceOptionsSettings: false,
        openTimezoneOptionsSettings: false,
        openAvailabilitiesOptionsSettings: false,
        openHolidaysOptionsSettings: false,
        allDay: false,
        end: new Date(),
        start: new Date(),
        endStr: new Date().toDateString(),
        startStr: new Date().toDateString(),
        startTimeForPause: '0:00',
        endTimeForPause: '0:00',
        name: '',
        id: undefined,
        settingUpStripe: false,
    };

    protected setOpenOptionsSettings(name: string, open: boolean = true) {
        if (!name) { return; }
        this.setState({
            [name]: open,
            allDay: false,
            end: new Date(),
            start: new Date(),
            endStr: new Date().toDateString(),
            startStr: new Date().toDateString(),
            startTimeForPause: '0:00',
            endTimeForPause: '0:00',
            name: '',
            id: undefined,
        });
        // Temporary fix
        if (name === "openAvailabilitiesOptionsSettings" && !open) {
            window.location.reload()
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private async saveHoliday() {
        const { start, end, name, startTimeForPause, endTimeForPause } = this.state;
        if (!start || !end) { return; }
        if (this.props.saveException) {
            let data = {
                startDate: start,
                endDate: end,
                name: name || this.translate('New event'),
                type: 'holiday'
            }
            let splitStartTime = startTimeForPause.split(':');
            data.startDate.setHours(Number(splitStartTime[0]), Number(splitStartTime[1]))
            let splitEndTime = endTimeForPause.split(':');
            data.endDate.setHours(Number(splitEndTime[0]), Number(splitEndTime[1]))
            try {
                await this.props.saveException(data);
                this.setOpenOptionsSettings('openHolidaysOptionsSettings', false);
                toast.success('Storefront settings successfully updated', {
                    autoClose: 1500,
                })
            } catch (e) {
                toast.error(e.message, {
                    autoClose: 1500,
                })
            }
        }
    }

    private async updateHoliday() {
        const { start, end, name, startTimeForPause, endTimeForPause, id } = this.state;
        if (!start || !end) { return; }
        if (this.props.updateException) {
            let data = {
                id,
                startDate: start,
                endDate: end,
                name: name || this.translate('New event'),
                type: 'holiday'
            }
            let splitStartTime = startTimeForPause.split(':');
            data.startDate.setHours(Number(splitStartTime[0]), Number(splitStartTime[1]))
            let splitEndTime = endTimeForPause.split(':');
            data.endDate.setHours(Number(splitEndTime[0]), Number(splitEndTime[1]))
            try {
                await this.props.updateException(data);
                this.setOpenOptionsSettings('openHolidaysOptionsSettings', false);
                toast.success('Storefront settings successfully updated', {
                    autoClose: 1500,
                })
            } catch (e) {
                toast.error(e.message, {
                    autoClose: 1500,
                })
            }
        }
    }

    private async deleteHoliday() {
        const { id } = this.state;
        if (!id) { return; }
        if (this.props.deleteException) {
            try {
                await this.props.deleteException(id);
                this.setOpenOptionsSettings('openHolidaysOptionsSettings', false);
                toast.success('Storefront settings successfully updated', {
                    autoClose: 1500,
                })
            } catch (e) {
                toast.error(e.message, {
                    autoClose: 1500,
                })
            }
        }
    }

    private generateAvailabilitiesOptions(values) {
        let closed: boolean = false;
        let options = values.map((value, index, array) => {
            if (value.isClosed) {
                closed = true;
                return <Label color='red' content={this.translate('Closed')} />
            } else {
                return (
                    <Label color='green' content={`${value.open} - ${value.close}`} />
                )
            }
        })
        // add break line between each label
        options = options.reduce((list, elem, i) => {
            list.push(elem);
            if ((i + 1) % 2 === 0) list.push(<br />);
            return list;
        }, []);

        return options;
    }

    generateAvailabilities() {
        if (!this.props.storefront) { return; }
        const { availabilities } = this.props.storefront;
        if (!availabilities) { return; }
        let renderedHeader: any[] = [];
        let renderedBody: any[] = [];
        for (const [key, value] of Object.entries(availabilities)) {
            renderedHeader.push(
                <Table.HeaderCell>{this.translate(commonUtils.capitalizeFirstLetter(key))}</Table.HeaderCell>
            )
            renderedBody.push(
                <Table.Cell content={<LabelGroup size="large" content={this.generateAvailabilitiesOptions(value)} />} />
            );
        }

        return <Table textAlign="center" celled columns={'7'}>
            <Table.Header>
                {renderedHeader}</Table.Header>
            <Table.Body><Table.Row>{renderedBody}</Table.Row></Table.Body>
        </Table>;
    }

    private displayHolidayModal(info) {
        const data = {
            start: info.start,
            end: info.end,
            endStr: info.endStr,
            startStr: info.startStr,
            name: info.title || this.translate('New event'),
            id: info.id
        }

        if (info.allDay || !Object.entries(info).length) {
            this.setState({
                ...info,
                openHolidaysOptionsSettings: true,
                name: data.name,
            });
        } else {
            const { end, start } = info;
            this.setState({
                ...data,
                openHolidaysOptionsSettings: true,
                startTimeForPause: `${getHours(start)}:${String(getMinutes(start)).padStart(2, "0")}`,
                endTimeForPause: `${getHours(end)}:${String(getMinutes(end)).padStart(2, "0")}`,
                name: data.name,
            });
        }
    }

    generateHolidays() {
        if (!this.props.storefront) { return; }
        const { availabilities } = this.props.storefront;
        if (!availabilities || !this.props.availabilities) { return; }

        return <FullCalendar
            height={'60vh'}
            plugins={[
                interactionPlugin,
                dayGridPlugin,
                timeGridPlugin,
            ]}
            customButtons={{
                myCustomButton: {
                    text: this.translate('Add holiday'),
                    click: () => {
                        this.displayHolidayModal({})
                    }
                }
            }}
            events={this.props.exceptions ? this.props.exceptions[0] : []}
            selectable
            headerToolbar={{ left: 'title', right: 'myCustomButton today prev,next' }}
            eventClick={(info) => this.displayHolidayModal(info.event)}
            fixedWeekCount={false}
            displayEventTime
            displayEventEnd
            eventColor={'rebeccapurple'}
            select={(arg: any) => {
                delete arg.jsEvent;
                delete arg.view;
                this.displayHolidayModal(arg);
            }}
            locales={[enLocale, frLocale]}
            locale={JSON.parse(localStorage.getItem('lang')!)}
            businessHours={this.props.availabilities}
        />
    }

    generateSettingsValueArray(obj) {
        let rendered: any[] = [];
        obj.forEach(element => {
            rendered.push(<Label basic size="large">{element}</Label>)
        });

        return <LabelGroup>{rendered}</LabelGroup>
    }

    generateSettingsValues(obj) {
        let rendered: any[] = [];
        for (const [key, value] of Object.entries(obj)) {
            let renderedValue;
            switch (typeof (value)) {
                case "boolean":
                    renderedValue = this.translate(value ? "Yes" : "No");
                    break;
                case "string":
                case "number":
                    renderedValue = this.translate(value.toString());
                    break;
                case "object":
                    if (Object.prototype.toString.call(value) == '[object Array]') {
                        renderedValue = <div style={{ margin: '15px 0px', paddingLeft: '15px' }}>{this.generateSettingsValueArray(value)}</div>
                    } else {
                        renderedValue = <div style={{ margin: '15px 0px', paddingLeft: '15px' }}>{this.generateSettingsValues(value)}</div>
                    }
            }
            rendered.push(
                <List.Item>
                    <List.Content>
                        <List.Description>
                            <b>{this.translate(commonUtils.capitalizeFirstLetter(commonUtils.splitCamelCaseWithAbbreviations(key)))}: </b>{' '}
                            {renderedValue}
                        </List.Description>
                    </List.Content>
                </List.Item>
            );
        }
        return rendered;
    }

    generateSettings(key) {
        if (!this.props.storefront) { return; }
        const { settings } = this.props.storefront;
        if (!settings) { return; }
        const wanted = settings[key];
        if (!wanted) { return; }
        let rendered: any[] = [];

        rendered = this.generateSettingsValues(wanted);

        return <List>{rendered}</List>;
    }

    async saveSettings(name: string, data: any, isSettings: boolean = true) {
        let finalData;
        // prepare settings object
        if (!this.props.onSave) { return; }
        if (isSettings) {
            const settings = { ...this.props.storefront.settings, ...data.settings }
            finalData = { ...this.props.storefront, settings };
        } else {
            finalData = { ...this.props.storefront, ...data };
        }
        try {
            await this.props.onSave(finalData);
            this.setOpenOptionsSettings(name, false);
            toast.success('Storefront settings successfully updated', {
                autoClose: 1500,
            })
        } catch (e) {
            toast.error(e.message, {
                autoClose: 1500,
            })
        }
    }

    private renderAvailabilitiesSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openAvailabilitiesOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openAvailabilitiesOptionsSettings", false) }}
            >
                <AvailabilitiesSettings localizationService={this.props.localizationService} settings={storefront.availabilities} onSave={(data) => this.saveSettings("openAvailabilitiesOptionsSettings", data, false)} />
            </Modal>
        )
    }

    private renderHolidaysSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openHolidaysOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openHolidaysOptionsSettings", false) }}
                size="small"
            >
                <Modal.Header>{this.translate('New Holiday')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <label>{this.translate('Event name')}</label>
                        <Form.Group inline>
                            <Form.Field>
                                <Input type="text" fluid value={this.state.name} onChange={(event, data) => { this.setState({ name: data.value }) }} />
                            </Form.Field>
                        </Form.Group>
                        <label>{this.translate('Select date and time of event')}</label>
                        <Form.Group inline>
                            <Form.Field>
                                <SemanticDatepicker datePickerOnly minDate={new Date()} value={this.state.startStr ? new Date(this.state.start) : undefined} clearable={false} onChange={(event, data) => { data.value && this.setState({ startStr: data.value!.toString(), start: data.value }) }} />
                                <Dropdown style={{ marginLeft: '1rem' }} placeholder='00:00' className='datepicker-time' options={commonUtils.generateTimes()} value={this.state.startTimeForPause} onChange={(event, data) => { data.value && this.setState({ startTimeForPause: data.value!.toString() }) }} scrolling search={false} trigger={<Input type='text' icon={'clock'} value={this.state.startTimeForPause} onChange={(event) => event.preventDefault()} />} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group inline>
                            <Form.Field>
                                <SemanticDatepicker pointing='top right' datePickerOnly minDate={new Date()} value={this.state.endStr ? new Date(this.state.end) : undefined} clearable={false} onChange={(event, data) => { data.value && this.setState({ endStr: data.value!.toString(), end: data.value }) }} />
                                <Dropdown style={{ marginLeft: '1rem' }} placeholder='00:00' className='datepicker-time' options={commonUtils.generateTimes()} value={this.state.endTimeForPause} onChange={(event, data) => { data.value && this.setState({ endTimeForPause: data.value!.toString() }) }} scrolling search={false} trigger={<Input type='text' value={this.state.endTimeForPause} icon={'clock'} onChange={(event) => event.preventDefault()} />} />
                            </Form.Field>
                        </Form.Group>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {!this.state.id ?
                        <Button color='purple' content={this.translate('Add')} onClick={() => { this.saveHoliday() }} /> :
                        <ButtonGroup>
                            <Button color='red' content={this.translate('Delete')} onClick={() => { this.deleteHoliday() }} />
                            <Button color='linkedin' content={this.translate('Update')} onClick={() => { this.updateHoliday() }} />
                        </ButtonGroup>
                    }
                </Modal.Actions>
            </Modal>
        )
    }

    private renderOrderSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openOrderOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openOrderOptionsSettings", false) }}
                size='tiny'>
                <OrderSettings localizationService={this.props.localizationService} settings={storefront.settings?.orders} onSave={(data) => this.saveSettings("openOrderOptionsSettings", data)} />
            </Modal>
        )
    }

    private renderDeliverySettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openDeliveryOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openDeliveryOptionsSettings", false) }}
                size='tiny'>
                <DeliverySettings localizationService={this.props.localizationService} settings={storefront.settings?.deliveries} onSave={(data) => this.saveSettings("openDeliveryOptionsSettings", data)} />
            </Modal>
        )
    }

    private renderProductSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openProductOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openProductOptionsSettings", false) }}
                size='tiny'>
                <ProductSettings localizationService={this.props.localizationService} settings={storefront.settings?.products} onSave={(data) => this.saveSettings("openProductOptionsSettings", data)} />
            </Modal>
        )
    }

    private renderInvoiceSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openInvoiceOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openInvoiceOptionsSettings", false) }}
                size='tiny'>
                <InvoiceSettings localizationService={this.props.localizationService} settings={storefront.settings?.invoices} onSave={(data) => this.saveSettings("openInvoiceOptionsSettings", data)} />
            </Modal>
        )
    }

    private renderTimezoneSettingsModal() {
        const { storefront } = this.props;
        if (!storefront) { return; }
        return (
            <Modal
                open={this.state.openTimezoneOptionsSettings}
                onClose={() => { this.setOpenOptionsSettings("openTimezoneOptionsSettings", false) }}
                size='tiny'>
                <TimezoneSettings localizationService={this.props.localizationService} settings={storefront.settings?.availabilities} onSave={(data) => this.saveSettings("openTimezoneOptionsSettings", data)} />
            </Modal>
        )
    }

    private stripeSetup() {
        if (this.props.onProvision) {
            this.setState({ settingUpStripe: true });
            this.props.onProvision();
        }
    }

    private async unauthorizeStripe() {
        if (this.props.unauthorize) {
            this.setState({ settingUpStripe: true });
            await this.props.unauthorize();
            this.setState({ settingUpStripe: false });
        }
    }

    // TODO: Make this entire feature more generic.
    private getStorefrontStripeIntegration() {
        const { storefrontIntegrations } = this.props;

        return (storefrontIntegrations || []).find((integration: any) => "stripe" === integration.provider);
    }

    private storefrontIntegrationHasStatus(storefrontIntegration, ...statuses: string[]) {
        return statuses.includes(storefrontIntegration?.status)
    }

    private getContextualizedErrorDescription(errorDescription) {
        return {
            'The user denied your request': 'You will need to restart the setup.'
        }[errorDescription] || errorDescription
    }

    render() {
        const storefrontStripeIntegration = this.getStorefrontStripeIntegration();

        return (
            <div>
                {this.renderOrderSettingsModal()}
                {this.renderDeliverySettingsModal()}
                {this.renderProductSettingsModal()}
                {this.renderInvoiceSettingsModal()}
                {this.renderTimezoneSettingsModal()}
                {this.renderAvailabilitiesSettingsModal()}
                {this.renderHolidaysSettingsModal()}
                <Container>
                    <div style={{ overflowX: 'auto', height: '75vh' }}>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openAvailabilitiesOptionsSettings") }} />
                            <Header floated="left">{this.translate('Opening Hours')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateAvailabilities()}
                        </Segment>
                        <Segment padded>
                            {/* <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openHolidaysOptionsSettings") }} /> */}
                            <Header floated="left">{this.translate('Holidays and Vacations')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            <div className='calendar' id='calendar'></div>
                            {this.generateHolidays()}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openTimezoneOptionsSettings") }} />
                            <Header floated="left">{this.translate('Timezone Options')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('availabilities')}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openOrderOptionsSettings") }} />
                            <Header floated="left">{this.translate('Order Options')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('orders')}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openDeliveryOptionsSettings") }} />
                            <Header floated="left">{this.translate('Delivery Options')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('deliveries')}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openProductOptionsSettings") }} />
                            <Header floated="left">{this.translate('Product Options')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('products')}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} onClick={() => { this.setOpenOptionsSettings("openInvoiceOptionsSettings") }} />
                            <Header floated="left">{this.translate('Invoice Options')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('invoices')}
                        </Segment>
                        <Segment padded>
                            <Button floated="right" primary content={this.translate('Modify')} disabled />
                            <Header floated="left">{this.translate('Cart')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {this.generateSettings('cart')}
                        </Segment>
                        <Segment padded>
                            {/* <Button floated="right" primary content={this.translate('Modify')} disabled /> */}
                            <Header floated="left">{this.translate('Storefront Integrations')}</Header>
                            <br style={{ clear: 'both' }} />
                            <Divider />
                            {/* {true && <List divided verticalAlign='middle' relaxed='very' style={{ width: '100%' }}>
                                    <List.Item fluid className='provision-item' style={{ margin: '15px 15px 0px', backgroundImage: 'none', backgroundColor: 'white' }}>
                                        <List.Content style={{ marginLeft: '5px' }} floated='right'>
                                            <Icon basic name={"spinner"} loading disabled size="big" />
                                        </List.Content>
                                        <List.Content>
                                            <List.Header>Checking provisioning status...</List.Header>
                                            <List.Description>Please wait</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>} */}
                            {(!this.storefrontIntegrationHasStatus(storefrontStripeIntegration, 'active')) && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <List divided verticalAlign='middle' relaxed='very'>
                                                        <List.Item className="stripe-item">
                                                            <List.Content floated='right'>
                                                                <Button
                                                                    primary
                                                                    icon={this.state.settingUpStripe ? "spinner" : undefined}
                                                                    loading={this.state.settingUpStripe}
                                                                    disabled={this.state.settingUpStripe}
                                                                    content={"Set up Stripe"}
                                                                    onClick={() => {
                                                                        this.stripeSetup();
                                                                    }} 
                                                                />
                                                            </List.Content>
                                                            <List.Content>
                                                                <List.Header>Stripe</List.Header>
                                                                <List.Description>Before you can accept payments via Stripe, you'll need to set it up.</List.Description>
                                                                {this.storefrontIntegrationHasStatus(storefrontStripeIntegration, 'failed', 'pending') && (
                                                                    <List.Description><strong>Connection state:</strong> {storefrontStripeIntegration?.status}</List.Description>
                                                                )}
                                                                {( this.storefrontIntegrationHasStatus(storefrontStripeIntegration, 'failed', 'pending') && storefrontStripeIntegration?.metadata?.error?.errorDescription )&& (
                                                                    <List.Description><strong>Description:</strong> {this.getContextualizedErrorDescription(storefrontStripeIntegration?.metadata?.error?.errorDescription)}</List.Description>
                                                                )}
                                                            </List.Content>
                                                        </List.Item>
                                                    </List>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {(this.storefrontIntegrationHasStatus(storefrontStripeIntegration, 'active')) && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <List divided verticalAlign='middle' relaxed='very'>
                                                        <List.Item className="stripe-item">
                                                            <List.Content floated='right'>
                                                                <Button 
                                                                    negative 
                                                                    icon={this.state.settingUpStripe ? "spinner" : undefined} 
                                                                    loading={this.state.settingUpStripe} 
                                                                    content={"Unauthorize Stripe."} 
                                                                    onClick={async () => { await this.unauthorizeStripe() }} 
                                                                />
                                                            </List.Content>
                                                            <List.Content>
                                                                <List.Header>Stripe</List.Header>
                                                                <List.Description>You are currently set up to receive payments via Stripe!</List.Description>
                                                            </List.Content>
                                                        </List.Item>
                                                    </List>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Segment>
                    </div>
                </Container>
            </div>
        );
    }
}
