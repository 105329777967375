import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IOrderPageProps {
    createRoutes?: Function;
    items: any;
    exceptions: any;
    pauseButtonMap: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any;
    breadcrumbLevels?: any;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
    quickSaveStatus: (data: any) => any;
    getInvoice: (data: any) => any;
    getInvoiceInformation: (data: any) => any;
    setExceptions: (data: any) => any;
    resumeOrders: () => any;
    refreshOrders: Function;
    countdownDate: number;
    hasNewOrders: boolean;
    lastUpdatedDate: string;
    displaySku:boolean;
}

export default class OrderPage extends PureComponent<IOrderPageProps> {
    render() {
        const { createRoutes,
            items,
            Gatekeeper,
            onDoubleClick,
            localizationService,
            breadcrumbLevels,
            refreshOrders,
            countdownDate,
            hasNewOrders,
            lastUpdatedDate,
            exceptions,
            pauseButtonMap,
            displaySku,
            onPathChange,
            quickSave,
            quickSaveStatus,
            getInvoice,
            getInvoiceInformation,
            setExceptions,
            resumeOrders,
        } = this.props;

        const Page = LocalizedPage();
        return (
            <DesktopPage
                items={items}
                exceptions={exceptions}
                pauseButtonMap={pauseButtonMap}
                Gatekeeper={Gatekeeper}
                onDoubleClick={onDoubleClick}
                localizationService={localizationService}
                breadcrumbLevels={breadcrumbLevels}
                refreshOrders={refreshOrders}
                countdownDate={countdownDate}
                hasNewOrders={hasNewOrders}
                lastUpdatedDate={lastUpdatedDate}
                displaySku={displaySku}
                onPathChange={onPathChange}
                quickSave={quickSave}
                quickSaveStatus={quickSaveStatus}
                getInvoice={getInvoice}
                getInvoiceInformation={getInvoiceInformation}
                setExceptions={setExceptions}
                resumeOrders={resumeOrders}
            />

        );
    }
}
