import React from 'react';
import { BlogEditPage } from '../../components/pages';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import { slug as slugGen } from 'slug-gen';

export default class BlogCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;

        try {
            item = await this.repository.create(data);
            await this.repository.createByPath({
                postId: item.id,
                slug: slugGen(item.title || 'slug'), // TO-DO: revise this ASAP
                language: item.language,
            }, `posts/${item.id}/webmetas`);
            this.router.redirect(`/posts/${item.id}/edit`);
        } catch (e) {
            throw e;
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Blog'), value: '/posts' },
            { key: 2, text: this.localizationService.translate('Create blog post'), value: undefined },
        ]
    }

    render() {
        const { item } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <BlogEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}