import React, { PureComponent } from "react";
import "./styles.css";
import {
    Button,
    Card,
    Divider,
    Grid,
    Header,
    Label,
    Modal,
    Table,
} from "semantic-ui-react";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import UnauthorizedMessage from "../../../layouts/messages/UnauthorizedMessage/UnauthorizedMessage";
import { ScrollableList } from "../../../layouts/sections";
import TemplateSectionForm from "./modalforms/TemplateSectionForm";
import TemplateLayoutForm from "./modalforms/TemplateLayoutForm";

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    item: any;
    layouts: any[];
    sections: any[];
    handleCreateTemplate?: (data: any) => any;
    handleCreateTemplateLayout: (data: any) => any;
    handleCreateTemplateSectionLayout: (data: any) => any;
}
export interface IDesktopPageState {
    openModal: boolean;
    openSectionsModal: boolean;
    attribute: any;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        openModal: false,
        openSectionsModal: false,
        attribute: undefined,
    };

    renderItem(item) {
        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Description>
                        {item?.locations &&
                            <>
                                <Divider />
                                <h4>Available locations:</h4>
                                <Label.Group style={{ margin: '10px 0px' }}>
                                    {item.locations?.map(location => {
                                        return <Label content={location} basic style={{ margin: '0 5px' }} />
                                    })}
                                </Label.Group>
                            </>
                        }
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button floated="right" color="red" icon="trash" />
                </Card.Content>
            </Card>
        )
    }

    private displayTemplateLayoutModal(openModal: boolean = true) {
        this.setState({ openModal });
    }

    private displaySectionLayoutModal(openSectionsModal: boolean = true) {
        this.setState({ openSectionsModal });
    }

    private handleSaveTemplateLayout(data) {
        if (this.props.handleCreateTemplateLayout) {
            this.props.handleCreateTemplateLayout(data);
        }
        this.displaySectionLayoutModal(false);
        this.displayTemplateLayoutModal(false);
    }

    private handleSaveSectionLayout(data) {
        if (this.props.handleCreateTemplateSectionLayout) {
            this.props.handleCreateTemplateSectionLayout(data);
        }
        this.displaySectionLayoutModal(false);
        this.displayTemplateLayoutModal(false);
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={2} textAlign={'center'}>
                    <Header>No layouts!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderListBody(list) {
        let items = list?.map(item => this.renderItem(item));
        return !items.length ? this.renderNoContentMessage() : items;
    }

    private renderInfoCard() {
        const { item } = this.props;
        if (!item) { return; }
        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                    {item?.state && 'published' === item?.state && <Button icon='send' color='red' onClick={() => { /*this.handlePublishUnpublish('draft')*/ }} floated={'right'}>
                        Unpublish
                    </Button>}
                    {item?.state && 'published' !== item?.state && <Button icon='send' color='green' onClick={() => { /*this.handlePublishUnpublish('published')*/ }} floated={'right'}>
                        Publish
                    </Button>}
                    <Button icon='edit' color='teal' as={'a'} href={`/website-management/pages/${item?.id}/edit`} floated={'right'}>
                        Edit
                    </Button>
                </Card.Content>
            </Card>
        )
    }

    render() {
        const { Gatekeeper, layouts, sections } = this.props;

        return (
            <Gatekeeper name="manage-websitetemplates" unauthorizedComponent={<UnauthorizedMessage />}>
                <Modal open={this.state.openModal}>
                    <TemplateSectionForm type='property' attribute={this.state.attribute} toggleModal={(display) => this.displayTemplateLayoutModal(display)} createAttribute={(data) => this.handleSaveSectionLayout(data)} />
                </Modal>
                <Modal open={this.state.openSectionsModal}>
                    <TemplateLayoutForm type='selection' attribute={this.state.attribute} toggleModal={(display) => this.displaySectionLayoutModal(display)} createAttribute={(data) => this.handleSaveTemplateLayout(data)} />
                </Modal>
                {this.renderInfoCard()}
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header>Layouts</Header>
                            <ScrollableList className="eight">
                                {this.renderListBody(layouts)}
                            </ScrollableList>
                            <Button onClick={() => { this.displaySectionLayoutModal() }} color="green" content="Add template layout" icon="plus" />
                        </Grid.Column>
                        <Grid.Column>
                            <Header>Sections</Header>
                            <ScrollableList className="eight">
                                {this.renderListBody(sections)}
                            </ScrollableList>
                            <Button onClick={() => { this.displayTemplateLayoutModal() }} color="green" content="Add section layout" icon="plus" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </ Gatekeeper>
        );
    }
}
