import React, { PureComponent } from "react";
import {
    Card,
    CardGroup,
    Icon,
} from "semantic-ui-react";
import { LoadingSpinner } from "../../controls";
import { UnauthorizedMessage } from "../../layouts/messages";
import { DesktopSkeleton } from "../../layouts/skeleton";
import Cookies from "universal-cookie";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import "./styles.css";

const cookies = new Cookies();
const commonUtils = new CommonUtils()

export interface IDesktopPageProps {
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {
    state = {};

    renderMainContainer() {
        const Gatekeeper = this.props.Gatekeeper;
        const Localization = this.props.localizationService;
        return (
            <CardGroup>
                <Gatekeeper name="website-management" or={["page-management"]}>
                    <Card link href={"/website-management"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="list" />
                                {Localization.translate('Manage your website')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('Manage your website settings, look and feel, and content.')}
                            </Card.Description>
                        </Card.Content>

                    </Card>
                </Gatekeeper>
                <Gatekeeper name="blog-management">
                    <Card link href={"/posts"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="list" />
                                {Localization.translate('View all blog posts')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('View and manage posts that are present on your blog.')}
                            </Card.Description>
                        </Card.Content>

                    </Card>
                </Gatekeeper>
                {/* <Gatekeeper name="events-management">
                    <Card link href={"/events"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="calendar" />
                                {Localization.translate('View all events')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('View and manage events.')}
                            </Card.Description>
                        </Card.Content>

                    </Card>
                </Gatekeeper> */}
                <Gatekeeper name="product-view" and={["tax-view", "catalog-view"]}>
                    <Card link href={"/store-management"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="shopping basket" />
                                {Localization.translate('Manage store')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('Manage your store.')}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
                <Gatekeeper name="messages-management">
                    <Card link href={"/inbox"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="envelope" />
                                {Localization.translate('See messages from customers')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('Keep up to date with your new customers by viewing and responding to their messages from your website.')}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
                <Gatekeeper name="account-view">
                    <Card link href={"/accounts"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="building" />
                                {Localization.translate('Manage accounts')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('View and manage accounts.')}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
                <Gatekeeper name="subscription-management">
                    <Card link href={"/subscriptions"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="bell" />
                                {Localization.translate('Manage subscriptions')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('View and manage subscriptions.')}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
                <Gatekeeper name="manage-websitetemplates">
                    <Card link href={"/website-template-management"}>
                        <Card.Content>
                            <Card.Header as="h2" icon>
                                <Icon name="paint brush" />
                                {Localization.translate('Manage website templates')}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Card.Description>
                                {Localization.translate('Manage the website templates available to platform users.')}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
            </CardGroup>
        )
    }

    renderFavoriteContainer() {
        const Gatekeeper = this.props.Gatekeeper;
        const Localization = this.props.localizationService;
        const favoriteFeatures = cookies.get('favorite-features');
        const favoriteFeaturesArray = favoriteFeatures ? favoriteFeatures.split(',') : [];
        const favoritePanes = favoriteFeaturesArray.map(feature => {
            return (<Card>
                <Card.Content>
                    <Card.Header as={'h3'}>{commonUtils.capitalizeFirstLetter(feature)}</Card.Header>
                </Card.Content>
            </Card>)
        })
        return (
            <CardGroup>
                <Gatekeeper name="product-view" and={["tax-view", "catalog-view"]}>
                    <Card link href="/store-management/orders">
                        <Card.Content>
                            <Card.Header as={'h3'}>{commonUtils.capitalizeFirstLetter(Localization.translate('Orders'))}</Card.Header>
                        </Card.Content>
                    </Card>
                </Gatekeeper>
            </CardGroup>
            // <CardGroup>
            //     {favoritePanes}
            // </CardGroup>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        const Localization = this.props.localizationService;

        return (
            <DesktopSkeleton>
                <Gatekeeper
                    name="blog-management"
                    or={["website-management", "messages-management", "page-management"]}
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="main-container">
                        {cookies.get('favorite-features') && <h2>{Localization.translate('Frequently used features.')}</h2>}
                        {this.renderFavoriteContainer()}
                        <h2>{Localization.translate('What would you like to accomplish today?')}</h2>
                        {this.renderMainContainer()}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
