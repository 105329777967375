import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../LocalizedPage';
import { ISignupFormState } from '../../forms/SignupForm/SignupForm';
import { IPageProps } from '../Page/Page';

export interface ISignupPageProps extends IPageProps {
    onSignup: (values: ISignupFormState) => any;
}

export default class SignupPage extends PureComponent<ISignupPageProps> {
    render() {
        let { children, header, routes, createRoutes, onSignup } = this.props;

        const Page = LocalizedPage();

        return (
            <Page createRoutes={createRoutes} headerHidden>
                <DesktopPage
                    header={header}
                    onSignup={onSignup}
                >
                    {children}
                </DesktopPage>
                <MobilePage
                    header={header}
                    routes={routes}
                    onSignup={onSignup}
                >
                    {children}
                </MobilePage>
            </Page>
        );
    }
}