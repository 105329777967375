import React from 'react';
import { toast } from 'react-toastify';
import { Form, Message, Accordion, Icon, Button, Grid, Dropdown, Input, Label, Card, CardContent, CardHeader, CardDescription, CardGroup } from 'semantic-ui-react';
import MarkdownEditor from '../../../controls/MarkdownEditor';
import SelectInput from '../../../controls/SelectInput';
import { LoadingMessage } from '../../../layouts/messages';
import StateAwareForm, {
    IStateAwareFormProps,
} from '../../StateAwareForm/StateAwareForm';
import languages from '../../../../utilities/Languages/Languages';
import './style.css';
import { DeleteButton } from '../../../controls';
import ProductAttributesInput from '../ProductAttributesForm';
import CancelButton from '../../../controls/buttons/CancelButton';
import BuildingAnimation from '../../../elements/BuildingAnimation/BuildingAnimation';
export interface IApplicationInstanceDefinitionFormProps extends IStateAwareFormProps {
    title: string;
    language: string;
    content: string;
    price: string;
    category: string;
    brand?: string;
    taxes?: any[];
    localizationService: any;
}
export default class ApplicationInstanceSubscriptionSelectionForm extends StateAwareForm<IApplicationInstanceDefinitionFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => void;
        status: 'draft';
        readOnly: false;
        taxes: [],
    };

    componentDidMount() {
        super.setState({
            title: this.props.title,
            language: this.props.language,
            content: this.props.content,
            price: this.props.price,
            category: this.props.category,
            brand: this.props.brand,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                title: this.props.title,
                language: this.props.language,
                content: this.props.content,
                price: this.props.price,
                category: this.props.category,
                brand: this.props.brand,
            });
        }
    }

    async submit() {
        try {
            await this.handleSubmit();
            toast.success('Product successfully updated.');
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    generateLanguagesMap() {
        return languages.map(language => {
            return { key: language.code, text: language.name, value: language.code };
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeAccordeonIndex } = this.state
        const newIndex = activeAccordeonIndex === index ? -1 : index

        this.setState({ activeAccordeonIndex: newIndex })
    }

    private renderGeneralInformation() {
        return (
            <>
                <Form.Input
                    width={'16'}
                    fluid
                    label={this.translate("Name")}
                    placeholder={this.translate("My Cool Site")}
                    defaultValue={this.props.title}
                    name="title"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input width={8} fluid labelPosition='right' type='text' placeholder='my-cool-site' label='Domain'>
                    <Label basic>https://</Label>
                    <input />
                    <Label>.dreamship.site</Label>
                </Form.Input>
            </>
        )
    }

    private renderSubscriptionList() {
        let subscriptionsList = [
            {
                'name': 'Landing Page Plan',
                'description': "Great for showcasing a professional site.",
                'price': "Starting at 7$/month"
            },
            {
                'name': 'Website Plan',
                'description': "Great for showcasing a professional site.",
                'price': "Starting at 15$/month"
            },
            {
                'name': 'eCommerce Plan',
                'description': "Great for showcasing a professional site.",
                'price': "Starting at 20$/month"
            },
        ];
        let subscriptionCards = subscriptionsList.map((subscription) => {
            return (
                <Card>
                    <CardContent>
                        <CardHeader content={subscription.name}></CardHeader>
                        <CardDescription content={subscription.description}></CardDescription>
                    </CardContent>
                    <CardContent extra>
                        {subscription.price}
                    </CardContent>
                </Card>
            )
        })
        return (
            <CardGroup itemsPerRow={3} centered>
                {subscriptionCards}
            </CardGroup>
        )
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form
                onSubmit={() => this.submit()}
                error={!!error}
                style={{ zIndex: '0' }}
            >
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the application"
                />
                <Message
                    error
                    header="Could not save application"
                    content={error && error.toString()}
                />
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {/* {this.renderSubscriptionList()} */}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <BuildingAnimation text={'We are creating your cool site!'} />
                <Button.Group floated="right">
                    <CancelButton
                        itemType="product definition"
                        color='grey'
                    />
                    <br />
                    <Button color="green" disabled={loading}>Save and Continue</Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="subscription"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </Button.Group>
            </Form>
        );
    }
}
