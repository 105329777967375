import controllers from './controllers';
import router from './router';
import factories from './factories';
import providers from './providers';
import repositories from './repositories';
import services from './services';
import core from './core';

export default {
    ...controllers,
    ...router,
    ...factories,
    ...providers,
    ...repositories,
    ...services,
    ...core,
}