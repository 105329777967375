import React, { PureComponent } from 'react';
import { DeleteButton } from '../../../controls';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import TaxesForm from '../../../forms/taxes/TaxesForm';
import "./styles.css";

export interface ITaxesDesktopPageProps {
    name: string;
    rate: number;
    rateType: string;
    registrationNumber: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
}

export default class DesktopPage extends PureComponent<ITaxesDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        name: "",
        rate: 0,
        rateType: "",
        registrationNumber: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        return (
            <DesktopSkeleton>
                <Gatekeeper name="tax-management">
                    <div className="editor-container">
                        <TaxesForm
                            name={this.props.name}
                            rate={this.props.rate}
                            rateType={this.props.rateType}
                            registrationNumber={this.props.registrationNumber}
                            onSubmit={(data) => this.props.onSave(data)}
                            localizationService={this.props.localizationService}
                        >
                            {this.props.name && (
                                <DeleteButton
                                    itemType="tax"
                                    itemName={this.props.name}
                                    icon="trash"
                                    color="red"
                                    handleOnClick={this.props.onDelete}
                                />
                            )}
                        </TaxesForm>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}