import React from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { Repository } from '../../repositories';
import { EntityId } from '../../repositories/Repository';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';
import MessagesSettingsPage from '../../components/pages/MessagesSettings';

export default class MessagesConfigurationSettingsController extends LocalizedController {
    private localizationService: LocalizationService;
    private repository: Repository;

    state = {
        items: [],
        hasProvisionned: false,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, repository] = props.args;

        this.localizationService = localizationService;
        this.repository = repository;
    }

    private async getMessageConfiguration() {
        let items: any[] = [];

        try {
            items = await this.repository.getByPath(`messages/configuration`)
            items.reverse();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    private async saveMessageConfiguration(data: any) {
        let { items } = this.state;

        data['id'] = items['id'];
        data['subjectTemplate'] = items['subjectTemplate'];
        data['template'] = items['template'];

        try {
            this.repository.updateByPath(data, `messages/configuration/${data.id}`);
        } catch (e) {
            console.error(e)
        }

        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
        }
    }

    async componentDidMount() {
        await this.fetch();
    }

    private async fetch() {
        let items = await this.getMessageConfiguration();
        if(items && items.length) {
            items = items[0];
        }

        this.setState({
            items,
        });
    }

    protected onChange(path) {
        this.router.redirect(`/website-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Messaging Settings'), value: undefined },
        ]
    }

    render() {
        const { items } = this.state;
        return this.prepare(
            <MessagesSettingsPage
                localizationService={this.localizationService}
                items={items}
                onChange={(path) => this.onChange(path)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                saveMessageConfiguration={(data) => this.saveMessageConfiguration(data)}
            />
        )
    }
}