import React from 'react';
import ProductPricingShippingEditPage from '../../../components/pages/productdefinitions/ProductPricingShippingEditPage';
import ProductEditPage from '../../../components/pages/products/ProductEditPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductPriceShippingEditController extends LocalizedController {
    private repository: Repository;
    private taxesRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        definitionTaxes: [],
        allTaxes: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, taxesRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.taxesRepository = taxesRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    private async getProductTaxes() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`definitions/${this.params?.id}/taxes`);
        } catch (e) {
            return;
        }

        return items;
    }

    private async getAllTaxes() {
        let items;

        try {
            items = await this.taxesRepository.get();
        } catch (e) {
            return;
        }

        return items;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.handleSave(item);
    }

    private async handleSave(data: any) {
        try {
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
                ...data,
            });

            // this.setState({
            //     item: {
            //         //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            //         id: this.params?.id,
            //         ...data,
            //     }
            // })
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/store-management/products');
        } catch (e) {
            throw e;
        }
    }

    private async handleLinkTax(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'taxId', data);
            await this.fetchItems();
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private async handleUnlinkTax(data) {
        console.error(data);
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'taxId', data);
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
        try {
            await this.fetchItems();
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private async handleCreateTax(data: any) {
        let item;

        try {
            item = await this.taxesRepository.create(data);
            let allTaxes = await this.getAllTaxes();
            this.setState({ allTaxes });
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        await this.fetchItems();
    }

    async fetchItems() {
        let item = await this.get();
        let allTaxes = await this.getAllTaxes();
        let definitionTaxes = await this.getProductTaxes();

        this.setState({
            item,
            allTaxes,
            definitionTaxes,
        });
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Product'), value: '/store-management/products' },
            { key: 3, text: this.state.item ? this.state.item!['title'] : '', value: undefined },
        ]
    }

    render() {
        const { item, allTaxes, definitionTaxes } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();
        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        const productDefinitionId = this.params?.id;

        return this.prepare(
            <ProductPricingShippingEditPage
                productDefinitionId={productDefinitionId}
                item={item}
                allTaxes={allTaxes}
                definitionTaxes={definitionTaxes}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                handleLinkTax={async (data: any) => this.handleLinkTax(data)}
                handleUnlinkTax={async (data: any) => this.handleUnlinkTax(data)}
                handleCreateTax={async (data: any) => this.handleCreateTax(data)}
            />
        )
    }
}