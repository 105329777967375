import React from 'react';
import ProductCatalogEditPage from '../../components/pages/productcatalogcreate/ProductCatalogEditPage/ProductCatalogEditPage'
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class ProductCatalogEditController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private productRepository: Repository;
    private productDefinitionRepository: Repository;

    state = {
        item: undefined,
        linkedProducts: [],
        allProductDefinitions: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, productRepository, productDefinitionRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.productRepository = productRepository;
        this.productDefinitionRepository = productDefinitionRepository;
    }

    private async get() {
        let item;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            console.error(e);
            return;
        }
        return item;
    }

    private async getLinkedProducts() {
        let items;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`catalogs/${this.params?.id}/definitions`);
        } catch (e) {
            console.error(e);
            return [];
        }
        return items;
    }

    private async getProductDefinitions() {
        let items;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.productDefinitionRepository.get();
        } catch (e) {
            console.error(e);
            return [];
        }
        return items;
    }

    private async handleSave(data: any) {
        try {
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
                ...data,
            });
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/store-management/catalogs');
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        await this.fetchAll();
    }

    private async fetchAll() {
        let item = await this.get();
        let linkedProducts = await this.getLinkedProducts();
        let allProductDefinitions = await this.getProductDefinitions();

        this.setState({
            item,
            linkedProducts,
            allProductDefinitions,
        });
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Catalogs'), value: '/store-management/catalogs' },
            { key: 3, text: this.state.item ? this.state.item!['title'] : '', value: undefined },
        ]
    }

    private async linkProductToCatalog(definitionId: string) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'definitionId', definitionId);
        } catch (e) {
            throw e;
        }
        await this.fetchAll();
    }

    private async unlinkProductToCatalog(definitionId: string) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'definitionId', definitionId);
        } catch (e) {
            throw e;
        }
        await this.fetchAll();
    }

    render() {
        const { item, linkedProducts, allProductDefinitions } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductCatalogEditPage
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                onLink={async (productId: any) => this.linkProductToCatalog(productId)}
                onUnlink={async (productId: any) => this.unlinkProductToCatalog(productId)}
                item={item}
                linkedProducts={linkedProducts}
                allProductDefinitions={allProductDefinitions}
            />
        )
    }
}