import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IInvoiceOverviewPageProps {
    createRoutes?: Function;
    Gatekeeper: any,
    onDoubleClick?: Function,
    localizationService?: any,
    breadcrumbLevels?: any,
    panes?: any[],
    invoice: any[],
    order: any,
    displaySku: boolean,
}

export default class InvoiceOverviewPage extends PureComponent<IInvoiceOverviewPageProps> {
    render() {
        const { createRoutes, Gatekeeper, onDoubleClick, localizationService, breadcrumbLevels, panes, order, invoice, displaySku } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage panes={panes} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} invoice={invoice} order={order} displaySku={displaySku} />
                    {/* <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} /> */}
                </Component>
            </Page>
        );
    }
}
