import React from 'react';
import { ILoginFormState } from '../components/forms/LoginForm/LoginForm';
import { LoginPage } from '../components/pages';
import { AuthenticationService, LocalizationService } from '../services';
import LocalizedController from './LocalizedController';

export default class LoginController extends LocalizedController {
    state = {}
    protected authenticationService: AuthenticationService;
    protected localizationService: LocalizationService;

    constructor({ args: [router, routeMenuItemFactory, localizationService, authenticationService], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.authenticationService = authenticationService;
        this.localizationService = localizationService;
    }

    async handleLogin(state: ILoginFormState) {
        const { email, password } = state;
        const lastVisitedPage = localStorage.getItem('last_visited_page');

        try {
            await this.authenticationService.authenticate(email, password);
        } catch (e) {
            throw e;
        }

        if (!lastVisitedPage || 'undefined' === typeof lastVisitedPage) {
            return this.router.redirect('/');
        }

        return this.router.redirect(lastVisitedPage!.toString());
    }

    render() {
        return this.prepare(
            <LoginPage
                onLogin={values => this.handleLogin(values)}
                localizationService={this.localizationService}
            />
        )
    }
}