import React from 'react';
import { SignupPage } from '../components/pages';
import { UserService } from '../services';
import LocalizedController from './LocalizedController';

export default class SignupController extends LocalizedController {
    state = {}
    protected userService: UserService;

    constructor({ args: [router, routeMenuItemFactory, localizationService, userService], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.userService = userService;
    }

    async handleSignup(user) {
        try {
            await this.userService.create(user);
        } catch (e) {
            throw e;
        }

        setTimeout(() => this.router.redirect('/login'), 1000);

        return true;
    }

    render() {
        return this.prepare(
            <SignupPage
                onSignup={user => this.handleSignup(user)}
            />
        )
    }
}