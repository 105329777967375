import React from "react";
import { Form, Message, Button, Grid, ButtonGroup } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import { DeleteButton } from "../../../controls";
import MetadataEditor from "../../../controls/MetadataEditor";

const commonUtils = new CommonUtils();

export interface ICheckoutSectionFormProps extends IStateAwareFormProps {
    title?: string;
    name: string;
    language: string;
    additional?: any;
}

export default class CheckoutSectionForm extends StateAwareForm<ICheckoutSectionFormProps> {
    componentDidMount() {
        super.setState({
            title: this.props.title,
            name: this.props.name,
            language: this.props.language,
            additional: this.props.additional,
            type: "checkout",
        });
    }



    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error} className={'section-form-container'}>
                <ButtonGroup floated="right">
                    <Button
                        color="teal"
                        disabled={loading}
                        floated="right"
                    >
                        Save
                    </Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="section"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </ButtonGroup>
                <div style={{ display: 'block', clear: 'both' }}>
                    <Grid>
                        <Grid.Column floated="left" width={11}>
                            <h2>General Information</h2>
                            <p>
                                This section manages the core information necessary for a section
                                to work.
                            </p>
                        </Grid.Column>
                    </Grid>
                </div>
                <br />
                <br />
                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.title}
                    name="title"
                    onChange={(e, { name, value }) => this.handleChange({ name, value })}
                />

                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        options={commonUtils.generateLanguagesMap()}
                        label={"Language"}
                        search
                        placeholder="Language"
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        options={[{ key: "type", text: "Checkout", value: "checkout" }]}
                        label={"Type"}
                        placeholder="Type"
                        value="checkout"
                        name="type"
                        disabled
                    />
                </Form.Group>

                <h2>Contact Information Form Details</h2>
                <p>Configure the verbiage of the Contact Information Form</p>

                <h3>Name</h3>

                <MetadataEditor
                    name="additional[contact][name]"
                    defaultValue={this.props.additional?.contact?.name}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h3>Phone Number</h3>

                <MetadataEditor
                    name="additional.contact.phone"
                    defaultValue={this.props.additional?.contact?.phone}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h3>Email</h3>

                <MetadataEditor
                    name="additional.contact.email"
                    defaultValue={this.props.additional?.contact?.email}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h2>Payment Method Options Placeholders</h2>
                <p>Configure the verbiage of the payments options</p>
                <p>Only verbiage permitted by the options configured in the Storefront are displayed.</p>

                <h3>Section Header</h3>

                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.additional?.payments?.title}
                    name="additional.payments.title"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <h3>Cash</h3>

                <MetadataEditor
                    name="additional.payments.cash"
                    defaultValue={this.props.additional?.payments?.cash}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h3>Stripe</h3>

                <MetadataEditor
                    name="additional.payments.stripe"
                    defaultValue={this.props.additional?.payments?.stripe}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h2>Pickup Options Placeholders</h2>
                <p>Configure the verbiage of the pickup options</p>
                <p>Only verbiage permitted by the options configured in the Storefront are displayed.</p>

                <h3>Section Header</h3>

                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.additional?.pickup?.title}
                    name="additional.pickup.title"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <h3>Standard</h3>

                <MetadataEditor
                    name="additional.pickup.standard"
                    defaultValue={this.props.additional?.pickup?.standard}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h3>Scheduled</h3>

                <MetadataEditor
                    name="additional.pickup.scheduled"
                    defaultValue={this.props.additional?.pickup?.scheduled}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={false}
                    canUpdateKeys={false}
                />

                <h2>Store Details Placeholders</h2>
                <p>Configure the verbiage of the store details</p>
                <p>Only verbiage permitted by the options configured in the Storefront are displayed.</p>

                <h3>Section Header</h3>

                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.additional?.storedetails?.title}
                    name="additional.storedetails.title"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Section"
                />

                <Message
                    error
                    header="Could not save section"
                    content={error && error.toString()}
                />
            </Form>
        );
    }
}
