import React, { PureComponent } from "react";
import { Tab, Table, Button, Popup } from "semantic-ui-react";
import CommonUtils from "../../../../../../utilities/Common/CommonUtils";
import { DeleteButton } from "../../../../../controls";
import AssociateApiKeyModal from "./AssociateApiKeyModal";
import EditApiKeyFeaturesModal from "./EditApiKeyFeaturesModal";
import './styles.css';

const commonUtils = new CommonUtils();

export interface IApiKeysPaneProps {
    items: any[];
    Gatekeeper: any;
    onToggleFeature: (item: any, data: any) => Promise<any>;
    onSave: (data: any) => Promise<any>;
    onDelete: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;

    getApikeyFeatures: (id: string) => Promise<any[]>

    features: any[];
    toastConfig: any;
}

export interface IApiKeyPaneState {
    modalOpen: boolean;
    editorModalOpen: boolean;
    selectedItem: any;
    featureModalOpen: boolean;
    apiKeyFeatures: any[];
}

export default class ApiKeysPane extends PureComponent<IApiKeysPaneProps, IApiKeyPaneState> {
    state: IApiKeyPaneState = {
        modalOpen: false,
        featureModalOpen: false,
        editorModalOpen: false,
        selectedItem: undefined,
        apiKeyFeatures: [],
    };

    async handleFeatureActivationToggle(item) {
        //
    }

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.setState({ selectedItem: undefined, apiKeyFeatures: [] }, () => this.props.onModalClose());
            }
        });
    }

    toggleFeatureModal() {
        this.setState(({ featureModalOpen }) => ({ featureModalOpen: !featureModalOpen }), () => {
            if (!this.state.featureModalOpen) {
                this.setState({ selectedItem: undefined, apiKeyFeatures: [] });
                // this.props.onModalClose();
            }
        });
    }

    async handleSelectApiKey(item) {
        let apiKeyFeatures;

        try {
            apiKeyFeatures = await this.props.getApikeyFeatures(item.id);
        } catch (e) {
            console.error(e);
            apiKeyFeatures = [];
        }

        return this.setState({ selectedItem: item, apiKeyFeatures: apiKeyFeatures.map(({ id }) => id) }, () => this.toggleFeatureModal())
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell className="feature-list-feature-status">
                    <Button icon circular name="circle" color={item.enabled ? "green" : "black"} size={'large'} />
                </Table.Cell>
                <Table.Cell>{commonUtils.renderDate(item.date)}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="apikey-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    onClick={async () => await this.handleSelectApiKey(item)}
                                />
                            }
                            content="Edit the api key's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="apikey-management">
                        <DeleteButton
                            itemType="API key"
                            itemName={item.name}
                            icon="trash"
                            color="red"
                            handleOnClick={async () => this.props.onDelete(item)}
                            serviceDisruption
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateApiKeyModal
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
                features={this.props.features}
                toastConfig={this.props.toastConfig}
                onSave={async (data: any) => await this.props.onSave(data)}
                onToggleFeature={async (item: any, data: any) => await this.props.onToggleFeature(item, data)}
                item={this.state.selectedItem}
            />
        );
    }

    private renderFeatureModal(modalOpen: boolean, Gatekeeper) {
        return (
            <EditApiKeyFeaturesModal
                toggleModal={() => this.toggleFeatureModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
                features={this.props.features}
                toastConfig={this.props.toastConfig}
                onToggleFeature={async (data: any) => await this.props.onToggleFeature(this.state.selectedItem, data)}
                apiKeyFeatures={this.state.apiKeyFeatures}
                item={this.state.selectedItem}
            />
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Tab.Pane attached={false}>
                <Gatekeeper name="application-apikey-management">
                    {this.renderModal(this.state.modalOpen, Gatekeeper)}
                    {this.renderFeatureModal(this.state.featureModalOpen, Gatekeeper)}
                    <Button
                        color="green"
                        size="small"
                        floated="right"
                        icon='plus'
                        onClick={(e) => this.toggleModal()}
                        content="Create Api Key"
                        style={{ marginBottom: '1rem' }}
                    />
                </Gatekeeper>

                <Table>
                    <Table.Header>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.props.items?.map(item => this.renderItem(item, Gatekeeper))}
                    </Table.Body>
                </Table>
            </Tab.Pane>
        );
    }
}