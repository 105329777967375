import React from "react";
import { Button, Dropdown, Form, Message } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
// import "./style.css";

export interface ITaxesFormProps extends IStateAwareFormProps {
    name: string;
    rate: number;
    rateType: string;
    registrationNumber: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
    localizationService: any;
}

export default class TaxesForm extends StateAwareForm<ITaxesFormProps> {
    constructor(props) {
        super(props);
    }

    public static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
            rate: this.props.rate,
            rateType: this.props.rateType,
            registrationNumber: this.props.registrationNumber,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
            });
        }
    }

    translate(key: string): string {
        if (!this.props.localizationService) { return key; }
        return this.props.localizationService.translate(key);
    }

    render() {
        const { error, loading } = this.state;
        const rateTypeOptions = [
            {
                key: 'percentage',
                text: this.translate('Percentage'),
                value: 'percentage',
            },
            {
                key: 'dollar',
                text: this.translate('Dollar'),
                value: 'dollar',
            },
        ];

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>{this.translate('Save')}</Button>
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content={this.translate("Persisting the tax")}
                />

                <Message
                    error
                    header={this.translate("Could not save tax")}
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={this.translate("Name")}
                    defaultValue={this.props.name}
                    name="name"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input
                    fluid
                    label={this.translate("Rate")}
                    defaultValue={this.props.rate}
                    type="number"
                    name={'rate'}
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                >
                    <input type='number' step='0.001' name={'rate'} />
                </Form.Input>
                <Form.Input
                    fluid
                    label={this.translate("Tax Registration Number")}
                    defaultValue={this.props.registrationNumber}
                    type="text"
                    name={'registrationNumber'}
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                >
                </Form.Input>
                <Form.Dropdown
                    label={this.translate('Rate Type')}
                    placeholder={this.translate('Select Rate Type')}
                    fluid
                    selection
                    options={rateTypeOptions}
                    labeled
                    defaultValue={this.props.rateType}
                    onChange={(e, data) => this.handleChange({ name: 'rateType', value: data.value })}
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>{this.translate('Save')}</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
