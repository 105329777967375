
export default class CustomizationRepository {
    constructor(protected storageProvider) {

    }

    get(option) {
        return this.storageProvider.getOne(option);
    }

    save(option, value) {
        return this.storageProvider.update(option, value);
    }

    delete(option) {
        return this.storageProvider.delete(option);
    }
}