import {
    LocalizationService,
    AuthenticationService,
    CsvParser,
    UserService,
    ActivationService,
    SecurityService,
} from '../../services';
import FeatureService from '../../services/FeatureService';

export default {
    "service.localization": {
        "class": LocalizationService,
        "constructor": [
            { "type": "service", "key": "repository.localization" },
            { "type": "service", "key": "repository.customization" },
            { "type": "parameter", "key": "i18n" },
        ],
        "functions": [
            {
                "function": "initialize",
                "args": [],
            },
        ]
    },
    "service.authentication": {
        "class": AuthenticationService,
        "constructor": [
            { "type": "service", "key": "provider.api.authentication" },
            { "type": "service", "key": "service.security" },
        ]
    },
    "service.user": {
        "class": UserService,
        "constructor": [
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "factory.decorated_entity" },
        ],
        "functions": [],
    },
    "service.csvparser": {
        "class": CsvParser,
        "constructor": [],
        "functions": [],
    },
    "service.activation": {
        "class": ActivationService,
        "constructor": [
            { "type": "service", "key": "provider.api.activations" },
        ],
        "functions": [],
    },
    "service.security": {
        "class": SecurityService,
        "constructor": [
            { "type": "service", "key": "provider.cookie" },
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "service.feature" },
        ],
        "functions": [],
    },
    "service.feature": {
        "class": FeatureService,
        "constructor": [],
        "functions": [],
    },
};