import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Form,
    CardGroup,
    Card,
    Image,
    Message,
    Button,
    Radio,
    List,
    Modal,
    Icon,
} from "semantic-ui-react";
import { ChromePicker } from 'react-color';
import { ISiteConfigurationPageProps } from "./SiteConfigurationPage";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import ThemingUtils from "../../../utilities/Theming/ThemingUtils";
import WebsiteSettingsTemplate from "./WebsiteSettingsTemplate";
import { toast } from "react-toastify";
import QRCodeModal from "./Modals/QRCodeModal";
import uh from 'unsplash';
import "./styles.css";

const commonUtils = new CommonUtils();
const themingUtils = new ThemingUtils();

export interface ISiteConfigurationDesktopPageProps extends ISiteConfigurationPageProps {
    onChange: (path: string) => any,
    onPathChange: (path: string) => any,
    onProvision?: () => any,
    onSave?: (data: any) => any,
    localizationService: any,
    options: any,
    settings: any,
    domain: any,
    isFetching: boolean,
    Gatekeeper: any,
}

export interface ISiteConfigurationDesktopPageState {
    open: boolean;
    name: string;
    disableSubmit: boolean;
    checked: boolean;
    accountEmailChecked: boolean;
    primaryPaletteColor: any;
    primaryPaletteSwatches: any;
    textPaletteColor: any;
    textPaletteSwatches: any;
    provisioning: boolean;
    qrCodeModalOpen: boolean;
    [x: string]: any;
}

export default class DesktopPage extends PureComponent<
    ISiteConfigurationDesktopPageProps,
    ISiteConfigurationDesktopPageState
> {
    state = {
        open: false,
        name: "",
        disableSubmit: true,
        checked: false,
        accountEmailChecked: false,
        primaryPaletteColor: '',
        primaryPaletteSwatches: {},
        textPaletteColor: '',
        textPaletteSwatches: {},
        provisioning: false,
        qrCodeModalOpen: false,
    };

    componentDidUpdate(prevProps) {
        const { settings } = this.props;
        let state = {};

        if (prevProps.settings?.primaryPalette?.color !== this.props.settings?.primaryPalette?.color) {
            state = {
                ...state,
                primaryPaletteColor: this.props.settings?.primaryPalette?.color,
                primaryPaletteSwatches: this.props.settings?.primaryPalette?.palette,
            };
        }

        if (prevProps.settings?.textPalette?.color !== this.props.settings?.textPalette?.color) {
            state = {
                ...state,
                textPaletteColor: this.props.settings?.textPalette?.color,
                textPaletteSwatches: this.props.settings?.textPalette?.palette,
            };
        }

        if (Object.keys(state).length) {
            super.setState(state);
        }
    }

    protected setOpen(open, name) {
        this.setState({ open, name: name });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    toggleQrModal() {
        this.setState({ qrCodeModalOpen: !this.state.qrCodeModalOpen });
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    getLanguage() {
        return this.props.localizationService.getLanguage();
    }

    prepareLayoutCards() {
        const { options, settings } = this.props;
        if (!options || !settings) { return; }
        return options?.theme?.options.map(item => {
            return (
                <Card link image>
                    <Image src={uh(480, 480, 'random')}></Image>
                    <Card.Content>
                        <Card.Header>
                            {item.name}
                        </Card.Header>
                    </Card.Content>
                </Card>
            );
        });
    }

    generateDefaultLanguageOptions() {
        const { options, settings } = this.props;
        if (!options || !settings) { return; }
        let selectedOption = settings.defaultLanguage || options.defaultLanguage.defaultSelection;
        let optionsDisplayed = options.defaultLanguage.options.map(option => {
            return (
                <Form.Field>
                    <Radio
                        label={commonUtils.renderLanguageRealName(option)}
                        name='radioGroup'
                        value={option}
                        checked={selectedOption === option}
                    />
                </Form.Field>
            )
        })
        if (optionsDisplayed.length == 0) { return; }
        return (<Grid.Row>
            <Grid.Column>
                <h3>{this.translate('Default Language')}</h3>
                <Grid centered>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Form.Field>
                                Default language:
                            </Form.Field>
                            {optionsDisplayed}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>)
    }

    save() {
        const { primaryPaletteColor, primaryPaletteSwatches, textPaletteColor, textPaletteSwatches } = this.state;
        if (this.props.onSave) {
            this.props.onSave({
                primaryPalette: {
                    color: primaryPaletteColor,
                    palette: primaryPaletteSwatches
                },
                textPalette: {
                    color: textPaletteColor,
                    palette: textPaletteSwatches
                },
            });
            toast.success('Successfully saved settings.', {
                autoClose: 1500,
            })
        }
    }

    triggerProvision() {
        if (this.props.onProvision) {
            this.props.onProvision();
            this.setState({ provisioning: true }, () => {
                toast.success('Started the provisionning of your site. Please wait.', {
                    autoClose: 1500,
                    onClose: () => window.location.reload()
                })
            });
        }
    }

    renderQRCodeModal() {
        return (
            <Modal
                size="mini"
                open={this.state.qrCodeModalOpen}
                onClose={() => this.toggleQrModal()}
                trigger={<Button compact icon='qrcode' content='View QR Code' onClick={() => { this.toggleQrModal() }} color='facebook' />}
            >
                <QRCodeModal value={`https://${this.props.domain}` || `https://dreamshipsolutions.com`} />
            </Modal>
        )
    }

    render() {
        const { primaryPaletteSwatches, textPaletteSwatches } = this.state;
        const { Gatekeeper } = this.props;
        return (
            <WebsiteSettingsTemplate onChange={this.props.onPathChange} canDeploy={!this.props.onProvision} activeItem="website-management" localizationService={this.props.localizationService}>
                <div style={{ padding: '0px 30px' }}>
                    {this.props.options && <Message
                        warning
                        content="Please note that changes to the theme and layout will only take effect on your next build."
                    />}
                    <h2>{this.translate('Website Settings')}</h2>
                    <Segment>
                        <Form>
                            <Grid>
                                {/* <Gatekeeper name="manage-applicationprovisions" and={['execute-webhooks', 'resume-deployment']}> */}
                                {this.props.isFetching && <List divided verticalAlign='middle' relaxed='very' style={{ width: '100%' }}>
                                    <List.Item fluid className='provision-item' style={{ margin: '15px 15px 0px', backgroundImage: 'none', backgroundColor: 'white' }}>
                                        <List.Content style={{ marginLeft: '5px' }} floated='right'>
                                            <Icon basic name={"spinner"} loading disabled size="big" />
                                        </List.Content>
                                        <List.Content>
                                            <List.Header>Checking provisioning status...</List.Header>
                                            <List.Description>Please wait</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>}
                                {!this.props.isFetching && (this.props.onProvision || this.props.domain) && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Grid centered>
                                                <Grid.Row columns={1}>
                                                    <Grid.Column>
                                                        <List divided verticalAlign='middle' relaxed='very'>
                                                            {this.props.onProvision && (
                                                                <List.Item className="provision-item">
                                                                    <List.Content floated='right'>
                                                                        <Button primary icon={this.state.provisioning ? "spinner" : undefined} loading={this.state.provisioning} content={"Provision"} onClick={() => { if (!this.state.provisioning) { this.triggerProvision(); } }} />
                                                                    </List.Content>
                                                                    <List.Content>
                                                                        <List.Header>Provision</List.Header>
                                                                        <List.Description>Before you can see your website, you need to provision it.</List.Description>
                                                                    </List.Content>
                                                                </List.Item>
                                                            )}
                                                            {this.props.domain && (
                                                                <>
                                                                    <List.Item className="preview-item">
                                                                        <List.Content floated='right'>
                                                                            <Button color='purple' target="_blank" content="Visit" as={'a'} href={`//${this.props.domain}`}></Button>
                                                                        </List.Content>
                                                                        <List.Content>
                                                                            <List.Header>Visit your website</List.Header>
                                                                            <List.Description>Visit your website.</List.Description>
                                                                        </List.Content>
                                                                    </List.Item>
                                                                    <br />
                                                                    <List.Item className="preview-item">
                                                                        <List.Content floated='right'>
                                                                            {this.renderQRCodeModal()}
                                                                        </List.Content>
                                                                        <List.Content>
                                                                            <List.Header>QR Code</List.Header>
                                                                            <List.Description>Get your QR code.</List.Description>
                                                                        </List.Content>
                                                                    </List.Item>
                                                                </>
                                                            )}
                                                        </List>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {/* </Gatekeeper> */}
                                {this.props.options && this.generateDefaultLanguageOptions()}
                                {this.props.options && <Grid.Row>
                                    <Grid.Column>
                                        <h3>{this.translate('Theming')}</h3>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Form.Input label={`${this.translate('Primary Color Set')}:`} fluid style={{ textAlign: 'center', margin: '0 auto' }}>
                                                        <ChromePicker disableAlpha color={this.state.primaryPaletteColor} onChange={(color) => { this.setThemeColor('primaryPalette', color) }} style={{ width: '40%' }} />
                                                    </Form.Input>
                                                    {/* <pre>{JSON.stringify(this.state.swatches)}</pre> */}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {primaryPaletteSwatches && Object.keys(primaryPaletteSwatches).length > 0 &&
                                                <Grid.Row columns={12} centered>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['0']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['50']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['100']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['200']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['300']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['400']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['500']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['600']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['700']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['800']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['900']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${primaryPaletteSwatches['1000']}` }}></div></Grid.Column>
                                                </Grid.Row>
                                            }
                                        </Grid>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Form.Input label={`${this.translate('Text Color Set')}:`} fluid style={{ textAlign: 'center', margin: '0 auto' }}>
                                                        <ChromePicker disableAlpha color={this.state.textPaletteColor} onChange={(color) => { this.setThemeColor('textPalette', color) }} style={{ width: '40%' }} />
                                                    </Form.Input>
                                                    {/* <pre>{JSON.stringify(this.state.swatches)}</pre> */}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {textPaletteSwatches && Object.keys(textPaletteSwatches).length > 0 &&
                                                <Grid.Row columns={12} centered>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['0']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['50']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['100']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['200']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['300']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['400']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['500']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['600']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['700']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['800']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['900']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${textPaletteSwatches['1000']}` }}></div></Grid.Column>
                                                </Grid.Row>
                                            }
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>}
                                {this.props.options && this.props.options?.layout && <Grid.Row>
                                    <Grid.Column>
                                        <h3>{this.translate('Layout')}</h3>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column columns={1}>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <CardGroup centered>
                                                        {this.prepareLayoutCards()}
                                                    </CardGroup>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>}
                                {this.props.options && <Grid.Row>
                                    <Grid.Column>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Button positive content="Save" onClick={() => this.save()}></Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>}
                            </Grid>
                        </Form>
                    </Segment>
                </div>
            </WebsiteSettingsTemplate>
        );
    }
    setThemeColor(baseKey: string, color: any) {
        let swatches = themingUtils.createSwatches(color.hex);
        this.setState({
            [(`${baseKey}Color`)]: color.hex.toUpperCase(),
            [`${baseKey}Swatches`]: swatches,
        })
    }
}
