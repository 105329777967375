// import React from "react";
// import { Dropdown, Menu } from "semantic-ui-react";
import { IControl } from "../../factories/ControlsEnabledRouteMenuItemFactory";
import './controls.css'

export const controls: IControl[] = [
    // {
    //     placementId: "inbox",
    //     control: ({ active, path, position, header, content }) => {
    //         return [
    //             <Menu.Menu position={position} className="create-button">
    //                 <Dropdown icon="add" button item>
    //                     <Dropdown.Menu>
    //                         <Dropdown.Item text="Blog post" href={"/posts/create"}/>
    //                         <Dropdown.Item text="Section" href={"/sections/create"}/>
    //                     </Dropdown.Menu>
    //                 </Dropdown>
    //             </Menu.Menu>,
    //             <Menu.Menu>
    //                 <Dropdown 
    //                     item
    //                     options={[{value: "dreamship", key: "dreamship", text: "Dreamship"}, {value: "rfit", key: "rfit", text: "RFit"}]}
    //                     value="dreamship"
    //                 />
    //             </Menu.Menu>,
    //             <Menu.Item as='a' active={active} href={path} header={header}>{content}</Menu.Item>
    //         ]
    //     },
    // },
]