import React, { PureComponent } from "react";
import { Form } from "semantic-ui-react";
import Toggle from 'react-toggle'
import "react-toggle/style.css"

export interface IToggleInputProps {
    defaultValue?: boolean;
    onChange?: (e, value) => any;
    label: string;
    name: string;
}

export interface IToggleInputState {
    value?: boolean
}

export default class ToggleInput extends PureComponent<IToggleInputProps, IToggleInputState> {
    state = {
        value: false,
    };

    static defaultProps = {
        options: [],
    }

    handleChange(e) {
        const value = !this.state.value;
        this.setState({value}, () => {
            this.props.onChange && this.props.onChange(e, { name: this.props.name, value });
        });
    }

    componentDidMount() {
        this.setState({
            value: this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.defaultValue,
            });
        }
    }

    render() {
        return (
            <Form.Input label={this.props.label} fluid>
                <Toggle
                    fluid
                    name={this.props.name}
                    defaultChecked={this.props.defaultValue}
                    onChange={(e) => this.handleChange(e)}
                    checked={this.state.value} 
                />
            </Form.Input>
        );
    }
}