import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Form,
    TextArea,
} from "semantic-ui-react";
import SettingsTemplate from "../SettingsTemplate";
import { IApplicationConfigurationSettingsPageProps } from "./ApplicationConfigurationSettingsPage";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import ThemingUtils from "../../../../utilities/Theming/ThemingUtils";
import ProgressBar from 'react-customizable-progressbar'
import "./styles.css";

const commonUtils = new CommonUtils();
const themingUtils = new ThemingUtils();

export interface IAccountSettingsDesktopPageProps extends IApplicationConfigurationSettingsPageProps {
    onChange: (path: string) => any,
    localizationService: any,
}

export interface IAccountSettingsDesktopPageState {
    open: boolean;
    name: string;
    disableSubmit: boolean;
    checked: boolean;
    accountEmailChecked: boolean;
    color: any;
    swatches: any[];
    descriptionLength: number;
}

export default class DesktopPage extends PureComponent<
    IAccountSettingsDesktopPageProps,
    IAccountSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        disableSubmit: true,
        checked: false,
        accountEmailChecked: false,
        color: {},
        swatches: [],
        descriptionLength: 0,
    };

    protected setOpen(open, name) {
        this.setState({ open, name: name });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    private updateDescriptionLength(description:string) {
        const progress = [

        ]
        
        this.setState({ descriptionLength: description.length });
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    getLanguage() {
        return this.props.localizationService.getLanguage();
    }

    render() {
        const { swatches } = this.state;
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="site" localizationService={this.props.localizationService}>
                <h2>{this.translate('Site Configuration')}</h2>
                <Segment>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>{this.translate('General Information')}</h3>
                                    <Grid>
                                        <Grid.Row columns={1}>
                                            <Grid.Column><Form.Input label={`${this.translate('Application Name')}:`} fluid defaultValue='Dreamship Solutions' /></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={1}>
                                            <Grid.Column><Form.Input label={`${this.translate('Application Domain')}:`} fluid defaultValue='https://dreamshipsolutions.com' /></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <label
                                                    style={{ "display": "block", "margin": "0 0 0.28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": 700, "textTransform": "none" }}
                                                >
                                                    {`${this.translate('Application Description')}:`}
                                                </label>
                                                <TextArea fluid defaultValue='Dreamship Solutions is an amazing place. Sit back and relax.' onChange={(e) => { this.updateDescriptionLength(e.target.value) }}></TextArea>
                                                <div style={{ display: 'flex', float: 'right' }}>
                                                    <ProgressBar
                                                        radius={10}
                                                        progress={this.state.descriptionLength}
                                                        steps={255}
                                                        strokeWidth={4}
                                                        strokeColor="#5c7dcd"
                                                        trackStrokeWidth={5}
                                                        pointerRadius={1}
                                                        pointerStrokeWidth={2}
                                                        pointerStrokeColor="#5c7dcd"
                                                    />
                                                    <p style={{ marginTop: '5px', marginLeft: '5px' }}>{this.state.descriptionLength} / 255</p>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <label style={{ "display": "block", "margin": "0 0 0.28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": 700, "textTransform": "none" }}>{`${this.translate('Preview')}:`}</label>
                                                <div className="google-preview">
                                                    <div className="google-preview-title">
                                                        Dreamship Solutions
                                                    </div>
                                                    <div className="google-preview-link">
                                                        https://dreamshipsolutions.com
                                                    </div>
                                                    <div className="google-preview-description">
                                                        Dreamship Solutions is an amazing place. Sit back and relax.
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </SettingsTemplate>
        );
    }
    setThemeColor(color: any) {
        let swatches = themingUtils.createSwatches(color.hex);
        this.setState({ color, swatches })
    }
}
