import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../../LocalizedPage';
import MobilePage from "./MobilePage";
import Component from "../../../layouts/component";

export interface IAccount {
    name: string;
}
export interface ICustomersPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    breadcrumbLevels: any;
    item?: IAccount;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
}

export default class CustomersPage extends PureComponent<ICustomersPageProps> {
    render() {
        const { createRoutes, items, item, onChange, onSave, Gatekeeper, onDoubleClick, breadcrumbLevels } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage items={items} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} name={item && item.name}
                        onChange={onChange}
                        onSave={onSave} />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} />
                </Component>
            </Page>
        );
    }
}
