import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import SubscriptionsPane from '../../components/pages/accounts/CustomerOverviewPage/panes/SubscriptionsPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountSubscriptionsController extends LocalizedController {
    private repository: Repository;
    private subscriptionRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
        subscriptions: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, subscriptionRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.subscriptionRepository = subscriptionRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get(subscriptions: any[]) {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/subscriptions`);
        } catch (e) {
            return [];
        }

        items.map(item => {
            const subscription = subscriptions.find(i => i.id === item.subscriptionId);
            item.name = subscription.name;
            item.description = subscription.description;

            return item;
        })

        return items;
    }

    async componentDidMount() {
        let subscriptions = [];

        try {
            subscriptions = await this.getSubscriptions();
        } catch (e) {
            console.error(e)
        }

        let items = await this.get(subscriptions);

        this.setState({
            items,
            subscriptions,
        })
    }

    async getSubscriptions() {
        let items;

        try {
            items = await this.subscriptionRepository.get();
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleCreateAccountSubscription(data: any) {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.createByPath(data, `accounts/${this.params?.id}/subscriptions`);
        } catch (e) {
            console.error(e)
            throw Error("Failed to create the subscription. Please try again.");
        }

        return item;
    }

    async refreshItems() {
        try {
            let items = await this.get(this.state.subscriptions);

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    async handleChooseSubscription(data: any) {
        let items = [];

        try {
            items = await this.subscriptionRepository.getByPath(`subscriptions/${data?.id}/terms`)
        } catch (e) {
            console.error(e)
            throw Error("Failed to get subscription terms. Please try again.");
        }

        return items;
    }

    render() {
        const { items, subscriptions } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();
        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        const id = this.params?.id;

        return this.prepare(
            <Gatekeeper
                name="account-subscription-management"
                or={["account-subscription-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <SubscriptionsPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    subscriptions={subscriptions}
                    onCreateAccountSubscription={async (data: any) => this.handleCreateAccountSubscription(data)}
                    onChooseSubscription={async (data: any) => this.handleChooseSubscription(data)}
                    onModalClose={async () => this.refreshItems()}
                    id={id}
                />
            </Gatekeeper>
        )
    }
}