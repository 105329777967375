import React from 'react';
import OrderPage from '../../components/pages/orders/OrderPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import CommonUtils from '../../utilities/Common/CommonUtils';
import DateUtils from '../../utilities/Date/DateUtils';

const commonUtils = new CommonUtils()
const dateUtils = new DateUtils()

export default class OrderController extends LocalizedController {
    private repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        orders: {},
        countdownDate: Date.now() + 10000,
        hasNewOrders: false,
        exceptions: [],
        pauseButtonMap: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        // let items: any[] = [];

        // try {
        //     items = await this.repository.getByPath('orders?sort=dueDate:desc');
        // } catch (e) {
        //     console.error(e)
        //     return [];
        // }
        // return items;
    }

    private async updatePrice(data: any) {
        // try {
        //     await this.repository.update(data);
        //     await this.fetchAll();
        // } catch (e) {
        //     console.error(e);
        // }

    }

    async componentWillMount() {
        // await this.fetchAll();
    }

    private async fetchAll() {
        // let { countdownDate, hasNewOrders, orders } = await this.getAll();
        // let exceptions = await this.getAvailabilities();
        // let pauseButtonMap = await this.generateAvailabilitiesPauseMap();

        // this.setState({
        //     countdownDate,
        //     hasNewOrders,
        //     orders,
        //     exceptions,
        //     pauseButtonMap,
        // })
    }

    private async getAvailabilities() {
        let storefronts;
        let exceptions = [];
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                exceptions = await this.repository.getByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions`);
                exceptions = exceptions.filter((exception: any) => 'holiday' !== exception.type)
            }
        } catch (e) {
            console.error('Issue with storefronts:', e);
        }
        return exceptions;
    }

    private generateText(time) {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        let value = { hours, minutes }
        let text = '';
        let lang = JSON.parse(localStorage.getItem('lang')!)
        const hoursText = lang == 'en' ? 'hour' : 'heure';
        const hoursTextPlur = lang == 'en' ? 'hours' : 'heures';
        const and = lang == 'en' ? 'and' : 'et';

        if (hours > 0) {
            text = `${hours} ${commonUtils.plur(hoursText, hoursTextPlur, hours)} ${minutes > 0 ? and + ' ' + minutes + ' ' + commonUtils.plur('minute', 'minutes', minutes) : ''}`;
        } else if (hours === 0) {
            text = `${minutes} ${commonUtils.plur('minute', 'minutes', minutes)}`
        }

        return { value, text };
    }

    private async generateAvailabilitiesPauseMap() {
        let storefronts, settings;
        let pauseButtonMap: any[] = [];
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                settings = storefronts[0].settings.orders.averageFulfillment || { unit: 'minutes', amount: 15 };
                if (settings.unit && 'minutes' === settings.unit) {
                    let res = this.generateText(settings.amount);
                    pauseButtonMap.push({
                        key: 1,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 2);
                    pauseButtonMap.push({
                        key: 2,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 2,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 4);
                    pauseButtonMap.push({
                        key: 4,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 4,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 8);
                    pauseButtonMap.push({
                        key: 8,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 8,
                        text: res.text,
                    });
                }
            }
        } catch (e) {
            console.error('Issue with settings:', e);
        }

        return pauseButtonMap;
    }

    private async setExceptions(data: any) {
        let storefronts;
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                data['storefrontId'] = storefronts[0].id;
                await this.repository.createByPath(data, `storefronts/${storefronts[0].id}/availabilities/exceptions`);
            }
        } catch (e) {
            console.error('Issue with creating an exception: ', e);
        }
        try {
            await this.fetchAll();
        } catch (e) {
            console.error('Issue with fetching everything:', e);
        }
    }

    private async resumeOrders() {
        let storefronts, exceptions;
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                exceptions = await this.repository.getByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions`);
                exceptions = exceptions.filter((exception: any) => 'holiday' !== exception.type)
                if (exceptions && exceptions.length) {
                    // assumption: only one exception at a given time
                    await this.repository.deleteByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions/${exceptions[0].id}`)
                }
            }
        } catch (e) {
            console.error('Issue with resuming orders', e);
        }

        try {
            await this.fetchAll();
        } catch (e) {
            console.error('Issue with fetching everything:', e);
        }
    }

    private async getAll() {
        // let orders = await this.get();

        // let today = orders.filter(order => dateUtils.isToday(new Date(order.dueDate)) && order.status !== 'cancelled' && order.status !== 'delivered')//.reverse();
        // let past = orders.filter(order => dateUtils.isPast(new Date(order.dueDate)) || order.status === 'cancelled' || order.status === 'delivered')//.reverse();

        // let future = orders.filter(order => dateUtils.isFuture(new Date(order.dueDate)) && order.status !== 'cancelled' && order.status !== 'delivered')//.reverse();
        // let result = {
        //     today,
        //     past,
        //     future,
        // };
        // let hasNewOrders = (
        //     this.state.orders?.['today'] && this.state.orders?.['today'].length < today.length
        // )

        // return {
        //     countdownDate: Date.now() + 60000,
        //     hasNewOrders,
        //     orders: result,
        // };
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/store-management/orders/${id}`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    private async getInvoice(orderId: string) {
        let orderItems;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            orderItems = await this.repository.getOneByPath(`orders/${orderId}/items`);
        } catch (e) {
            console.error(e)
            return [];
        }

        const licensesPromises = orderItems.map(async (item) => {
            try {
                let contents = await this.repository.getByPath(`products/${item.item.id}/contents`);
                if (!contents || !contents.length) {
                    contents = await this.repository.getByPath(`definitions/${item.item.definitionId}/contents`);
                    if (contents) {
                        let content = contents.filter((content) => content.language == JSON.parse(localStorage.getItem('lang')!));
                        item.item.title = content.title;
                        item.item.description = content.description;
                    }
                } else {
                    let content = contents.filter((content) => content.language == JSON.parse(localStorage.getItem('lang')!))[0];
                    item.item['title'] = content.title;
                    item.item['description'] = content.description;
                }
            } catch (e) {
                console.error(e);
            }
            return item;
        });

        orderItems = await Promise.all(licensesPromises);

        return orderItems;
    }

    private async getInvoiceInformation(invoiceId: string) {
        let invoice;
        let contact;
        let shipping;

        try {
            invoice = await this.repository.getByPath(`invoices/${invoiceId}`);
        } catch (e) {
            console.error(e);
        }

        try {
            contact = await this.repository.getByPath(`invoices/${invoiceId}/contact`);
        } catch (e) {
            console.error(e);
        }

        try {
            shipping = await this.repository.getByPath(`invoices/${invoiceId}/shipping`);
        } catch (e) {
            console.error(e);
        }

        return { invoice, contact, shipping };
    }

    private async updateStatus(data: any[]) {
        for (let index = 0; index < data.length; index++) {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                await this.repository.updateByPath(data[index], `orders/${this.params?.id}`);
            } catch (e) {
                console.error(e);
            }

            try {
                await this.fetchAll();
            } catch (e) {
                console.error(e);
            }
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Orders'), value: undefined },
        ]
    }

    preparePanes() {
        const UserController = this.repository.getController();

        return [
            {
                menuItem: "Orders",
                render: () => (
                    <>
                        {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                        {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                        <UserController params={this.params} location={this.location} history={this.history} />
                    </>
                ),
            },
        ]
    }

    render() {
        const { orders, exceptions, pauseButtonMap } = this.state;

        let countdownDate = exceptions.length > 0 ? new Date(exceptions[0]['endDate']).getTime() + 90000 : this.state.countdownDate;

        const Gatekeeper = this.gatekeeperFactory.create();

        const panes = this.preparePanes();

        return this.prepare(
            <OrderPage
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onPathChange={(path) => this.changeSettings(path)}
                panes={panes}
            />
        )
    }
}