import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../../LocalizedPage';
import Component from "../../../layouts/component";

export interface ITemplatesPageProps {
    createRoutes?: Function;
    createTemplate: (data: any) => any;
    Gatekeeper: any;
    items: any[];
    breadcrumbLevels: any[];
}

export default class TemplatesPage extends PureComponent<ITemplatesPageProps> {
    render() {
        const { createRoutes, Gatekeeper, items, breadcrumbLevels, createTemplate } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage Gatekeeper={Gatekeeper} items={items} handleCreateTemplate={createTemplate} />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
