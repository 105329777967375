import React from 'react';
import { PasswordResetPage } from '../components/pages';
import { ApiProvider } from '../providers';
import { AuthenticationService } from '../services';
import LocalizedController from './LocalizedController';

export default class PasswordResetController extends LocalizedController {
    state = {}
    protected authenticationService: AuthenticationService;
    protected passwordResetApiProvider: ApiProvider;

    constructor({ args: [router, routeMenuItemFactory, localizationService, authenticationService, passwordResetApiProvider], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.authenticationService = authenticationService;
        this.passwordResetApiProvider = passwordResetApiProvider;
    }

    async handleSubmit(data) {
        try {
            await this.passwordResetApiProvider.post(data);
        } catch(e) {
            throw e;
        }

        this.router.redirect('/login');
    }

    getSearchParameters() {
        const params = new URLSearchParams(this.router.getQueryParameters());
        return Object.fromEntries(params);
    }

    render() {
        const { token } = this.getSearchParameters() as any;

        return this.prepare(
            <PasswordResetPage 
                token={token}
                onSubmit={async (data) => this.handleSubmit(data)}
            />
        )
    }
}