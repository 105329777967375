import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";
import LocalizedPage from '../LocalizedPage';
import Component from "../../layouts/component";

export interface ICartsPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    breadcrumbLevels: any;
}

export default class CartsPage extends PureComponent<ICartsPageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, breadcrumbLevels } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage items={items} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} />
                    {/* <MobilePage items={items} Gatekeeper={Gatekeeper} toggleFeatureActive={toggleFeatureActive} toastConfig={toastConfig} delayCount={delayCount} featureUpdated={featureUpdated} /> */}
                </Component>
            </Page>
        );
    }
}
