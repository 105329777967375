import React, { PureComponent } from "react";
import {
    Header,
    Divider,
    Card,
    Label,
    Icon,
} from "semantic-ui-react";
import SettingsTemplate from "../SettingsTemplate";
import { IResourceSettingsPageProps } from "./ResourceSettingsPage";
import "./styles.css";

export interface IResourceSettingsDesktopPageProps extends IResourceSettingsPageProps {
    onChange: (path: string) => any;
    Gatekeeper: any;
    items: any[];
}

export interface IResourceSettingsDesktopPageState {
    groupModalOpen: boolean;
    selectedGroupName: string;
}

export default class DesktopPage extends PureComponent<
    IResourceSettingsDesktopPageProps,
    IResourceSettingsDesktopPageState
> {
    state = {
        groupModalOpen: false,
        selectedGroupName: "",
    };

    protected handleItemClick(name) {
        this.props.onChange(name)
    }

    protected setGroupModalOpen(groupModalOpen, selectedGroupName) {
        this.setState({ groupModalOpen, selectedGroupName });
    }

    protected renderResource(item) {
        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>{item?.name}</Card.Header>
                    <Card.Description>
                        <Card.Meta><Icon name="key" />{' '}{item?.path}</Card.Meta>
                        <Card.Meta><Icon name="server" />{' '}{item?.host}</Card.Meta>
                    </Card.Description>
                    <Divider />
                    <Card.Content>
                        <Header sub>Available permissions</Header>
                        {item.permissions.map(permission => <Label color="red" content={permission.verb} />)}
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="resources">
                <h2>Resources</h2>
                <Gatekeeper name="resource-view">
                    <Card.Group>
                        {this.props.items.map(item => this.renderResource(item))}
                    </Card.Group>
                </Gatekeeper>
            </SettingsTemplate>
        );
    }
}
