import {
    ApiProvider,
    LocalStorageProvider,
    LocalizationProvider,
    CookieProvider,
} from '../../providers';

export default {
    "provider.localstorage": {
        "class": LocalStorageProvider,
        "constructor": [],
        "functions": [],
    },
    "provider.localization": {
        "class": LocalizationProvider,
        "constructor": [
            { "type": "parameter", "key": "locales" },
        ],
        "functions": [],
    },
    "provider.api.users": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "usersApi" },
        ],
        "functions": [],
    },
    "provider.api.sections": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "sectionsApi" },
        ],
        "functions": [],
    },
    "provider.api.pages": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "pagesApi" },
        ],
        "functions": [],
    },
    "provider.api.applicationTemplates": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "applicationTemplatesApi" },
        ],
        "functions": [],
    },
    "provider.api.taxes": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "taxesApi" },
        ],
        "functions": [],
    },
    "provider.api.posts": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "postsApi" },
        ],
        "functions": [],
    },
    "provider.api.productdefinitions": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "productDefinitionsApi" },
        ],
        "functions": [],
    },
    "provider.api.productattributes": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "productAttributesApi" },
        ],
        "functions": [],
    },
    "provider.api.products": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "productsApi" },
        ],
        "functions": [],
    },
    "provider.api.productcatalogs": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "productCatalogsApi" },
        ],
        "functions": [],
    },
    "provider.api.messages": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "messagesApi" },
        ],
        "functions": [],
    },
    "provider.api.activations": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "activationsApi" },
        ],
        "functions": [],
    },
    "provider.api.authentication": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "authenticationApi" },
        ],
        "functions": [],
    },
    "provider.api.applications": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "applicationsApi" },
        ],
        "functions": [],
    },
    "provider.api.archetypes": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "archetypesApi" },
        ],
        "functions": [],
    },
    "provider.api.components": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "componentsApi" },
        ],
        "functions": [],
    },
    "provider.api.accounts": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "accountsApi" },
        ],
        "functions": [],
    },
    "provider.api.features": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "featuresApi" },
        ],
        "functions": [],
    },
    "provider.api.licenses": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "licensesApi" },
        ],
        "functions": [],
    },
    "provider.api.apikeys": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "apiKeysApi" },
        ],
        "functions": [],
    },
    "provider.api.resources": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "resourcesApi" },
        ],
        "functions": [],
    },
    "provider.api.subscriptions": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "subscriptionsApi" },
        ],
        "functions": [],
    },
    "provider.api.permissions": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "permissionsApi" },
        ],
        "functions": [],
    },
    "provider.api.groups": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "groupsApi" },
        ],
        "functions": [],
    },
    "provider.cookie": {
        "class": CookieProvider,
        "constructor": [],
        "functions": [],
    },
    "provider.api.passwordreset": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "passwordResetApi" },
        ],
        "functions": [],
    },
    "provider.api.websites": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "websitesApi" },
        ],
        "functions": [],
    },
    "provider.api.layouts": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "layoutsApi" },
        ],
        "functions": [],
    },
    "provider.api.sectionlayouts": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "sectionLayoutsApi" },
        ],
        "functions": [],
    },
    "provider.api.contents": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "contentsApi" },
        ],
        "functions": [],
    },
    "provider.api.events": {
        "class": ApiProvider,
        "constructor": [
            { "type": "parameter", "key": "eventsApi" },
        ],
        "functions": [],
    },
};