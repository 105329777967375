import React, { PureComponent } from 'react';
import ReactDOM from "react-dom";
import Editor from "@monaco-editor/react";
import { beauty } from 'css-beauty';

export interface ICSSEditorProps {
    code: any;
}

export default class CSSEditor extends PureComponent<ICSSEditorProps> {
    editor = null;

    state = {
        isEditorReady: false
    };

    handleEditorDidMount = (_, editor) => {
        this.editor = editor;
        this.setState({
            isEditorReady: true
        });
    }

    handleShowEditorInstance = _ => {
        console.log('Log ::: Editor Instance ::: ', this.editor);
    }

    private setCode(code: any) {
        this.setState({ code });
    }
    render() {
        return (
            <Editor
                height="25vh"
                defaultLanguage="python"
                defaultValue={beauty(this.props.code)}
                onChange={(value) => this.setCode(value)}
            />
        );
    }
}