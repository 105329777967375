import React from 'react';
import AccountSubscriptionPage from '../../components/pages/accounts/AccountSubscriptionPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class AccountSubscriptionsNewController extends LocalizedController {
    private repository: Repository;
    private subscriptionRepository: Repository;
    private licenseRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
        subscriptions: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, subscriptionRepository, licenseRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.subscriptionRepository = subscriptionRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.licenseRepository = licenseRepository;
    }

    private async get(subscriptions: any[]) {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`accounts/${this.params?.id}/subscriptions`);
        } catch (e) {
            return [];
        }

        items.map(item => {
            const subscription = subscriptions.find(i => i.id === item.subscriptionId);
            item.name = subscription.name;
            item.description = subscription.description;

            return item;
        })

        return items;
    }

    async componentDidMount() {
        let subscriptions = [];

        try {
            subscriptions = await this.getSubscriptions();
        } catch (e) {
            console.error(e)
        }

        let items = await this.get(subscriptions);

        this.setState({
            items,
            subscriptions,
        })
    }

    async getSubscriptions() {
        let items;

        try {
            items = await this.subscriptionRepository.get();
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleCreateAccountSubscription(data: any) {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.createByPath(data, `accounts/${this.params?.id}/subscriptions`);
        } catch (e) {
            console.error(e)
            throw Error("Failed to create the subscription. Please try again.");
        }

        return item;
    }

    async refreshItems() {
        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        this.router.redirect(`/accounts/${this.params?.id}`)
    }

    async handleChooseSubscription(data: any) {
        let items = [];
        try {
            items = await this.subscriptionRepository.getByPath(`subscriptions/${data?.id}/terms`)
        } catch (e) {
            console.error(e)
            throw Error("Failed to get subscription terms. Please try again.");
        }
        return items;
    }

    async handleGetSubscriptionLicenses(data: any) {
        let items = [];

        try {
            items = await this.subscriptionRepository.getByPath(`subscriptions/${data?.id}/licenses`)
        } catch (e) {
            console.error(e)
            throw Error("Failed to get licenses for this subscription. Please try again.");
        }

        return items;
    }

    async handleGetSubscriptionLicensesFeatures(data: any) {
        let items = [];

        try {
            items = await this.licenseRepository.getByPath(`licenses/${data?.id}/features`)
        } catch (e) {
            console.error(e)
            throw Error("Failed to get licenses for this subscription. Please try again.");
        }

        return items;
    }

    render() {
        const { items, subscriptions } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();
        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        const id = this.params?.id;

        return this.prepare(
            <AccountSubscriptionPage
                Gatekeeper={Gatekeeper}
                subscriptions={subscriptions}
                items={subscriptions}
                onCreateAccountSubscription={async (data: any) => this.handleCreateAccountSubscription(data)}
                onChooseSubscription={async (data: any) => this.handleChooseSubscription(data)}
                subscriptionLicenses={async (data: any) => this.handleGetSubscriptionLicenses(data)}
                subscriptionLicensesFeatures={async (data: any) => this.handleGetSubscriptionLicensesFeatures(data)}
                onAssociationComplete={() => this.refreshItems()}
                id={id}
            />
        )
    }
}