import {
    CustomizationRepository,
    LocalizationRepository,
} from '../../repositories';
import BasicRepository from '../../repositories/BasicRepository';

export default {
    "repository.localization": {
        "class": LocalizationRepository,
        "constructor": [
            { "type": "service", "key": "provider.localization" },
        ],
        "functions": [],
    },
    "repository.customization": {
        "class": CustomizationRepository,
        "constructor": [
            { "type": "service", "key": "provider.localstorage" },
        ],
        "functions": [],
    },
    "repository.users": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.users" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "usersResource" },
            { "type": "parameter", "key": "usersOptions" },
        ],
        "functions": [],
    },
    "repository.sections": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.sections" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "sectionsResource" },
            { "type": "parameter", "key": "sectionsOptions" },
        ],
        "functions": [],
    },
    "repository.posts": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.posts" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "postsResource" },
            { "type": "parameter", "key": "postsOptions" },
        ],
        "functions": [],
    },
    "repository.productsattributes": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.productattributes" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "productAttributesResource" },
            { "type": "parameter", "key": "productAttributessOptions" },
        ],
        "functions": [],
    },
    "repository.productdefinitions": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.productdefinitions" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "productDefinitionsResource" },
            { "type": "parameter", "key": "productDefinitionsOptions" },
        ],
        "functions": [],
    },
    "repository.products": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.products" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "productsResource" },
            { "type": "parameter", "key": "productsOptions" },
        ],
        "functions": [],
    },
    "repository.productcatalogs": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.productcatalogs" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "productCatalogsResource" },
            { "type": "parameter", "key": "productCatalogsOptions" },
        ],
        "functions": [],
    },
    "repository.carts": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.productcatalogs" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "cartsResource" },
            { "type": "parameter", "key": "cartsOptions" },
        ],
        "functions": [],
    },
    "repository.licenses": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.licenses" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "licensesResource" },
            { "type": "parameter", "key": "licensesOptions" },
        ],
        "functions": [],
    },
    "repository.apikeys": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.apikeys" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "apiKeysResource" },
            { "type": "parameter", "key": "apiKeysOptions" },
        ],
        "functions": [],
    },
    "repository.resources": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.resources" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "resourcesResource" },
            { "type": "parameter", "key": "resourcesOptions" },
        ],
        "functions": [],
    },
    "repository.permissions": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.permissions" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "permissionsResource" },
            { "type": "parameter", "key": "permissionsOptions" },
        ],
        "functions": [],
    },
    "repository.groups": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.groups" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "groupsResource" },
            { "type": "parameter", "key": "groupsOptions" },
        ],
        "functions": [],
    },
    "repository.messages": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.messages" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "messagesResource" },
            { "type": "parameter", "key": "messagesOptions" },
        ],
        "functions": [],
    },
    "repository.applications": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.applications" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "applicationsResource" },
            { "type": "parameter", "key": "applicationsOptions" },
        ],
        "functions": [],
    },
    "repository.archetypes": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.archetypes" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "archetypesResource" },
            { "type": "parameter", "key": "archetypesOptions" },
        ],
        "functions": [],
    },
    "repository.components": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.components" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "componentsResource" },
            { "type": "parameter", "key": "componentsOptions" },
        ],
        "functions": [],
    },
    "repository.accounts": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.accounts" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "accountsResource" },
            { "type": "parameter", "key": "accountsOptions" },
        ],
        "functions": [],
    },
    "repository.features": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.features" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "featuresResource" },
            { "type": "parameter", "key": "featuresOptions" },
        ],
        "functions": [],
    },
    "repository.subscriptions": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.subscriptions" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "subscriptionsResource" },
            { "type": "parameter", "key": "subscriptionsOptions" },
        ],
        "functions": [],
    },
    "repository.pages": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.pages" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "pagesResource" },
            { "type": "parameter", "key": "pagesOptions" },
        ],
        "functions": [],
    },
    "repository.applicationtemplates": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.applicationTemplates" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "applicationTemplatesResource" },
            { "type": "parameter", "key": "applicationTemplatesOptions" },
        ],
        "functions": [],
    },
    "repository.taxes": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.taxes" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "taxesResource" },
            { "type": "parameter", "key": "taxesOptions" },
        ],
        "functions": [],
    },
    "repository.websites": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.websites" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "websitesResource" },
            { "type": "parameter", "key": "websitesOptions" },
        ],
        "functions": [],
    },
    "repository.layouts": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.layouts" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "layoutsResource" },
            { "type": "parameter", "key": "layoutsOptions" },
        ],
        "functions": [],
    },
    "repository.sectionlayouts": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.sectionlayouts" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "sectionLayoutsResource" },
            { "type": "parameter", "key": "sectionLayoutsOptions" },
        ],
        "functions": [],
    },
    "repository.contents": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.contents" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "contentsResource" },
            { "type": "parameter", "key": "contentsOptions" },
        ],
        "functions": [],
    },
    "repository.events": {
        "class": BasicRepository,
        "constructor": [
            { "type": "service", "key": "provider.api.events" },
            { "type": "service", "key": "factory.decorated_entity" },
            { "type": "service", "key": "factory.decorated_collection" },
            { "type": "service", "key": "service.security" },
            { "type": "parameter", "key": "eventsResource" },
            { "type": "parameter", "key": "eventsOptions" },
        ],
        "functions": [],
    },
};