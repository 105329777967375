import React from 'react';
import ProductDefinitionsEditPage from '../../../components/pages/productdefinitions/ProductDefinitionsEditPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductDefinitionCreateController extends LocalizedController {
    private repository: Repository;
    private taxesRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, taxesRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.taxesRepository = taxesRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async getAllTaxes() {
        let items;

        try {
            items = await this.taxesRepository.get();
        } catch (e) {
            return;
        }

        return items.map((item, index) => {
            return { key: index, text: item.name, value: item.name }
        })
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }


    private async handleSave(data: any) {
        let item;
        data.brand = 'La Bolerie'; // remove after Tony
        try {
            item = await this.repository.create(data);
            this.router.redirect(`/productdefinitions/${item.id}/attributes`);
        } catch (e) {
            throw e;
        }
    }

    // private generateProductSKUs(...args) {
    //     let r: any[] = [], max = args.length - 1;
    //     function helper(arr, i) {
    //         for (let j = 0, l = args[i].length; j < l; j++) {
    //             let a = arr.slice(0); // clone arr
    //             a.push(args[i][j]);
    //             if (i == max)
    //                 r.push(a);
    //             else
    //                 helper(a, i + 1);
    //         }
    //     }
    //     helper([], 0);
    //     return r;
    // }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 2, text: this.localizationService.translate('Create product definition'), value: undefined },
        ]
    }

    render() {
        const { item } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <></>
            // <ProductDefinitionsEditPage
            //     item={item}
            //     onChange={(key: string, value: any) => this.handleChange(key, value)}
            //     onSave={async (data: any) => this.handleSave(data)}
            //     Gatekeeper={Gatekeeper}
            //     localizationService={this.localizationService}
            //     breadcrumbLevels={this.generateBreadcrumbs()}
            // />
        )
    }
}