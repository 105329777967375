import React, { PureComponent } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

export interface IBulkPriceUpdateModalProps {
    isMobile: boolean;
    localizationService?: any;
    items: any[];
    updatePrices?: (data: any[]) => void;
}

export default class BulkPriceUpdateModal extends PureComponent<IBulkPriceUpdateModalProps> {

    static defaultProps = {
        isMobile: false,
        items: [],
    };

    state = {
        changeType: 0,
        amount: 0,
    }

    onClick() {
        const { changeType, amount } = this.state;
        const { items } = this.props;
        let results: any[] = [];
        results = items.map(item => {
            switch (changeType) {
                case 0:
                    item.price = Number(item.price || 0) + amount;
                    break;
                case 1:
                    item.price = Number(item.price || 0) - amount;
                    break;
                case 2:
                    item.price = amount;
                    break;
                case 3:
                    item.price += (Number(item.price || 0) * (amount / 100));
                    break;
                case 4:
                    item.price -= (Number(item.price || 0) * (amount / 100));
                    break;
                default:
                    break;
            }
            if (item.price < 0) { item.price = 0; }
            return item;
        })
        this.props.updatePrices && this.props.updatePrices(results);
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private handleChange(value) {
        this.setState({ changeType: value });
    }

    private handleAmountChange(value: string): void {
        this.setState({ amount: Number(value.replace(/[^0-9.]/g, '')) });
    }

    render() {
        const options = [
            { key: 0, text: this.translate('Increase by amount'), value: 0 },
            { key: 1, text: this.translate('Reduce by amount'), value: 1 },
            { key: 2, text: this.translate('Set a new price'), value: 2 },
            { key: 3, text: this.translate('Increase by percentage'), value: 3 },
            { key: 4, text: this.translate('Reduce by percentage'), value: 4 },
        ]
        return (
            <Form>
                <Form.Group>
                    <Form.Select
                        fluid
                        width={'12'}
                        label={this.translate('Select')}
                        options={options}
                        placeholder={this.translate('What change would you like to make?')}
                        onChange={(e, { value }) => this.handleChange(value)}
                        defaultValue={0}

                    />
                    <Form.Input
                        width={'4'}
                        icon={this.state.changeType > 2 ? this.translate('percent') : this.translate('dollar')}
                        iconPosition={this.state.changeType > 2 ? undefined : 'left'}
                        placeholder={'0.00'}
                        label={this.translate('Amount')}
                        onChange={(e, { value }) => this.handleAmountChange(value)}
                    />
                </Form.Group>
                <Message content={this.translate('You\'re changing the base price of the product. Prices of variants will be changed accordingly.')} className='regular' info />
                <Form.Group widths={'equal'}>
                    <Form.Button color='twitter' content={this.translate('Update price')} onClick={() => this.onClick()} />
                </Form.Group>
            </Form>
        );
    }
}
