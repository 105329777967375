import React, { PureComponent } from 'react';
import { Button, Card, CardContent, CardDescription, CardHeader, Form, Grid, Header, Icon, Label, Modal, Step } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../controls';
import { ProductDefinitionForm } from '../../../forms/products';
import ProductAttributeForm from '../../../forms/products/ProductAttributesForm/ProductAttributeForm';
import ProductForm from '../../../forms/products/ProductForm';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import PropertyAttributeForm from './modalforms/PropertyAttributeForm';
import SelectionAttributeForm from './modalforms/SelectionAttributeForm';
import './styles.css';

export interface IProductDesktopPageProps {
    title: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    createProperty: (type: string) => any;
    deleteProductAttribute: (id: any) => any;
    properties: any[];
    selections: any[];
    productDefinitionId: string;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps> {
    static defaultProps = {
        title: '',
        createProperty: () => { },
        deleteProductAttribute: () => { },
    };

    state = {
        openModal: false,
        openSelectionModal: false,
        attribute: undefined,
    }

    private displayModal(openModal: boolean = true, attribute: any = undefined) {
        this.setState({ openModal, attribute });
    }

    private displaySelectionModal(openSelectionModal: boolean = true, attribute: any = undefined) {
        this.setState({ openSelectionModal, attribute });
    }

    private createAttribute(type: string) {
        this.props.createProperty(type);
    }

    private preparePropertyAttributes() {
        const { properties } = this.props;
        let results = properties.map((property, index) => {
            const { name, options, id } = property;
            return (<Card fluid>
                <CardContent>
                    <CardHeader>{name}</CardHeader>
                    <CardDescription>{options instanceof Array ? options.join() : options}</CardDescription>
                </CardContent>
                <Card.Content extra>
                    <Button basic icon='edit' color='teal' onClick={() => this.displayModal(true, property)}>
                        Edit
                    </Button>
                    <Button icon='trash' color='red' onClick={() => { this.props.deleteProductAttribute(id) }}>
                        Delete
                    </Button>
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    private prepareOptionAttributes() {
        const { selections } = this.props;
        let results = selections.map((property) => {
            const { name, options, id } = property;
            let optionTags = options.map((option) => {
                return (<Label basic color='black' content={option} />)
            });

            return (<Card fluid>
                <CardContent>
                    <CardHeader>{property.name}</CardHeader>
                    <CardDescription>{optionTags}</CardDescription>
                </CardContent>
                <Card.Content extra>
                    <Button basic icon='edit' color='teal' onClick={() => this.displaySelectionModal(true, property)}>
                        Edit
                    </Button>
                    <Button icon='trash' color='red' onClick={() => { this.props.deleteProductAttribute(id) }}>
                        Delete
                    </Button>
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    save(attribute) {
        this.props.onSave(attribute);
        this.displayModal(false);
        this.displaySelectionModal(false);
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                >
                    <Modal open={this.state.openModal}>
                        <PropertyAttributeForm type='property' attribute={this.state.attribute} toggleModal={(display) => this.displayModal(display)} createAttribute={(attribute) => this.save(attribute)} />
                    </Modal>
                    <Modal open={this.state.openSelectionModal}>
                        <SelectionAttributeForm type='selection' attribute={this.state.attribute} toggleModal={(display) => this.displaySelectionModal(display)} createAttribute={(attribute) => this.save(attribute)} />
                    </Modal>
                    <div className="editor-container product-editor">
                        <div>
                            {/* <ReturnButton
                                href={'/products'}
                                itemType={'products'}
                            /> */}
                        </div>
                        <Step.Group widths={'8'}>
                            <Step>
                                <Icon name='info' />
                                <Step.Content>
                                    <Step.Title>General Information</Step.Title>
                                    <Step.Description>Define the basic information of your product.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step active>
                                <Icon name='tags' />
                                <Step.Content>
                                    <Step.Title>Attributes and Options</Step.Title>
                                    <Step.Description>Provide product attributes and different options.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step disabled>
                                <Icon name='dollar' />
                                <Step.Content>
                                    <Step.Title>Pricing and Shipping</Step.Title>
                                    <Step.Description>Provide pricing and shipping information.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step disabled>
                                <Icon name='checkmark box' />
                                <Step.Content>
                                    <Step.Title>Review and confirm product definition</Step.Title>
                                </Step.Content>
                            </Step>
                        </Step.Group>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label style={{
                                        display: 'block',
                                        margin: '0 0 .28571429rem 0',
                                        color: 'rgba(0,0,0,.87)',
                                        fontSize: '.92857143em',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}>{'Product Attributes'}</label>
                                    <Button color='green' icon={'plus'} content='Add an attribute' onClick={(event) => { event.preventDefault(); this.displayModal(); }} />

                                    {this.preparePropertyAttributes()}
                                </Grid.Column>
                                <Grid.Column>
                                    <label style={{
                                        display: 'block',
                                        margin: '0 0 .28571429rem 0',
                                        color: 'rgba(0,0,0,.87)',
                                        fontSize: '.92857143em',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}>{'Product Options'}</label>
                                    <Button color='green' icon={'plus'} content='Add a set of options' onClick={(event) => { event.preventDefault(); this.displaySelectionModal(); }} />
                                    {this.prepareOptionAttributes()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button.Group floated="right" className='form-right-toolbar-container'>
                                        <Button as='a' href={`/productdefinitions/${this.props.productDefinitionId}/edit`}>{'Back'}</Button>
                                        <Button as='a' href={`/productdefinitions/${this.props.productDefinitionId}/priceshipping`} positive>{'Save and Continue'}</Button>
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
