import React from "react";
import { PureComponent } from "react";
import { Button, Menu, Modal, Icon, Card } from "semantic-ui-react";
import { Repository } from "../../../repositories";
import { SecurityService } from "../../../services";
import './styles.css'

export interface IDeployButtonProps {
    repository: Repository;
    service: SecurityService;
    isMobile?: boolean;
}

export default class DeployButton extends PureComponent<IDeployButtonProps> {
    private repository: Repository;
    private service: SecurityService;

    state = {
        items: [],
        value: undefined,
        modalOpen: false,
    };

    constructor({ repository, service, ...props }) {
        super({ repository, service, ...props });
        this.repository = repository;
        this.service = service;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            if (403 === e.statusCode) {
                throw e;
            }

            return [];
        }

        return items;
    }

    async componentDidMount() {
    }


    renderMobileSwitcher(items: any[], isMobile: boolean) {
        // const appCards = items.map(({ id, name }) => {
        //     return (
        //         <Card fluid onClick={() => this.handleChange(id)}>
        //             <Card.Content>
        //                 <Card.Header>
        //                     {name}
        //                     {id === this.state.value &&
        //                         <span style={{ float: 'right' }}><Icon name='check' color='green' /></span>
        //                     }
        //                 </Card.Header>
        //             </Card.Content>
        //         </Card>
        //     )
        // })
        return (
            <Menu.Item>
                <Button color="blue" compact size="mini" content="Configure Website" as="a" href="/website-management"></Button>
            </Menu.Item>
        )
    }

    render() {
        const { items, value } = this.state;

        // if (!items.length || 1 === items.length) {
        //     return (
        //         <></>
        //     )
        // }

        // if (this.props.isMobile) {
        //     return (
        //         this.renderMobileSwitcher(items)
        //     )
        // }

        return <></> //this.renderMobileSwitcher(items, this.props.isMobile || false)

        // REVISIT
        // return (
        //     <Dropdown
        //         className="application-switcher"
        //         item
        //         onChange={(e, { value }) => this.handleChange(value)}
        //         options={this.prepareItems(items)}
        //         value={value}
        //     />
        // )
    }
}