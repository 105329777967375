import React from "react";
import { Button, Form } from "semantic-ui-react";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
// import "./style.css";

export interface IArchetypeSearchFormProps extends IStateAwareFormProps {
    name: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
}

export default class ArchetypeSearchForm extends StateAwareForm<IArchetypeSearchFormProps> {
    constructor(props) {
        super(props);
    }

    public static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            name: this.props.name,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                name: this.props.name,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <Form.Input
                    fluid
                    label={"Name"}
                    defaultValue={this.props.name}
                    name="name"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                />

                <Button.Group floated="right">
                    <Button disabled={loading}>Find</Button>
                </Button.Group>
            </Form>
        );
    }
}
