import React, { PureComponent } from "react";
import FeaturesLinkForm from "../../../../../forms/features/FeaturesLinkForm";
import { MobileSkeleton } from "../../../../../layouts/skeleton";
import "./styles.css";

export interface ILicenseMobilePageProps {
    name: string;
    type: string;
    level: string;
    features: any[];
    licenseFeatures: any[];
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    toastConfig: any;
}

export default class MobilePage extends PureComponent<ILicenseMobilePageProps> {
    static defaultProps = {
        name: "",
        type: "",
        level: "",
        features: undefined,
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const { Gatekeeper, features, licenseFeatures, toastConfig } = this.props;
        return (
            <MobileSkeleton>
                <Gatekeeper name="license-management">
                    <FeaturesLinkForm
                        features={features}
                        licenseFeatures={licenseFeatures}
                        onSubmit={(data) => this.props.onSave(data)}
                        toastConfig={toastConfig}
                        isMobile
                    />
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
