import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header } from "semantic-ui-react";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";
import AssociateArchetypeModal from "./AssociateArchetypeModal";

export interface IArchetypesPaneProps {
    items: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onLinkArchetype: (data: any) => Promise<any>;
    onUnlinkArchetype: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface IArchetypePaneState {
    modalOpen: boolean;
}

export default class ArchetypesPane extends PureComponent<IArchetypesPaneProps, IArchetypePaneState> {
    state: IArchetypePaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="archetype-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/archetypes/${item.id}/edit`}
                                />
                            }
                            content="Edit the archetype's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="subscription-archetype-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkArchetype(item)}
                                />
                            }
                            content="Archetypes can be associated to multiple subscriptions. Unassociating the archetype will remove the archetype's access from this subscription but will not delete or alter the archetype itself"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateArchetypeModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onLinkArchetype={async (data: any) => this.props.onLinkArchetype(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={4} textAlign={'center'}>
                    <Header>No associated archetypes!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return (<Table.Body>
            {!items.length ? this.renderNoContentMessage() : items}
        </Table.Body>)
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Header>
                {this.renderTableBody()}
                <Gatekeeper name="subscription-archetype-management">
                    <Table.Footer>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan={2}>
                            {this.renderModal(this.state.modalOpen, Gatekeeper)}
                            <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Associate Archetype</Button>
                        </Table.HeaderCell>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}