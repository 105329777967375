import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Form,
    CardGroup,
    Card,
    Image,
    CardDescription,
    CardMeta,
    Rating,
    Icon,
    IconGroup,
    Message,
    TextArea,
} from "semantic-ui-react";
import { CirclePicker } from 'react-color';
import SettingsTemplate from "../SettingsTemplate";
import { ILayoutThemeConfigurationSettingsPageProps } from "./LayoutThemeConfigurationSettingsPage";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import ThemingUtils from "../../../../utilities/Theming/ThemingUtils";
import "./styles.css";
// import { Message } from "../../../layouts/messages";

const commonUtils = new CommonUtils();
const themingUtils = new ThemingUtils();

export interface IAccountSettingsDesktopPageProps extends ILayoutThemeConfigurationSettingsPageProps {
    onChange: (path: string) => any,
    localizationService: any,
}

export interface IAccountSettingsDesktopPageState {
    open: boolean;
    name: string;
    disableSubmit: boolean;
    checked: boolean;
    accountEmailChecked: boolean;
    color: any;
    swatches: any;
}

export default class DesktopPage extends PureComponent<
    IAccountSettingsDesktopPageProps,
    IAccountSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        disableSubmit: true,
        checked: false,
        accountEmailChecked: false,
        color: {},
        swatches: {},
    };

    protected setOpen(open, name) {
        this.setState({ open, name: name });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    getLanguage() {
        return this.props.localizationService.getLanguage();
    }

    render() {
        const { swatches } = this.state;
        return (
            <>
                <Message
                    warning
                    content="Please note that changes to the theme and layout will only take effect on your next build."
                />
                <SettingsTemplate onChange={this.props.onChange} activeItem="theme" localizationService={this.props.localizationService}>
                    <h2>{this.translate('Layout and Theme')}</h2>
                    <Segment>
                        <Form>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h3>{this.translate('Theming')}</h3>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Form.Input label={`${this.translate('Primary Color')}:`} fluid style={{ textAlign: 'center', margin: '0 auto' }}>
                                                        {/* <SketchPicker  /> */}
                                                        <CirclePicker color={this.state.color} onChange={(color) => { this.setThemeColor(color) }} style={{ width: 'fit-content' }} />
                                                        {/* <pre>{JSON.stringify(this.state.color)}</pre> */}
                                                    </Form.Input></Grid.Column>
                                            </Grid.Row>
                                            {swatches && Object.keys(swatches).length > 0 &&
                                                <Grid.Row columns={12} centered>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['0']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['50']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['100']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['200']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['300']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['400']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['500']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['600']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['700']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['800']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['900']}` }}></div></Grid.Column>
                                                    <Grid.Column><div className={'swatch-color'} style={{ backgroundColor: `${swatches['1000']}` }}></div></Grid.Column>
                                                </Grid.Row>
                                            }
                                            {/* <TextArea value={JSON.stringify(swatches)} /> */}
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h3>{this.translate('Layout')}</h3>
                                        <Grid centered>
                                            <Grid.Row columns={1}>
                                                <Grid.Column columns={1}>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <CardGroup centered>
                                                        <Card link image>
                                                            <Image src="/images/templates/hotels.png.webp"></Image>
                                                            <Card.Content>
                                                                <Card.Header>
                                                                    Hotels
                                                                </Card.Header>
                                                                <CardMeta>
                                                                    <Rating rating={5} maxRating={5} />
                                                                </CardMeta>
                                                            </Card.Content>
                                                        </Card>
                                                        <Card link image>
                                                            <Image src="/images/templates/dashboard.jpg.webp"></Image>
                                                            <Card.Content>
                                                                <Card.Header>
                                                                    Dashboard
                                                                </Card.Header>
                                                                <CardMeta>
                                                                    <Rating rating={3} maxRating={5} />
                                                                </CardMeta>
                                                            </Card.Content>
                                                        </Card>
                                                        <Card link image>
                                                            <Image src="/images/templates/landing.png.webp"></Image>
                                                            <Card.Content>
                                                                <Card.Header>
                                                                    Dashboard
                                                                </Card.Header>
                                                                <CardMeta>
                                                                    <Rating rating={4} maxRating={5} />
                                                                    <div style={{ float: 'right' }}>
                                                                        <Icon name='check circle' color="blue" />
                                                                    </div>
                                                                </CardMeta>
                                                            </Card.Content>
                                                        </Card>
                                                        <Card link image>
                                                            <Image src="/images/templates/exchange.png.webp"></Image>
                                                            <Card.Content>
                                                                <Card.Header>
                                                                    Exchange
                                                                </Card.Header>
                                                                <CardMeta>
                                                                    <Rating rating={4} maxRating={5} />
                                                                    <div style={{ float: 'right' }}>
                                                                        {/* <Icon name='check circle' color="blue" /> */}
                                                                    </div>
                                                                </CardMeta>
                                                            </Card.Content>
                                                        </Card>
                                                    </CardGroup>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </SettingsTemplate>
            </>
        );
    }
    setThemeColor(color: any) {
        let swatches = themingUtils.createSwatches(color.hex);
        this.setState({ color, swatches })
    }
}
