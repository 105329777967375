import React, { PureComponent } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import './styles.css';

export interface ILocalizedCreateButtonProps extends ButtonProps {
    itemType?: string;
    attentionGrabber?: boolean;
    localizationService?: any;
}

export default class LocalizedCreateButton extends PureComponent<ILocalizedCreateButtonProps> {
    state = {
        showButtonLabel: false,
    };

    static defaultProps = {
        itemType: undefined,
        handleOnClick: () => { },
        as: 'a',
        icon: 'chevron left',
        color: 'green',
        attentionGrabber: false,
    };

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleOnHover(showButtonLabel: boolean) {
        this.setState({ showButtonLabel });
    }

    render() {
        return (
            <div className='create-item-button-container'>
                <Button
                    onMouseEnter={() => this.handleOnHover(true)}
                    onMouseLeave={() => this.handleOnHover(false)}
                    className={`create-item-button ${this.props.attentionGrabber ? 'is-active' : null}`}
                    icon="add"
                    content={
                        this.state.showButtonLabel && this.props.itemType ? this.translate(`Create a new ${this.props.itemType}`) : null
                    }
                    color={this.props.color}
                    disabled={this.props.disabled}
                    basic={this.props.basic}
                    as={this.props.as}
                    href={this.props.href}
                    circular
                />
            </div>
        );
    }
}
