import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Header, Card } from "semantic-ui-react";
import ScrollableTable from "../../../../../layouts/tables/ScrollableTable";
import CommonUtils from '../../../../../../utilities/Common/CommonUtils';

const commonUtils = new CommonUtils();

export interface IProductsDefinitionsPaneProps {
    items: any;
    Gatekeeper: any;
}

export interface IProductsDefinitionsPaneState {
}

export default class ProductsDefinitionsPane extends PureComponent<IProductsDefinitionsPaneProps, IProductsDefinitionsPaneState> {
    state: IProductsDefinitionsPaneState = {
    };

    private renderDefinitionCard() {
        const { items } = this.props;
        const Gatekeeper = this.props.Gatekeeper;

        if (!items) return <></>;
        return (<Card fluid>
            <Card.Content>
                <Card.Header>{items.title}</Card.Header>
                <Card.Meta>{commonUtils.renderCurrency(items.price)}</Card.Meta>
                <Card.Description>{items.content}</Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="right">
                <Gatekeeper name="productdefinition-management">
                    <Button color='green' icon='edit' content='Edit' as='a' href={`/products/${items.id}/edit`} />
                </Gatekeeper>
            </Card.Content>
        </Card>)
    }

    render() {
        return (
            this.renderDefinitionCard()
        );
    }
}