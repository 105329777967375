import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { BrandedEntryContent } from '../../layouts/sections';
import PasswordResetForm from '../../forms/PasswordResetForm';
import './styles.css';

export interface ILoginPageProps {
    onSubmit: (values: any) => any;
    token: string;
}

export default class DesktopPage extends PureComponent<ILoginPageProps> {
    render() {
        // const Picker = LocalizedComponent()(DesktopPageRender);
        const Picker = DesktopPageRender;
        return <Picker {...this.props} />;
    }
}

class DesktopPageRender extends PureComponent<ILoginPageProps> {
    render() {
        const { token, onSubmit } = this.props;

        return (
            <DesktopSkeleton>
                <div style={{ marginTop: '35px' }}>
                    <div className="negate-menu-padding">
                        <BrandedEntryContent className="password-reset-page" useFullLogo header="Reset Your Password" stacked={false}>
                            <PasswordResetForm token={token} onSubmit={onSubmit} />
                        </BrandedEntryContent>
                    </div>
                </div>
            </DesktopSkeleton>
        )
    }
}