import React from "react";
import { Form, Message, Button, Grid, ButtonGroup } from "semantic-ui-react";
import DeleteButton from "../../../controls/buttons/DeleteButton";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import MarkdownEditor from "../../../controls/MarkdownEditor";
import "./styles.css";
import MetadataEditor from "../../../controls/MetadataEditor";

const commonUtils = new CommonUtils();

export interface IProductSectionFormProps extends IStateAwareFormProps {
    title?: string;
    name: string;
    language: string;
    content: string;
    additional: {
        rows: {
            label: string;
            placeholder?: string;
        };
        columns: {
            label: string;
            placeholder?: string;
        };
        callToAction: {
            label: string;
            placeholder?: string;
        };
        callToActionLink: {
            label: string;
            placeholder?: string;
        };
        query: any;
    };
}

export default class ProductSectionForm extends StateAwareForm<IProductSectionFormProps> {
    componentDidMount() {
        super.setState({
            title: this.props.title,
            name: this.props.name,
            language: this.props.language,
            content: this.props.content,
            additional: this.props.additional,
            type: "product",
        });
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error} className={'section-form-container'}>
                <ButtonGroup floated="right">
                    <Button
                        color="teal"
                        disabled={loading}
                        floated="right"
                    >
                        Save
                    </Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="section"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </ButtonGroup>
                <div style={{ display: 'block', clear: 'both' }}>
                    <Grid>
                        <Grid.Column floated="left" width={11}>
                            <h2>General Information</h2>
                            <p>
                                This section manages the core information necessary for a section
                                to work.
                            </p>
                        </Grid.Column>
                    </Grid>
                </div>
                <br />
                <br />
                <Form.Input
                    fluid
                    label={"Title"}
                    placeholder="Title"
                    defaultValue={this.props.title}
                    name="title"
                    onChange={(e, { name, value }) => this.handleChange({ name, value })}
                    required
                />

                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        search
                        options={commonUtils.generateLanguagesMap()}
                        label={"Language"}
                        placeholder="Language"
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Select
                        fluid
                        options={[
                            { key: "type", text: "Product", value: "product" },
                        ]}
                        label={"Type"}
                        placeholder="Type"
                        value="product"
                        name="type"
                        disabled
                    />
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Input
                        width={'3'}
                        fluid
                        label={"Rows"}
                        placeholder="1"
                        defaultValue={this.props.additional.rows}
                        name="additional.rows"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    >
                        <input type='number' step={1} min={1} />
                    </Form.Input>

                    <Form.Input
                        width={'3'}
                        fluid
                        label={"Columns"}
                        placeholder="1"
                        defaultValue={this.props.additional.columns}
                        name="additional.columns"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    >
                        <input type='number' step={1} min={1} />
                    </Form.Input>

                    <Form.Input
                        fluid
                        label={"Call to action"}
                        placeholder="See More Products"
                        defaultValue={this.props.additional.callToAction}
                        name="additional.callToAction"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />

                    <Form.Input
                        fluid
                        label={"Call to action link"}
                        placeholder="https://example.com/all-products"
                        defaultValue={this.props.additional.callToActionLink}
                        name="additional.callToActionLink"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                    />
                </Form.Group>

                <label className={'mobile-post-content-header'}>
                    Filters
                </label>

                <MetadataEditor
                    name="additional.query"
                    defaultValue={this.props.additional.query || {}}
                    onChange={({ name, value }) => this.handleChange({ name, value })}
                    canAddNewValues={true}
                    canUpdateKeys={true}
                    eligibleKeys={[
                        {key: 'category', text: 'Category', value: 'category'},
                        {key: 'brand', text: 'Brand', value: 'brand'},
                    ]}
                />

                <label className={'mobile-post-content-header'}>
                    Section blurb
                </label>

                <div className='product-section-form-editor'>
                    <MarkdownEditor
                        value={this.props.content}
                        name="content"
                        onChange={({ name, value }) => this.handleChange({ name, value })}
                    />
                </div>

                <br />

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Section"
                />

                <Message
                    error
                    header="Could not save section"
                    content={error && error.toString()}
                />
            </Form>
        );
    }
}
