import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Button,
    Table,
    TableCell,
    TableRow,
    Label,
    List,
} from "semantic-ui-react";
import { IDeploymentsPageProps } from "./DeploymentsPage";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import ThemingUtils from "../../../utilities/Theming/ThemingUtils";
import { ScrollableTable } from "../../layouts/tables";
import WebsiteSettingsTemplate from "../siteconfiguration/WebsiteSettingsTemplate";
import "./styles.css";

const commonUtils = new CommonUtils();

export interface IDeploymentsDesktopPageProps extends IDeploymentsPageProps {
    onChange: (path: string) => any,
    onDeploy: () => any,
    onRefresh: () => any,
    localizationService: any,
    items: any[],
    hasProvisionned: boolean,
}

export interface IDeploymentsDesktopPageState {
}

export default class DesktopPage extends PureComponent<
    IDeploymentsDesktopPageProps,
    IDeploymentsDesktopPageState
> {
    state = {
    };

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    getLanguage() {
        return this.props.localizationService.getLanguage();
    }

    renderTableHeaders() {
        return <>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell><Button floated="right" icon='refresh' onClick={this.props.onRefresh}></Button></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Deployment Date</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        </>;
    }

    renderTableBody() {
        const { items } = this.props;
        let rows = items?.map((resource) => {
            return (
                <TableRow>
                    <TableCell>{commonUtils.renderDate(resource?.date)}</TableCell>
                    <TableCell>{Number.isNaN(resource?.timeElapsed) ? "-" : `${resource?.timeElapsed} seconds`}</TableCell>
                    <TableCell><Label color={resource?.state == 'error' ? 'red' : 'green'} content={resource?.state}></Label></TableCell>
                </TableRow>
            );
        });
        return <Table.Body>{rows}</Table.Body>;
    }

    render() {
        return (
            <WebsiteSettingsTemplate canDeploy={this.props.hasProvisionned} onChange={this.props.onChange} activeItem="deployments">
                <h3>{this.translate('Deployments')}</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid centered>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <List divided verticalAlign='middle' relaxed='very'>
                                            <List.Item className="deploy-item">
                                                <List.Content floated='right'>
                                                    <Button positive size="small" content="Deploy" onClick={this.props.onDeploy}></Button>
                                                </List.Content>
                                                <List.Content>
                                                    <List.Header>Deploy</List.Header>
                                                    <List.Description>Deploy all of your changes to your website.</List.Description>
                                                </List.Content>
                                            </List.Item>
                                        </List>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h3>{this.translate('Latest Deployments')}</h3>
                            <ScrollableTable className={'eight'}>
                                {this.renderTableHeaders()}
                                {this.renderTableBody()}
                            </ScrollableTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </WebsiteSettingsTemplate>
        );
    }
}
