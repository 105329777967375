import { env } from "process";
import React, { PureComponent } from "react";
import { Repository } from "../../../repositories";
import FeatureService from "../../../services/FeatureService";
import { SecurityService } from "../../../services";

export interface IGatekeeperProps {
    repository: Repository;
    service: FeatureService;
    name: string;
    unauthorizedComponent: any;
    loadingComponent: any;
    or?: string[];
    and?: string[];
    hideLoadingComponent?: boolean;
}

export interface IGateKeeperState {
    visible: boolean;
    loading: boolean;
}

export default class Gatekeeper extends PureComponent<IGatekeeperProps, IGateKeeperState> {
    private repository: Repository;
    private service: FeatureService;
    private securityService: SecurityService;
    private delayCount = parseInt(process.env.REACT_APP_CLIENT_DISPLAY_LOOADING_SPINNER_MS || '1000');

    static defaultProps = {
        unauthorizedComponent: <></>,
        loadingComponent: null,
        hideLoadingComponent: false,
    }

    public state: IGateKeeperState = {
        visible: false,
        loading: true,
    };

    constructor(props) {
        super(props);
        this.repository = props.repository;
        this.service = props.service;
        this.securityService = props.securityService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.find({ active: true });
        } catch (e) {
            if (401 === (e as any)?.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            console.error(e)
            return [];
        }

        return items;
    }

    private hasPermission() {
        let and = true, or = false, main = false;

        main = this.service.hasPermission(this.props.name);

        if (this.props.and) {
            and = this.props.and.every(feature => this.service.hasPermission(feature));
        }

        if (this.props.or) {
            or = this.props.or.some(feature => this.service.hasPermission(feature));
        }

        return main && and || or;
    }

    async componentDidMount() {
        const timeout = this.props.loadingComponent === null ? 0 : this.delayCount;

        if (!this.service.isReady()) {
            if (!this.service.isInProgress()) {
                this.service.setPromise(this.get())
            }

            let items = await this.service.resolvePromise();
            this.service.populateFeatures(items);
        }

        setTimeout(() => {
            this.setState({
                visible: this.hasPermission(),
                loading: false,
            })
        }, timeout);
    }

    render() {
        if (this.state.loading && !this.props.hideLoadingComponent) {
            return this.props.loadingComponent
        }

        if (!this.state.loading && !this.state.visible) {
            return this.props.unauthorizedComponent
        }

        return this.props.children;
    }
}