import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../LocalizedPage';
import Component from '../../layouts/component';

export interface IBlogPageProps {
    createRoutes?: Function;
    items: any;
    submitted: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any,
    breadcrumbLevels?: any,
}

export default class BlogPage extends PureComponent<IBlogPageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, localizationService, breadcrumbLevels,submitted } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage items={items} submitted={submitted} Gatekeeper={Gatekeeper} onDoubleClick={onDoubleClick} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}
