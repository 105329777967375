import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { Button, Container, Header, Icon, Modal, Table, Image, Grid, Statistic } from 'semantic-ui-react';
import { ScrollableTable } from '../../layouts/tables';
import CommonUtils from '../../../utilities/Common/CommonUtils';
import BlogModalContent from './BlogModalContent';
import { DimmedModal } from '../../layouts/modals';
import { UnauthorizedMessage } from '../../layouts/messages';
import { LoadingSpinner } from '../../controls';
import LocalizedCreateButton from '../../controls/buttons/CreateButton/LocalizedCreateButton';
import './styles.css';
import WebsiteSettingsTemplate from '../siteconfiguration/WebsiteSettingsTemplate';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    submitted?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    onChange: (path: string) => any;
    hasProvisionned: boolean;
}
export interface IDesktopPageState {
    modalOpen: boolean;
    modalPageOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
        modalPageOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    openNewPageModal() {
        this.setState({ modalPageOpen: !this.state.modalPageOpen })
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header={this.translate('Nothing to see here!')}
                content={this.translate('Add your first page!')}
                actions={
                    [this.translate('Not now'),
                    { key: 'done', content: this.translate('Create a new page'), positive: true, href: '/website-management/pages/create' }
                    ]}
            />;
        }
        return;
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('slug')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>

                    <Table.HeaderCell icon>
                        {this.translate('Status')}
                        {/* {' '}
                        <Icon
                            name="question circle"
                            className="icon-link"
                            onClick={() => this.openModal()}
                        /> */}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    private renderTableBody() {
        const { items } = this.props;
        // const refinedItems = items.filter((page: any) => 'submitted' !== page.status && 'recalled' !== page.status);
        const pagesTable = items.map((page: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, page)}>
                    <Table.Cell>{page.title}</Table.Cell>
                    <Table.Cell>{page.slug}</Table.Cell>
                    <Table.Cell>
                        {commonUtils.renderLanguage(page.language)}
                    </Table.Cell>

                    <Table.Cell className="blog-list-page-status">
                        {page.status}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/website-management/pages/${page.id}`}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
        return (
            <Table.Body>
                {!pagesTable.length ? this.renderNoContentMessage() : pagesTable}
            </Table.Body>
        );
    }

    private renderInformationalModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>{this.translate('About Page Statuses')}</Modal.Header>
                <Modal.Content>
                    <BlogModalContent localizationService={this.props.localizationService} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        {this.translate('Got it!')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No pages!')}</Header>
                <Image centered src="/images/icons/Text_blog.png" size="small" />
            </Container>
        )
    }

    renderNoSubmittedContentMessage() {
        return (
            <Container style={{ marginTop: '75px', height: '10%' }} textAlign='center' fluid>
                <Header>{this.translate('No pages to review!')}</Header>
            </Container>
        )
    }

    renderNoRedacalledContentMessage() {
        return (
            <Container style={{ marginTop: '75px', height: '10%' }} textAlign='center' fluid>
                <Header>{this.translate('No recalled pages!')}</Header>
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <WebsiteSettingsTemplate onChange={this.props.onChange} activeItem="pages" localizationService={this.props.localizationService} canDeploy={this.props.hasProvisionned}>
                <Gatekeeper name="page-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    {this.renderInformationalModal(this.state.modalOpen)}
                    <div className="item-list-container">
                        <Grid columns={1} stackable style={{ marginBottom: '25px' }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3' content={this.translate('All Pages')}></Header>
                                    <ScrollableTable striped selectable className='ten'>
                                        {this.renderTableHeaders()}
                                        {this.renderTableBody()}
                                        <Table.Footer fullWidth>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell colSpan='4'>
                                                <Button color="green" size="small" floated="right" icon href='/website-management/pages/create'><Icon name='plus' />Create a new page</Button>
                                            </Table.HeaderCell>
                                        </Table.Footer>
                                    </ScrollableTable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.renderNoContentModal()}
                    </div>
                </Gatekeeper>
            </WebsiteSettingsTemplate>
        );
    }
}
