import React, { PureComponent } from 'react';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IGroupSettingsPageProps extends IPageProps {
    onChange: (path: string) => any;
    Gatekeeper: any;
    items: any[];
}

export default class GroupSettingsPage extends PureComponent<IGroupSettingsPageProps> {
    render() {
        const { createRoutes, header, onChange, Gatekeeper, items } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <DesktopPage
                    header={header}
                    onChange={onChange}
                    Gatekeeper={Gatekeeper}
                    items={items}
                />
            </Page>
        );
    }
}