import React, { PureComponent } from "react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import { LoginForm } from "../../forms";
import { BrandedEntryContent } from "../../layouts/sections";
import { ILoginFormState } from "../../forms/LoginForm/LoginForm";
import { Button, ButtonGroup, Divider } from "semantic-ui-react";
import "./styles.css";

export interface ILoginPageProps {
    onLogin?: (values: ILoginFormState) => any;
    localizationService: any;
}

export default class DesktopPage extends PureComponent<ILoginPageProps> {
    render() {
        const Picker = DesktopPageRender;
        return <Picker {...this.props} />;
    }
}

class DesktopPageRender extends PureComponent<ILoginPageProps> {

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    render() {
        const { onLogin } = this.props;

        return (
            <DesktopSkeleton>
                <div style={{ marginTop: '35px' }}>
                    <div className="negate-menu-padding">
                        <BrandedEntryContent className="login-page" useFullLogo stacked={false}>
                            {/* <p>Choose an authentication provider.</p>
                    <ButtonGroup size="huge" className="login-page-oauth">
                        <Button
                            href="https://localhost:8089/auth/google/url?redirect=true"
                            className="login-with-google-btn"
                        >
                            Sign in with Google
                        </Button>
                    </ButtonGroup>
                    <p>Or login with your email directly.</p> */}
                            <LoginForm localizationService={this.props.localizationService} onSubmit={onLogin} />
                            <Divider />
                            {/* <p>No account? <a href="/signup">Sign up</a>.</p> */}
                            <p>{this.translate('No account?')} <a href="https://dreamshipsolutions.com/#contact-us">{this.translate('Contact us')}</a>.</p>
                        </BrandedEntryContent>
                    </div>
                </div>
            </DesktopSkeleton>
        );
    }
}
