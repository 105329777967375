import React, { PureComponent } from 'react';
import { Button, Card, CardContent, CardDescription, CardHeader, CardMeta, Grid, GridColumn, Header, Icon, Label, Message, Step } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../controls';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import './styles.css';
import { ApplicationInstanceSubscriptionSelectionForm } from '../../../forms/applicationinstances';

const commonUtils = new CommonUtils();

export interface IProductDesktopPageProps {
    productDefinition: any,
    productPropertyAttributes: any[],
    productSelectionAttributes: any[],
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    createProperty?: () => void;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps> {
    static defaultProps = {
    };

    state = {
        openModal: false,
    }

    private onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    private onSave() {
        if (this.props.onSave) {
            this.props.onSave({});
        }
    }

    private preparePropertyAttributes() {
        const { productPropertyAttributes } = this.props;
        let results = productPropertyAttributes.map((property) => {
            const { name, options, id } = property;
            return (<Card fluid>
                <CardContent>
                    <CardHeader>{name}</CardHeader>
                    <CardDescription>{options instanceof Array ? options.join() : options}</CardDescription>
                </CardContent>
            </Card>
            )
        })
        return results;
    }

    private prepareOptionAttributes() {
        const { productSelectionAttributes } = this.props;
        let results = productSelectionAttributes.map((property) => {
            const { name, options, id } = property;
            let optionTags = options.map((option) => {
                return (<Label basic color='black' content={option} />)
            });

            return (<Card fluid>
                <CardContent>
                    <CardHeader>{property.name}</CardHeader>
                    <CardDescription>{optionTags}</CardDescription>
                </CardContent>
            </Card>
            )
        })
        return results;
    }


    renderGeneralInformation() {
        // const { productDefinition } = this.props;
        // if (!productDefinition) { return; }
        return (
            <ApplicationInstanceSubscriptionSelectionForm
                category=''
                content=''
                language=''
                price=''
                title=''
                localizationService={this.props.localizationService}
            />
        )
    }

    renderAttributes() {
        const { productPropertyAttributes, productSelectionAttributes } = this.props;
        if (!productPropertyAttributes || !productSelectionAttributes) { return; }
        return (
            <Grid columns={2}>
                <GridColumn>
                    {this.preparePropertyAttributes()}
                </GridColumn>
                <GridColumn>
                    {this.prepareOptionAttributes()}
                </GridColumn>
            </Grid>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="editor-container product-editor">
                        <div>
                            {/* <ReturnButton
                                href={'/products'}
                                itemType={'products'}
                            /> */}
                        </div>
                        {this.renderGeneralInformation()}
                        <br />
                        {this.props.productDefinition &&
                            <Button.Group floated="right" className='form-right-toolbar-container'>
                                <Button as='a' href={`/productdefinitions/${this.props.productDefinition.id}/priceshipping`}>{'Back'}</Button>
                                <Button as='a' onClick={() => this.onSave()} positive>{'Generate Products'}</Button>
                            </Button.Group>
                        }
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
