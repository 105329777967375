import React from 'react';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import ApiKeysPane from '../../components/pages/applications/ApplicationOverviewPage/panes/ApiKeysPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class ApplicationApiKeysController extends LocalizedController {
    private repository: Repository;
    private apikeyRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private featureRepository: Repository;

    state = {
        items: [],
        features: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, apikeyRepository, gatekeeperFactory, featureRepository] = props.args;
        this.repository = repository;
        this.apikeyRepository = apikeyRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.featureRepository = featureRepository;
    }

    private configureToast() {
        return (
            {
                autoClose: 1500,
            }
        );
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`applications/${this.params?.id}/apikeys`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();
        let features = await this.getLicenseFeatures();

        this.setState({
            items,
            features,
        })
    }

    async handleSave(data) {
        let item;

        try {
            item = await this.apikeyRepository.create(data);
        } catch (e) {
            throw e;
        }

        try {
            await this.handleLinkApiKey(item);
        } catch (e) {
            throw e;
        }

        return item;
    }

    async handleLinkApiKey(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'apiKeyId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the api key from this application. Please try again.");
        }
    }

    async handleUnlinkApiKey(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'apiKeyId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to remove the api key from this application. Please try again.");
        }
    }

    async handleToggleFeature(item, data) {
        delete data['features'];
        Object.keys(data).forEach((key) => {
            if (data[key]) {
                this.handleLinkApiKeyFeature(item.id, key);
            } else {
                this.handleUnlinkApiKeyFeature(item.id, key);
            }
        })
    }

    async handleLinkApiKeyFeature(apiKeyId, featureId) {
        try {
            await this.featureRepository.linkByPath(`apikeys/${apiKeyId}`, { featureId });
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the feature from this api key. Please try again.");
        }
    }

    async handleUnlinkApiKeyFeature(apiKeyId, featureId) {
        try {
            await this.featureRepository.unlinkByPath(`apikeys/${apiKeyId}`, { featureId });
        } catch (e) {
            console.error(e)
            throw Error("Failed to remove the feature from this api key. Please try again.");
        }
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    async getLicenseFeatures() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`applications/${this.params?.id}/licenses`);
            items = items.flatMap(async (item) => await this.featureRepository.getByPath(`licenses/${item.id}/features`));
            items = await Promise.all(items);
            items = items.reduce((result, item) => [...result, ...item], []);


            items.sort(function (a, b) {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();

                return (a < b) ? -1 : (a > b) ? 1 : 0;
            })

            let ret = [items[0]];

            for (var i = 1; i < items.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
                if (items[i - 1].id !== items[i].id) {
                    ret.push(items[i]);
                }
            }

            items = ret;

        } catch (e) {
            return [];
        }

        return items;
    }

    async getApikeyFeatures(id: string) {
        let items;

        try {
            items = await this.featureRepository.getByPath(`apikeys/${id}/features`);
        } catch (e) {
            return [];
        }

        return items;
    }

    private async handleDelete(item) {
        try {
            await this.handleUnlinkApiKey(item);
        } catch (e) {
            throw e;
        }

        try {
            item = await this.apikeyRepository.delete(item.id);
        } catch (e) {
            throw e;
        }

        this.refreshItems();
    }

    render() {
        const { items, features } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="application-apikey-management"
                or={["application-apikey-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
            >
                <ApiKeysPane
                    items={items}
                    features={features}
                    Gatekeeper={Gatekeeper}
                    onSave={async (data: any) => await this.handleSave(data)}
                    onDelete={async (data: any) => await this.handleDelete(data)}
                    onToggleFeature={async (item: any, data: any) => await this.handleToggleFeature(item, data)}
                    onModalClose={async () => this.refreshItems()}
                    toastConfig={this.configureToast()}
                    getApikeyFeatures={async (id: string) => await this.getApikeyFeatures(id)}
                />
            </Gatekeeper>
        )
    }
}