export default class Router {
    protected params;
    protected location: any;
    protected history: any;

    setParams(params) {
        this.params = params;
        return this;
    }

    setLocation(location) {
        this.location = location;
        return this;
    }

    setHistory(history) {
        this.history = history;
        return this;
    }

    redirect(path) {
        this.history.push(path);
    }

    reload() {
        this.history.go(0);
    }

    getQueryParameters() {
        return this.location?.search;
    }

    getPathName() {
        return this.location?.pathname;
    }
}