import React from 'react';
import { InboxPage } from '../../components/pages';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class InboxController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: []
    };

    constructor({ args: [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
            items.reverse();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Inbox'), value: '/inbox'},
        ]
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <InboxPage
                localizationService={this.localizationService}
                items={items}
                Gatekeeper={Gatekeeper}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}