import React from 'react';
import LoadingSpinner from '../../components/controls/loaders/LoadingSpinner';
import UnauthorizedMessage from '../../components/layouts/messages/UnauthorizedMessage';
import InvoiceOverviewPage from '../../components/pages/invoices/InvoiceOverviewPage';
import OrderOverviewPage from '../../components/pages/orders/OrderOverviewPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class OrderOverviewController extends LocalizedController {
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private repository: Repository;
    private productDefinitionAdapter;
    private productAdapter;
    private productCatalogAdapter;
    private productAttributeAdapter;

    state = {
        items: [],
        order: {},
        invoice: [],
        displaySku: true,
    };

    constructor(props) {
        super(props);
        // add productRepo + attributeRepo
        const [router, routeMenuItemFactory, localizationService, repository, productDefinitionAdapter, productAdapter, productCatalogAdapter, productAttributeAdapter, gatekeeperFactory] = props.args;
        this.productDefinitionAdapter = productDefinitionAdapter;
        this.repository = repository;
        this.productAdapter = productAdapter;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.productCatalogAdapter = productCatalogAdapter;
        this.productAttributeAdapter = productAttributeAdapter;
    }

    private async getOrder() {
        let order;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            order = await this.repository.getOneByPath(`orders/${this.params?.id}`);
        } catch (e) {
            console.error(e)
            return {};
        }

        return order;
    }

    private async getStorefrontSettings() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            let cart = await this.repository.getOneByPath(`invoices/${this.params?.id}`);
            let storefront = await this.repository.getOneByPath(`storefronts/${cart.storefrontId}`);
            return storefront.settings.orders.emails.template.displaySku;
        } catch (e) {
            console.error('Problem loading storefront settings.', e)
            return true;
        }
    }

    private async getInvoice() {
        let invoice;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            invoice = await this.repository.getOneByPath(`invoices/${this.params?.id}`);
        } catch (e) {
            console.error(e)
            return [];
        }

        return invoice;
    }

    private async getCheckoutInformation(cartId: string) {
        let checkout;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            checkout = await this.repository.getOneByPath(`checkouts/${cartId}`);
        } catch (e) {
            console.error(e)
            return [];
        }

        return checkout;
    }
    private async getCustomerInformation(customerId: string) {
        let checkout;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            checkout = await this.repository.getOneByPath(`customers/${customerId}`);
        } catch (e) {
            console.error(e)
            return [];
        }

        return checkout;
    }

    async componentDidMount() {
        // let order = await this.getOrder();
        let invoice = await this.getInvoice();
        let displaySku = await this.getStorefrontSettings();
        // TO-DO make the following calls when we're ready
        // let checkout = await this.getCheckoutInformation(invoice.checkoutId);
        // let customer = await this.getCustomerInformation(checkout[0].customerId);

        this.setState({
            invoice,
            displaySku
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/store-management/invoices/${id}/edit`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Invoices'), value: '/store-management/invoices' },
            { key: 3, text: this.localizationService.translate('Invoice Details'), value: undefined },
        ]
    }

    render() {
        const { invoice, order, displaySku } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <InvoiceOverviewPage
                invoice={invoice}
                order={order}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                displaySku={displaySku}
            />
        )
    }
}