import React, { PureComponent } from 'react';
import { Breadcrumb } from 'semantic-ui-react';

export interface IBreadcrumbsProps {
    links: any[];
}

export default class Breadcrumbs extends PureComponent<IBreadcrumbsProps> {
    static defaultProps = {
        links: [
            { key: 0, text: 'General', value: '/' },
            { key: 1, text: 'Blog', value: '/posts' },
        ]
    }

    renderBreadcrumbItems() {

        return this.props.links.map(({ key, text, value }, index) => {
            if (index + 1 === this.props.links.length) {
                return <Breadcrumb.Section key={key} active>{text}</Breadcrumb.Section>
            }
            return (<>
                <Breadcrumb.Section key={key} href={value}>{text}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
            </>);
        }
        );
    }

    render() {
        return (
            <Breadcrumb>
                {this.renderBreadcrumbItems()}
            </Breadcrumb>
        )
    }
}