import React from 'react';
import ProductCatalogEditPage from '../../components/pages/productcatalogcreate/ProductCatalogEditPage/ProductCatalogEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class ProductCatalogCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: [],
        submitted: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async handleSave(data: any) {
        let item;
        try {
            item = await this.repository.create(data, {});
            this.router.redirect(`/store-management/catalogs/${item.id}/edit`);
        } catch (e) {
            console.error(e);
        }
    }

    async componentDidMount() {

    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Catalogs'), value: '/store-management/catalogs' },
            { key: 3, text: this.localizationService.translate('Create'), value: undefined },
        ]
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductCatalogEditPage
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onSave={async (data: any) => this.handleSave(data)}
            />
        )
    }
}