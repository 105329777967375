import {
    BlogController,
    PageNotFoundController,
    LoginController,
    SignupController,
    ActivationController,
    InboxController,
    BlogEditController,
    SectionController,
    SectionEditController,
    SectionCreateController,
    PasswordResetController,
    OverviewController,
    BlogCreateController,
    UserController as SettingsUserController,
    AccountController as SettingsAccountController,
    GroupController,
    ResourceController,
    SecurityController,
    BillingPlanController,
    FeaturesController,
    FeaturesEditController,
} from '../../controllers';
import { withDependencyInjection } from 'tramway-router-react-strategy';
import AccountController from '../../controllers/accounts/AccountController';
import AccountOverviewController from '../../controllers/accounts/AccountOverviewController';
import AccountUsersController from '../../controllers/accounts/AccountUsersController';
import AccountSubscriptionsController from '../../controllers/accounts/AccountSubscriptionsController';
import UserCreateController from '../../controllers/users/UserCreateController';
import UserEditController from '../../controllers/users/UserEditController';
import UserController from '../../controllers/users/UserController';
import AccountEditController from '../../controllers/accounts/AccountEditController';
import AccountCreateController from '../../controllers/accounts/AccountCreateController';
import AccountContactsController from '../../controllers/accounts/AccountContactsController';
import SubscriptionController from '../../controllers/subscriptions/SubscriptionController';
import SubscriptionCreateController from '../../controllers/subscriptions/SubscriptionCreateController';
import SubscriptionEditController from '../../controllers/subscriptions/SubscriptionEditController';
import SubscriptionTermController from '../../controllers/subscriptions/SubscriptionTermController';
import SubscriptionDesignController from '../../controllers/subscriptions/SubscriptionDesignController';
import SubscriptionLicensesController from '../../controllers/subscriptions/SubscriptionLicenseController';
import FeaturesCreateController from '../../controllers/features/FeaturesCreateController';
import GroupEditController from '../../controllers/settings/GroupEditController';
import GroupCreateController from '../../controllers/settings/GroupCreateController';
import ResourceCreateController from '../../controllers/settings/ResourceCreateController';
import ResourceEditController from '../../controllers/settings/ResourceEditController';
import LicenseController from '../../controllers/licenses/LicenseController';
import LicenseCreateController from '../../controllers/licenses/LicenseCreateController';
import LicenseOverviewController from '../../controllers/licenses/LicenseOverviewController';
import LicenseEditController from '../../controllers/licenses/panes/LicenseEditController';
import LicenseFeaturesController from '../../controllers/licenses/panes/LicenseFeaturesController';
import AccountApplicationsController from '../../controllers/accounts/AccountApplicationsController';
import ApplicationController from '../../controllers/applications/ApplicationController';
import ApplicationCreateController from '../../controllers/applications/ApplicationCreateController';
import ApplicationEditController from '../../controllers/applications/ApplicationEditController';
import ApplicationOverviewController from '../../controllers/applications/ApplicationOverviewController';
import ApplicationLicensesController from '../../controllers/applications/ApplicationLicenseController';
import ApplicationApiKeysController from '../../controllers/applications/ApplicationApiKeyController';
import ArchetypeController from '../../controllers/applications/ArchetypeController';
import ArchetypeCreateController from '../../controllers/applications/ArchetypeCreateController';
import ArchetypeEditController from '../../controllers/applications/ArchetypeEditController';
import ComponentController from '../../controllers/applications/ComponentController';
import ComponentCreateController from '../../controllers/applications/ComponentCreateController';
import ComponentEditController from '../../controllers/applications/ComponentEditController';
import ArchetypeOverviewController from '../../controllers/applications/ArchetypeOverviewController';
import ArchetypeComponentController from '../../controllers/applications/ArchetypeComponentController';
import ApplicationUsersController from '../../controllers/applications/ApplicationUserController';
import SubscriptionArchetypesController from '../../controllers/subscriptions/SubscriptionArchetypeController';
import AccountSubscriptionsNewController from '../../controllers/accounts/AccountSubscriptionsNewController';
import ProductController from '../../controllers/products/ProductController';
import ProductEditController from '../../controllers/products/ProductEditController';
import ProductCreateController from '../../controllers/products/ProductCreateController';
import ProductCatalogController from '../../controllers/products/ProductCatalogController';
import OrderOverviewController from '../../controllers/orders/OrderOverviewController';
import ProductOverviewController from '../../controllers/products/ProductOverview/ProductOverviewController';
import ProductOverviewProductDefinitionController from '../../controllers/products/ProductOverview/ProductOverviewProductDefinitionController';
import ProductOverviewProductsController from '../../controllers/products/ProductOverview/ProductOverviewProductsController';
import ProductOverviewProductCatalogsController from '../../controllers/products/ProductOverview/ProductOverviewProductCatalogsController';
import ProductOverviewProductAttributesController from '../../controllers/products/ProductOverview/ProductOverviewProductAttributesController';
import CartsController from '../../controllers/carts/CartsController';
import ProductDefinitionCreateController from '../../controllers/products/ProductDefinitions/ProductDefinitionCreateController';
import ProductDefinitionEditController from '../../controllers/products/ProductDefinitions/ProductDefinitionEditController';
import ProductAttributesCreateController from '../../controllers/products/ProductAttributes/ProductAttributesCreateController';
import ProductConfirmationController from '../../controllers/products/ProductConfirmation/ProductConfirmationController';
import ProductDefinitionsOverviewController from '../../controllers/products/ProductDefinitionsOverview/ProductDefinitionsOverviewController';
import PageCreateController from '../../controllers/pages/PageCreateController';
import PageController from '../../controllers/pages/PageController';
import PageEditController from '../../controllers/pages/PageEditController';
import PageOverviewController from '../../controllers/pages/PageOverviewController';
import ApplicationTemplatesController from '../../controllers/applicationtemplates/ApplicationTemplatesController';
import ApplicationTemplatesCreateController from '../../controllers/applicationtemplates/ApplicationTemplatesCreateController';
import ApplicationTemplatesEditController from '../../controllers/applicationtemplates/ApplicationTemplatesEditController';
import ArchetypeApplicationTemplatesController from '../../controllers/applications/ArchetypeApplicationTemplatesController';
import TaxesController from '../../controllers/taxes/TaxesController';
import TaxesEditController from '../../controllers/taxes/TaxesEditController';
import TaxesCreateController from '../../controllers/taxes/TaxesCreateController';
import ProductPriceShippingEditController from '../../controllers/products/ProductPriceShipping/ProductPriceShippingEditController';
import SiteController from '../../controllers/settings/SiteController';
import ThemeLayoutController from '../../controllers/settings/ThemeLayoutController';
import ApplicationInstancesGeneralInformationController from '../../controllers/applicationinstances/ProductConfirmation/ApplicationInstancesGeneralInformationController';
import WebsitesController from '../../controllers/websites/WebsitesController';
import WebsiteConfigurationController from '../../controllers/websites/WebsiteConfigurationController';
import WebsiteDeploymentController from '../../controllers/websites/WebsiteDeploymentController';
import ProductCatalogCreateController from '../../controllers/products/ProductCatalogCreateController';
import ProductCatalogEditController from '../../controllers/products/ProductCatalogEditController';
import InvoiceOverviewController from '../../controllers/invoices/InvoiceOverviewController';
import OrderController from '../../controllers/orders/OrderController';
import InvoicesController from '../../controllers/invoices/InvoicesController';
import StorefrontConfigurationController from '../../controllers/storefront/StorefrontConfigurationController';
import StorefrontsController from '../../controllers/storefront/StorefrontsController';
import TemplatesController from '../../controllers/templates/TemplatesController';
import TemplatesEditController from '../../controllers/templates/TemplatesEditController';
import MessagesConfigurationSettingsController from '../../controllers/websites/MessagesConfigurationSettingsController';
import OrdersPaneController from '../../controllers/orders/OrdersPaneController';
// import EventsController from '../../controllers/events/EventsController';

export default {
    "controller.overview": {
        "class": withDependencyInjection(OverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.sections": {
        "class": withDependencyInjection(SectionController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.sections" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.blog": {
        "class": withDependencyInjection(BlogController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.posts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.blogcreate": {
        "class": withDependencyInjection(BlogCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.posts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.blogedit": {
        "class": withDependencyInjection(BlogEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.posts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.product": {
        "class": withDependencyInjection(ProductController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "repository.productcatalogs" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "repository.contents" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productcreate": {
        "class": withDependencyInjection(ProductCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productedit": {
        "class": withDependencyInjection(ProductEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productdefinitionsoverview": {
        "class": withDependencyInjection(ProductDefinitionsOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productdefinitioncreate": {
        "class": withDependencyInjection(ProductDefinitionCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productdefinitionedit": {
        "class": withDependencyInjection(ProductDefinitionEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "repository.contents" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productdefinitionsattributes": {
        "class": withDependencyInjection(ProductAttributesCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productdefinitionsconfirmation": {
        "class": withDependencyInjection(ProductConfirmationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productdefinitionspriceshipping": {
        "class": withDependencyInjection(ProductPriceShippingEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.invoice": {
        "class": withDependencyInjection(InvoicesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.invoiceoverview": {
        "class": withDependencyInjection(InvoiceOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "controller.productoverview:productdefinition" },
            { "type": "service", "key": "controller.productoverview:product" },
            { "type": "service", "key": "controller.productoverview:productcatalogs" },
            { "type": "service", "key": "controller.productoverview:productattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.order": {
        "class": withDependencyInjection(OrderController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "controller.order:pane" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.order:pane": {
        "class": withDependencyInjection(OrdersPaneController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.orderoverview": {
        "class": withDependencyInjection(OrderOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "controller.productoverview:productdefinition" },
            { "type": "service", "key": "controller.productoverview:product" },
            { "type": "service", "key": "controller.productoverview:productcatalogs" },
            { "type": "service", "key": "controller.productoverview:productattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productoverview": {
        "class": withDependencyInjection(ProductOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "controller.productoverview:productdefinition" },
            { "type": "service", "key": "controller.productoverview:product" },
            { "type": "service", "key": "controller.productoverview:productcatalogs" },
            { "type": "service", "key": "controller.productoverview:productattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productoverview:productdefinition": {
        "class": withDependencyInjection(ProductOverviewProductDefinitionController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productoverview:product": {
        "class": withDependencyInjection(ProductOverviewProductsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productoverview:productcatalogs": {
        "class": withDependencyInjection(ProductOverviewProductCatalogsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productcatalogs" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },

    "controller.productoverview:productattributes": {
        "class": withDependencyInjection(ProductOverviewProductAttributesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    //@TODO: Create Controllers and uncomment.
    "controller.productcatalog": {
        "class": withDependencyInjection(ProductCatalogController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productcatalogs" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productcatalogcreate": {
        "class": withDependencyInjection(ProductCatalogCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productcatalogs" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.productcatalogedit": {
        "class": withDependencyInjection(ProductCatalogEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productcatalogs" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.inbox": {
        "class": withDependencyInjection(InboxController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.messages" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.account": {
        "class": withDependencyInjection(AccountController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountsubscriptionsnew": {
        "class": withDependencyInjection(AccountSubscriptionsNewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountoverview": {
        "class": withDependencyInjection(AccountOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "controller.accountoverview:accountusers" },
            { "type": "service", "key": "controller.accountoverview:accountsubscriptions" },
            { "type": "service", "key": "controller.accountoverview:accountcontacts" },
            { "type": "service", "key": "controller.accountoverview:accountapplications" },
        ],
    },
    "controller.accountoverview:accountusers": {
        "class": withDependencyInjection(AccountUsersController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountoverview:accountsubscriptions": {
        "class": withDependencyInjection(AccountSubscriptionsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountoverview:accountcontacts": {
        "class": withDependencyInjection(AccountContactsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountoverview:accountapplications": {
        "class": withDependencyInjection(AccountApplicationsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountcreate": {
        "class": withDependencyInjection(AccountCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountedit": {
        "class": withDependencyInjection(AccountEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.usersettings": {
        "class": withDependencyInjection(SettingsUserController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationsettings": {
        "class": withDependencyInjection(SiteController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.themelayoutsettings": {
        "class": withDependencyInjection(ThemeLayoutController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.accountsettings": {
        "class": withDependencyInjection(SettingsAccountController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.groupsettings": {
        "class": withDependencyInjection(GroupController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.groups" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.groupsettingscreate": {
        "class": withDependencyInjection(GroupCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.groups" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.groupsettingsedit": {
        "class": withDependencyInjection(GroupEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.groups" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.resourcesettings": {
        "class": withDependencyInjection(ResourceController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.resources" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "repository.permissions" },
        ],
    },
    "controller.resourcesettingscreate": {
        "class": withDependencyInjection(ResourceCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.resources" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.resourcesettingsedit": {
        "class": withDependencyInjection(ResourceEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.resources" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.securitysettings": {
        "class": withDependencyInjection(SecurityController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.billingplansettings": {
        "class": withDependencyInjection(BillingPlanController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            // { "type": "service", "key": "repository.messages" },
            // { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.users": {
        "class": withDependencyInjection(UserController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.usercreate": {
        "class": withDependencyInjection(UserCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.useredit": {
        "class": withDependencyInjection(UserEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.sectioncreate": {
        "class": withDependencyInjection(SectionCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.sections" },
            { "type": "service", "key": "repository.pages" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "repository.websites" },
            { "type": "service", "key": "repository.layouts" },
            { "type": "service", "key": "repository.sectionlayouts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.sectionedit": {
        "class": withDependencyInjection(SectionEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.sections" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "repository.products" },
        ],
    },
    "controller.license": {
        "class": withDependencyInjection(LicenseController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.licensecreate": {
        "class": withDependencyInjection(LicenseCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.licenseoverview": {
        "class": withDependencyInjection(LicenseOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "controller.licenseoverview:licenseedit" },
            { "type": "service", "key": "controller.licenseoverview:licensefeatures" },
        ],
    },
    "controller.licenseoverview:licenseedit": {
        "class": withDependencyInjection(LicenseEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.licenseoverview:licensefeatures": {
        "class": withDependencyInjection(LicenseFeaturesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "repository.features" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.carts": {
        "class": withDependencyInjection(CartsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.carts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.features": {
        "class": withDependencyInjection(FeaturesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.features" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.featurescreate": {
        "class": withDependencyInjection(FeaturesCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.features" },
            { "type": "service", "key": "repository.resources" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.featuresedit": {
        "class": withDependencyInjection(FeaturesEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.features" },
            { "type": "service", "key": "repository.resources" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptions": {
        "class": withDependencyInjection(SubscriptionController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptionoverview": {
        "class": withDependencyInjection(SubscriptionDesignController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "controller.subscriptionoverview:subscriptionterms" },
            { "type": "service", "key": "controller.subscriptionoverview:subscriptionlicenses" },
            { "type": "service", "key": "controller.subscriptionoverview:subscriptionarchetypes" },
        ],
    },
    "controller.subscriptionoverview:subscriptionterms": {
        "class": withDependencyInjection(SubscriptionTermController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptionoverview:subscriptionlicenses": {
        "class": withDependencyInjection(SubscriptionLicensesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptionoverview:subscriptionarchetypes": {
        "class": withDependencyInjection(SubscriptionArchetypesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptioncreate": {
        "class": withDependencyInjection(SubscriptionCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.subscriptionedit": {
        "class": withDependencyInjection(SubscriptionEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.subscriptions" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applications": {
        "class": withDependencyInjection(ApplicationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "repository.accounts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationoverview": {
        "class": withDependencyInjection(ApplicationOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "controller.applicationoverview:applicationapikeys" },
            { "type": "service", "key": "controller.applicationoverview:applicationlicenses" },
            { "type": "service", "key": "controller.applicationoverview:applicationusers" },
        ],
    },
    "controller.applicationoverview:applicationlicenses": {
        "class": withDependencyInjection(ApplicationLicensesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "repository.licenses" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationoverview:applicationusers": {
        "class": withDependencyInjection(ApplicationUsersController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "repository.users" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationoverview:applicationapikeys": {
        "class": withDependencyInjection(ApplicationApiKeysController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "repository.apikeys" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "repository.features" },
        ],
    },
    "controller.applicationcreate": {
        "class": withDependencyInjection(ApplicationCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationedit": {
        "class": withDependencyInjection(ApplicationEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.archetypes": {
        "class": withDependencyInjection(ArchetypeController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.archetypeoverview": {
        "class": withDependencyInjection(ArchetypeOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "controller.archetypeoverview:archetypecomponents" },
            { "type": "service", "key": "controller.archetypeoverview:archetypeapplicationtemplates" },
        ],
    },
    "controller.archetypeoverview:archetypeapplicationtemplates": {
        "class": withDependencyInjection(ArchetypeApplicationTemplatesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "repository.applicationtemplates" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.archetypeoverview:archetypecomponents": {
        "class": withDependencyInjection(ArchetypeComponentController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "repository.components" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.archetypecreate": {
        "class": withDependencyInjection(ArchetypeCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.archetypeedit": {
        "class": withDependencyInjection(ArchetypeEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.archetypes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.components": {
        "class": withDependencyInjection(ComponentController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.components" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.componentcreate": {
        "class": withDependencyInjection(ComponentCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.components" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.componentedit": {
        "class": withDependencyInjection(ComponentEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.components" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.login": {
        "class": withDependencyInjection(LoginController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.authentication" },
        ]
    },
    "controller.passwordreset": {
        "class": withDependencyInjection(PasswordResetController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.authentication" },
            { "type": "service", "key": "provider.api.passwordreset" },
        ]
    },
    "controller.signup": {
        "class": withDependencyInjection(SignupController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.user" },
        ]
    },
    "controller.activation": {
        "class": withDependencyInjection(ActivationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.activation" },
        ],
    },
    "controller.storefronts": {
        "class": withDependencyInjection(StorefrontsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "repository.applications" },
        ],
    },
    "controller.storefrontconfigurationoverview": {
        "class": withDependencyInjection(StorefrontConfigurationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "repository.applications" },
        ],
    },
    "controller.messagesconfigurationsettings": {
        "class": withDependencyInjection(MessagesConfigurationSettingsController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.messages" },
        ],
    },
    "controller.websiteconfiguration": {
        "class": withDependencyInjection(WebsiteConfigurationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.websitedeployment": {
        "class": withDependencyInjection(WebsiteDeploymentController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.applications" },
        ],
    },
    "controller.pages": {
        "class": withDependencyInjection(PageController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "repository.pages" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.pagescreate": {
        "class": withDependencyInjection(PageCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.pages" },
            { "type": "service", "key": "repository.websites" },
            { "type": "service", "key": "repository.layouts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.pagesedit": {
        "class": withDependencyInjection(PageEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.pages" },
            { "type": "service", "key": "repository.websites" },
            { "type": "service", "key": "repository.layouts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.pagesoverview": {
        "class": withDependencyInjection(PageOverviewController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.pages" },
            { "type": "service", "key": "repository.sections" },
            { "type": "service", "key": "repository.layouts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationtemplates": {
        "class": withDependencyInjection(ApplicationTemplatesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applicationtemplates" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationtemplatescreate": {
        "class": withDependencyInjection(ApplicationTemplatesCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applicationtemplates" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationtemplatesedit": {
        "class": withDependencyInjection(ApplicationTemplatesEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applicationtemplates" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.taxes": {
        "class": withDependencyInjection(TaxesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.taxescreate": {
        "class": withDependencyInjection(TaxesCreateController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.taxesedit": {
        "class": withDependencyInjection(TaxesEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.taxes" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.applicationinstancescreate": {
        "class": withDependencyInjection(ApplicationInstancesGeneralInformationController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.productdefinitions" },
            { "type": "service", "key": "repository.productsattributes" },
            { "type": "service", "key": "repository.products" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.websitesettings": {
        "class": withDependencyInjection(WebsitesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "controller.pages" },
            { "type": "service", "key": "controller.sections" },
            { "type": "service", "key": "controller.websiteconfiguration" },
            { "type": "service", "key": "controller.websitedeployment" },
            // { "type": "service", "key": "controller.applicationoverview:applicationapikeys" },
            // { "type": "service", "key": "controller.applicationoverview:applicationlicenses" },
            // { "type": "service", "key": "controller.applicationoverview:applicationusers" },
        ],
    },
    "controller.templatesoverview": {
        "class": withDependencyInjection(TemplatesController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.websites" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    "controller.templatesedit": {
        "class": withDependencyInjection(TemplatesEditController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
            { "type": "service", "key": "repository.websites" },
            { "type": "service", "key": "repository.layouts" },
            { "type": "service", "key": "repository.sectionlayouts" },
            { "type": "service", "key": "factory.gatekeeper" },
        ],
    },
    // "controller.events": {
    //     "class": withDependencyInjection(EventsController),
    //     "constructor": [
    //         { "type": "service", "key": "core.router" },
    //         { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
    //         { "type": "service", "key": "service.localization" },
    //         { "type": "service", "key": "repository.events" },
    //         { "type": "service", "key": "factory.gatekeeper" },
    //     ],
    // },
    "controller.not_found": {
        "class": withDependencyInjection(PageNotFoundController),
        "constructor": [
            { "type": "service", "key": "core.router" },
            { "type": "service", "key": "resolver.factory.deviceawarerouteitem" },
            { "type": "service", "key": "service.localization" },
        ],
    },
}