import React, { PureComponent } from "react";
import {
    Grid,
    Card,
    Divider,
    CardGroup,
    Segment,
    Container,
    Tab,
    Button,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../../layouts/skeleton";
import ReactMarkdown from "react-markdown";
import "./styles.css";
import { ReturnButton } from "../../../controls";

export interface IDesktopPageProps {
    createRoutes?: Function;
    item?: any;
    Gatekeeper: any;
    panes: any[];
}
export interface IDesktopPageState { }

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {};

    render() {
        const { Gatekeeper, panes, item } = this.props;

        return (
            <DesktopSkeleton>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column>
                            <Gatekeeper name="subscription-view">
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>
                                            {item?.name}
                                        </Card.Header>
                                        <Divider />
                                        <Card.Description>
                                            <ReactMarkdown children={item?.description} />
                                        </Card.Description>
                                    </Card.Content>
                                    <Card.Content extra textAlign="right">
                                        <Button color='green' icon='edit' content='Edit' as='a' href={`/subscriptions/${item?.id}/edit`} />
                                    </Card.Content>
                                </Card>
                            </Gatekeeper>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <h4>Overview</h4>
                <Divider />
                <Grid columns={2} stackable padded>
                    {this.renderPanes()}
                </Grid>
            </DesktopSkeleton>
        );
    }

    renderPanes() {
        const { panes } = this.props;
        let widgets = panes.map((pane, index) => {
            return (
                <Grid.Column width={8}>
                    <div style={{ marginBottom: '15px' }}>
                        {pane.render()}
                    </div>
                </Grid.Column>
            )
        })
        return (
            <Grid.Row>
                {widgets}
            </Grid.Row>
        );
    }
}
