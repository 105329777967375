import React, { PureComponent } from 'react';
import { Visibility } from 'semantic-ui-react';
import { Navigation } from '../../sections';

export interface IDesktopHeadingProps {
    onBottomPassed?: Function | undefined;
    onBottomPassedReverse?: Function | undefined;
    children?: any;
    routes?: any[];
}

export default class DesktopHeading extends PureComponent<IDesktopHeadingProps> {
    state = {
        fixed: false,
    }

    showFixedMenu() {
        return this.setState({ fixed: true }, () => {
            this.props.onBottomPassed && this.props.onBottomPassed();
        });
    }

    hideFixedMenu() {
        return this.setState({ fixed: false }, () => {
            this.props.onBottomPassedReverse && this.props.onBottomPassedReverse();
        });
    }

    render() {
        const { children, routes } = this.props;
        const { fixed } = this.state;

        return (
            <Visibility
                once={false}
                onBottomPassed={() => this.showFixedMenu()}
                onBottomPassedReverse={() => this.hideFixedMenu()}
            >
                <Navigation
                    fixed={fixed}
                    routes={routes}
                />
                {children}
            </Visibility>
        );
    }
}