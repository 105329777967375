import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { BrandedEntryContent } from '../../layouts/sections';
import { Message } from '../../layouts/messages';
import { Button } from 'semantic-ui-react';
import './styles.css';

export interface IActivationDesktopPageProps {
    onAcknowledge: Function;
    message?: string;
    activationSuccessful?: boolean;
    header?: any;
}

export default class DesktopPage extends PureComponent<IActivationDesktopPageProps> {
    render() {
        // const Picker = LocalizedComponent()(DesktopPageRender);
        const Picker = DesktopPageRender;
        return <Picker {...this.props} />;
    }
}

class DesktopPageRender extends PureComponent<IActivationDesktopPageProps> {
    render() {
        const { onAcknowledge, message, activationSuccessful } = this.props;

        return (
            <DesktopSkeleton>
                <div style={{ marginTop: '35px' }}>
                    <div className="negate-menu-padding">
                        <BrandedEntryContent className="signup-page" useFullLogo>
                            <Message
                                header={activationSuccessful ? "Account activated" : "Error"}
                                error={!activationSuccessful}
                                content={message}
                            />
                            <Button onClick={() => onAcknowledge()}>Ok</Button>
                        </BrandedEntryContent>
                    </div>
                </div>
            </DesktopSkeleton>
        )
    }
}