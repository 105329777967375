import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IProductDefinitionsOverviewPageProps extends IPageProps {
    onDelete?: () => void;
    createProperty?: () => void;
    updatePrices?: (data:any[]) => void;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    products: any,
    productDefinition: any,
    productPropertyAttributes: any[],
    productSelectionAttributes: any[],
    hasCustomSku: boolean,
    handlePublishUnpublish: (data: string) => void,
    handleAttributePriceUpdate: (data: any) => void,
}

export default class ProductDefinitionsOverviewPage extends PureComponent<IProductDefinitionsOverviewPageProps> {
    render() {

        const {
            createRoutes,
            onDelete,
            Gatekeeper,
            localizationService,
            breadcrumbLevels,
            createProperty,
            productDefinition,
            productPropertyAttributes,
            productSelectionAttributes,
            products,
            hasCustomSku,
            updatePrices,
            handlePublishUnpublish,
            handleAttributePriceUpdate,
        } = this.props;

        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        productDefinition={productDefinition}
                        products={products}
                        productPropertyAttributes={productPropertyAttributes}
                        productSelectionAttributes={productSelectionAttributes}
                        hasCustomSku={hasCustomSku}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        onDelete={onDelete}
                        createProperty={createProperty}
                        updatePrices={updatePrices}
                        handlePublishUnpublish={handlePublishUnpublish}
                        handleAttributePriceUpdate={handleAttributePriceUpdate}
                    />
                    {/* <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                    /> */}
                </Component>
            </Page>
        );
    }
}
