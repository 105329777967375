import React, { PureComponent } from 'react';
import {
    Button,
    Icon,
    Modal,
    CardGroup,
    Card,
    Divider,
    Header,
} from 'semantic-ui-react';
import { MobileSkeleton } from '../../../layouts/skeleton'
import { Message, UnauthorizedMessage } from '../../../layouts/messages';
import { DimmedModal } from '../../../layouts/modals';
import { LoadingSpinner } from '../../../controls';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import CreateButton from '../../../controls/buttons/CreateButton';
import './styles.css';
import CustomersModalContent from './CustomersModalContent';

const commonUtils = new CommonUtils();
export interface IMobilePageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
}
export interface IMobilePageState {
    modalOpen: boolean;
}

export default class MobilePage extends PureComponent<
    IMobilePageProps,
    IMobilePageState
> {
    state = {
        modalOpen: false,
    };

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header">
                <Header as="h2" content="Accounts" className="mobile-items" />
                <Icon
                    name="question circle"
                    className="icon-link mobile-items"
                    onClick={() => this.openModal()}
                />
            </div>
        );
    }

    renderCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.map((account: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{account.name}</Card.Header>
                        <Card.Meta>
                            <span className="blog-list-account-status">
                                Stage: {account.stage || '-'}
                            </span>
                        </Card.Meta>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    icon="eye"
                                    color="blue"
                                    as="a"
                                    href={`/accounts/${account.id}`}
                                    content="Details"
                                />
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/accounts/${account.id}/edit`}
                                    content="Edit"
                                />
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? <Message content="No content." size='large' /> : cardList}
            </CardGroup>
        );
    }

    private renderModal() {
        const { modalOpen } = this.state;
        return (
            <Modal dimmer open={modalOpen} size="small">
                <Modal.Header>About Post Statuses</Modal.Header>
                <Modal.Content scrolling>
                    <CustomersModalContent isMobile />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        onClick={() => this.openModal()}
                        content="Got it!"
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="account-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="mobile-item-list">
                        {this.renderModal()}
                        {this.renderPageHeader()}
                        {this.renderCards()}
                        {!this.props.items.length &&
                            <DimmedModal header="Nothing to see here!" content="Add your first account!" actions={['Not now', { key: 'done', content: 'Create account', positive: true, href: '/accounts/create' }]} />
                        }
                        <CreateButton href='/accounts/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
