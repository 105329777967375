import React, { PureComponent } from "react";
import { Card, CardHeader, Table } from "semantic-ui-react";
import Responsive from "../../../layouts/responsive/Responsive";
import { ScrollableTable } from "../../../layouts/tables";
import FeaturePermissionInput from "./FeaturePermissionInput";

export interface IFeaturePermission {
    resourcePath: string;
    verb: string[];
}

export interface IFeaturesPermissionsInputProps {
    name: string;
    value?: IFeaturePermission[];
    resources?: any[];
    handleChange: ({ name, value }) => any;
}

export default class FeaturePermissionsInput extends PureComponent<IFeaturesPermissionsInputProps> {
    state = {};

    componentDidMount() {
        const { value = [] } = this.props;
        this.setState(
            value.reduce((accumulator, value) => {
                accumulator[value.resourcePath] = value;
                return accumulator;
            }, {})
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const { value = [] } = this.props;
            this.setState(
                value.reduce((accumulator, value) => {
                    accumulator[value.resourcePath] = value;
                    return accumulator;
                }, {})
            );
        }
    }

    handleChange(name, value) {
        const permissionObject = {
            resourcePath: name,
            verbs: value,
        };

        this.setState(
            {
                [name]: permissionObject,
            },
            () =>
                this.props.handleChange({
                    name: this.props.name,
                    value: Object.values(this.state),
                })
        );
    }

    renderTableHeaders() {
        return <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Resource</Table.HeaderCell>
                <Table.HeaderCell>Get</Table.HeaderCell>
                <Table.HeaderCell>Post</Table.HeaderCell>
                <Table.HeaderCell>Put</Table.HeaderCell>
                <Table.HeaderCell>Patch</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
                <Table.HeaderCell>Link</Table.HeaderCell>
                <Table.HeaderCell>Unlink</Table.HeaderCell>
            </Table.Row>
        </Table.Header>;
    }

    renderTableBody() {
        const { resources } = this.props;
        let rows = resources?.map((resource) => {
            let resourcePermission = this.state[resource.path] || {};
            return (
                <FeaturePermissionInput
                    name={resource.path}
                    handleChange={({ name, value }) => this.handleChange(name, value)}
                    value={resourcePermission["verbs"] || []}
                    resources={resources}
                />
            );
        });
        return <Table.Body>{rows}</Table.Body>;
    }

    displayCardedResources() {
        const { resources } = this.props;
        let rows = resources?.map((resource) => {
            let resourcePermission = this.state[resource.path] || {};
            return (
                <div>
                    <Card fluid centered>
                        <Card.Content>
                            <CardHeader>{resource.path}</CardHeader>
                        </Card.Content>
                        <Card.Content extra>
                            <FeaturePermissionInput
                                name={resource.path}
                                handleChange={({ name, value }) => this.handleChange(name, value)}
                                value={resourcePermission["verbs"] || []}
                                resources={resources}
                                isMobile
                            />
                        </Card.Content>
                    </Card>
                </div>
            );
        });
        return rows;
    }

    render() {
        return (
            <div className="feature-list-container">
                <Responsive device='desktop'>
                    <ScrollableTable>
                        {this.renderTableHeaders()}
                        {this.renderTableBody()}
                    </ScrollableTable>
                </Responsive>
                {/* Restore this soon */}
                {/* <Responsive device='mobile'>
                    <div className="card-container">
                        {this.displayCardedResources()}
                    </div>
                </Responsive> */}
            </div>
        );
    }
}
