import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

import { DependencyResolver } from 'tramway-core-dependency-injector';
import * as parameters from './config/parameters';
import services from './config/services';

DependencyResolver.create().initialize(services, parameters);

let router = DependencyResolver.getService('router');

ReactDOM.render(router.initialize(), document.getElementById('root'));
registerServiceWorker();