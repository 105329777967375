import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, ButtonGroup, Header } from "semantic-ui-react";
import { ScrollableTable } from "../../../../../layouts/tables";
import ContactEditorModal from "./ContactEditorModal";
import CommonUtils from "../../../../../../utilities/Common/CommonUtils";

const commonUtils = new CommonUtils();

export interface IContactsPaneProps {
    items: any[];
    Gatekeeper: any;
    onModalClose: () => Promise<any>;
    onSave: (data: any) => Promise<any>;
    onDelete: (data: any) => Promise<any>;
}

export interface IContactPaneState {
    modalOpen: boolean;
    selectedItem: any;
}

export default class ContactsPane extends PureComponent<IContactsPaneProps, IContactPaneState> {
    state: IContactPaneState = {
        modalOpen: false,
        selectedItem: undefined,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.setState({ selectedItem: undefined });
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>
                    {item.firstName} {item.lastName}
                    <br />
                    <caption>{item.title}</caption>
                </Table.Cell>
                <Table.Cell>{item.state}</Table.Cell>
                <Table.Cell>{item.email}<br />{item.telephoneNumber}<br />{commonUtils.renderLanguage(item.preferredLanguage)}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="account-contact-management">
                        <ButtonGroup>
                            <Popup
                                trigger={
                                    <Button
                                        icon="edit"
                                        color="teal"
                                        size="tiny"
                                        onClick={() => this.setState({ selectedItem: item }, () => this.toggleModal())}
                                    />
                                }
                                content="Edit the contact's details."
                                basic
                            />
                            <Popup
                                trigger={
                                    <Button
                                        icon="trash"
                                        color="red"
                                        size="tiny"
                                        onClick={() => this.props.onDelete(item)}
                                    />
                                }
                                content="The account contact will be removed permanently"
                                basic
                            />
                        </ButtonGroup>
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(item: any, modalOpen: boolean, Gatekeeper) {
        return (
            <ContactEditorModal
                onSave={async (data: any) => this.props.onSave(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
                item={item}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={2} textAlign={'center'}>
                    <Header>No contacts!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return !items.length ? this.renderNoContentMessage() : items;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <ScrollableTable className="three">
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell>Contact Info</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" floated="right">Actions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
                <Gatekeeper name="account-contact-management">
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='6'>
                                <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Add Contact</Button>
                                {this.renderModal(this.state.selectedItem, this.state.modalOpen, Gatekeeper)}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}