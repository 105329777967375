import React from 'react';
import ApplicationPage from '../../components/pages/applications/ApplicationPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import LocalizedController from '../LocalizedController';

export default class ApplicationController extends LocalizedController {
    private repository: Repository;
    private accountRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: []
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, accountRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.accountRepository = accountRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items: any[] = [];
        let accounts: any[] = [];
        let applications: any[] = [];
        let flatApplications: any[] = [];
        try {
            applications = await this.repository.get();
            accounts = await this.accountRepository.get();

            await Promise.all(accounts.map(async account => {
                let apps = await this.accountRepository.getByPath(`accounts/${account.id}/applications`);
                apps.forEach(app => {
                    flatApplications.push({
                        ...app,
                        account: account.name,
                        accountId: account.id,
                    })
                })
            }))

            applications.forEach(application => {
                let index = flatApplications.findIndex(x => x.id === application.id);
                if (index === -1) { items.push({ ...application, account: 'N/A' }) }
            })
        } catch (e) {
            console.error(e)
            return [];
        }

        return items.concat(flatApplications);
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ApplicationPage
                items={items}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}