import React, { PureComponent } from 'react';
import { ToastContainer, ToastPosition } from 'react-toastify';
import { MobileSkeleton } from '../../layouts/skeleton';
import { MobileHeading } from '../../layouts/heading';
import Footer from '../../sections/Footer';
import '../../../common.css';

export interface IMobilePageProps {
    children?: any;
    header?: any;
    footer?: any;
    routes?: any[];
    headerHidden?: boolean;
}

export default class MobilePage extends PureComponent<IMobilePageProps> {
    private toastConfig = {
        autoClose: parseInt(process.env.REACT_APP_CLIENT_TOAST_AUTOCLOSE || '5000'),
        closeButton: ('true' === process.env.REACT_APP_CLIENT_TOAST_CLOSEBUTTON),
        draggable: ('true' === process.env.REACT_APP_CLIENT_TOAST_DRAGGABLE),
        limit: parseInt(process.env.REACT_APP_CLIENT_TOAST_LIMIT || '0'),
        newestOnTop: ('true' === process.env.REACT_APP_CLIENT_TOAST_NEWESTONTOP),
        position: ('top-center') as ToastPosition,
        pauseOnHover: ('true' === process.env.REACT_APP_CLIENT_TOAST_PAUSEONHOVER),
    }

    render() {
        const { header, children, footer, routes, headerHidden } = this.props;

        if (headerHidden) {
            return (
                <MobileSkeleton>
                    {children}
                    {footer}
                </MobileSkeleton>
            );
        }

        return (
            <div>
                <MobileSkeleton>
                    <ToastContainer
                        closeButton={this.toastConfig.closeButton}
                        newestOnTop={this.toastConfig.newestOnTop}
                        limit={this.toastConfig.limit}
                        autoClose={this.toastConfig.autoClose}
                        position={this.toastConfig.position}
                        pauseOnHover={this.toastConfig.pauseOnHover}
                        draggable={this.toastConfig.draggable}
                    />
                    <MobileHeading
                        routes={routes}
                        header={
                            header &&
                            React.cloneElement(header, {
                                mobile: true,
                                ...header.props,
                            })
                        }
                    >
                        {children}
                        {footer}
                        <Footer isMobile />
                    </MobileHeading>
                </MobileSkeleton>
            </div>
        );
    }
}
