import { EntityId, IRepositoryActionOptions } from "./Repository";

// Temporary until HATEOAS implemented in API
export default class BasicRepository {
    protected options;

    constructor(protected provider, protected entityFactory, protected collectionFactory, protected securityService, protected resource, options = {}) {
        this.options = Object.assign({}, {
            secure: {
                getOne: true,
                get: true,
                create: true,
                update: true,
                delete: true,
                count: true,
            }
        }, options);
    }

    public async getOne(id: EntityId) {
        return await this.getOneByPath(`${this.resource}/${id}`);
    }

    public async getOneByPath(path: string, options?: IRepositoryActionOptions) {
        let item;

        try {
            let headers;

            if (this.options.secure['getOne']) {
                headers = this.securityService.getHeader();
            }

            item = await this.provider.get(path, { pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return item;
    }

    public async get(options?: IRepositoryActionOptions) {
        return await this.getByPath(this.resource);
    }

    public async find(query: any, options?: IRepositoryActionOptions) {
        let items;
        try {
            let headers;

            if (this.options.secure['get']) {
                headers = this.securityService.getHeader();
            }

            items = await this.provider.get(this.resource, { query, pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return items;
    }

    public async getByPath(path: string, options?: IRepositoryActionOptions) {
        let items;

        try {
            let headers;

            if (this.options.secure['get']) {
                headers = this.securityService.getHeader();
            }

            items = await this.provider.get(path, { pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return items;
    }

    public async create(entity: any, options?: IRepositoryActionOptions) {
        return await this.createByPath(entity, this.resource, options);
    }

    public async createByPath(entity: any, path: string, options?: IRepositoryActionOptions) {
        let result;

        try {
            let headers;

            if (this.options.secure['create']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.post(entity, path, { query: options?.query, pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }

    public async update(entity: any, options?: IRepositoryActionOptions) {
        return await this.updateByPath(entity, `${this.resource}/${entity.id}`, options);
    }

    public async updateByPath(entity: any, path: string, options?: IRepositoryActionOptions) {
        let result;

        try {
            let headers;

            if (this.options.secure['update']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.put(entity, path, { pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }

    public async delete(id: EntityId, options?: IRepositoryActionOptions) {
        return await this.deleteByPath(`${this.resource}/${id}`, options);
    }

    public async deleteByPath(path: string, options?: IRepositoryActionOptions) {
        let result;

        try {
            let headers;

            if (this.options.secure['delete']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.delete(path, { pathIsAbsolute: options?.pathIsAbsolute, headers })
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }

    public async link(id: EntityId, linkedIdField: string, linkedId: EntityId) {
        return await this.linkByPath(`${this.resource}/${id}`, {
            [linkedIdField]: linkedId,
        });
    }

    public async linkByPath(path: string, query: any, options?: IRepositoryActionOptions) {
        let result;
        try {
            let headers;

            if (this.options.secure['link']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.link(path, { query, pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }

    public async unlink(id: EntityId, linkedIdField: string, linkedId: EntityId) {
        return await this.unlinkByPath(`${this.resource}/${id}`, {
            [linkedIdField]: linkedId,
        });
    }

    public async unlinkByPath(path: string, query: any, options?: IRepositoryActionOptions) {
        let result;
        try {
            let headers;

            if (this.options.secure['unlink']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.unlink(path, { query, pathIsAbsolute: options?.pathIsAbsolute, headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }

    public async count(conditions: any) {
        let result;

        try {
            let headers;

            if (this.options.secure['count']) {
                headers = this.securityService.getHeader();
            }

            result = await this.provider.count(conditions, { headers });
        } catch (e) {
            if (401 === e.statusCode) {
                this.securityService.handleAuthenticationError();
            }

            throw e;
        }

        return result;
    }
}