import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';

export interface IRouteMenuItemFactory {
    create(activePath:string)
}

export default class RouteMenuItemFactory implements IRouteMenuItemFactory {
    protected routes;

    constructor(routes = []) {
        this.routes = Array.isArray(routes) ? routes : Object.values(routes);
    }

    create(activePath) {
        return this.routes
            .filter(({ title, icon }) => title || icon)
            .map(({ title, path = '/', position, icon, header }, i) => {
                const content = icon ? <Icon name={icon} /> : title;

                return (
                    <Menu.Item as='a' active={path === activePath} href={path} position={position} header={header}>{content}</Menu.Item>
                );
            });
    }
}