import React, { PureComponent } from 'react';
import { Message as SemanticMessage, MessageProps } from 'semantic-ui-react';
import './styles.css';

export interface IMessageProps extends MessageProps {

}
export default class Message extends PureComponent<IMessageProps> {
    render() {
        const { children, icon, header, content, color, size, hidden, attached, error, warning, info, compact } = this.props;
        return (
            <div className='message'>
                <SemanticMessage
                    icon={icon}
                    header={header}
                    content={content}
                    color={color}
                    size={size}
                    hidden={hidden}
                    attached={attached}
                    error={error}
                    warning={warning}
                    info={info}
                    children={children}
                    compact={compact}
                />
            </div>
        )
    }
}