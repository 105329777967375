import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import LocalizedPage from '../../LocalizedPage';
import Component from "../../../layouts/component";

export interface IArchetypeOverviewPageProps {
    createRoutes?: Function;
    item: any;
    Gatekeeper: any;
    panes: any[];
}

export default class ArchetypeOverviewPage extends PureComponent<IArchetypeOverviewPageProps> {
    render() {
        const { createRoutes, item, Gatekeeper, panes } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage item={item} Gatekeeper={Gatekeeper} panes={panes} />
                    {/* <MobilePage items={items} /> */}
                </Component>
            </Page>
        );
    }
}
