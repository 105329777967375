import React, { PureComponent } from "react";
import "./styles.css";
import {
    Grid,
    Card,
    Divider,
    CardGroup,
    Segment,
    Container,
    Tab,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../../layouts/skeleton";

export interface IDesktopPageProps {
    createRoutes?: Function;
    item?: any;
    Gatekeeper: any;
    panes: any[];
}
export interface IDesktopPageState { }

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {};

    render() {
        const { Gatekeeper, panes, item } = this.props;

        return (
            <DesktopSkeleton>
                <Container>
                    <Grid padded>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <CardGroup>
                                    <Gatekeeper name="archetype-view">
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Header>
                                                    {item?.name}
                                                </Card.Header>
                                                {/* <Divider />
                                                    <Card.Description>
                                                        {item?.description}
                                                    </Card.Description> */}
                                            </Card.Content>
                                        </Card>
                                    </Gatekeeper>
                                </CardGroup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <Container>
                    <Segment padded>
                        <h4>Overview</h4>
                        <Divider />
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                    </Segment>
                </Container>
            </DesktopSkeleton>
        );
    }
}
