import React from 'react';
import TemplatesEditPage from '../../components/pages/Templates/TemplatesEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import BasicRepository from '../../repositories/BasicRepository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class TemplatesEditController extends LocalizedController {
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private repository: BasicRepository;
    private layoutsRepository: BasicRepository;
    private sectionLayoutsRepository: BasicRepository;

    state = {
        item: undefined,
        layouts: [],
        sections: [],
    };

    constructor(props) {
        super(props);
        const [router, routeMenuItemFactory, localizationService, repository, layoutsRepository, sectionLayoutsRepository, gatekeeperFactory] = props.args;

        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.repository = repository;
        this.layoutsRepository = layoutsRepository;
        this.sectionLayoutsRepository = sectionLayoutsRepository;
    }

    async componentDidMount() {
        await this.fetchAll();
    }

    async fetchAll() {
        let item = await this.getTemplate();
        let layouts = await this.getTemplateLayouts();
        let sections = await this.getTemplateSectionLayouts();

        this.setState({
            item,
            layouts,
            sections,
        })
    }

    async getTemplate() {
        let websiteInfo;
        let item;

        try {
            websiteInfo = await this.repository.get();
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = this.repository.getByPath(`websites/${websiteInfo[0].id}/templates/${this.params?.id}`);
        } catch (e) {
            throw new Error(e);
        }
        return item;
    }

    async getTemplateLayouts() {
        let websiteInfo;
        let layouts;
        let rawLayouts;

        try {
            websiteInfo = await this.repository.get();
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            rawLayouts = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates/${this.params?.id}/layouts`);
            if (rawLayouts) {
                layouts = await rawLayouts.map(async (layout): Promise<any> => {
                    let locations;
                    try {
                        locations = await this.layoutsRepository.getByPath(`layouts/${layout.id}/locations`)
                        layout.locations = locations.map(location => {
                            return location.name;
                        })
                    }
                    catch (e) {
                        throw new Error(e);
                    }
                    return layout;
                })
                layouts = await Promise.all(layouts)
            } else {
                layouts = rawLayouts;
            }
        } catch (e) {
            throw new Error(e);
        }
        return layouts;
    }

    async getTemplateSectionLayouts() {
        let websiteInfo;
        let sections;

        try {
            websiteInfo = await this.repository.get();
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            sections = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates/${this.params?.id}/sectionlayouts`);
        } catch (e) {
            throw new Error(e);
        }
        return sections;
    }

    private async createTemplateLayout(data: any) {
        let layoutInfo = { name: data.name };
        let layoutLocations: string[] = [];
        layoutLocations = data.options;
        if (!layoutLocations.includes('main')) {
            layoutLocations.unshift('main');
        }
        let layout;
        let websiteInfo;
        try {
            websiteInfo = await this.repository.get();
            layout = await this.layoutsRepository.create(layoutInfo);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.linkByPath(`websites/${websiteInfo[0].id}/templates/${this.params?.id}`, { layoutId: layout.id });
            layoutLocations.forEach(element => {
                let locationEntity = { name: element };
                this.layoutsRepository.createByPath(locationEntity, `layouts/${layout.id}/locations`)
            });
            await this.fetchAll();
        } catch (e) {
            throw new Error(e);
        }
    }

    private async createTemplateSectionLayout(data: any) {
        let websiteInfo;
        let layoutInfo = { name: data.name };

        try {
            websiteInfo = await this.repository.get();
            let layoutsection = await this.sectionLayoutsRepository.create(layoutInfo);
            console.log('layoutSection', layoutsection);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.linkByPath(`websites/${websiteInfo[0].id}/templates/${this.params?.id}`, { sectionLayoutId: layoutsection.id })
            await this.fetchAll();
        } catch (e) {
            throw new Error(e);
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Template Management'), value: '/website-template-management' },
            { key: 1, text: this.state.item ? this.state.item!['name'] : '', value: undefined },
        ]
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();
        const { item, layouts, sections } = this.state;

        return this.prepare(
            <TemplatesEditPage
                Gatekeeper={Gatekeeper}
                item={item}
                layouts={layouts}
                sections={sections}
                breadcrumbLevels={this.generateBreadcrumbs()}
                handleCreateTemplateLayout={async (data) => this.createTemplateLayout(data)}
                handleCreateTemplateSectionLayout={async (data) => this.createTemplateSectionLayout(data)}
            />
        )
    }
}