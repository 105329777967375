import React, { PureComponent } from "react";
import { ToastContainer, ToastPosition } from 'react-toastify';
import { DesktopSkeleton } from "../../layouts/skeleton";
import { DesktopHeading } from "../../layouts/heading";
import { Footer } from "../../sections";
import "react-toastify/dist/ReactToastify.css";
import '../../../common.css';
import { Button, Icon, Modal } from "semantic-ui-react";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export interface IDesktopPageProps {
    children?: any;
    header?: any;
    footer?: any;
    routes?: any[];
    headerHidden?: boolean;
}

const pagesWithoutModal = [
    '/login',
    '/signup',
    '/reset',
    '/activate',
]

const verbsWithoutState = [
    'associate',
    'create',
    'edit',
]
export default class DesktopPage extends PureComponent<IDesktopPageProps> {
    state = {
        // openModal: false,
        openModal: 'Notification' in window && Notification.permission !== "granted" && !pagesWithoutModal.includes(window.location.pathname) && !cookies.get('allow-notifications'),
    }
    private toastConfig = {
        autoClose: parseInt(process.env.REACT_APP_CLIENT_TOAST_AUTOCLOSE || '5000'),
        closeButton: ('true' === process.env.REACT_APP_CLIENT_TOAST_CLOSEBUTTON),
        draggable: ('true' === process.env.REACT_APP_CLIENT_TOAST_DRAGGABLE),
        limit: parseInt(process.env.REACT_APP_CLIENT_TOAST_LIMIT || '0'),
        newestOnTop: ('true' === process.env.REACT_APP_CLIENT_TOAST_NEWESTONTOP),
        position: (process.env.REACT_APP_CLIENT_TOAST_POSITION || 'top-right') as ToastPosition,
        pauseOnHover: ('true' === process.env.REACT_APP_CLIENT_TOAST_PAUSEONHOVER),
    }

    componentDidMount(): void {
        if (!pagesWithoutModal.includes(window.location.pathname) && !verbsWithoutState.some((v) => window.location.href.indexOf(v) >= 0)) {
            localStorage.setItem('last_visited_page', window.location.pathname);
        }
    }

    private handleRefusalClick() {
        cookies.set('allow-notifications', 'false')
        this.setState({ openModal: false })
    }

    private handleOnClick() {
        cookies.set('allow-notifications', 'true')
        this.setState({ openModal: false })
        Notification.requestPermission();
    }

    private renderNotificationModal() {
        return (
            <Modal
                open={this.state.openModal}
                size='tiny'
            >
                <Modal.Header icon='bell'><Icon name='bell' />Hello there</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>In order to enhance your Dreamship experience, we need your consent to send notifications through your browser. These notifications are meant to inform you about new information regarding, but not limited to, new orders, messages, and more.</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={() => this.handleRefusalClick()}
                    >
                        <Icon name="x" />
                        No, I do not consent.
                    </Button>
                    <Button
                        color="green"
                        onClick={() =>
                            this.handleOnClick()
                        }
                    >
                        <Icon name="checkmark" /> Yes, I consent.
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const { children, header, footer, routes, headerHidden } = this.props;

        return (
            <div>
                <DesktopSkeleton>
                    <ToastContainer
                        closeButton={this.toastConfig.closeButton}
                        newestOnTop={this.toastConfig.newestOnTop}
                        limit={this.toastConfig.limit}
                        autoClose={this.toastConfig.autoClose}
                        position={this.toastConfig.position}
                        pauseOnHover={this.toastConfig.pauseOnHover}
                        draggable={this.toastConfig.draggable}
                    />
                    {this.renderNotificationModal()}
                    {!headerHidden && (
                        <DesktopHeading routes={routes}>{header}</DesktopHeading>
                    )}
                    {children}
                    {footer}
                    <Footer />
                </DesktopSkeleton>
            </div>
        );
    }
}
