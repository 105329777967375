import React from 'react';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import CommonUtils from '../../utilities/Common/CommonUtils';
import DateUtils from '../../utilities/Date/DateUtils';
import OrderPanePage from '../../components/pages/orders/OrderPanePage';

const commonUtils = new CommonUtils()
const dateUtils = new DateUtils()

export default class OrdersPaneController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        orders: {},
        countdownDate: Date.now() + 10000,
        hasNewOrders: false,
        exceptions: [],
        pauseButtonMap: [],
        displaySku: true,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.getByPath('orders?sort=dueDate:desc&limit=30');
        } catch (e) {
            console.error(e)
            return [];
        }
        return items;
    }

    private async updatePrice(data: any) {
        try {
            await this.repository.update(data);
            await this.fetchAll();
        } catch (e) {
            console.error(e);
        }

    }

    async componentWillMount() {
        await this.fetchAll();
    }

    private async fetchAll() {
        let { countdownDate, hasNewOrders, orders } = await this.getAll();
        let exceptions = await this.getAvailabilities();
        let pauseButtonMap = await this.generateAvailabilitiesPauseMap();
        let displaySku = await this.getStorefrontSettings();

        this.setState({
            countdownDate,
            hasNewOrders,
            orders,
            exceptions,
            pauseButtonMap,
            displaySku,
        })
    }

    private async getAvailabilities() {
        let storefronts;
        let exceptions = [];
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                exceptions = await this.repository.getByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions`);
                exceptions = exceptions.filter((exception: any) => 'holiday' !== exception.type)
            }
        } catch (e) {
            console.error('Issue with storefronts:', e);
        }
        return exceptions;
    }

    private generateText(time) {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        let value = { hours, minutes }
        let text = '';
        let lang = JSON.parse(localStorage.getItem('lang')!)
        const hoursText = lang == 'en' ? 'hour' : 'heure';
        const hoursTextPlur = lang == 'en' ? 'hours' : 'heures';
        const and = lang == 'en' ? 'and' : 'et';

        if (hours > 0) {
            text = `${hours} ${commonUtils.plur(hoursText, hoursTextPlur, hours)} ${minutes > 0 ? and + ' ' + minutes + ' ' + commonUtils.plur('minute', 'minutes', minutes) : ''}`;
        } else if (hours === 0) {
            text = `${minutes} ${commonUtils.plur('minute', 'minutes', minutes)}`
        }

        return { value, text };
    }

    private async generateAvailabilitiesPauseMap() {
        let storefronts, settings;
        let pauseButtonMap: any[] = [];
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                settings = storefronts[0].settings.orders.averageFulfillment || { unit: 'minutes', amount: 15 };
                if (settings.unit && 'minutes' === settings.unit) {
                    let res = this.generateText(settings.amount);
                    pauseButtonMap.push({
                        key: 1,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 2);
                    pauseButtonMap.push({
                        key: 2,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 2,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 4);
                    pauseButtonMap.push({
                        key: 4,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 4,
                        text: res.text,
                    });
                    res = this.generateText(settings.amount * 8);
                    pauseButtonMap.push({
                        key: 8,
                        value: res.value,
                        unit: settings.unit,
                        amount: settings.amount * 8,
                        text: res.text,
                    });
                }
            }
        } catch (e) {
            console.error('Issue with settings:', e);
        }

        return pauseButtonMap;
    }

    private async setExceptions(data: any) {
        let storefronts;
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                data['storefrontId'] = storefronts[0].id;
                await this.repository.createByPath(data, `storefronts/${storefronts[0].id}/availabilities/exceptions`);
            }
        } catch (e) {
            console.error('Issue with creating an exception: ', e);
        }
        try {
            await this.fetchAll();
        } catch (e) {
            console.error('Issue with fetching everything:', e);
        }
    }

    private async resumeOrders() {
        let storefronts, exceptions;
        try {
            storefronts = await this.repository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                exceptions = await this.repository.getByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions`);
                exceptions = exceptions.filter((exception: any) => 'holiday' !== exception.type)
                if (exceptions && exceptions.length) {
                    // assumption: only one exception at a given time
                    await this.repository.deleteByPath(`storefronts/${storefronts[0].id}/availabilities/exceptions/${exceptions[0].id}`)
                }
            }
        } catch (e) {
            console.error('Issue with resuming orders', e);
        }

        try {
            await this.fetchAll();
        } catch (e) {
            console.error('Issue with fetching everything:', e);
        }
    }

    private async getAll() {
        let orders = await this.get();

        let today = orders.filter(order => dateUtils.isToday(new Date(order.dueDate)) && order.status !== 'cancelled' && order.status !== 'delivered')//.reverse();
        let past = orders.filter(order => dateUtils.isPast(new Date(order.dueDate)) || order.status === 'cancelled' || order.status === 'delivered')//.reverse();

        let future = orders.filter(order => dateUtils.isFuture(new Date(order.dueDate)) && order.status !== 'cancelled' && order.status !== 'delivered')//.reverse();
        let result = {
            today,
            past,
            future,
        };
        let hasNewOrders = (
            this.state.orders?.['today'] && this.state.orders?.['today'].length < today.length
        )

        return {
            countdownDate: Date.now() + 60000,
            hasNewOrders,
            orders: result,
        };
    }

    private async getStorefrontSettings() {
        let storefronts: any[] = [];
        try {
            storefronts = await this.repository.getByPath('storefronts');
        } catch (e) {
            console.error(e);
            return true;
        }
        if (storefronts.length) {
            let storefront = storefronts[0];
            return storefront.settings.orders.emails.template.displaySku;
        }
        return true;
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/store-management/orders/${id}`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    private async getInvoice(orderId: string) {
        let orderItems;
        let showSku;
        let cart;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            orderItems = await this.repository.getOneByPath(`orders/${orderId}/items`);
        } catch (e) {
            console.error(e)
            return [];
        }

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            cart = await this.repository.getOneByPath(`orders/${orderId}`);
            let storefront = await this.repository.getOneByPath(`storefronts/${cart.storefrontId}`);
            showSku = storefront.settings.orders.emails.template.displaySku;
        } catch (e) {
            showSku = true;
        }

        const ordersPromises = orderItems.map(async (item) => {
            try {
                // let product = await this.repository.getOneByPath(`products/${item.item.id}`);
                let contents = await this.repository.getByPath(`products/${item.item.id}/contents`);
                if (!contents || !contents.length) {
                    contents = await this.repository.getByPath(`definitions/${item.item.definitionId}/contents`);
                    if (contents) {
                        let content = contents.filter((content) => content.language == JSON.parse(localStorage.getItem('lang')!));
                        item.item.title = content.title;
                    }
                } else {
                    let content = contents.filter((content) => content.language == JSON.parse(localStorage.getItem('lang')!))[0];
                    item.item.title = content.title;
                }
                if (!showSku) {
                    delete item.item.sku;
                }
            } catch (e) {
                console.error(e);
            }
            return item;
        });

        orderItems = await Promise.all(ordersPromises);

        return orderItems;
    }

    private async getInvoiceInformation(invoiceId: string) {
        let invoice;
        let contact;
        let shipping;

        try {
            invoice = await this.repository.getByPath(`invoices/${invoiceId}`)
        } catch (e) {
            console.error(e);
        }

        const ordersPromises = invoice.lineItems.map(async (item) => {
            try {
                let product = await this.repository.getOneByPath(`products/${item.item.id}`);
                item.item.attributes = product.attributes;
            } catch (e) {
                console.error(e);
            }
            return item;
        });

        await Promise.all(ordersPromises);

        try {
            contact = await this.repository.getByPath(`invoices/${invoiceId}/contact`)
        } catch (e) {
            console.error(e);
        }

        try {
            shipping = await this.repository.getByPath(`invoices/${invoiceId}/shipping`)
        } catch (e) {
            console.error(e);
        }

        return { invoice, contact, shipping };
    }

    private async updateStatus(data: any[]) {
        for (let index = 0; index < data.length; index++) {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                await this.repository.updateByPath(data[index], `orders/${this.params?.id}`);
            } catch (e) {
                console.error(e);
            }

            try {
                await this.fetchAll();
            } catch (e) {
                console.error(e);
            }
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Orders'), value: undefined },
        ]
    }

    render() {
        const { orders, exceptions, pauseButtonMap } = this.state;

        let countdownDate = exceptions.length > 0 ? new Date(exceptions[0]['endDate']).getTime() + 90000 : this.state.countdownDate;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <OrderPanePage
                items={orders}
                exceptions={exceptions}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onPathChange={(path) => this.changeSettings(path)}
                quickSave={(data) => this.updatePrice(data)}
                setExceptions={(data) => this.setExceptions(data)}
                refreshOrders={async () => this.fetchAll()}
                getInvoice={async (invoiceId) => this.getInvoice(invoiceId)}
                getInvoiceInformation={async (invoiceId) => this.getInvoiceInformation(invoiceId)}
                countdownDate={countdownDate}
                quickSaveStatus={(data) => this.updateStatus(data)}
                hasNewOrders={this.state.hasNewOrders}
                lastUpdatedDate={new Date().toLocaleString()}
                resumeOrders={() => this.resumeOrders()}
                pauseButtonMap={pauseButtonMap}
                displaySku={this.state.displaySku}
            />
        )
    }
}