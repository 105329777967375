import React, { PureComponent } from 'react';
import { Form, Button, InputOnChangeData } from 'semantic-ui-react';
import { LoadingMessage, Message } from '../../layouts/messages';

export interface ILoginFormProps {
    onSubmit: (values: ILoginFormState) => any;
    localizationService?: any;
}

export interface ILoginFormState {
    error?: any;
    loading?: boolean;
    email?: string;
    password?: string;
}

export default class LoginForm extends PureComponent<ILoginFormProps> {
    state: ILoginFormState = {};

    static defaultProps = {
        onSubmit: values => values,
    };

    translate(key: string): string {
        if (this.props.localizationService) {
            return this.props.localizationService.translate(key);
        }
        return key;
    }

    handleChange({ name, value }: InputOnChangeData) {
        this.setState({ [name]: value });
    }

    async handleSubmit() {
        try {
            this.setState({ error: undefined, loading: true })
            await this.props.onSubmit(this.state);
        } catch (e) {
            this.setState({ error: e, loading: false })
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form size='large' onSubmit={() => this.handleSubmit()} error={!!error}>
                <Form.Input
                    fluid
                    name="email"
                    icon='user'
                    iconPosition='left'
                    placeholder={this.translate('E-mail address')}
                    type='email'
                    onChange={(e, value) => this.handleChange(value)}
                />

                <Form.Input
                    fluid
                    name="password"
                    icon='lock'
                    iconPosition='left'
                    placeholder={this.translate('Password')}
                    type='password'
                    onChange={(e, value) => this.handleChange(value)}
                />

                <LoadingMessage hidden={!loading} size='large' content={this.translate('We are currently trying to log you in')} header={this.translate('One moment')} />

                <Message
                    error
                    header={this.translate('Authentication Failed')}
                    content={this.translate('You have not entered the proper credentials')}
                />

                <Button color='teal' fluid size='large' disabled={loading}>
                    {this.translate('Login')}
                </Button>
            </Form>
        );
    }
}