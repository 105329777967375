import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
// import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface ICatalog {
    title: string;
    language: string;
    content: any;
}

export interface IProductCatalogEditPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    localizationService?: any;
    breadcrumbLevels?: any;
    onSave: (data: any) => any;
    onDelete?: () => void;
    onLink?: (data: any) => any;
    onUnlink?: (data: any) => any;
    item?: ICatalog;
    linkedProducts?: any[];
    allProductDefinitions?: any[];
}

export default class ProductCatalogEditPage extends PureComponent<IProductCatalogEditPageProps> {
    render() {
        const {
            createRoutes,
            Gatekeeper,
            localizationService,
            breadcrumbLevels,
            onSave,
            onDelete,
            onLink,
            onUnlink,
            item,
            linkedProducts,
            allProductDefinitions,
        } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        onSave={onSave}
                        onDelete={onDelete}
                        onLink={onLink}
                        onUnlink={onUnlink}
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        linkedProducts={linkedProducts}
                        allProductDefinitions={allProductDefinitions}
                    />
                    {/* <MobilePage Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} /> */}
                </Component>
            </Page>
        );
    }
}
