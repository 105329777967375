import React, { PureComponent } from 'react';
import { MobileSkeleton } from '../../layouts/skeleton';
import { BrandedEntryContent } from '../../layouts/sections';
import { Message } from '../../layouts/messages';
import { Button } from 'semantic-ui-react';
import './styles.css';

export interface IActivationMobilePageProps {
    onAcknowledge: Function;
    message?: string;
    activationSuccessful?: boolean;
}

export default class MobilePage extends PureComponent<IActivationMobilePageProps> {
    render() {
        const { onAcknowledge, message, activationSuccessful } = this.props;

        return (
            <MobileSkeleton>
                <BrandedEntryContent className="signup-page" useFullLogo>
                    <Message
                        header={activationSuccessful ? "Account activated" : "Error"}
                        error={!activationSuccessful}
                        content={message}
                    />
                    <Button onClick={() => onAcknowledge()}>Ok</Button>
                </BrandedEntryContent>
            </MobileSkeleton>
        )
    }
}