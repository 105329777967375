import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../LocalizedPage';
import Component from '../../layouts/component';

export interface IPagesPageProps {
    createRoutes?: Function;
    items: any;
    submitted: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any,
    breadcrumbLevels?: any,
    onChange: (path: string) => any,
    hasProvisionned: boolean;
}

export default class PagesPage extends PureComponent<IPagesPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
            onDoubleClick,
            localizationService,
            breadcrumbLevels,
            submitted,
            onChange,
            hasProvisionned,
        } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        items={items}
                        submitted={submitted}
                        Gatekeeper={Gatekeeper}
                        onDoubleClick={onDoubleClick}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        onChange={onChange}
                        hasProvisionned={hasProvisionned}
                    />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}
