import React, { PureComponent } from "react";
import {
    Segment,
    Header,
    Button,
    Form,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";

export interface IUndefinedSectionTemplateProps {
    section: any;
}

export default class UndefinedSectionTemplate extends PureComponent<
    IUndefinedSectionTemplateProps
> {
    render() {
        const { section } = this.props;
        console.log('section', section)
        return (
            <div>
                <Header>{section.title}</Header>
                <caption>{section.type}</caption>
                <p>A preview for this section is currently unavailable.</p>
            </div>
        )
    }
}