import React from 'react';
import BlogEditPage from '../../components/pages/BlogEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class BlogEditController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        webmeta: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;
        let webmeta;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            webmeta = await this.repository.getByPath(`posts/${this.params?.id}/webmetas`);
        } catch (e) {
            return;
        }

        return { item, webmeta };
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        try {
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
                ...data,
            });

            this.setState({
                item: {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    id: this.params?.id,
                    ...data,
                }
            })
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/posts');
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        let result = await this.get();
        let item = result?.item;
        let webmeta = result?.webmeta;

        this.setState({
            item,
            webmeta,
        });
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Blog'), value: '/posts' },
            { key: 2, text: this.state.item ? this.state.item!['title'] : '', value: undefined },
        ]
    }

    render() {
        const { item, webmeta } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <BlogEditPage
                item={item}
                webmeta={webmeta}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}