import React, { PureComponent } from 'react';
import { Button, Card, CardContent, CardDescription, CardGroup, CardHeader, Form, Grid, Icon, Input, Label, Modal, Step } from 'semantic-ui-react';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import QuickTaxCreationForm from './modalforms/QuickTaxCreationForm';
import './styles.css';

const commonUtils = new CommonUtils();

export interface IProductDesktopPageProps {
    item: any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    productDefinitionId: string;
    allTaxes: any[];
    definitionTaxes: any[];
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    handleLinkTax: (data: any) => any;
    handleUnlinkTax: (data: any) => any;
    handleCreateTax: (data: any) => any;
}

export default class DesktopPage extends PureComponent<IProductDesktopPageProps> {
    static defaultProps = {
        item: undefined,
        createProperty: () => { },
        deleteProductAttribute: () => { },
        allTaxes: [],
    };

    state = {
        openModal: false,
        openSelectionModal: false,
        attribute: undefined,
    }

    onChange(key, value) {
        if ((value !== undefined && value !== null) && (!isNaN(value))) {
            if (this.props.onChange) {
                this.props.onChange(key, value);
            }
        }
    }

    private displayModal(openModal: boolean = true, attribute: any = undefined) {
        this.setState({ openModal, attribute });
    }

    private handleLink(data: string) {
        if (this.props.handleLinkTax) {
            this.props.handleLinkTax(data);
        }
    }

    private handleUnlink(data: string) {
        if (this.props.handleUnlinkTax) {
            this.props.handleUnlinkTax(data);
        }
    }

    private handleCreateTax(data: any) {
        if (this.props.handleCreateTax) {
            this.props.handleCreateTax(data);
            this.setState({ openModal: false })
        }
    }

    private prepareTaxes() {
        const { allTaxes, definitionTaxes } = this.props;
        if (!allTaxes || !definitionTaxes) { return; }
        let results = allTaxes.map((property, index) => {
            const { name, rateType, rate, id } = property;
            const isChecked = definitionTaxes.findIndex((definitionTax) => { return definitionTax.id === id; }) >= 0;
            const icon = isChecked ? <Icon name='check circle' color='blue' /> : <Icon name='check circle' />;
            return (<Card link onClick={() => isChecked ? this.handleUnlink(id) : this.handleLink(id)}>
                <CardContent>
                    <CardHeader>{name}</CardHeader>
                    <CardDescription>{rateType === 'percentage' ? `${rate}%` : commonUtils.renderCurrency(rate)}</CardDescription>
                </CardContent>
                <Card.Content extra textAlign='right'>
                    {icon}
                </Card.Content>
            </Card>
            )
        })
        return results;
    }

    save(attribute) {
        this.props.onSave(attribute);
        this.displayModal(false);
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        const price = this.props.item && this.props.item.price || 0.00;
        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                >
                    <Modal open={this.state.openModal}>
                        <QuickTaxCreationForm type='tax' onSave={(data) => this.handleCreateTax(data)} attribute={this.state.attribute} toggleModal={(display) => this.displayModal(false)} createAttribute={(attribute) => this.save(attribute)} />
                    </Modal>
                    <div className="editor-container product-editor">
                        <div>
                            {/* <ReturnButton
                                href={'/products'}
                                itemType={'products'}
                            /> */}
                        </div>
                        <Step.Group widths={'8'}>
                            <Step>
                                <Icon name='info' />
                                <Step.Content>
                                    <Step.Title>General Information</Step.Title>
                                    <Step.Description>Define the basic information of your product.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step>
                                <Icon name='tags' />
                                <Step.Content>
                                    <Step.Title>Attributes and Options</Step.Title>
                                    <Step.Description>Provide product attributes and different options.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step active>
                                <Icon name='dollar' />
                                <Step.Content>
                                    <Step.Title>Pricing and Shipping</Step.Title>
                                    <Step.Description>Provide pricing and shipping information.</Step.Description>
                                </Step.Content>
                            </Step>

                            <Step disabled>
                                <Icon name='checkmark box' />
                                <Step.Content>
                                    <Step.Title>Review and confirm product definition</Step.Title>
                                </Step.Content>
                            </Step>
                        </Step.Group>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Group>
                                        <label style={{
                                            display: 'block',
                                            margin: '0 0 .28571429rem 0',
                                            color: 'rgba(0,0,0,.87)',
                                            fontWeight: 700,
                                            textTransform: 'none'
                                        }}>{'Price'}</label>
                                        <Input labelPosition='right' type='number' placeholder='Price' defaultValue={this.props.item && this.props.item.price} onChange={(e) => this.onChange('price', parseFloat(e.target.value))}>
                                            <Label basic>$</Label>
                                            <input step="0.01" min="0" type="number" name="price" defaultValue={parseFloat(price).toFixed(2)}
                                                pattern="^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$" />
                                        </Input>
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <label style={{
                                        display: 'block',
                                        margin: '0 0 .28571429rem 0',
                                        color: 'rgba(0,0,0,.87)',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}>{'Taxes'}</label>
                                    <CardGroup itemsPerRow={4}>
                                        {this.prepareTaxes()}
                                        <Card link style={{ border: 'dashed 1px grey', backgroundColor: 'rgba(255,255,255,8)' }} onClick={() => this.displayModal()}>
                                            <CardContent>
                                                <CardHeader><Icon name='plus' />Add new tax rate.</CardHeader>
                                            </CardContent>
                                            <CardContent>
                                                <CardDescription>
                                                    Quickly create and add a new tax rate to this product definition.
                                                </CardDescription>
                                            </CardContent>
                                        </Card>
                                    </CardGroup>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button.Group floated="right" className='form-right-toolbar-container'>
                                        <Button as='a' href={`/productdefinitions/${this.props.productDefinitionId}/edit`}>{'Back'}</Button>
                                        <Button as='a' href={`/productdefinitions/${this.props.productDefinitionId}/confirmation`} positive>{'Continue'}</Button>
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
