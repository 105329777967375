import Cookies from 'js-cookie';

/**
 * @abstract
 * @export
 * @class CookieProvider
 */
export default class CookieProvider {

    /**
     * @param {number|string} id
     * @returns {Promise<Object>}
     * 
     * @memberOf CookieProvider
     */
    getOne(id) {
        let item = Cookies.get(id);

        try {
            item = JSON.parse(item);
        } catch (e) {

        }

        return item;
    }

    /**
     * @param {number|string} id
     * @returns {Promise<boolean>}
     * 
     * @memberOf CookieProvider
     */
    has(id) {
        return !! this.getOne(id);
    }

    /**
     * @param {number|string} id
     * @param {Object} item
     * 
     * @memberOf CookieProvider
     */
    update(id, item, options) {
        try {
            item = JSON.stringify(item);
        } catch (e) {

        }

        Cookies.set(id, item, options);
    }

    /**
     * @param {number|string} id
     * 
     * @memberOf CookieProvider
     */
    delete(id, options?) {
        Cookies.remove(id, options);
    }
}