import React, { PureComponent } from 'react';
import { Button, ButtonGroup, Divider, Message } from 'semantic-ui-react';
import { ReturnButton } from '../../controls';
import Breadcrumbs from '../../controls/Breadcrumbs/Breadcrumbs';
import { PostForm } from '../../forms/posts';
import { MobileSkeleton } from '../../layouts/skeleton';
import './styles.css';

export interface IOverviewMobilePageProps {
    title: string;
    language: string;
    content: any;
    featured: boolean;
    slug: string;
    category?: string[];
    date: string;
    status: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
}

export default class MobilePage extends PureComponent<IOverviewMobilePageProps> {
    static defaultProps = {
        title: '',
        language: '',
        content: '',
        featured: false,
        slug: '',
        category: [],
        date: '',
        status: 'draft',
    };

    state = {
        alertDisplayed: true,
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    dismissAlert() {
        this.setState({ alertDisplayed: false });
    }

    renderAlert() {
        return (
            <span>
                {this.state.alertDisplayed && (
                    <Message
                        warning
                        size={'small'}
                        style={{ textAlign: 'center' }}
                    >
                        <Message.Header>
                            Manage Your Blog Posts on Desktop
                        </Message.Header>
                        <Divider />
                        <Message.Content>
                            For an optimal experience, we recommend you update
                            your blog post's content on a desktop computer.
                        </Message.Content>
                        <br />
                        <Button
                            fluid
                            color={'instagram'}
                            onClick={() => this.dismissAlert()}
                        >
                            Dismiss
                        </Button>
                    </Message>
                )}
            </span>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="blog-management">
                    <div style={{ marginBottom: '50px' }}>
                        {/* {this.renderAlert()} */}
                        <div className="blog-editor-container">
                            <PostForm
                                title={this.props.title}
                                slug={this.props.slug}
                                language={this.props.language}
                                featured={this.props.featured}
                                content={this.props.content}
                                category={this.props.category}
                                date={this.props.date}
                                status={this.props.status}
                                onSubmit={(data) => this.props.onSave(data)}
                                readOnly={true}
                                onDelete={this.props.onDelete}
                                localizationService={this.props.localizationService}
                            />
                        </div>
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
