import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IInvoicePageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any;
    breadcrumbLevels?: any;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
}

export default class InvoicePage extends PureComponent<IInvoicePageProps> {
    render() {
        const { createRoutes, items, Gatekeeper, onDoubleClick, localizationService, breadcrumbLevels, onPathChange, quickSave } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        items={items}
                        Gatekeeper={Gatekeeper}
                        onDoubleClick={onDoubleClick}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        onPathChange={onPathChange}
                        quickSave={quickSave}
                    />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}
