import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';

export interface IComponent {
    name: string;
}

export interface IComponentEditPageProps extends IPageProps {
    item?: IComponent;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
}

export default class ComponentEditPage extends PureComponent<IComponentEditPageProps> {
    render() {
        const { createRoutes, item, onChange, onSave, onDelete, Gatekeeper } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        name={item && item.name}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}