import React from 'react';
import BillingPlanSettingsPage from '../../components/pages/SettingsPage/BillingPlanSettingsPage';
import LocalizedController from '../LocalizedController';

export default class BillingPlanController extends LocalizedController {
    state = {};

    constructor({ args: [router, routeMenuItemFactory, localizationService], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
    }

    protected changeSettings(path) {
        this.router.redirect(`/settings/${path}`);
    }

    render() {
        return this.prepare(
            <BillingPlanSettingsPage
                
                onChange={(path) => this.changeSettings(path)}
            />
        )
    }
}