import React from 'react';
import DeploymentsPage from '../../components/pages/deployments';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { Repository } from '../../repositories';
import { EntityId } from '../../repositories/Repository';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class WebsiteDeploymentController extends LocalizedController {
    private localizationService: LocalizationService;
    private applicationRepository: Repository;
    private securityService: SecurityService;

    state = {
        items: [],
        hasProvisionned: false,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, applicationRepository] = props.args;

        this.localizationService = localizationService;
        this.securityService = securityService;
        this.applicationRepository = applicationRepository;
    }

    private async getDeployments(applicationId: EntityId) {
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/deployments`)
            items.reverse();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    private async hasProvisionned() {
        const applicationId = this.securityService.getApplicationId();
        let items: any[] = [];

        try {
            items = await this.applicationRepository.getByPath(`applications/${applicationId}/provisions`)
        } catch (e) {
            console.error(e);
            return false;
        }

        return items.length > 0;
    }

    private async deploy() {
        const applicationId = this.securityService.getApplicationId();
        try {
            await this.applicationRepository.createByPath({}, `applications/${applicationId}/deployments`);
            await this.getDeploymentData();
        } catch (e) {
            console.error(e)
        }
    }

    private extractDeploymentInfo(items: any[]) {
        let refinedItems = items.map(item => {
            const { date, endDate, state } = item;
            let timeElapsed = differenceInSeconds(Date.parse(endDate), Date.parse(date));
            return ({ date, timeElapsed, state });
        })
        return refinedItems;
    }

    async componentDidMount() {
        await this.getDeploymentData();
    }

    private async getDeploymentData() {
        const applicationId = this.securityService.getApplicationId();
        let items = await this.getDeployments(applicationId);
        items = this.extractDeploymentInfo(items);
        let hasProvisionned = this.hasProvisionned();

        this.setState({
            items,
            hasProvisionned,
        });
    }

    protected onChange(path) {
        this.router.redirect(`/website-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Deployments'), value: undefined },
        ]
    }

    render() {
        const { items, hasProvisionned } = this.state;
        return this.prepare(
            <DeploymentsPage
                localizationService={this.localizationService}
                items={items}
                onDeploy={() => this.deploy()}
                onRefresh={() => this.getDeploymentData()}
                hasProvisionned={hasProvisionned}
                onChange={(path) => this.onChange(path)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                
            />
        )
    }
}