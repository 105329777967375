import React from 'react';
import { controllers } from 'tramway-router-react-strategy';
import { NotFoundPage } from '../components/pages';
import { RouteMenuItemFactory } from '../factories';
const { NotFoundController } = controllers;

export default class PageNotFoundController extends NotFoundController {
    protected routeMenuItemFactory: RouteMenuItemFactory;
    protected location: any;
    protected router;

    constructor({ args: [router, routeMenuItemFactory], ...props }) {
        super(props);
        this.routeMenuItemFactory = routeMenuItemFactory;
        this.router = router;
    }

    render() {
        let routes = []//this.routeMenuItemFactory.create(this.router.getPathName());
        return <NotFoundPage routes={routes} />
    }
}