import React from 'react';
import ProductCatalogPage from '../../components/pages/productcatalog/ProductCatalogPage';
import ProductPage from '../../components/pages/products/ProductPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class ProductCatalogController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: [],
        submitted: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    private async onDoubleClick(id: any) {
        // this.router.redirect(`/catalogs/${id}/edit`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Catalogs'), value: undefined },
        ]
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductCatalogPage
                items={items}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onPathChange={(path)=>this.changeSettings(path)}
            />
        )
    }
}