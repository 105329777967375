import React, { PureComponent } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

export interface IPropertyAttributeFormProps {
    name?: string;
    type?: string;
    value?: any[];
    isMobile?: boolean;
    handleChange: ({ name, value }) => any;
    uniqueKey: number;
    attribute: any;
    toggleModal: (toggleModal: boolean) => void;
    createAttribute: (attribute: any) => void;
}

export interface IPropertyAttributeFormState {
    name: string;
    type: string;
    value: any[];
}

export default class PropertyAttributeForm extends PureComponent<IPropertyAttributeFormProps, IPropertyAttributeFormState> {
    static defaultProps = {
        name: "",
        value: [],
        type: "selection",
        isMobile: false,
        handleChange: ({ name, value }) => { },
        uniqueKey: 0,
        toggleModal: (toggleModal) => { },
        createAttribute: (attribute) => { console.log('saved', attribute) },
    };

    state = {
        name: '',
        type: 'selection',
        value: []
    };

    componentDidMount() {
        if (this.props.attribute) {
            const { name, type, options } = this.props.attribute;
            this.setState({
                value: options.toString(),
                name: name || '',
                type: type || 'property',

            });
        }
    }

    private handleNameChange(name) {
        this.setState({ name: name.target.value });
    }

    handleChange(value) {
        this.setState({ 'value': value.target.value });
    }

    async submit() {
        try {
            const { name, value } = this.state;
            let data = { name, options: [value.toString()], type: 'property' };
            if (this.props.attribute) {
                data['id'] = this.props.attribute.id;
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                data['definitionId'] = this.params?.id;
            }
            await this.props.createAttribute(data);
        } catch (e) {

        }
    }

    render() {
        const { name, isMobile } = this.props;

        return (
            <>
                <Modal.Header>Create a property</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Form onSubmit={() => this.submit()}>
                            <Form.Input label={'Attribute Name'}>
                                <input placeholder="Name" autoFocus defaultValue={this.props.name || this.state.name} onChange={(e) => this.handleNameChange(e)} />
                            </Form.Input>
                            <Form.Input label={'Attribute Value'}>
                                <input placeholder="Value" defaultValue={this.state.value} onChange={(e) => this.handleChange(e)} />
                            </Form.Input>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        content={this.props.attribute ? "Update Property" : "Create Property"}
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { this.submit(); }}
                        positive
                    />
                </Modal.Actions>
            </>
        )
    }
}