import React, { PureComponent } from "react";
import {
    Grid,
    Menu,
    Segment,
} from "semantic-ui-react";
import "./styles.css";

export interface IWebsiteSettingsTemplate {
    onChange: (path: string) => any;
    activeItem: string;
    localizationService?: any;
    canDeploy?: boolean;
}

export default class WebsiteSettingsTemplate extends PureComponent<IWebsiteSettingsTemplate> {
    translate(key: string): string {
        return this.props.localizationService ? this.props.localizationService.translate(key) : key;
    }

    protected handleItemClick(name) {
        this.props.onChange(name)
    };

    render() {
        return (
            <div className="editor-container">
                <Grid centered>
                    <Grid.Column width={3}>
                        <Menu fluid pointing vertical secondary>
                            <Menu.Item
                                name={this.translate('Website Settings')}
                                content={this.translate('Website Settings')}
                                active={'website-management' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("")}
                            />
                            <Menu.Item
                                name={this.translate('Pages')}
                                content={this.translate('Pages')}
                                active={'pages' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("pages")}
                            />
                            <Menu.Item
                                name={this.translate('Sections')}
                                content={this.translate('Sections')}
                                active={'sections' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("sections")}
                            />
                            {this.props.canDeploy &&
                                <Menu.Item
                                    name={this.translate('Deployments')}
                                    content={this.translate('Deployments')}
                                    active={'deployments' === this.props.activeItem}
                                    onClick={(e) => this.handleItemClick("deployments")}
                                />
                            }
                            <Menu.Item
                                name={this.translate('Messaging Settings')}
                                content={this.translate('Messaging Settings')}
                                active={'messaging-settings' === this.props.activeItem}
                                onClick={(e) => this.handleItemClick("messaging-settings")}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column stretched width={13}>
                        <Segment>{this.props.children}</Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}
