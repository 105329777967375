import React from "react";
import DeployButton from "../components/controls/DeployButton";
import { Repository } from "../repositories";
import { SecurityService } from "../services";

export default class DeployButtonFactory {
    constructor(protected repository: Repository, protected service: SecurityService) { }

    create(hide?: boolean) {
        return (
            <DeployButton
                repository={this.repository}
                service={this.service}
                isMobile={hide}
            />
        )
    }
}