import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Button,
    Icon,
    Form,
    CardGroup,
    Card,
    FormGroup,
    ButtonGroup,
    Divider,
    IconGroup,
} from "semantic-ui-react";
import SettingsTemplate from "../SettingsTemplate";
import { IBillingPlanSettingsPageProps } from "./BillingPlanSettingsPage";
import "./styles.css";

export interface IAccountSettingsDesktopPageProps
    extends IBillingPlanSettingsPageProps {
    onChange: (path: string) => any;
}

export interface IAccountSettingsDesktopPageState {
    open: boolean;
    name: string;
    disableSubmit: boolean;
    checked: boolean;
    accountEmailChecked: boolean;
}

export default class DesktopPage extends PureComponent<
    IAccountSettingsDesktopPageProps,
    IAccountSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        disableSubmit: true,
        checked: false,
        accountEmailChecked: false,
    };

    protected setOpen(open, name) {
        this.setState({ open, name: name });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    render() {
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="billingplan">
                <h2>Billing and Plans</h2>
                <Segment>
                    <Form>
                        <Grid columns={"one"}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>Personal Billing</h3>
                                    <CardGroup itemsPerRow={3}>
                                        <Card>
                                            <Card.Content>
                                                <Card.Description>
                                                    Current monthly bill
                                                </Card.Description>
                                                <Card.Header>$0</Card.Header>
                                            </Card.Content>
                                        </Card>
                                        <Card>
                                            <Card.Content>
                                                <Card.Description>Next payment due</Card.Description>
                                                <Card.Header>-</Card.Header>
                                            </Card.Content>
                                        </Card>
                                        <Card>
                                            <Card.Content>
                                                <Card.Description>Payment information</Card.Description>
                                                <Card.Header>-</Card.Header>
                                            </Card.Content>
                                        </Card>
                                    </CardGroup>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
                <Segment>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormGroup>
                                        <Form.Field width={"11"}>
                                            <h3>Current Plan</h3>
                                        </Form.Field>
                                        <Form.Field>
                                            <ButtonGroup>
                                                <Button>Compare all plans</Button>
                                                <Button.Or />
                                                <Button color="green" disabled>
                                                    Upgrade
                                                </Button>
                                            </ButtonGroup>
                                        </Form.Field>
                                    </FormGroup>
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Header>
                                                <IconGroup>
                                                    <Icon fitted name="star" color="yellow" />
                                                    <Icon name='circle' size="tiny" color='blue' />
                                                </IconGroup> Super Admin
                                            </Card.Header>
                                            <Divider />
                                            <Card.Description>
                                                <Icon fitted name={"checkmark"} color="green" />{" "}
                                                Unlimited sites.
                                                <br />
                                                <Icon fitted name={"checkmark"} color="green" />{" "}
                                                Unlimited access to features.
                                                <br />
                                                <Icon fitted name={"checkmark"} color="green" />{" "}
                                                Unlimited application users.
                                                <br />
                                                <Icon fitted name={"checkmark"} color="green" />{" "}
                                                Unlimited groups.
                                                <br />
                                                <Icon fitted name={"checkmark"} color="green" />{" "}
                                                Unlimited access to resources.
                                                <br />
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </SettingsTemplate>
        );
    }
}
