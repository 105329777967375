import React, { PureComponent } from 'react';
import { Divider } from 'semantic-ui-react';

export interface IHeadingDividerProps {
    category?: any;
    categoryLink?: string;
    children: any;
}

export default class HeadingDivider extends PureComponent<IHeadingDividerProps> {
    render() {
        const { category, categoryLink, children } = this.props;

        return (
            <Divider
                as='h4'
                className='heading'
                horizontal
            >
                <a href={categoryLink}>{children || category}</a>
            </Divider>
        );
    }
}