export default class UserService {
    constructor(protected repository, protected factory) {

    }

    async create(item) {
        const {
            firstName,
            lastName,
            email,
            password,
            password_confirmed,
        } = item;

        if (password_confirmed !== password) {
            throw new Error('Passwords must match');
        }

        if (!password || !firstName || !lastName || !email) {
            throw new Error('We need a bit more information before we can continue')
        }

        let location;

        try {
            location = await this.repository.create({ firstName, lastName, email, password });
        } catch (e) {
            throw e;
        }

        return await this.repository.getOneByPath(location, true);
    }
}