import React, { PureComponent } from 'react';
import { Menu } from 'semantic-ui-react';
import { LocalizedNavigationExtra } from '../NavigationExtra';
import './styles.css';

export interface INavigationProps {
    children?: any;
    routes?: any[];
    fixed?: boolean;
}

export default class Navigation extends PureComponent<INavigationProps> {
    render() {
        const { routes } = this.props;
        const NavigationExtra = LocalizedNavigationExtra();
        return (
            <Menu fixed='top'>
                {routes}
            </Menu>
        );
    }
}