import React, { PureComponent } from 'react';
import { ButtonGroup } from 'semantic-ui-react';
import { LoadingSpinner, ReturnButton } from '../../controls';
import Breadcrumbs from '../../controls/Breadcrumbs/Breadcrumbs';
import { PostForm } from '../../forms/posts';
import { UnauthorizedMessage } from '../../layouts/messages';
import { DesktopSkeleton } from '../../layouts/skeleton';
import './styles.css';

export interface IBlogDesktopPageProps {
    title: string;
    language: string;
    content: any;
    featured: boolean;
    slug: string;
    category?: string[];
    date: string;
    status: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    webmeta?: any;
}

export default class DesktopPage extends PureComponent<IBlogDesktopPageProps> {
    static defaultProps = {
        title: '',
        language: '',
        content: '',
        featured: false,
        slug: '',
        category: [],
        date: '',
        status: 'draft',
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="blog-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="editor-container">
                        <div>
                            {/* <ReturnButton
                                href={'/posts'}
                                itemType={'blog posts'}
                            /> */}
                        </div>
                        <PostForm
                            title={this.props.title}
                            slug={this.props.slug}
                            language={this.props.language}
                            featured={this.props.featured}
                            content={this.props.content}
                            category={this.props.category}
                            date={this.props.date}
                            status={this.props.status}
                            onSubmit={async (data) => this.props.onSave(data)}
                            onDelete={this.props.onDelete}
                            localizationService={this.props.localizationService}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
