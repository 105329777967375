import React, { PureComponent } from "react";
import { Icon, Label, Image, Modal, Button } from "semantic-ui-react";
import * as htmlToImage from "html-to-image";
import ReleaseNotesModalContent from "./ReleaseNotesModalContent";
import "./Footer.css";

export interface IFooterProps {
    isMobile?: boolean,
}
export interface IFooterState {
    modalOpen: boolean,
}

export default class Footer extends PureComponent<IFooterProps, IFooterState> {
    state = {
        modalOpen: false,
    }

    static defaultProps = {
        isMobile: false,
    }

    capture() {
        const element = document.getElementById("root");
        if (!element) {
            throw new Error("The element #capture wasn't found");
        }
        htmlToImage
            .toPng(element, { backgroundColor: "rgba(231, 236, 239, 1)" })
            .then(function (dataUrl) {
                let elem = window.document.createElement("a");
                elem.href = dataUrl;
                elem.download = "mynode.png";
                (document.body || document.documentElement).appendChild(elem);
                if (typeof elem.click === "function") {
                    elem.click();
                } else {
                    elem.target = "_blank";
                    elem.dispatchEvent(
                        new MouseEvent("click", {
                            view: window,
                            bubbles: true,
                            cancelable: true,
                        })
                    );
                }
                URL.revokeObjectURL(elem.href);
                elem.remove();
            });
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    releaseNotesModal() {
        return (
            <Modal
                onClose={() => this.openModal()}
                onOpen={() => this.openModal()}
                dimmer
                open={this.state.modalOpen}
                size="small"
                trigger={<span className="release-notes-link">what's new?</span>}
            >
                <Modal.Header>Release Notes</Modal.Header>
                <Modal.Content scrolling>
                    <ReleaseNotesModalContent />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        Got it!
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        return (
            <div className="footer">
                {!this.props.isMobile ?
                    <Image
                        src="/images/logo-white.png"
                        centered
                        className='footer-logo' /> :
                    <Image
                        src="/images/logo-mini-white.png"
                        className='footer-logo-mobile' />
                }
                <Label
                    attached="bottom right"
                    content=""
                    as={"p"}
                    className="label-test"
                >
                    {/* <Icon color="green" name="circle" /> */}
                    <span>version {process.env.REACT_APP_VERSION}</span>
                    {/* {this.releaseNotesModal()} */}
                    {/*
                        <span style={{ paddingRight: "7px" }}>|</span>
                        <span className="report-bug-icon" onClick={() => this.capture()}>
                            <Icon name="bug" />
                            report a problem
                        </span>
                    */}
                </Label>
            </div>
        );
    }
}
