import React, { PureComponent } from 'react';
import './styles.css';

export interface IReleaseNotesModalContentProps {
    isMobile: boolean;
}

export default class ReleaseNotesModalContent extends PureComponent<IReleaseNotesModalContentProps> {
    static defaultProps = {
        isMobile: false,
    };
    render() {
        return (
            <div className="release-notes">
                <section className="release-note">
                    <header>
                        <h2>1.1.2</h2>
                        <div className="date">March 26th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>

                <section className="release-note">
                    <header>
                        <h2>1.1.1</h2>
                        <div className="date">March 23th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>

                <section className="release-note">
                    <header>
                        <h2>1.1.2</h2>
                        <div className="date">March 26th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>

                <section className="release-note">
                    <header>
                        <h2>1.1.1</h2>
                        <div className="date">March 23th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>

                <section className="release-note">
                    <header>
                        <h2>1.1.2</h2>
                        <div className="date">March 26th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>

                <section className="release-note">
                    <header>
                        <h2>1.1.1</h2>
                        <div className="date">March 23th 2018</div>
                    </header>
                    <ul>
                        <li><span className="tag added">ADDED</span> New feature</li>
                        <li><span className="tag fixed">FIXED</span> Fixed feature</li>
                        <li><span className="tag improved">IMPROVED</span> Improved feature</li>
                    </ul>
                </section>
            </div>
        );
    }
}
