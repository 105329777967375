import React, { PureComponent } from 'react';
import {
    Button,
    Icon,
    Modal,
    CardGroup,
    Card,
    Divider,
    Header,
    Container,
    Image,
    Dropdown,
} from 'semantic-ui-react';
import { MobileSkeleton } from '../../../layouts/skeleton';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { DimmedModal } from '../../../layouts/modals';
import { LoadingSpinner } from '../../../controls';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import CreateButton from '../../../controls/buttons/CreateButton';
import SwipeableViews from 'react-swipeable-views';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IMobilePageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    localizationService?: any;
    breadcrumbLevels: any;
}
export interface IMobilePageState {
    modalOpen: boolean;
    index: number;
}

export default class MobilePage extends PureComponent<
    IMobilePageProps,
    IMobilePageState
> {
    state = {
        modalOpen: false,
        index: 0,
    };

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header">
                <Header as="h2" content={this.translate("Product")} className="mobile-items" />
                <Icon
                    name="question circle"
                    className="icon-link mobile-items"
                    onClick={() => this.openModal()}
                />
            </div>
        );
    }

    renderCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.filter((product: any) => 'submitted' !== product.status && 'recalled' !== product.status).map((product: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{product.title}</Card.Header>
                        <Card.Meta>
                            <span>
                                {commonUtils.renderLanguage(product.language)} -{' '}
                                {commonUtils.renderDate(product.date)}
                            </span>
                            <br />
                            <span className="product-list-product-status">
                                {product.status}
                            </span>
                        </Card.Meta>
                        <Divider />
                        <Card.Description>
                            {product.content
                                ? commonUtils.truncate(product.content, 250)
                                : this.translate('This product is empty. Why not start writing it?')}
                        </Card.Description>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`/products/${product.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    {this.translate('Edit')}
                                </Button>
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? this.renderNoContentMessage() : cardList}
            </CardGroup>
        );
    }

    renderRecalledCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.filter((product: any) => 'recalled' == product.status).map((product: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{product.title}</Card.Header>
                        <Card.Meta>
                            <span>
                                {commonUtils.renderLanguage(product.language)} -{' '}
                                {commonUtils.renderDate(product.date)}
                            </span>
                            <br />
                            <span className="product-list-product-status">
                                {product.status}
                            </span>
                        </Card.Meta>
                        <Divider />
                        <Card.Description>
                            {product.content
                                ? commonUtils.truncate(product.content, 250)
                                : this.translate('This product is empty. Why not start writing it?')}
                        </Card.Description>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`/products/${product.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    {this.translate('Edit')}
                                </Button>
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? this.renderNoRecalledContentMessage() : cardList}
            </CardGroup>
        );
    }

    renderSubmittedCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.filter((product: any) => 'submitted' == product.status).map((product: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{product.title}</Card.Header>
                        <Card.Meta>
                            <span>
                                {commonUtils.renderLanguage(product.language)} -{' '}
                                {commonUtils.renderDate(product.date)}
                            </span>
                            <br />
                            <span className="product-list-product-status">
                                {product.status}
                            </span>
                        </Card.Meta>
                        <Divider />
                        <Card.Description>
                            {product.content
                                ? commonUtils.truncate(product.content, 250)
                                : this.translate('This product is empty. Why not start writing it?')}
                        </Card.Description>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`/products/${product.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    {this.translate('Edit')}
                                </Button>
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? this.renderNoSubmittedContentMessage() : cardList}
            </CardGroup>
        );
    }

    private renderModal() {
        const { modalOpen } = this.state;
        return (
            <Modal dimmer open={modalOpen} size="small">
                <Modal.Header>{this.translate('About Product Statuses')}</Modal.Header>
                <Modal.Content scrolling>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        onClick={() => this.openModal()}
                        content={this.translate('Got it!')}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>{this.translate('No products!')}</Header>
                <Image centered src="/images/icons/Text_product.png" size="small" />
            </Container>
        )
    }

    renderNoRecalledContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>{this.translate('No recalled products!')}</Header>
                <Image centered src="/images/icons/Text_product.png" size="small" />
            </Container>
        )
    }

    renderNoSubmittedContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>{this.translate('No products to review!')}</Header>
                <Image centered src="/images/icons/Text_product.png" size="small" />
            </Container>
        )
    }

    handleChangeIndex(index) {
        this.setState({
            index: index,
        });
    }

    handleClick(key: any) {
        this.setState({
            index: key,
        });
    }

    private renderProductDropdown() {
        const options = [
            {
                key: 0,
                text: 'all products.',
                value: 0,
            },
            {
                key: 1,
                text: 'products that need review.',
                value: 1,
            },
            {
                key: 2,
                text: 'recalled products.',
                value: 2,
            },
        ]

        return <span>
            Show me {' '}
            <Dropdown
                inline
                options={options}
                defaultValue={options[this.state.index].value}
                value={this.state.index}
                onChange={(event, { value }) => this.handleClick(value)} />
        </span>;
    }

    private renderSwipeableViews() {
        return (<SwipeableViews
            enableMouseEvents
            onChangeIndex={(index, indexLatest, meta) => this.handleChangeIndex(index)}
            index={this.state.index}
        >
            <div className={'product-swipeable-view'} key={0}>
                {this.renderCards()}
            </div>
            <div className={'product-swipeable-view'} key={1}>
                {this.renderSubmittedCards()}
            </div>
            <div className={'product-swipeable-view'} key={2}>
                {this.renderRecalledCards()}
            </div>
        </SwipeableViews>);
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="product-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="mobile-item-list">
                        {this.renderModal()}
                        {this.renderPageHeader()}
                        {this.renderProductDropdown()}
                        {this.renderSwipeableViews()}
                        {!this.props.items.length &&
                            <DimmedModal
                                header={this.translate('Nothing to see here!')}
                                content={this.translate('Add your first product!')}
                                actions={
                                    [this.translate('Not now'),
                                    { key: 'done', content: this.translate('Create product'), positive: true, href: '/products/create' },
                                    ]
                                }
                            />
                        }
                        <CreateButton href='/products/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
