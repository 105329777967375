import React, { PureComponent } from 'react';
import { LoadingSpinner } from '../../controls';
import { PagesForm } from '../../forms/pages';
import { UnauthorizedMessage } from '../../layouts/messages';
import { DesktopSkeleton } from '../../layouts/skeleton';
import './styles.css';

export interface IPageDesktopPageProps {
    title: string;
    language: string;
    content: any;
    slug: string;
    layout: string;
    status: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels?: any;
    layoutMap: any[];
}

export default class DesktopPage extends PureComponent<IPageDesktopPageProps> {
    static defaultProps = {
        title: '',
        language: '',
        content: '',
        slug: '',
        layout: [],
        status: 'draft',
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="page-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                >
                    <div className="editor-container">
                        <PagesForm
                            title={this.props.title}
                            slug={this.props.slug}
                            language={this.props.language}
                            content={this.props.content}
                            status={this.props.status}
                            layout={this.props.layout}
                            onSubmit={async (data) => this.props.onSave(data)}
                            onDelete={this.props.onDelete}
                            localizationService={this.props.localizationService}
                            layoutMap={this.props.layoutMap}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
