import React, { PureComponent } from "react";
import {
    Segment,
    Header,
} from "semantic-ui-react";
import './styles.css';

export interface IMapWithOpenHoursTemplateProps {
    section: any;
}

export default class MapWithOpenHoursTemplate extends PureComponent<
    IMapWithOpenHoursTemplateProps
> {
    render() {
        const { section } = this.props;
        return (
            <Segment textAlign="center">
                <iframe width="100%" height="100%" frameBorder="0" title="map" scrolling="no"
                    src={`https://maps.google.com/maps?width=100%&height=600&hl=en&q=${section.additional.address}&ie=UTF8&t=&z=14&iwloc=B&output=embed`} style={{ filter: "opacity(1)", height: '500px' }}></iframe>
                <div style={{ borderRadius: '5%', backgroundColor: 'white', minHeight: '150px', width: 'fit-content', position: 'absolute', zIndex: 1, top: '30%', left: '60%', padding: '30px', paddingTop: '15px' }}>
                    <h3>Opening Hours</h3>
                    <p className='filter-effects' style={{ fontSize: '1.5rem' }}>
                        Monday................0:00-10:00<br />
                        Tuesday................0:00-10:00<br />
                        Wednesday................0:00-10:00<br />
                        Thursday................0:00-10:00<br />
                        Friday................0:00-10:00<br />
                        Saturday................0:00-10:00<br />
                        Sunday................0:00-10:00
                    </p>
                </div>
            </Segment>
        )
    }
}