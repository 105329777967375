import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup } from "semantic-ui-react";
import AssociateUserModal from "./AssociateUserModal";

export interface IUsersPaneProps {
    items: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onLinkUser: (data: any) => Promise<any>;
    onUnlinkUser: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface IUserPaneState {
    modalOpen: boolean;
}

export default class UsersPane extends PureComponent<IUsersPaneProps, IUserPaneState> {
    state: IUserPaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.firstName}</Table.Cell>
                <Table.Cell>{item.lastName}</Table.Cell>
                <Table.Cell><a href={`mailto:${item.email}`}>{item.email}</a></Table.Cell>
                <Table.Cell>
                    <Gatekeeper name="user-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/users/${item.id}/edit`}
                                />
                            }
                            content="Edit the user's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="account-user-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkUser(item)}
                                />
                            }
                            content="Users can be associated to multiple accounts. Unassociating the user will remove the user's access from this account but will not delete or alter the user itself"
                            basic
                        />
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateUserModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onLinkUser={async (data: any) => this.props.onLinkUser(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
            />
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Tab.Pane attached={false}>
                <Gatekeeper name="application-user-management">
                    {this.renderModal(this.state.modalOpen, Gatekeeper)}
                    <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()} style={{marginBottom:'1rem'}}><Icon name='plus' />Associate User</Button>
                </Gatekeeper>

                <Table>
                    <Table.Header>
                        <Table.HeaderCell>First Name</Table.HeaderCell>
                        <Table.HeaderCell>Last Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.props.items?.map(item => this.renderItem(item, Gatekeeper))}
                    </Table.Body>
                </Table>
            </Tab.Pane>
        );
    }
}