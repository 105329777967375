import React, { PureComponent } from 'react';
import './styles.css';
import { Button, Divider, Dropdown, Form, Header, Icon, Input, Popup, Table } from 'semantic-ui-react';
import ScrollableTable from '../../../layouts/tables/ScrollableTable';
import Media from 'react-media';
import CommonUtils from '../../../../utilities/Common/CommonUtils';

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
}

export interface IDesktopPageState {
    applications: any[];
    filteredNumberResults: number;
    searchInputHasValue: boolean;
    searchValue: string;
    searchKey: string;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps, IDesktopPageState> {

    state = {
        applications: [],
        filteredNumberResults: 0,
        searchInputHasValue: false,
        searchValue: '',
        searchKey: 'name',
    }

    componentDidMount() {
        this.setState({
            applications: this.props.items ? this.props.items : [],
            filteredNumberResults: this.props.items ? this.props.items.length : 0,
        })
    }

    componentDidUpdate(prevProps: Readonly<IDesktopPageProps>, prevState: Readonly<IDesktopPageState>, snapshot?: any): void {
        const searchParams = new URLSearchParams(document.location.search).get('action');
        if (prevProps !== this.props) {
            this.setState({
                applications: this.props.items ? this.props.items : [],
                filteredNumberResults: this.props.items ? this.props.items.length : 0,
            })
        }
    }

    private translate(key) {
        return key;
    }

    filterApplications(name: string) {
        const { items } = this.props;
        const { searchKey } = this.state;
        if (!items) { return; }
        const applications = items.filter(function (application) { return application[searchKey] && application[searchKey].toLowerCase().indexOf(name.toLowerCase()) >= 0; });
        this.setState({ applications, filteredNumberResults: applications.length, searchInputHasValue: name.length > 0, searchValue: name });
    }


    resetTextInput() {
        this.filterApplications('');
    }

    private renderSearch() {
        let options = [
            { key: 'name', text: 'Application Name', value: 'name' },
            { key: 'account', text: 'Account Name', value: 'account' },
        ]
        return <>
            <Media queries={{
                medium: "(max-width: 1023px)",
                large: "(min-width: 1024px)",
            }}>
                {matches => (
                    <>
                        {matches.medium &&
                            <Input fluid placeholder={this.translate("Search application by...")} value={this.state.searchValue} onChange={(event) => this.filterApplications(event.target.value)} type='text' icon={(e) => {
                                if (this.state.searchInputHasValue) {
                                    return <Icon name='delete' link onClick={() => { this.resetTextInput(); }} />;
                                }
                                return <Icon name='search' />;
                            }} label={<Dropdown defaultValue='name' options={options} onChange={(event, data) => { data.value && this.setState({ searchKey: data.value.toString() }); this.filterApplications(this.state.searchValue) }} />} labelPosition='right' />}
                        {matches.large &&
                            <Input style={{ width: '40%' }} placeholder={this.translate("Search application by...")} value={this.state.searchValue} onChange={(event) => this.filterApplications(event.target.value)} type='text' icon={(e) => {
                                if (this.state.searchInputHasValue) {
                                    return <Icon name='delete' link onClick={() => { this.resetTextInput(); }} />;
                                }
                                return <Icon name='search' />;
                            }} label={<Dropdown defaultValue='name' options={options} onChange={(event, data) => { data.value && this.setState({ searchKey: data.value.toString() }); this.filterApplications(this.state.searchValue) }} />} labelPosition='right' />}
                    </>
                )}
            </Media>
            <br />
            <small className="text-muted">
                {this.translate('Now showing')} {this.state.filteredNumberResults} {this.translate(commonUtils.plur('result', 'results', this.state.filteredNumberResults))}{this.state.searchInputHasValue && ` ${this.translate('for')} ${this.state.searchValue}`}.
            </small>
        </>
    }

    renderitems() {
        const { Gatekeeper } = this.props;
        const { applications } = this.state;

        const itemsTable = applications.map((item: any) => {
            return (
                <Table.Row>
                    <Table.Cell>
                        <Header as='h3'>
                            <Header.Content>
                                {item.name}
                                <Header.Subheader>Account: {item.accountId ? <a href={`/accounts/${item.accountId}`}>{item.account}</a> : 'N/A'}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Gatekeeper name="application-view">
                            <Popup
                                trigger={
                                    <Button
                                        icon="eye"
                                        color="blue"
                                        as="a"
                                        href={`/applications/${item.id}`}
                                    />
                                }
                                content="View the details of the application."
                                basic
                            />
                        </Gatekeeper>
                        <Gatekeeper name="application-management">
                            <Button icon='edit' color='teal' as="a" href={`/applications/${item.id}/edit`} />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="application-management">
                <div className="application-list-container">
                    {this.renderSearch()}
                    <ScrollableTable className='ten'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderitems()}
                        </Table.Body>
                    </ScrollableTable>
                </div>
            </Gatekeeper>

        )
    }
}