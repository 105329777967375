import React from "react";
import { Form, Message, Button, Grid, ButtonGroup } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import { DeleteButton } from "../../../controls";

const commonUtils = new CommonUtils();

export interface IApplicationTemplatesFormProps extends IStateAwareFormProps {
    name: string;
    templateRepository: string;
    templateRepositoryOwner: string;
}

export default class ApplicationTemplatesForm extends StateAwareForm<IApplicationTemplatesFormProps> {
    componentDidMount() {
        super.setState({
            templateRepository: this.props.templateRepository,
            name: this.props.name,
            templateRepositoryOwner: this.props.templateRepositoryOwner,
        });
    }



    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error} className={'section-form-container'}>
                <ButtonGroup floated="right">
                    <Button
                        color="teal"
                        disabled={loading}
                        floated="right"
                    >
                        Save
                    </Button>
                    {this.props.name && (
                        <DeleteButton
                            itemType="section"
                            itemName={this.props.name}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </ButtonGroup>

                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={"Name"}
                        placeholder="Name"
                        defaultValue={this.props.name}
                        name="name"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Input
                        fluid
                        label={"Repository Name"}
                        placeholder="Repository Name"
                        defaultValue={this.props.templateRepository}
                        name="templateRepository"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Input
                        fluid
                        label={"Repository Owner"}
                        placeholder="Repository Owner"
                        defaultValue={this.props.templateRepositoryOwner}
                        name="templateRepositoryOwner"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />

                </Form.Group>

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Application Template"
                />

                <Message
                    error
                    header="Could not save application template"
                    content={error && error.toString()}
                />
            </Form>
        );
    }
}
