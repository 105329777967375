import React, { PureComponent } from "react";
import {
    Form,
    Button,
    Divider,
    Grid,
    Header,
    Input,
    Progress,
} from "semantic-ui-react";
import { Message } from "../../layouts/messages";
import generator from "generate-password";
var zxcvbn = require("zxcvbn");

export interface IPasswordResetFormProps { 
    token: string;
    onSubmit: (data: any) => any;
}

export interface IPasswordResetFormState {
    loading?: boolean;
    email?: string;
    password: string;
    passwordRepeat: string;
    strength: number;
    howLong: number;
    generated: boolean;
    disableSubmit: boolean;
    error: boolean;
}

export default class PasswordResetForm extends PureComponent<IPasswordResetFormProps> {
    state: IPasswordResetFormState = {
        password: "",
        passwordRepeat: "",
        strength: 0,
        howLong: 0,
        generated: false,
        disableSubmit: true,
        error: false,
    };

    static defaultProps = {};

    private strongPasswordGenerator() {
        let password = generator.generate({
            length: 24,
            numbers: true,
            symbols: true,
        });
        let strength = zxcvbn(password);
        this.setState({
            disableSubmit: false,
            generated: true,
            password,
            passwordRepeat: password,
            strength: strength["score"],
            howLong:
                strength["crack_times_display"].online_no_throttling_10_per_second,
        });
    }

    private passwordChecker(e) {
        const { passwordRepeat } = this.state;
        let password = e.target.value;
        let strength = zxcvbn(password);
        let disableSubmit = !(
            password &&
            passwordRepeat &&
            password === passwordRepeat
        );
        this.setState({
            generated: false,
            password,
            disableSubmit,
            strength: strength["score"],
            howLong:
                strength["crack_times_display"].online_no_throttling_10_per_second,
        });
    }

    private handleChange(e) {
        const { password } = this.state;
        let disableSubmit = !(
            password &&
            password === e.target.value
        );
        this.setState({
            passwordRepeat: e.target.value,
            disableSubmit,
        });
    }

    private async handleSubmit() {
        const item = {
            token: this.props.token,
            password: this.state.password,
        }

        try {
            this.setState({ error: undefined, loading: true });
            await this.props.onSubmit(item);
        } catch (e) {
            this.setState({ error: e, loading: false });
        }
    }

    render() {
        const {
            error,
            generated,
            howLong,
            password,
            passwordRepeat,
            strength,
        } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <Grid columns={"one"} centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <label>New Password</label>
                                <Input
                                    type="password"
                                    onChange={(e) => this.passwordChecker(e)}
                                    value={password}
                                    action
                                >
                                    <input />
                                    <Button
                                        content={"Generate a strong password"}
                                        onClick={() => this.strongPasswordGenerator()}
                                        size="tiny"
                                        type="button"
                                    ></Button>
                                </Input>
                                <Progress
                                    percent={((strength / 4) * 100).toString()}
                                    indicating
                                    size="tiny"
                                />
                            </Form.Field>
                            {password && (
                                <Message size="tiny">
                                    {generated && (
                                        <>
                                            <Header size="tiny">Generated Password: </Header>
                                            <pre>{password}</pre>
                                            <Divider />
                                        </>
                                    )}
                                    It would take <strong>{howLong}</strong> to
                                    crack your password.
                                </Message>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <label>Retype New Password</label>
                                <Input
                                    type="password"
                                    onChange={(e) => this.handleChange(e)}
                                    value={passwordRepeat}
                                >
                                    <input />
                                </Input>
                            </Form.Field>
                            <Form.Button primary>
                                Update password
                            </Form.Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}
