import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';

export interface ICustomersModalContentProps {
    isMobile: boolean;
}

export default class CustomersModalContent extends PureComponent<ICustomersModalContentProps> {
    static defaultProps = {
        isMobile: false,
    };
    render() {
        return (
            <Table compact>
                {!this.props.isMobile && (
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={"two"}>Stage</Table.HeaderCell>
                            <Table.HeaderCell width="six">Definition</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )}
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Lead</Table.Cell>
                        <Table.Cell>
                            A potential customer has been discovered as a result of research and is ready for further research and initial contact.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Prospect</Table.Cell>
                        <Table.Cell>
                            The potential customer has been researched and contacted and is interested in considering working with us. This prospect is ready for engagement and the beginning stages of negotiations.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Negotiation</Table.Cell>
                        <Table.Cell>
                            Negotiations are in progress with the potential customer with the aim of closing a contract.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Customer</Table.Cell>
                        <Table.Cell>
                            The potential customer has signed a contract with us and is ready to work with us on various opportunities and projects.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Former Customer</Table.Cell>
                        <Table.Cell>
                            The customer has decided to part ways with us. Depending on the nature of rupture, future opportunities could present themselves such that the Former Customer could become a Prospect for a new engagement.
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
}
