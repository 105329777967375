import React, { PureComponent } from "react";
import { Form, Button } from "semantic-ui-react";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface IInlinePriceUpdateProps {
    defaultValue?: number;
    onChange?: (data: any) => any;
    item: any;
    name: string;
    value?: number;
    toggle?: boolean;
    key?: string;
}

export interface IInlinePriceUpdateState {
    value?: number
}

export default class InlinePriceUpdate extends PureComponent<IInlinePriceUpdateProps, IInlinePriceUpdateState> {
    state = {
        value: 0.00,
    };

    static defaultProps = {
        options: [],
        item: {},
        toggle: false,
        key: '',
    }

    handleChange(e) {
        this.setState({ value: e.target.value.replace(',', '.').replace(/[^0-9.]/g, '') });
    }

    componentDidMount() {
        this.setState({
            value: this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.defaultValue,
            });
        }
    }

    onSave() {
        if (this.props.onChange) {
            let { item } = this.props;
            const { value } = this.state;
            const data = {
                ...item,
                price: Number(value),
            }
            this.props.onChange(data);
        }
    }

    handleBlur(e) {
        
    }

    render() {
        return (
            <Form.Input fluid action key={this.props.key}>
                <input key={this.props.key} name={this.props.name} defaultValue={commonUtils.renderCurrency(this.props.defaultValue, (localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')!) : 'en'))} onChange={(e) => { this.handleChange(e) }} />
                <Button color='twitter' icon='save' onClick={() => { this.onSave() }} />
            </Form.Input>
        );
    }
}