import React, { PureComponent } from 'react';
import { InputOnChangeData } from 'semantic-ui-react';

export interface IStateAwareFormProps {
    onChange?: (value: InputOnChangeData) => any;
    onDelete: () => void;
    onSubmit: (value: any) => any;
}

export interface IStateAwareFormPropsState {
    loading?: boolean;
    error?: any;
    activeAccordeonIndex: number;
}

export default class StateAwareForm<Props extends IStateAwareFormProps = IStateAwareFormProps, State extends IStateAwareFormPropsState = IStateAwareFormPropsState> extends PureComponent<Props> {
    public state: State = {} as State;

    static defaultProps = {
        onSubmit: values => values,
        onDelete: () => { },
    };

    handleChange({ name, value }) {
        if (this.props.onChange) {
            this.props.onChange({ name, value })
        }

        this.setState({ [name]: value });
    }

    async handleSubmit() {
        const { loading, error, ...data } = this.state;

        try {
            this.setState({ error: undefined, loading: true })

            let item = Object.entries(data).reduce((accumulator, [key, value]) => {
                let keys: string[] = [];

                if (key.includes('.')) {
                    keys = key.split('.');
                } else {
                    let [match = []] = Array.from(key.matchAll(/([a-zA-Z0-9]*)\[([a-zA-Z0-9]*)\]/g)) as string[][];
                    let [k, ...ks] = match;

                    if (!ks.length) {
                        ks = [key];
                    }

                    keys = ks;
                }

                let nested = keys.reverse().reduce((acc, k) => {
                    acc = { [k]: acc };
                    return acc;
                }, value);

                return this.merge(accumulator, nested);
            }, {});

            await this.props.onSubmit(item);
            this.setState({ error: undefined, loading: false })
        } catch (e) {
            this.setState({ error: e, loading: false })
        }
    }

    private isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    private merge(target, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    this.merge(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return this.merge(target, ...sources);
    }
}