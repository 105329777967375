import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import ArchetypeOverviewPage from '../../components/pages/applications/ArchetypeOverviewPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class ArchetypeOverviewController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private componentControllerAdapter;
    private applicationTemplatesControllerAdapter;

    state = {
        item: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory, componentControllerAdapter, applicationTemplatesControllerAdapter] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.componentControllerAdapter = componentControllerAdapter;
        this.applicationTemplatesControllerAdapter = applicationTemplatesControllerAdapter;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    async componentDidMount() {
        let item = await this.get();

        this.setState({
            item,
        })
    }

    preparePanes(Gatekeeper) {
        const ComponentController = this.componentControllerAdapter.getController();
        const ApplicationTemplatesController = this.applicationTemplatesControllerAdapter.getController();

        return [
            {
                menuItem: "Components",
                render: () => (
                    <>
                        <Gatekeeper
                            name="archetype-component-management"
                            or={["archetype-component-view"]}
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ComponentController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Application Templates",
                render: () => (
                    <>
                        <Gatekeeper
                            name="archetype-component-management"
                            or={["archetype-component-view"]}
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ApplicationTemplatesController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
        ]
    }

    render() {
        const { item } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        const panes = this.preparePanes(Gatekeeper);

        return this.prepare(
            <ArchetypeOverviewPage
                item={item}

                Gatekeeper={Gatekeeper}
                panes={panes}
            />
        )
    }
}