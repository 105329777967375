import React, { PureComponent } from 'react';
import { Button, Container, Grid, Header, Icon, Table } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import { DimmedModal } from '../../../layouts/modals';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import LocalizedCreateButton from '../../../controls/buttons/CreateButton/LocalizedCreateButton';
import StoreSettingsTemplate from '../../storeconfiguration/StoreSettingsTemplate';
import './styles.css';
import Media from 'react-media';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    onPathChange: (path: string) => any;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header={this.translate('Nothing to see here!')}
                content={this.translate('Add your first catalog!')}
                actions={
                    [this.translate('Not now'),
                    { key: 'done', content: this.translate('Create catalog'), positive: true, href: '/store-management/catalogs/create' }
                    ]}
            />;
        }
        return;
    }

    private renderCatalogHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{this.translate('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Language')}</Table.HeaderCell>
                    <Table.HeaderCell>{this.translate('Description')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {this.translate('Actions')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderCatalogBody() {
        const { items } = this.props;
        const catalogsTable = items.map((catalog: any) => (
            <Table.Row onClick={(e) => this.handleDoubleClick(e, catalog)}>
                <Table.Cell>{catalog.title}</Table.Cell>
                <Table.Cell>
                    {commonUtils.renderLanguage(catalog.language)}
                </Table.Cell>
                <Table.Cell>{(catalog.content)}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Button
                        icon="edit"
                        color="teal"
                        as="a"
                        href={`/store-management/catalogs/${catalog.id}/edit`}
                    />
                </Table.Cell>
            </Table.Row>
        ));
        return (
            <Table.Body>
                {!catalogsTable.length ? this.renderNoContentMessage() : catalogsTable}
            </Table.Body>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No catalogs!')}</Header>
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <StoreSettingsTemplate activeItem='catalogs' onChange={this.props.onPathChange} localizationService={this.props.localizationService}>
                <Gatekeeper name="catalog-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <Grid columns={1} stackable style={{ marginBottom: '25px' }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3' content={this.translate('All Catalogs')}></Header>
                                    {/* ProductsDefinitionsPane */}
                                    <ScrollableTable striped selectable className='ten'>
                                        <Media queries={{
                                            large: "(min-width: 1024px)",
                                        }}>
                                            {matches => (
                                                <>
                                                    {matches.large && this.renderCatalogHeaders()}
                                                </>
                                            )}
                                        </Media>
                                        {this.renderCatalogBody()}
                                        <Table.Footer fullWidth>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell colSpan='4'>
                                                {/* <Button color="purple" size="small" floated="right" icon onClick={async () => await this.publishAll()} disabled={this.state.isPublishing}><Icon loading={this.state.isPublishing} name={this.state.isPublishing ? 'spinner' : 'save'} />{this.state.isPublishing ? '' : 'Publish All Products'}</Button> */}
                                                <Button color="green" size="small" floated="right" icon href='/store-management/catalogs/create' as='a'><Icon name='plus' />{this.translate('Create Catalog')}</Button>
                                            </Table.HeaderCell>
                                        </Table.Footer>
                                    </ScrollableTable>
                                    {this.renderNoContentModal()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </StoreSettingsTemplate>
        );
    }
}
