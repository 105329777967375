import React, { PureComponent } from 'react';
import { Button, Table, Image, Header, Container, Grid } from 'semantic-ui-react';
import { LoadingSpinner } from '../../controls';
import CreateButton from '../../controls/buttons/CreateButton/CreateButton';
import { UnauthorizedMessage } from '../../layouts/messages';
import { DimmedModal } from '../../layouts/modals';
import { ScrollableTable } from '../../layouts/tables';
import WebsiteSettingsTemplate from '../siteconfiguration/WebsiteSettingsTemplate';
import './styles.css';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
    onDoubleClick: Function;
    onPathChange: (path: string) => any;
    hasProvisionned: boolean;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    state = {
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal header="Nothing to see here!" content="Add your first section!" actions={['Not now', { key: 'done', content: 'Create section', positive: true, href: '/website-management/sections/create' }]} />;
        }
        return;
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Language</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderitems() {
        const { items } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, item)}>
                    <Table.Cell>{item.title}</Table.Cell>
                    <Table.Cell>{item.type}</Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.language}</Table.Cell>
                    <Table.Cell textAlign='right'><Button icon='edit' color='teal' as="a" href={`/website-management/sections/${item.id}/edit`} /></Table.Cell>
                </Table.Row>
            )
        });
        return (
            <Table.Body>
                {!itemsTable.length ? this.renderNoContentMessage() : itemsTable}
            </Table.Body>
        )
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center' fluid>
                <Header>No sections!</Header>
                <Image centered src="/images/icons/Site Section.png" size="medium" />
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <WebsiteSettingsTemplate onChange={this.props.onPathChange} canDeploy={this.props.hasProvisionned} activeItem="sections">
                <Gatekeeper name="website-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <Grid columns={1} stackable style={{ marginBottom: '25px' }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3' content={'All Sections'}></Header>
                                    <ScrollableTable className='ten'>
                                        {this.renderTableHeaders()}
                                        {this.renderitems()}
                                    </ScrollableTable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.renderNoContentModal()}
                        <CreateButton itemType='section' href='/website-management/sections/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </WebsiteSettingsTemplate>
        )
    }
}