import React, { PureComponent } from "react";
import { Grid, Table } from "semantic-ui-react";
import CheckboxInput from "../../../controls/CheckboxInput";

export interface IFeaturePermissionInputProps {
    name: string;
    value?: any[];
    resources: any[];
    isMobile?: boolean;
    handleChange: ({ name, value }) => any;
}

export default class FeaturePermissionInput extends PureComponent<IFeaturePermissionInputProps> {
    defaultProps = {
        name: "",
        value: [],
        resources: [],
        isMobile: false,
        handleChange: ({ name, value }) => { },
    };

    state = {};

    handleChange(name, value) {
        this.setState(
            {
                [name]: value,
            },
            () =>
                this.props.handleChange({
                    name: this.props.name,
                    value: Object.keys(this.state).filter((key) => this.state[key]),
                })
        );
    }

    componentDidMount() {
        this.setState(
            this.props.value?.reduce((state, verb) => {
                state[verb] = true;
                return state;
            }, {}) || {}
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState(
                this.props.value?.reduce((state, verb) => {
                    state[verb] = true;
                    return state;
                }, {}) || {}
            );
        }
    }

    renderCheckboxes() {
        return ["get", "post", "put", "patch", "delete", "link", "unlink"].map(
            (verb) => {
                let checked = this.state[verb] || false;
                return (
                    <Table.Cell>
                        <CheckboxInput
                            defaultValue={checked}
                            value={checked}
                            name={verb}
                            onChange={(e, { name, value }) => this.handleChange(name, value)}
                        />
                    </Table.Cell>
                )
            }
        );
    }

    renderToggles() {
        const toggleGrid = ["get", "post", "put", "patch", "delete", "link", "unlink"].map(
            (verb) => {
                let checked = this.state[verb] || false;
                return (
                    <Grid.Row>
                        <Grid.Column>{verb}</Grid.Column>
                        <Grid.Column className='card-container-feature-toggle'>
                            <CheckboxInput
                                defaultValue={checked}
                                value={checked}
                                name={verb}
                                onChange={(e, { name, value }) => this.handleChange(name, value)}
                                toggle
                            />
                        </Grid.Column>
                    </Grid.Row>
                )
            }
        );
        return (
            <Grid columns={'equal'} centered padded>
                {toggleGrid}
            </Grid>
        )
    }

    render() {
        const { name, isMobile } = this.props;

        return (
            <>
                {
                    !isMobile ?
                        <Table.Row>
                            <Table.Cell>{name}</Table.Cell>
                            {this.renderCheckboxes()}
                        </Table.Row> :
                        this.renderToggles()
                }
            </>
        );
    }
}
