import React, { PureComponent } from 'react';
import Page from '../Page';
import { Heading } from '../../sections';

export interface INotFoundPageProps {
    routes: any[];
}

export default class NotFoundPage extends PureComponent<INotFoundPageProps> {

    private renderHeadingProperties() {
        let lang = JSON.parse(localStorage.getItem('lang')!);

        if ("/storefronts/integrations/payments/stripe/authorize/oauth2" === window.location.pathname) {
            return {
                header: 'en' === lang ? "Please wait" : "Un instant.",
                subHeader: 'en' === lang ? "You should be redirected in a few moments." : "Vous serez redirigé dans quelques instants.",
                callToAction: null,
                callToActionLink: "",
            }
        }

        return {
            header: "404",
            subHeader: 'en' === lang ? "We're sorry, but the page you were looking for couldn't be found." : "Nous sommes désolés, mais la page que vous cherchez n'existe pas.",
            callToAction: 'en' === lang ? "Go Home" : "Retour à l'acceuil.",
            callToActionLink: "/",
        }
    }

    render() {
        const { routes } = this.props;
        const { header, subHeader, callToAction, callToActionLink } = this.renderHeadingProperties();
        return (
            <Page
                header={(
                    <Heading
                        header={header}
                        subHeader={subHeader}
                        callToAction={callToAction}
                        callToActionLink={callToActionLink}
                    />
                )}
                routes={routes}
            />
        );
    }
}