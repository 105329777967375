import React, { PureComponent } from "react";
import { FeaturesForm } from "../../forms/features";
import { DesktopSkeleton } from "../../layouts/skeleton";
import "./styles.css";

export interface IBlogDesktopPageProps {
    name: string;
    active: boolean;
    featureKey: string;
    resources?: any[];
    permissions?: any[];
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    toastConfig: any;
}

export default class DesktopPage extends PureComponent<IBlogDesktopPageProps> {
    static defaultProps = {
        name: "",
        active: false,
        featureKey: "",
        resources: [],
        permissions: [],
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        return (
            <DesktopSkeleton>
                <Gatekeeper name="feature-management">
                    <div className="editor-container">
                        <FeaturesForm
                            name={this.props.name}
                            active={this.props.active}
                            featureKey={this.props.featureKey}
                            onSubmit={(data) => this.props.onSave(data)}
                            resources={this.props.resources}
                            permissions={this.props.permissions}
                            toastConfig={this.props.toastConfig}
                            onDelete={this.props.onDelete}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
