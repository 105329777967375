import React, { PureComponent } from "react";
import {
    Button,
    CardGroup,
    Dropdown,
    Form,
    FormGroup,
    Grid,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import './styles.css';

export interface IHeroThemingProps {
    title: string;
    content: string;
    cta: string;
}

export interface IHeroThemingState {
    pattern: string;
    displayColorPicker: boolean;
    displayTitleColorPicker: boolean;
    color: any;
}

export default class HeroTheming extends PureComponent<
    IHeroThemingProps, IHeroThemingState
> {
    state = {
        pattern: 'pattern-polka',
        displayColorPicker: false,
        displayTitleColorPicker: false,
        color: { r: '0', g: '0', b: '0', a: '1' },
        buttonBackgroundColor: { r: '0', g: '0', b: '0', a: '1' },
    }
    updatePattern(value) {
        this.setState({ pattern: value });
    }
    handleClick(key) {
        this.setState({ ...this.state, [key]: !this.state[key] })
    };

    handleClose(key) {
        this.setState({ ...this.state, [key]: false })
    };

    handleChange(color, key) {
        this.setState({ ...this.state, [key]: color.rgb })
    };
    render() {
        const styles = reactCSS({
            'default': {
                title: {
                    color: `rgba(${this.state.color.r},${this.state.color.g},${this.state.color.b},${this.state.color.a})`,
                    fontWeight: 900,
                },
                button: {
                    color: 'white',
                    backgroundColor: `rgba(${this.state.buttonBackgroundColor.r},${this.state.buttonBackgroundColor.g},${this.state.buttonBackgroundColor.b},${this.state.buttonBackgroundColor.a})`,
                },
                swatch: {
                    padding: '17px 120px',
                    background: `rgba(${this.state.color.r},${this.state.color.g},${this.state.color.b},${this.state.color.a})`,
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    // margin: '4px 0px 0px 6px',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
                'buttonColor': {
                    swatch: {
                        padding: '17px 120px',
                        background: `rgba(${this.state.buttonBackgroundColor.r},${this.state.buttonBackgroundColor.g},${this.state.buttonBackgroundColor.b},${this.state.buttonBackgroundColor.a})`,
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                        // margin: '4px 0px 0px 6px',
                    },
                    popover: {
                        position: 'absolute',
                        zIndex: '2',
                    },
                    cover: {
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    },
                }
            },


        })
        const patternOptions = [
            { key: 0, text: 'Diagonal', value: 'pattern-diagonal' },
            { key: 1, text: 'Polka', value: 'pattern-polka' },
            { key: 2, text: 'Rhombus', value: 'pattern-rhombus' },
            { key: 3, text: 'Wavy', value: 'pattern-wavy' },
            { key: 4, text: 'Zig-Zag', value: 'pattern-zig-zag' },
        ]
        return (
            <Grid columns={2} divided={'vertically'}>
                <Grid.Row centered columns={2}>
                    <Grid.Column width={4}>
                        <p>Title color</p>
                        <div>
                            <div style={styles.swatch} onClick={() => this.handleClick('displayColorPicker')}>
                                <div style={styles.color} />
                            </div>
                            {this.state.displayColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={() => this.handleClose('displayColorPicker')} />
                                <SketchPicker color={this.state.color} onChange={(color) => this.handleChange(color, 'color')} />
                            </div> : null}

                        </div>
                        <p>Button color</p>
                        <div>
                            <div style={styles.buttonColor.swatch} onClick={() => this.handleClick('displayTitleColorPicker')}>
                                <div style={styles.color} />
                            </div>
                            {this.state.displayTitleColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={() => this.handleClose('displayTitleColorPicker')} />
                                <SketchPicker color={this.state.buttonBackgroundColor} onChange={(color) => this.handleChange(color, 'buttonBackgroundColor')} />
                            </div> : null}

                        </div>
                        {/* <Form.Input fluid label={'Button color'} />
                        <Form.Input fluid label={'Button text color'} />
                        <Form.Input fluid label={'Background color'} /> */}
                        <br />
                        <p>Background pattern</p>
                        {/* <Dropdown
                            fluid
                            selection
                            options={patternOptions}
                            value={this.state.pattern}
                            onChange={(e, data) => { this.updatePattern(data.value) }}
                        /> */}
                        <div className="palette">
                            <div className="single pattern-wavy" onClick={() => { this.updatePattern('pattern-wavy') }}>
                                <div id="color1"></div>
                                <div className="hex"></div>
                            </div>
                            <div className="single pattern-polka" onClick={() => { this.updatePattern('pattern-polka') }}>
                                <div id="color1"></div>
                                <div className="hex"></div>
                            </div>
                            <div className="single pattern-rhombus" onClick={() => { this.updatePattern('pattern-rhombus') }}>
                                <div id="color1"></div>
                                <div className="hex"></div>
                            </div>
                            <div className="single pattern-zig-zag" onClick={() => { this.updatePattern('pattern-zig-zag') }}>
                                <div id="color1"></div>
                                <div className="hex"></div>
                            </div>
                            <div className="single pattern-diagonal" onClick={() => { this.updatePattern('pattern-diagonal') }}>
                                <div id="color1"></div>
                                <div className="hex"></div>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div className={`theming-jumbotron text-center ${this.state.pattern}`}>
                            <div className="inner">
                                <h1 style={styles.title}>{this.props.title}</h1>
                                <ReactMarkdown children={this.props.content} />
                                <Button primary style={styles.button} content={this.props.cta} />
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}