import React, { PureComponent } from "react";
import { Button, Dropdown, Label } from 'semantic-ui-react'
import CommonUtils from "../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface ISimpleInlineOrderUpdateProps {
    defaultValue?: string;
    onChange?: (data: any) => any;
    item: any;
    name: string;
    value?: string;
    localizationService: any;
}

export interface ISimpleInlineOrderUpdateState {
    value?: string
}

export default class SimpleInlineOrderUpdate extends PureComponent<ISimpleInlineOrderUpdateProps, ISimpleInlineOrderUpdateState> {
    state = {
        value: '',
    };

    static defaultProps = {
        item: {},
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleClick(e) {
        this.onSave(e);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || this.props.defaultValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                value: this.props.value || this.props.defaultValue,
            });
        }
    }

    onSave(value: string) {
        if (this.props.onChange) {
            let { item } = this.props;
            const data = {
                ...item,
                status: value,
            }
            this.props.onChange([data]);
        }
    }

    render() {

        return (
            <>
                {
                    this.props.value == 'delivered' &&
                    <Label content={this.translate('Delivered')} color='green' />
                }
                {
                    this.props.value == 'cancelled' &&
                    <Label content={this.translate('Cancelled')} color='red' />
                }
                {
                    this.props.value !== 'delivered' && this.props.value !== 'cancelled' &&
                    <Button.Group>
                        <Button content={this.translate('Cancelled')} color='red' onClick={(e) => { this.handleClick('cancelled') }} />
                        <Button content={this.translate('Completed')} color='green' onClick={(e) => { this.handleClick('delivered') }} />
                    </Button.Group>
                }
            </>
        );
    }
}