import React, { PureComponent } from "react";
import {
    Button,
    Card,
    CardGroup,
    Divider,
    Feed,
    Grid,
    Header,
    Container,
    Image,
} from "semantic-ui-react";
import { MobileSkeleton } from "../../layouts/skeleton";
import TimeAgo from "timeago-react";
import UnauthorizedMessage from "../../layouts/messages/UnauthorizedMessage";
import LoadingSpinner from "../../controls/loaders/LoadingSpinner";
import "./styles.css";

export interface IMobilePageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
}

export default class MobilePage extends PureComponent<IMobilePageProps> {
    state = {
        activeMessage: -1,
    };

    renderMessagesList() {
        const { items } = this.props;
        const inboxTable = items.map((post, index) => {
            return (
                <Card onClick={() => this.setState({ activeMessage: index })}>
                    <Card.Content>
                        <Feed>
                            <Feed.Event>
                                <Feed.Content>
                                    <Feed.Summary className='sidebar-header-mobile'>
                                        <Feed.User style={{ color: 'black' }}>{post.name}</Feed.User>
                                    </Feed.Summary>
                                    <Feed.Date>
                                        {post.date &&
                                            new Date(post.date).toLocaleString("en-CA", {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            })}
                                    </Feed.Date>
                                    {post?.message && <Feed.Extra content={post.message.substring(0, 50) + "..."} />}
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    </Card.Content>
                </Card>
            );
        });
        return <CardGroup stackable itemsPerRow={1} centered>{!inboxTable.length ? this.renderNoContentMessage() : inboxTable}</CardGroup>;
    }

    renderMessage(activeMessage) {
        return this.props.items[activeMessage] ? (
            <Card fluid basic className="message-container-mobile">
                <Card.Content>
                    <Card.Header>
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    this.setState({ activeMessage: -1 });
                                }}
                                icon={"angle left"}
                            />
                        </Button.Group>
                        <Button.Group floated="right">
                            <Button
                                as="a"
                                href={`mailto:${this.props.items[activeMessage]?.email}`}
                                icon="mail"
                            />
                            <Button
                                as="a"
                                href={`tel:${this.props.items[activeMessage]?.phoneNumber}`}
                                icon="phone"
                            />
                        </Button.Group>
                    </Card.Header>
                    <Card.Header className="message-container-header-mobile">
                        {`${this.props.items[activeMessage]?.name}`} {`<${this.props.items[activeMessage]?.email}>`}
                    </Card.Header>
                    <Card.Meta>
                        {this.props.items[activeMessage]?.date &&
                            `${new Date(this.props.items[activeMessage]?.date).toLocaleString(
                                "en-US"
                            )} (`}
                        {this.props.items[activeMessage]?.date && (
                            <TimeAgo
                                datetime={new Date(this.props.items[activeMessage]?.date)}
                                locale="en_US"
                            />
                        )}
                        {this.props.items[activeMessage]?.date && `)`}
                    </Card.Meta>
                    <Divider />
                    <Card.Description>
                        {this.props.items[activeMessage]?.message}
                    </Card.Description>
                </Card.Content>
            </Card>
        ) : (
            "Nothing"
        );
    }

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header" style={{ marginBottom: '0px' }}>
                <Header as="h2" content="Inbox" className="mobile-items" />
            </div>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>No messages!</Header>
                <Image centered src="/images/icons/Empty mailbox with grass.png" size="small" />
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="messages-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div style={{ marginTop: "10px" }}>
                        <Grid padded divided>
                            <Grid.Row>
                                <Grid.Column mobile={16}>
                                    {this.renderPageHeader()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {this.state.activeMessage === -1 && (
                                    <Grid.Column mobile={"16"}>
                                        <div className="inbox-sidebar-mobile">
                                            {this.renderMessagesList()}
                                        </div>
                                    </Grid.Column>
                                )}
                                {this.state.activeMessage > -1 && (
                                    <Grid.Column mobile={"16"}>
                                        {this.renderMessage(this.state.activeMessage)}
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
