import React, { PureComponent } from 'react';
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react';

export interface IDatePickerProps extends DateInputProps {
    name: string;
}

export default class DatePicker extends PureComponent<IDatePickerProps> {
    state = {
        value: ''
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    onChange(e, { value }) {
        this.setState({
            value,
        }, () => {
            this.props.onChange && this.props.onChange(e, { name: this.props.name, value })
        })
    }

    render() {
        const { value } = this.state;
        const { onChange, value: v, ...props } = this.props;

        return (
            <DateInput
                dateFormat='YYYY-MM-DD'
                value={value}
                onChange={(e, { name, value }) => this.onChange(e, { value })}
                {...props}
            />
        );
    }
}