import React, { PureComponent } from 'react';
import './styles.css';
import { Button, Popup, Table } from 'semantic-ui-react';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    state = {
    }

    renderitems() {
        const { items, Gatekeeper } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Gatekeeper name="component-view">
                            <Popup
                                trigger={
                                    <Button
                                        icon="eye"
                                        color="blue"
                                        as="a"
                                        href={`/components/${item.id}`}
                                    />
                                }
                                content="View the details of the component."
                                basic
                            />
                        </Gatekeeper>
                        <Gatekeeper name="component-management">
                            <Button icon='edit' color='teal' as="a" href={`/components/${item.id}/edit`} />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="component-management">
                <div className="component-list-container">
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderitems()}
                        </Table.Body>
                    </Table>
                </div>
            </Gatekeeper>

        )
    }
}