import React, { PureComponent } from "react";
import { DeleteButton } from "../../controls";
import { FeaturesForm } from "../../forms/features";
import { MobileSkeleton } from "../../layouts/skeleton";
// import ReactMarkdown from "react-markdown";
import "./styles.css";

export interface IBlogMobilePageProps {
    name: string;
    active: boolean;
    featureKey: string;
    resources?: any[];
    permissions?: any[];
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    toastConfig: any;
}

export default class MobilePage extends PureComponent<IBlogMobilePageProps> {
    static defaultProps = {
        name: "",
        active: false,
        featureKey: "",
        resources: [],
        permissions: [],
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        return (
            <MobileSkeleton>
                <Gatekeeper name="feature-management">
                    <div className="editor-container">
                        <FeaturesForm
                            name={this.props.name}
                            active={this.props.active}
                            featureKey={this.props.featureKey}
                            onSubmit={(data) => this.props.onSave(data)}
                            resources={this.props.resources}
                            permissions={this.props.permissions}
                            toastConfig={this.props.toastConfig}
                        >
                            {this.props.name && (
                                <DeleteButton
                                    itemType="feature"
                                    itemName={this.props.name}
                                    icon="trash"
                                    color="red"
                                    handleOnClick={this.props.onDelete}
                                />
                            )}
                        </FeaturesForm>
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
