import {
    DecoratedCollectionFactory,
    DecoratedEntityFactory,
    DeviceAwareRouteItemFactoryResolver,
    LocalizedRouteMenuItemFactory,
} from '../../factories';
import ControlsEnabledRouteMenuItemFactory, { ControlsResolver } from '../../factories/ControlsEnabledRouteMenuItemFactory';
import { controls } from '../../components/navbar/controls';
import ApplicationSwitcherFactory from '../../factories/ApplicationSwitcherFactory';
import DeployButtonFactory from '../../factories/DeployButtonFactory';
import RightNavbarControlsFactory from '../../factories/RightNavbarControlsFactory';
import LogoutButtonFactory from '../../factories/LogoutButtonFactory';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import FeatureAwareRouteMenuItemFactory from '../../factories/FeatureAwareRouteMenuItemFactory';
import RightNavbarLanguageControlsFactory from '../../factories/RightNavbarLanguageControlsFactory';

export default {
    "factory.route_menu_item:resolver": {
        "class": ControlsResolver,
        "constructor": [
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "service.localization" },
        ],
        "functions": controls.map(control => ({
            "function": "addControl",
            "args": [control],
        })),
    },
    "factory.route_menu_item:desktop": {
        "class": ControlsEnabledRouteMenuItemFactory,
        "constructor": [
            { "type": "parameter", "key": "routes" },
            { "type": "service", "key": "factory.route_menu_item:resolver" },
            //TODO: Cleanup: hack to trigger it to load. Should replace tramway with foundation but sync becomes async.
            { "type": "service", "key": "factory.rightnavbarcontrols" },
        ],
    },
    "factory.route_menu_item": {
        "class": LocalizedRouteMenuItemFactory,
        "constructor": [
            { "type": "service", "key": "service.localization" },
            { "type": "parameter", "key": "routes" },
        ],
    },
    "factory.decorated_collection": {
        "class": DecoratedCollectionFactory,
        "constructor": [
            { "type": "service", "key": "factory.decorated_entity" },
        ],
    },
    "factory.decorated_entity": {
        "class": DecoratedEntityFactory,
    },
    "factory.application_switcher": {
        "class": ApplicationSwitcherFactory,
        constructor: [
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "service.security" },
            { "type": "service", "key": "factory.gatekeeper" },
        ]
    },
    "factory.deploy_button": {
        "class": DeployButtonFactory,
        constructor: [
            { "type": "service", "key": "repository.applications" },
            { "type": "service", "key": "service.security" },
        ]
    },
    "factory.gatekeeper": {
        "class": GatekeeperFactory,
        constructor: [
            { "type": "service", "key": "repository.features" },
            { "type": "service", "key": "service.feature" },
            { "type": "service", "key": "service.security" },
        ]
    },
    "factory.logout_button": {
        "class": LogoutButtonFactory,
        constructor: [
            { "type": "service", "key": "service.security" },
        ]
    },

    "factory.languagecontrols": {
        "class": RightNavbarLanguageControlsFactory,
    },

    "factory.rightnavbarcontrols": {
        "class": RightNavbarControlsFactory,
        constructor: [
            { "type": "service", "key": "factory.route_menu_item:resolver" },
            { "type": "service", "key": "factory.application_switcher" },
            { "type": "service", "key": "factory.logout_button" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "factory.deploy_button" },
            { "type": "service", "key": "factory.languagecontrols" },
            { "type": "service", "key": "service.localization" },
        ],
        functions: [
            {
                "function": "create",
                "args": []
            }
        ]
    },
    "resolver.factory.deviceawarerouteitem": {
        class: DeviceAwareRouteItemFactoryResolver,
        "functions": [
            {
                "function": "addDeviceRouteFactory",
                "args": ["desktop", { "type": "service", "key": "factory.route_menu_item:desktop" }]
            },
            {
                "function": "addDeviceRouteFactory",
                "args": ["mobile", { "type": "service", "key": "factory.route_menu_item:mobile" }]
            }
        ]
    },
    "factory.route_menu_item:mobile": {
        "class": FeatureAwareRouteMenuItemFactory,
        "constructor": [
            { "type": "parameter", "key": "routes" },
            { "type": "service", "key": "factory.gatekeeper" },
            { "type": "service", "key": "factory.logout_button" },
            { "type": "service", "key": "factory.application_switcher" },
            { "type": "service", "key": "factory.deploy_button" },
        ],
    },
}
