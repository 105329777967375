import React, { PureComponent } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import './styles.css';

export interface IReturnButtonProps extends ButtonProps {
    itemType?: string;
}

export default class ReturnButton extends PureComponent<IReturnButtonProps> {

    static defaultProps = {
        itemType: undefined,
        handleOnClick: () => { },
        as: 'a',
        icon: 'chevron left',
        color: 'teal',
    };

    render() {
        return (
            <Button
                className="return-button"
                icon={this.props.icon}
                content={
                    this.props.itemType && `Back to all ${this.props.itemType}`
                }
                color={this.props.color}
                disabled={this.props.disabled}
                basic={this.props.basic}
                as={this.props.as}
                href={this.props.href}
            />
        );
    }
}
