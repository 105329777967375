import React from 'react';
import ApplicationInstancesGeneralInformationPage from '../../../components/pages/applicationinstances/ApplicationInstancesGeneralInformationPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductConfirmationController extends LocalizedController {
    private repository: Repository;
    private attributesRepository: Repository;
    private productsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        productDefinition: undefined,
        productPropertyAttributes: [],
        productSelectionAttributes: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, productDefinitionsRepository, attributesRepository, productsRepository, gatekeeperFactory] = props.args;

        this.repository = productDefinitionsRepository;
        this.attributesRepository = attributesRepository;
        this.productsRepository = productsRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    async componentDidMount() {
        await this.fetchProductDefinition();
        await this.fetchProductAttributes();
    }

    private async fetchProductDefinition() {
        let item;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            console.error(e);
        }

        this.setState({ productDefinition: item });
    }

    private async fetchProductAttributes() {
        let productAttributes;
        let productPropertyAttributes = [];
        let productSelectionAttributes = [];
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            productAttributes = await this.attributesRepository.find({ definitionId: this.params?.id });
            productPropertyAttributes = productAttributes.filter((item) => { return item.type == 'property' });
            productSelectionAttributes = productAttributes.filter((item) => { return item.type == 'selection' });
        } catch (e) {
            console.error(e);
        }

        this.setState({ productPropertyAttributes, productSelectionAttributes });
    }

    private async handleSave(data: any) {
        let items = [];
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.productsRepository.createByPath({}, `definitions/${this.params?.id}/products`);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            this.router.redirect(`/productdefinitions/${this.params?.id}`);
        } catch (e) {
            throw e;
        }
    }

    private async createProductAttribute() {
        let entity = {
            name: "Weight",
            type: "property",
            options: ['10kg'],
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            definitionId: this.params?.id,
        }
        await this.attributesRepository.create(entity);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 3, text: this.state.productDefinition ? this.state.productDefinition!['title'] : '', value: undefined },
            { key: 4, text: this.localizationService.translate('Review product'), value: undefined },
        ]
    }

    render() {
        const { productDefinition,
            productPropertyAttributes,
            productSelectionAttributes,
        } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ApplicationInstancesGeneralInformationPage
                productDefinition={productDefinition}
                productPropertyAttributes={productPropertyAttributes}
                productSelectionAttributes={productSelectionAttributes}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                createProperty={async () => this.createProductAttribute()}
            />
        )
    }
}