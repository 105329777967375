import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import TermsPane from '../../components/pages/subscriptions/SubscriptionDesignPage/panes/TermsPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class SubscriptionTermController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`subscriptions/${this.params?.id}/terms`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    async handleSave(data: any) {
        let item;

        if (data?.id) {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                item = await this.repository.updateByPath(data, `subscriptions/${this.params?.id}/terms/${data?.id}`);
            } catch (e) {
                console.error(e)
                throw Error("Failed to update the term. Please try again.");
            }
        } else {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                item = await this.repository.createByPath(data, `subscriptions/${this.params?.id}/terms`);
            } catch (e) {
                console.error(e)
                throw Error("Failed to create the term. Please try again.");
            }
        }

        return item;
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    private async handleDelete(item) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.deleteByPath(`subscriptions/${this.params?.id}/terms/${item.id}`);
        } catch (e) {
            throw e;
        }

        await this.refreshItems();
    }

    render() {
        const { items } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="subscription-term-management"
                or={["subscription-term-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <TermsPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSave={async (data) => this.handleSave(data)}
                    onModalClose={async () => this.refreshItems()}
                    onDelete={async (data) => this.handleDelete(data)}
                />
            </Gatekeeper>
        )
    }
}