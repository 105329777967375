import React, { PureComponent } from "react";
import {
    Button,
    Form,
    Grid,
} from "semantic-ui-react";
import { IMessagesSettingsPageProps } from "./MessagesSettingsPage";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import WebsiteSettingsTemplate from "../siteconfiguration/WebsiteSettingsTemplate";
import "./styles.css";
import { toast } from "react-toastify";
import { UnauthorizedMessage } from "../../layouts/messages";

const commonUtils = new CommonUtils();

export interface IDeploymentsDesktopPageProps extends IMessagesSettingsPageProps {
    onChange: (path: string) => any,
    saveMessageConfiguration: (data: any) => any,
    localizationService: any,
    items: any[],
}

export interface IDeploymentsDesktopPageState {
    bcc: any,
    cc: any,
    destinations: any,
    errors: any,
    success: boolean,
    hasUpdated: boolean,
}

export default class DesktopPage extends PureComponent<
    IDeploymentsDesktopPageProps,
    IDeploymentsDesktopPageState
> {
    state = {
        bcc: '',
        cc: '',
        destinations: '',
        errors: {},
        success: false,
        hasUpdated: false,
    };

    componentDidMount(): void {
        this.setState({
            bcc: this.props.items['bcc'],
            cc: this.props.items['cc'],
            destinations: this.props.items['destinations'],
        })
    }

    componentDidUpdate(prevProps: Readonly<IDeploymentsDesktopPageProps>, prevState: Readonly<IDeploymentsDesktopPageState>, snapshot?: any): void {
        console.log(this.props.items)
        if (prevProps.items !== this.props.items && Object.entries(this.props.items).length > 0 && !this.state.hasUpdated) {
            this.setState({
                bcc: this.props.items['bcc'].length ? this.props.items['bcc'].join(',') : '',
                cc: this.props.items['cc'].length ? this.props.items['cc'].join(',') : '',
                destinations: this.props.items['destinations'].length ? this.props.items['destinations'].join(',') : '',
                hasUpdated: true,
            })
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleChange(key, value) {
        value = value.replace(/\s/g, '');

        this.setState({ ...this.state, [key]: value })
    }

    trimAndValidate(value): boolean {
        let hasError = false;
        if (!value) { return hasError }
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let values = value.split(',').filter(i => i);
        values.forEach(element => {
            if (!element.match(validRegex)) {
                hasError = true;
            }
        });
        return hasError;
    }

    async save() {
        let { bcc, cc, destinations } = this.state;
        let errors = {
            bcc: this.trimAndValidate(bcc),
            cc: this.trimAndValidate(cc),
            destinations: this.trimAndValidate(destinations),
        }
        if (!Object.values(errors).some(val => val === true) && this.props.saveMessageConfiguration) {
            await this.props.saveMessageConfiguration({
                bcc: bcc.split(','),
                cc: cc.split(','),
                destinations: destinations.split(','),
            });
            this.setState({ errors, success: true })
            toast.success(this.translate('Successfully updated settings. Reloading in a few moments...'), {
                onClose: () => window.location.reload(),
                onClick: () => window.location.reload(),
            });
        } else {
            this.setState({ errors })
        }
    }

    render() {
        console.log(this.state)
        return (
            <WebsiteSettingsTemplate onChange={this.props.onChange} activeItem="messaging-settings">
                <h3>{this.translate('Messaging Settings')}</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            {Object.entries(this.props.items).length > 0 && <Form>
                                <Form.Input disabled={this.state.success} error={this.state.errors['destinations']} label={`${this.translate('Destinations')}*`} value={this.state.destinations} onChange={(event, data) => this.handleChange('destinations', data.value)} />
                                <Form.Input disabled={this.state.success} error={this.state.errors['cc']} label={`${this.translate('CC')}*`} value={this.state.cc} onChange={(event, data) => this.handleChange('cc', data.value)} />
                                <Form.Input disabled={this.state.success} error={this.state.errors['bcc']} label={`${this.translate('BCC')}*`} value={this.state.bcc} onChange={(event, data) => this.handleChange('bcc', data.value)} />
                                <small className="text-muted"><sup>*</sup>{this.translate('Separate the email addresses with a comma (,).')}</small><br /><br />
                                <Button disabled={this.state.success} primary content={this.translate('Save')} onClick={() => this.save()} />
                            </Form>}
                            {!Object.entries(this.props.items).length &&
                                <UnauthorizedMessage />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </WebsiteSettingsTemplate>
        );
    }
}
