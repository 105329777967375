import React from 'react';
import { LoadingSpinner } from '../../components/controls';
import { UnauthorizedMessage } from '../../components/layouts/messages';
import LicensesPane from '../../components/pages/subscriptions/SubscriptionDesignPage/panes/LicensesPane';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class SubscriptionLicensesController extends LocalizedController {
    private repository: Repository;
    private licenseRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, licenseRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.licenseRepository = licenseRepository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`subscriptions/${this.params?.id}/licenses`);
        } catch (e) {
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    async handleSearch(data: any) {
        let items;

        try {
            items = await this.licenseRepository.find(data);
        } catch (e) {
            throw e;
        }

        return items;
    }

    async handleLinkLicense(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'licenseId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the license to this subscription. Please try again.");
        }
    }

    async handleUnlinkLicense(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'licenseId', data.id);
        } catch (e) {
            console.error(e)
            throw Error("Failed to disassociate the license to this subscription. Please try again.");
        }

        await this.refreshItems();
    }

    async refreshItems() {
        try {
            let items = await this.get();

            this.setState({
                items,
            })
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { items } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <Gatekeeper
                name="subscription-license-management"
                or={["subscription-license-view"]}
                unauthorizedComponent={<UnauthorizedMessage />}
                loadingComponent={<LoadingSpinner />}
            >
                <LicensesPane
                    items={items}
                    Gatekeeper={Gatekeeper}
                    onSearch={async (data: any) => this.handleSearch(data)}
                    onLinkLicense={async (data: any) => this.handleLinkLicense(data)}
                    onUnlinkLicense={async (data: any) => this.handleUnlinkLicense(data)}
                    onModalClose={async () => this.refreshItems()}
                />
            </Gatekeeper>
        )
    }
}