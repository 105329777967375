import { controllers } from 'tramway-router-react-strategy';
import Router from './Router';
const { ReactController } = controllers;

export default class Controller extends ReactController {
    protected router: Router;
    props;

    constructor({ args: [router], ...props }) {
        super(props);
        this.router = router;

        const { params, location, history } = props;

        this.router.setParams(params);
        this.router.setLocation(location);
        this.router.setHistory(history);
    }

    componentDidCatch(error, info) {
        return super.componentDidCatch(error, info)
    }

    setState(any) {
        return super.setState(any)
    }
}