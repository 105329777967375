import React, { PureComponent } from 'react';
import { Button, Card, Grid, GridColumn, GridRow, Header, Label, List, ListContent, Message, Modal, Table } from 'semantic-ui-react';
import { ScrollableTable } from '../../../layouts/tables';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import ProductCatalogForm from '../../../forms/products/ProductCatalogForm';
import { ScrollableList } from '../../../layouts/sections';
import './styles.css';

const commonUtils = new CommonUtils();
export interface IDesktopPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    onSave: (data: any) => any;
    onDelete?: () => void;
    onLink?: (data: any) => any;
    onUnlink?: (data: any) => any;
    title: string;
    language: string;
    content: any;
    linkedProducts?: any[];
    allProductDefinitions?: any[];
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    static defaultProps = {
        title: '',
        language: '',
        content: '',
    };

    state = {
        modalOpen: false,
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    toggleModal(open: boolean = false) {
        this.setState({ modalOpen: open });
    }

    private linkItem(id: string) {
        this.props.onLink && this.props.onLink(id);
    }

    private unlinkItem(id: string) {
        this.props.onUnlink && this.props.onUnlink(id);
    }

    private renderItems() {
        const { allProductDefinitions, linkedProducts } = this.props;
        if (!allProductDefinitions?.length) { return; }
        const items = allProductDefinitions.map(product => {
            if (product.title) {
                const button = linkedProducts && linkedProducts.length && linkedProducts?.find(linkedProduct => product.id === linkedProduct.id) ?
                    <Button floated='right' color='red' content={this.translate('Remove from catalog')} icon='broken chain' onClick={() => { this.unlinkItem(product.id) }} /> :
                    <Button floated='right' color='green' content={this.translate('Add to catalog')} icon='chain' onClick={() => { this.linkItem(product.id) }} />;
                return (<Table.Row>
                    <Table.Cell>{product.title}</Table.Cell>
                    <Table.Cell>
                        {button}
                    </Table.Cell>
                </Table.Row>)
            }
        })
        return items;
    }

    renderModal() {
        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={this.state.modalOpen}
                closeIcon
            >
                <Modal.Header>{this.translate('Add products to the catalog')}</Modal.Header>
                <Modal.Content>
                    <p>{this.translate('Click on the link action that corresponds with the product you want to associate to the catalog in the table below.')}</p>
                    <ScrollableTable divided className="ten">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{this.translate('Name')}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderItems()}
                        </Table.Body>
                    </ScrollableTable>
                </Modal.Content>
            </Modal>)
    }

    private renderNoContentMessage() {
        return (
            <List.Item>
                <ListContent>
                    <Message info content={this.translate('No products in this catalog!')}>
                    </Message>
                </ListContent>
                <ListContent fluid>
                    <Button content={this.translate('Add product to catalog')} icon={'chain'} fluid color='blue' onClick={() => this.toggleModal(true)} />
                </ListContent>
            </List.Item>

        )
    }

    renderLinkedProductsList(): any {
        const { linkedProducts, allProductDefinitions } = this.props;
        if (!linkedProducts?.length) { return this.renderNoContentMessage(); }

        const linkedProductsList = linkedProducts.map(linkedProduct => {
            return (
                <List.Item>
                    <List.Content floated='right'>
                        <Button content="Remove from catalog" color={'red'} icon={'broken chain'} onClick={() => this.unlinkItem(linkedProduct.id)}></Button>
                    </List.Content>
                    <List.Content>
                        <List.Header>{linkedProduct.title}</List.Header>
                    </List.Content>
                </List.Item>
            )
        })

        return linkedProductsList.length > 0 ? linkedProductsList : this.renderNoContentMessage();
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;
        const { title, allProductDefinitions } = this.props;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="catalog-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />} hideLoadingComponent>
                    <div className="item-list-container">
                        {this.renderModal()}
                        <Header as='h3' content={this.translate(`${title ? 'Edit' : 'Create'} Catalog`)}></Header>
                        <Grid>
                            <GridRow divided>
                                <GridColumn width={title ? '8' : '16'}>
                                    <ProductCatalogForm
                                        localizationService={this.props.localizationService}
                                        title={this.props.title}
                                        language={this.props.language}
                                        content={this.props.content}
                                        onSubmit={this.props.onSave}
                                        onDelete={this.props.onDelete}
                                    />
                                </GridColumn>
                                {this.props.title && <GridColumn width={'8'} style={{ marginTop: '25px' }}>
                                    <Header as='h3' content={this.translate('Catalog Products')} />
                                    {(this.props.linkedProducts && this.props.linkedProducts?.length > 0) &&
                                        <Card fluid>
                                            <Card.Content>
                                                <Button fluid basic color='blue' icon='chain' content='Add product to catalog' onClick={() => this.toggleModal(true)} />
                                            </Card.Content>
                                        </Card>
                                    }
                                    <Card fluid>
                                        <Card.Content>
                                            <ScrollableList className={this.props.linkedProducts && this.props.linkedProducts?.length > 0 ? 'six' : 'four'}>
                                                {this.renderLinkedProductsList()}
                                            </ScrollableList>
                                        </Card.Content>
                                    </Card>
                                    <Label color='grey' icon='boxes' content={`${this.props.linkedProducts ? this.props.linkedProducts.length : 0} ${commonUtils.plur('product', 'products', this.props.linkedProducts ? this.props.linkedProducts.length : 0)} ${this.translate('in this collection')}`} />
                                </GridColumn>}
                            </GridRow>
                        </Grid>
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
