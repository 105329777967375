import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import { DeleteButton } from '../../../controls';
import GroupForm from '../../../forms/permissions/GroupForm';
import "./styles.css";

export interface ISectionDesktopPageProps {
    name: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ISectionDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        name: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <Gatekeeper name="group-management">
                <div className="group-editor-container">
                    {this.props.name && (
                        <Button.Group floated="right">
                            <DeleteButton
                                handleOnClick={this.props.onDelete}
                                icon={"trash"}
                                itemName={this.props.name}
                                itemType={"group"}
                            />
                        </Button.Group>
                    )}
                    <GroupForm
                        name={this.props.name}
                        onSubmit={(data) => this.props.onSave(data)}
                    />
                </div>
            </Gatekeeper>
        );
    }
}