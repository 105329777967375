import React, { PureComponent } from 'react';
import BackToTopButton from '../../controls/buttons/BackToTopButton';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IPageProps {
    children?: any;
    header?: any;
    routes?: any[];
    createRoutes?: Function | undefined;
    headerHidden?: boolean;
    footer?: any;
}

export default class Page extends PureComponent<IPageProps> {
    render() {
        let { children, header, routes, createRoutes, headerHidden } =
            this.props;
        let desktopRoutes = routes;
        let mobileRoutes = routes;
        if (createRoutes && !routes) {
            desktopRoutes = createRoutes("desktop");
            mobileRoutes = createRoutes("mobile");
        }

        return (
            <div>
                <DesktopPage
                    header={header}
                    routes={desktopRoutes}
                    headerHidden={headerHidden}
                >
                    {children}
                </DesktopPage>
                <MobilePage
                    header={header}
                    routes={mobileRoutes}
                    headerHidden={headerHidden}
                >
                    {children}
                </MobilePage>
                <BackToTopButton />
            </div>
        );
    }
}
