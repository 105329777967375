import React, { PureComponent } from "react";
import {
    Segment,
    Header,
    Button,
    Form,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import './styles.css';

export interface IIndividualServiceTemplateProps {
    section: any;
}

export default class IndividualServiceTemplate extends PureComponent<
    IIndividualServiceTemplateProps
> {
    render() {
        const { section } = this.props;
        console.log('section', section)
        return (
            <div>
                <figure className={`image-and-text image-${section.additional.imagePosition}`}>
                    <img src={section.additional.imageLink} />
                    <figcaption>
                        <Header>{section.title}</Header>
                        {section.content}
                    </figcaption>
                </figure>
                {section.additional.callToAction &&
                    <div style={{ textAlign: 'center' }}>
                        <Form.Field
                            id='form-button-control-public'
                            control={Button}
                            content={section.additional.callToAction}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                }
            </div>
        )
    }
}