import React, { PureComponent } from "react";
import {
    Grid,
    Button,
    Icon,
    Form,
    Message,
    Card,
    Label,
    Dropdown,
    Modal,
    Image,
} from "semantic-ui-react";
import { IUserSettingsPageProps } from "./UserSettingsPage";
import Avatar from "boring-avatars";
import generator from "generate-password";
import "./styles.css";
import * as zxcvbn from "zxcvbn";
import SettingsTemplate from "../SettingsTemplate";

export interface IUserSettingsDesktopPageProps extends IUserSettingsPageProps {
    onChange: (path: string) => any
}

export interface IUserSettingsDesktopPageState {
    open: boolean;
    name: string;
    password: string;
    passwordRepeat: string;
    strength: number;
    howLong: number;
    generated: boolean;
    disableSubmit: boolean;
}

export default class DesktopPage extends PureComponent<
    IUserSettingsDesktopPageProps,
    IUserSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        password: "",
        passwordRepeat: "",
        strength: 0,
        howLong: 0,
        generated: false,
        disableSubmit: true,
    };

    static options = [
        { key: "administrator", text: "Administrator", value: "administrator" },
        { key: "collaborator", text: "Collaborator", value: "collaborator" },
    ];

    componentDidMount() { }

    protected strongPasswordGenerator() {
        let password = generator.generate({
            length: 24,
            numbers: true,
            symbols: true,
        });
        let strength = zxcvbn(password);
        this.setState({
            disableSubmit: false,
            generated: true,
            password,
            passwordRepeat: password,
            strength: strength["score"],
            howLong:
                strength["crack_times_display"].online_no_throttling_10_per_second,
        });
    }

    protected handleItemClick(name) {
        this.props.onChange(name)
    }

    protected setOpen(open, name) {
        this.setState({ open, name: name, password: "" });
    }

    protected renderUserModal() {
        const { open, name } = this.state;
        return (
            <Modal
                open={open}
                onClose={() => this.setOpen(false, "")}
                onOpen={() => this.setOpen(true, name)}
            >
                <Modal.Header image>
                    <Image floated="left" verticalAlign="middle">
                        <Avatar
                            size={40}
                            name={name}
                            variant="bauhaus"
                            colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                        />
                    </Image>
                    {name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <input />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <input />
                            </Form.Field>
                            <Form.Field>
                                <label>Associated Groups</label>
                                <Dropdown
                                    placeholder="Skills"
                                    fluid
                                    multiple
                                    selection
                                    options={DesktopPage.options}
                                />
                            </Form.Field>
                            <Grid columns={"equal"}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Password</label>
                                        <input type="password" />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Retype Password</label>
                                        <input type="password" />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column style={{ textAlign: "center" }}>
                                    <Button
                                        content={"Generate a strong password"}
                                        onClick={() => this.strongPasswordGenerator()}
                                    ></Button>
                                    {this.state.password && (
                                        <Message>
                                            {this.state.password}
                                            <br />
                                            Strength: ${this.state.strength}
                                        </Message>
                                    )}
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setOpen(false, "")} primary>
                        Proceed <Icon name="chevron right" />
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="users">
                {this.renderUserModal()}
                <h2>Users</h2>
                <Card.Group>
                    <Card
                        fluid
                        onClick={() => {
                            this.setOpen(true, "Julian Wolfe");
                        }}
                    >
                        <Card.Content>
                            <Image floated="left">
                                <Avatar
                                    size={50}
                                    name="Julian Wolfe"
                                    variant="bauhaus"
                                    colors={[
                                        "#92A1C6",
                                        "#146A7C",
                                        "#F0AB3D",
                                        "#C271B4",
                                        "#C20D90",
                                    ]}
                                />
                            </Image>
                            <Card.Header>Julian Wolfe</Card.Header>
                            <Card.Meta>julian@dreamship.ca</Card.Meta>
                            <Card.Content extra>
                                <Label>Administrator</Label>
                            </Card.Content>
                        </Card.Content>
                    </Card>
                    <Card
                        fluid
                        onClick={() => {
                            this.setOpen(true, "Christopher Richa");
                        }}
                    >
                        <Card.Content>
                            <Image floated="left">
                                <Avatar
                                    size={50}
                                    name="Christopher Richa"
                                    variant="bauhaus"
                                    colors={[
                                        "#92A1C6",
                                        "#146A7C",
                                        "#F0AB3D",
                                        "#C271B4",
                                        "#C20D90",
                                    ]}
                                />
                            </Image>
                            <Card.Header>Christopher Richa</Card.Header>
                            <Card.Meta>christopher@dreamship.ca</Card.Meta>
                            <Card.Content extra>
                                <Label>Administrator</Label>
                            </Card.Content>
                        </Card.Content>
                    </Card>
                    <Card
                        fluid
                        onClick={() => {
                            this.setOpen(true, "Meagan Rodrigues");
                        }}
                    >
                        <Card.Content>
                            <Image floated="left">
                                <Avatar
                                    size={50}
                                    name="Meagan Rodrigues"
                                    variant="bauhaus"
                                    colors={[
                                        "#92A1C6",
                                        "#146A7C",
                                        "#F0AB3D",
                                        "#C271B4",
                                        "#C20D90",
                                    ]}
                                />
                            </Image>
                            <Card.Header>Meagan Rodrigues</Card.Header>
                            <Card.Meta>meagan@dreamship.ca</Card.Meta>
                            <Card.Content extra>
                                <Label>Collaborator</Label>
                            </Card.Content>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </SettingsTemplate>
        );
    }
}
