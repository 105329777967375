import React, { PureComponent } from "react";
import { Button, Form, Header, Icon, Message, Modal, Popup, Table } from "semantic-ui-react";
import ApiKeyForm from "../../../../../forms/applications/ApiKeyForm";
import FeaturesLinkForm from "../../../../../forms/features/FeaturesLinkForm";
import copy from "clipboard-copy";
import { toast } from "react-toastify";

export interface IAssociateApiKeyModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onSave: (data: any) => Promise<any>;
    onToggleFeature: (item: any, data: any) => Promise<any>;

    features: any[];
    toastConfig: any;
    item: any;
}

export interface IAssociateApiKeyModalState {
    state: string;
    item: any;
    query: any;
}

export default class AssociateApiKeyModal extends PureComponent<IAssociateApiKeyModalProps, IAssociateApiKeyModalState> {
    state: IAssociateApiKeyModalState = {
        state: 'apikey',
        item: undefined,
        query: {},
    };

    toggleModal() {
        this.props.toggleModal();
        this.setState({
            state: 'apikey',
            item: undefined,
            query: {},
        })
    }

    async handleSave(data) {
        let item;

        try {
            item = await this.props.onSave(data);
        } catch(e) {
            throw e;
        }

        this.setState({
            item,
            state: 'copy',
        });
    }

    goToApiKey() {
        return this.setState({
            state: 'apikey'
        })
    }

    goToFeatureSelection() {
        return this.setState({
            state: 'features',
        })
    }

    renderState(state: string) {
        switch (state) {
            case 'apikey': return this.renderApiKeyState();
            case 'copy': return this.renderCopyApiKeyState();
            case 'features': return this.renderFeatureSelectionState();
        }

        return ""
    }

    renderApiKeyState() {
        const { Gatekeeper, item } = this.props;
        return [
            <Modal.Content>
                <Gatekeeper name="application-apikey-management">
                    <Header>Give the api key a name</Header>
                    <p>API keys are meant to be single use with the least permissions necessary to achieve a given goal. Choose a name that describes how the API key will be used so that it can easily be tracked and modified as needed.</p>
                    <ApiKeyForm
                        name={item?.name}
                        controlsBottom
                        controlsTop={false}
                        onSubmit={(data) => this.handleSave(data)}
                    />
                </Gatekeeper>
            </Modal.Content>,
        ];
    }

    async copyApiKey(item) {
        try {
            await copy(item?.key);
            toast.success(`Key for ${item?.name} copied to clipboard.`, this.props.toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", this.props.toastConfig);
        }
    }

    renderCopyApiKeyState() {
        const { Gatekeeper } = this.props;
        const { item } = this.state;

        return [
            <Modal.Content>
                <Gatekeeper name="application-apikey-management">
                    <Header>Copy API Key</Header>
                    <p>The API key will only be shown once. Be sure to store it somewhere secure.</p>
                    <Form.Group>
                        <Popup
                            trigger={
                                <Button
                                    icon
                                    labelPosition="right"
                                    basic
                                    size="mini"
                                    onClick={async () => {
                                        this.copyApiKey(item);
                                    }}
                                >
                                    <Icon name="clipboard" />
                                    {item?.key}
                                </Button>
                            }
                            content="Copy the feature key."
                            position="right center"
                            className={'popover-modal-view'}
                        />
                    </Form.Group>
                </Gatekeeper>
            </Modal.Content>,
            <Modal.Actions>
                Associate Features?
                <Button onClick={() => this.toggleModal()}>Later</Button>
                <Button positive onClick={() => this.goToFeatureSelection()}>Now</Button>
            </Modal.Actions>
        ];
    }

    renderFeatureSelectionState() {
        const { Gatekeeper, features, toastConfig } = this.props;
        return [
            <Modal.Content>
                <Gatekeeper name="license-management">
                    <Header>Associtate features to the api key</Header>
                    Toggling the features will associate them to the key in real time.
                    <Message warning>
                        The following features can be assigned to an API key. It is strongly recommended to keep the selections to a minimum to avoid any issues if the API key gets into the wrong hands.
                    </Message>
                    <FeaturesLinkForm
                        features={features}
                        onSubmit={(data) => this.props.onToggleFeature(this.state.item, data)}
                        toastConfig={toastConfig}
                        licenseFeatures={[]}
                    />
                </Gatekeeper>
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.goToApiKey()}>Back</Button>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ];
    }

    render() {
        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={this.props.modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Associate an Api Key</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}