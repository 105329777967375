import React, { PureComponent } from 'react';
import { Message } from 'semantic-ui-react';
import './styles.css';

export interface IUnauthorizedMessageProps { }

export default class UnauthorizedMessage extends PureComponent<IUnauthorizedMessageProps> {

    reloadPage() {
        window.location.reload();
    }

    render() {
        return (
            <div className='unauthorized-message-container'>
                <Message info compact>
                    <Message.Header>It appears you don't have full access.</Message.Header>
                    <Message.Content>
                        <h3>If you think this is a mistake and the error persist, please reach out to an administrator to get access to various features in the platform.</h3>
                        <a href='' onClick={() => this.reloadPage()}>
                            Try again.
                        </a>
                    </Message.Content>
                </Message>
            </div>
        )
    }
}