import { isFuture, isToday, startOfTomorrow } from "date-fns"

export default class DateUtils {
    toDate<DateType extends Date = Date>(
        argument: DateType | number
    ): DateType {
        const argStr = Object.prototype.toString.call(argument)

        // Clone the date
        if (
            argument instanceof Date ||
            (typeof argument === 'object' && argStr === '[object Date]')
        ) {
            // Prevent the date to lose the milliseconds when passed to new Date() in IE10
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: TODO find a way to make TypeScript happy about this code
            return new argument.constructor(argument.getTime())
            // return new Date(argument.getTime())
        } else if (typeof argument === 'number' || argStr === '[object Number]') {
            // TODO: Can we get rid of as?
            return new Date(argument) as DateType
        } else {
            // TODO: Can we get rid of as?
            return new Date(NaN) as DateType
        }
    }

    startOfDay<DateType extends Date>(
        dirtyDate: DateType | number
    ): DateType {
        const date = this.toDate(dirtyDate)
        date.setHours(0, 0, 0, 0)
        return date
    }

    endOfDay<DateType extends Date>(
        dirtyDate: DateType | number
    ): DateType {
        const date = this.toDate(dirtyDate)
        date.setHours(23, 59, 59, 0)
        return date
    }

    renderDate(date: string, seconds: boolean = true) {
        let options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };
        if (!seconds) { delete options.second; }
        return (
            date &&
            new Date(date).toLocaleString('en-CA', options)
        );
    }

    isToday(someDate) {
        return isToday(this.startOfDay(someDate))
    }

    isPast(someDate) {
        return this.toDate(someDate).getTime() < Date.now();
    }

    isFuture(someDate) {
        return this.toDate(someDate) > startOfTomorrow();
    }
}