import React, { PureComponent } from 'react';
import Component from '../../layouts/component/Component';
import Page from '../Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IOverviewPageProps {
    createRoutes?: Function,
    Gatekeeper?: any
    localizationService?: any,
    breadcrumbLevels?: any,
}

export default class OverviewPage extends PureComponent<IOverviewPageProps> {
    render() {
        const {
            createRoutes,
            Gatekeeper,
            localizationService,
            breadcrumbLevels,
        } = this.props;

        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                    <MobilePage Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}