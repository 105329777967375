import React from "react";
import ApplicationSwitcher from "../components/controls/ApplicationSwitcher";
import { Repository } from "../repositories";
import { SecurityService } from "../services";
import GatekeeperFactory from "./GatekeeperFactory";

export default class ApplicationSwitcherFactory {
    constructor(protected repository: Repository, protected service: SecurityService, protected gatekeeperFactory: GatekeeperFactory) { }

    create(hide?: boolean) {
        const Gatekeeper = this.gatekeeperFactory.create();
        return (
            <ApplicationSwitcher
                repository={this.repository}
                service={this.service}
                isMobile={hide}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}