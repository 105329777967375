import React, { PureComponent } from "react";
import "./styles.css";
import {
    Button,
    ButtonGroup,
    Header,
    Icon,
    Modal,
    Table,
} from "semantic-ui-react";
import { ScrollableTable } from "../../../layouts/tables";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import NewTemplateModal from "./modals/NewTemplateModal";
import UnauthorizedMessage from "../../../layouts/messages/UnauthorizedMessage/UnauthorizedMessage";

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    items: any[];
    handleCreateTemplate?: (data: any) => any;
}
export interface IDesktopPageState {
    openModal: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        openModal: false,
    };

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>
                    {item.name}
                </Table.Cell>
                {/* <Table.Cell>{item.state}</Table.Cell> */}
                <Table.Cell textAlign="right">
                    <Gatekeeper name="">
                        <ButtonGroup>
                            <Button
                                icon="edit"
                                color="teal"
                                size="tiny"
                                as='a'
                                href={`/website-template-management/${item.id}/edit`}
                            />
                            <Button
                                icon="trash"
                                color="red"
                                size="tiny"
                                disabled
                            // onClick={() => this.props.onDelete(item)}
                            />
                        </ButtonGroup>
                    </Gatekeeper>
                </Table.Cell>
            </Table.Row>
        )
    }

    private displayModal(openModal: boolean = true) {
        this.setState({ openModal });
    }

    save(data) {
        console.log(data);
        // this.props.onSave(attribute);
        this.displayModal(false);
    }

    private handleCreateTemplate(data: any) {
        console.log(data);
        if (this.props.handleCreateTemplate) {
            this.props.handleCreateTemplate(data);
        }
        this.setState({ openModal: false })
    }

    // private renderModal(item: any, modalOpen: boolean, Gatekeeper) {
    //     return (
    //         <ContactEditorModal
    //             onSave={async (data: any) => this.props.onSave(data)}
    //             toggleModal={() => this.toggleModal()}
    //             modalOpen={modalOpen}
    //             Gatekeeper={Gatekeeper}
    //             item={item}
    //         />
    //     );
    // }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={2} textAlign={'center'}>
                    <Header>No templates!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        if(!this.props.items) {return;}
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return !items.length ? this.renderNoContentMessage() : items;
    }

    render() {
        const { Gatekeeper, items } = this.props;

        return (
            <Gatekeeper name="manage-websitetemplates" unauthorizedComponent={<UnauthorizedMessage />}>
                <Modal open={this.state.openModal}>
                    <NewTemplateModal onSave={(data) => this.handleCreateTemplate(data)} toggleModal={(display) => this.displayModal(false)} />
                </Modal>
                <ScrollableTable className="ten">
                    <Table.Header>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                        <Table.HeaderCell textAlign="right" floated="right">Actions</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.renderTableBody()}
                    </Table.Body>
                    <Gatekeeper name="manage-websitetemplates">
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='6'>
                                    <Button color="green" size="small" floated="right" icon onClick={() => this.displayModal()}><Icon name='plus' />Add Template</Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Gatekeeper>
                </ScrollableTable>
            </ Gatekeeper>
        );
    }
}
