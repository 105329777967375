import React, { PureComponent } from 'react';
import {
    Button,
    Card,
    CardGroup,
    Container,
    Divider,
    Header,
    Icon,
    Image,
} from 'semantic-ui-react';
import { LoadingSpinner } from '../../controls';
import { UnauthorizedMessage } from '../../layouts/messages';
import { DimmedModal } from '../../layouts/modals';
import { MobileSkeleton } from '../../layouts/skeleton';
import CommonUtils from '../../../utilities/Common/CommonUtils';
import CreateButton from '../../controls/buttons/CreateButton';
import './styles.css';

const commonUtils = new CommonUtils();

export interface IMobilePageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
}

export default class MobilePage extends PureComponent<IMobilePageProps> {

    state = {
    };

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal header="Nothing to see here!" content="Add your first section!" actions={['Not now', { key: 'done', content: 'Create section', positive: true, href: '/sections/create' }]} />;
        }
        return;
    }

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header">
                <Header as="h2" content="Sections" className="mobile-items" />
            </div>
        );
    }

    renderCards() {
        const { items } = this.props;
        let cardList = [];
        cardList = items.map((item: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header>{item.title}</Card.Header>
                        <Card.Meta>
                            <span style={{ textTransform: 'capitalize' }}>
                                {commonUtils.renderLanguage(item.language)} {item.type} section
                            </span>
                        </Card.Meta>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`sections/${item.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    Edit
                                </Button>
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? this.renderNoContentMessage() : cardList}
            </CardGroup>
        );
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>No sections!</Header>
                <Image centered src="/images/icons/Site Section.png" size="small" />
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="website-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="section-list-container">
                        {this.renderPageHeader()}
                        {this.renderCards()}
                    </div>
                    {this.renderNoContentModal()}
                    <CreateButton href='/sections/create' attentionGrabber={this.props.items.length === 0} />
                </Gatekeeper>
            </MobileSkeleton>
        )
    }
}