import React, { PureComponent } from "react";
import {
    Button,
    Icon,
    Table,
    Modal,
    Form,
    Popup,
    FormInput,
    FormGroup,
    Grid,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { ScrollableTable } from "../../layouts/tables";
import FeaturesModalContent from "./FeaturesModalContent";
import { DimmedModal } from "../../layouts/modals";
import { Message, UnauthorizedMessage } from "../../layouts/messages";
import { LoadingSpinner } from "../../controls";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import "./styles.css";

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
    toggleFeatureActive: Function;
    toastConfig: any;
    delayCount: number;
    featureUpdated?: boolean;
}
export interface IDesktopPageState {
    modalOpen: boolean;
    features: any[];
    filteredNumberResults: number;
    searchInputHasValue: boolean;
    searchvalue: string;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    static defaultProps = {
        onDoubleClick: () => { },
        toggleFeatureActive: (e) => { },
        delayCount: 5000,
        toastConfig: {},
        featureUpdated: false,
    }

    state = {
        modalOpen: false,
        features: [],
        filteredNumberResults: 0,
        searchInputHasValue: false,
        searchvalue: '',
    };

    componentDidMount() {
        this.setState({
            features: this.props.items ? this.props.items : [],
            filteredNumberResults: this.props.items ? this.props.items.length : 0,
        })
    }

    componentDidUpdate(prevProps: Readonly<IDesktopPageProps>, prevState: Readonly<IDesktopPageState>, snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({
                features: this.props.items ? this.props.items : [],
                filteredNumberResults: this.props.items ? this.props.items.length : 0,
            })
        }
    }

    filterFeatures(name: string) {
        const { items } = this.props;
        if (!items) { return; }
        const features = items.filter(function (feature) { return feature.name.toLowerCase().indexOf(name.toLowerCase()) >= 0; });
        this.setState({ features, filteredNumberResults: features.length, searchInputHasValue: name.length > 0, searchvalue: name });
    }

    resetTextInput() {
        this.filterFeatures('');
    }

    openModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
    }

    async copyFeatureKey(feature) {
        try {
            await copy(feature.key);
            toast.success(`Key for ${feature.name} copied to clipboard.`, this.props.toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", this.props.toastConfig);
        }
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header="Nothing to see here!"
                content="Add your first feature!"
                actions={['Not now', { key: 'done', content: 'Create feature', positive: true, href: '/features/create' }]}
            />
        }
        return;
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    async handleFeatureActivationToggle(feature: any) {
        const { toggleFeatureActive, toastConfig, delayCount } = this.props;
        const verb = feature.active ? 'deactivated' : 'activated';
        try {
            await toggleFeatureActive(feature);
            toast.success(`${feature.name} was succesfully ${verb}. Refreshing in ${delayCount / 1000} seconds`, toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", toastConfig);
        }
    }

    private renderTableHeaders() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Key</Table.HeaderCell>
                    <Table.HeaderCell icon>
                        Status{" "}
                        <Icon
                            name="question circle"
                            className="icon-link"
                            onClick={() => this.openModal()}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderTableBody() {
        const { features } = this.state;
        if (!features) return;
        const featuresTable = features.map((feature: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, feature)}>
                    <Table.Cell>{feature.name}</Table.Cell>
                    <Table.Cell>
                        <Form.Group>
                            <Popup
                                trigger={
                                    <Button
                                        icon
                                        labelPosition="right"
                                        basic
                                        size="mini"
                                        onClick={async () => {
                                            this.copyFeatureKey(feature);
                                        }}
                                    >
                                        <Icon name="clipboard" />
                                        {feature.key}
                                    </Button>
                                }
                                content="Copy the feature key."
                                position="right center"
                            />
                        </Form.Group>
                    </Table.Cell>
                    <Table.Cell className="feature-list-feature-status">
                        <Icon className={'feature-list-activation-toggle'} name="circle" color={feature.active ? "green" : "black"} onClick={async (e) => await this.handleFeatureActivationToggle(feature)} size={'large'} />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            icon="edit"
                            color="teal"
                            as="a"
                            href={`/features/${feature.id}/edit`}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
        return <Table.Body>{!featuresTable.length ? <Message content="No content." compact /> : featuresTable}</Table.Body>;
    }

    private renderModal(modalOpen: boolean) {
        return (
            <Modal
                onClose={() => this.openModal()}
                dimmer
                open={modalOpen}
                size="small"
            >
                <Modal.Header>About Feature Statuses</Modal.Header>
                <Modal.Content>
                    <FeaturesModalContent />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.openModal()}>
                        Got it!
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="feature-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />} hideLoadingComponent={this.props.featureUpdated}>
                    {this.renderModal(this.state.modalOpen)}
                    <div className="item-list-container">
                        <Grid columns={'1'}>
                            <Grid.Column width={'5'}>
                                <FormGroup>
                                    <FormInput placeholder="Search feature by name" value={this.state.searchvalue} onChange={(event) => this.filterFeatures(event.target.value)} type='text' icon={(e) => {
                                        if (this.state.searchInputHasValue) {
                                            return <Icon name='delete' link onClick={() => { this.resetTextInput() }} />
                                        }
                                        return <Icon name='search' />
                                    }} />
                                    <small className="text-muted">Now showing {this.state.filteredNumberResults} {commonUtils.plur('result', 'results', this.state.filteredNumberResults)}{this.state.searchInputHasValue && ` for ${this.state.searchvalue}`}.</small>
                                </FormGroup>
                            </Grid.Column>
                        </Grid>
                        <ScrollableTable padded striped selectable className="ten">
                            {this.renderTableHeaders()}
                            {this.renderTableBody()}
                            <Table.Footer fullWidth>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='4'>
                                    <Button color="green" size="small" floated="right" icon href='/features/create'><Icon name='plus' />Create Features</Button>
                                </Table.HeaderCell>
                            </Table.Footer>
                        </ScrollableTable>
                        {this.renderNoContentModal()}
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
