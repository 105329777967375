import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Form,
    Button,
    Icon,
    Input,
    Checkbox,
    FormGroup,
} from "semantic-ui-react";
import TemporaryLanguagePicker from "../../../controls/TemporaryLanguagePicker";
import SettingsTemplate from "../SettingsTemplate";
import { IAccountSettingsPageProps } from "./AccountSettingsPage";
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import "./styles.css";

const commonUtils = new CommonUtils();
export interface IAccountSettingsDesktopPageProps extends IAccountSettingsPageProps {
    onChange: (path: string) => any,
    localizationService: any,
}

export interface IAccountSettingsDesktopPageState {
    open: boolean;
    name: string;
    disableSubmit: boolean;
    checked: boolean;
    accountEmailChecked: boolean;
}

export default class DesktopPage extends PureComponent<
    IAccountSettingsDesktopPageProps,
    IAccountSettingsDesktopPageState
> {
    state = {
        open: false,
        name: "",
        disableSubmit: true,
        checked: false,
        accountEmailChecked: false,
    };

    protected setOpen(open, name) {
        this.setState({ open, name: name });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    getLanguage() {
        return this.props.localizationService.getLanguage();
    }

    render() {
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="account" localizationService={this.props.localizationService}>
                <h2>{this.translate('Account')}</h2>
                {/* <Segment>
                    <Form>
                        <Grid columns={"one"}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>Change personal information</h3>
                                    <Form.Field>
                                        <label>First Name</label>
                                        <Input />
                                        <label>Last Name</label>
                                        <Input />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            onClick={() => this.setOpen(false, "")}
                                            primary
                                            icon
                                        >
                                            Update personal information <Icon name="save" />
                                        </Button>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment> */}
                {/* <Segment>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>Change email address</h3>
                                    <Form.Field>
                                        <Input />
                                    </Form.Field>
                                    <p>
                                        For security reasons, changing your email address will log
                                        you out of any active sessions across your all of your
                                        devices.
                                    </p>
                                    <Form.Field>
                                        <Checkbox
                                            label="I understand and still want to change my email address."
                                            onChange={() => this.toggle()}
                                            checked={this.state.accountEmailChecked}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            onClick={() => this.setOpen(false, "")}
                                            primary
                                            icon
                                        >
                                            Update email address <Icon name="save" />
                                        </Button>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment> */}
                <Segment>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>{this.translate('Change language')}</h3>
                                    <Grid>
                                        <Grid.Row columns={3}>
                                            <Grid.Column>{this.translate('Current language')}:</Grid.Column>
                                            <Grid.Column>{commonUtils.renderLanguageRealName(this.getLanguage())}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <br />
                                    <TemporaryLanguagePicker localizationService={this.props.localizationService} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </SettingsTemplate>
        );
    }
}
