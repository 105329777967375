import React from 'react';
import ProductDefinitionsEditPage from '../../../components/pages/productdefinitions/ProductDefinitionsEditPage';
import ProductEditPage from '../../../components/pages/products/ProductEditPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';
import { slug as slugGen } from 'slug-gen';

export default class ProductDefinitionEditController extends LocalizedController {
    private repository: Repository;
    private taxesRepository: Repository;
    private contentsRepository: Repository;
    private attributesRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        updatedItem: undefined,
        taxes: [],
        contents: [],
        properties: [],
        selections: [],
        productTaxes: [],
        webmeta: undefined,
        isFetching: true,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, taxesRepository, contentsRepository, attributesRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.taxesRepository = taxesRepository;
        this.contentsRepository = contentsRepository;
        this.attributesRepository = attributesRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    private async getMeta() {
        let webmeta;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            webmeta = await this.repository.getByPath(`definitions/${this.params?.id}/webmetas`);
            if (webmeta) {
                webmeta = webmeta[0];
            }
        } catch (e) {
            return;
        }

        return webmeta;
    }

    private async getContents() {
        let contents;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            contents = await this.repository.getByPath(`definitions/${this.params?.id}/contents`);
        } catch (e) {
            return;
        }

        return contents;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item: any = this.state.item;
        let slug: any = data['slug'];
        delete data['slug'];
        item = {
            ...item,
            ...data,
        }

        try {
            await this.repository.update({
                ...item,
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
            });

            if (slug) {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                let result = await this.repository.getByPath(`definitions/${this.params?.id}/webmetas`);
                if (result) {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    await this.repository.updateByPath({ ...result[0], slug: slugGen(slug) }, `definitions/${this.params?.id}/webmetas/${result.id}`);
                    // @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    this.router.redirect(`/productdefinitions/${this.params?.id}/confirmation`);
                }
            }

            // @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.createByPath({ slug: slugGen(slug), language: JSON.parse(localStorage.getItem('lang')!) }, `definitions/${this.params?.id}/webmetas`)

            // @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            this.router.redirect(`/productdefinitions/${this.params?.id}/confirmation`);
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/store-management/products');
        } catch (e) {
            throw e;
        }
    }

    private async handleCancel() {
        return this.router.redirect('/store-management/products');
    }

    // attributes

    private async fetchAttributes() {
        let items;
        let properties;
        let selections;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.attributesRepository.find({ definitionId: this.params?.id });
            properties = items.filter((item) => { return item.type == 'property' });
            selections = items.filter((item) => { return item.type == 'selection' });
            this.setState({ properties, selections });
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private handleAttributesChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleAttributesSave(data: any) {
        if (data.id) {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            data.definitionId = this.params?.id
        }
        try {
            data.id ? await this.attributesRepository.updateByPath(data, `attributes/${data.id}`) : await this.attributesRepository.create(data, {
                query: {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    definitionId: this.params?.id
                }
            });
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async createProductAttribute(type: string) {
        let entity = {
            name: "",
            type: type,
            options: [],
        }

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.attributesRepository.create(entity, { query: { definitionId: this.params?.id } });
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async deleteProductAttribute(id: any) {
        try {
            await this.attributesRepository.delete(id);
            await this.fetchAttributes();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    // end attributes

    // taxes

    private async getAllTaxes() {
        let items;

        try {
            items = await this.taxesRepository.get();
        } catch (e) {
            return;
        }

        return items;
    }

    private async getProductTaxes() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`definitions/${this.params?.id}/taxes`);
        } catch (e) {
            return;
        }

        return items;
    }

    private async handleLinkTax(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.link(this.params?.id, 'taxId', data);
            let productTaxes = await this.getProductTaxes();
            this.setState({ productTaxes });
            // await this.fetchItems();
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private async handleUnlinkTax(data) {
        console.error(data);
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlink(this.params?.id, 'taxId', data);
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
        try {
            let productTaxes = await this.getProductTaxes();
            this.setState({ productTaxes });
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private async handleCreateTax(data: any) {
        let item;

        try {
            item = await this.taxesRepository.create(data);
            let allTaxes = await this.getAllTaxes();
            this.setState({ allTaxes });
        } catch (e) {
            throw e;
        }
    }

    // end taxes

    async componentDidMount() {
        await this.fetchItems();
    }

    private async fetchItems() {
        let item = await this.get();
        let webmeta = await this.getMeta();
        let contents = await this.getContents();
        let taxes = await this.getAllTaxes();
        let productTaxes = await this.getProductTaxes();
        await this.fetchAttributes();

        this.setState({
            item,
            taxes,
            contents,
            productTaxes,
            webmeta,
            isFetching: false,
        });
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 3, text: this.state.contents && this.state.contents.length > 0 ? this.state.contents![0]['title'] : '', value: undefined },
        ]
    }

    private async saveAndLinkContent(data: any) {
        let contents, content;

        try {
            content = await this.contentsRepository.create({ content: data.content, title: data.title, language: data.language });
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.linkByPath(`/definitions/${this.params?.id}`, { contentId: content.id });
            contents = await this.getContents();
            this.setState({ contents });
        } catch (e) {
            throw new Error(e);
        }
    }

    private async unlinkAndDeleteContent(data: any) {
        let contents;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.unlinkByPath(`/definitions/${this.params?.id}`, { contentId: data });
            await this.contentsRepository.delete(data);
            contents = await this.getContents();
            this.setState({ contents });
        } catch (e) {
            throw new Error(e);
        }
    }

    private async updateContent(data: any) {
        try {
            await this.contentsRepository.update(data);
            let contents = await this.getContents();
            this.setState({ contents });
        } catch (e) {
            throw e;
        }
    }

    render() {
        const { item, taxes, properties, selections, productTaxes, contents, isFetching, webmeta } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductDefinitionsEditPage
                isFetching={isFetching}
                item={item}
                taxes={taxes}
                productTaxes={productTaxes}
                properties={properties}
                selections={selections}
                content={contents && contents[0]}
                contents={contents}
                webmeta={webmeta}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onContentSave={async (data: any) => this.saveAndLinkContent(data)}
                onContentDelete={async (data: any) => this.unlinkAndDeleteContent(data)}
                onContentUpdate={async (data: any) => this.updateContent(data)}
                onDelete={async () => this.handleDelete()}
                onCancel={async () => this.handleCancel()}
                createProperty={async (data: any) => this.createProductAttribute(data)}
                deleteProductAttribute={async (data: any) => this.deleteProductAttribute(data)}
                onAttributesChange={(key: string, value: any) => this.handleAttributesChange(key, value)}
                onAttributesSave={async (data: any) => this.handleAttributesSave(data)}
                handleLinkTax={async (data: any) => this.handleLinkTax(data)}
                handleUnlinkTax={async (data: any) => this.handleUnlinkTax(data)}
                handleCreateTax={async (data: any) => this.handleCreateTax(data)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                localizationService={this.localizationService}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}