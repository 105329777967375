import React, { PureComponent } from "react";
import "./styles.css";
import {
    Button,
    Icon,
    CardGroup,
    Card,
    Divider,
    Header,
} from "semantic-ui-react";
import { MobileSkeleton } from "../../layouts/skeleton";
import CommonUtils from "../../../utilities/Common/CommonUtils";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../controls";
import { Message, UnauthorizedMessage } from "../../layouts/messages";
import { DimmedModal } from "../../layouts/modals";
import CreateButton from "../../controls/buttons/CreateButton";

const commonUtils = new CommonUtils();

export interface IMobilePageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    toggleFeatureActive: Function;
    toastConfig: any;
    delayCount: number;
    featureUpdated?: boolean;
}
export interface IMobilePageState {
    modalOpen: boolean;
}

export default class MobilePage extends PureComponent<
    IMobilePageProps,
    IMobilePageState
> {
    static defaultProps = {
        toggleFeatureActive: (e) => { },
        delayCount: 5000,
        toastConfig: {},
        featureUpdated: false,
    }

    async handleFeatureActivationToggle(feature: any) {
        const { toggleFeatureActive, toastConfig, delayCount } = this.props;
        const verb = feature.active ? 'deactivated' : 'activated';
        try {
            await toggleFeatureActive(feature);
            toast.success(`${feature.name} was succesfully ${verb}. Refreshing in ${delayCount / 1000} seconds`, toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error.", toastConfig);
        }
    }

    toggleFeatureButton(feature) {
        return (
            <Button
                className=""
                color={feature.active ? "black" : "green"}
                basic
                onClick={async (e) => await this.handleFeatureActivationToggle(feature)}
            >
                <Icon name='circle' color={feature.active ? "black" : "green"} />
                {feature.active ? "Deactivate" : "Activate"}
            </Button>
        )
    }

    renderPageHeader() {
        return (
            <div className="mobile-item-list-header">
                <Header as="h2" content="Features" className="mobile-items" />
            </div>
        );
    }

    renderCards() {
        const { items } = this.props;
        const cardList = items.map((item: any) => {
            return (
                <Card image>
                    <Card.Content>
                        <Card.Header><Icon name='circle' color={item.active ? 'green' : 'black'} />{' '}{item.name}</Card.Header>
                        <Divider />
                        <Card.Content extra>
                            <div className="ui two buttons">
                                <Button
                                    className=""
                                    icon
                                    color="teal"
                                    as="a"
                                    href={`/features/${item.id}/edit`}
                                >
                                    <Icon name="edit" />
                                    Edit
                                </Button>
                                {this.toggleFeatureButton(item)}
                            </div>
                        </Card.Content>
                    </Card.Content>
                </Card>
            );
        });
        return (
            <CardGroup className="mobile-item-list-container" centered>
                {!cardList.length ? <Message content="No content." size='large' /> : cardList}
            </CardGroup>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="feature-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}
                    hideLoadingComponent={this.props.featureUpdated}>
                    <div className="mobile-item-list">
                        {this.renderPageHeader()}
                        {this.renderCards()}
                    </div>
                    {!this.props.items.length &&
                        <DimmedModal header="Nothing to see here!" content="Add your first feature!" actions={['Not now', { key: 'done', content: 'Create feature', positive: true, href: '/features/create' }]} />
                    }
                    <CreateButton href='/features/create' attentionGrabber={this.props.items.length === 0} />
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
