import React, { PureComponent } from "react";
import { Tab, Table, Button, Icon, Popup, Container, Header } from "semantic-ui-react";
import { ScrollableTable } from "../../../../../layouts/tables";
import AssociateApplicationModal from "./AssociateApplicationModal";
import { SecurityService } from "../../../../../../services";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface IApplicationsPaneProps {
    items: any[];
    Gatekeeper: any;
    onSearch: (data: any) => Promise<any[]>;
    onCreateApplication: (data: any) => Promise<any>;
    onLinkApplication: (data: any) => Promise<any>;
    onUnlinkApplication: (data: any) => Promise<any>;
    onModalClose: () => Promise<any>;
}

export interface IApplicationPaneState {
    modalOpen: boolean;
}

export default class ApplicationsPane extends PureComponent<IApplicationsPaneProps, IApplicationPaneState> {

    state: IApplicationPaneState = {
        modalOpen: false,
    };

    toggleModal() {
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }), () => {
            if (!this.state.modalOpen) {
                this.props.onModalClose();
            }
        });
    }

    handleSwitch(appId) {
        cookies.set(SecurityService.APPLICATION_ID_KEY, appId, {
            sameSite: 'strict',
            path: '/',
        });
        localStorage.setItem('applicationId', appId);
        if (window.location.pathname !== '/login') {
            window.location.replace('/');
        }
    }

    renderItem(item, Gatekeeper) {
        return (
            <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Gatekeeper name="application-view">
                        <Popup
                            trigger={
                                <Button
                                    icon="eye"
                                    color="blue"
                                    as="a"
                                    href={`/applications/${item.id}`}
                                />
                            }
                            content="View the details of the application."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="application-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="edit"
                                    color="teal"
                                    as="a"
                                    href={`/applications/${item.id}/edit`}
                                />
                            }
                            content="Edit the application's details."
                            basic
                        />
                    </Gatekeeper>
                    <Gatekeeper name="account-application-management">
                        <Popup
                            trigger={
                                <Button
                                    icon="unlink"
                                    color="red"
                                    onClick={() => this.props.onUnlinkApplication(item)}
                                />
                            }
                            content="Applications can be associated to multiple accounts. Unassociating the application will remove the application's access from this account but will not delete or alter the application itself"
                            basic
                        />
                    </Gatekeeper>
                    <Popup
                        trigger={
                            <Button
                                icon="spy"
                                color="purple"
                                onClick={() => this.handleSwitch(item.id)} />
                        }
                        content="Visit this application"
                        basic
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    private renderModal(modalOpen: boolean, Gatekeeper) {
        return (
            <AssociateApplicationModal
                onSearch={async (data: any) => this.props.onSearch(data)}
                onCreateApplication={async (data: any) => this.props.onCreateApplication(data)}
                onLinkApplication={async (data: any) => this.props.onLinkApplication(data)}
                toggleModal={() => this.toggleModal()}
                modalOpen={modalOpen}
                Gatekeeper={Gatekeeper}
            />
        );
    }

    private renderNoContentMessage() {
        return (
            <Table.Row>
                <Table.Cell colSpan={2} textAlign={'center'}>
                    <Header>No applications!</Header>
                </Table.Cell>
            </Table.Row>

        )
    }

    private renderTableBody() {
        const Gatekeeper = this.props.Gatekeeper;
        let items = this.props.items?.map(item => this.renderItem(item, Gatekeeper));
        return !items.length ? this.renderNoContentMessage() : items;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (


            <ScrollableTable className="three">
                <Table.Header fullWidth>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
                <Gatekeeper name="account-application-management">
                    <Table.Footer fullWidth>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan='2'>
                            {this.renderModal(this.state.modalOpen, Gatekeeper)}
                            <Button color="green" size="small" floated="right" icon onClick={() => this.toggleModal()}><Icon name='plus' />Associate Application</Button>
                        </Table.HeaderCell>
                    </Table.Footer>
                </Gatekeeper>
            </ScrollableTable>
        );
    }
}