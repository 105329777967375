import React, { PureComponent } from "react";
import { Button, ButtonGroup, Checkbox, Dropdown, FormGroup, Input, Label, LabelGroup, List, Modal, Select, Table } from 'semantic-ui-react'
import CommonUtils from "../../../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface IAvailabilitiesSettingsProps {
    defaultValue?: string;
    onSave?: (data: any) => any;
    item: any;
    settings: any;
    value?: string;
    localizationService: any;
}

export interface IAvailabilitiesSettingsState {
    value: any;
}

export default class AvailabilitiesSettings extends PureComponent<IAvailabilitiesSettingsProps, IAvailabilitiesSettingsState> {
    state = {
        value: {},
    };

    static defaultProps = {
        item: {},
    }

    componentDidMount() {
        this.setState({
            value: this.props.settings || {},
        });
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private onCheckboxChange(name, checkboxValue) {
        let n;
        let newValue: any[] = [{ isClosed: true }];
        if (!checkboxValue)
            newValue = [{ "open": "0:00", "close": "0:00" }];
        n = { ...this.state.value, [name]: newValue };
        this.setState({ value: n });
    }

    private onTextboxChange(name, val, key, index) {
        const { value } = this.state;
        let newHours = value[key];
        newHours[index][name] = val;
        this.setState({ ...value, [key]: newHours });
    }

    private onAddNewSchedule(key) {
        const { value } = this.state;
        let newHours = value[key];
        newHours.push({ "open": "0:00", "close": "0:00" });
        this.setState({ ...this.state.value, [key]: newHours });
        this.forceUpdate();
    }

    private onDeleteSchedule(key, index) {
        const { value } = this.state;
        let newHours = value[key];
        if (index > 0) {
            newHours.splice(index, 1); // 2nd parameter means remove one item only
        }
        else {
            newHours.shift();
        }
        this.setState({ ...this.state.value, [key]: newHours });
        this.forceUpdate();
    }

    private onSave() {
        if (this.props.onSave) {
            this.props.onSave({ availabilities: this.state.value });
        }
    }

    private generateHeaders() {
        const { value } = this.state;
        let headers: any[] = [];
        for (const [key, val] of Object.entries(value)) {
            headers.push(<Table.HeaderCell>
                {commonUtils.capitalizeFirstLetter(key)}
            </Table.HeaderCell>)
        }
        return headers;
    }

    private generateAvailabilitiesOptions(key, values) {
        let closed: boolean = false;
        let options = values.map((value, index, array) => {
            if (value.isClosed) {
                closed = true;
                return <Label color='red' content={this.translate('Closed')} />
            } else {
                return (
                    <FormGroup style={{ margin: '5px 0px' }}>
                        <Select options={commonUtils.generateTimes()} defaultValue={value.open} name={'open'} search scrolling onChange={(event, { name, value }) => this.onTextboxChange(name, value, key, index)} style={{ margin: '0px 8px' }} /> {':'}
                        <Select options={commonUtils.generateTimes()} defaultValue={value.close} name={'close'} search scrolling onChange={(event, { name, value }) => this.onTextboxChange(name, value, key, index)} style={{ margin: '0px 8px' }} />
                        <ButtonGroup style={{ margin: '0px 15px' }}>
                            {array.length !== 1 && <Button color="red" icon='minus' onClick={() => { this.onDeleteSchedule(key, index) }} />}
                            {index + 1 === array.length && <Button color="green" icon='plus' onClick={() => { this.onAddNewSchedule(key) }} />}
                        </ButtonGroup>
                    </FormGroup>
                )
            }
        })

        return options;
    }

    private generateIsClosedControl(key, value) {
        const isClosed = value[0].isClosed;
        return <Checkbox defaultChecked={isClosed} label={this.translate('Closed') + '?'} name={key} onChange={(event, { name, checked }) => this.onCheckboxChange(name, checked)} />
    }

    private generateAvailabilities() {
        if (!this.state.value) { return; }
        const availabilities = this.state.value;
        let renderedHeader: any[] = [];
        for (const [key, value] of Object.entries(availabilities)) {
            renderedHeader.push(
                <Table>
                    <Table.Header>
                        <Table.HeaderCell>{this.translate(commonUtils.capitalizeFirstLetter(key))}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{this.generateIsClosedControl(key, value)}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row colSpan={2}>
                            <Table.Cell content={<LabelGroup size="large" content={this.generateAvailabilitiesOptions(key, value)} />} />
                        </Table.Row>
                    </Table.Body>
                </Table>
            );
        }

        return renderedHeader;
    }

    render() {
        return (
            <>
                <Modal.Header>{this.translate('Opening Hours')}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.generateAvailabilities()}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={this.translate('Save')} onClick={() => { this.onSave() }} />
                </Modal.Actions>
            </>
        );
    }
}