import React, { PureComponent } from "react";
import "./styles.css";
import {
    Tab,
} from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";

export interface IDesktopPageProps {
    createRoutes?: Function;
    Gatekeeper: any;
    panes: any[];
}
export interface IDesktopPageState { }

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {};

    render() {
        const { Gatekeeper, panes } = this.props;

        return (
            <DesktopSkeleton>
                <Tab menu={{ secondary: true, pointing: true, vertical: true }} grid={{ paneWidth: 14, tabWidth: 2 }} menuPosition='left' panes={panes} />
            </DesktopSkeleton>
        );
    }
}
