import React, { PureComponent } from "react";
import { IPageProps } from "../../../../Page/Page";
import DesktopPage from "./DesktopPage";

export interface ILicense {
    name: string;
    type: string;
    level: string;
}

export interface IFeatures {
    name: string;
    key: string;
}
export interface ILicensePaneProps extends IPageProps {
    item?: ILicense;
    features?: IFeatures;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    Gatekeeper: any;
}

export default class LicensePane extends PureComponent<ILicensePaneProps> {
    render() {
        const {
            item,
            features,
            onChange,
            onSave,
            onDelete,
            Gatekeeper,
        } = this.props;
        return (
            <>
                <DesktopPage
                    name={item && item.name}
                    type={item && item.type}
                    level={item && item.level}
                    features={features}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    Gatekeeper={Gatekeeper}
                />
                {/* <MobilePage
                    name={item && item.name}
                    language={item && item.language}
                    content={item && item.content}
                    featured={item && item.featured}
                    slug={item && item.slug}
                    category={item && item.category}
                    date={item && item.date}
                    status={item && item.status}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    Gatekeeper={Gatekeeper}
                /> */}
            </>
        );
    }
}
