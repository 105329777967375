import React, { PureComponent } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import ApplicationForm from "../../../../../forms/accounts/ApplicationForm";
import ApplicationSearchForm from "../../../../../forms/accounts/ApplicationSearchForm";

export interface IAssociateApplicationModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onSearch: (data: any) => Promise<any[]>;
    onCreateApplication: (data: any) => Promise<any>;
    onLinkApplication: (data: any) => Promise<any>;
}

export interface IAssociateApplicationModalState {
    state: string;
    items: any[];
    query: any;
}

export default class AssociateApplicationModal extends PureComponent<IAssociateApplicationModalProps, IAssociateApplicationModalState> {
    state: IAssociateApplicationModalState = {
        state: 'search',
        items: [],
        query: {},
    };

    toggleModal() {
        this.props.toggleModal();
        this.setState({
            state: 'search',
            items: [],
            query: {},
        })
    }

    async handleSearch(data) {
        let items;

        try {
            items = await this.props.onSearch(data);
        } catch (e) {
            throw e;
        }

        if (!items.length) {
            return this.setState({
                query: data,
                state: 'notfound',
            })
        }

        return this.setState({
            items,
            state: 'selection'
        })
    }

    handleChooseCreate() {
        return this.setState({
            state: 'create'
        })
    }

    handleTryAgain() {
        return this.setState({
            state: 'search'
        })
    }

    async handleCreate(data: any) {
        let item;

        try {
            item = await this.props.onCreateApplication(data);
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'successful'
        });
    }

    async handleLinkAccount(data) {
        let item;

        try {
            item = await this.props.onLinkApplication(data);
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'successful'
        });
    }

    renderState(state: string) {
        switch (state) {
            case 'search': return this.renderSearchState();
            case 'selection': return this.renderSelectionState();
            case 'notfound': return this.renderNotFoundState();
            case 'create': return this.renderCreateState();
            case 'successful': return this.renderSuccessful();
        }

        return ""
    }

    renderSearchState() {
        return (
            <Modal.Content>
                <Header>Find an application</Header>
                <ApplicationSearchForm onSubmit={async (data: any) => this.handleSearch(data)} />
            </Modal.Content>
        );
    }

    renderSelectionState() {
        return [
            <Modal.Content>
                <Header>Verify and Associate</Header>
                <p>Click on the link action that corresponds with the application you want to associate in the table below.</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderItems()}
                    </Table.Body>
                </Table>
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderItems() {
        const { Gatekeeper } = this.props;
        const itemsTable = this.state?.items.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Button icon="linkify" positive onClick={async () => await this.handleLinkAccount(item)} />
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    renderNotFoundState() {
        return [
            <Modal.Content>
                <Header>We could not find a application associated with {this.state.query?.name}</Header>
                <p>By clicking create, you will be greeted with a form to create the application.</p>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.handleChooseCreate()}>Create</Button>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderCreateState() {
        return [
            <Modal.Content>
                <p>Saving the form will create the application and associate it to your account.</p>
                <ApplicationForm
                    onSubmit={async (data: any) => this.handleCreate(data)}
                    name={this.state.query?.name}
                    controlsTop={false}
                    controlsBottom={true}
                />
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderSuccessful() {
        return [
            <Modal.Content>
                <Header>Successfully associated {this.state.query?.name} to this account</Header>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ]
    }

    render() {
        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={this.props.modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Associate a Application</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}