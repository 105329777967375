import React, { PureComponent } from "react";
import { Button, Checkbox, Dropdown, Label, LabelGroup, List, Modal } from 'semantic-ui-react'
import CommonUtils from "../../../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface IOrderSettingsProps {
    defaultValue?: string;
    onSave?: (data: any) => any;
    item: any;
    settings: any;
    value?: string;
    localizationService: any;
}

export interface IOrderSettingsState {
    value: any;
}

export default class OrderSettings extends PureComponent<IOrderSettingsProps, IOrderSettingsState> {
    state = {
        value: {},
    };

    static defaultProps = {
        item: {},
    }

    componentDidMount() {
        this.setState({
            value: this.props.settings || {},
        });
        console.log(this.props.settings);
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private onCheckboxChange(name, checkboxValue) {
        this.setState({ value: { ...this.state.value, [name]: checkboxValue } });
    }

    private onSave() {
        if (this.props.onSave) {
            this.props.onSave({ settings: { orders: this.state.value } });
        }
    }

    private generateSettingsValueArray(obj) {
        let rendered: any[] = [];
        obj.forEach(element => {
            rendered.push(<Label basic size="large">{element}</Label>)
        });

        return <LabelGroup>{rendered}</LabelGroup>
    }

    private generateSettingsValues(obj) {
        let rendered: any[] = [];
        for (const [key, value] of Object.entries(obj)) {
            let renderedValue;
            switch (typeof (value)) {
                case "boolean":
                    // renderedValue = value ? "Yes" : "No";
                    renderedValue = <Checkbox defaultChecked={value} name={key} onChange={(event, { name, checked }) => { this.onCheckboxChange(name, checked) }} />
                    break;
                case "string":
                case "number":
                    renderedValue = value;
                    break;
                case "object":
                    if (Object.prototype.toString.call(value) == '[object Array]') {
                        renderedValue = <div style={{ margin: '15px 0px', paddingLeft: '15px' }}>{this.generateSettingsValueArray(value)}</div>
                    } else {
                        renderedValue = <div style={{ margin: '15px 0px', paddingLeft: '15px' }}>{this.generateSettingsValues(value)}</div>
                    }
            }
            rendered.push(
                <List.Item>
                    <List.Content>
                        {/* <List.Header>{commonUtils.capitalizeFirstLetter(commonUtils.splitCamelCaseWithAbbreviations(key))}</List.Header> */}
                        <List.Description>
                            <b>{this.translate(commonUtils.capitalizeFirstLetter(commonUtils.splitCamelCaseWithAbbreviations(key)))}: </b>{' '}
                            {renderedValue}
                        </List.Description>
                    </List.Content>
                </List.Item>
            );
        }
        return rendered;
    }

    private generateSettings() {
        if (!this.props.settings) { return; }
        let rendered: any[] = [];

        rendered = this.generateSettingsValues(this.props.settings);

        return <List>{rendered}</List>;
    }

    render() {


        return (
            <>
                <Modal.Header>{this.translate('Order Options')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.generateSettings()}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={this.translate('Save')} onClick={() => { this.onSave() }} />
                </Modal.Actions>
            </>
        );
    }
}