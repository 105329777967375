import React, { PureComponent } from 'react';
import { Container, Header, Button, Icon, SemanticICONS } from 'semantic-ui-react';
import { LoadingSpinner } from '../../controls';

export interface IHeadingProps {
    mobile?: boolean;
    callToAction?: any;
    callToActionIcon?: SemanticICONS;
    callToActionLink?: string;
    header?: any;
    subHeader?: any;
}

export default class Heading extends PureComponent<IHeadingProps> {
    render() {
        const {
            mobile,
            callToAction,
            callToActionIcon = 'arrow right',
            callToActionLink,
            header,
            subHeader,
        } = this.props;

        return (
            <Container text>
                <Header
                    className={`heading ${mobile ? 'mobile' : ''}`}
                    as='h1'
                    content={header}
                />
                <Header
                    className={`heading ${mobile ? 'mobile' : ''}`}
                    as='h2'
                    content={subHeader}
                />
                {callToAction &&
                    <Button as='a' primary size='huge' href={callToActionLink}>
                        {callToAction}
                        <Icon name={callToActionIcon} />
                    </Button>
                }

                {!callToAction && <LoadingSpinner />}
            </Container>
        );
    }
}