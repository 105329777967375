import React, { PureComponent } from 'react';
import Responsive from '../responsive/Responsive';

export default class MobileSkeleton extends PureComponent {
    render() {
        const { children } = this.props;
        return (
            <></>
            // TO-DO Restore this soon.
            // <Responsive device='mobile'>
            //     {children}
            // </Responsive>
        );
    }
}