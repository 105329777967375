import React, { PureComponent } from 'react';
import { messages } from './messages';
import './styles.css';

export interface ILoadingSpinnerProps {
    showLoadingMessage?: boolean;
}

export default class LoadingSpinner extends PureComponent<ILoadingSpinnerProps> {

    static defaultProps = {
        showLoadingMessage: false,
    }

    generateRandomLoadingMessage() {
        let index = ~~(Math.random() * messages.length);
        return messages[index];
    }

    render() {
        return (
            <div className={'loading-spinner-container'}>
                <div className="loading-spinner load-9">
                    <div className="spinner">
                        <div className="bubble-1"></div>
                        <div className="bubble-2"></div>
                    </div>
                    {this.props.showLoadingMessage && <p className={'loading-message'}>{this.generateRandomLoadingMessage()}</p>}
                </div>
            </div>
        );
    }
}