export default class FeatureService {
    protected features: string[] = [];
    protected ready: boolean = false;
    protected inProgress: boolean = false;
    protected promise: any = null;

    public async populateFeatures(items: any[]) {
        this.features = items.map(feature => feature.key);
        this.ready = true;
        this.inProgress = false;
    }

    public async resolvePromise() {
        return await this.promise;
    }

    public isInProgress() {
        return this.inProgress;
    }

    public isReady() {
        return this.ready;
    }

    public setStale() {
        this.ready = false;
    }

    public setPromise(promise: Promise<any>): this {
        this.promise = promise;
        this.inProgress = true;
        return this;
    }

    public hasPermission(name: string): boolean {
        return this.features.includes(name);
    }
}