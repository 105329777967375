import React, { PureComponent } from "react";
import {
    Grid,
    Segment,
    Button,
    Icon,
    Form,
    Checkbox,
} from "semantic-ui-react";
import SettingsTemplate from "../SettingsTemplate";
import { ISecuritySettingsPageProps } from "./SecuritySettingsPage";
import "./styles.css";

export interface ISecuritySettingsDesktopPageProps extends ISecuritySettingsPageProps {
    onChange: (path: string) => any
}

export interface ISecuritySettingsDesktopPageState {
    open: boolean;
    password: string;
    disableSubmit: boolean;
    checked: boolean;
}

export default class DesktopPage extends PureComponent<
    ISecuritySettingsDesktopPageProps,
    ISecuritySettingsDesktopPageState
> {
    state = {
        open: false,
        password: "",
        disableSubmit: true,
        checked: false,
    };

    protected handleItemClick(name) {
        this.props.onChange(name)
    }

    protected setOpen(open, name) {
        this.setState({ open, password: "" });
    }

    protected toggle() {
        this.setState((prevState) => ({
            checked: !prevState.checked,
            disableSubmit: !prevState.disableSubmit,
        }));
    }

    render() {
        return (
            <SettingsTemplate onChange={this.props.onChange} activeItem="security">
                <h2>Security</h2>
                <Segment>
                    <Form>
                        <Grid columns={"one"}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h3>Change password</h3>
                                    <p>
                                        By clicking this button, we will email you a one-time code
                                        which will allow you to change your password.
                                    </p>
                                    <p>
                                        For security reasons, changing your password will log you
                                        out of any active sessions across your all of your devices.
                                    </p>
                                    <Form.Field>
                                        <Checkbox
                                            label="I understand and still want to change my password."
                                            onChange={() => this.toggle()}
                                            checked={this.state.checked}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            disabled={this.state.disableSubmit}
                                            onClick={() => this.setOpen(false, "")}
                                            primary
                                        >
                                            Update password <Icon name="chevron right" />
                                        </Button>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </SettingsTemplate>
        );
    }
}
