import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { LoadingNavPlaceholder } from '../components/controls/';
import { LocalizationService } from '../services';
import GatekeeperFactory from './GatekeeperFactory';
import { IRouteMenuItemFactory } from './RouteMenuItemFactory';

export interface IControl {
    placementId: string,
    control: (props: Record<string, any>) => React.ComponentElement<any, any>[]
}

export class ControlsResolver {
    protected controls: Map<string, (props: Record<string, any>) => React.ComponentElement<any, any>[]> = new Map();

    constructor(protected gatekeeperFactory: GatekeeperFactory, protected localizationService: LocalizationService) {

    }

    addControl({ placementId, control }: IControl): this {
        this.controls.set(placementId, control);
        return this;
    }

    getControl(placementId: string): (props: Record<string, any>) => React.ComponentElement<any, any>[] {
        let control = this.controls.get(placementId);
        const Gatekeeper = this.gatekeeperFactory.create()

        if (!control) {
            return ({ active, path, position, header, content, disabled, featureKey }) => [
                featureKey ? (
                    <Gatekeeper key={featureKey} name={featureKey} loadingComponent={<LoadingNavPlaceholder />}>
                        <Menu.Item key={featureKey} as={disabled ? 'div' : 'a'} active={active} href={path} position={position} header={header} disabled={disabled}>{this.localizationService.translate(content)}</Menu.Item>
                    </Gatekeeper>
                ) : (
                    <Menu.Item key={featureKey} as={disabled ? 'div' : 'a'} active={active} href={path} position={position} header={header} disabled={disabled}>{this.localizationService.translate(content)}</Menu.Item>
                )
            ];
        }

        return control;
    }
}

export default class ControlsEnabledRouteMenuItemFactory implements IRouteMenuItemFactory {
    protected routes;

    constructor(routes = [], protected resolver: ControlsResolver) {
        this.routes = Array.isArray(routes) ? routes : Object.values(routes);
    }

    addControl(placementId: string, control: (props: Record<string, any>) => React.ComponentElement<any, any>[]): this {
        this.resolver.addControl({ placementId, control });
        return this;
    }

    create(activePath) {
        return this.routes
            .filter(({ title, icon }) => title || icon)
            .flatMap(({ title, path = '/', position, icon, header, id, disabled, featureKey, key }, i) => {
                const content = icon ? <Icon name={icon} /> : key;

                let control = this.resolver.getControl(id);

                return control({
                    active: path === activePath,
                    content,
                    title,
                    path,
                    position,
                    icon,
                    header,
                    id,
                    disabled,
                    featureKey
                });
            })
            ;
    }
}