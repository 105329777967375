import React from 'react';
import ApplicationTemplatesEditPage from '../../components/pages/ApplicationTemplatesEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class ApplicationTemplatesCreateController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined
    };

    constructor({ args: [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory], ...props }) {
        super({ args: [router, routeMenuItemFactory, localizationService], ...props });
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;
        try {
            item = await this.repository.createByPath(data, "templates");
            this.router.redirect(`/applicationtemplates/`);
        } catch (e) {
            console.error(e)
            throw e;
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Application Templates'), value: '/applicationtemplates' },
            { key: 2, text: this.localizationService.translate('Create an application template'), value: undefined },
        ]
    }

    render() {
        const { item } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ApplicationTemplatesEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}