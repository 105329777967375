import React, { PureComponent } from "react";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";

export interface ITemplateLayoutFormProps {
    name?: string;
    type?: string;
    value?: any[];
    isMobile?: boolean;
    handleChange: ({ name, value }) => any;
    uniqueKey: number;
    attribute: any;
    toggleModal: (toggleModal: boolean) => void;
    createAttribute: (attribute: any) => void;
}

export interface ITemplateLayoutFormState {
    name: string;
    type: string;
    value: any[];
    currentValues: string[];
    options: any[];
}

export default class TemplateLayoutForm extends PureComponent<ITemplateLayoutFormProps, ITemplateLayoutFormState> {
    static defaultProps = {
        name: "",
        value: [],
        type: "selection",
        isMobile: false,
        handleChange: ({ name, value }) => { },
        uniqueKey: 0,
        toggleModal: (toggleModal) => { },
        createAttribute: (attribute) => { console.log('saved', attribute) },
    };

    state = {
        name: '',
        type: 'selection',
        value: [],
        currentValues: [],
        options: [],
    };

    componentDidMount() {
        if (this.props.attribute) {
            const { name, type, value } = this.props.attribute;
            this.setState({
                value: value || [],
                name: name || '',
                type: type || 'property',

            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // this.setState(
            //     this.props.value?.reduce((state, verb) => {
            //         state[verb] = true;
            //         return state;
            //     }, {}) || {}
            // );
        }
    }

    private handleNameChange(name) {
        this.setState({ name: name.target.value });
    }

    handleChange(e, { value }) {
        this.setState({ currentValues: value })
    }

    async submit() {
        try {
            const { name, currentValues } = this.state;
            const data = { name, options: currentValues, type: 'selection' };
            await this.props.createAttribute(data);
        } catch (e) {

        }
    }

    handleAddition(e, { value }) {
        this.setState(prevState => ({
            options: [{ text: value, value }, ...prevState.options],
        }))
    }

    render() {
        const { name, isMobile } = this.props;

        return (
            <>
                <Modal.Header>Create a template layout</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Form onSubmit={() => this.submit()}>
                            <Form.Input label={'Layout Name'}>
                                <input placeholder="Name" autoFocus defaultValue={this.props.name || this.state.name} onChange={(e) => this.handleNameChange(e)} />
                            </Form.Input>
                            <Form.Input label={'Layout Locations'}>
                                <Dropdown
                                    options={this.state.options}
                                    placeholder='Add one or more locations'
                                    search
                                    selection
                                    fluid
                                    multiple
                                    allowAdditions
                                    value={this.state.currentValues}
                                    onAddItem={(e, data) => this.handleAddition(e, { value: data.value })}
                                    onChange={(e, data) => this.handleChange(e, { value: data.value })}
                                />
                            </Form.Input>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        content="Create Template Layout"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { this.submit(); }}
                        positive
                    />
                </Modal.Actions>
            </>
        )
    }
}