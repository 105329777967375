import React from 'react';
import LicensePane from '../../../components/pages/licenses/LicenseOverviewPage/panes/LicensePane';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import LocalizedController from '../../LocalizedController';

export default class LicenseEditController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        item: undefined,
        features: undefined,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
        } catch (e) {
            return;
        }

        return item;
    }

    private async getFeatures() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.featureRepository.get();
        } catch (e) {
            return;
        }

        return items;
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        try {
            await this.repository.update({
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                id: this.params?.id,
                ...data,
            });

            this.setState({
                item: {
                    //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                    id: this.params?.id,
                    ...data,
                }
            })
        } catch (e) {
            throw e;
        }
    }

    private async handleDelete() {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.delete(this.params?.id);
            this.router.redirect('/licenses');
        } catch (e) {
            throw e;
        }
    }

    async componentDidMount() {
        let item = await this.get();
        let features = await this.getFeatures();

        this.setState({
            item,
            features,
        });
    }

    render() {
        const { item, features } = this.state;
        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <LicensePane
                
                item={item}
                features={features}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                onDelete={async () => this.handleDelete()}
                Gatekeeper={Gatekeeper}
            />
        )
    }
}