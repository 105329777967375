import React, { PureComponent } from 'react';
import { ButtonGroup } from 'semantic-ui-react';
import { ReturnButton } from '../../controls';
import { LoadingSpinner } from '../../controls';
import { ContactFormSectionForm, HeroSectionForm, TextSectionForm } from '../../forms/sections';
import SectionTypeSelectorForm from '../../forms/sections/SectionTypeSelectorForm';
import UnauthorizedMessage from '../../layouts/messages/UnauthorizedMessage';
import { MobileSkeleton } from '../../layouts/skeleton';
import "./styles.css";

export interface ISectionMobilePageProps {
    title: string;
    name: string;
    language: string;
    type: string;
    content: string;
    additional?: any;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class MobilePage extends PureComponent<ISectionMobilePageProps> {
    state: any = {}

    static defaultProps = {
        title: "",
        name: "",
        language: "",
        type: "",
        content: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type !== this.props.type) {
            this.setState({
                type: this.props.type
            })
        }
    }

    resolveAdditional(type: string) {
        return {
            "text": undefined,
            "hero": {
                callToAction: ""
            },
            "contact-form": {
                "name": {
                    "label": "",
                    "placeholder": "",
                },
                "phone": {
                    "label": "",
                    "placeholder": "",
                },
                "email": {
                    "label": "",
                    "placeholder": "",
                },
                "message": {
                    "label": "",
                    "placeholder": "",
                },
                "submit": {
                    "label": "",
                },
            }
        }[type];
    }

    handleSectionTypeSelection({ type }) {
        this.setState({
            type,
            additional: this.resolveAdditional(type)
        });
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="website-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="editor-container">
                        <ButtonGroup floated="left">
                            <ReturnButton
                                href={'/sections'}
                            />
                        </ButtonGroup>
                        {!this.state.type && (
                            <SectionTypeSelectorForm
                                onSubmit={data => this.handleSectionTypeSelection(data)}
                            />
                        )}

                        {"text" === this.state.type && (
                            <TextSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                content={this.props.content}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"hero" === this.state.type && (
                            <HeroSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {"contact-form" === this.state.type && (
                            <ContactFormSectionForm
                                title={this.props.title}
                                name={this.props.name}
                                language={this.props.language}
                                additional={this.props.additional || this.state.additional}
                                onSubmit={data => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                            />
                        )}

                        {/* <h4>Preview</h4>
                    <ReactMarkdown children={markdown} /> */}
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}