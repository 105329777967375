import React from "react";
import { Form, Message } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface IUserFormProps extends IStateAwareFormProps {
    email?: string;
}

export default class UserForm extends StateAwareForm<IUserFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => {};
    };

    componentDidMount() {
        super.setState({
            email: this.props.email,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                email: this.props.email,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the User"
                />

                <Message
                    error
                    header="Could not save user"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"Email"}
                    placeholder="example@email.com"
                    defaultValue={this.props.email}
                    name="email"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    type="email"
                    required
                    action={{
                        color: "green",
                        content: "Search"
                    }}
                />
            </Form>
        );
    }
}
