import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import LocalizedPage from '../../LocalizedPage';
import { IPageProps } from '../../Page/Page';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';

export interface IProduct {
    title: string;
    language: string;
    content: any;
    category: string;
    price: string;
}

export interface IProductPricingShippingEditPageProps extends IPageProps {
    item?: IProduct;
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    handleLinkTax: (data: any) => any;
    handleUnlinkTax: (data: any) => any;
    handleCreateTax: (data: any) => any;
    onDelete?: () => void;
    createProperty?: (type: string) => void;
    deleteProductAttribute?: (id: any) => void;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
    productDefinitionId: string;
    allTaxes: any[];
    definitionTaxes: any[];
}

export default class ProductPricingShippingEditPage extends PureComponent<IProductPricingShippingEditPageProps> {
    render() {
        const { createRoutes, item, allTaxes, definitionTaxes, onChange, onSave, onDelete, Gatekeeper, localizationService, breadcrumbLevels, createProperty, deleteProductAttribute, productDefinitionId, handleLinkTax, handleUnlinkTax, handleCreateTax } =
            this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels}>
                    <DesktopPage
                        item={item}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        productDefinitionId={productDefinitionId}
                        allTaxes={allTaxes}
                        definitionTaxes={definitionTaxes}
                        handleLinkTax={handleLinkTax}
                        handleUnlinkTax={handleUnlinkTax}
                        handleCreateTax={handleCreateTax}
                    />
                    <MobilePage
                        title={item && item.title}
                        language={item && item.language}
                        content={item && item.content}
                        category={item && item.category}
                        price={item && item.price}
                        onChange={onChange}
                        onSave={onSave}
                        onDelete={onDelete}
                        Gatekeeper={Gatekeeper}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                    />
                </Component>
            </Page>
        );
    }
}
