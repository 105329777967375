import React, { PureComponent } from 'react';
import DesktopPage from './DesktopPage';
import MobilePage from './MobilePage';
import LocalizedPage from '../../LocalizedPage';
import Component from '../../../layouts/component';

export interface IProductPageProps {
    createRoutes?: Function;
    items: any;
    Gatekeeper: any;
    onDoubleClick?: Function;
    localizationService?: any;
    breadcrumbLevels?: any;
    products: any;
    attributes: any;
    catalogs: any;
    taxes: any;
    brand: any;
    categories: any;
    productsHaveSlugs: boolean;
    isFetching: boolean;
    onPathChange: (path: string) => any;
    quickSave: (data: any) => any;
    handleChange: (data: any) => any;
    handleSave: ({ key, value }: any) => any;
    handlePublishAll: () => any;
    getDefinitionProducts: (data: any) => any;
    handleStockUpdate: (data: any) => any;
    updateSingleProductPrice: (data: any) => any;
    handleGenerateSlugs: () => any;
}

export default class ProductPage extends PureComponent<IProductPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
            onDoubleClick,
            localizationService,
            breadcrumbLevels,
            products,
            attributes,
            catalogs,
            taxes,
            brand,
            isFetching,
            categories,
            productsHaveSlugs,
            onPathChange,
            quickSave,
            handleChange,
            handleSave,
            handlePublishAll,
            getDefinitionProducts,
            handleStockUpdate,
            updateSingleProductPrice,
            handleGenerateSlugs,
        } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component breadcrumbLinks={breadcrumbLevels} localizationService={localizationService}>
                    <DesktopPage
                        items={items}
                        products={products}
                        taxes={taxes}
                        catalogs={catalogs}
                        attributes={attributes}
                        Gatekeeper={Gatekeeper}
                        onDoubleClick={onDoubleClick}
                        localizationService={localizationService}
                        breadcrumbLevels={breadcrumbLevels}
                        brand={brand}
                        categories={categories}
                        isFetching={isFetching}
                        productsHaveSlugs={productsHaveSlugs}
                        onPathChange={onPathChange}
                        quickSave={quickSave}
                        handleChange={handleChange}
                        handleSave={handleSave}
                        handlePublishAll={handlePublishAll}
                        getDefinitionProducts={getDefinitionProducts}
                        handleStockUpdate={handleStockUpdate}
                        updateSingleProductPrice={updateSingleProductPrice}
                        handleGenerateSlugs={handleGenerateSlugs}
                    />
                    <MobilePage items={items} Gatekeeper={Gatekeeper} localizationService={localizationService} breadcrumbLevels={breadcrumbLevels} />
                </Component>
            </Page>
        );
    }
}
