import { IRouteMenuItemFactory } from "./RouteMenuItemFactory";

export default class DeviceAwareRouteItemFactoryResolver {
    protected deviceRouteFactories: Map<string, IRouteMenuItemFactory> = new Map();

    public getDeviceRouteFactory(device: string): IRouteMenuItemFactory {
        const factory = this.deviceRouteFactories.get(device);
        if (!factory) throw new Error("Invalid device type.");
        return factory;
    }

    public addDeviceRouteFactory(device: string, factory: IRouteMenuItemFactory): this {
        this.deviceRouteFactories.set(device, factory);
        return this;
    }
}