import React from "react";
import { LoadingSpinner } from "../../components/controls";
import { UnauthorizedMessage } from "../../components/layouts/messages";
import LicenseOverviewPage from "../../components/pages/licenses/LicenseOverviewPage/LicenseOverviewPage";
import GatekeeperFactory from "../../factories/GatekeeperFactory";
import Repository from "../../repositories/Repository";
import LocalizedController from "../LocalizedController";

export default class LicenseOverviewController extends LocalizedController {
	private repository: Repository;
	private gatekeeperFactory: GatekeeperFactory;
	private licenseEditControllerAdapter;
	private licenseFeaturesControllerAdapter;

	state = {
		item: undefined,
		features: undefined,
	};

	constructor(props) {
		super(props);

		const [
			router,
			routeMenuItemFactory,
			localizationService,
			repository,
			gatekeeperFactory,
			licenseEditControllerAdapter,
			licenseFeaturesControllerAdapter,
		] = props.args;

		this.repository = repository;
		this.gatekeeperFactory = gatekeeperFactory;
		this.licenseEditControllerAdapter = licenseEditControllerAdapter;
		this.licenseFeaturesControllerAdapter = licenseFeaturesControllerAdapter;
	}

	private async get() {
		let item;

		try {
			//@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
			item = await this.repository.getOne(this.params?.id);
		} catch (e) {
			return;
		}

		return item;
	}

	private handleChange(key: string, value: any) {
		let item = this.state.item || {};

		item[key] = value;

		this.setState({
			item,
		});
	}

	generatePanes(Gatekeeper) {
		const LicenseEditController = this.licenseEditControllerAdapter.getController();
		const LicenseFeaturesController = this.licenseFeaturesControllerAdapter.getController();
		return [
			{
				menuItem: "General Information",
				render: () => (
					<>
						<Gatekeeper
							name="license-management"
							unauthorizedComponent={<UnauthorizedMessage />}
							loadingComponent={<LoadingSpinner />}
						>
							{/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
							{/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
							<LicenseEditController params={this.params} location={this.location} history={this.history} />
						</Gatekeeper>
					</>
				),
			},
			{
				menuItem: "Features",
				render: () => (
					<>
						<Gatekeeper
							name="license-management"
							unauthorizedComponent={<UnauthorizedMessage />}
							loadingComponent={<LoadingSpinner />}
						>
							{/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
							{/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
							<LicenseFeaturesController params={this.params} location={this.location} history={this.history} />
						</Gatekeeper>
					</>
				),
			},
		];
	}

	private async handleSave(data: any) {
		try {
			await this.repository.update({
				//@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
				id: this.params?.id,
				...data,
			});

			this.setState({
				item: {
					//@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
					id: this.params?.id,
					...data,
				},
			});
		} catch (e) {
			throw e;
		}
	}

	private async handleDelete() {
		try {
			//@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
			await this.repository.delete(this.params?.id);
			this.router.redirect("/licenses");
		} catch (e) {
			throw e;
		}
	}

	async componentDidMount() {
		let item = await this.get();

		this.setState({
			item,
		});
	}

	render() {
		const { item } = this.state;;
		const Gatekeeper = this.gatekeeperFactory.create();
		const panes = this.generatePanes(Gatekeeper);

		return this.prepare(
			<LicenseOverviewPage
				onChange={(key: string, value: any) => this.handleChange(key, value)}
				onSave={async (data: any) => this.handleSave(data)}
				onDelete={async () => this.handleDelete()}
				Gatekeeper={Gatekeeper}
				panes={panes}
				item={item}
			/>
		);
	}
}
