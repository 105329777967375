import React, { PureComponent } from 'react';
import { Button, Message } from 'semantic-ui-react';
import './styles.css';

export interface INoItemsMessageProps {
    itemType: string,
    actionHref: string,
}

export default class NoItemsMessage extends PureComponent<INoItemsMessageProps> {


    render() {
        return (
            <div className='no-items-message-container'>
                <Message>
                    <Message.Header>Nothing to see here!</Message.Header>
                    <Message.Content>
                        <p>{`Add your first ${this.props.itemType}!`}</p>
                        <Button
                            as={'a'}
                            href={this.props.actionHref}
                            content={`Write a new ${this.props.itemType}`}
                            positive
                        />
                    </Message.Content>
                </Message>
            </div>
        )
    }
}