import React from 'react';
import { BlogPage } from '../../components/pages';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class BlogController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: [],
        submitted: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();
        let submitted = items.filter(value => value.status == 'submitted')
        // let others = items.filter(value => value.status != 'submitted')

        this.setState({
            items,
            submitted,
            // others,
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/posts/${id}/edit`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Blog'), value: '/posts' },
        ]
    }

    render() {
        const { items,submitted } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <BlogPage
                items={items}
                submitted={submitted}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}