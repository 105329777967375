import React from 'react';
import LoadingSpinner from '../../../components/controls/loaders/LoadingSpinner';
import UnauthorizedMessage from '../../../components/layouts/messages/UnauthorizedMessage';
import ProductOverviewPage from '../../../components/pages/products/ProductOverviewPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import Repository from '../../../repositories/Repository';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductOverviewController extends LocalizedController {
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private repository: Repository;
    private productDefinitionAdapter;
    private productAdapter;
    private productCatalogAdapter;
    private productAttributeAdapter;

    state = {
        items: [],
        products: [],
    };

    constructor(props) {
        super(props);
        // add productRepo + attributeRepo
        const [router, routeMenuItemFactory, localizationService, repository, productDefinitionAdapter, productAdapter, productCatalogAdapter, productAttributeAdapter, gatekeeperFactory] = props.args;
        this.productDefinitionAdapter = productDefinitionAdapter;
        this.repository = repository;
        this.productAdapter = productAdapter;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.productCatalogAdapter = productCatalogAdapter;
        this.productAttributeAdapter = productAttributeAdapter;
    }

    private async get() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getOne(this.params?.id);
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let products;
        let items = await this.get();
        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        // let products = await this.repository.get(this.params?.id);

        this.setState({
            items,
            products,
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/products/${id}/edit`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 1, text: this.state.items && this.state.items['title'] || '', value: undefined },
        ]
    }

    preparePanes(Gatekeeper) {
        const ProductDefinitionController = this.productDefinitionAdapter.getController();
        const ProductController = this.productAdapter.getController();
        const ProductAttributesController = this.productAttributeAdapter.getController();
        const ProductCatalogsController = this.productCatalogAdapter.getController();

        return [
            {
                menuItem: "ProductDefinition",
                render: () => (
                    <>
                        <Gatekeeper
                            name="productdefinition-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ProductDefinitionController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "Product",
                render: () => (
                    <>
                        <Gatekeeper
                            name="productdefinition-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ProductController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "ProductCatalog",
                render: () => (
                    <>
                        <Gatekeeper
                            name="productdefinition-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ProductCatalogsController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
            {
                menuItem: "ProductAttribute",
                render: () => (
                    <>
                        <Gatekeeper
                            name="productdefinition-management"
                            unauthorizedComponent={<UnauthorizedMessage />}
                            loadingComponent={<LoadingSpinner />}
                        >
                            {/* This controller is being invoked by another controller and not the router so we need to pass info from this controller down */}
                            {/* @ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13 */}
                            <ProductAttributesController params={this.params} location={this.location} history={this.history} />
                        </Gatekeeper>
                    </>
                ),
            },
        ]
    }

    render() {
        const { items, products } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();
        const panes = this.preparePanes(Gatekeeper);

        return this.prepare(
            <ProductOverviewPage
                items={items}
                //submitted={submitted}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                panes={panes}
            />
        )
    }
}