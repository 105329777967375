import React, { PureComponent } from 'react';
import { DatesRangeInput, DatesRangeInputProps } from 'semantic-ui-calendar-react';

export interface IDateRangePickerProps extends DatesRangeInputProps {

}

export default class DateRangePicker extends PureComponent<IDateRangePickerProps> {
    render() {
        return (
            <DatesRangeInput
                dateFormat='YYYY-MM-DD'
                {...this.props}
            />
        );
    }
}