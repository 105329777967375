import React, { PureComponent } from 'react';
import { LoadingSpinner } from '../../controls';
import ApplicationTemplates from '../../forms/applicationtemplates/ApplicationTemplatesForm';
import UnauthorizedMessage from '../../layouts/messages/UnauthorizedMessage';
import { DesktopSkeleton } from '../../layouts/skeleton';
import "./styles.css";

export interface ISectionDesktopPageProps {
    name: string;
    templateRepository: string;
    templateRepositoryOwner: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
}

export default class DesktopPage extends PureComponent<ISectionDesktopPageProps> {
    state: any = {}

    static defaultProps = {
        templateRepositoryOwner: "",
        name: "",
        templateRepository: "",
    }

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="applicationtemplate-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="editor-container">
                        <ApplicationTemplates
                            name={this.props.name}
                            templateRepository={this.props.templateRepository}
                            templateRepositoryOwner={this.props.templateRepositoryOwner}
                            onSubmit={data => this.props.onSave(data)}
                            onDelete={this.props.onDelete}
                        />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}