import React from 'react';
import StoreConfigurationPage from '../../components/pages/storeconfiguration/StoreConfigurationPage';
import { Repository } from '../../repositories';
import { LocalizationService, SecurityService } from '../../services';
import LocalizedController from '../LocalizedController';
import CommonUtils from '../../utilities/Common/CommonUtils';
import Cookies from 'universal-cookie';

const commonUtils = new CommonUtils();

const cookies = new Cookies();

export default class StorefrontConfigurationController extends LocalizedController {
    private repository: Repository;
    private localizationService: LocalizationService;
    private applicationRepository: Repository;
    private securityService: SecurityService;

    state = {
        component: undefined, //todo: pass through
        settings: undefined,
        provisions: [],
        domain: undefined,
        storefront: {},
        exceptions: [],
        availabilities: [],
        storefrontIntegrations: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, securityService, repository, applicationRepository] = props.args;

        this.repository = repository;
        this.localizationService = localizationService;
        this.securityService = securityService;
        this.applicationRepository = applicationRepository;
    }

    private async getStorefront() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getByPath(`storefronts/${this.params?.id}`)
        } catch (e) {
            console.error(e);
            return [];
        }
        return item;
    }

    private async getStoreAvailabilities() {
        let item;
        let availabilities: any[] = [];
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getByPath(`storefronts/${this.params?.id}`)

            for (const [key, value] of Object.entries(item.availabilities)) {
                (value as Array<any>).forEach(element => {
                    if (!element.hasOwnProperty('isClosed')) {
                        availabilities.push(
                            {
                                daysOfWeek: [days.indexOf(commonUtils.capitalizeFirstLetter(key.toLowerCase()))],
                                startTime: element.open,
                                endTime: element.close
                            }
                        )
                    }
                });
            }
        } catch (e) {
            console.error(e);
            return [];
        }
        return availabilities;
    }

    private async getStorefrontIntegration() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getByPath(`storefronts/${this.params?.id}/integrations/payments/stripe`);
        } catch (e) {
            console.error(e);
            return [];
        }
        if (!item || !item.length) {
            try {
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                await this.repository.createByPath({}, `storefronts/${this.params?.id}/integrations/payments/stripe`);
                //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
                item = await this.repository.getByPath(`storefronts/${this.params?.id}/integrations/payments/stripe`);
            } catch (e) {
                console.error(e);
                return [];
            }
        }


        return item;
    }

    private async get() {
        let storefront = await this.getStorefront();
        let exceptions = await this.getHolidays();
        let availabilities = await this.getStoreAvailabilities();
        let storefrontIntegrations = await this.getStorefrontIntegration();

        return { storefront, exceptions, availabilities, storefrontIntegrations };
    }

    private async provision() {
        const applicationId = this.securityService.getApplicationId();

        let item: any = this.state.storefrontIntegrations.filter((integration: any) => {
            if (['created', 'initiated', 'pending', 'failed'].includes(integration.status) && "stripe" === integration.provider) {
                return integration;
            }
        });
        if (item && item.length) {
            let { storefrontId, id } = item[0];

            // move this to .env and make it configurable.
            fetch(`https://${process.env.REACT_APP_API_PRODUCT_DEFINITION_HOST}${process.env.REACT_APP_API_PRODUCT_DEFINITION_PORT ? `:${process.env.REACT_APP_API_PRODUCT_DEFINITION_PORT}` : ''}/${process.env.REACT_APP_API_PRODUCT_DEFINITION_BASE_PATH ? `${process.env.REACT_APP_API_PRODUCT_DEFINITION_BASE_PATH}/` : ''}storefronts/${storefrontId}/integrations/${id}/connect/oauth2`, {
                method: 'GET',
                headers: {
                    "application-id": applicationId,
                    "Authorization": `Bearer ${cookies.get('token')}`,
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            }).then(data => {
                window.open(data, "_blank");
            }).catch(error => {
                console.error('There is an Error:', error);
            });
        }
    }

    private async unauthorize() {
        let item: any = this.state.storefrontIntegrations.filter((integration: any) => {
            if ("stripe" === integration.provider) {
                return integration;
            }
        });
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.deleteByPath(`storefronts/${this.params?.id}/integrations/payments/stripe/${item[0].id}`)
        } catch (e) {
            console.error('Cannot unauthorize.');
        }
        let storefrontIntegrations = await this.getStorefrontIntegration();
        this.setState({ storefrontIntegrations })
    }

    private async getHolidays() {
        let exceptions: any[] = [];
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            exceptions = await this.repository.getByPath(`storefronts/${this.params?.id}/availabilities/exceptions`);
            exceptions = exceptions.filter((exception: any) => 'holiday' === exception.type)
            exceptions = exceptions.map((exception: any) => {
                exception.title = exception.name || 'Holiday';
                exception.start = new Date(exception.startDate)
                exception.end = new Date(exception.endDate)
                return exceptions;
            })
        } catch (e) {
            console.error('Issue with storefronts:', e);
        }
        return exceptions;
    }

    async componentDidMount() {
        await this.fetchAll();
    }

    async fetchAll() {
        let { storefront, exceptions, availabilities, storefrontIntegrations } = await this.get();

        this.setState({
            storefront,
            exceptions,
            availabilities,
            storefrontIntegrations,
        })
    }

    private async save(data: any) {
        const { storefront } = this.state;
        if (!storefront) { return; }

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.updateByPath(data, `storefronts/${this.params?.id}`)
        } catch (e) {
            console.error(e);
            return;
        }
        this.fetchAll();
    }

    private async saveException(data: any) {

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.createByPath(data, `storefronts/${this.params?.id}/availabilities/exceptions`)
        } catch (e) {
            console.error(e);
            return;
        }
        this.fetchAll();
    }

    private async updateException(data: any) {
        if (!data || !data.id) { return; }

        //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
        data.storefrontId = this.params?.id;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.updateByPath(data, `storefronts/${this.params?.id}/availabilities/exceptions/${data.id}`)
        } catch (e) {
            console.error(e);
            return;
        }
        this.fetchAll();
    }

    private async deleteException(data: any) {

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.deleteByPath(`storefronts/${this.params?.id}/availabilities/exceptions/${data}`)
        } catch (e) {
            console.error(e);
            return;
        }
        this.fetchAll();
    }

    protected changeSettings(path) {
        this.router.redirect(`/website-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.state.storefront ? this.state.storefront['name'] : '', value: '/store-management' },
            { key: 3, text: this.localizationService.translate('Configure Store'), value: undefined },
        ]
    }

    render() {
        return this.prepare(
            <StoreConfigurationPage
                localizationService={this.localizationService}
                options={(this.state.component as any)?.options}
                settings={(this.state.settings as any)?.settings}
                onProvision={!this.state.provisions.length ? () => this.provision() : undefined}
                onSave={(data) => this.save(data)}
                domain={this.state.domain}
                onPathChange={(path) => this.changeSettings(path)}
                breadcrumbLevels={this.generateBreadcrumbs()}
                storefront={this.state.storefront}
                exceptions={this.state.exceptions}
                availabilities={this.state.availabilities}
                saveException={(data) => this.saveException(data)}
                updateException={(data) => this.updateException(data)}
                deleteException={(data) => this.deleteException(data)}
                storefrontIntegrations={this.state.storefrontIntegrations}
                unauthorize={async () => this.unauthorize()}
            />
        )
    }
}