import React, { PureComponent } from "react";
import { Button, ButtonGroup, Checkbox, Dropdown, Form, FormGroup, Grid, Input, Label, LabelGroup, List, Modal, Select, Table } from 'semantic-ui-react'
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"

const commonUtils = new CommonUtils();

export interface IGenericPageSectionSettingsProps {
    onSave?: (data: any) => any;
    onCancel?: (data: any) => any;
    item?: any;
    locationMap?: any;
}

export interface IGenericPageSectionSettingsState {
    layoutType: string;
    location: string;
    options: any;
}

export default class GenericPageSectionSettings extends PureComponent<IGenericPageSectionSettingsProps, IGenericPageSectionSettingsState> {
    state = {
        layoutType: '',
        location: 'main',
        options: {}
    };

    static defaultProps = {
        item: {},
        locationMap: [],
    }

    componentDidMount() {
        this.setState({
            layoutType: this.props.item && this.props.item.options?.layout?.type,
            location: this.props.item && this.props.item.location || 'main',
            options: this.props.item && this.props.item.options?.layout?.options || {},
        });
    }

    private onChange(name, val, key, index) {
        const { options } = this.state;
        this.setState({ options: { ...options, [key]: val } });
    }

    private onChangeLocationDropdown(location) {
        this.setState({ location })
    }

    private onSave() {
        if (this.props.onSave) {
            this.props.onSave({
                location: this.state.location,
                options: {
                    layout: {
                        type: this.state.layoutType,
                        options: this.state.options,
                    }
                }
            });
        }
    }

    private onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel({})
        }
    }

    private generateOptions() {
        const { options } = this.state;
        let headers: any[] = [];
        for (const [index, [key, val]] of Object.entries(Object.entries(options))) {
            headers.push(
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Form.Input label={commonUtils.capitalizeFirstLetter(key)} defaultValue={val} onChange={(event, { name, value }) => this.onChange(name, value, key, index)} />
                    </Grid.Column>
                </Grid.Row>
            )
        }
        return <Grid padded>{headers}</Grid>;
    }

    private generateLocationLayoutOptions() {
        return (
            <Grid padded>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form.Field
                            control={Select}
                            options={this.props.locationMap}
                            label={{ children: 'Section Location', htmlFor: 'form-select-section-location' }}
                            placeholder='Section Location'
                            search
                            defaultValue={this.props.item?.location || 'main'}
                            searchInput={{ id: 'form-select-section-location' }}
                            onChange={(event, { value }) => { this.onChangeLocationDropdown(value) }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field
                            disabled
                            control={Select}
                            options={[]}
                            label={{ children: 'Section Layout', htmlFor: 'form-select-section-layout' }}
                            placeholder='Section Layout'
                            search
                            searchInput={{ id: 'form-select-section-layout' }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            {this.generateLocationLayoutOptions()}
                            {this.generateOptions()}
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Cancel' onClick={() => { this.onCancel() }} />
                    <Button content='Save' positive onClick={() => { this.onSave() }} />
                </Modal.Actions>
            </>
        );
    }
}