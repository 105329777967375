export interface ILink {
    self?: {
        href?: string;
    }
}

export default class DecoratedEntity {
    protected links: ILink = {};
    embedded = {};
    protected id;
    protected entity;

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
        return this;
    }

    getLinks() {
        return this.links;
    }

    getSelfLink() {
        return this.links?.self?.href;
    }

    getLink(link) {
        if (!this.links[link]) {
            return;
        }

        return this.links[link].href;
    }

    setLinks(links) {
        this.links = links;
        return this;
    }

    getEmbedded(link) {
        return this.embedded[link];
    }

    setEmbedded(embedded = {}) {
        this.embedded = embedded;
        return this;
    }

    getEntity() {
        return this.entity;
    }

    setEntity(entity) {
        this.entity = entity;
        return this;
    }
}