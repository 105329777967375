import React, { PureComponent } from 'react';
import { Sidebar, Menu, Container, Icon, Segment } from 'semantic-ui-react';
import { LocalizedNavigationExtra } from '../../sections/NavigationExtra';

export interface IMobileHeadingProps {
    onBottomPassed?: Function | undefined;
    onBottomPassedReverse?: Function | undefined;
    children?: any;
    routes?: any[];
    header?: any;
}

export default class MobileHeading extends PureComponent<IMobileHeadingProps> {
    state = {
        sidebarOpened: false,
    }

    handleToggle() {
        return this.setState({ sidebarOpened: !this.state.sidebarOpened });
    }

    handlePusherClick() {
        const { sidebarOpened } = this.state;

        if (sidebarOpened) {
            this.setState({ sidebarOpened: false });
        }
    }

    render() {
        const { routes, children, header } = this.props;
        const { sidebarOpened } = this.state;
        const NavigationExtra = LocalizedNavigationExtra();

        return (
            <Sidebar.Pushable className="mobile-heading">
                <Sidebar as={Menu} animation='scale down' vertical visible={sidebarOpened}>
                    {routes}
                </Sidebar>

                <Sidebar.Pusher
                    className='heading'
                    dimmed={sidebarOpened}
                    onClick={() => this.handlePusherClick()}
                >
                    <Segment
                        className={header && 'heading mobile'}
                        textAlign='center'
                        vertical
                    >
                        <Menu pointing size='large'>
                            <Menu.Item onClick={() => this.handleToggle()}>
                                <Icon name='sidebar' />
                            </Menu.Item>
                            {/* <NavigationExtra /> */}
                        </Menu>
                        {header}
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}