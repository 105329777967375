import React, { PureComponent } from 'react';
import Media from 'react-media';

// TO-DO: Restore this soon.
// export type ResponsiveDevice = "desktop" | "mobile";
export type ResponsiveDevice = "desktop";

export interface IResponsiveProps {
    device: ResponsiveDevice,
}

export default class Responsive extends PureComponent<IResponsiveProps> {
    protected propResolver = new Map();

    constructor(props) {
        super(props);
        this.propResolver.set('desktop', { query: 'all' })
        this.propResolver.set('mobile', { query: 'all' })
    }

    protected resolveProps(device: ResponsiveDevice) {
        return this.propResolver.get(device);
    }

    render() {
        const { children, device } = this.props;
        const props = this.resolveProps(device) || {};

        return (
            <Media {...props} render={() =>
                (children)}
            />
        );
    }
}