import React, { PureComponent } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

export interface INewTemplateModalProps {
    name?: string;
    isMobile?: boolean;
    onSave: (data: any) => void;
    toggleModal: (toggleModal: boolean) => void;
}

export interface INewTemplateModalState {
    name: string;
}

export default class NewTemplateModal extends PureComponent<INewTemplateModalProps, INewTemplateModalState> {
    static defaultProps = {
        onSave: (data) => { console.log(data) }
    };

    state = {
        name: '',
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
        }
    }

    private handleNameChange(value) {
        this.setState({ 'name': value });
    }

    async submit() {
        try {
            const { name } = this.state;
            let data = { name, state: 'draft' };
            await this.props.onSave(data);
        } catch (e) {

        }
    }

    render() {
        const { name, isMobile } = this.props;
        return (
            <>
                <Modal.Header>Create a new template</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form onSubmit={() => this.submit()}>
                            <Form.Input label={'Template Name'}>
                                <input placeholder="Name" autoFocus defaultValue={this.props.name || this.state.name} onChange={(e) => this.handleNameChange(e.target.value)} />
                            </Form.Input>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black'
                        onClick={() => this.props.toggleModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        content={"Create Template"}
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { this.submit(); }}
                        positive
                    />
                </Modal.Actions>
            </>
        )
    }
}