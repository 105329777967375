import React, { PureComponent } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import UserForm from "../../../../../forms/users/UserForm";
import UserSearchForm from "../../../../../forms/users/UserSearchForm";

export interface IAssociateUserModalProps {
    Gatekeeper: any;
    modalOpen: boolean;
    toggleModal: () => any;
    onSearch: (data: any) => Promise<any[]>;
    onCreateUser: (data: any) => Promise<any>;
    onLinkUser: (data: any) => Promise<any>;
    onLinkApplication: (user: any, data: any) => Promise<any>;
    applications: any[];
}

export interface IAssociateUserModalState {
    state: string;
    items: any[];
    query: any;
    user: any;
    [key: string]: any;
}

export default class AssociateUserModal extends PureComponent<IAssociateUserModalProps, IAssociateUserModalState> {
    state: IAssociateUserModalState = {
        state: 'search',
        items: [],
        query: {},
        user: undefined,
    };

    toggleModal() {
        this.props.toggleModal();
        this.setState({
            state: 'search',
            items: [],
            query: {},
            user: undefined,
        })
    }

    async handleSearch(data) {
        let items;

        try {
            items = await this.props.onSearch(data);
        } catch (e) {
            throw e;
        }

        if (!items.length) {
            return this.setState({
                query: data,
                state: 'notfound',
            })
        }

        return this.setState({
            items,
            state: 'selection'
        })
    }

    handleChooseCreate() {
        return this.setState({
            state: 'create'
        })
    }

    handleTryAgain() {
        return this.setState({
            state: 'search'
        })
    }

    async handleCreate(data: any) {
        let item;

        try {
            item = await this.props.onCreateUser(data);
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'applications',
            user: item,
        });
    }

    async handleLinkAccount(data) {
        let item;

        try {
            item = await this.props.onLinkUser(data);
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'applications',
            user: data,
        });
    }

    handleSkipApplications() {
        this.setState({
            state: 'successful'
        })
    }

    async handleLinkApplication(data) {
        let item;

        try {
            item = await this.props.onLinkApplication(this.state.user, data);
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'applications',
            [`application-${data.id}`]: true,
        });
    }

    renderState(state: string) {
        switch (state) {
            case 'search': return this.renderSearchState();
            case 'selection': return this.renderSelectionState();
            case 'applications': return this.renderApplicationState();
            case 'notfound': return this.renderNotFoundState();
            case 'create': return this.renderCreateState();
            case 'successful': return this.renderSuccessful();
        }

        return ""
    }

    renderSearchState() {
        return (
            <Modal.Content>
                <Header>Find a user</Header>
                <UserSearchForm onSubmit={async (data: any) => this.handleSearch(data)} />
            </Modal.Content>
        );
    }

    renderSelectionState() {
        return [
            <Modal.Content>
                <Header>Verify and Associate</Header>
                <p>Click on the link action that corresponds with the user you want to associate in the table below.</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderItems()}
                    </Table.Body>
                </Table>
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderApplicationState() {
        return [
            <Modal.Content>
                <Header>Grant access to applications</Header>
                <p>Click on the link action for each application you want the user to have access to and/or click continue.</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderApplications()}
                    </Table.Body>
                </Table>
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.handleSkipApplications()} positive>Continue</Button>
            </Modal.Actions>
        ];
    }

    renderItems() {
        const { Gatekeeper } = this.props;
        const itemsTable = this.state?.items.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.firstName}</Table.Cell>
                    <Table.Cell>{item.lastName}</Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Button icon="linkify" positive onClick={async () => await this.handleLinkAccount(item)} />
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    renderApplications() {
        const { Gatekeeper } = this.props;
        const itemsTable = this.props?.applications.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Button 
                            icon={this.state[`application-${item.id}`] ? "check" : "linkify"} 
                            disabled={this.state[`application-${item.id}`]} 
                            positive 
                            onClick={async () => await this.handleLinkApplication(item)} 
                        />
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    renderNotFoundState() {
        return [
            <Modal.Content>
                <Header>We could not find a user associated with {this.state.query?.email}</Header>
                <p>By clicking create, you will be greeted with a form to create the user.</p>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.handleChooseCreate()}>Create</Button>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderCreateState() {
        return [
            <Modal.Content>
                <p>Saving the form will create the user and associate it to your account.</p>
                <UserForm
                    onSubmit={async (data: any) => this.handleCreate(data)}
                    email={this.state.query?.email}
                    firstName=""
                    lastName=""
                    controlsTop={false}
                    controlsBottom={true}
                />
            </Modal.Content>,
            <Modal.Actions>
                <Button onClick={() => this.handleTryAgain()}>Back</Button>
            </Modal.Actions>
        ];
    }

    renderSuccessful() {
        return [
            <Modal.Content>
                <Header>Successfully associated {this.state.query?.email} to this account</Header>
            </Modal.Content>,
            <Modal.Actions>
                <Button positive onClick={() => this.toggleModal()}>Done</Button>
            </Modal.Actions>
        ]
    }

    render() {
        return (
            <Modal
                onClose={() => this.toggleModal()}
                dimmer
                open={this.props.modalOpen}
                size="small"
                closeIcon
            >
                <Modal.Header>Associate a User</Modal.Header>
                {this.renderState(this.state.state)}
            </Modal>
        );
    }
}