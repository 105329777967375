import React, { PureComponent } from "react";
import {
    Segment,
    Header,
    Button,
    Card,
    Grid,
    Container,
    Image,
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import './styles.css';

export interface IProductTemplateProps {
    section: any;
}

export default class ProductTemplate extends PureComponent<
    IProductTemplateProps
> {

    generateProductsGrid(options: any) {
        if (!options || !options.rows || !options.columns) { return; }
        let grid: any[] = [];
        let cols: any[] = [];
        for (let x = 0; x < options.rows; x++) {
            for (let y = 0; y < options.columns; y++) {
                cols.push(
                    <Grid.Column textAlign="center"><Image src='https://dummyimage.com/250/000/fff.png&text=Product' centered /></Grid.Column>
                )
            }
            grid.push(<Grid.Row centered columns={options.columns}>{cols}</Grid.Row>)
            cols = [];
        }
        return (<Grid padded centered>{grid}</Grid>);
    }

    render() {
        const { section } = this.props;
        console.log('a', section);
        const options = section.options.layout.options;
        return (

            <div className="product-jumbotron text-center">
                <div className="product-inner">
                    <h1 style={{ fontWeight: 300 }}>{section.title || section.section.name}</h1>
                    <h4>{section.content}</h4>
                    <div>
                        {this.generateProductsGrid(options)}
                        {options.callToAction && '' !== options.callToAction &&
                            <Button style={{ marginTop: '25px' }} primary content={options.callToAction} />}
                    </div>
                </div>
            </div>

        )
    }
}