import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { LoadingMessage } from "../../../layouts/messages";
import StateAwareForm, {
    IStateAwareFormProps,
} from "../../StateAwareForm/StateAwareForm";

export interface IUserFormProps extends IStateAwareFormProps {
    firstName: string;
    lastName: string;
    email: string;
    controlsBottom?: boolean;
    controlsTop?: boolean;
}

export default class UserForm extends StateAwareForm<IUserFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        onSubmit: (values: any) => values,
        onDelete: () => { },
        controlsTop: true,
        controlsBottom: false,
    };

    componentDidMount() {
        super.setState({
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            email: this.props.email,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                firstName: this.props.firstName,
                lastName: this.props.lastName,
                email: this.props.email,
            });
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Form onSubmit={() => this.handleSubmit()} error={!!error}>
                {this.props.controlsTop && (
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>
                )}

                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the User"
                />

                <Message
                    error
                    header="Could not save user"
                    content={error && error.toString()}
                />

                <Form.Input
                    fluid
                    label={"First Name"}
                    defaultValue={this.props.firstName}
                    name="firstName"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input
                    fluid
                    label={"Last Name"}
                    defaultValue={this.props.lastName}
                    name="lastName"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />
                <Form.Input
                    fluid
                    label={"Email"}
                    placeholder="example@email.com"
                    defaultValue={this.props.email}
                    name="email"
                    type="email"
                    onChange={(e, { name, value }) =>
                        this.handleChange({ name, value })
                    }
                    required
                />

                {this.props.controlsBottom && [
                    <Button.Group floated="right">
                        <Button color="green" disabled={loading}>Save</Button>
                    </Button.Group>,
                    <br />,
                    <br />,
                ]}
            </Form>
        );
    }
}
