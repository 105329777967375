import React, { PureComponent } from "react";
import { IPageProps } from "../../../../Page/Page";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";

export interface ILicense {
    name: string;
    type: string;
    level: string;
}

export interface IFeatures {
    name: string;
    key: string;
}
export interface ILicenseFeaturePaneProps extends IPageProps {
    item?: ILicense;
    features: IFeatures[];
    licenseFeatures: any[];
    selectedLicenses: any[];
    onChange?: (key: string, value: any) => void;
    onSave: (data: any) => any;
    onDelete?: () => void;
    fetchSelectedLicenses?: () => void;
    Gatekeeper: any;
    toastConfig?: any;
}

export default class LicenseFeaturePane extends PureComponent<ILicenseFeaturePaneProps> {
    render() {
        const {
            item,
            features,
            onChange,
            onSave,
            onDelete,
            fetchSelectedLicenses,
            Gatekeeper,
            licenseFeatures,
            selectedLicenses,
            toastConfig,
        } = this.props;

        return (
            <>
                <DesktopPage
                    name={item && item.name}
                    type={item && item.type}
                    level={item && item.level}
                    features={features}
                    licenseFeatures={licenseFeatures}
                    selectedLicenses={selectedLicenses}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    fetchSelectedLicenses={fetchSelectedLicenses}
                    Gatekeeper={Gatekeeper}
                    toastConfig={toastConfig}
                />
                <MobilePage
                    name={item && item.name}
                    type={item && item.type}
                    level={item && item.level}
                    features={features}
                    licenseFeatures={licenseFeatures}
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    Gatekeeper={Gatekeeper}
                    toastConfig={toastConfig}
                />
            </>
        );
    }
}
