import React from 'react';
import { FeaturesPage } from '../../components/pages';
import CartsPage from '../../components/pages/CartsPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class CartsController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            return [];
        }
        return items;
    }

    async componentDidMount() {
        await this.fetchAllCarts();
    }

    private async fetchAllCarts() {
        let items = await this.get();

        this.setState({
            items,
        });
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/carts/${id}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Carts'), value: undefined },
        ]
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <CartsPage Gatekeeper={Gatekeeper} items={items} breadcrumbLevels={this.generateBreadcrumbs()} />
        )
    }
}