export default {
    '1 hour': '1 heure',
    '15 minutes': '15 minutes',
    '2 hours': '2 heures',
    '30 minutes': '30 minutes',
    'About Post Statuses': 'À propos des statuts d\'articles',
    'Account': 'Compte',
    'Accounts': 'Comptes',
    'Actions': 'Actions',
    'Add a new tax rate': 'Ajouter une nouvelle taxe',
    'Add a set of options.': 'Ajoutez un ensemble d\'options.',
    'Add a translation': 'Ajouter une traduction',
    'Add an attribute.': 'Ajouter un attribut.',
    'Add holiday': 'Nouveau congé',
    'Add new tax rate.': 'Ajouter une nouvelle taxe',
    'Add new translation.': 'Ajoutez une nouvelle traduction',
    'Add product to catalog': 'Associer un produit au catalogue',
    'Add products to the catalog': 'Associer des produits au catalogue',
    'Add to catalog': 'Ajouter au catalogue',
    'Add your first blog post!': 'Ajoutez votre premier article!',
    'Add your first product!': 'Ajoutez votre premier produit!',
    'Add': 'Ajouter',
    'Added Products': 'Produits ajoutés',
    'Address': 'Adresse',
    'All Catalogs': 'Tous les catalogues',
    'All Invoices': 'Toutes les factures',
    'All Orders': 'Toutes les commandes',
    'All Products': 'Tous les produits',
    'Allow External Management': 'Autoriser la gestion externe des commandes',
    'Allow In Store Pickup': 'Permettre le ramassage en magasin',
    'Amount': 'Montant',
    'An issue was found with the post after it was published, and therefore the post needed to be taken down. At this stage, edits can be made and a new round of approval sought. The Recalled status is also available during the approval phase of the post so that a decision maker can indicate that the post needs revisions.': 'Un problème a été trouvé avec l\'article et, par conséquent, il a dû être supprimé. À ce stade, des modifications peuvent être apportées et un nouveau cycle d\'approbation recherché. Le statut Rappelé est également disponible pendant la phase d\'approbation de la publication afin qu\'un décideur puisse indiquer si la publication nécessite des révisions.',
    'Applications': 'Applications',
    'Approved': 'Approuvé',
    'Attribute Name': 'Nom de l\'attribut',
    'Attribute Value': 'Valeur de l\'attribut',
    'Authentication Failed': 'Connexion échouée',
    'Back': 'Retour',
    'Blockquote': 'Citation en bloc',
    'Blog Post': 'Article',
    'Blog': 'Blogue',
    'Bold': 'Gras',
    'Brand': 'Marque',
    'Bulk Update Price': 'Mise à jour en masse du prix',
    'Cancel': 'Annuler',
    'Cancelled': 'Annulée',
    'Capacity': 'Capacité',
    'Catalog Products': 'Produits dans le catalogue',
    'Catalogs': 'Catalogues',
    'Category': 'Catégorie',
    'Change language': 'Changer la langue',
    'Change the price of': 'Changer le prix pour',
    'Click on the link action that corresponds with the product you want to associate to the catalog in the table below.': 'Cliquez sur le bouton qui correspond au produit que vous souhaitez associer au catalogue dans le tableau ci-dessous.',
    'Closed': 'Fermé',
    'Completed': 'Completée',
    'Configure Store': 'Configurer les paramètres du magasin',
    'Contact us': 'Contactez-nous',
    'Content': 'Contenu',
    'Create Attribute Values': 'Créer des valeurs d\'attribut',
    'Create Catalog': 'Créer un catalogue',
    'Create Product': 'Créer un nouveau produit',
    'Create Property': 'Créer',
    'Create Tax': 'Créer une taxe',
    'Create a new blog post': 'Créer un nouvel article',
    'Create a new page': 'Créer une nouvelle page',
    'Create a property': 'Ajouter un attribut',
    'Create blog post': 'Créer un article',
    'Create product': 'Créer un nouveau produit',
    'Create section': 'Créer une section',
    'Current language': 'Langue',
    'Current': 'En cours',
    'Customer Info': 'Informations Client',
    'Dashboard': 'Tableau de bord',
    'Date Created': 'Date de création',
    'Date Modified': 'Date de modification',
    'Date Placed': 'Date placée',
    'Date': 'Date',
    'Definition': 'Définition',
    'Delete': 'Supprimer',
    'Delivered': 'Completée',
    'Delivery Options': 'Options de livraison',
    'Discard Changes': 'Annuler les changements',
    'Draft': 'Brouillon',
    'Due Date Days': 'Jours d\'échéance',
    'Due Date': 'Date d\'échéance',
    'E-mail address': 'Adresse courriel',
    'Edit Catalog': 'Modifier le catalogue',
    'Edit a translation': 'Modifier une traduction',
    'Edit': 'Modifier',
    'Event name': 'Nom de l\'évenement',
    'Featured': 'En vedette',
    'Features': 'Fonctionnalités',
    'Friday': 'Vendredi',
    'Fulfillment Statuses': 'Statuts d\'exécution',
    'Future Orders': 'À remplir',
    'General Information': 'Informations générales',
    'Generate Products': 'Génerer les produits',
    'Got it!': 'Compris!',
    'Holidays and Vacations': 'Jours fériés et vacances',
    'I want to pause for': 'Je veux suspendre les commandes pour',
    'Inbox': 'Boîte de réception',
    'Incident': 'Incident',
    'Increase by amount': 'Augmentation fixe',
    'Increase by percentage': "Augmentation par pourcentage",
    'Inventory': 'Inventaire',
    'Invoice ID': 'Numéro de facture',
    'Invoice Status': 'État de la facture',
    'Invoice Details': 'Détails de la facture',
    'Invoice Options': 'Options de facturation',
    'Invoices': 'Factures',
    'Invoice': 'Facture',
    'Italic': 'Italique',
    'Item': 'Article',
    'Keep up to date with your new customers by viewing and responding to their messages from your website.': 'Restez à jour avec vos nouveaux clients en lisant et en répondant à leurs messages.',
    'Language': 'Langue',
    'Last updated on': 'Dernière mise à jour le',
    'Let\'s confirm your product definition.': 'Prenons le temps de confirmer les informations du produit.',
    'Licenses': 'Licenses',
    'Login': 'Connexion',
    'Manage sections on the website': 'Gérer les sections de votre site web.',
    'Manage store': 'Gestion de commerce en ligne.',
    'Manage your store.': 'Gerez votre commerce en ligne.',
    'Manage your website settings, look and feel, and content.': 'Gérez les paramètres, l\'apparence, et le contenu de votre site Web.',
    'Manage your website': 'Gestion du site web.',
    'Modify': 'Modifier',
    'Monday': 'Lundi',
    'Name': 'Nom',
    'New Holiday': 'Nouveau congé',
    'New event': 'Nouvel évenement',
    'No account?': 'Pas de compte?',
    'No blog posts to review!': 'Aucun article à reviser!',
    'No blog posts!': 'Aucun article!',
    'No orders!': 'Aucune commande!',
    'No products in this catalog!': 'Aucun produit dans ce catalogue!',
    'No products!': 'Aucun produit!',
    'No recalled blog posts!': 'Aucun article rappelé!',
    'No': 'Non',
    'Not now': 'Non merci',
    'Note: You will be able to add taxes later.': 'Remarque : Vous pourrez associer les taxes plus tard.',
    'Nothing to see here!': 'Rien à voir ici!',
    'Now showing': 'Nous avons trouvé',
    'One moment': 'Un moment',
    'Opening Hours': 'Heures d\'ouverture',
    'Order Created Date': 'Commande placée le',
    'Invoice Created Date': 'Date de facturation',
    'Order Details': 'Détails de la commande',
    'Order Due Date': 'Date d\'échéance',
    'Order Number': 'No. de commande',
    'Order Options': 'Options de commandes',
    'Order Status': 'État de la commande',
    'Order auto-refresh paused.': 'L\'actualisation automatique de la commande a été interrompue.',
    'Order': 'Commande',
    'Orders suspended until': 'Commandes suspendues jusqu\'au',
    'Orders': 'Commandes',
    'Overview': 'Géneral',
    'Paragraph': 'Paragraphe',
    'Password': 'Mot de passe',
    'Past Orders': 'Historique',
    'Pause Orders': 'Suspendre les commandes',
    'Pause reason': 'Raison de la pause',
    'Pause': 'Pause',
    'Percentage': 'Pourcentage',
    'Permitted Payment Strategies': 'Types de paiement autorisées',
    'Phone number': 'Numéro de téléphone',
    'Post Content': 'Contenu de l\'article',
    'Price': 'Prix',
    'Print': 'Imprimer',
    'Product Attributes and Options': 'Attributs et options du produit',
    'Product Attributes': 'Attributs du produit',
    'Product Catalog Description': 'Description du catalogue de produits',
    'Product Description': 'Description du produit',
    'Product Options': 'Options de produit',
    'Product Tax Information': 'Informations sur les taxes sur les produits',
    'Product successfully updated.': 'Produit mis à jour avec succès.',
    'Product': 'Produit',
    'Product(s) successfully updated. Refreshing page...': 'Produit(s) mis à jour avec succès. Veuillez patienter...',
    'Product(s) successfully updated.': 'Produit(s) mis à jour avec succès.',
    'Products Have Attributes': 'Les produits contiennent des attributs',
    'Products Have Custom Skus': 'Les produits ont des références personnalisées',
    'Products Have Different Brands': 'Les produits viennent de différentes marques',
    'Products Have Options': 'Les produits contiennent des options',
    'Products': 'Produits',
    'Publish': 'Publier',
    'Published': 'Publié',
    'Quantity': 'Quantité',
    'Quickly create and add a new attribute to this product.': 'Créez et ajoutez rapidement un nouvel attribut à ce produit.',
    'Quickly create and add a new set of options to this product.': 'Créez et ajoutez rapidement un nouvel ensemble d\'options à ce produit.',
    'Quickly create and add a new tax rate to this product definition.': 'Créez et ajoutez rapidement une nouvelle taxe à ce produit.',
    'Quickly create and add a new translation to this product.': 'Créez et ajoutez rapidement une nouvelle traduction à ce produit.',
    'Rate Type': 'Type de taux',
    'Rate': 'Taux',
    'Recall': 'Rappeler',
    'Recalled': 'Rappelé',
    'Received': 'Reçue',
    'Reduce by amount': 'Réduction fixe',
    'Reduce by percentage': 'Réduction par pourcentage',
    'Refreshing in': 'Chargement de nouvelles données dans',
    'Refreshing...': 'Chargement...',
    'Remainder of the day': 'Jusqu\'à la fin de la journée',
    'Remove from catalog': 'Supprimer du catalogue',
    'Resume Orders': 'Reprendre les commandes',
    'Review product': 'Confirmer les informations produit',
    'SKU': "Réf.",
    'Saturday': 'Samedi',
    'Save and Continue': 'Sauvegarder et continuer',
    'Save and continue': 'Sauvegarder et continuer',
    'Save': 'Sauvegarder',
    'Search product by name': 'Recherchez un produit',
    'Sections': 'Sections',
    'See messages from customers': 'Lire les messages de vos clients',
    'Select Language': 'Choisir votre langue',
    'Select Rate Type': 'Sélectionnez le type de tarif',
    'Select date and time of event': 'Selectionner la date et l\'heure du congé',
    'Select translation language': 'Choisir la langue de la traduction',
    'Separate the email addresses with a comma (,).': 'Séparez les adresses e-mail par une virgule (,).',
    'Set a new price': "Définir un nouveau prix",
    'Status': 'Statut',
    'Stock successfully updated.': 'Inventaire mis à jour avec succès.',
    'Store Management': 'Gestion du magasin',
    'Store Name': 'Nom de la vitrine',
    'Store Settings': 'Paramètres du magasin',
    'Store': 'Commerce',
    'Storefront Management': 'Gestion du magasin',
    'Storefront Settings': 'Paramètres du magasin',
    'Storefronts': 'Vitrines',
    'Submit': 'Soumettre',
    'Submitted': 'Soumit',
    'Subscriptions': 'Souscriptions',
    'Successfully updated settings. Reloading in a few moments...': 'Configuration mise à jour avec succès. Veuillez patienter...',
    'Sunday': 'Dimanche',
    'Tax Name': 'Nom',
    'The post has been reviewed and approved by decision makers to ensure the contents are up to par.': 'Le message a été examiné et approuvé par les décideurs pour s\'assurer que le contenu est à la hauteur.',
    'The post is being worked on and saved but it isn\'t ready for review, or for public eyes.': 'L\'article m\'est qu\'un brouillon et n\'est pas prêt à être publié.',
    'The post is completed and is ready for review.': 'L\'article est complété et prêt à être revu',
    'The post is now visible to the public on the website.': 'La publication est maintenant accéssible au public sur le blogue.',
    'Thursday': 'Jeudi',
    'Timezone Options': 'Paramètres du fuseau horaire',
    'Timezone': 'Fuseau horaire',
    'Title': 'Titre',
    'Tuesday': 'Mardi',
    'Tweak aspects of your website layout and ensure its contents are relevant.': 'Modifiez la mise en page de votre site et assurez-vous que son contenu soit pertinent.',
    'Underline': 'Souligné',
    'Universal Brand': 'Marque universelle',
    'Unpublish': 'Dépublier',
    'Update Property': 'Mettre à jour',
    'Update price': 'Mettre à jour le prix',
    'Update': 'Mettre à jour',
    'Users': 'Utilisateurs',
    'Value': 'Valeur',
    'View all blog posts': 'Voir tous vos articles',
    'View and manage posts that are present on your blog.': 'Voir et gérer les articles qui sont présent sur votre blogue.',
    'We are currently trying to log you in': 'Nous essayons de vous authentifier',
    'Website': 'Site web',
    'Wednesday': 'Mercredi',
    'What change would you like to make?': 'Quel changement voulez-vous apporter?',
    'What would you like to accomplish today?': 'Que voulez-vous accomplir aujourd\'hui?',
    'Yes': 'Oui',
    'You have not entered the proper credentials': 'Vous n\'avez pas entré les informations d\'identification appropriées',
    'You\'re changing the base price of the product. Prices of variants will be changed accordingly.': 'Vous modifiez le prix de base du produit. Le prix des variantes seront modifiés en conséquence.',
    'draft': 'brouillon',
    'for': 'pour',
    'in this collection': 'dans cette collection',
    'percent': 'pourcent',
    'percentage': 'pourcentage',
    'product': 'produit',
    'products': 'produits',
    'published': 'publié',
    'result': 'resultat',
    'results': 'resultats',
    'second': 'seconde',
    'seconds': 'secondes',
    'sentence': 'phrase',
    'unpublished': 'dépublié',
    'word': 'mot',
    'Minimum Total For Free Delivery': "Montant minimum pour bénéficier de la livraison gratuite",
    'Delivery Rate For Not Meeting Minimum': "Frais de livraison si le minimum n'est pas atteint",
    'In Stock?': 'En inventaire?',
    'Delivery fees': 'Frais de livraison',
    'Subtotal': 'Sous-total',
    "Tax Registration Number": "Numéro de taxe",
    "Variant": "Variante",
}