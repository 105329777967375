import React, { PureComponent } from 'react';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import StoreSettingsTemplate from '../../storeconfiguration/StoreSettingsTemplate';
import CreateButton from '../../../controls/buttons/CreateButton';
import { ScrollableTable } from '../../../layouts/tables';
import './styles.css';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
    onPathChange: (path: string) => any;
    localizationService?: any;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    state = {
    }

    translate(key: string): string {
        if (!this.props.localizationService) { return key; }
        return this.props.localizationService.translate(key);
    }

    renderitems() {
        const { items, Gatekeeper } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.name}<br/>{item.registrationNumber}</Table.Cell>
                    <Table.Cell>{item.rate}</Table.Cell>
                    <Table.Cell>{this.translate(item.rateType)}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Gatekeeper name="tax-management">
                            <Button icon='edit' color='teal' as="a" href={`/store-management/taxes/${item.id}/edit`} />
                        </Gatekeeper>
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <StoreSettingsTemplate localizationService={this.props.localizationService} activeItem='taxes' onChange={this.props.onPathChange}>
                <Gatekeeper name="tax-view">
                    <div>
                        <ScrollableTable className='ten'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{this.translate('Name')}</Table.HeaderCell>
                                    <Table.HeaderCell>{this.translate('Rate')}</Table.HeaderCell>
                                    <Table.HeaderCell>{this.translate('Rate Type')}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderitems()}
                            </Table.Body>
                            <Gatekeeper name="tax-management">
                                <Table.Footer fullWidth>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell colSpan='4'>
                                        {/* <Button color="purple" size="small" floated="right" icon onClick={async () => await this.publishAll()} disabled={this.state.isPublishing}><Icon loading={this.state.isPublishing} name={this.state.isPublishing ? 'spinner' : 'save'} />{this.state.isPublishing ? '' : 'Publish All Products'}</Button> */}
                                        <Button color="green" size="small" floated="right" icon href='/store-management/taxes/create' as='a'><Icon name='plus' />{this.translate('Create Tax')}</Button>
                                    </Table.HeaderCell>
                                </Table.Footer>
                            </Gatekeeper>
                        </ScrollableTable>
                    </div>
                </Gatekeeper>
            </StoreSettingsTemplate>
        )
    }
}