import React, { PureComponent } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { animateScroll as scroll } from 'react-scroll'
import './styles.css';

export interface IBackToTopButtonProps extends ButtonProps {
}

export default class BackToTopButton extends PureComponent<IBackToTopButtonProps> {
    state = {
        showButton: false,
    }

    scrollToTop(e) {
        e.preventDefault();
        scroll.scrollToTop();
    }

    componentWillMount() {
        window.addEventListener('scroll', async () => this.scrollListener());
    }

    async scrollListener() {
        if (window.pageYOffset >= 125) {
            await this.setState({ showButton: true });
        } else {
            await this.setState({ showButton: false });
        }
    }


    render() {
        return (
            <>
                {this.state.showButton &&
                    <Button
                        className={'back-to-top'}
                        icon={'chevron up'}
                        color={this.props.color || 'black'}
                        onClick={(e) => this.scrollToTop(e)}
                    />
                }
            </>
        );
    }
}
