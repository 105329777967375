import React, { PureComponent } from 'react';
import { Button, Divider, Message } from 'semantic-ui-react';
import { ProductDefinitionForm } from '../../../forms/products';
import ProductForm from '../../../forms/products/ProductForm';
import { MobileSkeleton } from '../../../layouts/skeleton';
import './styles.css';

export interface IOverviewMobilePageProps {
    title: string;
    language: string;
    content: any;
    sku: string;
    price: string;
    onChange?: (key: string, value: any) => void;
    onDelete?: () => void;
    onSave: (data: any) => any;
    Gatekeeper: any;
    localizationService: any;
    breadcrumbLevels: any;
}

export default class MobilePage extends PureComponent<IOverviewMobilePageProps> {
    static defaultProps = {
        title: '',
        language: '',
        content: '',
        sku: '',
        price: '',
    };

    state = {
        alertDisplayed: true,
    };

    onChange(key, value) {
        if (this.props.onChange) {
            this.props.onChange(key, value);
        }
    }

    dismissAlert() {
        this.setState({ alertDisplayed: false });
    }

    renderAlert() {
        return (
            <span>
                {this.state.alertDisplayed && (
                    <Message
                        warning
                        size={'small'}
                        style={{ textAlign: 'center' }}
                    >
                        <Message.Header>
                            Manage Your products on Desktop
                        </Message.Header>
                        <Divider />
                        <Message.Content>
                            For an optimal experience, we recommend you update
                            your product's content on a desktop computer.
                        </Message.Content>
                        <br />
                        <Button
                            fluid
                            color={'instagram'}
                            onClick={() => this.dismissAlert()}
                        >
                            Dismiss
                        </Button>
                    </Message>
                )}
            </span>
        );
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <MobileSkeleton>
                <Gatekeeper name="product-management">
                    <div style={{ marginBottom: '50px' }}>
                        {/* {this.renderAlert()} */}
                        <div className="product-editor-container">
                            <ProductForm
                                productId=''
                                title={this.props.title}
                                language={this.props.language}
                                content={this.props.content}
                                sku={this.props.sku}
                                price={this.props.price}
                                onSubmit={(data) => this.props.onSave(data)}
                                onDelete={this.props.onDelete}
                                localizationService={this.props.localizationService}
                            />
                        </div>
                    </div>
                </Gatekeeper>
            </MobileSkeleton>
        );
    }
}
