import React from 'react';
import ProductPage from '../../components/pages/products/ProductPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import { slug as slugGen } from 'slug-gen';

export default class ProductController extends LocalizedController {
    private repository: Repository;
    private productsRepository: Repository;
    private productsCatalogsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private productsAttributesRepository: Repository;
    private taxRepository: Repository;
    private contentsRepository: Repository;

    state = {
        productDefinitions: [],
        products: [],
        taxes: [],
        attributes: [],
        catalogs: [],
        items: [],
        categories: [],
        item: undefined,
        brand: undefined,
        isFetching: true,
        productsHaveSlugs: true,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, productsRepository, productsAttributesRepository, productsCatalogsRepository, taxRepository, contentsRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.productsRepository = productsRepository;
        this.productsAttributesRepository = productsAttributesRepository;
        this.productsCatalogsRepository = productsCatalogsRepository;
        this.taxRepository = taxRepository;
        this.contentsRepository = contentsRepository;
        this.localizationService = localizationService;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
            items = items.filter(item => item.status !== 'deleted');
            // items = await Promise.all(items.map(async (item) => {
            //     try {
            //         if (!item.seriesCode) {
            //             let products = await this.repository.getByPath(`definitions/${item.id}/products`);
            //             if (products.length) {
            //                 item.seriesCode = products[0].sku;
            //                 item = await this.repository.update(item);
            //             }
            //         }
            //     } catch (e) {
            //         console.error(e);
            //     }
            //     return item;
            // }));
        } catch (e) {
            console.error(e);
            return [];
        }

        return items;
    }

    private async getStorefrontSettings() {
        let storefronts, storefront;

        try {
            storefronts = await this.productsRepository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                storefront = await this.productsRepository.getByPath(`storefronts/${storefronts[0].id}`);
                this.setState({
                    brand: !storefront.settings.products.brand.productsHaveDifferentBrands ? storefront.settings.products.brand.universalBrand : undefined,
                })
            }
        } catch (e) {
            console.error('Issue with storefronts:', e);
        }
    }

    private async getDefinitionProducts(data: any) {
        let items: any[] = [];

        try {
            items = await this.repository.getByPath(`definitions/${data.id}/products`);
        } catch (e) {
            console.error(e)
            return [];
        }

        for (let item of items) {
            let inventory: any;
            try {
                inventory = await this.repository.getByPath(`products/${item.id}/inventory`);
            } catch (e) {
                if (404 !== e.statusCode)
                    throw e;
            }
            item.inventory = inventory?.data?.available || false;
        }

        return items;
    }

    private async updateStock(data: any) {
        let productId = data.productId;
        delete data['productId'];
        try {
            await this.repository.updateByPath(data, `products/${productId}/inventory`);
        } catch (e) {
            console.error(e);
            throw new Error(e);
        }
    }

    private async updatePrice(data: any) {
        try {
            await this.repository.update(data);
        } catch (e) {
            throw new Error(e);
        }
        try {
            let products = await this.repository.getByPath(`definitions/${data.id}/products`);
            await Promise.all(products.map(async (item) => {
                try {
                    item.price = data.price;
                    item = await this.productsRepository.update(item);
                } catch (e) {
                    console.error(e);
                }
            }));
        } catch (e) {
            throw new Error(e);
        }

    }

    private async updateSingleProductPrice(data: any) {
        try {
            let item = await this.productsRepository.update(data);
        } catch (e) {
            throw new Error(e);
        }
    }

    async componentDidMount() {
        await this.getAll();
    }

    private async getAll() {
        let productDefinitions = await this.get();
        // let allSlugs = await Promise.all(productDefinitions.map(async (value) => {
        //     let webmetas = await this.repository.getByPath(`definitions/${value.id}/webmetas`);

        //     return webmetas && webmetas.length && undefined !== webmetas[0].slug;
        // }))

        let categories = productDefinitions.map((productDefinition) => { return productDefinition.category }).filter((item, i, ar) => ar.indexOf(item) === i);

        await this.getStorefrontSettings();

        this.setState({
            productDefinitions,
            categories,
            isFetching: false,
            productsHaveSlugs: true //allSlugs.every((value) => value),
        });
    }

    private async generateSlugs() {
        let productDefinitions = await this.get();
        let allSlugs = await Promise.all(productDefinitions.map(async (value) => {
            let webmetas = await this.repository.getByPath(`definitions/${value.id}/webmetas`);
            return webmetas && webmetas.length && webmetas[0].slug;
        }))
        if (!allSlugs.every((value) => value.slug)) {
            await Promise.all(productDefinitions.map(async (value) => {
                try {
                    await this.repository.createByPath({
                        slug: slugGen(value.title),
                        language: 'fr',
                    }, `definitions/${value.id}/webmetas`);
                    await this.repository.createByPath({
                        slug: slugGen(value.title),
                        language: 'en',
                    }, `definitions/${value.id}/webmetas`);
                } catch (e) {
                    throw new Error(e);
                }
            }))
        }
        this.setState({
            productsHaveSlugs: true,
        })
    }

    private async publishAll() {
        let productDefinitions;
        try {
            productDefinitions = await this.get();
            for (let definition of productDefinitions) {
                if (definition.status == 'draft') {
                    definition.status = 'published';
                    await this.repository.update(definition);
                }
                let contents = await this.repository.getByPath(`definitions/${definition.id}/contents`);
                if (contents.length) {
                    for (let content of contents) {
                        if (content.status == 'draft') {
                            content.status = 'published';
                            await this.contentsRepository.update(content);
                        }
                    }
                }
            }
            this.router.reload();
        } catch (e) {
            throw e;
        }
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/productdefinitions/${id}`);
    }

    protected changeSettings(path) {
        this.router.redirect(`/store-management/${path}`);
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Products'), value: undefined },
        ]
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }


    private async handleSave(data: any) {
        let item, content, webmeta;
        if (this.state.brand)
            data.brand = this.state.brand;
        data.language = 'fr'; // Update to use application settings default language.
        try {
            item = await this.repository.create(data);
            content = await this.contentsRepository.create({ content: data.content, title: data.title, language: data.language });
            await this.repository.link(item.id, 'contentId', content.id);
        } catch (e) {
            if (webmeta) {
                await this.repository.deleteByPath(`definitions/${item.id}/webmetas/${webmeta.id}`);
            }
            if (item) {
                await this.repository.delete(item.id);
            }
            if (content) {
                await this.contentsRepository.delete(content.id);
            }
            throw e;
        }

        try {
            webmeta = await this.repository.createByPath({
                definitionId: item.id,
                slug: slugGen(data.title),
                language: data.language,
            }, `definitions/${item.id}/webmetas`);
        } catch (e) {
            if (webmeta) {
                await this.repository.deleteByPath(`definitions/${item.id}/webmetas/${webmeta.id}`);
            }
            console.error(e);
        }

        this.router.redirect(`/productdefinitions/${item.id}/edit`);
    }

    render() {
        const { productDefinitions, products, taxes, attributes, catalogs, brand, categories, isFetching, productsHaveSlugs } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductPage
                isFetching={isFetching}
                items={productDefinitions}
                products={products}
                taxes={taxes}
                attributes={attributes}
                catalogs={catalogs}
                brand={brand}
                categories={categories}
                productsHaveSlugs={productsHaveSlugs}
                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                onPathChange={(path) => this.changeSettings(path)}
                quickSave={(data) => this.updatePrice(data)}
                handleChange={({ key, value }) => { this.handleChange(key, value) }}
                handleSave={(data) => { this.handleSave(data) }}
                handlePublishAll={async () => { this.publishAll() }}
                getDefinitionProducts={async (data) => this.getDefinitionProducts(data)}
                handleStockUpdate={async (data) => { this.updateStock(data) }}
                updateSingleProductPrice={async (data) => { this.updateSingleProductPrice(data) }}
                handleGenerateSlugs={(async () => { this.generateSlugs() })}
            />
        )
    }
}