import React from 'react';
import { OverviewPage } from '../components/pages';
import GatekeeperFactory from '../factories/GatekeeperFactory';
import { LocalizationService } from '../services';
import LocalizedController from './LocalizedController';

export default class OverviewController extends LocalizedController {
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {};

    constructor(props) {
        super(props);
        const [router, routeMenuItemFactory, localizationService, gatekeeperFactory] = props.args;

        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: undefined },
        ]
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();
        return this.prepare(
            <OverviewPage
                localizationService={this.localizationService}
                Gatekeeper={Gatekeeper}
                breadcrumbLevels={this.generateBreadcrumbs()}
            />
        )
    }
}