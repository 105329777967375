import React from 'react';
import { toast } from 'react-toastify';
import { Form, Message, Accordion, Icon, Button } from 'semantic-ui-react';
import MarkdownEditor from '../../../controls/MarkdownEditor';
import SelectInput from '../../../controls/SelectInput';
import { LoadingMessage } from '../../../layouts/messages';
import StateAwareForm, {
    IStateAwareFormProps,
} from '../../StateAwareForm/StateAwareForm';
import languages from '../../../../utilities/Languages/Languages';
import './style.css';
import { DeleteButton } from '../../../controls';

export interface IProductFormProps extends IStateAwareFormProps {
    title: string;
    language: string;
    content: string;
    price: string;
    sku: string;
    productId: string;
    localizationService: any;
}
export default class ProductForm extends StateAwareForm<IProductFormProps> {
    constructor(props) {
        super(props);
    }

    static defaultProps: {
        onSubmit: (values: any) => any;
        onDelete: () => void;
        status: 'draft';
        readOnly: false;
    };

    componentDidMount() {
        super.setState({
            title: this.props.title,
            language: this.props.language,
            content: this.props.content,
            price: this.props.price,
            sku: this.props.sku,
            productId: this.props.productId,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            super.setState({
                title: this.props.title,
                language: this.props.language,
                content: this.props.content,
                price: this.props.price,
                sku: this.props.sku,
                productId: this.props.productId,
            });
        }
    }

    async submit() {
        try {
            await this.handleSubmit();
            toast.success('Product Variant successfully updated.');
        } catch (e) {
            toast.error(e.message);
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    generateLanguagesMap() {
        return languages.map(language => {
            return { key: language.code, text: language.name, value: language.code };
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeAccordeonIndex } = this.state
        const newIndex = activeAccordeonIndex === index ? -1 : index

        this.setState({ activeAccordeonIndex: newIndex })
    }

    encapsulateAccordeon() {
        const { activeAccordeonIndex } = this.state

        return (
            <Form.Group fluid>
                <Accordion fluid style={{ margin: '10px auto' }} styled>
                    <Accordion.Title
                        active={activeAccordeonIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        {this.translate('General Information')}
                    </Accordion.Title>
                    <Accordion.Content active={activeAccordeonIndex === 0}>
                        {this.renderGeneralInformation()}
                    </Accordion.Content>
                </Accordion>
            </Form.Group>
        )
    }

    renderGeneralInformation() {
        return (
            <>
                <Form.Group fluid>
                    <Form.Input
                        width={'16'}
                        fluid
                        label={this.translate("Title")}
                        placeholder={this.translate("Title")}
                        defaultValue={this.props.title}
                        name="title"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label={this.translate("SKU")}
                        placeholder={this.translate("SKU")}
                        defaultValue={this.props.title}
                        name="sku"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <Form.Input
                        fluid
                        label={this.translate("Price")}
                        placeholder={this.translate("Price")}
                        defaultValue={this.props.title}
                        type="number"
                        pattern="^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$"
                        min="0.00"
                        step="0.01"
                        name="price"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                    <SelectInput
                        options={this.generateLanguagesMap()}
                        search
                        label={this.translate('Language')}
                        placeholder={this.translate('Language')}
                        defaultValue={this.props.language}
                        name="language"
                        onChange={(e, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        required
                    />
                </Form.Group>
            </>
        )
    }

    render() {
        const { error, loading } = this.state;
        return (
            <Form
                onSubmit={() => this.submit()}
                error={!!error}
                style={{ zIndex: '0' }}
            >
                <Button.Group floated="right">
                    <Button color="green" disabled={loading}>Save</Button>
                    {this.props.title && (
                        <DeleteButton
                            itemType="subscription"
                            itemName={this.props.title}
                            icon="trash"
                            color="red"
                            handleOnClick={this.props.onDelete}
                        />
                    )}
                </Button.Group>
                <LoadingMessage
                    hidden={!loading}
                    size="large"
                    content="Persisting the Product Variant"
                />
                <Message
                    error
                    header="Could not save product"
                    content={error && error.toString()}
                />
                <div style={{ display: 'block', clear: 'both' }}>
                    {this.props.title ? this.encapsulateAccordeon() : this.renderGeneralInformation()}
                </div>
                <label className={'mobile-product-content-header'}>
                    {this.translate('Product Variant Description')}
                </label>
                <MarkdownEditor
                    value={this.props.content}
                    name="content"
                    onChange={({ name, value }) =>
                        this.handleChange({ name, value })
                    }
                />
            </Form>
        );
    }
}
