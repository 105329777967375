import React, { PureComponent } from 'react';
import Component from '../../../layouts/component';
import Page from '../../Page';
import DesktopPage from './DesktopPage';

export interface IApplicationPageProps {
    createRoutes?: Function,
    items: any,
    Gatekeeper: any;
}

export default class ApplicationPage extends PureComponent<IApplicationPageProps> {
    render() {
        const {
            createRoutes,
            items,
            Gatekeeper,
        } = this.props;

        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        items={items}
                        Gatekeeper={Gatekeeper}
                    />
                </Component>
            </Page>
        );
    }
}