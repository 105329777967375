import React, { PureComponent } from "react";
import Responsive from "../responsive/Responsive";
export default class DesktopSkeleton extends PureComponent {
    render() {
        const { children } = this.props;
        return (
            <Responsive device="desktop">
                {children}
            </Responsive>
        );
    }
}
