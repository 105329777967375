import React, { PureComponent } from "react";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";
import LocalizedPage from '../../LocalizedPage';
import Component from "../../../layouts/component";

export interface IAccountSubscriptionPageProps {
    createRoutes?: Function;
    items: any;
    subscriptions: any;
    Gatekeeper: any;
    id?: any;
    onCreateAccountSubscription: (data: any) => Promise<any>;
    onAssociationComplete: () => Promise<any>;
    onChooseSubscription: (data: any) => Promise<any>;
    subscriptionLicenses: (data: any) => Promise<any>;
    subscriptionLicensesFeatures: (data: any) => Promise<any>;
}

export default class AccountSubscriptionPage extends PureComponent<IAccountSubscriptionPageProps> {
    render() {
        const { createRoutes, id, items, subscriptions, Gatekeeper, onCreateAccountSubscription, onAssociationComplete, onChooseSubscription, subscriptionLicenses, subscriptionLicensesFeatures } = this.props;
        const Page = LocalizedPage();
        return (
            <Page createRoutes={createRoutes}>
                <Component>
                    <DesktopPage
                        items={items}
                        id={id}
                        Gatekeeper={Gatekeeper}
                        onCreateAccountSubscription={async (data: any) => onCreateAccountSubscription(data)}
                        onChooseSubscription={async (data: any) => onChooseSubscription(data)}
                        onAssociationComplete={() => onAssociationComplete()}
                        subscriptionLicenses={async (data: any) => subscriptionLicenses(data)}
                        subscriptionLicensesFeatures={async (data: any) => subscriptionLicensesFeatures(data)}
                    />
                    <MobilePage
                        items={items}
                        id={id}
                        Gatekeeper={Gatekeeper}
                        onCreateAccountSubscription={async (data: any) => onCreateAccountSubscription(data)}
                        onChooseSubscription={async (data: any) => onChooseSubscription(data)}
                        onAssociationComplete={() => onAssociationComplete()}
                        subscriptionLicenses={async (data: any) => subscriptionLicenses(data)}
                        subscriptionLicensesFeatures={async (data: any) => subscriptionLicensesFeatures(data)}
                    />
                </Component>
            </Page>
        );
    }
}
