import React, { PureComponent } from "react";
import { Button, Table } from "semantic-ui-react";
import { DesktopSkeleton } from "../../layouts/skeleton";
import { Message, UnauthorizedMessage } from "../../layouts/messages";
import { LoadingSpinner } from "../../controls";
import { ScrollableTable } from "../../layouts/tables";
import { DimmedModal } from "../../layouts/modals";
import "./styles.css";
import CreateButton from "../../controls/buttons/CreateButton/CreateButton";

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    Gatekeeper: any;
    onDoubleClick: Function;
}
export interface IDesktopPageState {
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {

    state = {
        column: 'name',
        direction: undefined,
        data: [],
    };

    static defaultProps = {
        onDoubleClick: () => { }
    }

    componentDidMount(): void {
        this.setState({
            data: this.props.items ? this.props.items : [],
        })
    }

    componentDidUpdate(prevProps: Readonly<IDesktopPageProps>, prevState: Readonly<IDesktopPageState>, snapshot?: any): void {
        if (this.props !== prevProps) {
            this.setState({
                data: this.props.items ? this.props.items : [],
            });
        }
    }

    private sortColumn(action: string, column: string) {
        switch (action) {
            case 'CHANGE_SORT':
                const direction = this.state.direction === 'ascending' ? 'descending' : 'ascending';
                const data = this.state.data.sort((a: string[], b: string[]) =>
                    a[column].localeCompare(b[column])
                );
                if (direction == 'descending') { data.reverse(); }
                this.setState({
                    data,
                    direction,
                    column,
                });
                break;
            default:
                console.error('not an appropriate action type.');
        }
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal
                header="Nothing to see here!"
                content="Add your first license!"
                actions={['Not now', { key: 'done', content: 'Create license', positive: true, href: '/licenses/create' }]}
            />
        }
        return;
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private renderTableHeaders() {
        const { column, direction } = this.state;
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell sorted={column === 'name' ? direction : undefined} onClick={(e) => { this.sortColumn('CHANGE_SORT', 'name') }}>Name</Table.HeaderCell>
                    <Table.HeaderCell sorted={column === 'type' ? direction : undefined} onClick={(e) => { this.sortColumn('CHANGE_SORT', 'type') }}>Type</Table.HeaderCell>
                    <Table.HeaderCell sorted={column === 'level' ? direction : undefined} onClick={(e) => { this.sortColumn('CHANGE_SORT', 'level') }}>Level</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    renderTableBody() {
        // const { items } = this.props;
        const { data } = this.state;
        if (!data) { return; }
        const licensesTable = data.map((license: any) => {
            return (
                <Table.Row onClick={(e) => this.handleDoubleClick(e, license)}>
                    <Table.Cell>{license.name}</Table.Cell>
                    <Table.Cell>{license.type}</Table.Cell>
                    <Table.Cell>{license.level}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button.Group>
                            <Button
                                icon="edit"
                                color="teal"
                                as="a"
                                href={`/licenses/${license.id}/edit`}
                            />
                        </Button.Group>
                    </Table.Cell>
                </Table.Row>
            );
        });
        return <Table.Body>{!licensesTable.length ? <Message content="No content." compact /> : licensesTable}</Table.Body>;
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="license-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <ScrollableTable striped selectable sortable>
                            {this.renderTableHeaders()}
                            {this.renderTableBody()}
                        </ScrollableTable>
                        {this.renderNoContentModal()}
                        <CreateButton itemType='license' href='/licenses/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        );
    }
}
