import React, { PureComponent, ReactElement } from "react";
import { Accordion, Button, Card, Divider, Form, Grid, GridColumn, GridRow, Icon, Label, List, ListItem, Modal, Table } from "semantic-ui-react";
import InlineOrderUpdate from "../../InlineOrderUpdate";
import CommonUtils from "../../../../../utilities/Common/CommonUtils";
import Media from "react-media";
import SimpleInlineOrderUpdate from "../../SimpleInlineOrderUpdate";

const commonUtils = new CommonUtils();

export interface IOrderQuickViewProps {
    order: any;
    orderItems?: any[];
    invoiceInfo?: any;
    toggleModal?: (toggleModal: boolean) => void;
    toggleOrderStatus?: (data: any) => void;
    localizationService?: any;
    displaySku?: boolean;
}

export interface IOrderQuickViewState {
}

export default class OrderQuickView extends PureComponent<IOrderQuickViewProps, IOrderQuickViewState> {
    static defaultProps = {
        orderItems: [],
        invoiceInfo: {
            contact: {},
            invoice: {},
        },
        displaySku: true,
    };

    state = {
        activeIndex: -1,
    };

    componentDidMount() { }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
        }
    }

    translate(key: string): string {
        if (!this.props.localizationService) { return key; }
        return this.props.localizationService.translate(key);
    }

    toggleModal() {
        if (this.props.toggleModal) {
            this.props.toggleModal(false);
        }
    }

    toggleAccordion(key: number) {
        let activeIndex = key;

        if (this.state.activeIndex === key) {
            activeIndex = -1;
        }
        this.setState({ activeIndex });
    }

    toggleOrderStatus(data) {
        this.props.toggleOrderStatus && this.props.toggleOrderStatus(data);
    }

    private getItemTitle(item) {
        let lang = JSON.parse(localStorage.getItem('lang')!);
        if (!item.title) {
            let content = item.contents.filter((content) => content.language == lang)[0];
            return content.title;
        } return item.title;
    }

    private generateOrderLineItems() {
        const { orderItems, invoiceInfo } = this.props;
        let subTotal = 0;
        if (!orderItems || !invoiceInfo) { return; }
        let lineItems = invoiceInfo.invoice.lineItems.map((lineItem, index) => {
            const { item } = lineItem;
            subTotal += (item.price * lineItem.quantity);
            let attributes = item && item.attributes && Object.entries(item.attributes).map(([key, value], index) => {
                return <span><strong>{key}:</strong> {value}</span>;
            })
            if ('product' === lineItem.item.type) {
                return (
                    <Table.Row>
                        <Table.Cell>
                            <span style={{ fontWeight: 'bolder' }}>{`${this.props.displaySku && item.sku ? `${item.sku}.` : ''}${this.getItemTitle(item)}`}</span>
                            <br /><span style={{ fontWeight: 'lighter' }}>{orderItems[index].item.category}</span>
                            {attributes && attributes.length &&
                                <Accordion>
                                    <Accordion.Title
                                        active={this.state.activeIndex === index}
                                        index={index}
                                        onClick={() => this.toggleAccordion(index)}
                                    >
                                        <Icon name='dropdown' />
                                        {this.translate('Details')}
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === index}>
                                        <span style={{ display: "flex", flexDirection: 'column', width: '100%', flexWrap: 'wrap', margin: '0rem 1.5rem', fontSize: 'smaller' }}>
                                            {attributes}
                                        </span>
                                    </Accordion.Content>
                                </Accordion>
                            }
                        </Table.Cell>
                        <Table.Cell verticalAlign='top'>
                            {`x ${lineItem.quantity}`}
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' textAlign="right">
                            {commonUtils.renderCurrency((lineItem.quantity * item.price), 'fr-CA', 'CAD')}
                        </Table.Cell>
                    </Table.Row>
                )
            }
            return (
                <Table.Row>
                    <Table.Cell>
                        <span style={{ fontWeight: 'bolder' }}>{this.translate('Delivery fees')}</span>
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle'>

                    </Table.Cell>
                    <Table.Cell verticalAlign='middle' textAlign="right">
                        {commonUtils.renderCurrency(item.price, 'fr-CA', 'CAD')}
                    </Table.Cell>
                </Table.Row>
            )
        })

        lineItems.push(
            <Table.Row>
                <Table.Cell>
                    <span style={{ fontWeight: 'bolder' }}>{this.translate('Subtotal')}</span>
                </Table.Cell>
                <Table.Cell verticalAlign='middle'>

                </Table.Cell>
                <Table.Cell verticalAlign='middle' textAlign="right">
                    <span style={{ fontWeight: 'bolder' }}>{commonUtils.renderCurrency(subTotal, 'fr-CA', 'CAD')}</span>
                </Table.Cell>
            </Table.Row>
        )

        if (invoiceInfo?.invoice?.taxes && invoiceInfo?.invoice?.taxes.length > 0) {
            lineItems.push(
                <Table.Row>
                    <Table.Cell>
                        <span style={{ fontWeight: 'bolder' }}>TPS</span>
                    </Table.Cell>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle' textAlign="right">
                        {invoiceInfo?.invoice?.taxes && invoiceInfo.invoice.taxes.length > 0 && commonUtils.renderCurrency(invoiceInfo.invoice.taxes.find((tax) => tax.tax.name == "TPS").value, 'fr-CA')}
                    </Table.Cell>
                </Table.Row>
            );
            lineItems.push(
                <Table.Row>
                    <Table.Cell>
                        <span style={{ fontWeight: 'bolder' }}>TVQ</span>
                    </Table.Cell>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle' textAlign="right">
                        {invoiceInfo?.invoice?.taxes && invoiceInfo.invoice.taxes.length > 0 && commonUtils.renderCurrency(invoiceInfo.invoice.taxes.find((tax) => tax.tax.name == "TVQ").value, 'fr-Ca')}
                    </Table.Cell>
                </Table.Row>
            );
        }

        lineItems.push(
            <Table.Row>
                <Table.Cell>
                    <span style={{ fontWeight: 'bolder' }}>Total</span>
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell verticalAlign='middle' textAlign="right">
                    <span style={{ fontWeight: 'bolder' }}>{invoiceInfo?.invoice?.total && commonUtils.renderCurrency(invoiceInfo.invoice.total, 'fr-CA')}</span>
                </Table.Cell>
            </Table.Row>
        );
        return lineItems;
    }

    private generateOrderLineItemCards() {
        const { orderItems, invoiceInfo } = this.props;
        if (!orderItems || !invoiceInfo) { return; }
        let subTotal = 0;
        let lineItems = invoiceInfo.invoice.lineItems.map((lineItem, index) => {
            const { item } = lineItem;
            let attributes = item && item.attributes && Object.entries(item.attributes).map(([key, value], index) => {
                return <span><strong>{key}:</strong> {value}</span>;
            })
            subTotal += (item.price * lineItem.quantity);
            if ('product' === lineItem.item.type) {
                return (
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>{`${this.props.displaySku && item.sku ? `${item.sku}.` : ''}${this.getItemTitle(item)}`}</Card.Header>
                            <Card.Meta>{item.category}</Card.Meta>
                            <Card.Description textAlign="left" style={{ fontWeight: 'bolder' }}>x {lineItem.quantity}<br />
                                {attributes && attributes.length &&
                                    <Accordion>
                                        <Accordion.Title
                                            active={this.state.activeIndex === index}
                                            index={index}
                                            onClick={() => this.toggleAccordion(index)}
                                        >
                                            <Icon name='dropdown' />
                                            {this.translate('Details')}
                                        </Accordion.Title>
                                        <Accordion.Content active={this.state.activeIndex === index}>
                                            <span style={{ display: "flex", flexDirection: 'column', width: '100%', flexWrap: 'wrap', margin: '0rem 1.5rem', fontSize: 'smaller' }}>
                                                {attributes}
                                            </span>
                                        </Accordion.Content>
                                    </Accordion>}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                )
            } else {
                return (
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>{this.translate('Delivery fees')}</Card.Header>
                            <Card.Description textAlign="right" style={{ fontWeight: 'bolder' }}>{commonUtils.renderCurrency(item.price, 'fr-CA', 'CAD')}</Card.Description>
                        </Card.Content>
                    </Card>
                )
            }
        })

        lineItems.push(
            <Card fluid>
                <Card.Content>
                    <Card.Header>{this.translate('Subtotal')}</Card.Header>
                    <Card.Description textAlign="right" style={{ fontWeight: 'bolder' }}>
                        {commonUtils.renderCurrency(subTotal, 'fr-CA')}
                    </Card.Description>
                </Card.Content>
            </Card>
        );

        if (invoiceInfo?.invoice?.taxes && invoiceInfo?.invoice?.taxes.length > 0) {
            lineItems.push(
                <Card fluid>
                    <Card.Content>
                        <Card.Header>TPS</Card.Header>
                        <Card.Description textAlign="right" style={{ fontWeight: 'bolder' }}>
                            {invoiceInfo?.invoice?.taxes && invoiceInfo?.invoice?.taxes.length > 0 && commonUtils.renderCurrency(invoiceInfo.invoice.taxes.find((tax) => tax.tax.name == "TPS").value, 'fr-CA')}
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
            lineItems.push(
                <Card fluid>
                    <Card.Content>
                        <Card.Header>TVQ</Card.Header>
                        <Card.Description textAlign="right" style={{ fontWeight: 'bolder' }}>
                            {invoiceInfo?.invoice?.taxes && invoiceInfo?.invoice?.taxes.length > 0 && commonUtils.renderCurrency(invoiceInfo.invoice.taxes.find((tax) => tax.tax.name == "TVQ").value, 'fr-Ca')}
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
        }
        lineItems.push(
            <Card fluid>
                <Card.Content>
                    <Card.Header>Total</Card.Header>
                    <Card.Description textAlign="right" style={{ fontWeight: 'bolder' }}>
                        {invoiceInfo?.invoice?.total && commonUtils.renderCurrency(invoiceInfo.invoice.total, 'fr-CA')}
                    </Card.Description>
                </Card.Content>
            </Card>
        );
        return lineItems;
    }

    private generateCustomerInfo() {
        const { invoiceInfo } = this.props;
        if (!invoiceInfo || !invoiceInfo.contact) { return; }
        let contactItems: ReactElement[] = [];
        contactItems.push(<List.Header>{this.translate('Name')}: {invoiceInfo.contact.name}</List.Header>)
        contactItems.push(<List.Content>{this.translate('E-mail address')}: {invoiceInfo.contact.email}</List.Content>)
        contactItems.push(<List.Content>{this.translate('Phone number')}: {invoiceInfo.contact.phoneNumber}</List.Content>)
        return contactItems;
    }

    private generateShippingInfo() {
        const { invoiceInfo } = this.props;
        if (!invoiceInfo || !invoiceInfo.shipping) { return; }
        let shippingItems: ReactElement[] = [];
        shippingItems.push(<List.Header>{this.translate('Name')}: {invoiceInfo.shipping.name}</List.Header>)
        shippingItems.push(<List.Content>{this.translate('E-mail address')}: {invoiceInfo.shipping.email}</List.Content>)
        shippingItems.push(<List.Content>{this.translate('Phone number')}: {invoiceInfo.shipping.phoneNumber}</List.Content>)
        if (invoiceInfo.shipping.apt) {
            shippingItems.push(<List.Content>{this.translate('Address')}: {invoiceInfo.shipping.apt}-{invoiceInfo.shipping.civic} {invoiceInfo.shipping.street}</List.Content>)
        } else {
            shippingItems.push(<List.Content>{this.translate('Address')}: {invoiceInfo.shipping.civic} {invoiceInfo.shipping.street}</List.Content>)
        }
        shippingItems.push(<List.Content>{invoiceInfo.shipping.city}, {invoiceInfo.shipping.state}, {invoiceInfo.shipping.country}</List.Content>)
        shippingItems.push(<List.Content>{invoiceInfo.shipping.postalCode}</List.Content>)
        return shippingItems;
    }

    render() {
        const { order } = this.props;

        return (
            <>
                <Modal.Header>{this.translate('Order')} #{order.orderNumber}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid>
                            <GridRow columns={1}>
                                <GridColumn>
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Header>{this.translate('Order Details')}</Card.Header>
                                            <Divider />
                                            <Grid divided columns={2} relaxed className='customer-order-information-card'>
                                                <GridColumn>
                                                    <List.Header>{this.translate('Customer Info')}</List.Header>
                                                    <List.Content>
                                                        {this.generateCustomerInfo()}
                                                    </List.Content>
                                                </GridColumn>
                                                {this.props.invoiceInfo && this.props.invoiceInfo.shipping &&
                                                    <GridColumn>
                                                        <List.Header>{this.translate('Shipping Info')}</List.Header>
                                                        <List.Content>
                                                            {this.generateShippingInfo()}
                                                        </List.Content>
                                                    </GridColumn>
                                                }
                                                <GridColumn>
                                                    <List.Header>{this.translate('Order Created Date')}</List.Header>
                                                    <List.Content>
                                                        {commonUtils.renderDate(order.date, false)}
                                                    </List.Content>
                                                </GridColumn>
                                                <GridColumn>
                                                    <List.Header>{this.translate('Order Due Date')}</List.Header>
                                                    <List.Content>
                                                        {commonUtils.renderDate(order.dueDate, false)}
                                                    </List.Content>
                                                </GridColumn>
                                            </Grid>
                                        </Card.Content>
                                    </Card>
                                </GridColumn>
                            </GridRow>
                            <GridRow columns={1}>
                                <GridColumn>
                                    <Media queries={{
                                        medium: "(max-width: 1023px)",
                                        large: "(min-width: 1024px)",
                                    }}>
                                        {matches => (
                                            <>
                                                {matches.medium && <Card.Group itemsPerRow={1}>
                                                    {this.generateOrderLineItemCards()}
                                                </Card.Group>}
                                                {matches.large && <Table columns={2}>
                                                    <Table.Header>
                                                        <Table.HeaderCell>{this.translate('Item')}</Table.HeaderCell>
                                                        <Table.HeaderCell>{this.translate('Quantity')}</Table.HeaderCell>
                                                        <Table.HeaderCell>{this.translate('Price')}</Table.HeaderCell>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {this.generateOrderLineItems()}
                                                    </Table.Body>
                                                </Table>}
                                            </>
                                        )}
                                    </Media>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div style={{ paddingBottom: '1rem' }}>
                        <span style={{ float: 'left' }}><Button content='Fermer' onClick={() => this.toggleModal()} /></span>
                        {this.translate('Order Status')}: <SimpleInlineOrderUpdate localizationService={this.props.localizationService} name='' value={order.status} item={order} onChange={(data) => { this.toggleOrderStatus(data) }} />
                    </div>
                </Modal.Actions>
            </>
        )
    }
}