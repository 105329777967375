import React, { PureComponent } from "react";
import "./styles.css";
import {
    Button,
    Table,
    Header,
} from "semantic-ui-react";
import { MobileSkeleton } from "../../../layouts/skeleton";
import { ScrollableTable } from "../../../layouts/tables";
import { Dock } from "../../../layouts/sections";
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ReactMarkdown from "react-markdown";
import { ReturnButton } from "../../../controls";

export interface IDesktopPageProps {
    createRoutes?: Function;
    id?: any;
    items: any[];
    Gatekeeper: any;
    onCreateAccountSubscription: (data: any) => Promise<any>;
    onChooseSubscription: (data: any) => Promise<any[]>;
    subscriptionLicenses: (data: any) => Promise<any[]>;
    subscriptionLicensesFeatures: (data: any) => Promise<any[]>;
    onAssociationComplete: () => {};
}
export interface IDesktopPageState {
    dockIsVisible: boolean,
    activeIndex: number,
    state: string;
    query: any;
    selectedSubscription: any;
    selectedTerm: any;
    terms: any[];
    activeSubscriptionDescription: any;
    licenses: any;
    features: any;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state: IDesktopPageState = {
        dockIsVisible: false,
        activeIndex: -2,
        state: 'subscription',
        query: {},
        terms: [],
        selectedSubscription: undefined,
        selectedTerm: undefined,
        activeSubscriptionDescription: undefined,
        licenses: undefined,
        features: undefined,
    };

    handleNavigateToSubscription() {
        return this.setState({
            state: 'subscription'
        })
    }

    handleNavigateToTerm() {
        return this.setState({
            state: 'term'
        })
    }

    toggleVisibility() {
        this.setState({ dockIsVisible: !this.state.dockIsVisible })
    }

    handleClick(activeIndex: number) {
        const current = this.state.activeIndex;
        this.setState({ activeIndex: current !== activeIndex ? activeIndex : -1 })
    }

    private async handleCreate() {
        let item;

        try {
            item = await this.props.onCreateAccountSubscription({
                subscriptionId: this.state.selectedSubscription?.id,
                termId: this.state.selectedTerm?.id,
                state: "active"
            });
        } catch (e) {
            throw e;
        }

        this.setState({
            state: 'successful'
        });
    }

    private async handleChooseSubscription(data: any) {
        let items;

        try {
            items = await this.props.onChooseSubscription(data);
        } catch (e) {
            throw e;
        }

        this.setState({
            terms: items,
            selectedSubscription: data,
            state: 'term',
        });
    }

    private async handleChooseTerm(data: any) {
        this.setState({
            selectedTerm: data,
            state: 'review',
        });
    }

    renderState(state: string) {
        switch (state) {
            case 'subscription': return this.renderSubscriptionSelectionState();
            case 'term': return this.renderTermSelectionState();
            case 'review': return this.renderReviewState();
            case 'successful': return this.renderSuccessful();
        }

        return ""
    }

    private renderSuccessful() {
        return [
            <div>
                <Header>Successfully added {this.state.selectedSubscription?.name} for a period of {this.state.selectedTerm?.period} months to this account</Header>
            </div>,
            <div>
                <Button positive onClick={() => this.props.onAssociationComplete()}>Done</Button>
            </div>
        ]
    }

    private renderReviewState() {
        return [
            <div>
                <Header>Review Subscription</Header>
                <p>The following subscription and term will be assigned.</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Period (Months)</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{this.state.selectedSubscription?.name}</Table.Cell>
                            <Table.Cell>{this.state.selectedTerm?.period}</Table.Cell>
                            <Table.Cell>${this.state.selectedTerm?.price}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>,
            <div>
                <Button onClick={() => this.handleNavigateToSubscription()}>Modify Subscription</Button>
                <Button onClick={() => this.handleNavigateToTerm()}>Modify Term</Button>
                <Button primary onClick={() => this.handleCreate()}>Create</Button>
            </div>
        ]
    }

    private renderSubscriptionsTableBody() {
        const itemsTable = this.props?.items.map((item) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell><ReactMarkdown children={item.description} /></Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Button icon='info circle' onClick={async () => {
                            await this.getInformation(item);
                        }} />
                        <Button icon="check" positive onClick={() => this.handleChooseSubscription(item)} />
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    private async getInformation(data) {
        let licenses;
        let features;

        try {
            licenses = await this.props.subscriptionLicenses(data);
            features = await Promise.all(licenses.map(license => {
                return this.props.subscriptionLicensesFeatures(license);
            }))

        } catch (e) {
            console.error(e);
        }

        this.setState({ dockIsVisible: !this.state.dockIsVisible, activeSubscriptionDescription: data, licenses, features })
    }

    private renderTerms() {
        const { Gatekeeper } = this.props;
        const itemsTable = this.state?.terms.map((item: any) => {
            return (
                <Table.Row>
                    <Table.Cell>{item.period}</Table.Cell>
                    <Table.Cell>${item.price}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Button icon="check" positive onClick={() => this.handleChooseTerm(item)} />
                    </Table.Cell>
                </Table.Row>
            )
        });
        return itemsTable;
    }

    private renderSubscriptionSelectionState() {
        return <ScrollableTable>
            {this.renderSubscriptionsTableHeader()}

            <Table.Body>
                {this.renderSubscriptionsTableBody()}
            </Table.Body>
        </ScrollableTable>;
    }

    renderTermSelectionState() {
        return [
            <div>
                <Header>Choose a Term</Header>
                <p>Click on the checkmark that corresponds with the term you want to create in the table below.</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Period (Months)</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderTerms()}
                    </Table.Body>
                </Table>
                <Button onClick={() => this.handleNavigateToSubscription()}>Back to Subscriptions</Button>
            </div>
        ];
    }

    private renderDock() {
        console.log('activeSubscriptionDescription', this.state.activeSubscriptionDescription)
        return <Dock openFromRight showNav={this.state.dockIsVisible} onHideNav={() => this.setState({ dockIsVisible: false })}>
            <Header as='h2'>{this.state.activeSubscriptionDescription?.name}</Header>
            <div className="shop-items">
                <div className="item" v-for="item in shopItems">
                    <div className="item-block">
                        <div className="description">
                            <ReactMarkdown children={this.state.activeSubscriptionDescription?.description} />
                        </div>
                        <div className="description panel">
                            <Collapse accordion={true}>
                                {this.renderLicensesForSubscription()}
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </Dock>;
    }

    private renderLicensesForSubscription() {
        const { licenses } = this.state;
        if (!licenses) return;
        return licenses.map(license => {
            return (<Panel header={license?.name}>
                {this.renderFeaturesForLicense()}
            </Panel>)
        })
    }

    private renderSubscriptionsTableHeader() {
        return (
            <Table.Header>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Header>
        )
    }

    private renderFeaturesForLicense() {
        const { features } = this.state;
        console.log('5', features);
        if (!features) return;
        let results = features.map((feature, index) => {
            return feature.map(feat => {
                return (
                    <Panel header={feat?.name}>
                        {this.generateVerbs(feat)}
                    </Panel>
                )
            })

        })
        return (<Collapse accordion={true}>
            {results}
        </Collapse>);
    }

    private generateVerbs(verbs) {
        const { permissions } = verbs;
        let renderedVerbs = permissions.map(permission => {
            return (
                <div>
                    <p style={{ fontWeight: 'bold' }}>{permission?.resourcePath}</p>
                    <ul>
                        {permission.verbs.map(verb => { return <li>{verb}</li> })}
                    </ul>
                </div>)
        })
        return <div>{renderedVerbs}</div>;
    }

    render() {
        const { state } = this.state;

        return (
            <MobileSkeleton>
                {this.props.id && <><ReturnButton href={`/accounts/${this.props.id}`} /><br /><br /><br /></>}

                {this.renderState(state)}
                {this.renderDock()}
            </MobileSkeleton>
        );
    }
}
