import React, { PureComponent } from 'react';
import { Button, Table, Image, Header, Container, Card, CardContent, CardHeader, CardDescription, Icon, CardMeta, CardGroup } from 'semantic-ui-react';
import { LoadingSpinner } from '../../controls';
import CreateButton from '../../controls/buttons/CreateButton/CreateButton';
import { UnauthorizedMessage } from '../../layouts/messages';
import { DimmedModal } from '../../layouts/modals';
import { DesktopSkeleton } from '../../layouts/skeleton';
import { ScrollableTable } from '../../layouts/tables';
import './styles.css';

export interface IDesktopPageProps {
    createRoutes?: Function,
    items?: any,
    Gatekeeper: any;
    onDoubleClick: Function;
}

export default class DesktopPage extends PureComponent<IDesktopPageProps> {

    state = {
    };

    static defaultProps = {
        onDoubleClick: () => { },
    }

    renderNoContentModal() {
        if (!this.props.items.length) {
            return <DimmedModal header="Nothing to see here!" content="Add your first application template!" actions={['Not now', { key: 'done', content: 'Create application template', positive: true, href: '/applicationtemplates/create' }]} />;
        }
        return;
    }

    renderitems() {
        const { items } = this.props;
        const itemsTable = items.map((item) => {
            return (
                <Card>
                    <CardContent>
                        <CardHeader>{item.name}</CardHeader>
                    </CardContent>
                    <CardContent>
                        <CardDescription>
                            <Icon name='code branch' /> {item.templateRepository} <br />
                            <Icon name='ticket' /> {item.templateRepositoryOwner} <br />
                        </CardDescription>
                    </CardContent>
                    <CardContent extra textAlign='right'>
                        <Button icon='edit' color='teal' as="a" href={`/applicationtemplates/${item.id}/edit`} />
                    </CardContent>
                </Card>
            )
        });
        return (
            <CardGroup centered stackable itemsPerRow={'3'}>
                {!itemsTable.length ? this.renderNoContentMessage() : itemsTable}
            </CardGroup>
        )
    }

    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '10%' }} textAlign='center'>
                <Header>No application templates!</Header>
            </Container>
        )
    }

    render() {
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="applicationtemplate-management"
                    unauthorizedComponent={<UnauthorizedMessage />}
                    loadingComponent={<LoadingSpinner />}>
                    <div className="item-list-container">
                        <Container style={{ height: '70vh', overflowX: 'hidden', margin: '50px 50px' }}>
                            {this.renderitems()}
                        </Container>
                        {this.renderNoContentModal()}
                        <CreateButton itemType='application template' href='/applicationtemplates/create' attentionGrabber={this.props.items.length === 0} />
                    </div>
                </Gatekeeper>
            </DesktopSkeleton>
        )
    }
}