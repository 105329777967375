import React, { PureComponent } from 'react';
import { Message, Icon, MessageProps } from 'semantic-ui-react';
import './styles.css';

export interface ILoadingMessageProps extends MessageProps {

}

export default class LoadingMessage extends PureComponent<ILoadingMessageProps> {
    static defaultProps = {
        iconName: 'circle notched',
        header: 'One moment',
        content: 'We are fetching your content',
        /**
         * Color can be either red, orange, yellow, olivegreen,
         *                  teal, blue, violet, purple, pink,
         *                  brown, grey, black.
         *  */
        color: 'grey',
        size: 'small',
        loading: true,
    }
    render() {
        const { children, iconName, header, content, color, size, loading, hidden } = this.props;

        return (
            <Message icon size={size} className='loading-message-container' color={color} hidden={hidden}>
                <Icon name={iconName} loading={loading} />
                <Message.Content>
                    <Message.Header>{header}</Message.Header>
                    {content || children}
                </Message.Content>
            </Message>
        );
    }
}