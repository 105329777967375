import React from 'react';
import { LicensePage } from '../../components/pages';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import LocalizedController from '../LocalizedController';

export default class LicenseController extends LocalizedController {
    private repository: Repository;
    private gatekeeperFactory: GatekeeperFactory;

    state = {
        items: []
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
    }

    private async get() {
        let items: any[] = [];

        try {
            items = await this.repository.get();
        } catch (e) {
            console.error(e)
            return [];
        }

        return items;
    }

    async componentDidMount() {
        let items = await this.get();

        this.setState({
            items,
        })
    }

    private async onDoubleClick(id: any) {
        this.router.redirect(`/licenses/${id}/edit`);
    }

    render() {
        const { items } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <LicensePage
                items={items}

                Gatekeeper={Gatekeeper}
                onDoubleClick={async (id: any) => this.onDoubleClick(id)}
            />
        )
    }
}